import React,{useEffect} from 'react'
import {Row,Col} from 'react-bootstrap'
import validator from 'validator';
import {useDispatch,useSelector} from 'react-redux'
import PhoneNumberComp from '../../../Components/PhoneNumberComp';
import {onChangeNameForm1,onChangeBirthDateForm1,onChangeSchoolNameForm1,onChangeFamilyDocNameForm1,onChangeGradeForm1,
        onChangeRefferingDocNameForm1,onChangePrimaryLangForm1,onChangeOtherLangForm1,onChangeChildFirstLangForm1,
        onChangeAgeWhenLearnedEnglishForm1,onChangeChildBirthCountryForm1,onChangeAgeWhenArrivedCanadaForm1,onChangeChildLivesWithForm1,
        
        onChangeParent1NameForm1,onChangeParent1AgeForm1,onChangeParent1CellForm1,onChangeParent1EmailForm1,onChangeParent1OccupationForm1,
        onChangeParent1EducationForm1, 
        
        onChangeParent2NameForm1,onChangeParent2AgeForm1,onChangeParent2CellForm1,onChangeParent2EmailForm1,onChangeParent2OccupationForm1,
        onChangeParent2EducationForm1,

        onChangeMaritalStatusForm1,onChangeLegalCustodyForm1,onChangeRelativeNameForm1,onChangeRelativeAgeForm1,onChangeRelativeRelationForm1,
        onChangeRelativeLivesInForm1

    } from '../../../Store/Actions/OnBoardingFormActions/Form1Actions'
import {OnlyAlphabets,EmailValidation} from '../../../Validations'
import InputComp from '../../../Components/InputComp'
import SelectComponent from '../../../Components/SelectComponent'
import SelectCountryComp from '../../../Components/SelectCountryComp'
import CheckboxComp from '../../../Components/CheckboxComp'
import DatePickerComp from '../../../Components/DatePickerComp'
import {getTimeInISO} from '../../../TimeFormate'
import './CSS/PreCallQuestionForm.css'

function PreCallQuestionForm1(props) {


    const dispatch = useDispatch()

    // End other Relatives functions
    const Name = useSelector(state => state.Form1Reducer.Name)
    const BirthDate = useSelector(state => state.Form1Reducer.birthDate)
    const SchoolName = useSelector(state => state.Form1Reducer.schoolName)
    const familyDoc = useSelector(state => state.Form1Reducer.familyDoc)
    const grade = useSelector(state => state.Form1Reducer.grade)
    const refferingDoc = useSelector(state => state.Form1Reducer.refferingDoc)
    const primaryLang = useSelector(state => state.Form1Reducer.primaryLang)
    const otherLang = useSelector(state => state.Form1Reducer.otherLang)
    const childFirstLang = useSelector(state => state.Form1Reducer.childFirstLang)
    const ageWhenLeargedEng = useSelector(state => state.Form1Reducer.ageWhenLeargedEng)
    const birthCountry = useSelector(state => state.Form1Reducer.birthCountry)
    const ageWhenArrivedCanada = useSelector(state => state.Form1Reducer.ageWhenArrivedCanada)
    const livesWith = useSelector(state => state.Form1Reducer.livesWith)
    
    // Parent 1
    const parent1Name = useSelector(state => state.Form1Reducer.livesWith[0].Name)
    const parent1Age = useSelector(state => state.Form1Reducer.livesWith[0].Age)
    const parent1Cell = useSelector(state => state.Form1Reducer.livesWith[0].cell)
    const parent1Occupation = useSelector(state => state.Form1Reducer.livesWith[0].Occupation)
    const parent1Education = useSelector(state => state.Form1Reducer.livesWith[0].educationLevel)
    const parent1Email = useSelector(state => state.Form1Reducer.livesWith[0].email)


    // parent 2
    const parent2Name = useSelector(state => state.Form1Reducer.livesWith[1].Name)
    const parent2Age = useSelector(state => state.Form1Reducer.livesWith[1].Age)
    const parent2Cell = useSelector(state => state.Form1Reducer.livesWith[1].cell)
    const parent2Occupation = useSelector(state => state.Form1Reducer.livesWith[1].Occupation)
    const parent2Education = useSelector(state => state.Form1Reducer.livesWith[1].educationLevel)
    const parent2Email = useSelector(state => state.Form1Reducer.livesWith[1].email)
    const maritalStatusOfParent = useSelector(state => state.Form1Reducer.maritalStatusOfParent)
    const legalCustody = useSelector(state => state.Form1Reducer.legalCustody)
    const otherRelatives = useSelector(state => state.Form1Reducer.otherRelatives)

    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])

    return (
        <div style={{width:'100%'}} className="pre-call-form-container">
            <h3>Child Adolescent</h3>
            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0">
                    <InputComp 
                        label="Name *" 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        value={Name.value}
                        onChange={(e)=>(
                            OnlyAlphabets(e.target.value) ? 
                                (dispatch(onChangeNameForm1({value:e.target.value,isErr:true})))
                            : 
                                (dispatch(onChangeNameForm1({value:e.target.value,isErr:false})))
                        )}
                        isError={(props.form1NameErr && Name.isNull ) || Name.isErr}
                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
                    />
                    {Name.isErr&&<p style={{marginLeft:10,fontSize:12,color:'red'}}>Name should be only alphabets</p>}
                </Col>
                <Col>
                    <DatePickerComp 
                        onChange={ (date)=>dispatch(onChangeBirthDateForm1({value:getTimeInISO(date).split('T')[0]}))} 
                        value={BirthDate.value&&new Date(BirthDate.value)} 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        maxDate={new Date()}
                        isError={props.form1BirthDateErr && BirthDate.isNull}
                        label="Birthdate *"
                    /> 
                </Col>
                <Col className="pr-0">
                    <InputComp 
                        label="School *" 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        value={SchoolName.value}
                        onChange={(e)=>dispatch(onChangeSchoolNameForm1({value:e.target.value,isErr:true}))}
                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,25)}
                        isError={props.form1SchoolErr && SchoolName.isNull}
                    />
                </Col>
            </Row>
            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0">
                    <InputComp label="Famiy Doctor *" 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        value={familyDoc.value}
                        onChange={(e)=>(
                            OnlyAlphabets(e.target.value) ? 
                                (dispatch(onChangeFamilyDocNameForm1({value:e.target.value,isErr:true})))
                            : 
                                (dispatch(onChangeFamilyDocNameForm1({value:e.target.value,isErr:false})))
                        )}
                        isError={(props.form1FamilyDocErr && familyDoc.isNull) || Name.isErr}
                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
                    />
                    {familyDoc.isErr&&<p style={{marginLeft:10,fontSize:12,color:'red'}}>Family Doc Name should be only alphabets</p>}
                </Col>
                <Col>
                    <InputComp 
                        label="Grade *" 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        value={grade.value}
                        onChange={(e)=>dispatch(onChangeGradeForm1({value:e.target.value}))}
                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,10)}
                        isError={props.form1GradeErr && grade.isNull}
                    />
                </Col>
                <Col className="pr-0">
                    <InputComp 
                        label="Referring Doctor *" 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        value={refferingDoc.value}
                        onChange={(e)=>(
                            OnlyAlphabets(e.target.value) ? 
                                (dispatch(onChangeRefferingDocNameForm1({value:e.target.value,isErr:true})))
                            : 
                                (dispatch(onChangeRefferingDocNameForm1({value:e.target.value,isErr:false})))
                        )}
                        isError={(props.form1ReferingDocErr && refferingDoc.isNull) || refferingDoc.isErr }
                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)} 
                    />
                    {refferingDoc.isErr&&<p style={{marginLeft:10,fontSize:12,color:'red'}}>Family Doc Name should be only alphabets</p>}
                </Col>
            </Row>
            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0">
                <SelectComponent 
                    label="Primary Language at Home *"
                    labelStyle={{fontSize:16,fontWeight:600}}
                    value={primaryLang.value}
                    onChange={(e)=>dispatch(onChangePrimaryLangForm1({value:e,isErr:false}))}
                    options={['Arabic','Chinese','English','French','Français','Greek','German','Italian','Persian','Russian','Swedish','Türk','Urdu','Other']}
                    isError={(props.form1PrimaryLangErr && primaryLang.isNull )}
                />
                    {/* <InputComp 
                        label="Primary Language at Home" 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        value={primaryLang.value}
                        onChange={(e)=>(
                            OnlyAlphabets(e.target.value) ? 
                                (dispatch(onChangePrimaryLangForm1({value:e.target.value,isErr:true})))
                            : 
                                (dispatch(onChangePrimaryLangForm1({value:e.target.value,isErr:false})))
                        )}
                        isError={(props.form1PrimaryLangErr && primaryLang.isNull )|| primaryLang.isErr}
                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,25)}
                    />
                    {primaryLang.isErr&&<p style={{marginLeft:10,fontSize:12,color:'red'}}>Primary Language should be only alphabets</p>} */}
                </Col>
                <Col className="pr-0">
                    <InputComp 
                        label="Other Languages Spoken *" 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        value={otherLang.value}
                        onChange={(e)=>dispatch(onChangeOtherLangForm1({value:e.target.value,isErr:true}))}
                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
                        isError={(props.form1OtherLangErr && otherLang.isNull )}
                    />
                </Col>
            </Row>

            <div className="w-100 pt-5">
                <h4>If your chid’s first language is not English, please complete the following</h4>
            </div>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0">
                    <SelectComponent 
                        label="Child's ﬁrst language"
                        labelStyle={{fontSize:16,fontWeight:600}}
                        value={childFirstLang.value}
                        onChange={(e)=>dispatch(onChangeChildFirstLangForm1({value:e,isErr:false}))}
                        options={['Arabic','Chinese','English','French','Français','Greek','German','Italian','Persian','Russian','Swedish','Türk','Urdu','Other']}
                        // isError={childFirstLang.isErr}
                    />
                    {/* <InputComp 
                        label="Child's ﬁrst language" 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        value={childFirstLang.value}
                        onChange={(e)=>
                            OnlyAlphabets(e.target.value) ? 
                            (dispatch(onChangeChildFirstLangForm1({value:e.target.value,isErr:true})))
                        : 
                            (dispatch(onChangeChildFirstLangForm1({value:e.target.value,isErr:false})))
                        }
                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,25)}
                        isError={childFirstLang.isErr}
                    /> */}
                </Col>
                <Col className="pr-0">
                    <InputComp 
                        type="number" 
                        label="Age when child learned English" 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        value={ageWhenLeargedEng}
                        onChange={(e)=>dispatch(onChangeAgeWhenLearnedEnglishForm1(e.target.value))}
                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,2)}
                        isError={null}
                    />
                </Col>
            </Row>

            <div className="w-100 pt-5">
                <h4>If your chid was not born in Canada, please Complete the following</h4>
            </div>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0">
                    <SelectCountryComp 
                        label="Birth Country" 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        value={birthCountry}
                        onSelect={(val)=>dispatch(onChangeChildBirthCountryForm1(val))}
                    />
                </Col>
                <Col className="pr-0">
                    <InputComp
                        type="number" 
                        label="Age of arrival in Canada" 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        value={ageWhenArrivedCanada}
                        onChange={(e)=>dispatch(onChangeAgeWhenArrivedCanadaForm1(e.target.value))}
                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,2)}
                        isError={null}
                    />
                </Col>
            </Row>


            <div className="w-100 pt-5">
                <h4>Child Lives with ?</h4>
            </div>

            {
                livesWith.map(dt=>{
                    return(
                        <Row className="mx-0 p-0 mt-4">
                            <Col className="pl-0 d-flex align-items-center">
                                <p className="my-0 mr-3" style={{fontWeight:600}}>Parent {dt.parent}</p>
                                <CheckboxComp 
                                    checked={dt.isLived === 'Yes'&&true} 
                                    label="Yes" 
                                    onChange={()=>dispatch(onChangeChildLivesWithForm1({parent:dt.parent,isLived:'Yes'}))}
                                />
                                <CheckboxComp 
                                    checked={dt.isLived === 'No'&&true} 
                                    className="ml-3" 
                                    label="No"
                                    onChange={()=>dispatch(onChangeChildLivesWithForm1({parent:dt.parent,isLived:'No'}))}
                                />
                            </Col>
                        </Row>
                    )
                })
            }

            <div className="w-100 pt-5">
                <h4>Parents/Guardian</h4>
            </div>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0">
                    <InputComp 
                        value={parent1Name.value}
                        label="Parent 1 Name *" 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        // disabled={livesWith[0].isLived === 'No'&&true}
                        onChange={(e)=>
                            OnlyAlphabets(e.target.value) ? 
                            (dispatch(onChangeParent1NameForm1({value:e.target.value,isErr:true})))
                        : 
                            (dispatch(onChangeParent1NameForm1({value:e.target.value,isErr:false})))
                        }
                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
                        isError={(props.form1Parent1NameErr && parent1Name.isNull) || parent1Name.isErr}
                    />
                    {parent1Name.isErr&&<p style={{marginLeft:10,fontSize:12,color:'red'}}>Name should be only alphabets</p>}
                </Col>
                <Col className="pr-0">
                    <InputComp 
                         value={parent2Name.value}
                         label="Parent 2 Name *" 
                         labelStyle={{fontSize:16,fontWeight:600}}
                        //  disabled={livesWith[1].isLived === 'No'&&true}
                         onChange={(e)=>
                             OnlyAlphabets(e.target.value) ? 
                             (dispatch(onChangeParent2NameForm1({value:e.target.value,isErr:true})))
                         : 
                             (dispatch(onChangeParent2NameForm1({value:e.target.value,isErr:false})))
                         }
                         inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
                         isError={(props.form1Parent2NameErr && parent2Name.isNull) || parent2Name.isErr}
                    />
                    {parent2Name.isErr&&<p style={{marginLeft:10,fontSize:12,color:'red'}}>Name should be only alphabets</p>}
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0">
                    <InputComp 
                        value={parent1Age.value}
                        type="number" 
                        label="Age *" 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        onChange={(e)=>dispatch(onChangeParent1AgeForm1({value:e.target.value}))}
                        // disabled={livesWith[0].isLived === 'No'&&true}
                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,2)}
                        isError={props.form1Parent1AgeErr && parent1Age.isNull}
                    />
                </Col>
                <Col className="pr-0">
                    <InputComp 
                        value={parent2Age.value}
                        type="number" 
                        label="Age *" 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        onChange={(e)=>dispatch(onChangeParent2AgeForm1({value:e.target.value}))}
                        // disabled={livesWith[1].isLived === 'No'&&true}
                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,2)}
                        isError={props.form1Parent2AgeErr && parent2Age.isNull}
                    />
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0">
                    <InputComp 
                        value={parent1Occupation}
                        label="Occupation" 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        onChange={(e)=>dispatch(onChangeParent1OccupationForm1(e.target.value))}
                        // disabled={livesWith[0].isLived === 'No'&&true}
                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
                    />
                </Col>
                <Col className="pr-0">
                    <InputComp 
                         value={parent2Occupation}
                         label="Occupation" 
                         labelStyle={{fontSize:16,fontWeight:600}}
                         onChange={(e)=>dispatch(onChangeParent2OccupationForm1(e.target.value))}
                        //  disabled={livesWith[1].isLived === 'No'&&true}
                         inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
                    />
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0">
                    <InputComp 
                        value={parent1Education}
                        label="Education Level" 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        onChange={(e)=>dispatch(onChangeParent1EducationForm1(e.target.value))}
                        // disabled={livesWith[0].isLived === 'No'&&true}
                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,25)}
                    />
                </Col>
                <Col className="pr-0">
                    <InputComp 
                        value={parent2Education}
                        label="Education Level" 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        onChange={(e)=>dispatch(onChangeParent2EducationForm1(e.target.value))}
                        // disabled={livesWith[1].isLived === 'No'&&true}
                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,25)}
                    />
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0">
                    <PhoneNumberComp
                        value={parent1Cell.value}
                        label="Work or Cell *" 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        onChange={(e)=>dispatch(onChangeParent1CellForm1({value:e}))}
                        // disabled={livesWith[0].isLived === 'No'&&true}
                        inputLength={(e)=>e.target.value =  (e.target.value).toString().replace(/ /g,"").slice(0,15)}
                        isError={props.form1Parent1CellErr && parent1Cell.isNull}
                    />
                    {parent1Cell.isLimitErr&&<p style={{marginLeft:10,fontSize:12,color:'red'}}>Number Should be between 8 to 15 digits</p>}
                </Col>
                <Col className="pr-0">
                    <PhoneNumberComp
                        value={parent2Cell.value}
                        label="Work or Cell *" 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        onChange={(e)=>dispatch(onChangeParent2CellForm1({value:e}))}
                        // disabled={livesWith[1].isLived === 'No'&&true}
                        inputLength={(e)=>e.target.value =  (e.target.value).toString().replace(/ /g,"").slice(0,15)}
                        isError={props.form1Parent2CellErr && parent2Cell.isNull}
                    />
                    {parent2Cell.isLimitErr&&<p style={{marginLeft:10,fontSize:12,color:'red'}}>Number Should be between 8 to 15 digits</p>}
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0">
                    <InputComp 
                        value={parent1Email.value}
                        label="Email *" 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        onChange={(e)=>
                            EmailValidation(e.target.value) ? 
                            (dispatch(onChangeParent1EmailForm1({value:e.target.value,isErr:true})))
                        : 
                            (dispatch(onChangeParent1EmailForm1({value:e.target.value,isErr:false})))
                        }
                        // disabled={livesWith[0].isLived === 'No'&&true}
                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
                        isError={(props.form1Parent1EmailErr && parent1Email.isNull) || parent1Email.isErr}
                    />
                    {parent1Email.isErr&&<p style={{marginLeft:10,fontSize:12,color:'red'}}>Email should be in format e.g abc@zyx.com</p>}
                </Col>
                <Col className="pr-0">
                    <InputComp 
                        value={parent2Email.value}
                        label="Email *" 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        onChange={(e)=>
                            EmailValidation(e.target.value) ? 
                            (dispatch(onChangeParent2EmailForm1({value:e.target.value,isErr:true})))
                        : 
                            (dispatch(onChangeParent2EmailForm1({value:e.target.value,isErr:false})))
                        }
                        // disabled={livesWith[1].isLived === 'No'&&true}
                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
                        isError={(props.form1Parent2EmailErr && parent2Email.isNull) || parent2Email.isErr}
                    />
                    {parent2Email.isErr&&<p style={{marginLeft:10,fontSize:12,color:'red'}}>Email should be in format e.g abc@zyx.com</p>}
                </Col>
            </Row>

            
            <div className="w-100 pt-5">
                <h4>Marital Status of Parents/Guardian</h4>
            </div>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0 d-flex align-items-center">
                    {
                        maritalStatusOfParent.map((dt,i)=>{
                            return  <CheckboxComp 
                                        checked={dt.isSelected} 
                                        label={dt.status === 'Married' ? "Married or Common Law" : dt.status} 
                                        onChange={()=>dispatch(onChangeMaritalStatusForm1(dt.status))}
                                        className={i === 0 ? "ml-0" : "ml-3"}
                                    />
                        })
                    }
                </Col>
            </Row>

            <div className="w-100 pt-5">
                <h4>Who has legal custody of the child? (Check one)</h4>
            </div>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0 d-flex align-items-center">
                    {
                        legalCustody.map((dt,i)=>{
                            return  <CheckboxComp 
                                        checked={dt.isSelected} 
                                        label={dt.custody} 
                                        onChange={()=>dispatch(onChangeLegalCustodyForm1(dt.custody))}
                                        className={i === 0 ? "ml-0" : "ml-3"}
                                    />
                        })
                    }
                </Col>
            </Row>

            <div className="w-100 pt-5">
                <h4>Other Important Adults/Family Members (e.g., stepparents, siblings)</h4>
            </div>

            {
                otherRelatives.map(dt=>{
                    return(
                        <Row className="mx-0 p-0 mt-4">
                            <Col className="pl-0">
                                <InputComp 
                                    label="Name" 
                                    labelStyle={{fontSize:16,fontWeight:600}}
                                    value={dt.name.value}
                                    onChange={(e)=> 
                                        OnlyAlphabets(e.target.value) ? 
                                        (dispatch(onChangeRelativeNameForm1({Id:dt.Id,Name:{value:e.target.value,isErr:true}})))
                                    : 
                                        (dispatch(onChangeRelativeNameForm1({Id:dt.Id,Name:{value:e.target.value,isErr:false}})))
                                    }
                                    inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
                                    isError={dt.name.isErr}
                                />
                            </Col>
                            <Col>
                                <InputComp
                                    type="number" 
                                    label="Age"
                                    labelStyle={{fontSize:16,fontWeight:600}}
                                    value={dt.age}
                                    onChange={(e)=>dispatch(onChangeRelativeAgeForm1({value:Number.isNaN(e.target.value) ? 0 : parseInt(e.target.value),Id:dt.Id}))}
                                    inputLength={(e)=>e.target.value = (e.target.value).slice(0,2)}
                                />
                            </Col>
                            <Col>
                                <SelectComponent
                                    value={dt.relationShip}
                                    label="Relationship to your child" 
                                    labelStyle={{fontSize:16,fontWeight:600}}
                                    options={['Brother','Sister']}
                                    onChange={(val)=>dispatch(onChangeRelativeRelationForm1({value:val,Id:dt.Id}))}
                                />
                            </Col>

                            <Col className="pr-0">
                                <p className="m-0" style={{fontWeight:600}}>Lives in the home?</p>
                                <div className="d-flex align-items-center mt-2">
                                    <CheckboxComp 
                                        checked={dt.livesInHome === 'Yes'&&true} 
                                        label="Yes" 
                                        onChange={()=>dispatch(onChangeRelativeLivesInForm1({Id:dt.Id,value:'Yes'}))}
                                    />
                                    <CheckboxComp 
                                        checked={dt.livesInHome === 'No'&&true} 
                                        className="ml-3" 
                                        label="No" 
                                        onChange={()=>dispatch(onChangeRelativeLivesInForm1({Id:dt.Id,value:'No'}))}
                                    />
                                </div>
                            </Col>
                        </Row>
                    )
                })
            }

            <div className="mt-5 d-flex justify-content-center">
                <button className="pre-call-form-btn" onClick={()=>props.onChangeStep(2)}>Next</button>
            </div>


           
        </div>
    )
}

export default PreCallQuestionForm1;
