import React,{useState,useEffect} from 'react'
import {useSelector} from 'react-redux'
import { Table } from 'antd'
import {baseURL} from '../../Config'
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import ClinicianDashboardNavbar from '../../Components/ClinicianDashboardNavbar'
import './CSS/ClinicianAppointments.css'
import { getTimeInLocalString } from '../../TimeFormate'

export default function RBTEearnings() {
  
    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const [loader,setLoader] = useState([{},{},{},{}])
    const [earnings,setEarnings] = useState([])

    const sidebarWidth = useSelector(data => data.SidebarReducer.width)

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })

        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            fetch(baseURL+'/api/getRBTEarnings?Id='+dt.uid,{
                headers:{'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Success'){
                    setEarnings(res2?.message)
                }
            })
        }

        
       
    },[])

    return (
        <div className="bc-dashboard-appointments-container">
            <ParentDashboardSideBar active="earnings" user="RBT" />
            <div style={{marginLeft:windowWidth > 1024 && sidebarWidth}}>
                <ClinicianDashboardNavbar showAppointments={false} active="earnings" user="RBT" />
                <div className="bc-dashboard-appointments-body">
                    <div style={{width:'100%',marginTop:0}}>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems:'flex-end'}}>
                          <h2 style={{fontFamily:'CocoSharp bold'}} className="mb-0">Earnings</h2>
                          <h4 style={{fontFamily:'CocoSharp bold',color:'var(--text-color)'}} className="mb-0">Total Earnings: {earnings?.length > 0 ? earnings.reduce((partialMul, a) => partialMul + a.ammount, 0) : 0}</h4>
                        </div>
                        <Table 
                            columns={[
                              {
                                title: 'Date',
                                dataIndex: 'sessionDateTime',
                                render:(data,dt)=>getTimeInLocalString(dt.sessionDateTime).split(' ')[0] + ' ' + getTimeInLocalString(dt.sessionDateTime).split(' ')[1] + ' ' + getTimeInLocalString(dt.sessionDateTime).split(' ')[2] + ' ' + getTimeInLocalString(dt.sessionDateTime).split(' ')[3]
                              },
                              {
                                title: 'Time',
                                dataIndex: 'sessionDateTime',
                                render:(data,dt)=>getTimeInLocalString(dt.sessionDateTime).split(' ')[4] + ' ' + getTimeInLocalString(dt.sessionDateTime).split(' ')[5]
                              },
                              {
                                title: 'Child',
                                dataIndex: 'childName',
                                render:(data,dt)=> <p>{dt.childName}</p>
                              },
                              {
                                title: 'Earning',
                                dataIndex: 'ammount'
                              }
                            ]}
                            dataSource={earnings}
                            className="rbt-appointments-table"
                        />
                    </div>
                </div>
            </div>
            
        </div>
    )
}


