import React, { useState } from 'react'
import {useHistory} from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import { toast } from 'react-toastify'
import { baseURL, headers } from '../Config'
import './CSS/PackageCard.css'

export default function PackageCard({className,price,title,totalPrice,detail,sessions,childId,approvedAmount,file}) {

    const history = useHistory()
    const [isLoading,setIsLoading] = useState(false)

    const handleBuyPackage = () =>{
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            setIsLoading(true)
            fetch(baseURL + '/api/createCheckout',{
                method:'Post',
                body:JSON.stringify({Id:childId}),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2?.code === 'Success'){
                    setIsLoading(false)
                    window.location.href = res2?.message 
                }
                else{
                    setIsLoading(false)
                    toast.error(res2?.message)
                }
            })
        }
    }

    return (
        <div className={`Package-card-container ${className}`}>
            <div className="Package-card-header">
                <p className="Package-title">Recommended</p>
            </div>
            <div className="Package-card-body">
                <h1>{'$' + price}<span style={{fontFamily:'Montserrat-regular',fontSize:20}}>/session</span></h1>
                <p style={{margin:0,marginTop:5,fontFamily:'CocoSharp bold'}}>Total Amount: { '$' + totalPrice}</p>
                {
                    approvedAmount && approvedAmount > 0 &&
                    <p style={{margin:0,fontFamily:'CocoSharp bold'}}>Approved Amount: { '$' + approvedAmount}</p>
                }
                <p style={{margin:0,fontFamily:'CocoSharp bold'}}>Total Sessions: {sessions}</p>
                <p style={{fontFamily:'CocoSharp bold',margin:0,marginTop:10,textAlign:'left'}}>{title}</p>
                <div dangerouslySetInnerHTML={{__html:detail}}></div>
            </div>
            <div style={{display:'flex',justifyContent:'center',position:'absolute',bottom:20,width:'100%'}}>
            {
                isLoading ?
                    <button className="package-card-buy-btn">
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        {' '}
                        Loading....
                    </button>
                :
                    <button onClick={handleBuyPackage} className="package-card-buy-btn">Buy Package</button>
            }
            </div>
        </div>
    )
}
