import React,{useEffect,useState} from 'react'
import {baseURL, Decrypt, Encrypt, headers} from '../../Config'
import {useSelector,useDispatch} from 'react-redux'
import Skeleton from 'react-loading-skeleton';
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import ClinicianDashboardNavbar from '../../Components/ClinicianDashboardNavbar'
import DashboardClinicianCard from '../../Components/DashboardClinicianCard'
import { toast } from 'react-toastify'
import './CSS/AssignedBCBA.css'
import { Row,Col,Spinner } from 'react-bootstrap'

export default function AssignedBCBA() {

    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const [clinicians,setClinicians] = useState([])
    const [skeletons,setSkeletons] = useState([{},{},{},{},{},{},{},{},{},{}])
    const [isLoading,setIsLoading] = useState(true)

    const sidebarWidth = useSelector(data => data.SidebarReducer.width)

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })

        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            fetch(baseURL+ '/api/getAssignedBCBA?Id=' + dt.uid,{
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    toast.error(res2?.message)
                    setIsLoading(false)
                }
                else{
                    setClinicians(res2.message)
                    setIsLoading(false)
                }
            })
        }
    },[])

    const handleRequest = () =>{
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            fetch(baseURL + '/api/requestBCBA?Id='+dt.uid,{
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    toast.error(res2.message)
                    setIsLoading(false)
                }
                else{
                    setClinicians([res2?.message])
                }
            })
        }
    }

    return (
        <div className="assigned-bcba-page-container">
            <ParentDashboardSideBar active="bcba" user="BC" />
            <div style={{marginLeft:windowWidth > 1024 &&sidebarWidth}}>
                <ClinicianDashboardNavbar active="bcba" user="BC"/>
                <div className="assigned-bcba-page-body">
                    <div style={{width:'100%',display:'flex',justifyContent:'space-between',alignItems:'center',flexWrap:'wrap'}}>
                        <h2 style={{fontFamily:'CocoSharp bold',margin:0}}>BCBA Profile</h2>
                        {
                            clinicians&&clinicians.length === 0 &&
                            <button className="request-bcba-btn" onClick={handleRequest}>Request BCBA</button>
                        }
                    </div>
                    <Row className="mx-0 mt-4">
                        <Col sm={12}className="d-flex align-items-center flex-wrap px-0">
                            {
                                isLoading ?
                                    skeletons.map(dat=>{
                                        return <Skeleton width={210} height={220} style={{marginLeft:0,marginRight:20,marginTop:20,borderRadius:15}}/>
                                    })
                                : 
                                    clinicians&&
                                    clinicians?.length > 0 ?
                                        clinicians.map(dt=>{
                                            
                                            return <DashboardClinicianCard 
                                                        isParent={false}
                                                        name={dt.first_name + ' ' + dt.last_name}
                                                        image={dt.image}
                                                        otherInfo={dt.about}
                                                        Id={dt.uid}
                                                        path={"/therapist-profile-detail/bcba/" + dt.uid}
                                                    />
                                        })
                                    : 
                                        <h4 style={{color:'lightgray',margin:0}}>No Data</h4>
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}
