import home from './icons/home.svg'
import home1 from './icons/home1.svg'
import user from './icons/user.svg'
import user1 from './icons/user1.svg'
import littleKid from './icons/little-kid.svg'
import littleKid1 from './icons/little-kid1.svg'
import logout from './icons/logout.svg'
import parents from './icons/parents.svg'
import parents1 from './icons/parents1.svg'
import appointmentsIcon from './icons/appointments.svg'
import appointmentsIcon1 from './icons/appointments1.svg'
import chat from './icons/chat.svg'
import chat1 from './icons/chat1.svg'
import scheduleIcon from './icons/schedule.svg'
import scheduleIcon1 from './icons/schedule1.svg'
import stethoscope from './icons/stethoscope.svg'
import stethoscope1 from './icons/stethoscope1.svg'
import notes from './icons/notes.svg'
import earningIcon from './icons/earningIcon.svg'
import earningIcon1 from './icons/earningIcon1.svg'
import bcbaIcon from './icons/bcbaIcon.svg'
import bcbaIcon1 from './icons/bcbaIcon1.svg'
import sessionIcon from './icons/sessionIcon.svg'
import sessionIcon1 from './icons/sessionIcon1.svg'
import reports from './icons/reports.svg'
import reports1 from './icons/reports1.svg'

const PatientRoutes = [
    {
        type:'home',
        name:'Home',
        icon:home,
        activeIcon:home1,
        route:'/dashboard-home'
    },
    {
        type:'profile',
        name:'My Profile',
        icon:user,
        activeIcon:user1,
        route:'/perent-account-info'
    },
    {
        type:'child',
        name:'Child',
        icon:littleKid,
        activeIcon:littleKid1,
        route:'/childs'
    },
    {
        type:'appointments',
        name:'Appointments',
        icon:appointmentsIcon,
        activeIcon:appointmentsIcon1,
        route:'/appointments'
    },
    {
        type:'chats',
        name:'Messages',
        icon:chat,
        activeIcon:chat1,
        route:'/parent-chats'
    },
    {
        type:'schedule',
        name:'Schedules',
        icon:scheduleIcon,
        activeIcon:scheduleIcon1,
        route:'/schedule-appointment'
    },
    {
        type:'sessions',
        name:'Sessions',
        icon:sessionIcon,
        activeIcon:sessionIcon1,
        route:'/select-child'
    },
    {
        type:'clinician',
        name:'Behavaiour Consultant',
        icon:stethoscope,
        activeIcon:stethoscope1,
        route:'/assigned-clinician'
    },
    // {
    //     type:'notes',
    //     name:'Notes',
    //     icon:notes,
    //     activeIcon:notes,
    //     route:''
    // },
    {
        type:'logout',
        name:'Logout',
        icon:logout,
        activeIcon:logout,
        route:''
    },
]

const BCBARoutes = [
    {
        type:'home',
        name:'Home',
        icon:home,
        activeIcon:home1,
        route:'/bcba-dashboard'
    },
    {
        type:'profile',
        name:'My Profile',
        icon:user,
        activeIcon:user1,
        route:'/profile-detail'
    },
    // {
    //     type:'child',
    //     name:'Child',
    //     icon:littleKid,
    //     activeIcon:littleKid1,
    //     route:'/childs'
    // },
    {
        type:'parents',
        name:'Parents',
        icon:parents,
        activeIcon:parents1,
        route:'/parents-profiles'
    },
    // {
    //     type:'appointments',
    //     name:'Appointments',
    //     icon:appointmentsIcon,
    //     activeIcon:appointmentsIcon1,
    //     route:'/appointments'
    // },
    {
        type:'sessions',
        name:'Sessions',
        icon:sessionIcon,
        activeIcon:sessionIcon1,
        route:'/bcba-sessions'
    },
    {
        type:'reports',
        name:'Reports',
        icon:reports,
        activeIcon:reports1,
        route:'/select-child'
    },
    {
        type:'chats',
        name:'Messages',
        icon:chat,
        activeIcon:chat1,
        route:'/therapist-chats'
    },
    {
        type:'bc',
        name:'Behavior Consultants',
        icon:stethoscope,
        activeIcon:stethoscope1,
        route:'/behaviour-consultants'
    },
    // {
    //     type:'notes',
    //     name:'Notes',
    //     icon:notes,
    //     activeIcon:notes,
    //     route:''
    // },
    {
        type:'logout',
        name:'Logout',
        icon:logout,
        activeIcon:logout,
        route:''
    },
]

const BCRoutes = [
    {
        type:'home',
        name:'Home',
        icon:home,
        activeIcon:home1,
        route:'/bc-dashboard'
    },
    {
        type:'profile',
        name:'My Profile',
        icon:user,
        activeIcon:user1,
        route:'/profile-detail'
    },
    {
        type:'child',
        name:'Child',
        icon:littleKid,
        activeIcon:littleKid1,
        route:'/childs'
    },
    {
        type:'parents',
        name:'Parents',
        icon:parents,
        activeIcon:parents1,
        route:'/parents-profiles'
    },
    {
        type:'appointments',
        name:'Appointments',
        icon:appointmentsIcon,
        activeIcon:appointmentsIcon1,
        route:'/appointments'
    },
    {
        type:'sessions',
        name:'Sessions',
        icon:sessionIcon,
        activeIcon:sessionIcon1,
        route:'/bc-sessions'
    },
    {
        type:'reports',
        name:'Reports',
        icon:reports,
        activeIcon:reports1,
        route:'/select-child'
    },
    {
        type:'chats',
        name:'Messages',
        icon:chat,
        activeIcon:chat1,
        route:'/therapist-chats'
    },
    {
        type:'bcba',
        name:'Assigned BCBA',
        icon:stethoscope,
        activeIcon:stethoscope1,
        route:'/bc-assigned-bcba'
    },
    // {
    //     type:'notes',
    //     name:'Notes',
    //     icon:notes,
    //     activeIcon:notes,
    //     route:''
    // },
    {
        type:'logout',
        name:'Logout',
        icon:logout,
        activeIcon:logout,
        route:''
    },
]

const RBTRoutes = [
    {
        type:'appointments',
        name:'Appointments',
        icon:appointmentsIcon,
        activeIcon:appointmentsIcon1,
        route:'/rbt-appointments'
    },
    {
        type:'profile',
        name:'My Profile',
        icon:user,
        activeIcon:user1,
        route:'/profile-detail'
    },
    {
        type:'parents',
        name:'Parents',
        icon:parents,
        activeIcon:parents1,
        route:'/parents-profiles'
    },
    {
        type:'earnings',
        name:'Earnings',
        icon:earningIcon,
        activeIcon:earningIcon1,
        route:'/rbt-earnings'
    },
    {
        type:'reports',
        name:'Reports',
        icon:reports,
        activeIcon:reports1,
        route:'/select-child'
    },
    // {
    //     type:'bcba',
    //     name:'BCBA',
    //     icon:bcbaIcon,
    //     activeIcon:bcbaIcon1,
    //     route:'/bcba-profiles'
    // },
    {
        type:'chats',
        name:'Messages',
        icon:chat,
        activeIcon:chat1,
        route:'/therapist-chats'
    },
    {
        type:'logout',
        name:'Logout',
        icon:logout,
        activeIcon:logout,
        route:''
    },
]

export {
    PatientRoutes,
    BCBARoutes,
    RBTRoutes,
    BCRoutes
}