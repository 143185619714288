import React, {useState,useEffect} from 'react';
import {Row, Col, Spinner} from 'react-bootstrap';
import visapic from '../images/VisaCardPic.png';
import {useHistory} from 'react-router-dom';
import './CSS/OrderDetailComponent.css';


export default function OrderDetailComponent({path1,path2,...props}) {

    
    const history = useHistory()
    const [number,setNumber] = useState('')
    
    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])

    return (

            <div className="order-detail-comp-container">
                <Row className="flex-column-reverse flex-md-row">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="order-detail-comp-Sec-1">
                        <h3>$100.00</h3>
                        <h4>Total Aba Joining Fees</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,</p>
                        
                        <div style={{display:'flex',alignItems:'center'}}>
                            <div style={{width:'100%',display:'flex',justifyContent:"space-between",alignItems:'center',padding:'5px 10px 5px 10px',boxShadow:"0px 0px 10px 0px rgba(0,0,0,0.1)",borderRadius:10}}>
                                <div style={{display:'flex',alignItems:'center'}}>
                                    <img className="img-fluid" src={visapic} style={{marginRight:10}} /> 
                                    <p style={{margin:0}}>Visa Debit Card</p>
                                </div>

                                <p style={{margin:0}}>{
                                    number.substr(0,number.length - 4).toString().replace(/0|1|2|3|4|5|6|7|8|9/g,'*') + ' ' +
                                    number.substr(number.length -4 , number.length)
                                }</p>
                            </div>
                        </div>
                        <div className="order-detail-comp-btns-container">
                            {
                                props.showModals ?
                                    <>
                                        {
                                            props.isLoadingSelf ? 
                                                <button className="order-detail-comp-btn">
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    {' '}
                                                    Loading....
                                                </button>
                                            :
                                                <button className="order-detail-comp-btn" onClick={()=>props.onClick1()}>Self Pay</button>
                                        }
                                        {
                                            props.isLoading ?
                                                <button className="order-detail-comp-btn-2">
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    {' '}
                                                    Loading....
                                                </button>
                                            :

                                                <button className="order-detail-comp-btn-2" onClick={()=>props.onClick2()}>Insurance Pay</button>
                                        }
                                    </>
                                :
                                    <>
                                        <button className="order-detail-comp-btn" onClick={()=>history.push(path1)}>Self Pay</button>
                                        <button className="order-detail-comp-btn-2" onClick={()=>history.push(path2)}>Insurance Pay</button>
                                    </>
                            }
                        </div>
                    </Col>
                    {/* <Col xs={12} sm={12} md={4} lg={4} xl={4} className="Payment-Confirm-Page-Sec-2">
                        <div className="Payment-detail">
                            <h6>Order Recap</h6>
                            <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:20}}>
                                <p>Lorem Ipsum</p>
                                <p>$20</p>
                            </div>
                            <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:20}}>
                                <p>Lorem Ipsum</p>
                                <p>$20</p>
                            </div>
                            <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:20}}>
                                <p>Lorem Ipsum</p>
                                <p>$20</p>
                            </div>
                            <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:20}}>
                                <p>Lorem Ipsum</p>
                                <p>$20</p>
                            </div>
                            <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:20}}>
                                <p>Lorem Ipsum</p>
                                <p>$20</p>
                            </div>
                            <hr />

                            <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:10}}>
                                <p>Total</p>
                                <p>$100</p>
                            </div>
                        </div>
                    </Col> */}
                </Row>
            </div>

    );
  }
  

  