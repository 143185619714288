const Form6Data = {
    childFavouriteActivities:'',
    bestWayToHelpChild:'',
    assetsOrStrengths:'',
    mostProudMoment:'',
    otherInformation:'',
    childGender:'Male',
    imageUrl:'',
    documents:[]
}

export default Form6Data;