import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {OnlyAlphabets} from '../../../Validations'
import {
    onChangeIllnessForm3, onChangeCurrentMedicationForm3, onChangeVissionProblemSelectForm3, onChangeVissionProblemForm3,
    onChangeHearingProblemSelectForm3, onChangeHearingProblemForm3, onChangeFamilyMedicationForm3, onChangePreviousAssessmentsForm3,
    onChangePsycatricTreatmentListForm3, onChangeRecentStressorsForm3
} from '../../../Store/Actions/OnBoardingFormActions/Form3Actions'
import InputComp from '../../../Components/InputComp'
import CheckboxComp from '../../../Components/CheckboxComp'
import TextAreaComp from '../../../Components/TextAreaComp'
import DatePickerComp from '../../../Components/DatePickerComp'
import DatePicker from 'react-date-picker';
import {getTimeInISO} from '../../../TimeFormate'
import './CSS/PreCallQuestionForm.css'




function PreCallQuestionForm3(props) {

    const dispatch = useDispatch()


    const illnessOrConditions = useSelector(state => state.Form3Reducer.illnessOrConditions)
    const currentMedication = useSelector(state => state.Form3Reducer.currentMedication)
    const vissionProblemSelect = useSelector(state => state.Form3Reducer.vissionProblemSelect)
    const vissionProblem = useSelector(state => state.Form3Reducer.vissionProblem)
    const hearingProblemSelect = useSelector(state => state.Form3Reducer.hearingProblemSelect)
    const hearingProblem = useSelector(state => state.Form3Reducer.hearingProblem)
    const familyMedication = useSelector(state => state.Form3Reducer.familyMedication)
    const previousAssessments = useSelector(state => state.Form3Reducer.previousAssessments)
    const psycatricTreatmentList = useSelector(state => state.Form3Reducer.psycatricTreatmentList)
    const recentStressors = useSelector(state => state.Form3Reducer.recentStressors)


    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])

    return (
        <div style={{ width: '100%' }} className="pre-call-form-container">
            <h3>MEDICAL HISTORY</h3>
            <p style={{ margin: 0, marginTop: 0, color: '#F99B9B' }}>Place a check next to any illness or condition that your child has had. When you check an item, also note
the age of your child at the time of the illness or condition.</p>

            <HeadingComp
                h4="During pregnancy, did the mother of this child"
            />

            <Row className="mx-0 p-0 mt-5">
                <Col md={3} className="pl-0">
                    <h5 style={{ fontWeight: 600 }}>Illness or condition</h5>
                </Col>
                <Col md={3}>
                    <h5 style={{ fontWeight: 600 }}>Age(s)</h5>
                </Col>
                <Col md={3} className="pl-0">
                    <h5 style={{ fontWeight: 600 }}>Illness or condition</h5>
                </Col>
                <Col md={3} className="pr-0">
                    <h5 style={{ fontWeight: 600 }}>Age(s)</h5>
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-0">
                {
                    illnessOrConditions.map((dt, i) => {
                        return (
                            <>
                                <Col md={2} style={{ marginTop: 30 }} className="pl-0 d-flex align-items-center">
                                    <CheckboxComp
                                        checked={dt.isSelected}
                                        label={dt.illness}
                                        onChange={() => dispatch(
                                            onChangeIllnessForm3({ index: i, data: { illness: dt.illness, age: dt.age, isSelected: !dt.isSelected } })
                                        )}
                                    />
                                </Col>
                                <Col md={4} style={{ marginTop: 30, padding:'0px 30px' }}>
                                    <InputComp
                                        type="Number"
                                        value={dt.age}
                                        label="Age"
                                        labelStyle={{ fontSize: 16, fontWeight: 600 }}
                                        disabled={!dt.isSelected}
                                        inputLength={(e) => e.target.value = (e.target.value).slice(0, 2)}
                                        onChange={(e) => dispatch(
                                            onChangeIllnessForm3({ index: i, data: { illness: dt.illness, age: e.target.value, isSelected: dt.isSelected } })
                                        )}
                                        isError={props.illnessOrCondErr.includes(i) && dt.isSelected && dt.isNull}
                                    />
                                </Col>
                            </>
                        )
                    })
                }
            </Row>


            <HeadingComp
                h4="Current Medications"
                h5="List all medications that your child is currently taking:"
            />

            <table cellPadding={0} id="custom-table" className="w-100 mt-4">
                <tr className="w-100">
                    <th>Medications</th>
                    <th>Reason Taken</th>
                    <th>Dosage (If Known)</th>
                    <th>Start Date</th>
                </tr>
                {
                    currentMedication.map((list, i) => {
                        return (
                            <tr>
                                <td>
                                    <input
                                        value={list.medication}
                                        style={{ border: 'none', width: '100%' }}
                                        onChange={(e) => dispatch(
                                            onChangeCurrentMedicationForm3({
                                                index: i, data: {
                                                    medication: e.target.value,
                                                    reasonTaken: list.reasonTaken,
                                                    dosage: list.dosage,
                                                    startDate: list.startDate
                                                }
                                            })
                                        )}
                                        onInput={(e) => e.target.value = (e.target.value).slice(0, 50)}
                                    />
                                </td>
                                <td>
                                    <input
                                        value={list.reasonTaken}
                                        style={{ border: 'none', width: '100%' }}
                                        onChange={(e) => dispatch(
                                            onChangeCurrentMedicationForm3({
                                                index: i, data: {
                                                    medication: list.medication,
                                                    reasonTaken: e.target.value,
                                                    dosage: list.dosage,
                                                    startDate: list.startDate
                                                }
                                            })
                                        )}
                                        onInput={(e) => e.target.value = (e.target.value).slice(0, 50)}
                                    />
                                </td>
                                <td>
                                    <input
                                        value={list.dosage}
                                        style={{ border: 'none', width: '100%' }}
                                        onChange={(e) => dispatch(
                                            onChangeCurrentMedicationForm3({
                                                index: i, data: {
                                                    medication: list.medication,
                                                    reasonTaken: list.reasonTaken,
                                                    dosage: e.target.value,
                                                    startDate: list.startDate
                                                }
                                            })
                                        )}
                                        onInput={(e) => e.target.value = (e.target.value).slice(0, 25)}
                                    />
                                </td>
                                <td>
                                    <DatePicker
                                        value={list.startDate&&new Date(list.startDate)}
                                        maxDate={new Date()}
                                        closeCalendar={true}
                                        clearIcon={false}
                                        onChange={(e) => dispatch(
                                            onChangeCurrentMedicationForm3({
                                                index: i, data: {
                                                    medication: list.medication,
                                                    reasonTaken: list.reasonTaken,
                                                    dosage: list.dosage,
                                                    startDate: getTimeInISO(e).split('T')[0]
                                                }
                                            })
                                        )}
                                        className="form-3-date-picker-class"
                                        showLeadingZeros
                                    />
                                </td>
                            </tr>
                        )
                    })
                }
            </table>


            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0 d-flex align-items-center">
                    <p className="my-0 mr-3" style={{ fontWeight: 600 }}>Vision Problems</p>
                    <CheckboxComp
                        checked={vissionProblemSelect === 'Yes' && true}
                        label="Yes"
                        onChange={() => dispatch(onChangeVissionProblemSelectForm3('Yes'))}
                    />
                    <CheckboxComp
                        className="ml-3"
                        checked={vissionProblemSelect === 'No' && true}
                        label="No"
                        onChange={() => dispatch(onChangeVissionProblemSelectForm3('No'))}
                    />
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0">
                    <InputComp
                        label="Describe"
                        labelStyle={{ fontSize: 16, fontWeight: 600 }}
                        value={vissionProblem.detail}
                        disabled={vissionProblemSelect === 'No' && true}
                        inputLength={(e) => e.target.value = (e.target.value).slice(0, 120)}
                        onChange={(e) => dispatch(
                            onChangeVissionProblemForm3({ detail: e.target.value, examinationDate: vissionProblem.examinationDate })
                        )}
                        isError={vissionProblemSelect === 'Yes'&&props.form3VissionProbErr&&vissionProblem.isDetailNull}
                    />
                </Col>
                <Col className="pr-0">
                    <DatePickerComp 
                        onChange={ (e)=>dispatch(
                            onChangeVissionProblemForm3({ detail: vissionProblem.detail, examinationDate: getTimeInISO(e).split('T')[0] })
                        )} 
                        value={vissionProblem.examinationDate&&new Date(vissionProblem.examinationDate)} 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        maxDate={new Date()}
                        disabled={vissionProblemSelect === 'No' && true}
                        label="Date of last vision exam"
                        isError={vissionProblemSelect === 'Yes'&&props.form3VissionProbErr&&vissionProblem.isDateNull}
                    /> 
                    {/* <InputComp
                        type="date"
                        label="Date of last vision exam"
                        labelStyle={{ fontSize: 16, fontWeight: 600 }}
                        value={vissionProblem.examinationDate}
                        disabled={vissionProblemSelect === 'No' && true}
                        inputLength={(e) => e.target.value = (e.target.value).slice(0, 20)}
                        onChange={(e) => dispatch(
                            onChangeVissionProblemForm3({ detail: vissionProblem.detail, examinationDate: e.target.value })
                        )}
                        isError={vissionProblemSelect === 'Yes'&&props.form3VissionProbErr&&vissionProblem.isDateNull}
                    /> */}
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0 d-flex align-items-center">
                    <p className="my-0 mr-3" style={{ fontWeight: 600 }}>Hearing Problems</p>
                    <CheckboxComp
                        checked={hearingProblemSelect === 'Yes' && true}
                        label="Yes"
                        onChange={() => dispatch(onChangeHearingProblemSelectForm3('Yes'))}
                    />
                    <CheckboxComp
                        className="ml-3"
                        label="No"
                        checked={hearingProblemSelect === 'No' && true}
                        onChange={() => dispatch(onChangeHearingProblemSelectForm3('No'))}
                    />
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0">
                    <InputComp
                        label="Describe"
                        labelStyle={{ fontSize: 16, fontWeight: 600 }}
                        value={hearingProblem.detail}
                        disabled={hearingProblemSelect === 'No' && true}
                        inputLength={(e) => e.target.value = (e.target.value).slice(0, 120)}
                        onChange={(e) => dispatch(
                            onChangeHearingProblemForm3({ detail: e.target.value, examinationDate: hearingProblem.examinationDate })
                        )}
                        isError={hearingProblemSelect === 'Yes'&&props.form3HearingProbErr&&hearingProblem.isDetailNull}
                    />
                </Col>
                <Col className="pr-0">
                    <DatePickerComp 
                        onChange={ (e)=>dispatch(
                            onChangeHearingProblemForm3({ detail: hearingProblem.detail, examinationDate: getTimeInISO(e).split('T')[0] })
                        )} 
                        value={hearingProblem.examinationDate&&new Date(hearingProblem.examinationDate)} 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        maxDate={new Date()}
                        disabled={hearingProblemSelect === 'No' && true}
                        label="Date of last hearing exam"
                        isError={hearingProblemSelect === 'Yes'&&props.form3HearingProbErr&&hearingProblem.isDateNull}
                    /> 
                    {/* <InputComp
                        type="date"
                        label="Date of last hearing exam"
                        labelStyle={{ fontSize: 16, fontWeight: 600 }}
                        value={hearingProblem.examinationDate}
                        disabled={hearingProblemSelect === 'No' && true}
                        inputLength={(e) => e.target.value = (e.target.value).slice(0, 20)}
                        onChange={(e) => dispatch(
                            onChangeHearingProblemForm3({ detail: hearingProblem.detail, examinationDate: e.target.value })
                        )}
                        isError={hearingProblemSelect === 'Yes'&&props.form3HearingProbErr&&hearingProblem.isDateNull}
                    /> */}
                </Col>
            </Row>

            <HeadingComp
                h4="FAMILY MEDICAL HISTORY"
                h5="Place a check next to any illness or condition that any member of the immediate family (i.e., brothers,
                    sisters, aunts, uncles, cousins, grandparents) has had. Please note the family member's relationship to
                    the child."
            />

            <Row className="mx-0 p-0 mt-5">
                <Col className="pl-0">
                    <h5 style={{ fontWeight: 600 }}>Condition</h5>
                </Col>
                <Col>
                    <h5 style={{ fontWeight: 600 }}>Relationship to child</h5>
                </Col>
                <Col>
                    <h5 style={{ fontWeight: 600 }}>Condition</h5>
                </Col>
                <Col className="pr-0">
                    <h5 style={{ fontWeight: 600 }}>Relationship to child</h5>
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-1">
                {
                    familyMedication.map((data, i) => {
                        return (
                            <>
                                <Col md={2} style={{ marginTop: 30 }} className="pl-0 d-flex align-items-center">
                                    <CheckboxComp
                                        checked={data.isSelected}
                                        label={data.illness}
                                        onChange={() => dispatch(
                                            onChangeFamilyMedicationForm3({ index: i, data: { illness: data.illness, detail: data.detail, isSelected: !data.isSelected } })
                                        )}
                                    />
                                </Col>
                                <Col md={4} style={{ marginTop: 30 }}>
                                    <InputComp
                                        label="Type Here"
                                        labelStyle={{ fontSize: 16, fontWeight: 600 }}
                                        value={data.detail}
                                        disabled={!data.isSelected}
                                        inputLength={(e) => e.target.value = (e.target.value).slice(0, 30)}
                                        onChange={(e) => dispatch(
                                            onChangeFamilyMedicationForm3({ index: i, data: { illness: data.illness, detail: e.target.value, isSelected: data.isSelected } })
                                        )}
                                        isError={props.form3FamilyMedicationErr.includes(i) && data.isSelected && data.isNull}
                                    />
                                </Col>
                            </>
                        )
                    })
                }
            </Row>

            <HeadingComp
                h4="PREVIOUS ASSESSMENTS AND INTERVENTIONS"
                h5="List if any previous assessments that your child has had:"
            />

            <Row className="mx-0 p-0 mt-5">
                <Col md={2} className="pl-0">
                </Col>
                <Col md={4}>
                    <h5 style={{ fontWeight: 600 }}>Date(s) of Testing</h5>
                </Col>
                <Col md={6} className="pr-0">
                    <h5 style={{ fontWeight: 600 }}>Name of Examiner</h5>
                </Col>
            </Row>


            {
                previousAssessments.map((dt, i) => {
                    return (
                        <Row className="mx-0 p-0 mt-4">
                            <Col md={2} className="pl-0 d-flex align-items-center">
                                <p className="my-0 mr-3" style={{ fontWeight: 600 }}>{dt.assessment}</p>
                            </Col>
                            <Col md={4} className="d-flex align-items-center">
                                <DatePickerComp 
                                    onChange={ (date)=>dispatch(
                                        onChangePreviousAssessmentsForm3(
                                            { index: i, data: 
                                                { 
                                                    assessment: dt.assessment, 
                                                    dateOfTesting: getTimeInISO(date).split('T')[0], 
                                                    nameOfExaminer: dt.nameOfExaminer,
                                                    isErr:false
                                                } 
                                            }
                                        )
                                    )} 
                                    value={dt.dateOfTesting&&new Date(dt.dateOfTesting)} 
                                    labelStyle={{fontSize:16,fontWeight:600}}
                                    maxDate={new Date()}
                                    label="Type Here"
                                /> 
                            </Col>
                            <Col md={6} className="pr-0">
                                <InputComp
                                    label="Type Here"
                                    labelStyle={{ fontSize: 16, fontWeight: 600 }}
                                    value={dt.nameOfExaminer}
                                    inputLength={(e) => e.target.value = (e.target.value).slice(0, 50)}
                                    onChange={(e) =>
                                        OnlyAlphabets(e.target.value) ?
                                            dispatch( onChangePreviousAssessmentsForm3(
                                                { index: i, data: 
                                                    { 
                                                        assessment: dt.assessment, 
                                                        dateOfTesting: dt.dateOfTesting, 
                                                        nameOfExaminer: e.target.value,
                                                        isErr:true
                                                    } 
                                                }
                                            ))
                                        :
                                        dispatch( onChangePreviousAssessmentsForm3(
                                            { index: i, data: 
                                                { 
                                                    assessment: dt.assessment, 
                                                    dateOfTesting: dt.dateOfTesting, 
                                                    nameOfExaminer: e.target.value,
                                                    isErr:false
                                                } 
                                            }
                                        ))
                                    }

                                    isError={dt.isErr}
                                />
                                 {dt.isErr&&<p style={{marginLeft:10,fontSize:12,color:'red'}}>Name should be only alphabets</p>}
                            </Col>
                        </Row>
                    )
                })
            }


            <div className="w-100 pt-5">
                <h5>List any psychological/psychiatric treatment that your child has had (e.g., psychotherapy, family therapy,
                    inpatient or residential treatment)</h5>
            </div>


            <table className="w-100 mt-4" id="custom-table2">
                <tr className="w-100">
                    <th>Type of Treatment</th>
                    <th>Dates</th>
                    <th>Name of therapist</th>
                </tr>
                {
                    psycatricTreatmentList.map((list, i) => {
                        return (
                            <tr>
                                <td>
                                    <input
                                        style={{ border: 'none', width: '100%' }}
                                        value={list.typeOfTreatment}
                                        onInput={(e) => e.target.value = (e.target.value).slice(0, 30)}
                                        onChange={(e) => dispatch(
                                            onChangePsycatricTreatmentListForm3({
                                                index: i,
                                                data: {
                                                    typeOfTreatment: e.target.value,
                                                    dateOfTreatment: list.dateOfTreatment,
                                                    nameOfTherapist: list.nameOfTherapist
                                                }
                                            })
                                        )}
                                    />
                                </td>
                                <td>
                                <DatePicker
                                        value={list.dateOfTreatment&&new Date(list.dateOfTreatment)}
                                        maxDate={new Date()}
                                        closeCalendar={true}
                                        clearIcon={false}
                                        onChange={(e) => dispatch(
                                            onChangePsycatricTreatmentListForm3({
                                                index: i,
                                                data: {
                                                    typeOfTreatment: list.typeOfTreatment,
                                                    dateOfTreatment: getTimeInISO(e).split('T')[0],
                                                    nameOfTherapist: list.nameOfTherapist
                                                }
                                            })
                                        )}
                                        className="form-3-date-picker-class"
                                        showLeadingZeros
                                    />
                                </td>
                                <td>
                                    <input
                                        style={{ border: 'none', width: '100%' }}
                                        value={list.nameOfTherapist}
                                        onInput={(e) => e.target.value = (e.target.value).slice(0, 50)}
                                        onChange={(e) => dispatch(
                                            onChangePsycatricTreatmentListForm3({
                                                index: i,
                                                data: {
                                                    typeOfTreatment: list.typeOfTreatment,
                                                    dateOfTreatment: list.dateOfTreatment,
                                                    nameOfTherapist: e.target.value
                                                }
                                            })
                                        )}
                                    />
                                </td>
                            </tr>
                        )
                    })
                }
            </table>


            <div className="w-100 pt-5">
                <h5>Have there been any recent stressors that you think may be contributing to your child’s difﬁculties (e.g.,
                    illness, deaths, operations, accidents, separations, divorce of parents, parent changed job, changed
                    schools, family moved, family ﬁnancial problems, remarriage, other losses)</h5>
            </div>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pr-0 pl-0">
                    <TextAreaComp
                        label="Type Here"
                        labelStyle={{ fontSize: 16, fontWeight: 600 }}
                        value={recentStressors}
                        inputLength={(e) => e.target.value = (e.target.value).slice(0, 800)}
                        onChange={(e) => dispatch(
                            onChangeRecentStressorsForm3(e.target.value)
                        )}
                    />
                </Col>
            </Row>

            <div className="mt-5 d-flex justify-content-center">
                <button className="pre-call-form-btn" onClick={() => props.onChangeStep(4)}>Next</button>
            </div>
        </div>
    )
}

const HeadingComp = (props) => {
    return (
        <>
            <div className="w-100 pt-5">
                <h4>{props.h4}</h4>
            </div>
            <div className="w-100 pt-1">
                <h5>{props.h5}</h5>
            </div>
        </>
    )
}

export default PreCallQuestionForm3;