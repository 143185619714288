import React,{useState,useEffect} from 'react'
import './CSS/InputComp2.css'

const InputComp2 = (props) => {

    const [focus,setFocus] = useState(false)
    return (
      <fieldset className={`${props.isError ? "fld_set_err1" : focus ? "fld_set_fcs1" : "fld_set1"} ${props.className}`} style={props.containerStyle}>
        <input 
            onFocus={()=>setFocus(true)} 
            onBlur={()=>(setFocus(false))}
            type={props.type || 'text'} 
            className="input1 form-control" 
            onChange={(e)=>props.onChange(e)}
            onInput={(e)=>props.inputLength(e)}
            value={props.value}
            disabled={props.disabled || false}
            max={props.type === 'date'? !props.min&&new Date().toISOString().split('T')[0]  : null}
            min={props.type === 'date'? props.min : "0"}
            onKeyPress={props.type === 'date'? (e)=>{ 
                e.preventDefault()
                return false} : null}
        />
      </fieldset>
    )
}

export default InputComp2