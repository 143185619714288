import React,{useState,useEffect} from 'react'
import {useSelector,useDispatch} from 'react-redux'
import { Spinner } from 'react-bootstrap'
import {useHistory} from 'react-router-dom'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import {baseURL, Encrypt, headers} from '../../Config'
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import SelectDate from '../../Components/SelectDate'
import ParentDashboardNavbar from '../../Components/ParentDashboardNavbar'
import { toast } from 'react-toastify'
import './CSS/SelectAvailability.css'
import moment from 'moment';
import { convertIntoUtc } from '../../TimeFormate';


export default function SelectAvailability(props) {

    const history = useHistory()
    const dispatch = useDispatch()

    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const [loader,setLoader] = useState([{},{},{},{}])
    const [startTime,setStartTime] = useState('')
    const [endTime,setEndTime] = useState('')
    const [endTimeError,setEndTimeError] = useState('')
    const [disabledTo,setDisabledTo] = useState(true)
    const [isLoading,setIsLoading] = useState(false)

    const sidebarWidth = useSelector(data => data.SidebarReducer.width)

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })
    },[])

    const handleSetStartTime = (date) =>{
        date&&
        setStartTime(date)
        setDisabledTo(false)
    }

    const handleSetEndTime = (date) =>{
        if(startTime !== ''){
            const time = moment(date)
            const stTime = moment(startTime)

            if (time.diff(stTime) > 11) {
                setEndTime(date)
                setEndTimeError('')
            } else {
                setEndTimeError('End time should be greater than start time')
            }
        }
        else{
            toast.error('Please select start time first')
        }
    }


    const handlePay = () =>{
        if(endTime && startTime){
            const etime = moment(endTime)
            const stTime = moment(startTime)
       
            if (etime.diff(stTime) > 11) {

                const start_time = convertIntoUtc(startTime)
                const end_time = convertIntoUtc(endTime)

                const dt = JSON.parse(localStorage.getItem('bx_user_token'))
                if(dt){
                    setIsLoading(true)
                    const obj = {
                        childId:props.match.params.childId,
                        packageId:props.match.params.packageId,
                        startTime:start_time,
                        endTime:end_time,
                        parentId:dt.uid
                    }
                    fetch(baseURL + '/api/createSessions',{
                        method:'Post',
                        body:JSON.stringify(obj),
                        headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
                    })
                    .then(res=>res.json())
                    .then(res2=>{
                        if(res2?.code === 'Error') {
                            toast.error(res2?.message,{toastId:'set-availability-id-3',autoClose:3000})
                            setIsLoading(false)
                        }
                        else {
                            setIsLoading(false)
                            toast.error(res2?.message,{toastId:'set-availability-id-4',autoClose:3000})
                            history.push('/child-sessions/'+props.match.params.childId)
                            
                        }
                    })
                    .catch(err=>setIsLoading(false))
                }
            }else {
                toast.error('End time should be greater than start time',{toastId:'set-availability-id-1',autoClose:3000})
            }
        }
        else{
            toast.error('Start time & End time should not be null',{toastId:'set-availability-id-2',autoClose:3000})
        }
    }


    return (
        <div className="parent-dashboard-set-availability-container">
            <ParentDashboardSideBar active="sessions" />
            <div style={{marginLeft:windowWidth > 1024 && sidebarWidth}}>
                <ParentDashboardNavbar active="sessions" showAppointments={true} />
                <div className="parent-dashboard-set-availability-body">
                    <div style={{width:'100%',marginTop:0}}>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems:'flex-end'}}>
                            <h2 style={{fontFamily:'CocoSharp bold'}} className="mb-0">Time Availability</h2>
                        </div>
                        <div className='parent-dashboard-set-availability-clocks-container'>
                            <div>
                                <h5 style={{fontFamily:'CocoSharp bold',color:'var(--text-color)'}} className="mb-1 ml-3">From</h5>
                                <div className='parent-dashboard-set-availability-clock'>   
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <StaticTimePicker
                                            ampm
                                            orientation={windowWidth < 425 ? "portrait" : "landscape"}
                                            openTo="hours"
                                            minutesStep={0}
                                            value={startTime}
                                            onChange={handleSetStartTime}
                                            renderInput={(params) => <div {...params} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div>
                                <h5 style={{fontFamily:'CocoSharp bold',color:'var(--text-color)'}} className="mb-1 ml-3">To</h5>
                                <div className={!disabledTo ? 'parent-dashboard-set-availability-clock' : 'parent-dashboard-set-availability-clock disabled'}>    
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <StaticTimePicker
                                            ampm
                                            orientation={windowWidth < 425 ? "portrait" : "landscape"}
                                            openTo="hours"
                                            minutesStep={0}
                                            value={endTime}
                                            onChange={handleSetEndTime}
                                            renderInput={(params) => <div {...params} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <p style={{margin:0,fontSize:11,color:'red'}} className="mt-1 ml-3">{endTimeError}</p>
                            </div>
                        </div>
                        {
                            isLoading ?
                                <button className="parent-dashboard-set-availability-confirm-btn" style={{marginTop:30,height:55}}>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    {' '}
                                    Loading....
                                </button>
                            :
                                <button className="parent-dashboard-set-availability-confirm-btn" onClick={handlePay}>Confirm</button>
                        }
                    </div>
                </div>
            </div>
            
        </div>
    )
}
