import React from 'react'
import './CSS/SideStepBar.css'

export default function SideStepBar(props) {


    return (
        <div className="pre-call-questions-side-step-bar">
            <div id="side-step-bar-option-1" className={`d-flex flex-column justify-content-center align-items-center side-step-bar-option ${props.isActive === 1&&'active'}`} onClick={()=>props.onChangeStep(1)}>
                {props.isActive === 1&&<p style={{color:props.form1Err&&'red'}} className="m-0">Step</p>}
               <p style={{color:props.form1Err&&'red'}} className="m-0">1</p>
            </div>
            <div id="side-step-bar-option-2" className={`d-flex flex-column justify-content-center align-items-center side-step-bar-option ${props.isActive === 2&&'active'}`} onClick={()=>props.onChangeStep(2)}>
                {props.isActive === 2&&<p className="m-0">Step</p>}
                <p className="m-0">2</p>
            </div>
            <div id="side-step-bar-option-3" className={`d-flex flex-column justify-content-center align-items-center side-step-bar-option ${props.isActive === 3&&'active'}`} onClick={()=>props.onChangeStep(3)}>
                {props.isActive === 3&&<p style={{color:props.form3Err&&'red'}} className="m-0">Step</p>}
                <p style={{color:props.form3Err&&'red'}} className="m-0">3</p>
            </div>
            <div id="side-step-bar-option-4" className={`d-flex flex-column justify-content-center align-items-center side-step-bar-option ${props.isActive === 4&&'active'}`} onClick={()=>props.onChangeStep(4)}>
                {props.isActive === 4&&<p className="m-0">Step</p>}
                <p className="m-0">4</p>
            </div>
            <div id="side-step-bar-option-5" className={`d-flex flex-column justify-content-center align-items-center side-step-bar-option ${props.isActive === 5&&'active'}`} onClick={()=>props.onChangeStep(5)}>
                {props.isActive === 5&&<p className="m-0">Step</p>}
                <p className="m-0">5</p>
            </div>
            <div id="side-step-bar-option-6" className={`d-flex flex-column justify-content-center align-items-center side-step-bar-option ${props.isActive === 6&&'active'}`} onClick={()=>props.onChangeStep(6)}>
                {props.isActive === 6&&<p style={{color:props.form6Err&&'red'}} className="m-0">Step</p>}
                <p style={{color:props.form6Err&&'red'}} className="m-0">6</p>
            </div>
        </div>
    )
}

