import React from "react";
import HeroSection from "./Hero";
import Refer from "./Refer";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Informed from "../../Components/Informed";

function Pediatricians() {
  return (
    <div>
      <Header/>
      <HeroSection />
      <Section2 />
      <Section3 />
      <Refer />
      <Informed />
      <Footer />
    </div>
  );
}

export default Pediatricians;
