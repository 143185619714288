import React,{useState,useEffect} from 'react'
import {useSelector} from 'react-redux'
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import ParentDashboardNavbar from '../../Components/ParentDashboardNavbar'
import VideoComp from '../../Components/VideoComp'
import './CSS/WatchVideo.css'
import { Decrypt } from '../../Config'

export default function WatchVideo(props) {
    const obj =  JSON.parse(Decrypt(props.match.params.comp))

    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const sidebarWidth = useSelector(data => data.SidebarReducer.width)

    const [title,setTitle] = useState(obj&&obj.title)
    const [des,setDes] = useState(obj&&obj.des)
    const [url,setUrl] = useState(obj&&obj.url)
    const [comp,setComp] = useState(obj&&obj.comp)

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })

    },[])

    return (
        <div className="watch-video-container">
            <ParentDashboardSideBar active="home" />
            <div style={{marginLeft:windowWidth > 1024 && sidebarWidth}}>
                <ParentDashboardNavbar />
                <div className="watch-video-body">
                    <h1 className="mb-0">{
                            comp == 0 ? 'Instructions & Guidance'
                            : comp == 1 ? 'Additional Resources' 
                            : null
                    }</h1>
                    <h5 className="mt-0 mb-4">You can view the training material.</h5>
                    <div style={{width:'100%'}}>
                        <VideoComp 
                            url={url}
                        />
                    </div>
                    <h3 style={{marginTop:25,marginBottom:0}}>Title</h3>
                    <p style={{marginTop:0,fontSize:18}}>{title}</p>

                    <h3 style={{margin:0,marginTop:25}}>Description</h3>
                    <p style={{marginTop:0,fontSize:18}}>{des}</p>
                </div>
            </div>
        </div>
    )
}

