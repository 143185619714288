export const onChangeIllnessForm3 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeIllnessForm3',payload:data})
    }
}


export const onChangeCurrentMedicationForm3 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeCurrentMedicationForm3',payload:data})
    }
}


export const onChangeVissionProblemSelectForm3 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeVissionProblemSelectForm3',payload:data})
    }
}


export const onChangeVissionProblemForm3 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeVissionProblemForm3',payload:data})
    }
}


export const onChangeHearingProblemSelectForm3 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeHearingProblemSelectForm3',payload:data})
    }
}


export const onChangeHearingProblemForm3 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeHearingProblemForm3',payload:data})
    }
}


export const onChangeFamilyMedicationForm3 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeFamilyMedicationForm3',payload:data})
    }
}


export const onChangePreviousAssessmentsForm3 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangePreviousAssessmentsForm3',payload:data})
    }
}


export const onChangePsycatricTreatmentListForm3 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangePsycatricTreatmentListForm3',payload:data})
    }
}


export const onChangeRecentStressorsForm3 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeRecentStressorsForm3',payload:data})
    }
}