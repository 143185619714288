export const onChangeLearningProgramForm5 = (data) => {
    return (dispatch) => {
        dispatch({ type: 'onChangeLearningProgramForm5', payload: data })
    }
}


export const onChangeEducationPlanForm5 = (data) => {
    return (dispatch) => {
        dispatch({ type: 'onChangeEducationPlanForm5', payload: data })
    }
}


export const onChangeCurrentLearningProgramForm5 = (data) => {
    return (dispatch) => {
        dispatch({ type: 'onChangeCurrentLearningProgramForm5', payload: data })
    }
}


export const onChangeHeldBackGradeForm5 = (data) => {
    return (dispatch) => {
        dispatch({ type: 'onChangeHeldBackGradeForm5', payload: data })
    }
}


export const onChangeSpecialEducationForm5 = (data) => {
    return (dispatch) => {
        dispatch({ type: 'onChangeSpecialEducationForm5', payload: data })
    }
}


export const onChangeLearningAssistanceForm5 = (data) => {
    return (dispatch) => {
        dispatch({ type: 'onChangeLearningAssistanceForm5', payload: data })
    }
}


export const onChangeSuspendedOrExpelledForm5 = (data) => {
    return (dispatch) => {
        dispatch({ type: 'onChangeSuspendedOrExpelledForm5', payload: data })
    }
}


export const onChangeReceivedTutoringForm5 = (data) => {
    return (dispatch) => {
        dispatch({ type: 'onChangeReceivedTutoringForm5', payload: data })
    }
}


export const onChangeSchoolProblemsForm5 = (data) => {
    return (dispatch) => {
        dispatch({ type: 'onChangeSchoolProblemsForm5', payload: data })
    }
}