import React from "react";
import img from "../../assets/team/team8.png";
function Joanah() {
  return (
    <div className="bg-[#FFEEEF] w-full flex justify-center items-center py-11">
      <div className="max-w-[1440px] flex justify-between flex-col-reverse lg:flex-row items-center px-10 w-full gap-10">
        <img src={img} alt="" />
        <div className="flex justify-center flex-col items-start gap-1">
          <h1 className="font-bold text-lightGreen-500 text-3xl md:text-5xl">
            Joanna Smieja
          </h1>
          <h2 className="font-bold text-lightGreen-500 text-xl md:text-2xl">
            MSc. ABA, BCBA
          </h2>
          <h4 className="font-bold text-lightGreen-500 text-md md:text-xl">
            Behavior Analyst - Clinical Supervisor
          </h4>
          <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
            Joanna is a highly skilled and dedicated professional with a wealth of experience in autism treatment.
            With a deep passion for working with children and a commitment to making a positive impact in their lives, Joanna has gained valuable experience throughout her career.
          </p>
          <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
            She has worked diligently to expand her education her education and qualifications, earning a degree in psychology from California State University and a Masters in ABA from the University of Bangor.
            Additionally, she is a Board Certified Behavior Analyst. She’s currently pursuing her PhD.
          </p>
          <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
            Joanna’s true passion lies in working with children with autism. She brings a compassionate and patient approach to her work,
            understanding the unique needs and challenges faced by these individuals and their families.
          </p>
          <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
            Through her dedication, qualifications, and unwavering commitment to working with children with autism,
            Joanna is making a significant difference in the lives of the individuals she serves. Her combination of psychological expertise, ABA knowledge, and genuine passion
            creates a strong foundation for positively impacting the development and well-being of children with autism.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Joanah;
