import React,{useEffect} from 'react'
import {Route,Redirect} from 'react-router-dom'
import { Decrypt } from './Config'

export const ProtectedRoute = ({component:Component,...rest}) => {
    const data = JSON.parse(window.localStorage.getItem('bx_user_token'))
    return (
        <Route
            {...rest}
            render={(props)=>{
                if(data&&(Decrypt(data.userType) === 'Parent' && data.accessToken)){
                    return <Component to={props.match.path} match={props.match} location={props.location}/>
                }
                else{
                    return <Redirect to="/login"/>
                }
            }}
        />
    )
}


export const ProtectedRoute1 = ({component:Component,...rest}) => {
    const data = JSON.parse(window.localStorage.getItem('bx_user_token'))
    return (
        <Route
            {...rest}
            render={(props)=>{
                if(data&&((Decrypt(data.userType) === 'BCBA' || Decrypt(data.userType) === 'BC' || Decrypt(data.userType) === 'RBT')  && data.accessToken)){
                    return <Component to={props.match.path} match={props.match} location={props.location}/>
                }
                else { return <Redirect to="/login"/>}
            }}
        />
    )
}


export const CommonProtectedRoute = ({component:Component,...rest}) => {
    const data = JSON.parse(window.localStorage.getItem('bx_user_token'))
    return (
        <Route
            {...rest}
            render={(props)=>{
                if(data&&((Decrypt(data.userType) === 'Parent' || Decrypt(data.userType) === 'BCBA' || Decrypt(data.userType) === 'BC' || Decrypt(data.userType) === 'RBT')  && data.accessToken)){
                    return <Component to={props.match.path} match={props.match} location={props.location}/>
                }
                else{
                    return <Redirect to="/login"/>
                }
            }}
        />
    )
}
