import React,{useEffect,useState} from 'react'
import Skeleton from 'react-loading-skeleton';
import {useSelector,useDispatch} from 'react-redux'
import {useLocation} from 'react-router-dom'
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import ParentDashboardNavbar from '../../Components/ParentDashboardNavbar'
import './CSS/DashboardHomePage.css'
import DashboardHomeCard from '../../Components/DashboardHomeCard'
import SelfPayModal from '../../Components/SelfPayModal'
import {GetParentData} from '../../Store/Actions/ParentActions'
import {GetParentAppointments} from '../../Store/Actions/AppointmentActions'
import {GetNotifications} from '../../Store/Actions/NotificationActions'
import childImage from '../../images/db_child_image.png'
import instImage from '../../images/dashboardInstructionImage.png'
import trainingProImage from '../../images/dashboardTrainingProImage.png'
import dashboardResource from '../../images/dashboard-resource-img.png'
import dashboardTherapaticImg from '../../images/dashboard-therapatic.png'
import Img4 from '../../images/clinician-db-img4.png'
import { baseURL,headers } from '../../Config';
import { toast } from 'react-toastify';

export default function DashboardHomePage(props) {

    const dispatch = useDispatch()
    const location = useLocation()

    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const [parentStatus,setParentStatus] = useState(window.innerWidth)
    const [isLoading,setIsLoading] = useState(true)
    const [showModal,setShowModal] = useState(false)
    const [isLoadingModalBtn,setIsLoadingModalBtn] = useState(false)

    const sidebarWidth = useSelector(data => data.SidebarReducer.width)
    const parentData = useSelector(data => data.ParentReducer.parentData)
    const isPay = useSelector(data => data.ParentReducer.isPay)


    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })

        dispatch(GetParentData())
        dispatch(GetParentAppointments())
        dispatch(GetNotifications())
    },[])

    useEffect(()=>{
        if(Object.entries(parentData).length > 0){
            setParentStatus(parentData.status)
            setIsLoading(false)
        }
    },[parentData])

    useEffect(()=>{
        location?.state?.reload && 
        window.location.reload()
    },[location])


    useEffect(()=>{
        setShowModal(isPay?.showPayment)
    },[isPay])


    const handleConfirmSelfPay = () =>{
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))

        if(dt){
            setIsLoadingModalBtn(true)
            fetch(baseURL + '/api/payRemainingAmount',{
                method:'Post',
                body:JSON.stringify({Id:isPay?.childId}),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Success'){
                    setIsLoadingModalBtn(false)
                    setShowModal(false)
                    toast.error('Payment Successful',{toastId:'pay-rem-amount-success-id1',autoClose:3000})
                }
                else{
                    setIsLoadingModalBtn(false)
                    toast.error(res2.message,{toastId:'pay-rem-amount-err-id1',autoClose:3000})
                }
            })
        }
    }

    return (
        <div className="parent-dashboard-home">
            <ParentDashboardSideBar active="home"  />
            <div style={{marginLeft:windowWidth > 1024 &&sidebarWidth}}>
                <ParentDashboardNavbar />
                {
                    isLoading ?
                        <div className="parent-dashboard-home-body">
                            <Skeleton width={windowWidth > 991 ? '20vw' : windowWidth > 2100 ? '18.5vw' : '300px'} height={200} style={{borderRadius:10,marginTop:30}} />
                            <Skeleton width={windowWidth > 991 ? '20vw' : windowWidth > 2100 ? '18.5vw' : '300px'} height={200} style={{borderRadius:10,marginTop:30}} />
                            <Skeleton width={windowWidth > 991 ? '20vw' : windowWidth > 2100 ? '18.5vw' : '300px'} height={200} style={{borderRadius:10,marginTop:30}} />
                            <Skeleton width={windowWidth > 991 ? '20vw' : windowWidth > 2100 ? '18.5vw' : '300px'} height={200} style={{borderRadius:10,marginTop:30}} />
                            <Skeleton width={windowWidth > 991 ? '20vw' : windowWidth > 2100 ? '18.5vw' : '300px'} height={200} style={{borderRadius:10,marginTop:30}} />
                            <Skeleton width={windowWidth > 991 ? '20vw' : windowWidth > 2100 ? '18.5vw' : '300px'} height={200} style={{borderRadius:10,marginTop:30}} />
                        </div>
                    :
                        <div className="parent-dashboard-home-body">
                            <DashboardHomeCard
                                title="Child Profile"
                                buttonTitle="View Profile"
                                imageUrl={childImage}
                                path="/childs" 
                            />
                            <DashboardHomeCard
                                title="Instructions & Guidance"
                                buttonTitle="View"
                                imageUrl={instImage}
                                path="/instructions-and-guidance" 
                            />
                            <DashboardHomeCard
                                title="Resources "
                                buttonTitle="Explore Now"
                                imageUrl={dashboardResource}
                                path="/additional-resources" 
                            />
                            <DashboardHomeCard
                                title="Appointments"
                                buttonTitle="View"
                                imageUrl={Img4}
                                row="2"
                                path="/appointments"
                            />
                            <DashboardHomeCard
                                title="Parent Training"
                                buttonTitle="Enroll Now"
                                imageUrl={trainingProImage}
                                row="2"
                                isDisable={parentStatus <=1&&true}
                                // path="/" 
                            />
                            {/* <DashboardHomeCard
                                title="Therapeutic Support"
                                buttonTitle="View Support"
                                imageUrl={dashboardTherapaticImg}
                                row="2"
                                isDisable={parentStatus <=1&&true}
                                // path="/" 
                            /> */}
                            <DashboardHomeCard
                                title=""
                                buttonTitle=""
                                imageUrl=""
                                row="2"
                                display="none"
                                style={{backgroundColor:'transparent',border:'none',boxShadow:'none' }}
                                // path="/" 
                            />
                        </div>
                }
            </div>
            {/* Self Pay Modal */}
            <SelfPayModal 
                show={showModal} 
                setShow={()=>null}
                isShowCloseBtn={false}
                isLoading={isLoadingModalBtn}
                handleConfirmPay={()=>handleConfirmSelfPay()}
            />
        </div>
    )
}
