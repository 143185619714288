import React,{useState,useEffect} from 'react'
import DatePicker from 'react-date-picker';
import './CSS/DatePickerComp.css'

export default function DatePickerComp(props) {

    const [focus,setFocus] = useState(false)

    return (
        <fieldset className={props.isError ? "fld_set_err" : focus ? "fld_set_fcs" : "fld_set"} style={props.containerStyle}>
            <legend className={props.isError ? "legend2" : focus ? "legend1" : "legend"} style={props.labelStyle}>
                {props.label }{props.label === 'Cardholder Name' || props.label === 'Card Number' || props.label === 'Expiry Date' || props.label === 'CVV' ? <span style={{color:'#CC1527'}}>*</span> : null}</legend>
                <DatePicker
                    onChange={(e)=>props.onChange(e)}
                    value={props.value}
                    minDate={props.minDate&&props.minDate}
                    maxDate={props.maxDate&&props.maxDate}
                    closeCalendar={true}
                    clearIcon={false}
                    className="date-picker-class"
                    onCalendarOpen={()=>setFocus(true)}
                    onCalendarClose={()=>setFocus(false)}
                    format={props.format&&props.format}
                    showLeadingZeros
                />
        </fieldset>
    )
}
