import React,{useState,useEffect} from 'react'
import {useSelector} from 'react-redux'
import ParentDashboardSideBar from '../../../Components/ParentDashboardSideBar'
import ParentDashboardNavbar from '../../../Components/ParentDashboardNavbar'
import ChildCondition from '../../../Components/ChildCondition'
import './CSS/SelectChildCondition.css'

export default function SelectChildCondition() {

    const [windowWidth,setWindowWidth] = useState(window.innerWidth)


    const sidebarWidth = useSelector(data => data.SidebarReducer.width)

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })
    },[])

    return (
        <div className="parent-dashboard-child-condition">
            <ParentDashboardSideBar active="child" />
            <div style={{marginLeft:windowWidth > 1024 && sidebarWidth}}>
                <ParentDashboardNavbar active="child" />
                <ChildCondition path1="/dashboar-select-payment-type" path2="/dashboard-screener-intro" />
            </div>
        </div>
    )
}

