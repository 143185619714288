import React, { useState, useEffect } from 'react'
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
import circle1 from '../../images/mchatCircleImg1.svg'
import circle2 from '../../images/mchatCircleImg2.svg'
import vector from '../../images/Vector.png'
import {Col} from 'react-bootstrap'
import './CSS/MChatResult.css'
import { Link, useHistory } from 'react-router-dom'
import { Decrypt } from '../../Config'

export default function MChatResult(props) {

    const history = useHistory()

    const [isSelected,setIsSelected] = useState(2)
    const [isRecom,setIsRecom] = useState('Consultation')
    const [risk,setRisk] = useState('')
    const [score,setScore] = useState(0)


    useEffect(()=>{
        const result = Decrypt(props?.match?.params?.result)
        setScore(result)
        if(result >=0 && result <= 2){
            setRisk('LOW risk')
            setIsRecom('Consultation')
            setIsSelected(1)
        }
        else if(result >=3 && result <= 7){
            setRisk('MODERATE risk')
            setIsRecom('Assessment')
            setIsSelected(2)
        }
        else{
            setRisk('HIGH risk')
            setIsRecom('Assessment')
            setIsSelected(2)
        }
    },[])


    const handleContinue = () => {
        localStorage.setItem('diagnosisType',JSON.stringify({isRecom,score}))
        history.push('/signup')
    }

    return (
        <div style={{width:'100%'}}>
            <Col className="w-100" sm='12' md='12' lg='12' xl='12' >
                <Navbar />
            </Col>
            <div className="mchat-result-screen-container">
                <div className="mchat-result-screen-content">
                    <p style={{margin:0,fontSize:20,fontFamily:'CocoSharp bold'}}>M-CHAT-R : Score = {score}</p>
                    <h1 style={{margin:0,marginTop:35,fontFamily:'CocoSharp bold'}}>Result : {risk}</h1>

                    <p style={{marginTop:30}}>
                        The score indicates a moderate risk of autism or other developmental disorders. 
                        We recommend that you take your child for consultation or you can go for an assessment of your child.
                    </p>
                    <p style={{marginTop:20}}>
                        This questionnaire is designed to screen for autism, not other developmental issues. 
                        It is important to discuss any concerns about your child’s development with his or her doctor.
                    </p>
                   
                    {/* <div className="mchat-result-start-btns-container">
                        <button className={`mchat-result-start-btn ${isSelected===1&&'selected'}`} onClick={()=>(setIsRecom('Consultation'),setIsSelected(1))} >Consultation</button>
                        <button className={`mchat-result-start-btn ${isSelected===2&&'selected'}`} onClick={()=>(setIsRecom('Assessment'),setIsSelected(2))}>Assessment</button>
                    </div> */}
                    

                    <Link onClick={handleContinue} className="mchat-result-start-btn selected" style={{marginTop:50}}>Talk To Us</Link>

                </div>
               
                <img src={vector} style={{position:'absolute',bottom:50,left:0,width:'100%',zIndex:-1000}} /> 
                <img src={circle2} className="mchat-floated-circle circle-2" /> 
                <img src={circle1} className="mchat-floated-circle circle-1" /> 
            </div>
            <Footer />
        </div>
        
    )
}
