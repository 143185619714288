import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import CombineReducer from './Store/Reducers/CombineReducer'
import {createStore,applyMiddleware,compose} from 'redux'
import moment from 'moment-timezone'
import thunk from 'redux-thunk'
import {Provider} from 'react-redux'
import reportWebVitals from "./reportWebVitals";
import { HashRouter as Router } from "react-router-dom";
import 'react-calendar/dist/Calendar.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css'


moment.tz.setDefault('Asia/Karachi')

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(CombineReducer,composeEnhancers(applyMiddleware(thunk)))

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
);

reportWebVitals();
