import React from "react";
import Form from "./Form";
import Hero from "./Hero";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Informed from "../../Components/Informed";

function Contact() {
  return (
    <div>
      <Header />
      <Hero />
      <Form />
      <Informed />
      <Footer />
    </div>
  );
}

export default Contact;
