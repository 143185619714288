import React from "react";
import PinkBtn from "../../Components/PinkBtn";
import HomeCard from "../../Components/HomeCard";

export default function Procedure() {
  const tiles = [
    {
      title: "Antecedent (A)",
      desc: "What happens before a behavior",
      url: "./Icons/Anticident.png",
      color: "blue",
    },
    {
      title: "Behavior (B)",
      desc: "The actual response.",
      url: "./Icons/Behavior.png",
      color: "pink",
    },
    {
      title: "Consequence (C)",
      desc: "The result of a behavior.",
      url: "./Icons/consequence.png",
      color: "blue",
    },
  ];
  return (
    <>
      <div className="grid grid-cols-1">
        <p className="text-[#096E82] font-bold text-[20px] px-[20px] py-[20px] md:text-[48px] text-center md:px-[200px] md:py-[100px] leading-none">
          All ABA procedures involve the application of one or more components
          of the three-term contingency{" "}
        </p>
      </div>
      <div className="grid grid-cols-1 px-5 lg:grid-cols-2 xl:grid-cols-3 gap-6 md:px-[180px] justify-center w-fit mx-auto">
        {tiles.map((v, i) => (
          <HomeCard
            key={i}
            src={v.url}
            color={v.color}
            title={v.title}
            desc={v.desc}
          />
        ))}
      </div>
      <div className="mt-8 flex justify-center">
        <PinkBtn text={"Get Started"} url="signup" />
      </div>
    </>
  );
}
