import React from 'react'
import './CSS/AnimatedToggle.css'

export default function AnimatedToggle({toggleState,toggleTab,value1,value2}) {
  return (
    <div className={`animated-toggle-tabs-container ${toggleState === 2 ? "right" : toggleState === 1 ? "left" : "default"}`}>
        <button className={toggleState === 1 ? "tabs active-tab" :toggleState === 2 ? "tabs" : "active-default"} 
        onClick={() => toggleTab(1)}>{value1}</button>
        <button className={toggleState === 2 ? "tabs active-tab" : "tabs"} 
        onClick={() => toggleTab(2)}>{value2}</button>
    </div>
  )
}
