import React, {useState} from 'react';
import {OnlyAlphabets} from '../Validations'
import InputComp from './InputComp';
import DatePickerComp from './DatePickerComp';



export default function InsuranceForm({companyVal,setCompany,insurancePlanVal,setInsurancePlan,holderNameVal,setHolderName,insuranceIdVal,setInsuranceId,expiryDateVal,setExpiryDate}) {


    const [companyErr,setCompanyErr] = useState(false)
    const [insurancePlanErr,setInsurancePlanErr] = useState(false)
    const [holderNameErr,setHolderNameErr] = useState(false)

    return (
        <div style={{width:'100%'}}>
            <InputComp
                value={companyVal}
                onChange={(e)=>(
                    OnlyAlphabets(e.target.value) ? 
                        (setCompanyErr(true), setCompany(e.target.value)) 
                    : 
                        (setCompany(e.target.value), setCompanyErr(false))   
                    )}
                inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
                onFocus={true}
                isError={companyErr}
                containerStyle={{marginTop:20}} 
                label="Insurance Company" 
                labelStyle={{fontSize:16,fontWeight:600}} 
            />
            {companyErr&&<p style={{marginLeft:10,fontSize:12,color:'red'}}>Company Name should be only alphabets</p>}

            <InputComp
                value={insurancePlanVal}
                onChange={(e)=>(
                    OnlyAlphabets(e.target.value) ? 
                        (setInsurancePlanErr(true), setInsurancePlan(e.target.value)) 
                    : 
                        (setInsurancePlan(e.target.value), setInsurancePlanErr(false))   
                    )}
                inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
                onFocus={true}
                isError={insurancePlanErr}
                containerStyle={{marginTop:40}} 
                label="Insurance Plan" 
                labelStyle={{fontSize:16,fontWeight:600}} 
            />
            {insurancePlanErr&&<p style={{marginLeft:10,fontSize:12,color:'red'}}>Insurance plan should be only alphabets</p>}

            <InputComp
                value={holderNameVal}
                onChange={(e)=>(
                    OnlyAlphabets(e.target.value) ? 
                        (setHolderNameErr(true), setHolderName(e.target.value)) 
                    : 
                        (setHolderName(e.target.value), setHolderNameErr(false))   
                    )}
                inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
                onFocus={true}
                isError={holderNameErr}
                containerStyle={{marginTop:40}} 
                label="Insurance Holder Name" 
                labelStyle={{fontSize:16,fontWeight:600}} 
            />
            {holderNameErr&&<p style={{marginLeft:10,fontSize:12,color:'red'}}>Insurance holder name should be only alphabets</p>}


            <InputComp
                type="number"
                value={insuranceIdVal}
                containerStyle={{marginTop:40}} 
                label="Insurance ID" 
                labelStyle={{fontSize:16,fontWeight:600}} 
                inputLength={(e)=>e.target.value = (e.target.value).slice(0,20)}
                onChange={(e)=>setInsuranceId(e.target.value)}
            />


            <DatePickerComp 
                onChange={(date)=>setExpiryDate(date)} 
                value={expiryDateVal} 
                containerStyle={{marginTop:40,marginRight:10}}  
                labelStyle={{fontSize:16,fontWeight:600}}
                minDate={new Date()}
                label="Expiry Date"
                format="y-MM-dd"
            />
        </div>
    );
  }
  

  