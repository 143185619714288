import React from 'react'
import {FaCheck} from 'react-icons/fa'
import './CSS/CheckboxComp.css'

export default function CheckboxComp(props) {
    return (
        <div className={`d-flex align-items-center checkbox-component-container ${props.containerClass}`} onClick={props.onChange}>
            <div className={`component-checkbox mr-3 ${props.checked && 'checked'} ${props.className}`}>
                <div style={{position:'absolute',right:-7,top:-6}}>
                    {
                        props.checked ? 
                            <FaCheck size={28} color="#F99B9B" />
                        :
                            null
                    }
                </div>
            </div>
            <p className="m-0" style={{fontWeight:600}}>{props.label}</p>
        </div>
    )
}
