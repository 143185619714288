import React from "react";

export default function BigCard(props) {
  const { key, title, desc, list2, list3, list4, list5, src, color } = props;

  return (
    <div
      key={key}
      className={`${
        color === "blue" ? "bg-[#E8FCFF]" : "bg-[#FFEEEF]"
      }  p-4 max-w-[630px] min-h-[446px]`}
    >
      <div className="px-2 flex flex-col gap-4">
        <div className="flex justify-start mb-4">
          <img src={src} alt="" className="h-[50px]" />
        </div>
        <h4 className="text-brand font-bold text-2xl">{title}</h4>
        <ul
          className={`flex flex-col gap-4 text-sm items-center mt-3 list-disc px-3 mb-6`}
        >
          {desc && (
            <li className="text-dark1 ml-[-4px] mt-[-2px] text-justify"> {desc}</li>
          )}
          {list2 && (
            <li className="text-dark1 ml-[-4px] mt-[-2px] text-justify"> {list2}</li>
          )}
          {list3 && (
            <li className="text-dark1 ml-[-4px] mt-[-2px] text-justify"> {list3}</li>
          )}
          {list4 && (
            <li className="text-dark1 ml-[-4px] mt-[-2px] text-justify"> {list4}</li>
          )}
          {list5 && (
            <li className="text-dark1 ml-[-4px] mt-[-2px] text-justify"> {list5}</li>
          )}
        </ul>

        {/* {list5 && (
          <div className="flex  items-start mt-3">
            <span className="dot mt-1"></span>
            <p className="text-[10px] text-[#333333]  mt-[-2px]"> {list5}</p>
          </div>
        )} */}
      </div>
    </div>
  );
}
