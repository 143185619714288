import React from 'react'
import { Link,useHistory } from 'react-router-dom'
import {Encrypt} from '../Config'
import './CSS/PendingProfilesCard.css'

export default function PendingProfilesCard({status,Id}) {

    const history = useHistory()

    const handleCompleteProfile = () =>{
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        dt.childStatus = Encrypt(`${status}`)
        dt.childId = Id
        localStorage.setItem('bx_user_token',JSON.stringify(dt))

        history.push('/pre-call-questionnaire')
    }

    return (
        <div className="pending-profiles-card-container">
            <Link to="#" onClick={handleCompleteProfile} style={{fontWeight:'bold',color:'white',padding:'8px 20px',backgroundColor:'#00C8D4',borderRadius:8}}>Complete Profile</Link>
        </div>
    )
}
