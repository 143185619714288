import React,{useState,useRef,useEffect} from 'react'
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'
import {BiPlay,BiStop,BiFullscreen} from 'react-icons/bi'
import {BsSkipForwardFill,BsSkipBackwardFill} from 'react-icons/bs'
import {GoUnmute,GoMute} from 'react-icons/go'
import ReactPlayer from 'react-player'
import './CSS/VideoCompe.css'

export default function VideoCompe(props) {

    const [isPlay,setIsPlay] = useState(false)
    const [isMuted,setIsMuted] = useState(false)
    const [totalDuration,setTotalDuration] = useState(0)
    const [totalPlayed,setTotalPlayed] = useState(0)
    const [controls,setControls] = useState(false)

    const player = useRef(null)

    useEffect(() => {
      screenfull.on('change',()=>{
        if(screenfull.isFullscreen){
            setControls(true)
        }
        else{
            setControls(false)
        }
      })
    }, [])


    const handleFullScreen = () =>{
        if(screenfull.isEnabled){
            screenfull.request(player.current.wrapper)
        }
    }

    const handleForwardVideo=(val)=>{
        const newPlayed = totalPlayed + parseFloat(val)
        if((totalPlayed + parseFloat(val)) < totalDuration){
            setTotalPlayed(newPlayed)
            player.current.seekTo(newPlayed)
        }
    }

    const handleBackwardVideo = (val) =>{
        const newPlayed = totalPlayed - parseFloat(val)
        if((totalPlayed - parseFloat(val)) > 0){
            setTotalPlayed(newPlayed)
            player.current.seekTo(newPlayed)
        }
    }

    return (
        <div className="video-comp-container">
            <div className="embed-responsive embed-responsive-16by9" style={{backgroundColor:'black'}}>
                <ReactPlayer 
                    ref={player}
                    width="100%" 
                    height="100%" 
                    onPlay={()=>setIsPlay(true)}
                    onPause={()=>setIsPlay(false)}
                    url={props.url}
                    playing={isPlay}
                    muted={isMuted}
                    controls={controls}
                    config={{
                        file: {
                            attributes: {
                                controlsList: "nofullscreen",
                            },
                            }
                    }}
                    onEnded={()=>setIsPlay(false)}
                    onDuration={(d)=>setTotalDuration(d)}
                    onProgress={e => setTotalPlayed(e.playedSeconds)}
                />
            </div>
            {
                props.url !== ''&&
                <div className="video-controls-container">
                    <div style={{display:'flex',alignItems:'center'}}>
                        <div  style={{fontSize:24,marginRight:window.innerWidth < 375 ? 10 : 15,marginTop:5}}>
                            <BsSkipBackwardFill id="player-icon-linked" onClick={() => handleBackwardVideo(10)} />
                        </div>

                        {
                            isPlay ?
                                <div style={{fontSize:40}}>
                                    <BiStop id="player-icon-linked" onClick={()=>setIsPlay(false)} />
                                </div>
                            :

                                <div style={{fontSize:40}}>
                                    <BiPlay id="player-icon-linked" onClick={()=>setIsPlay(true)} />
                                </div>
                        }

                        <div style={{fontSize:24,marginLeft:window.innerWidth < 375 ? 10 : 15,marginTop:5}}>
                            <BsSkipForwardFill id="player-icon-linked" onClick={() => handleForwardVideo(10)} />
                        </div>

                        {
                            !isMuted ?
                                <div style={{fontSize:24,marginLeft:window.innerWidth < 375 ? 15 : 25}}>
                                    <GoUnmute id="player-icon-linked" onClick={()=>setIsMuted(true)} />
                                </div>
                            :
                                <div style={{fontSize:24,marginLeft:window.innerWidth < 375 ? 15 : 25}}>
                                    <GoMute id="player-icon-linked" onClick={()=>setIsMuted(false)} />
                                </div>
                            
                        }
                    </div>

                    <div style={{display:'flex',alignItems:'center'}}>
                        <time dateTime={`P${Math.round(totalPlayed)}S`} style={{fontSize:16,fontFamily:'CocoSharp bold',marginTop:5}}>
                            {format(totalPlayed)}/{format(totalDuration)}
                        </time>
                        <div style={{fontSize:24,marginLeft:window.innerWidth < 375 ? 15 : 30}}>
                            <BiFullscreen id="player-icon-linked" onClick={()=>handleFullScreen()} />
                        </div>
                    </div>
                    
                </div>
            }
        </div>
    )
}


function format (seconds) {
    const date = new Date(seconds * 1000)
    const hh = date.getUTCHours()
    const mm = date.getUTCMinutes()
    const ss = pad(date.getUTCSeconds())
    if (hh) {
      return `${hh}:${pad(mm)}:${ss}`
    }
    return `${mm}:${ss}`
  }
  
  function pad (string) {
    return ('0' + string).slice(-2)
  }
