import React from 'react'
import {FiMic,FiMicOff,FiVideo,FiVideoOff} from 'react-icons/fi'
import {baseURL, headers} from '../../Config'
import {MdCallEnd} from 'react-icons/md'
import Swal from 'sweetalert2'
import './CSS/CallButtons.css'

export default function CallButtons(props) {

    const handleEndCall = async () =>{
        const data = JSON.parse(localStorage.getItem('bx_user_token'))
        if(data){

        Swal.fire({
            title: 'Are you sure?',
            text: 'This action will close the video',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, close it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(baseURL + '/api/completeMeeting',{
                method:'Post',
                body:JSON.stringify({Id:props.meetingId}),
                headers:{...headers,'authorization':'Bearer ' + data.accessToken}
                })
                .then(res=>res.json())
                .then(res2=>{
                    if(res2.code === 'Success'){
                        props.leave()
                    }
                })
            }
        })
        }
    }

    return (
        <div className="call-btns-container">
            {
                props.isMutedAudio ?
                    <button onClick={()=>props.handleUnMuteAudio()} style={{marginRight:20}} className="call-btn">
                        <FiMicOff />
                    </button>
                :
                    <button onClick={()=>props.handleMuteAudio()} style={{marginRight:20}} className="call-btn">
                        <FiMic />
                    </button>
            }
            
            <button onClick={handleEndCall} style={{marginRight:20,fontSize:25,backgroundColor:'red'}} className="call-btn">
                <MdCallEnd color="white" />
            </button>

            {
                props.isMutedVideo ?
                    <button onClick={()=>props.handleUnMuteVideo()} style={{fontSize:22}} className="call-btn">
                        <FiVideoOff />
                    </button>
            :
                    <button onClick={()=>props.handleMuteVideo()} style={{fontSize:22}} className="call-btn">
                        <FiVideo />
                    </button>
            }
        </div>
    )
}
