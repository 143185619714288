import React from "react";
import {useHistory} from 'react-router-dom'
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Roles() {

  const history = useHistory()

  return (
    <div className="w-full flex justify-center items-center mt-24 px-5">
      <div className="gap-9 max-w-[1440px] items-center text-center justify-center flex flex-col w-full">
        <h1 className="text-lightGreen-500 leading-9 sm:leading-[50px] font-bold text-[30px] sm:text-[48px]  ">
          Open Roles
        </h1>
        {/* <div className="flex justify-center sm:flex-row flex-col items-center gap-4 w-full">
          <div className="relative w-full max-w-[580px]">
            <input
              type="text"
              placeholder="Select Department"
              className="border-2 border-solid placeholder:text-lg placeholder:text-lightGreen-500 placeholder:text-bold border-lightGreen-500 rounded-[11px] bg-white w-full max-w-[580px] h-[60px] p-2 pl-4"
            />
            <FontAwesomeIcon
              icon={faChevronDown}
              className="text-[#096E82] absolute right-6 top-1/2 -translate-y-1/2 text-xl cursor-pointer"
            />
          </div>
          <div className="relative w-full max-w-[580px]">
            <input
              type="text"
              placeholder="Select Location"
              className="border-2 border-solid placeholder:text-lg placeholder:text-lightGreen-500 placeholder:text-bold border-lightGreen-500 rounded-[11px] bg-white w-full max-w-[580px] h-[60px] p-2 pl-4"
            />
            <FontAwesomeIcon
              icon={faChevronDown}
              className="text-[#096E82] absolute right-6 top-1/2 -translate-y-1/2 text-xl cursor-pointer"
            />
          </div>
        </div> */}
        <hr className="w-full bg-lightGreen-500 h-[2px]" />
        <div className="flex flex-wrap justify-center sm:flex-row flex-col items-center gap-4 w-full">
          <div onClick={()=>history.push('/careersBI')} className="flex cursor-pointer justify-center items-center flex-col gap-4 border-2 border-solid p-5 rounded-[24px] w-[90%] max-w-[640px] border-lightGreen-500 ">
            {/* <h1 className="text-lightGreen-500 leading-9 sm:leading-[50px] font-bold text-[30px] sm:text-[48px]  ">
              Care
            </h1> */}
            <p className="text-lightGreen-500 sm:leading-[50px] font-normal text-base sm:text-[24px]">
            Behavior Interventionist 
            </p>
          </div>
          <div onClick={()=>history.push('/careersBCBC')} className="flex cursor-pointer justify-center items-center flex-col gap-4 border-2 border-solid p-5 rounded-[24px] w-[90%] max-w-[640px] border-lightGreen-500 ">
            {/* <h1 className="text-lightGreen-500 leading-9 sm:leading-[50px] font-bold text-[30px] sm:text-[48px]  ">
              Care
            </h1> */}
            <p className="text-lightGreen-500 sm:leading-[50px] font-normal text-base sm:text-[24px]">
            Board Certified Behavior Analyst 
            </p>
          </div>
          <div onClick={()=>history.push('/careersFrom')} className="flex cursor-pointer justify-center items-center flex-col gap-4 border-2 border-solid p-5 rounded-[24px] w-[90%] max-w-[640px] border-lightGreen-500 ">
            {/* <h1 className="text-lightGreen-500 leading-9 sm:leading-[50px] font-bold text-[30px] sm:text-[48px]  ">
              Care
            </h1> */}
            <p className="text-lightGreen-500 sm:leading-[50px] font-normal text-base sm:text-[24px]">
              Behavior Consultant 
            </p>
          </div>
        </div>
        <hr className="w-full bg-lightGreen-500 h-[2px]" />
      </div>
    </div>
  );
}

export default Roles;
