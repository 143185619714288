import React from 'react'
import './CSS/ProcedureComp.css'

export default function ProcedureComp({title,content,text,setText}) {

    return (
        <div className="procedure-fld-comp-container">
            <p className="procedure-fld-comp-title">{title}</p>
            <p className="procedure-fld-comp-des">{content}</p>
            <textarea 
                placeholder="Type here" 
                rows="5" 
                className="procedure-fld-comp-text-area" 
                value={text} 
                onChange={(e)=>setText(e.target.value)}
            ></textarea>
        </div>
    )
}
