import React from 'react';
import { Drawer } from 'antd';
import ContactCard from './ContactCard';
import { RiCloseFill } from 'react-icons/ri';
import './CSS/ChatSideDrawer.css';

export default function ChatSideDrawer({visible,onClose,list,hanldeSelectChat,headerTitle,headerTitle1,selectedType,changeSelectedType,type}) {
  return (
    <Drawer
        className='chat-side-drawer-container'
        title={
          type == '1' ? 
            <div style={{width:'100%',padding:25}}>{headerTitle}</div>
          :
            <div style={{display:'flex',alignItems:'center',width:'100%'}}>
                <div 
                  onClick={()=>changeSelectedType('1')}
                  style={{
                    width:'150px',
                    padding:25,
                    backgroundColor:selectedType == '1' ? '#4831D4' : 'white',
                    color:selectedType == '1' ? 'white' : '#096E82'
                  }}
                >{headerTitle}</div>
                <div 
                  onClick={()=>changeSelectedType('2')}
                  style={{
                    width:'150px',
                    padding:25,
                    backgroundColor:selectedType == '2' ? '#4831D4' : 'white',
                    color:selectedType == '2' ? 'white' : '#096E82'
                  }}
                >{headerTitle1}</div>
            </div>
        }
        headerStyle={{color:'white'}}
        placement="right"
        closable={true}
        closeIcon={<RiCloseFill color={selectedType == '2' ? 'white' : 'gray'} size={20} />}
        onClose={onClose}
        visible={visible}
        getContainer={false}
        style={{ position: 'absolute' }}
    >
        {
            list?.map((dt,i)=>{
                return (
                        <React.Fragment key={i}>
                          <ContactCard 
                              name={dt.name}
                              lastMessage={dt.lastMessage}
                              date={dt.date}
                              isActive={dt.isActive}
                              hanldeSelectChat={(Id)=>hanldeSelectChat(Id)}
                              Id={dt.Id}
                          />
                        </React.Fragment>
                      )
            })
        }
    </Drawer>
  );
}
