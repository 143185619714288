import React,{useEffect, useState,useRef} from 'react'
import logo from '../images/logo1.svg'
import logo1 from '../images/logo.png'
import {useSelector,useDispatch} from 'react-redux'
import { Drawer } from 'antd';
import { FaBars } from 'react-icons/fa';
import { IoNotificationsSharp } from "react-icons/io5";
import Skeleton from 'react-loading-skeleton';
import {getTimeInLocal,getTimeInLocalString, convert24To12} from '../TimeFormate'
import {GetClinicianAppointments} from '../Store/Actions/AppointmentActions'
import {GetNotifications} from '../Store/Actions/NotificationActions'
import {MarkAsRead,MarkAllAsRead} from '../Store/Actions/NotificationActions'
import moment from 'moment'
import {RBTRoutes,BCBARoutes,BCRoutes} from '../Routes'
import 'antd/dist/antd.min.css';
import './CSS/ClinicianDashboardNavbar.css'
import { toast } from 'react-toastify'
import { Button,Dropdown} from 'react-bootstrap';
import { Link,useHistory } from 'react-router-dom';
import { baseURL, Decrypt, headers, Encrypt } from '../Config';


export default function ClinicianDashboardNavbar(props) {

    const counterInterval = useRef(null)
    const history = useHistory()
    const dispatch = useDispatch()

    const [isOpenDrawer,setIsOpenDrawer] = useState(false)
    const [upcomingAppointment,setUpcomingAppointment] = useState({})
    const [previousAppointment,setPreviousAppointment] = useState({})
    const [isJoin,setIsJoin] = useState(false)
    const [showCounter,setShowCounter] = useState(false)
    const [counter,setCounter] = useState(null)
    const [Notifications,setNotifications] = useState([])

    const parentImage = useSelector(data => data.ParentReducer.parentData.image)
    const upcomingApp = useSelector(data => data.AppointmentReducer.upcomingApp)
    const previousApp = useSelector(data => data.AppointmentReducer.previousApp)
    const isLoaded = useSelector(data => data.AppointmentReducer.isLoaded)
    const notifications = useSelector(data => data.NotificationsReducer.notifications)

    useEffect(()=>{
        const data = JSON.parse(localStorage.getItem('bx_user_token'))
        if(data&&Decrypt(data.userType) !== 'Parent'){
            dispatch(GetClinicianAppointments())
            dispatch(GetNotifications())
        }
    },[])

    // For notification
    useEffect(()=>{
        setNotifications(notifications)
    },[notifications])


    /// setting up counter for showCounter
    useEffect(()=>{
        showCounter&&
        setInterval(()=>setCounter((count)=>count - 1),1000)
    },[showCounter])


    // Checking for counter and join btn 
    useEffect(()=>{

        Object.keys(upcomingAppointment)?.length > 0 && clearInterval(counterInterval.current)
        
        counterInterval.current = Object.keys(upcomingAppointment)?.length > 0 && setInterval(()=>{

            const currentDate = new Date()
            
            const currentDateConverted = getTimeInLocal(currentDate)
            const appointmentDate = getTimeInLocal(upcomingAppointment.schedulingDateTime)
            const diff = moment.duration(moment(appointmentDate).diff(currentDateConverted))

            const seconds = diff/1000 

            if(Math.ceil(seconds) <= 900){ // compairing every second of upcomming appointment
                setIsJoin(true)
                setShowCounter(false)
            }
            else if(Math.ceil(seconds) <=959){
                setShowCounter(true)
                setCounter(Math.round(seconds).toFixed(0))
            }

        },1000)

    },[upcomingAppointment])


    useEffect(()=>{
       const currentDate = new Date()
       let upcomApp = {}
       let upcomingAppDiff = null
       let preApp = {}
       let previousAppDiff = null

       upcomingApp&&upcomingApp.map(dt=>{
            const currentDateConverted = getTimeInLocal(currentDate)
            const appointmentDate = getTimeInLocal(dt.schedulingDateTime)
            const diff = moment.duration(moment(appointmentDate).diff(currentDateConverted))
            const minutes = diff/60000

            if(Math.round(minutes) > -55){ // Checking if upcoming appointment and current time difference is less than -55 than show next upcoming appointment
                if(upcomingAppDiff === null){
                    upcomingAppDiff = Math.round(minutes)
                    upcomApp = dt
                }
                else if(Math.round(minutes) < upcomingAppDiff){
                    upcomingAppDiff = Math.round(minutes)
                    upcomApp = dt
                }
            }
       })

        setUpcomingAppointment(upcomApp)

        
        previousApp&&previousApp.map(dt=>{

            const currentDateConverted = getTimeInLocal(currentDate)
            const appointmentDate = getTimeInLocal(dt.schedulingDateTime)
            const diff = moment.duration(moment(appointmentDate).diff(currentDateConverted))
            const minutes = diff/60000

            if(dt.status == 1){
                if(Math.round(minutes) < 0){ /// checking if previous appointment 
                    if(previousAppDiff === null){
                        previousAppDiff = Math.round(minutes)
                        preApp = dt
                    }
                    else if(Math.round(minutes) > previousAppDiff){
                        previousAppDiff = Math.round(minutes)
                        preApp = dt
                    }
                }
            }
        })

        setPreviousAppointment(preApp)

    },[upcomingApp,previousApp])


    const handleLogout = () =>{
        dispatch({type:'GetAppointments',payload:[]})
        localStorage.removeItem('bx_user_token')
        history.push('/')
    }

    const showAlert = () =>{
        toast.error('Coming soon',{toastId:'show-alert-coming-soon-id'})
    }

    const handleJoinMeeting = () =>{
        const data = JSON.parse(localStorage.getItem('bx_user_token'))
        const appoint = upcomingAppointment
        if(data&&Decrypt(data.userType) !== 'Parent'){
            fetch(baseURL + '/api/joinMeetingByClinician',{
                method:'Post',
                body:JSON.stringify({appId:appoint.Id,uid:data.uid,dateAndTime:Encrypt(appoint.schedulingDateTime)}),
                headers:{...headers,'authorization':'Bearer ' + data.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Success'){
                    dispatch({type:'GetMeeting',payload:res2.message})
                    localStorage.setItem('agora-token',JSON.stringify({token:Encrypt(res2.message.token),channelName:Encrypt(res2.message.channelName)}))
                    history.push({pathname:'/bc-calling',state:{reload:true}})
                }
            })
        }
    }

    
    return (
        <div className="row py-3 pt-4 m-0 w-100 custom-row-reverse dashboard-navbar-container">
            <div  className="col-xl-8 col-lg-12 col-md-12 col-sm-12 p-0">
            {
                props.showAppointments !== false &&
                <>    
                    <div className="d-flex align-items-center">
                    {
                        isLoaded ?
                            Object.keys(previousAppointment).length > 0 ?
                                <JoinMeetingCard 
                                    title="Previous Appointment"
                                    date={getTimeInLocalString(previousAppointment.schedulingDateTime).split(' ')[1] + ' ' + getTimeInLocalString(previousAppointment.schedulingDateTime).split(' ')[2] + ' ' + getTimeInLocalString(previousAppointment.schedulingDateTime).split(' ')[3]}
                                    time={getTimeInLocalString(previousAppointment.schedulingDateTime).split(' ')[4] + ' ' + getTimeInLocalString(previousAppointment.schedulingDateTime).split(' ')[5]} 
                                    containerClass="pre-appointment-card"
                                />
                            :
                                null
                        :
                            <Skeleton width={240} height={85} style={{borderRadius:9,marginRight:30}} />
                    }
                    {
                        isLoaded ?
                            Object.keys(upcomingAppointment).length > 0 ?
                                <JoinMeetingCard 
                                    title={isJoin ? "Meeting Started" : "Upcoming Appointment"}
                                    date={getTimeInLocalString(upcomingAppointment.schedulingDateTime).split(' ')[1] + ' ' + getTimeInLocalString(upcomingAppointment.schedulingDateTime).split(' ')[2] + ' ' + getTimeInLocalString(upcomingAppointment.schedulingDateTime).split(' ')[3]}
                                    time={getTimeInLocalString(upcomingAppointment.schedulingDateTime).split(' ')[4] + ' ' + getTimeInLocalString(upcomingAppointment.schedulingDateTime).split(' ')[5]} 
                                    containerClass="next-appointment-card"
                                />
                            :
                                null
                        :
                            <Skeleton width={240} height={85} style={{borderRadius:9,marginRight:30}} />
                    }
                    {isLoaded&&Object.keys(upcomingAppointment)?.length === 0 && Object.keys(previousAppointment)?.length === 0 && <h5 style={{color:'#d7d7d7'}}>No Upcoming Appointment</h5>}
                    </div>
                    {
                        isLoaded ?
                                showCounter ?
                                    <Button id='bc-dashboard-navbar-join-btn'>
                                        Meeting Starts in {counter}s
                                    </Button>
                                :isJoin ? 
                                    <Button id='bc-dashboard-navbar-join-btn' onClick={handleJoinMeeting}>Join Meeting</Button>
                                :
                                    <Button id='bc-dashboard-navbar-join-btn1'>Join Meeting</Button>
                        :
                            <Skeleton width={240} height={40} style={{borderRadius:9,marginTop:15}} />
                    }
                    
                </>
            }
            </div>            
            <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 bc-dashboard-navbar-col-2 p-0">
                <FaBars color="gray" size={25} id="small_device_bars" onClick={()=>setIsOpenDrawer(true)} />
                <div className="d-flex align-items-center p-0">
                        <Dropdown>
                            <Dropdown.Toggle variant="link" id="dropdown-basic" >
                                <div style={{position:'relative'}}>
                                    <IoNotificationsSharp size={25} color="#096E82" />
                                    {
                                        Notifications&&
                                        Notifications.some(dt=>dt.isRead === 0)&&
                                        <div style={{width:8,height:8,borderRadius:4,backgroundColor:'red',position:'absolute',top:2,right:2,zIndex:100}}></div>
                                    }
                                </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu align="right" >
                                <Dropdown.Item style={{whiteSpace:'normal',paddingLeft:8,paddingRight:8}}>
                                    <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                                        <h6 style={{margin:0}}>Notifications</h6>
                                        <Link to="#" onClick={()=>dispatch(MarkAllAsRead())}> 
                                            <p style={{margin:0,fontSize:12}}>Mark all as read</p>
                                        </Link>
                                    </div>
                                </Dropdown.Item>
                                <hr style={{margin:0}}/>
                                <Dropdown.Item id="notifications-container" style={{whiteSpace:'normal',paddingLeft:8,paddingRight:8,height:Notifications&&Notifications.length > 2 ? 300 : 'auto'}}>
                                    {
                                        Notifications&&
                                        Notifications.reverse().map(dt=>{
                                            return (
                                                <div style={{display:'flex',alignItems:'flex-start',padding:'10px 0px',borderBottom:'solid 1px gray',width:'100%'}}>
                                                    <div style={{width:'15%'}}>
                                                        <div style={{width:50,height:50,borderRadius:'50%',display:'flex',justifyContent:'center'}}>
                                                            <img src={logo} className="img-fluid" style={{width:'80%'}} />
                                                        </div>
                                                    </div>
                                                    <div style={{marginLeft:15,width:'85%'}}>
                                                        <p style={{margin:0,color:'black',fontSize:14}}>{dt.title}</p>
                                                        <p style={{margin:0,fontSize:12,color:'#707070',lineHeight:1.5}}>{dt.message}</p>
                                                        <div style={{marginTop:10,display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                                                            {/* <div>
                                                                <p style={{margin:0,color:'gray',fontSize:9}}>{notiDate.split('T')[0]} at {time}</p>
                                                            </div> */}
                                                            {
                                                                dt.isRead === 0 &&
                                                                <Link to="#" onClick={()=>dispatch(MarkAsRead(dt.Id))}> 
                                                                    <p style={{margin:0,fontSize:10}}>Mark as read</p>
                                                                </Link>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        {
                            parentImage ? 
                                <div style={{width:85,height:85,borderRadius:23,marginLeft:20,backgroundImage:`url(${parentImage})`,backgroundSize:'cover',backgroundPosition:'center',backgroundRepeat:'no-repeat'}}>
                                </div>
                            :
                                <Skeleton style={{width:85,height:85,borderRadius:23,marginLeft:20}} />
                        }
                </div>
            </div>   
            <Drawer
                placement="left"
                closable={false}
                onClose={()=>setIsOpenDrawer(false)}
                visible={isOpenDrawer}
                drawerStyle={{backgroundColor:'#F0FFF8',padding:0}}
            >
                <div className="d-flex justify-content-center p-3">
                    <img src={logo1} className="img-fluid" />
                </div>
                <div style={{marginTop:40}}>
                {
                    props?.user === 'RBT' ?
                        RBTRoutes?.map((dt,i)=>{
                            return(
                                <Link 
                                    key={i} 
                                    to={dt.route || '#'} 
                                    onClick={()=>dt.type == 'logout' ? handleLogout() : dt.type == 'notes' ? showAlert() : null } 
                                    className={dt.type == props.active ? "small-device-side-bar-menu small-device-active" : "small-device-side-bar-menu"}>
                                    <div className="small-device-side-bar-menu-col-1">
                                        <img src={dt.type == props.active ? dt.activeIcon : dt.icon} />
                                    </div>
                                    <div className="small-device-side-bar-menu-col-2">
                                        <p>{dt.name}</p> 
                                    </div>
                                </Link>
                            )
                        })
                    : props?.user === 'BC' ?
                        BCRoutes?.map((dt,i)=>{
                            return(
                                <Link 
                                    key={i} 
                                    to={dt.route || '#'} 
                                    onClick={()=>dt.type == 'logout' ? handleLogout() : dt.type == 'notes' ? showAlert() : null } 
                                    className={dt.type == props.active ? "small-device-side-bar-menu small-device-active" : "small-device-side-bar-menu"}>
                                    <div className="small-device-side-bar-menu-col-1">
                                        <img src={dt.type == props.active ? dt.activeIcon : dt.icon} />
                                    </div>
                                    <div className="small-device-side-bar-menu-col-2">
                                        <p>{dt.name}</p> 
                                    </div>
                                </Link>
                            )
                        })
                    : props?.user === 'BCBA' ?
                        BCBARoutes?.map((dt,i)=>{
                            return(
                                <Link 
                                    key={i} 
                                    to={dt.route || '#'} 
                                    onClick={()=>dt.type == 'logout' ? handleLogout() : dt.type == 'notes' ? showAlert() : null } 
                                    className={dt.type == props.active ? "small-device-side-bar-menu small-device-active" : "small-device-side-bar-menu"}>
                                    <div className="small-device-side-bar-menu-col-1">
                                        <img src={dt.type == props.active ? dt.activeIcon : dt.icon} />
                                    </div>
                                    <div className="small-device-side-bar-menu-col-2">
                                        <p>{dt.name}</p> 
                                    </div>
                                </Link>
                            )
                        })
                    :null
                    
                }
                </div>
            </Drawer>         
        </div>
    )
}


const JoinMeetingCard = (props)=>{
    return(
        <div className={props.containerClass} style={props.containerStyle}>
            <h6>{props.title}</h6>
            <p>{props.date}</p>
            <p>{props.time}</p>
        </div>
    )
}