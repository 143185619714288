import React from "react";
import Hero from "./Hero";
import Last from "./Last";
import Mission from "./Mission";
import Vision from "./Vision";
import Values from "./Values";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Informed from "../../Components/Informed";

function About() {
  return (
    <div>
      <Header/>
      <Hero />
      <Values/>
      <Mission />
      <Vision />
      <Last />
      <Informed />
      <Footer />
    </div>
  );
}

export default About;
