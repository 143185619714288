import React, { useEffect, useState } from 'react';
import ContactsListComp from './ContactsListComp';
import MessagesContainer from './MessagesContainer';
import './CSS/ChatIndex.css'
import { Decrypt } from '../../Config';

export default function ChatIndex({contactListHeaderTitle,contactListHeaderTitle1,selectedType,listLoaded,changeSelectedType,type,contactList,hanldeSelectChat,selectedUser,channel,senderImage,senderName,user,userId}) {

  const [name,setName] = useState('')
  const [image,setImage] = useState('')
  const [uid,setUID] = useState('')
  const [userType,setUserType] = useState('')

  useEffect(()=>{
    selectedUser&&
    setName(Decrypt(selectedUser?.first_name) + ' ' + Decrypt(selectedUser?.last_name))

    selectedUser&&
    setImage(Decrypt(selectedUser?.image))
    
    selectedUser&&
    setUID(selectedUser?.uid)

    selectedUser&&
    setUserType(Decrypt(selectedUser?.user_type))
  },[selectedUser])

  return (
        <div className='chat-component-index-container'>
          <ContactsListComp 
            headerTitle={contactListHeaderTitle}
            headerTitle1={contactListHeaderTitle1}
            selectedType={selectedType}
            changeSelectedType={changeSelectedType}
            type={type}
            userType={userType}
            list={contactList}
            listLoaded={listLoaded}
            hanldeSelectChat={(Id,userType)=>hanldeSelectChat(Id,userType)}
            channel={channel}
          />
          <MessagesContainer 
            isShowMessages = {selectedUser ? true : false}
            name={name}
            image={image}
            status={true}
            headerTitle={contactListHeaderTitle}
            headerTitle1={contactListHeaderTitle1}
            selectedType={selectedType}
            changeSelectedType={changeSelectedType}
            type={type}
            list={contactList}
            hanldeSelectChat={(Id)=>hanldeSelectChat(Id)}
            channel={channel}
            senderImage={senderImage}
            senderName={senderName}
            user={user}
            userId={uid}
            userType={userType}
            senderId={userId}
          />
        </div>
  )
}
