import React from "react";
import { Link } from "react-router-dom";

function PinkBtn({ text, classes, url, onClick, target }) {
  return (
    <Link
      to={url ? `/${url}` : "/"}
      onClick={onClick}
      target={target}
      className={`bg-lightPink-100 rounded-[10px] ${
        classes ? classes : "h-12  w-[136px] text-sm"
      } no-underline flex justify-center items-center font-semibold text-white hover:bg-pink-700 hover:scale-95 transition-all`}
    >
      {text}
    </Link>
  );
}

export default PinkBtn;
