import React, {useState,useEffect} from 'react';
import {Col, Spinner } from 'react-bootstrap';
import {OnlyAlphabets,Required} from '../../../Validations'
import Navbar from '../../../Components/Navbar';
import Footer from '../../../Components/Footer';
import InputComp from '../../../Components/InputComp';
import DatePickerComp from '../../../Components/DatePickerComp';
import {getTimeInLocal} from '../../../TimeFormate';
import {AiOutlineGoogle,AiFillApple} from 'react-icons/ai'
import {useHistory} from 'react-router-dom';
import './CSS/PaymentCardPage.css';
import { toast } from 'react-toastify';
import { baseURL, headers } from '../../../Config';


export default function PaymentCardPage(props) {

    const history = useHistory()

    const [method,setMethod] = useState('default')
    const [name,setName] = useState('')
    const [nameErr,setNameErr] = useState(false)
    const [number,setNumber] = useState('')
    const [cvv,SetCVV] = useState('')
    const [expiryDate,setExpiryDate] = useState('')
    const [isChecked,setIsChecked] = useState('')
    const [isLoading,setIsLoading] = useState(false)

    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])

    const handleConfirmPayment = () =>{
        if(Required('Card Holder Name',name)&&Required('Card Number',number)&&Required('Expiry Date',expiryDate?getTimeInLocal(expiryDate).split('T')[0]:'')&&Required('CVV',cvv)&&!nameErr){
            if(isChecked){
                if(name.length < 2){
                    toast.error('Card holder name must be 2 or more characters')
                }
                else if(number.length < 16){
                    toast.error('Card number must be 16 digits')
                }
                else if(cvv.length < 3){
                    toast.error('CVV number must be 3 or 4 digits')
                }
                else{

                    const dt = JSON.parse(localStorage.getItem('bx_user_token'))
                    if(dt){
                        setIsLoading(true)
                        fetch(baseURL + '/api/selfPayment',{
                            method:'Post',
                            body:JSON.stringify({Id:dt.childId}),
                            headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
                        })
                        .then(res=>res.json())
                        .then(res2=>{
                            if(res2.code === 'Success'){
                                res2?.message?.isPayed&&
                                history.push('/pre-call-questionnaire')
                                setIsLoading(false)
                            }
                            else{
                                toast.error(res2.message,{toastId:'self-pay-err-id1',autoClose:3000})
                                setIsLoading(false)
                            }
                        })
                    }
                }
            }
            else{
                toast.error('Please accept terms & conditions',{toastId:"please-accept-term-id"})
            }
        }
    }

    return (
        <div style={{width:'100%'}}>
            <Col sm='12' md='12' lg='12' xl='12' >
                <div className="Payment-Card-Page-Container">
                    <div className="Payment-Card-Page-Body">
                        <h1 style={{fontFamily:'CocoSharp bold'}}>Payment</h1>
                        <hr /> 
                        <div className={`Payment-Card-tabs-container ${method === 'google' ? "left" : method === 'apple' ?  "right" : 'default'}`}>
                            <button className={method === 'google' ? "tabs active-tab d-flex justify-content-center align-items-center" :method === 'apple' ? "tabs d-flex justify-content-center align-items-center" : "active-default d-flex justify-content-center align-items-center"} 
                            onClick={() => setMethod(method === 'default' ? 'default' : 'google' )}><AiOutlineGoogle size={20} /> Pay</button>
                            <button className={method === 'apple' ? "tabs active-tab d-flex justify-content-center align-items-center" : "tabs d-flex justify-content-center align-items-center"} 
                            onClick={() => setMethod('apple')}><AiFillApple size={20} /> Apple Pay</button>
                        </div>
                        
                        <div style={{width:'100%',marginTop:25}}>
                            <div className="Payment-Card-row">
                                <InputComp
                                    value={name}
                                    containerStyle={{marginTop:30,marginRight:10}} 
                                    label="Cardholder Name" 
                                    labelStyle={{fontSize:16,fontWeight:600}}
                                    inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
                                    isError={nameErr}
                                    onChange={(e)=>(
                                        OnlyAlphabets(e.target.value) ? 
                                            (setName(e.target.value), setNameErr(true))
                                        : 
                                            (setName(e.target.value), setNameErr(false))
                                    )}
                                />
                                <InputComp
                                    type="number"
                                    value={number}
                                    containerStyle={{marginTop:30}} 
                                    label='Card Number'
                                    labelStyle={{fontSize:16,fontWeight:600}}
                                    inputLength={(e)=>e.target.value = (e.target.value).slice(0,16)}
                                    onChange={(e)=>setNumber(e.target.value)}
                                />
                            </div>
                            <div  className="Payment-Card-row">
                                <DatePickerComp 
                                    onChange={(date)=>setExpiryDate(date)} 
                                    value={expiryDate} 
                                    containerStyle={{marginTop:30,marginRight:10}}  
                                    labelStyle={{fontSize:16,fontWeight:600}}
                                    minDate={new Date()}
                                    label="Expiry Date"
                                    format="y-MM-dd"
                                />
                                <InputComp
                                    type="number"
                                    value={cvv}
                                    containerStyle={{marginTop:30}} 
                                    label="CVV" 
                                    labelStyle={{fontSize:16,fontWeight:600}} 
                                    inputLength={(e)=>e.target.value = (e.target.value).slice(0,4)}
                                    onChange={(e)=>SetCVV(e.target.value)}
                                />
                            </div>
                            <div className="d-flex align-items-center mt-3">
                                    <input 
                                        id="check-box"
                                        type="checkbox" 
                                        checked={isChecked} 
                                        onChange={(e)=>setIsChecked(e.target.checked)}
                                    />
                                    <label className="custom-check-box" for="check-box">
                                        <span className="check-mark"></span>
                                    </label>
                                    <span style={{fontFamily:'CocoSharp bold',marginLeft:10}}>Accept Terms & Conditions</span>
                            </div>
                            
                            <br/>
                            {
                                isLoading ?
                                    <button className="Payment-Card-btn">
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        {' '}
                                        Loading....
                                    </button>
                                :
                                    <button className="Payment-Card-btn" onClick={handleConfirmPayment}>Confirm</button>
                            }
                        </div>
                    </div>
                </div>
            </Col>
        </div>
    
    );
  }
  

  