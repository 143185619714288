import React from 'react'
import {BsCircleFill} from 'react-icons/bs'
import {RiArrowDropRightLine} from 'react-icons/ri'
import {AiFillCheckCircle,AiFillCloseCircle} from 'react-icons/ai'
import {BsInfoCircleFill} from 'react-icons/bs'
import './CSS/TopStepBar.css'

export default function TopStepBar(props) {


    return (
        <div className="pre-call-questions-top-step-bar">
            <div className="d-flex align-items-center top-step-bar-option" onClick={()=>props.onChangeStep(1)}>
                {
                    props.form1Err ?
                        <AiFillCloseCircle color="red" style={{fontSize:18}}/>
                    :
                        props.isActive === 1 ? 
                            <AiFillCheckCircle color="rgba(255,255,255,1)" style={{fontSize:18}}/>
                        :
                            <BsCircleFill color="rgba(255,255,255,0.5)" style={{fontSize:12}} />
                }
                <p className="my-0 ml-2" style={{color:props.isActive === 1 ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.5)'}}>Background History Form</p>
                <RiArrowDropRightLine style={{fontSize:24}} color="rgba(255,255,255,1)" />
            </div>
            <div className="d-flex align-items-center top-step-bar-option" onClick={()=>props.onChangeStep(2)}>
                {
                    props.isActive === 2 ? 
                        <AiFillCheckCircle color="rgba(255,255,255,1)" style={{fontSize:18}} onClick={()=>props.onChangeStep(1)}/>
                    :
                        <BsCircleFill color="rgba(255,255,255,0.5)" style={{fontSize:12}} />
                }
                <p className="my-0 ml-2" style={{color:props.isActive === 2 ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.5)'}}>Developmental History</p>
                <RiArrowDropRightLine style={{fontSize:24}} color="rgba(255,255,255,1)" />
            </div>
            <div className="d-flex align-items-center top-step-bar-option" onClick={()=>props.onChangeStep(3)}>
                {
                    props.form3Err ?
                        <AiFillCloseCircle color="red" style={{fontSize:18}}/>
                    :
                        props.isActive === 3 ? 
                            <AiFillCheckCircle color="rgba(255,255,255,1)" style={{fontSize:18}}/>
                        :
                            <BsCircleFill color="rgba(255,255,255,0.5)" style={{fontSize:12}} />
                    
                }
                <p className="my-0 ml-2" style={{color:props.isActive === 3 ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.5)'}}>Medical History</p>
                <RiArrowDropRightLine style={{fontSize:24}} color="rgba(255,255,255,1)" />
            </div>
            <div className="d-flex align-items-center top-step-bar-option" onClick={()=>props.onChangeStep(4)}>
                {
                    props.isActive === 4 ? 
                        <AiFillCheckCircle color="rgba(255,255,255,1)" style={{fontSize:18}}/>
                    :
                        <BsCircleFill color="rgba(255,255,255,0.5)" style={{fontSize:12}} />
                }
                <p className="my-0 ml-2" style={{color:props.isActive === 4 ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.5)'}}>Behaviour Checklist</p>
                <RiArrowDropRightLine style={{fontSize:24}} color="rgba(255,255,255,1)" />
            </div>
            <div className="d-flex align-items-center top-step-bar-option" onClick={()=>props.onChangeStep(5)}>
                {
                    props.isActive === 5 ? 
                        <AiFillCheckCircle color="rgba(255,255,255,1)" style={{fontSize:18}}/>
                    :
                        <BsCircleFill color="rgba(255,255,255,0.5)" style={{fontSize:12}} />
                }
                <p className="my-0 ml-2" style={{color:props.isActive === 5 ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.5)'}}>School Information</p>
                <RiArrowDropRightLine style={{fontSize:24}} color="rgba(255,255,255,1)" />
            </div>
            <div className="d-flex align-items-center top-step-bar-option" onClick={()=>props.onChangeStep(6)}>
                {
                    props.form6Err ?
                    <AiFillCloseCircle color="red" style={{fontSize:18}}/>
                    :
                        props.isActive === 6 ? 
                            <AiFillCheckCircle color="rgba(255,255,255,1)" style={{fontSize:18}}/>
                        :
                            <BsCircleFill color="rgba(255,255,255,0.5)" style={{fontSize:12}} />
                }
                <p className="my-0 ml-2" style={{color:props.isActive === 6 ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.5)'}}>Other Information</p>
                <RiArrowDropRightLine style={{fontSize:24}} color="rgba(255,255,255,1)" />
            </div>


            <div className="d-flex align-items-center ml-2" id="bar">
                <h5 className="my-0 mr-2" style={{color:'rgba(255,255,255,0.7)'}}>|</h5>
            </div>

            <div className="d-flex align-items-center ml-auto" id="save-buttons">
                {/* <div className="save-link">
                    <p className="my-0 mr-2" style={{color:props.isActive === 6 ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.5)',fontWeight:600}}>Save</p>
                </div> */}
                <h5 className="my-0 mr-2 text-white">|</h5>
                <div className="save-link" onClick={props.handleSaveDraft}>
                    <p className="my-0 mr-2" style={{color:'rgba(255,255,255,1)',fontWeight:600}}>Save Draft</p>
                </div>

                <div className="ml-3 save-link">
                    <BsInfoCircleFill color="white" className="m-0" /> 
                </div>
            </div>
        </div>
    )
}

