import React from "react";

export default function WorkCard(props) {
  const { key, title, list1, list2, list3, src, color } = props;

  return (
    <div
      key={key}
      className={`${
        color === "blue" ? "bg-[#E8FCFF]" : "bg-[#FFEEEF]"
      }  p-4 w-[300px] max-w-full min-h-[227px]`}
    >
      <div className="p-2 h-full flex flex-col justify-between">
        <h4 className="text-[#096E82] font-bold text-2xl">{title}</h4>
        <ul
          className={`flex flex-col items-start gap-2 text-sm mt-3 list-disc px-3 mb-6`}
        >
          {list1 && (
            <li className="text-dark1 ml-[-4px] mt-[-2px]"> {list1}</li>
          )}
          {list2 && (
            <li className="text-dark1 ml-[-4px] mt-[-2px]"> {list2}</li>
          )}
          {list3 && (
            <li className="text-dark1 ml-[-4px] mt-[-2px]"> {list3}</li>
          )}
        </ul>

        <div className="flex justify-end">
          <img src={src} alt="" className="w-[50px]" />
        </div>
      </div>
    </div>
  );
}
