import React,{useState,useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {useSelector} from 'react-redux'
import ParentDashboardSideBar from '../../../Components/ParentDashboardSideBar'
import ParentDashboardNavbar from '../../../Components/ParentDashboardNavbar'
import MChatQuestionComp from '../../../Components/MChatQuestionComp'
import './CSS/ScreenerQuestions.css'
import { Encrypt } from '../../../Config'

export default function ScreenerQuestions() {

    const history = useHistory()

    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const [showedIndex,setShowedIndex] = useState(0)


    const sidebarWidth = useSelector(data => data.SidebarReducer.width)

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })
    },[])

    

    const handleSetYes = (index) =>{
        if((index + 1) < 20){
            const newArr = Questions.map((dt,i)=>{
                if(i === index){
                    if(index === 1 || index === 4 || index === 11){
                        dt.answer = 'at-risk'
                        return dt
                    }
                    else{
                        dt.answer = 'typical'
                        return dt
                    }
                }
                return dt
            })
            setShowedIndex(showedIndex + 1)
            Questions = newArr
        }
        else{
            const newArr = Questions.map((dt,i)=>{
                if(i === index){
                    dt.answer = 'typical'
                    return dt
                }
                return dt
            })
            Questions = newArr
            calculateResult()
        }
    }

    const handleSetNo = (index) =>{
        if((index + 1) < 20){
            const newArr = Questions.map((dt,i)=>{
                if(i === index){
                    if(index === 1 || index === 4 || index === 11){
                        dt.answer = 'typical'
                        return dt
                    }
                    else{
                        dt.answer = 'at-risk'
                        return dt
                    }
                }
                return dt
            })
            setShowedIndex(showedIndex + 1)
            Questions = newArr
        }
        else{
            const newArr = Questions.map((dt,i)=>{
                if(i === index){
                    dt.answer = 'at-risk'
                    return dt
                }
                return dt
            })
            Questions = newArr
            calculateResult()
            // 
        }
    }

    const calculateResult = () =>{
        let result = 0

        Questions.map(dt=>{
            if(dt.answer === 'at-risk'){
                result = result + 1
            }
        })
        
        history.push(`/dashboard-screener-result/${Encrypt(`${result}`)}`)
    }

    return (
        <div className="parent-dashboard-screener-questions">
            <ParentDashboardSideBar active="child" />
            <div style={{marginLeft:windowWidth > 1024 && sidebarWidth}}>
                <ParentDashboardNavbar active="child" />
                <div className="parent-dashboard-screener-questions-body">
                    <p style={{margin:0,fontSize:20,fontFamily:'CocoSharp bold'}}>M-CHAT-R</p>
                    {
                        Questions.map((dt,i)=>{
                            if(showedIndex === i){
                                return  <MChatQuestionComp 
                                            question={dt.question} 
                                            example={dt.example} 
                                            questionNumber={i+1}
                                            onYes={()=>handleSetYes(i)} 
                                            onNo={()=>handleSetNo(i)} 
                                        />
                            } 
                        })
                    }
                </div>
            </div>
        </div>
        
    )
}


let Questions = [
    {
        question:'If you point at something across the room, does your child look at it?',
        example:'If you point at a toy or an animal, does your child look at the toy or animal?',
        answer:''
    },
    {
        question:'Have you ever wondered if your child might be deaf?',
        example:'If you point at a toy or an animal, does your child look at the toy or animal?',
        answer:''
    },
    {
        question:'Does your child play pretend or make-believe?',
        example:'Pretend to drink from an empty cup, pretend to talk on a phone, or pretend to feed a doll or stuffed animal?',
        answer:''
    },
    {
        question:'Does your child like climbing on things?',
        example:'Climbing on furniture, playground equipment, or stairs.',
        answer:''
    },
    {
        question:'Does your child make unusual finger movements near his or her eyes?',
        example:'Does your child wiggle his or her fingers to his or her eyes?',
        answer:''
    },
    {
        question:'Does your child point with one finger to ask for something or to get help?',
        example:'Pointing to a snack or toy that is out of reach.',
        answer:''
    },
    {
        question:'Does your child point with one finger to show you something interesting?',
        example:'Pointing to an airplane in the sky or a big truck in the road.',
        answer:''
    },
    {
        question:'Is your child interested in other children?',
        example:'Does your child watch other children, smile at them, or go to them?',
        answer:''
    },
    {
        question:'Does your child show you things by bringing them to you or holding them up for you to see - not to get help, but just to share?',
        example:'Showing you a flower, a stuffed animal, or a toy truck.',
        answer:''
    },
    {
        question:'Does your child respond when you call his or her name?',
        example:'Does he or she look up, talk or babble, or stop what he or she is doing when you call his or her name?',
        answer:''
    },
    {
        question:'When you smile at your child, does he or she smile back at you?',
        example:'If you point at a toy or an animal, does your child look at the toy or animal?',
        answer:''
    },
    {
        question:'Does your child get upset by everyday noises?',
        example:'Does your child scream or cry to noise such as a vacuum cleaner or loud music?',
        answer:''
    },
    {
        question:'Does your child walk?',
        example:'',
        answer:''
    },
    {
        question:'Does your child look you in the eye when you are talking to him or her, playing with him or her, or dressing him or her?',
        example:'',
        answer:''
    },
    {
        question:'Does your child try to copy what you do?',
        example:'Wave bye-bye, clap, or make a funny noise when you do.',
        answer:''
    },
    {
        question:'If you turn your head to look at something, does your child look around to see what you are looking at?',
        example:'',
        answer:''
    },
    {
        question:'Does your child try to get you to watch him or her?',
        example:'Does your child look at you for praise, or say “look” or “watch me”?',
        answer:''
    },
    {
        question:'Does your child understand when you tell him or her to do something?',
        example:'If you don’t point, can your child understand “put the book on the chair” or “bring me the blanket”?',
        answer:''
    },
    {
        question:'If something new happens, does your child look at your face to see how you feel about it?',
        example:'If he or she hears a strange or funny noise, or sees a new toy, will he or she look at your face?',
        answer:''
    },
    {
        question:'Does your child like movement activities?',
        example:'being swung or bounced on your knee.',
        answer:''
    },
]

