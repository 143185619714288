import React,{useState,useEffect} from 'react'
import {useSelector,useDispatch} from 'react-redux'
import { Table} from 'antd'
import { toast } from 'react-toastify'
import {baseURL, Encrypt, headers} from '../../Config'
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import ClinicianDashboardNavbar from '../../Components/ClinicianDashboardNavbar'
import { getTimeInLocalString } from '../../TimeFormate'
import {Link} from 'react-router-dom'
import './CSS/Sessions.css'

export default function Sessions() {

    const dispatch = useDispatch()

    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const [sessions1,setSessions1] = useState([])
    const [sessions,setSessions] = useState([])
    const [searchText,setSearchText] = useState('')
    const [loader,setLoader] = useState([{},{},{},{}])

    const sidebarWidth = useSelector(data => data.SidebarReducer.width)

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })

        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
          fetch(baseURL + '/api/getSessionsByBCBAId?Id='+dt.uid,{
            headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
          })
          .then(res=>res.json())
          .then(res2=>{
            if(res2?.code === 'Success'){
              setSessions(res2?.message)
              setSessions1(res2?.message)
            }
          })
        }
    },[])

    const handleSearch = () =>{
      if(searchText){
        const newArr = sessions1.filter((dt)=>dt.parentName.toLowerCase() == searchText.toLowerCase())
        setSessions(newArr)
      }
      else{
          setSessions(sessions1)
      }
    }

    const handleGetFile = (Id) =>{
      const dt = JSON.parse(localStorage.getItem('bx_user_token'))
      if(dt&&Id){
          fetch(baseURL + '/api/getSessionReport?Id='+Id,{
            headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
          })
          .then(res=>res.json())
          .then(res2=>{
            if(res2?.code === 'Success'){
              if(res2?.message){
                const a = document.createElement('a')
                a.href = res2?.message
                a.target = '_blank'
                a.click();
              }
              else{
                toast.error('Report not uploaded yet.',{toastId:'no-file-id',autoClose:3000})
              }
            }
          })
      }
    }

    return (
        <div className="bc-dashboard-sessions-container">
            <ParentDashboardSideBar active="sessions" user="BCBA" />
            <div style={{marginLeft:windowWidth > 1024 && sidebarWidth}}>
                <ClinicianDashboardNavbar active="sessions" user="BCBA" showAppointments={false} />
                <div className="bc-dashboard-sessions-body">
                    <div style={{width:'100%',marginTop:0}}>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems:'flex-end'}}>
                            <h2 style={{fontFamily:'CocoSharp bold'}} className="mb-0">Sessions</h2>
                            <div style={{display:'flex',alignItems:'center'}}>
                                <input 
                                    type='text' 
                                    style={{border:'solid 1px #C4C4C4',padding:10,borderRadius:7,width:'310px'}}
                                    placeholder="Search by parent"
                                    value={searchText}
                                    onChange={(e)=>setSearchText(e.target.value)}
                                />

                                <button className="clinician-sessions-search-btn" onClick={handleSearch}>Search</button>
                            </div>
                        </div>
                        <Table 
                            columns={[
                              {
                                title: 'Behavior Consultant',
                                dataIndex: 'bcName',
                                render:(data,dt)=> <Link to={`/therapist-profile-detail/behaviour_consultant/${dt.bcId}`}>{dt.bcName}</Link>
                              },
                              {
                                title: 'Behavior Intervener',
                                dataIndex: 'rbtName',
                                render:(data,dt)=> <Link to={`/therapist-profile-detail/rbt/${dt.rbtId}`}>{dt.rbtName}</Link>
                              },
                              {
                                title: 'Date',
                                dataIndex: 'sessionDateTime',
                                render:(data,dt)=>getTimeInLocalString(dt.sessionDateTime).split(' ')[0] + ' ' + getTimeInLocalString(dt.sessionDateTime).split(' ')[1] + ' ' + getTimeInLocalString(dt.sessionDateTime).split(' ')[2] + ' ' + getTimeInLocalString(dt.sessionDateTime).split(' ')[3]
                              },
                              {
                                title: 'Time',
                                dataIndex: 'sessionDateTime',
                                render:(data,dt)=>getTimeInLocalString(dt.sessionDateTime).split(' ')[4] + ' ' + getTimeInLocalString(dt.sessionDateTime).split(' ')[5]
                              },
                              {
                                title: 'Parent',
                                dataIndex: 'parentName',
                                render:(data,dt)=> <Link to={`/parent-detail/${dt.parentId}`}>{dt.parentName}</Link>
                              },
                              {
                                title: 'Child',
                                dataIndex: 'child_name',
                                render:(data,dt)=> <Link to={`/child-detail/${dt.childId}`}>{dt.child_name}</Link>
                              },
                              // {
                              //   title: 'Session type',
                              //   dataIndex: 'status',
                              //   render:(data,dt)=> dt.type == '0' ? "Inperson" : 'Virtual'
                              // },
                              {
                                title: 'Status',
                                dataIndex: 'status',
                                render:(data,dt)=> dt.status >= 3 ? <Link to="#" onClick={()=>handleGetFile(dt.Id)}>View Report</Link> : 'Not Completed'
                              }]} 
                            dataSource={sessions}
                            className="clinician-sessions-table"
                        />
                    </div>
                </div>
            </div>
            
        </div>
    )
}


