import React,{useState,useEffect} from 'react'
import './CSS/InputComp.css'

export default function InputComp(props) {

    const [focus,setFocus] = useState(false)

    return (
        <fieldset className={props.isError ? "fld_set_err" : focus ? "fld_set_fcs" : "fld_set"} style={props.containerStyle}>
            <legend className={props.isError ? "legend2" : focus ? "legend1" : "legend"} style={props.labelStyle}>
                {props.label}{props.label === 'Cardholder Name' || props.label === 'Card Number' || props.label === 'Expiry Date' || props.label === 'CVV' ? <span style={{color:'#CC1527'}}>*</span> : null}</legend>
            <input 
                onFocus={()=>setFocus(true)} 
                onBlur={()=>(setFocus(false))}
                type={props.type || 'text'} 
                className="input form-control" 
                onChange={(e)=>props.onChange(e)}
                onInput={(e)=>props.inputLength(e)}
                value={props.value}
                disabled={props.disabled || false}
                max={props.type === 'date'? !props.min&&new Date().toISOString().split('T')[0]  : null}
                min={props.type === 'date'? props.min : "0"}
                onKeyPress={props.type === 'date'? (e)=>{ 
                    e.preventDefault()
                    return false} : null}
            />
        </fieldset>
    )
}
