import React from "react";
import img from "../../assets/pediatricians/refer.png";
import PinkBtn from "../../Components/PinkBtn";
function Refer() {
  return (
    <div className="pt-8 lg:pt-0 gap-20 flex justify-between items-center flex-col lg:flex-row w-full bg-[#E8FCFF] mt-[100px] lg:gap-7">
      <div className="flex justify-start items-start flex-col pl-5 xl:pl-[120px] gap-4">
        <h1 className="text-lightGreen-500 font-bold text-4xl lg:text-4xl xl:text-5xl">
          Cognitia Referrals
        </h1>
        <p className="text-gray-500 text-justify font-normal text-xl max-w-[600px]">
          A member of our Patient Care Services team will contact the family
          within hours of receipt of your referral to schedule their
          consultation.
        </p>
        <PinkBtn text={"Refer a Patient"} url={"ReferalForm"} target="_blank" />
      </div>
      <img src={img} className="object-contain self-end lg:self-auto" alt="" />
    </div>
  );
}

export default Refer;
