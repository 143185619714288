import React from 'react'
import {Link} from 'react-router-dom'
import './CSS/ProgramCard.css'

export default function ProgramCard({name,path,onClick}) {
    return (
            <div className='program-card-container'>
                <p style={{fontFamily:'CocoSharp bold',color:'#096E82',fontSize:22,margin:0}}>{name}</p>
                <Link 
                    to={path} 
                    // onClick={onClick}
                    target="_blank"
                    className="child-card-select-btn"
                >
                    Continue
                </Link>
            </div>
        )
}
