 import React,{useState,useEffect,useRef} from 'react'
import {useSelector,useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import { Table } from 'antd'
import { io } from "socket.io-client";
import {baseURL, headers, socketURL} from '../../Config'
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import PackageCard from '../../Components/PackageCard'
import ParentDashboardNavbar from '../../Components/ParentDashboardNavbar'
import './CSS/Sessions.css'
import { convertIntoUtc, getcurrentDateTime, getTimeInLocalString } from '../../TimeFormate'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

export default function Sessions(props) {

    const history = useHistory()
    const dispatch = useDispatch()
    const socket = useRef(null)
    socket.current = io(socketURL)

    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const [type,setType] = useState(null)
    const [data,setData] = useState(null)
    const [loader,setLoader] = useState([{},{},{},{}])

    const sidebarWidth = useSelector(data => data.SidebarReducer.width)

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })

        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
          fetch(baseURL + '/api/getChildSessions?Id='+props.match.params.id,{
            headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
          })
          .then(res=>res.json())
          .then(res2=>{
            if(res2?.code === 'Success'){
              setType(res2?.message?.dataType)
              setData(res2?.message)
            }
          })
        }
    },[])

    socket.current.on('requestStartInpersonSession',(dt)=>{
      const newData = JSON.parse(dt)

      const data1 = JSON.parse(localStorage.getItem('bx_user_token'))
      if(data1&&newData?.parentId === data1?.uid){
        const newArr = data?.data?.map(dat=>{
          if(dat.sessionId === newData?.sessionId){
            dat.sessionStatus = 1
            return dat
          }
  
          return dat
        })
        
        newArr&&
        setData({dataType:data?.dataType,data:newArr})
      }
    })

    socket.current.on('requestStartVirtualSession',(dt)=>{
      const newData = JSON.parse(dt)

      const data1 = JSON.parse(localStorage.getItem('bx_user_token'))
      if(data1&&newData?.parentId === data1?.uid){
        const newArr = data?.data?.map(dat=>{
          if(dat.sessionId === newData?.sessionId){
            dat.sessionStatus = 1
            dat.token = newData?.token
            dat.channelName = newData?.channelName
            return dat
          }
  
          return dat
        })
        
        newArr&&
        setData({dataType:data?.dataType,data:newArr})
      }
    })

    socket.current.on('sessionCompleted',(dt)=>{
      const id = JSON.parse(dt)?.Id

      if(id){
        const newArr = data?.data?.map(dat=>{
          if(dat.sessionId === id){
            dat.sessionStatus = 3
            return dat
          }
  
          return dat
        })
        
        newArr&&
        setData({dataType:data?.dataType,data:newArr})
      }
    })

    socket.current.on('sessionType',(dt)=>{
      const {Id,key} = JSON.parse(dt)

      if(Id&&key){
        const newArr = data?.data?.map(dat=>{
          if(dat.sessionId === Id){
            dat.type = key
            return dat
          }
  
          return dat
        })
        
        newArr&&
        setData({dataType:data?.dataType,data:newArr})
      }
    })

    const handleJoinSession = (sessionData) =>{
      const dt = JSON.parse(localStorage.getItem('bx_user_token'))
      if(dt){
        const date = getcurrentDateTime()
        const utc_date = convertIntoUtc(date)

        const obj = {
          date:utc_date,
          sessionId:sessionData?.sessionId
        }

        fetch(baseURL + '/api/joinSessionByParent',{
          method:'Post',
          body:JSON.stringify(obj),
          headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
        })
        .then(res=>res.json())
        .then(res2=>{
          if(res2?.code === 'Success'){
            const newArr = data?.data?.map(dat=>{
              if(dat.sessionId === res2?.message){
                dat.sessionStatus = 2
                return dat
              }
      
              return dat
            })
            
            newArr&&
            setData({dataType:data?.dataType,data:newArr})

            sessionData?.type == '1' &&
            history.push(`/virtual-session/${sessionData?.sessionId}`)
          }
          else{
            toast.error(res2?.message,{toastId:'session_id1',autoClose:3000})
          }
        })
      }
    }


    return (
        <div className="parent-dashboard-sessions-container">
            <ParentDashboardSideBar active="sessions" />
            <div style={{marginLeft:windowWidth > 1024 && sidebarWidth}}>
                <ParentDashboardNavbar active="sessions" showAppointments={true} />
                <div className="parent-dashboard-sessions-body">
                  {
                    type == 0 ?
                      <h4 style={{color:'lightgray'}}>No Sessions</h4>
                    :type == 1 ?
                      <PackageCard 
                        price={((data?.price - data?.approved_amount || 0) / data?.total_sessions).toFixed(1)}
                        totalPrice={data?.price}
                        approvedAmount={data?.approved_amount}
                        file={data?.file}
                        title={data?.title} 
                        detail={data?.description}
                        sessions={data?.total_sessions}
                        childId={props.match.params.id}
                        packageId={data?.Id}
                      />
                    :
                      <div style={{width:'100%',marginTop:0}}>
                          <div style={{display:'flex',justifyContent:'space-between',alignItems:'flex-end'}}>
                              <h2 style={{fontFamily:'CocoSharp bold'}} className="mb-0">Sessions</h2>
                              {
                                type == 2 &&
                                <button 
                                  onClick={()=>history.push(`/parent-set-availability/${props.match.params.id}/${data?.Id}`)} 
                                  className="parent-sessions-search-btn">Schedule Sessions</button>
                              }
                          </div>
                          <Table 
                              columns={[
                                {
                                  title: 'Behavior Intervener',
                                  dataIndex: 'rbtName',
                                  render:(data,dt)=> <Link>{dt.rbtName}</Link>
                                },
                                {
                                  title: 'Date',
                                  dataIndex: 'sessionDateTime',
                                  render:(data,dt)=>getTimeInLocalString(dt.sessionDateTime).split(' ')[0] + ' ' + getTimeInLocalString(dt.sessionDateTime).split(' ')[1] + ' ' + getTimeInLocalString(dt.sessionDateTime).split(' ')[2] + ' ' + getTimeInLocalString(dt.sessionDateTime).split(' ')[3]
                                },
                                {
                                  title: 'Time',
                                  dataIndex: 'sessionDateTime',
                                  render:(data,dt)=>getTimeInLocalString(dt.sessionDateTime).split(' ')[4] + ' ' + getTimeInLocalString(dt.sessionDateTime).split(' ')[5]
                                },
                                {
                                  title: 'Session Type',
                                  dataIndex: 'type',
                                  render:(data,dt)=>dt.type == '0' ? 'In Person' : 'Virtual'
                                },
                                {
                                  title: 'Status',
                                  dataIndex: 'sessionStatus',
                                  render:(data,dt)=> dt.sessionStatus === 0 ? 'Up coming' :dt.sessionStatus === 1 ? 'Pending' :dt.sessionStatus === 2 ? 'Ongoing' : 'Completed'
                                },
                                {
                                  title: 'Action',
                                  dataIndex: 'sessionStatus',
                                  render:(data,dt)=> (
                                      (dt.type == 0 && dt.sessionStatus == 1) || (dt.type == 1 && (dt.sessionStatus == 1 || dt.sessionStatus == 2)) ?
                                        <button 
                                          className="parent-sessions-search-btn" 
                                          style={{marginLeft:0}} 
                                          onClick={()=>handleJoinSession(dt)}
                                        >Join Session</button>
                                      :null
                                  )
                                }
                              ]} 
                              dataSource={data?.data}
                              className="parent-sessions-table"
                          />
                      </div>
                  }
                </div>
            </div>
            
        </div>
    )
}


