import React from 'react'
import { Link } from 'react-router-dom'
import './CSS/DashboardHomeCard.css'

export default function DashboardHomeCard(props) {


    return (
        <div className={props.row&&props.row === "2" ? "dashboard-home-card-container second-row" : "dashboard-home-card-container"} style={props.style}>
            <div className="dashboard-home-card-col-1">
                {
                    props.display !== 'none' &&
                    <div>
                        <h6>{props.title}</h6>
                        <hr />
                    </div>
                }
                
                
                {/* <div className="w-100"> */}
                {
                    props.display !== 'none' &&
                    <Link to={props.path}  className="dashboard-home-card-btn">
                        {props.buttonTitle}
                    </Link>
                }
                    
                {/* </div> */}
                
            </div>
            <div className="dashboard-home-card-col-2">
                <img src={props.imageUrl} className="img-fluid" loading="lazy"  />
            </div>
            {
                props.isDisable&&
                <div className="disabled-card"></div>
            }
        </div>
    )
}
