import React from "react";
import img from "../../assets/Home/redefine.png";
import patt from "../../assets/Home/model-patt.png";
import pattGreen from "../../assets/Home/redefine-green.png";
import pattPink from "../../assets/Home/redefine-pink.png";
import PinkBtn from "../../Components/PinkBtn";

function Redefine() {
  return (
    <div className=" xl:pb-[250px] w-full flex justify-center items-center mt-[140px] xl:mt-[180px] px-5 relative isolate">
      <div className="xl:flex-row flex-col max-w-[1440px] flex justify-center items-center gap-16 xl:gap-8 w-full">
        <div className="relative isolate  flex justify-end items-center">
          <img
            src={patt}
            className="absolute -z-[1] -top-[10%] sm:-left-[8%] h-full -left-[4%]"
            alt=""
          />
          <img
            src={img}
            alt=""
            className="w-full max-w-[400px] xl:max-w-[522px]"
          />
        </div>
        <div className="flex justify-center items-start flex-col gap-4 xl:w-1/2">
          <h1 className="text-lightGreen-500 leading-9 sm:leading-[50px] font-bold text-[30px] sm:text-[48px]  ">
            Integrating ABA Research <br /> with Technology
          </h1>
          <p className="text-lightGreen-500 font-normal text-lg ">
            At Cognitia, we assume ability and not disability! We focus on the
            unique needs of your child. Our programs are highly effective and
            research-validated. We measure your child’s progress using
            sophisticated data collection tools and modify treatment when
            necessary.
          </p>
          <p className="text-lightGreen-600 font-bold text-2xl ">
            Simply stated, we provide treatment that works!
          </p>
          <div className="mt-6">
            <PinkBtn text={"Get Started"} url={"Signup"} />
          </div>
        </div>
      </div>
      <img
        src={pattPink}
        className="xl:block hidden absolute bottom-0 -z-[1] left-0 w-full"
        alt=""
      />
      <img
        src={pattGreen}
        className="xl:block hidden absolute bottom-[10%] -z-[2] left-0 w-full"
        alt=""
      />
    </div>
  );
}

export default Redefine;
