import React from "react";
import team1 from "../../assets/Home/team/team1.png";
import team2 from "../../assets/Home/team/team2.png";
import team3 from "../../assets/Home/team/team3.png";
import team4 from "../../assets/Home/team/team4.png";
import team5 from "../../assets/Home/team/team5.png";
import team10 from "../../assets/Home/team/team10.png";
import Sumaiya from "../../assets/Home/team/Sumaiya.png";
import PinkBtn from "../../Components/PinkBtn";
export default function Team() {
  return (
    <div className="  w-full flex justify-center items-center mt-20 px-5 ">
      <div className="flex-col max-w-[1440px] flex justify-center items-center gap-16 xl:gap-8 w-full">
        <h1 className="text-lightGreen-500 leading-9 sm:leading-[50px] font-bold  text-[48px]  ">
          Our Experts
        </h1>
        <div className="lg:grid flex justify-center items-center flex-col  lg:grid-cols-3 w-full lg:grid-rows-2 lg:place-items-center gap-7 mt-2 lg:mt-20">
          <div className="flex justify-center lg:justify-evenly items-center flex-col lg:flex-row w-full lg:col-span-4 gap-7 lg:gap-0">
            <div className="flex justify-center items-center flex-col gap-1">
              <img src={team1} alt="" />
              <h2 className="text-lightGreen-500  font-bold text-[18px]">
                Dr. Linda Heitzmann-Powell
              </h2>
              <p className="text-lightGreen-500  font-normal text-[16px]">
                PhD., BCBA-D
              </p>
            </div>
            <div className="flex justify-center items-center flex-col gap-1">
              <img src={team2} alt="" />
              <h2 className="text-lightGreen-500  font-bold text-[18px]">
                Dr. Monika Stephany
              </h2>
              <p className="text-lightGreen-500  font-normal text-[16px]">
                Ph.D, BCBA-D
              </p>
            </div>
          </div>
          {/* <div className="lg:row-start-2 lg:row-end-3 flex justify-center items-center flex-col gap-1">
            <img src={team3} alt="" />
            <h2 className="text-lightGreen-500  font-bold text-[18px]">
              Vanessa Snyder
            </h2>
            <p className="text-lightGreen-500  font-normal text-[16px]">
              MA, LPC, BCBA
            </p>
          </div> */}
          <div className="lg:row-start-2 lg:row-end-3 flex justify-center items-center flex-col gap-1">
            <img src={team10} alt="" />
            <h2 className="text-lightGreen-500  font-bold text-[18px]">
              Carmen Fong
            </h2>
            <p className="text-lightGreen-500  font-normal text-[16px]">
              M. Ed. ABA, BCBA
            </p>
          </div>
          <div className="lg:row-start-2 lg:row-end-3 flex justify-center items-center flex-col gap-1">
            <img src={team4} alt="" />
            <h2 className="text-lightGreen-500  font-bold text-[18px]">
              Paul Stephany
            </h2>
            <p className="text-lightGreen-500  font-normal text-[16px]">
              MFT, BCBA
            </p>
          </div>
          {/* <div className="lg:row-start-2 lg:row-end-3 flex justify-center items-center flex-col gap-1">
            <img src={team5} alt="" />
            <h2 className="text-lightGreen-500  font-bold text-[18px]">
              Hanna Traphagan
            </h2>
            <p className="text-lightGreen-500  font-normal text-[16px]">
              M. Ed
            </p>
          </div> */}
          <div className="lg:row-start-2 lg:row-end-3 flex justify-center items-center flex-col gap-1">
            <img src={Sumaiya} alt="" />
            <h2 className="text-lightGreen-500  font-bold text-[18px]">
              Sumaia Agha
            </h2>
            <p className="text-lightGreen-500  font-normal text-[16px]">
              MSc. ABA, BCBA
            </p>
          </div>
        </div>
        <PinkBtn text={"Meet The Team"} url={"Team"} />
      </div>
    </div>
  );
}
