import React,{useState} from 'react'
import Navbar from '../../../Components/Navbar';
import Footer from '../../../Components/Footer';
import InsuranceForm from '../../../Components/InsuranceForm';
import {Required} from '../../../Validations';
import {baseURL,Encrypt, headers} from '../../../Config';
import {useHistory} from 'react-router-dom'
import {Col,Spinner} from 'react-bootstrap';
import './CSS/InsurancePay.css';
import { toast } from 'react-toastify';

export default function InsurancePay() {

    const history = useHistory()

    const [company,setCompany] = useState('')
    const [insurancePlan,setInsurancePlan] = useState('')
    const [holderName,setHolderName] = useState('')
    const [insuranceId,setInsuranceId] = useState('')
    const [expiryDate,setExpiryDate] = useState('')
    const [isLoading,setIsLoading] = useState(false)

    const handleConfirmPayment = () =>{

        const data = JSON.parse(localStorage.getItem('bx_user_token'))

        const isDone = Required('Company Name',company)&&Required('Insurance Plan',insurancePlan)&&Required('Holder name',holderName) &&
                        Required('Insurance Id',insuranceId)&&Required('Expiry Date',expiryDate.toString())
        

        if(isDone){
            const date = expiryDate.toString().split(' ')
            const newDate = date[0] + ' ' + date[1] + ' ' + date[2] + ' ' + date[3] 

            const obj = {
                companyName:Encrypt(company),
                insurancePlan:Encrypt(insurancePlan),
                holderName:Encrypt(holderName),
                insuranceId:Encrypt(insuranceId),
                expiryDate:Encrypt(newDate),
                totalAmount:Encrypt('1000'),
                Id:data.childId
            }

            setIsLoading(true)

            fetch(baseURL + '/api/payWithInsurance',{
                method:'Post',
                body:JSON.stringify(obj),
                headers:{...headers,'authorization':'Bearer ' + data.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Success'){
                    history.push('/pre-call-questionnaire')
                    setIsLoading(false)
                }
                else{
                    toast.error(res2.message,{toastId:'insurance-req-err-id1',autoClose:3000})
                    setIsLoading(false)
                }
            })
        }
    }

    return (
        <div style={{width:'100%'}}>
            <Col sm='12' md='12' lg='12' xl='12' >
                <div className="Insurance-pay-Page-Container">
                    <div className="Insurance-pay-Page-Body">
                        <h1 style={{fontFamily:'CocoSharp bold'}}>Insurance Details</h1>
                        <hr /> 
                        <div style={{width:'100%',marginTop:25}}>
                            <InsuranceForm 
                                companyVal={company}
                                setCompany={(val)=>setCompany(val)}

                                insurancePlanVal={insurancePlan}
                                setInsurancePlan={(val)=>setInsurancePlan(val)}

                                holderNameVal={holderName}
                                setHolderName={(val)=>setHolderName(val)}

                                insuranceIdVal={insuranceId}
                                setInsuranceId={(val)=>setInsuranceId(val)}

                                expiryDateVal={expiryDate}
                                setExpiryDate={(val)=>setExpiryDate(val)}
                            />
                            <br/>
                            {
                                isLoading ?
                                    <button className="Insurance-pay-btn">
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        {' '}
                                        Loading....
                                    </button>
                                :
                                    <button className="Insurance-pay-btn" onClick={handleConfirmPayment}>Confirm</button>
                            }
                        </div>
                    </div>
                </div>
            </Col>  
        </div>
    )
}
