import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Header'
import Informed from '../Components/Informed'
import Footer from '../Components/Footer'
import ellipseImg from '../images/circleImg.svg'
import Skeleton from 'react-loading-skeleton';
import './CSS/PrivacyPolicy.css'
import { baseURL } from '../Config';

export default function PrivacyPolicy() {

    const [content,setContent] = useState('')
    const [isLoaded,setIsLoaded] = useState(false)

    useEffect(()=>{
        fetch(baseURL+'/api/getPrivacyPolicy')
        .then(res=>res.json())
        .then(res2=>{
            if(res2.code === 'Success'){
                setIsLoaded(true)
                setContent(res2?.message[0]?.description)
            }
            else {
                setIsLoaded(true)
            }
        })
    },[])

    return (
        <div>
            <div className="container-fluid">
                <Navbar />
            </div>
            <div className="pp-inner-container sections-horizontal-padding">
                <h1 className="pp-heading">Privacy Policy</h1>
                <hr className="pp-divider" />
                <div className='pp-content'>
                    {
                        isLoaded ?
                            content != '' ?
                                <div dangerouslySetInnerHTML={{__html:content}}></div>
                            : null
                        :
                            <div>
                                <div>
                                    <Skeleton width='100%' height={15} />
                                    <Skeleton width='100%' height={15} />
                                    <Skeleton width='100%' height={15} />
                                    <Skeleton width='50%' height={15} />
                                </div>
                                <div className='mt-4'>
                                    <Skeleton width='100%' height={15} />
                                    <Skeleton width='100%' height={15} />
                                    <Skeleton width='20%' height={15} />
                                </div>
                                <div className='mt-4'>
                                    <Skeleton width='100%' height={15} />
                                    <Skeleton width='100%' height={15} />
                                    <Skeleton width='100%' height={15} />
                                    <Skeleton width='100%' height={15} />
                                    <Skeleton width='100%' height={15} />
                                    <Skeleton width='70%' height={15} />
                                </div>
                                <div className='mt-4'>
                                    <Skeleton width='100%' height={15} />
                                    <Skeleton width='100%' height={15} />
                                    <Skeleton width='100%' height={15} />
                                    <Skeleton width='40%' height={15} />
                                </div>
                            </div>
                    }
                   
                </div>
                <img src={ellipseImg} className="ellipse-bg" />
            </div>
            <Informed />
            <Footer />
        </div>
    )
}
