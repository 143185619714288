import React from "react";
import Steps from "../Home/Steps";
import Learn from "./Learn";
import Program from "./Programs";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Refer from "../../Components/Informed";

function Work() {
  return (
    <div>
      <Header />
      <div className="bg-lightGreen-200 mt-[100px] pt-10 pb-[60px] lg:pb-[150px]">
        <Steps margin={"mt-0"} />
        
      </div>
      <Learn />
      <Program />
      <Refer />
      <Footer />
    </div>
  );
}

export default Work;
