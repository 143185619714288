import React from 'react'
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
import MChatIntroComp from '../../Components/MChatIntroComp'
import circle1 from '../../images/mchatCircleImg1.svg'
import circle2 from '../../images/mchatCircleImg2.svg'
import vector from '../../images/Vector.png'
import './CSS/IntroScreen.css'

export default function IntroScreen() {

    return (
        <div style={{width:'100%'}}>
            <div className="mchat-intro-screen-container">
                <div className="mchat-intro-screen-body">
                    <MChatIntroComp path="/mchat-screener-questions" />
                </div>
                <img src={vector} style={{position:'absolute',bottom:50,left:0,width:'100%',zIndex:-1000}} /> 
                <img src={circle2} className="mchat-floated-circle circle-2" /> 
            </div>
        </div>
        
    )
}
