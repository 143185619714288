import React from 'react'
import { Link } from 'react-router-dom'
import './CSS/DashboardArticleCard.css'

export default function DashboardArticleCard(props) {
    return (
        <div>
            <a href={props.file} target="_blank">
                <div className="dashboard-article-card-container" style={{background:`rgba(0,0,0,0.4)`,backgroundImage:`url(${props.thumbnail}) `,backgroundBlendMode:'multiply',backgroundPosition:'center',backgroundSize:'cover'}}>

                </div>
            </a>
            <p style={{fontSize:16,fontFamily:'CocoSharp regular',marginTop:5}}>{props.title&&props.title.length > 24 ? props.title.slice(0,24) + '...' : props.title}</p>
        </div>
    )
}
