import React,{useState,useEffect} from 'react'
import AgoraRTC from 'agora-rtc-sdk-ng';
import {useSelector,useDispatch} from 'react-redux'
import {baseURL, headers, socketURL, Decrypt, AGORA_APP_ID} from '../../Config'
import VideoPlayer from './AgoraVideoPlayer'
import CallButtons from './CallButtons'
import CallCounter from './CallCounter'
import {useLocation} from 'react-router-dom'
import ExtendCallConfirmModal from './ExtendCallConfirmModal'
import TestDeviceModal from './TestDeviceModal'
import { io } from "socket.io-client";
import './CSS/callIndex.css'

// Creating client
const client = AgoraRTC.createClient({ codec: 'h264', mode: 'rtc' });

export default function CallIndex(props) {

    const [showTestModal,setShowTestModal] = useState(true)

    const dispatch = useDispatch()
    const [socket] = useState(()=> io(socketURL))

    // States for agora
    const [localVideoTrack, setLocalVideoTrack] = useState(undefined);
    const [localAudioTrack, setLocalAudioTrack] = useState(undefined);
    const [joinState, setJoinState] = useState(false);
    const [remoteUsers, setRemoteUsers] = useState([]);

    // Other States
    const [isMutedVideo,setIsMutedVideo] = useState(false)
    const [isMutedAudio,setIsMutedAudio] = useState(false)
    const [extendCallConfirmation,setExtendCallConfirmation] = useState(false)
    const [audioDevices,setAudioDevices] = useState([])
    const [videoDevices,setVideoDevices] = useState([])

    // Data for meeting
    const meetingData = useSelector(dt=>dt.MeetingReducer.data)
    const extend = useSelector(dt=>dt.MeetingReducer.isExtend)


    // Creating Local Audio and Video track
    const createLocalTracks = async (audioDeviceId, videoDeviceId)=>{

        let microphoneTrack;
        let cameraTrack;

        try{   
          cameraTrack = await AgoraRTC.createCameraVideoTrack({
            cameraId:videoDeviceId,
            encoderConfig: {
              width: 640,
              // Specify a value range and an ideal value
              height: { ideal: 480, min: 400, max: 500 },
              frameRate: 15,
              bitrateMin: 100, bitrateMax: 1000,
            },
          });
          setLocalVideoTrack(cameraTrack);
        }
        catch(err){
          if(err.code === 'DEVICE_NOT_FOUND'){
            setShowTestModal(true)
          }
        }
        
        try{
          microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack({
            microphoneId:audioDeviceId,
            AEC:true,
            ANS:true
          });
          setLocalAudioTrack(microphoneTrack);
        }
        catch(err){
          if(err.code === 'DEVICE_NOT_FOUND'){
            setShowTestModal(true)
          }
        }

        return [microphoneTrack, cameraTrack];
    }

    // Function for join channel
    const join = async (appid, channel, token, audioDeviceId, videoDeviceId) => {
        if (!client) return;
        const [microphoneTrack, cameraTrack] = await createLocalTracks(audioDeviceId, videoDeviceId);

        await client.join(appid, channel, token || null);

        await client.publish([microphoneTrack, cameraTrack]).catch(err=>console.log(err));

        (window).client = client;
        (window).videoTrack = cameraTrack;
    
        setJoinState(true);
    }
    
    //For join meeting
    // useEffect(async()=>{

      // const data = JSON.parse(localStorage.getItem('agora-token'))

      // if(data){
      //   join(AGORA_APP_ID, Decrypt(data.channelName), Decrypt(data.token))
      // }

      // AgoraRTC.getDevices().then(devices=>{
      //   const audioDevices = devices.filter(function(device){
      //     return device.kind === "audioinput" && device.deviceId !== 'default' && device.deviceId !== 'communications';
      //   });
      //   const videoDevices = devices.filter(function(device){
      //       return device.kind === "videoinput";
      //   });
      //   setAudioDevices(audioDevices)
      //   setVideoDevices(videoDevices)
      // })

    // },[])


    useEffect(()=>{
      
      AgoraRTC.onMicrophoneChanged = async (changedDevice) => {
        // When plugging in a device, switch to a device that is newly plugged in.
        if (changedDevice.state === "ACTIVE") {
            const newDevices = audioDevices
            if(newDevices.length > 0) {
              newDevices.push(changedDevice.device)
              setAudioDevices(newDevices)
            }
            else{
              setAudioDevices([changedDevice.device])
            }
            
        } 
        else{
            const newArr = audioDevices.filter(dt=>dt.deviceId !== changedDevice.device.deviceId)
            setAudioDevices(newArr)
            setShowTestModal(true)
        }
      }
    },[AgoraRTC.onMicrophoneChanged])


    useEffect(()=>{
      
      AgoraRTC.onCameraChanged = async (changedDevice) => {
        // When plugging in a device, switch to a device that is newly plugged in.
        if (changedDevice.state === "ACTIVE") {
            const newDevices = videoDevices
            if(newDevices.length > 0) {
              newDevices.push(changedDevice.device)
              setVideoDevices(newDevices)
            }
            else{
              setVideoDevices([changedDevice.device])
            }
            
        } 
        else{
            const newArr = videoDevices.filter(dt=>dt.deviceId !== changedDevice.device.deviceId)
            setVideoDevices(newArr)
            setShowTestModal(true)
        }
      }
    },[AgoraRTC.onCameraChanged])


    // leaving call
    const leave = async () => {
        if (localAudioTrack) {
          localAudioTrack.stop();
          localAudioTrack.close();
        }
        if (localVideoTrack) {
          localVideoTrack.stop();
          localVideoTrack.close();
        }
        setRemoteUsers([]);
        setJoinState(false);
        await client.unpublish();
        await client.leave();
        localStorage.removeItem('agora-token')
        localStorage.removeItem('bx-devices')
        props.handleCallCompleteModal(true)
    }


    // mute audio
    const handleMuteAudio = async () =>{
        await localAudioTrack.setEnabled(false)
        setIsMutedAudio(true)
    }
    
    // unmute audio
    const handleUnMuteAudio = async () =>{
        await localAudioTrack.setEnabled(true)
        setIsMutedAudio(false)
    }
    
    // mute video
    const handleMuteVideo = async () =>{
      if(localVideoTrack){
        await localVideoTrack.setEnabled(false)
        setIsMutedVideo(true)
      }
    }
    
    // unmute video
    const handleUnMuteVideo = async () =>{
      if(localVideoTrack){
        await localVideoTrack.setEnabled(true)
        setIsMutedVideo(false)
      }
    }

    // extending call request
    const handleExtendCall = () =>{
        socket.emit('extend-call-confirmation',({Id:meetingData&&meetingData.meeting_id}))
        setExtendCallConfirmation(false)
    }


    useEffect(()=>{
        const data = JSON.parse(localStorage.getItem('bx_user_token'))
    
        if(data){
          if(Decrypt(data.userType)  === 'Parent'){
            fetch(baseURL + '/api/getParentMeeting',{
              method:'Post',
              body:JSON.stringify({Id:data.uid}),
              headers:{...headers,'authorization':'Bearer ' + data.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
              if(res2.code === 'Success'){
                dispatch({type:'GetMeeting',payload:res2.message})
              }
            })
          }
        }
    },[])
    
    useEffect(() => {
        if (!client) return;
    
        setRemoteUsers(client.remoteUsers);
    
        const handleUserPublished = async (user, mediaType = ('audio' || 'video')) => {
          await client.subscribe(user, mediaType);
    
          // toggle re-render while state of remoteUsers changed.
          setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
        }
        const handleUserUnpublished = (user) => {
          setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
        }
        const handleUserJoined = (user) => {
          setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
        }
        const handleUserLeft = (user) => {
          setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
        }
    
        client.on('user-published', handleUserPublished);
        client.on('user-unpublished', handleUserUnpublished);
        client.on('user-joined', handleUserJoined);
        client.on('user-left', handleUserLeft);
    
        return () => {
          client.off('user-published', handleUserPublished);
          client.off('user-unpublished', handleUserUnpublished);
          client.off('user-joined', handleUserJoined);
          client.off('user-left', handleUserLeft);
        };
    
    }, [client]);


    const onHandleContinue = (audioDevice , videoDevice) =>{
      const data = JSON.parse(localStorage.getItem('agora-token'))

      if(data){
        join(AGORA_APP_ID, Decrypt(data.channelName), Decrypt(data.token), audioDevice?.value , videoDevice?.value)
        setShowTestModal(false)
      }
    }

    return (
        <div className="agora-video-component-container">
            {
                <CallCounter 
                    data={meetingData}
                    leave={()=>leave()}
                    meetingId={meetingData&&meetingData.meeting_id}
                />
            }
            {
                remoteUsers&&
                remoteUsers.length > 0 ?
                    <VideoPlayer style={{maxWidth:'100%',height:props.user === 'clinician' ? '660px' : '660px',position:'relative',backgroundColor:'black',borderRadius:8}} videoTrack={remoteUsers[0].videoTrack} audioTrack={remoteUsers[0].audioTrack}>
                        <div className={props.view === 'second-view' ? "agora-video-component-row-2" : "agora-video-component-row"}>
                            {props.user === 'clinician'&&<button className="extend-call-btn" onClick={()=>setExtendCallConfirmation(true)}>Extend Call</button>}

                            <CallButtons
                                leave={()=>leave()}
                                isMutedAudio={isMutedAudio}
                                isMutedVideo={isMutedVideo}
                                handleMuteAudio={handleMuteAudio}
                                handleUnMuteAudio={handleUnMuteAudio}
                                handleMuteVideo={handleMuteVideo}
                                handleUnMuteVideo={handleUnMuteVideo}
                                meetingId={meetingData&&meetingData.meeting_id}
                            />
                            <div className="local-user-container">
                                <VideoPlayer style={{width:'100%',height:'100%'}} videoTrack={localVideoTrack} key={localAudioTrack?.uid} ></VideoPlayer>
                            </div>
                        </div>
                        {
                            props.user === 'clinician'&&
                            extendCallConfirmation&&
                            <ExtendCallConfirmModal 
                                handleExtendCall={()=>handleExtendCall()}
                                setExtendCallConfirmation={()=>setExtendCallConfirmation(false)}
                            />
                        }
                    </VideoPlayer> 
                :
                    <div className="" style={{maxWidth:'100%',height:props.user === 'clinician' ? '660px' : '600px',position:'relative',backgroundColor:'black',borderRadius:8}}>
                    {
                        joinState&&
                        <div className={props.view === 'second-view' ? "agora-video-component-row-2" : "agora-video-component-row"}>
                            {props.user === 'clinician'&&<button className="extend-call-btn" onClick={()=>setExtendCallConfirmation(true)}>Extend Call</button>}
                            <CallButtons
                                leave={()=>leave()}
                                isMutedAudio={isMutedAudio}
                                isMutedVideo={isMutedVideo}
                                handleMuteAudio={handleMuteAudio}
                                handleUnMuteAudio={handleUnMuteAudio}
                                handleMuteVideo={handleMuteVideo}
                                handleUnMuteVideo={handleUnMuteVideo}
                                meetingId={meetingData&&meetingData.meeting_id}
                            />
                            <div className="local-user-container">
                                <VideoPlayer style={{width:'100%',height:'100%'}} videoTrack={localVideoTrack} key={localAudioTrack.uid} ></VideoPlayer>
                            </div>
                        </div>
                    }
                    </div>
            }
            
            <TestDeviceModal 
              show={showTestModal} 
              audioDevices={audioDevices} 
              videoDevices={videoDevices}
              handleContinue = {()=>setShowTestModal(false)}
              onContinue={(selectedAudioDevice,selectedVideoDevice)=>onHandleContinue(selectedAudioDevice,selectedVideoDevice)}
            />
        </div>
    )
}
