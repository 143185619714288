import React from "react";
import Behavior from "./Behavior";
import HeroSection from "./Hero";
import KeyFeature from "./Keyfeature";
import Procedure from "./procedure";
import TreatmentDesease from "./TreatmentDisease";
import Principles from "./Principles";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Informed from "../../Components/Informed";

function Autism() {
  return (
    <div>
      <Header />
      <HeroSection />
      <KeyFeature />
      <Procedure />
      <Behavior />
      <Principles />
      <TreatmentDesease />
      <Informed />
      <Footer />
    </div>
  );
}

export default Autism;
