import React from "react";
import img from "../../assets/contact.png";
import PinkBtn from "../../Components/PinkBtn";
import heroDesk from "../../assets/contact-desk.png";
import curve from "../../assets/about/curve.png";
function Hero() {
  return (
    <div className=" relative z-[3] mt-[100px] flex justify-between items-center gap-7 xl:gap-0 bg-lightGreen-100 xl:px-0 xl:flex-row flex-col py-6  xl:py-0">
      <div className="xl:block flex justify-center flex-[0.5] h-full relative z-[3]">
        <img className="w-[100%] xl:block hidden" src={heroDesk} alt="" />
        <img
          className="w-[100%] rounded-lg xl:hidden block xl:rounded-tl-[50%] xl:rounded-bl-[50%] "
          src={img}
          alt=""
        />
      </div>
      <div className="flex-[0.5] xl:pl-14 flex justify-center items-center xl:text-left xl:items-start gap-6 flex-col">
        <h1 className="text-lightGreen-500 font-bold md:text-5xl text-2xl">
          We’re here to help!
        </h1>

        <p className="text-lightGreen-500 font-normal text-lg 2xl:w-3/5">
          Looking to schedule a consultation or do you simply have a question?
          We know it can be overwhelming which is why we’re always here.
        </p>

        {/* <div className="xl:mt-7">
          <PinkBtn text={"View Positions"} />
        </div> */}
      </div>
      <img
        src={curve}
        className="absolute object-cover xl:block hidden bottom-[0%]  left-0 w-full z-10"
        alt=""
      />
    </div>
  );
}

export default Hero;
