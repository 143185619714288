import React, {useState} from 'react';
import {Col,Spinner} from 'react-bootstrap';
import {EmailValidation, Required} from '../../../Validations'
import Navbar from '../../../Components/Navbar';
import InputComp from '../../../Components/InputComp';
import SelectComponent from '../../../Components/SelectComponent';
import InputPassComp from '../../../Components/InputPassComp';
import {Link,useHistory} from 'react-router-dom';
import './CSS/LoginPage.css';
import { baseURL, headers } from '../../../Config';
import { toast } from 'react-toastify';
import {Encrypt,Decrypt} from '../../../Config';
import Header from "../../../Components/Header";
import Footer from "../../../Components/Footer";
import Informed from "../../../Components/Informed";


export default function Login() {

    const history = useHistory()

    const [toggleState, setToggleState] = useState('default');

    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState(false);
    const [password, setPassword] = useState('');
    const [loginAs, setLoginAs] = useState('');
    const [isLoading,setIsLoading] = useState(false)
    const [keepMeIn,setKeepMeIn] = useState(false)


    const toggleTab = (index) => {
        if(index === 1 && toggleState === 'default'){
            setToggleState('default');
        }
        else{
            setToggleState(index)
        }
        
    };

    const handleParentLogin=()=>{
       if(Required('Email',email) && !emailErr && Required('Password',password)){

            const obj={
                email:Encrypt(email),
                password:Encrypt(password)
            }

            setIsLoading(true)
            fetch(baseURL+'/api/parent-login',{
                method:'Post',
                body:JSON.stringify(obj),
                headers:headers
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    setIsLoading(false)
                    toast.error(res2.message,{toastId:'login-form-toast1'})
                }
                else{
                    window.localStorage.setItem('bx_user_token',JSON.stringify(res2.message))

                    if(Decrypt(res2.message.userStatus) >= 1){
                        setIsLoading(false)
                        history.push('/dashboard-home')
                    }
                    else if(Decrypt(res2.message.userStatus) == 0 && Decrypt(res2.message.childStatus) == 0){
                        setIsLoading(false)
                        history.push('/payment-detail')
                    }
                    else if(Decrypt(res2.message.userStatus) == 0 && Decrypt(res2.message.childStatus) == 1){
                        setIsLoading(false)
                        history.push('/pre-call-questionnaire')
                    }
                    else if(Decrypt(res2.message.userStatus) == 0 && Decrypt(res2.message.childStatus) == 2){
                        setIsLoading(false)
                        history.push('/schedule-first-meeting')
                    }
                }
            })
            .catch(err=>{
                setIsLoading(false)
                toast.error('Error',{toastId:'login-form-err-toast1'})
                return Promise.reject()
            })
       }
    }


    const handleClinicianLogin=()=>{

        if(Required('Email',email) && !emailErr && Required('Password',password) && Required('Clinician role',loginAs)){

            const obj={
                email:Encrypt(email),
                password:Encrypt(password)
            }

            const url = loginAs == 'Behavior Consultant' ? '/api/clinician-login' :loginAs == 'BCBA' ? '/api/bcba-login' : '/api/rbt-login' 

            setIsLoading(true)
            fetch(baseURL +  url,{
                method:'Post',
                body:JSON.stringify(obj),
                headers:headers
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    setIsLoading(false)
                    toast.error(res2.message,{toastId:'login-page-toast1'})
                }
                else{
                    setIsLoading(false)
                    window.localStorage.setItem('bx_user_token',JSON.stringify(res2.message))
                    history.push(loginAs == 'Behavior Consultant' ? '/bc-dashboard' :loginAs == 'BCBA' ? '/bcba-dashboard' : '/rbt-appointments')
                }
            })
            .catch(err=>{
                setIsLoading(false)
                toast.error('Error',{toastId:'login-form-err1-toast1'})
                return Promise.reject()
            })
        }
    }

    return (
        <div style={{width:'100%'}}>
            <Header />
            <Col sm='12' md='12' lg='12' xl='12' >
                <div className="Login-Page-Container mt-5">
                    <div className="Login-Page-Body">
                        <h1>Login</h1>
                        <hr /> 
                        <div className={`Login-Page-tabs-container ${toggleState === 2 ? "right" : toggleState === 1 ? "left" : "default"}`}>
                            <button className={toggleState === 1 ? "tabs active-tab" :toggleState === 2 ? "tabs" : "active-default"} 
                            onClick={() => toggleTab(1)}>Parent</button>
                            <button className={toggleState === 2 ? "tabs active-tab" : "tabs"} 
                            onClick={() => toggleTab(2)}>Clinician</button>
                        </div>
                            <div style={{width:'100%'}}>
                                <InputComp
                                    value={email}
                                    onChange={(e)=>EmailValidation(e.target.value) ? (setEmail(e.target.value),setEmailErr(true)) : (setEmail(e.target.value), setEmailErr(false))}
                                    inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
                                    onFocus={true}
                                    isError={emailErr}
                                    containerStyle={{marginTop:30}} 
                                    label="Email *" 
                                    labelStyle={{fontSize:16,fontWeight:600}} 
                                />
                                {emailErr&&<p style={{marginLeft:10,fontSize:12,color:'red'}}>Email should be in format e.g abc@zyx.com</p>}

                                <InputPassComp
                                    value={password}
                                    onChange={(e)=>setPassword(e.target.value)}
                                    inputLength={(e)=>e.target.value = (e.target.value).slice(0,64)}
                                    containerStyle={{marginTop:30}}
                                    label="Password *" 
                                    labelStyle={{fontSize:16,fontWeight:600}} 
                                />

                                {toggleState === 2 && <SelectComponent 
                                    label="Log in as"
                                    containerStyle={{width:'100%',marginTop:30}}
                                    labelStyle={{fontSize:16,fontWeight:600,textAlign:'left'}}
                                    onChange={(val)=>setLoginAs(val)}
                                    options={['BCBA','Behavior Consultant','Behavior Intervener']}
                                />}
                                <br/>
                                <div className="d-flex align-items-center">
                                    <input id="check-box" type="checkbox" checked={keepMeIn} onChange={()=>setKeepMeIn(keepMeIn ? false : true)} name="signin" />
                                    <label className="custom-check-box" htmlFor="check-box">
                                    <span className="check-mark"></span>
                                    </label>
                                    <span className="r-span">Keep me Signed in</span>
                                </div>
                                
                                <br/>
                                {
                                    isLoading ?
                                        <button className="login-btn">
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            {' '}
                                            Loading....
                                        </button>
                                    :
                                        <button className="login-btn" onClick={toggleState === 2 ? handleClinicianLogin : handleParentLogin}>Log in</button>
                                }
                                <br/>
                                <div style={{marginTop:20}}>
                                    <Link to="/forgot-password" className="login-page-link">Forgot Password?</Link><br/>
                                    <p className="colr">Don't have an account? <Link to="/Signup" className="login-page-link">Create Account</Link></p>  
                                </div>        
                                </div>
                    </div>
                </div>
            </Col>
            <Informed />
            <Footer />
        </div>
    );
  }
  

  