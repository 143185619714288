import React from "react";
import img from "../../assets/team/team3.png";
function Paul() {
  return (
    <div className="bg-[#E8FCFF] w-full flex justify-center items-center py-11">
      <div className="max-w-[1440px] flex justify-between flex-col lg:flex-row items-center px-10 w-full gap-10">
        <div className="flex justify-center flex-col items-start gap-1">
          <h1 className="font-bold text-lightGreen-500 text-3xl md:text-5xl">
            Paul Stephany
          </h1>
          <h2 className="font-bold text-lightGreen-500 text-xl md:text-2xl">
            MFT, BCBA
          </h2>
          <h4 className="font-bold text-lightGreen-500 text-md md:text-xl">
            Chief Learning Officer
          </h4>
          <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
            As Chief Learning Officer, Paul plays a vital role in training and developing our team of professionals.
            With his extensive experience as a Board Certified Behavior Analyst (BCBA) and his passion for sharing knowledge,
            Paul is instrumental in shaping the learning and professional growth of COGNITIA’s staff.

          </p>
          <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
            Paul’s deep understanding of principles of behavior and evidence based practices ensures that the training
            programs he develops are grounded in research and tailored to the unique needs of the team.
          </p>
          <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
            Paul’s dedication to excellence extends beyond training. He also contributes to the overall strategic
            vision of COGNITIA, collaborating with leadership to shape the organization’s approach to providing high quality services and
            implementing best practices in the field. His expertise and insights help drive the organization’s
            mission to make a positive impact on the lives of individuals with autism.

          </p>
          {/* <p className="font-normal text-lightGreen-500 text-lg lg:max-w-[680px]">
            Paul is also a Senior Lecturer at the University of Social Sciences
            and Humanities in Warsaw.
          </p> */}
        </div>
        <img src={img} alt="" />
      </div>
    </div>
  );
}

export default Paul;








// As Chief Learning Officer, Paul plays a vital role in training and developing our team of professionals. With his extensive experience as a Board Certified Behavior Analyst (BCBA) and his passion for sharing knowledge, Paul is instrumental in shaping the learning and professional growth of COGNITIA’s staff.

// Paul’s deep understanding of principles of behavior and evidence based practices ensures that the training programs he develops are grounded in research and tailored to the unique needs of the team.

// Paul’s dedication to excellence extends beyond training. He also contributes to the overall strategic vision of COGNITIA, collaborating with leadership to shape the organization’s approach to providing high quality services and implementing best practices in the field. His expertise and insights help drive the organization’s mission to make a positive impact on the lives of individuals with autism. 
