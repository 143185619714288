import React, {useState} from 'react';
import {Col,Spinner} from 'react-bootstrap';
import {EmailValidation, Required} from '../../../Validations'
import Header from "../../../Components/Header";
import Footer from "../../../Components/Footer";
import Informed from "../../../Components/Informed";
import InputComp from '../../../Components/InputComp';
import {Link} from 'react-router-dom';
import './CSS/ForgetPassPage.css';
import { baseURL, headers } from '../../../Config';
import { toast } from 'react-toastify';


export default function ForgetPassPage() {

    const [isLoading,setIsLoading] = useState(false)

    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState(false);

    const handleResetPassword = () =>{
        if(Required('Email',email) && !emailErr){
            setIsLoading(true)
            fetch(baseURL+'/send-reset-email?email='+email,{
                headers:headers
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Success'){
                    setIsLoading(false)
                    toast.success('Password reset link sent to your email...',{autoClose:3000,toastId:'forgot-pass-toast1'})
                }
                else{
                    setIsLoading(false)
                    toast.error(res2?.message,{toastId:'forgot-pass-toast2'})
                }
            })
        }
    }

    return (
        <div style={{width:'100%'}}>
            <Header />
            <Col sm='12' md='12' lg='12' xl='12' >
                <div className="Forgot-Pass-Page-Container mt-5">
                    <div className="Forgot-Pass-Page-Body">
                        <h1 style={{fontFamily:'CocoSharp bold'}}>Forgot Password</h1>
                        <hr /> 
                        <div style={{width:'100%'}}>
                            <InputComp
                                value={email}
                                onChange={(e)=>EmailValidation(e.target.value) ? (setEmail(e.target.value),setEmailErr(true)) : (setEmail(e.target.value), setEmailErr(false))}
                                inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
                                onFocus={true}
                                isError={emailErr}
                                containerStyle={{marginTop:30}} 
                                label="Email" 
                                labelStyle={{fontSize:16,fontWeight:600}} 
                            />
                            {emailErr&&<p style={{marginTop:0,marginLeft:10,fontSize:12,color:'red'}}>Email should be in format e.g abc@zyx.com</p>}
                            <p>Please enter your email address below to receive a password reset link</p>
                            <br/>
                            {
                                isLoading?
                                    <button className="forgot-pass-btn">
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        {' '}
                                        Loading....
                                    </button>
                                :
                                    <button className="forgot-pass-btn" onClick={handleResetPassword}>Reset My Password</button>
                            }
                            <br/>
                            <div style={{marginTop:12}}>
                                <p style={{fontSize:16}}>Do you remember your password? <Link to="/login" className="forgot-pass-page-link">Login</Link></p>  
                            </div>        
                        </div>
                    </div>
                </div>
            </Col>
            <Informed />
            <Footer />
        </div>
    
    );

  }
  

  