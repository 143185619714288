const form1Data = {
    Name:{value:'',isErr:false, isNull:true},
    schoolName:{value:'', isNull:true},
    birthDate:{value:'',isNull:true},
    familyDoc:{value:'',isErr:false,isNull:true},
    grade:{value:'',isNull:true},
    refferingDoc:{value:'',isErr:false,isNull:true},
    primaryLang:{value:'',isErr:false, isNull:true},
    otherLang:{value:'',isErr:false, isNull:true},
    childFirstLang:{value:'',isErr:false},
    ageWhenLeargedEng:0,
    birthCountry:'',
    ageWhenArrivedCanada:0,
    livesWith:[{
        parent:1,
        isLived:'Yes',
        Name:{value:'',isErr:false, isNull:true},
        Age:{value:'', isNull:true},
        Occupation:'',
        educationLevel:'',
        cell:{value:'', isNull:true},
        email:{value:'',isErr:false, isNull:true},
    },
    {
        parent:2,
        isLived:'No',
        Name:{value:'',isErr:false, isNull:true},
        Age:{value:'', isNull:true},
        Occupation:'',
        educationLevel:'',
        cell:{value:'', isNull:true,isLimitErr:false},
        email:{value:'',isErr:false, isNull:true},
    }],
    maritalStatusOfParent:[{status:'Married',isSelected:false},{status:'Separated',isSelected:false},{status:'Divorced',isSelected:false},{status:'Widowed',isSelected:false},{status:'Other',isSelected:true}],
    legalCustody:[{custody:'Mother',isSelected:false},{custody:'Father',isSelected:false},{custody:'Shared',isSelected:false},{custody:'Other',isSelected:true}],
    otherRelatives:[
        {Id:1,name:{value:'',isErr:false},age:0,relationShip:'',livesInHome:'Yes'},
        {Id:2,name:{value:'',isErr:false},age:0,relationShip:'',livesInHome:'Yes'},
        {Id:3,name:{value:'',isErr:false},age:0,relationShip:'',livesInHome:'Yes'}
    ]
}

export default form1Data