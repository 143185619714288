import Form6State from '../../States/OnBoardingFormStates/Form6State'

const Form6Reducer = (state = Form6State, action) => {
    switch (action.type) {
        case 'form6-draft-data': {
            state = action.payload
            return state
        }
        case 'onChangeChildFavouriteActivitiesForm6': {
            state.childFavouriteActivities = action.payload
            return state
        }
        case 'onChangeBestWayToHelpChildForm6': {
            state.bestWayToHelpChild = action.payload
            return state
        }
        case 'onChangeAssetsOrStrengthsForm6': {
            state.assetsOrStrengths = action.payload
            return state
        }
        case 'onChangeMostProudMomentForm6': {
            state.mostProudMoment = action.payload
            return state
        }
        case 'onChangeOtherInformationForm6': {
            state.otherInformation = action.payload
            return state
        }
        case 'onChangeChildGenderForm6': {
            state.childGender = action.payload
            return state
        }
        case 'onChangeImageForm6': {
            state.imageUrl = action.payload
            return state
        }
        case 'onChangeDocumentsForm6': {
           const newArr = state.documents.concat(action.payload)
            return {
                ...state,
                documents:newArr
            }
        }
        case 'onChangeDeleteDocumentForm6': {
           const newArr = state.documents.filter((dt,i)=>{
               return i !== action.payload
           })
            return {
                ...state,
                documents:newArr
            }
        }
        case 'RESET_FORM_6':{
            state = Data
            return state
        }
        default: {
            return state
        }
    }
}


const Data = {
    childFavouriteActivities:'',
    bestWayToHelpChild:'',
    assetsOrStrengths:'',
    mostProudMoment:'',
    otherInformation:'',
    childGender:'Male',
    imageUrl:'',
    documents:[]
}


export default Form6Reducer