import React,{useEffect,useState} from 'react'
import {baseURL, Decrypt, Encrypt, headers} from '../../Config'
import {useSelector,useDispatch} from 'react-redux'
import Skeleton from 'react-loading-skeleton';
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import ClinicianDashboardNavbar from '../../Components/ClinicianDashboardNavbar'
import DashboardClinicianCard from '../../Components/DashboardClinicianCard'
import { toast } from 'react-toastify'
import './CSS/CiliniciansProfiles.css'
import { Row,Col,Spinner } from 'react-bootstrap'

export default function CiliniciansProfile() {

    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const [clinicians,setClinicians] = useState([])
    const [skeletons,setSkeletons] = useState([{},{},{},{},{},{},{},{},{},{}])
    const [isLoading,setIsLoading] = useState(true)

    const sidebarWidth = useSelector(data => data.SidebarReducer.width)

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })

        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            fetch(baseURL+ '/api/get-all-clinicians',{
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    toast.error('error..')
                    setIsLoading(false)
                }
                else{
                    const newArr = res2.message.filter(data => data.uid !== dt.uid)
                    setClinicians(newArr)
                    setIsLoading(false)
                }
            })
        }
    },[])

    return (
        <div className="cilinicians-profiles-page-container">
            <ParentDashboardSideBar active="home" user="BC" />
            <div style={{marginLeft:windowWidth > 1024 &&sidebarWidth}}>
                <ClinicianDashboardNavbar active="home" user="BC"/>
                <div className="cilinicians-profiles-page-body">
                    <h2 style={{fontFamily:'CocoSharp bold'}}>Doctors Profile</h2>
                    <Row className="mx-0 mt-4">
                        <Col sm={12}className="d-flex align-items-center flex-wrap px-0">
                            {
                                isLoading ?
                                    skeletons.map(dat=>{
                                        return <Skeleton width={210} height={220} style={{marginLeft:0,marginRight:20,marginTop:20,borderRadius:15}}/>
                                    })
                                :
                                    clinicians.length > 0 ?
                                        clinicians.map(dt=>{
                                            
                                            return <DashboardClinicianCard 
                                                        isParent={false}
                                                        name={Decrypt(dt.first_name) + ' ' + Decrypt(dt.last_name)}
                                                        image={dt.image}
                                                        otherInfo={Decrypt(dt.about)}
                                                        Id={dt.uid}
                                                    />
                                        })
                                    : 
                                        <div>
                                            <h4 style={{color:'lightgray',margin:0}}>No Data</h4>
                                        </div>
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}
