import {baseURL,Decrypt,headers} from '../../Config'
import {toast} from 'react-toastify'

export const GetRBTData = () =>{
    
    const data = JSON.parse(localStorage.getItem('bx_user_token'))
    return(dispatch)=>{
        if(data){
            fetch(baseURL+'/api/getRbtById',{
                method:'Post',
                body:JSON.stringify({uid:data.uid}),
                headers:{...headers,'authorization':'Bearer ' + data.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Success' && res2.message.length > 0){
                    const dt = res2.message[0]
                    const obj = {
                        uid:dt.uid,
                        first_name: Decrypt(dt.first_name),
                        email: Decrypt(dt.email), 
                        last_name: Decrypt(dt.last_name),
                        gender: Decrypt(dt.gender),
                        image: Decrypt(dt.image),
                        mobile_number: Decrypt(dt.mobile_number),
                        nationality: Decrypt(dt.nationality),
                        short_des: Decrypt(dt.short_des),
                        status: Decrypt(dt.status),
                        experiences:dt.experiences
                    }
                    dispatch({type : 'getParentData' , payload:obj})
                }
            })
            .catch(err=>{
                return Promise.reject()
            })
        }
        
    }
}

export const UpdateRBTProfile = (obj) =>{
    const data = JSON.parse(localStorage.getItem('bx_user_token'))
    return(dispatch)=>{
        fetch(baseURL+ '/api/update-rbt-data',{
            method:'Put',
            body:JSON.stringify({...obj,uid:data.uid}),
            headers:{...headers,'authorization':'Bearer ' + data.accessToken}
        })
        .then(res=>res.json())
        .then(res2=>{
            if(res2.code === 'Error'){
                toast.error(res2.message,{toastId:'bc-profile-update-1'})
                dispatch({type:'SetIsLoading',payload:false})
            }
            else{
                toast.success(res2.message,{toastId:'bc-profile-update-2'})
                dispatch({type:'SetIsLoading',payload:false})
            }
        })
    }
}