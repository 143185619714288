import React from "react";
import img from "../../assets/about/vision.png";

function Vision() {
  return (
    <div className="pt-14 lg:pt-0 gap-10 flex justify-between items-center flex-col-reverse lg:flex-row w-full bg-[#FFEEEF] lg:gap-7">
      <img
        src={img}
        className="object-contain self-start lg:self-auto"
        alt=""
      />
      <div className="flex justify-start items-start flex-col pl-5 lg:pl-0 lg:pr-5 xl:pr-[120px] gap-4">
        <h1 className="text-lightGreen-500 font-bold text-4xl lg:text-4xl xl:text-5xl">
          Our Vision
        </h1>
        <p className="text-gray-500 font-normal text-xl max-w-[600px]">
          Our vision is to become a leading provider of clinical services where
          our clients thrive, and our people grow and develop.
        </p>
      </div>
    </div>
  );
}

export default Vision;
