import React,{useState} from "react";
import img from "../../assets/pediatricians/sec3.png";
import patt from "../../assets/Home/model-patt.png";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function Section3() {

  const faqs = [
    {
      question: "How does the referral process work?",
      answer:
        "Once you refer a patient, we assign a dedicated member of our care team to handle all aspects of the family’s care. The care coordinator collects the information required and works closely with the family in guiding them through the assessment and treatment process.",
    },
    {
      question: "Can the referring pediatrician be part of the joint care?",
      answer:
        "At Cognitia, we welcome cohesive collaboration between professionals to facilitate effective treatment. Please contact us at hello@cognitia.co to learn more.",
    },
    {
      question: "How can I learn more about Cognitia?",
      answer:
        "Please email us at hello@cognitia.co to get in touch with a member or our team.",
    },
  ];
  const [faqActive, setFaqActive] = useState(Array(faqs.length).fill(false));

  return (
    <div className="w-full flex justify-center items-center mt-[100px] xl:mt-[220px] px-5">
      <div className="xl:flex-row flex-col-reverse max-w-[1440px] flex justify-center items-center xl:items-start xl:gap-16 w-full">
        <div className="relative isolate  flex justify-end items-center mt-[80px] xl:mt-0">
          <img
            src={patt}
            className="absolute -z-[1] -top-[10%] sm:-left-[8%] h-full -left-[4%]"
            alt=""
          />
          <img
            src={img}
            alt=""
            className="w-full max-w-[400px] xl:max-w-[522px]"
          />
        </div>
        <div className="flex justify-center items-start flex-col gap-4 xl:w-1/2">
          <h1 className="text-lightGreen-500 leading-9 mb-8 sm:leading-[50px] font-bold text-[30px] sm:text-[48px]  ">
            Frequently Asked <br /> Questions
          </h1>
          {faqs.map((elem, idx) => {
            return (
              <div
                key={"faq-her" + idx}
                className={`transition-all max-w-[960px] w-full border-[1px] border-solid border-lightGreen-600  rounded-[10px] flex flex-col items-start overflow-hidden ${
                  faqActive[idx] && "bg-lightGreen-600"
                }`}
              >
                <div
                  className={`flex justify-between w-full items-center cursor-pointer p-4`}
                  onClick={() => {
                    setFaqActive((prev) => {
                      let copy = Array(faqs.length).fill(false);
                      copy[idx] = !prev[idx];
                      return copy;
                    });
                  }}
                >
                  <h2
                    className={`transition-all ${
                      faqActive[idx] ? "text-white" : "text-lightGreen-600"
                    } font-bold text-lg sm:text-2xl`}
                  >
                    {elem.question}
                  </h2>
                  <FontAwesomeIcon
                    className={`transition-all text-[20px] ${
                      faqActive[idx]
                        ? "text-white rotate-180"
                        : "text-lightGreen-600"
                    }`}
                    icon={faChevronDown}
                  />
                </div>
                <div
                  className={`text-white text-justify text-lg transition-all pointer-events-none px-4  ${
                    faqActive[idx] ? "max-h-screen pb-4" : "max-h-0"
                  } `}
                >
                  <p>{elem.answer}</p>
                </div>
              </div>
            );
          })}
          {/* <FaqTab
            question={"How does the referral process work?"}
            answer={
              "Once you refer a patient, we assign a dedicated member of out care team to handle all aspects of the family’s care. The care coordinator collects the information required and works closely with the family in guiding them through the assessment and treatment process."
            }
          />
          <FaqTab
            question={
              "Can the referring pediatrician be part of the joint care?"
            }
            answer={
              "At Cognitia, we welcome cohesive collaboration between professionals to facilitate effective treatment. Please contact us at hello@cognitia.co to learn more."
            }
          />
          <FaqTab
            question={"How can I learn more about Cognitia?"}
            answer={
              "Please email us at hello@cognitia.co to get in touch with a member or our team."
            }
          /> */}
        </div>
      </div>
    </div>
  );
}

export default Section3;
