import React from "react";
// import img from "../../assets/team/hero.png";
// import img from "../../assets/team/team.jpg";
import img from "../../assets/team/MohammedGhani04032023_bg.jpg";
function Hero() {
  return (
    <div className="flex justify-center items-center mt-32 flex-col gap-5 pt-4 text-center">
      <h1 className="text-lightGreen-500 font-bold text-5xl px-5">Our Team</h1>
      <img src={img} alt="" className="object-cover w-full" />
    </div>
  );
}

export default Hero;
