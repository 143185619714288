import React,{useState,useEffect,useRef} from 'react'
import {getTimeInLocal} from '../../TimeFormate'
import {baseURL, headers} from '../../Config'
import moment from 'moment'
import './CSS/CallCounter.css'

export default function CallCounter(props) {

    const interval = useRef(null)

    const [hours,setHours] = useState(0)
    const [minutes,setMinutes] = useState('00')
    const [seconds,setSeconds] = useState('00')

    // for timer
    useEffect(()=>{
        props.data&&clearInterval(interval.current)
        
        interval.current = props.data&&setInterval(()=>{
        const currentDate = new Date()
        const currentDateConverted = getTimeInLocal(currentDate)
        const appointmentDate = getTimeInLocal(props.data.startDateAndTime)
        const diff = moment.duration(moment(currentDateConverted).diff(appointmentDate))
        const sec = Math.ceil(diff / 1000)
        const durationInSeconds = props.data.duration * 60
        const totalDuration = durationInSeconds - sec

        let hours   = Math.floor(totalDuration / 3600); // get hours
        let minutes = Math.floor((totalDuration - (hours * 3600)) / 60); // get minutes
        let seconds = totalDuration - (hours * 3600) - (minutes * 60); //  get seconds
        
        if(totalDuration > 0){
            setHours(hours)
            setMinutes(minutes)
            setSeconds(seconds)
        }
        else if(totalDuration&&totalDuration <= 0) {
            setHours(0)
            setMinutes('00')
            setSeconds('00')

            const data = JSON.parse(localStorage.getItem('bx_user_token'))
            if(data){
              fetch(baseURL + '/api/completeMeeting',{
                method:'Post',
                body:JSON.stringify({Id:props.meetingId}),
                headers:{...headers,'authorization':'Bearer ' + data.accessToken}
              })
              .then(res=>res.json())
              .then(res2=>{
                if(res2.code === 'Success'){
                    props.leave()
                }
              })
            }
        }
        },1000)
    },[props])

    return (
        <div className="agora-video-component-couter">
            <p>
                Remaining Time: 0{hours} : {minutes&&minutes.toString() === '0' ? '00':minutes.toString().length === 1 ? '0'+minutes : minutes} : {seconds&&seconds.toString() === '0' ? '00':seconds.toString().length === 1 ? '0'+seconds : seconds}
            </p>
        </div>
    )
}
