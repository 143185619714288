import React,{useState} from 'react'
import {Spinner} from 'react-bootstrap'
import { toast } from 'react-toastify';
import { baseURL } from '../Config';
import './CSS/UploadComponent.css'

export default function UploadComponent(props) {

    const [uploading, setUploading] = useState(false)

    const handleUpload = (e) =>{
        if(e){
            setUploading(true)
            for(let i=0;i < e.target.files.length; i++){
                const image = new FormData()
                image.append('image',e.target.files[i])

                fetch(baseURL + '/upload-file',{
                    method:'Post',
                    body:image
                })
                .then(res => res.json())
                .then(res2=>{
                    if(res2.code === 'Success'){
                        props.getUploadedFile(res2.message)
                        if(i+1 === e.target.files.length){
                            setUploading(false)
                            toast.success('Files Uploaded...')
                        }
                    }
                })
            }
        }
    }

    return (
        <div>
            {
                uploading ? 
                    <label className="upload_component_container">
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    </label>
                :
                <>
                    <label for="file" style={{marginBottom:0}} className="upload_component_container">
                        <h2 style={{margin:0,color:'lightgray'}}>+</h2>
                        <h5 style={{margin:0,fontSize:20,margin:0}}>Upload File</h5>
                        <p style={{margin:0,fontSize:10,margin:0,color:'#C5C5C5'}}>Not more than 5mb</p>
                    </label>
                    <input 
                        type="file" 
                        id="file" 
                        style={{display:'none'}} 
                        // accept=".doc, .docx, .txt, .pdf"
                        accept=".pdf, .doc. .docx, .txt"
                        multiple={props.isMultiple}
                        onChange={(e)=>handleUpload(e)} 
                    />
                </>

            }
        </div>
    )
}
