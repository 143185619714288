import Form1State from '../../States/OnBoardingFormStates/Form1State'

const Form1Reducer = (state=Form1State,action)=>{

    switch(action.type){
        case 'form1-draft-data':{
            state = action.payload
            return {
                ...state,
            }
        }
        case 'onChangeNameForm1':{
            action.payload.isNull = action.payload.value.trim() !== '' ? false : true
            state.Name = action.payload
            const obj = state.Name
            return {
                ...state,
                Name:obj
            }
        }
        case 'onChangeBirthDateForm1':{
            action.payload.isNull = action.payload.value.trim() !== '' ? false : true
            state.birthDate = action.payload
            const obj = state.birthDate
            return {
                ...state,
                birthDate:obj
            }
        }
        case 'onChangeSchoolNameForm1':{
            action.payload.isNull = action.payload.value.trim() !== '' ? false : true
            state.schoolName = action.payload
            const obj = state.schoolName
            return {
                ...state,
                schoolName:obj
            }
        }
        case 'onChangeFamilyDocNameForm1':{
            state.familyDoc = action.payload
            const obj = state.familyDoc
            return {
                ...state,
                familyDoc:obj
            }
        }
        case 'onChangeGradeForm1':{
            state.grade = action.payload
            const obj = state.grade
            return {
                ...state,
                grade:obj
            }
        }
        case 'onChangeRefferingDocNameForm1':{
            state.refferingDoc = action.payload
            const obj = state.refferingDoc
            return {
                ...state,
                refferingDoc:obj
            }
        }
        case 'onChangePrimaryLangForm1':{
            action.payload.isNull = action.payload.value.trim() !== '' ? false : true
            state.primaryLang = action.payload
            const obj = state.primaryLang
            return {
                ...state,
                primaryLang:obj
            }
        }
        case 'onChangeOtherLangForm1':{
            state.otherLang = action.payload
            const obj = state.otherLang
            return {
                ...state,
                otherLang:obj
            }
        }
        case 'onChangeChildFirstLangForm1':{
            state.childFirstLang = action.payload
            const obj = state.childFirstLang
            return {
                ...state,
                childFirstLang:obj
            }
        }
        case 'onChangeAgeWhenLearnedEnglishForm1':{
            state.ageWhenLeargedEng = action.payload
            const obj = state.ageWhenLeargedEng
            return {
                ...state,
                ageWhenLeargedEng:obj
            }
        }
        case 'onChangeChildBirthCountryForm1':{
            state.birthCountry = action.payload
            const obj = state.birthCountry
            return {
                ...state,
                birthCountry:obj
            }
        }
        case 'onChangeAgeWhenArrivedCanadaForm1':{
            state.ageWhenArrivedCanada = action.payload
            const obj = state.ageWhenArrivedCanada
            return {
                ...state,
                ageWhenArrivedCanada:obj
            }
        }
        case 'onChangeChildLivesWithForm1':{
            const newArr = state.livesWith.map(dt=>{
                if(dt.parent === action.payload.parent){
                    dt.isLived = action.payload.isLived
                    return dt
                }
                return dt
            })
            return {
                ...state,
                livesWith:newArr
            }
        }
        // Parent 1 cases
        case 'onChangeParent1NameForm1':{
            action.payload.isNull = action.payload.value.trim() !== '' ? false : true
            state.livesWith[0].Name = action.payload
            const obj = state.livesWith
            return {
                ...state,
                livesWith:obj
            }
        }
        case 'onChangeParent1AgeForm1':{
            action.payload.isNull = action.payload.value.trim() !== '' ? false : true
            state.livesWith[0].Age = action.payload
            const obj = state.livesWith
            return {
                ...state,
                livesWith:obj
            }
        }
        case 'onChangeParent1CellForm1':{
            action.payload.isNull = action.payload.value&&action.payload.value.trim() !== '' ? false : true
            action.payload.isLimitErr = action.payload.value&&action.payload.value.trim().length < 8 ? true : false
            state.livesWith[0].cell = action.payload
            const obj = state.livesWith
            return {
                ...state,
                livesWith:obj
            }
        }
        case 'onChangeParent1EmailForm1':{
            action.payload.isNull = action.payload.value.trim() !== '' ? false : true
            state.livesWith[0].email = action.payload
            const obj = state.livesWith
            return {
                ...state,
                livesWith:obj
            }
        }
        case 'onChangeParent1OccupationForm1':{
            state.livesWith[0].Occupation = action.payload
            const obj = state.livesWith
            return {
                ...state,
                livesWith:obj
            }
        }
        case 'onChangeParent1EducationForm1':{
            state.livesWith[0].educationLevel = action.payload
            const obj = state.livesWith
            return {
                ...state,
                livesWith:obj
            }
        }

        // Parent 2 cases
        case 'onChangeParent2NameForm1':{
            action.payload.isNull = action.payload.value.trim() !== '' ? false : true
            state.livesWith[1].Name = action.payload
            const obj = state.livesWith
            return {
                ...state,
                livesWith:obj
            }
        }
        case 'onChangeParent2AgeForm1':{
            action.payload.isNull = action.payload.value.trim() !== '' ? false : true
            state.livesWith[1].Age = action.payload
            const obj = state.livesWith
            return {
                ...state,
                livesWith:obj
            }
        }
        case 'onChangeParent2CellForm1':{
            action.payload.isNull = action.payload.value&&action.payload.value.trim() !== '' ? false : true
            action.payload.isLimitErr = action.payload.value&&action.payload.value.trim().length < 8 ? true : false
            state.livesWith[1].cell = action.payload
            const obj = state.livesWith
            return {
                ...state,
                livesWith:obj
            }
        }
        case 'onChangeParent2EmailForm1':{
            action.payload.isNull = action.payload.value.trim() !== '' ? false : true
            state.livesWith[1].email = action.payload
            const obj = state.livesWith
            return {
                ...state,
                livesWith:obj
            }
        }
        case 'onChangeParent2OccupationForm1':{
            state.livesWith[1].Occupation = action.payload
            const obj = state.livesWith
            return {
                ...state,
                livesWith:obj
            }
        }
        case 'onChangeParent2EducationForm1':{
            state.livesWith[1].educationLevel = action.payload
            const obj = state.livesWith
            return {
                ...state,
                livesWith:obj
            }
        }

        // end of parent 2 cases
        case 'onChangeMaritalStatusForm1':{
            const newArr = state.maritalStatusOfParent.map(dt=>{
                if(dt.status === action.payload){
                    dt.isSelected = true
                    return dt
                }
                dt.isSelected = false
                return dt
            })
            return {
                ...state,
                maritalStatusOfParent : newArr
            }
        }
        case 'onChangeLegalCustodyForm1':{
            const newArr = state.legalCustody.map(dt=>{
                if(dt.custody === action.payload){
                    dt.isSelected = true
                    return dt
                }
                dt.isSelected = false
                return dt
            })
            return {
                ...state,
                legalCustody : newArr
            }
        }

        //Other Relative Cases
        case 'onChangeRelativeNameForm1':{
            const newArr = state.otherRelatives.map(dt=>{
                if(dt.Id === action.payload.Id){
                    dt.name = action.payload.Name
                    return dt
                }
                return dt
            })
            return {
                ...state,
                otherRelatives : newArr
            }
        }
        case 'onChangeRelativeAgeForm1':{
            const newArr = state.otherRelatives.map(dt=>{
                if(dt.Id === action.payload.Id){
                    dt.age = action.payload.value
                    return dt
                }
                return dt
            })
            return {
                ...state,
                otherRelatives : newArr
            }
        }

        case 'onChangeRelativeRelationForm1':{
            const newArr = state.otherRelatives.map(dt=>{
                if(dt.Id === action.payload.Id){
                    dt.relationShip = action.payload.value
                    return dt
                }
                return dt
            })
            return {
                ...state,
                otherRelatives : newArr
            }
        }

        case 'onChangeRelativeLivesInForm1':{
            const newArr = state.otherRelatives.map(dt=>{
                if(dt.Id === action.payload.Id){
                    dt.livesInHome = action.payload.value
                    return dt
                }
                return dt
            })
            return {
                ...state,
                otherRelatives : newArr
            }
        }

        case 'RESET_FORM_1':{
            state = Data
            return {
                ...state,
            }
        }

        default:{
            return {
                ...state,
            }
        }
    }
}



const Data = {
    Name:{value:'',isErr:false, isNull:true},
    schoolName:'',
    birthDate:{value:'',isNull:true},
    familyDoc:{value:'',isErr:false},
    grade:'',
    refferingDoc:{value:'',isErr:false},
    primaryLang:{value:'',isErr:false, isNull:true},
    otherLang:'',
    childFirstLang:{value:'',isErr:false},
    ageWhenLeargedEng:0,
    birthCountry:'',
    ageWhenArrivedCanada:0,
    livesWith:[{
        parent:1,
        isLived:'No',
        Name:{value:'',isErr:false, isNull:true},
        Age:{value:'', isNull:true},
        Occupation:'',
        educationLevel:'',
        cell:{value:'', isNull:true},
        email:{value:'',isErr:false, isNull:true},
    },
    {
        parent:2,
        isLived:'No',
        Name:{value:'',isErr:false, isNull:true},
        Age:{value:'', isNull:true},
        Occupation:'',
        educationLevel:'',
        cell:{value:'', isNull:true,isLimitErr:false},
        email:{value:'',isErr:false, isNull:true},
    }],
    maritalStatusOfParent:[{status:'Married',isSelected:false},{status:'Separated',isSelected:false},{status:'Divorced',isSelected:false},{status:'Widowed',isSelected:false},{status:'Other',isSelected:true}],
    legalCustody:[{custody:'Mother',isSelected:false},{custody:'Father',isSelected:false},{custody:'Shared',isSelected:false},{custody:'Other',isSelected:true}],
    otherRelatives:[
        {Id:1,name:{value:'',isErr:false},age:0,relationShip:'',livesInHome:'Yes'},
        {Id:2,name:{value:'',isErr:false},age:0,relationShip:'',livesInHome:'Yes'},
        {Id:3,name:{value:'',isErr:false},age:0,relationShip:'',livesInHome:'Yes'}
    ]
}


export default Form1Reducer;