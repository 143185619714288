import React,{useEffect,useState} from 'react'
import {baseURL, Decrypt, Encrypt, headers} from '../../Config'
import {useSelector,useDispatch} from 'react-redux'
import Skeleton from 'react-loading-skeleton';
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import ParentDashboardNavbar from '../../Components/ParentDashboardNavbar'
import ClinicianDashboardNavbar from '../../Components/ClinicianDashboardNavbar'
import DashboardChildCard from '../../Components/DashboardChildCard'
import PendingProfilesCard from '../../Components/PendingProfilesCard'
import TelePedsASD from '../../Components/AssessmentToolComponents/Tele-Peds-ASD'
import ProceduresIntro from '../../Components/AssessmentToolComponents/ProceduresIntro'
import ProcedureComp from '../../Components/AssessmentToolComponents/ProcedureComp'
import {GoPlus} from 'react-icons/go'
import { toast } from 'react-toastify'
import './CSS/ChildProfile.css'
import { Row,Col,Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom';

export default function ParentChildProfile(props) {

    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const [childs,setChilds] = useState([])
    const [pendingProfiles,setPendingProfiles] = useState([])
    const [skeletons,setSkeletons] = useState([{},{},{},{},{},{},{},{},{},{}])
    const [isLoading,setIsLoading] = useState(true)
    const [isSelected,setIsSelected] = useState(1)
    const [userType,setUserType] = useState(null)

    const sidebarWidth = useSelector(data => data.SidebarReducer.width)
    const first_name = useSelector(data => data.ParentReducer.parentData.first_name)
    const last_name = useSelector(data => data.ParentReducer.parentData.last_name)

    useEffect(()=>{

        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })

        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            setUserType(Decrypt(dt.userType))
            const path = Decrypt(dt.userType) === 'Parent' ? '/api/get-parent-childs' :Decrypt(dt.userType) === 'BC' ? '/api/get-clinician-assigned-childs' : null

            fetch(baseURL + path,{
                method:'Post',
                body:JSON.stringify({uid:dt.uid}),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    toast.error('error..')
                    setIsLoading(false)
                }
                else{
                    setChilds(res2.message)
                    setIsLoading(false)
                }
            })
        }
        
    },[])

    const handleSelectCompleteProfiles = () =>{
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            setIsLoading(true)
            setIsSelected(1)
            fetch(baseURL+ '/api/get-parent-childs',{
                method:'Post',
                body:JSON.stringify({uid:dt.uid}),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    toast.error('error..')
                    setIsLoading(false)
                }
                else{
                    setChilds(res2.message)
                    setIsLoading(false)
                }
            })
        }
    }

    const handleSelectPendingProfiles = () =>{
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            setIsSelected(2)
            setIsLoading(true)
            fetch(baseURL+ '/api/getParentPendingChilds',{
                method:'Post',
                body:JSON.stringify({uid:dt.uid}),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    toast.error('error..')
                    setIsLoading(false)
                }
                else{
                    setPendingProfiles(res2.message)
                    setIsLoading(false)
                }
            })
        }
    }

    return (
        <div className="child-profile-page-container">
            <ParentDashboardSideBar active="child" user={userType&&userType === 'BC' ? "BC" : 'Parent'} />
            <div style={{marginLeft:windowWidth > 1024 &&sidebarWidth}}>
                {
                    userType&&userType === 'Parent' ?
                        <ParentDashboardNavbar active="child" user='Parent' />
                    :
                        <ClinicianDashboardNavbar 
                            active="child" 
                            user='BC'
                            showAppointments={userType === 'BCBA' ? false : true}
                        />
                }
                <div className="child-profile-page-body">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <h2 style={{fontFamily:'CocoSharp bold'}}>Child Profile</h2>
                        {
                            userType&&userType === 'Parent' &&
                            <Link to="/dashboar-select-payment-type">
                                <button className="child-profile-add-child-btn">Add Child <GoPlus/></button>
                            </Link>
                        }
                    </div>
                    {
                        userType&&userType === 'Parent' &&
                        <div className="child-profile-page-tab-bar">
                            <button className="child-profile-page-tab-bar-btn" onClick={handleSelectCompleteProfiles} style={isSelected === 1 ? {backgroundColor:'#F99B9B',color:'white'} : {backgroundColor:'white',color:'#F99B9B'}}>
                                Complete Profiles
                            </button>
                            <button className="child-profile-page-tab-bar-btn" onClick={handleSelectPendingProfiles} style={isSelected === 2 ? {backgroundColor:'#F99B9B',color:'white'} : {backgroundColor:'white',color:'#F99B9B'}}>
                                Pending Profiles
                            </button> 
                        </div>
                    }
                    <Row className="mx-0 mt-4">
                        <Col sm={12}className="d-flex align-items-center flex-wrap px-0">
                            {
                                isLoading ?
                                    skeletons.map((dat,i)=>{
                                        return <Skeleton key={i} width={210} height={220} style={{marginLeft:0,marginRight:20,marginTop:20,borderRadius:15}}/>
                                    })
                                :
                                    isSelected === 1 ?
                                        childs.length > 0 ?
                                            childs.map((dt,i)=>{
                                                return (
                                                    <React.Fragment key={i}>
                                                        <DashboardChildCard 
                                                            isParent={true}
                                                            childName={Decrypt(dt.child_name)}
                                                            image={dt.image}
                                                            dob={Decrypt(dt.birth_date)}
                                                            gender={Decrypt(dt.gender)}
                                                            otherInfo={Decrypt(dt.other_information)}
                                                            Id={dt.uid}
                                                            path="/child-detail"
                                                            parentName={Decrypt(dt.parentName)}
                                                        />
                                                    </React.Fragment>
                                                )
                                            })
                                    
                                        :
                                            <div style={{width:'100%'}}>
                                                <h3 style={{color:'lightgray'}}>No Data</h3>
                                            </div>
                                    :
                                        pendingProfiles.length > 0 ?
                                            pendingProfiles.map((dt,i)=>{
                                                return (
                                                        <React.Fragment key={i}>
                                                            <PendingProfilesCard 
                                                                Id={dt.uid}
                                                                status={Decrypt(dt.status)}
                                                            />
                                                        </React.Fragment>
                                                    )
                                            })
                                    
                                        :
                                            <div style={{width:'100%'}}>
                                                <h3 style={{color:'lightgray'}}>No Data</h3>
                                            </div>
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}
