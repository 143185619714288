import React,{useState} from 'react'
import './CSS/SelectComponent.css'

export default function SelectComponent(props) {

    const [focus,setFocus] = useState(false)

    return (
        <fieldset className={`${ props.isError ? "fld_set_err" : focus ? 'fld_set_fcs' : 'fld_set'} ${props.containerClass}`} style={props.containerStyle}>
            {props?.label && <legend className={props.isError ? "legend2" : focus ? "legend1" : "legend"} style={props.labelStyle}>{props.label}</legend>}
            <select onFocus={()=>setFocus(true)} onBlur={()=>setFocus(false)} type="text" value={props.value} className="select form-control" style={{paddingTop:0}} onChange={(e)=>props.onChange(e.target.value)}>
                <option value="">Select</option>
                {
                    props.options.map((dt,i)=>{
                        return <option key={i} value={dt}>{dt}</option>
                    })
                }
            </select>
        </fieldset>
    )
}
