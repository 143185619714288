import React, { useState, useEffect } from 'react'
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import ParentDashboardNavbar from '../../Components/ParentDashboardNavbar'
import CallCompletedModal from '../../Components/CallCompletedModal'
import { useSelector } from 'react-redux'
import AgoraVideoComp from '../../Components/VideoCallComp/callIndex'
import './CSS/ParentVideoCallingPage.css'

export default function ParentVideoCallingPage() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [showCallCompleteModal, setShowCallCompleteModal] = useState(false)
    const sidebarWidth = useSelector(data => data.SidebarReducer.width)

    useEffect(() => {
        window.addEventListener('resize', () => {
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })
    }, [])

    return (
        <div className="parent-video-calling-page-container">
            <ParentDashboardSideBar />
            <div style={{ marginLeft:windowWidth > 1024 && sidebarWidth }}>
                <ParentDashboardNavbar showAppointments={false} />
                <div className="parent-video-calling-page-body">
                    <AgoraVideoComp
                        view={windowWidth < 768 && 'second-view'}
                        handleCallCompleteModal={(val) => setShowCallCompleteModal(val)}
                    />
                </div>
            </div>
            <CallCompletedModal
                showCompletedAlert={showCallCompleteModal}
                path="/dashboard-home"
            />
        </div>
    )
}
