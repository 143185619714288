import React,{useEffect,useState} from 'react'
import {baseURL, Decrypt, Encrypt} from '../../Config'
import {UpdateParentProfile} from '../../Store/Actions/ParentActions'
import {useSelector,useDispatch} from 'react-redux'
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import ParentDashboardNavbar from '../../Components/ParentDashboardNavbar'
import InputComp from '../../Components/InputComp'
import InputPassComp from '../../Components/InputPassComp'
import TextAreaComp from '../../Components/TextAreaComp'
import PhoneNumberComp from '../../Components/PhoneNumberComp'
import SelectCountryComp from '../../Components/SelectCountryComp'
import './CSS/ParentEditProfile.css'
import { Row,Col,Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { OnlyAlphabets, PasswordValidation, Required } from '../../Validations'

export default function ParentEditProfile() {

    const dispatch = useDispatch()
    const parentImage = useSelector(data => data.ParentReducer.parentData.image)

    const [windowWidth,setWindowWidth] = useState(window.innerWidth)

    const [userName,setUserName] = useState('')

    const [firstName,setFirstName] = useState('')
    const [firstNameErr,setFirstNameErr] = useState(false)

    const [lastName,setLastName] = useState('')
    const [lastNameErr,setLastNameErr] = useState(false)

    const [email,setEmail] = useState('')
    const [number,setNumber] = useState('')
    const [numberErr,setNumberErr] = useState('')

    const [nationality,setNationality] = useState('')
    const [image,setImage] = useState(parentImage)
    const [description,setDescription] = useState('')
    const [imageUploading,setImageUploading] = useState(false)

    const [currentPassword,setCurrentPassword] = useState('')
    const [newPass,setNewPass] = useState('')
    const [newPassErr,setNewPassErr] = useState('')

    const [isUploading,setIsUploading] = useState(false)


    const sidebarWidth = useSelector(data => data.SidebarReducer.width)
    const newImage = useSelector(data => data.ParentReducer.parentData.image)
    const first_name = useSelector(data => data.ParentReducer.parentData.first_name)
    const last_name = useSelector(data => data.ParentReducer.parentData.last_name)
    const newEmail = useSelector(data => data.ParentReducer.parentData.email)
    const mobile_number = useSelector(data => data.ParentReducer.parentData.mobile_number)
    const nationality1 = useSelector(data => data.ParentReducer.parentData.nationality)
    const short_des = useSelector(data => data.ParentReducer.parentData.short_des)
    const isLoading = useSelector(data => data.ParentReducer.isLoading)
    const parentData = useSelector(data => data.ParentReducer.parentData)

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })

        setFirstName(first_name)
        setLastName(last_name)
        setUserName(first_name + ' ' + last_name)
        setEmail(newEmail)
        setNumber(mobile_number)
        setNationality(nationality1)
        setImage(newImage)
        setDescription(short_des)
    },[])


    useEffect(()=>{
        setImage(newImage)
        setFirstName(first_name)
        setLastName(last_name)
        setEmail(parentData.email)
        setNumber(parentData.mobile_number)
        setNationality(parentData.nationality)
    },[parentData,newImage,newEmail,first_name,last_name])


    const handleUpdateImage = (e) =>{
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))

        if(dt){
            const formData = new FormData()
            formData.append('image',e.target.files[0])
            formData.append('uid',dt.uid)

            setImageUploading(true)
            fetch(baseURL+ '/api/update-parent-image',{
                method:'Put',
                body:formData,
                headers:{'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    setImageUploading(false)
                    toast.error('error..')
                }
                else{
                    setImageUploading(false)
                    dispatch({type:'UpdateImage',payload:res2.message[0].image})
                }
            })
        }
    }


    const handleUpdateProfile = () =>{

        const isDone = Required('First Name',firstName)&&Required('Last Name',lastName)&&Required('Number',number)&&Required('Nationality',nationality)&&!numberErr&&!firstNameErr&&!lastNameErr

        if(isDone){
            const obj = {
                first_name:Encrypt(firstName),
                last_name:Encrypt(lastName),
                mobile_number:Encrypt(number),
                nationality:Encrypt(nationality),
                short_des:Encrypt(description),
                password:Encrypt(currentPassword),
                newPass:Encrypt(newPass)
            }

            if(currentPassword.trim() !== ''){
                if(Required('New Password',newPass)&&newPassErr !== 0){
                    dispatch(UpdateParentProfile(obj))
                    dispatch({type:'SetIsLoading',payload:true})
                }
            }
            else{
                dispatch(UpdateParentProfile(obj))
                dispatch({type:'SetIsLoading',payload:true})
            }
        }
    }
    

    return (
        <div className="parent-edit-profile-page-container">
            <ParentDashboardSideBar active="profile" />
            <div style={{marginLeft:windowWidth > 1024 &&sidebarWidth}}>
                <ParentDashboardNavbar />
                <div className="parent-edit-profile-page-body">
                    <h2 style={{fontFamily:'CocoSharp bold'}}>Edit Profile</h2>
                    <Row className="mx-0 mt-4 parent-edit-profile-page-body-row">
                        <Col sm={12} md={12} lg={4} className="d-flex flex-column align-items-center parent-edit-profile-page-body-row-col-1">
                            <div style={{width:100,height:100,borderRadius:'50%',backgroundImage:`url(${image})`,backgroundSize:'cover',backgroundRepeat:'no-repeat',backgroundPosition:'center',marginTop:40}}>
                            </div>
                            <h5 style={{fontFamily:'CocoSharp bold',color:'#096E82',fontSize:25}} className="mt-2">{first_name + ' ' + last_name}</h5>
                            <p className="m-0" style={{fontFamily:'CocoSharp bold',marginTop:50,fontSize:15}}>Acceptable format jpg, png only</p>
                            <p className="mb-3 mt-0" style={{fontFamily:'CocoSharp bold',fontSize:15}}>Max file size is 500 kb</p>
                            {
                                imageUploading ? 
                                    <label className="edit-profile-btn">Uploading.....</label>
                                :
                                    <>
                                        <input type='file' style={{display:'none'}} id="change-image-input" onChange={(e)=>handleUpdateImage(e)} />
                                        <label htmlFor="change-image-input" className="edit-profile-btn">Change Picture</label>
                                    </>
                            }
                        </Col>
                        <Col sm={12} md={12} lg={8} className="parent-edit-profile-page-body-row-col-2 px-0">
                            <Row className="mx-0 mt-4">
                                <Col sm={12} md={12} lg={6}>
                                    <InputComp 
                                        value={firstName}
                                        onChange={(e)=>OnlyAlphabets(e.target.value) ? (setFirstName(e.target.value),setFirstNameErr(true)) : (setFirstName(e.target.value),setFirstNameErr(false))}
                                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
                                        isError={firstNameErr}  
                                        label="First Name" 
                                        labelStyle={{fontSize:16,fontWeight:600}} 
                                    />
                                    <InputComp 
                                        value={lastName}
                                        onChange={(e)=>OnlyAlphabets(e.target.value) ? (setLastName(e.target.value),setLastNameErr(true)) : (setLastName(e.target.value),setLastNameErr(false))}
                                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
                                        isError={lastNameErr} 
                                        containerStyle={{marginTop:20}} 
                                        label="Last Name" 
                                        labelStyle={{fontSize:16,fontWeight:600}} 
                                    />
                                    <InputComp 
                                        value={email}
                                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
                                        containerStyle={{marginTop:20}} 
                                        label="Email" 
                                        labelStyle={{fontSize:16,fontWeight:600}}
                                        disabled={true}
                                    />
                                    <PhoneNumberComp 
                                        value={number}
                                        onChange={(e)=>  e&&(e).toString().replace(/ /g,"").length < 8 ? (setNumber(e),setNumberErr(true)) : (setNumber(e),setNumberErr(false)) }
                                        inputLength={(e)=>e.target.value = (e.target.value).toString().replace(/ /g,"").slice(0,15)}
                                        // isError={emailErr || emailExist} 
                                        containerStyle={{marginTop:20}} 
                                        label="Number" 
                                        labelStyle={{fontSize:16,fontWeight:600}} 
                                    />
                                    {numberErr&&<p style={{marginLeft:10,fontSize:12,color:'red'}}>Number Should be between 8 to 15 digits</p>}
                                    
                                    <SelectCountryComp 
                                        value={nationality}
                                        onSelect={(e)=>setNationality(e)}
                                        containerStyle={{marginTop:20}} 
                                        label="Nationality" 
                                        labelStyle={{fontSize:16,fontWeight:600}} 
                                    />
                                </Col>
                                <Col sm={12} md={12} lg={6}>
                                    <TextAreaComp
                                        value={description}
                                        label="Description"
                                        containerStyle={{marginTop:windowWidth < 991&&30}}
                                        labelStyle={{ fontSize: 16, fontWeight: 600 }}
                                        inputLength={(e) => e.target.value = (e.target.value).slice(0, 800)}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                    <InputPassComp
                                        value={currentPassword}
                                        onChange={(e)=> setCurrentPassword(e.target.value)}
                                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,64)}
                                        label="Current Password" 
                                        containerStyle={{marginTop:20}}
                                        labelStyle={{fontSize:16,fontWeight:600}} 
                                    />
                                    <InputPassComp
                                        value={newPass}
                                        onChange={(e)=> (setNewPassErr(PasswordValidation(e.target.value)), setNewPass(e.target.value))}
                                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,64)}
                                        containerStyle={{marginTop:20}}
                                        label="New Password" 
                                        labelStyle={{fontSize:16,fontWeight:600}} 
                                    />
                                    
                                    {newPassErr === 0 &&<p style={{marginLeft:10,fontSize:12,color:'red'}}><b>Weak</b>, Password must contain one digit (0-9) and one special character (! @ # $ % ^ & *)</p>}
                                    {
                                        isLoading ?
                                            <button className="edit-profile-btn" style={{marginTop:30,height:55}}>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                {' '}
                                                Loading....
                                            </button>
                                        :
                                            <button className="edit-profile-btn" onClick={handleUpdateProfile} style={{marginTop:30,height:55}}>Save Changes</button>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}
