import React,{useState} from 'react'
import {Modal,Spinner} from 'react-bootstrap'
import './CSS/IntroModal.css'

export default function IntroModal({show,setShow,url}) {

    const [isLoading,setIsLoading] = useState(true)

    return (
        <Modal className="report-gen-intro-modal-comp" show={show} centered onHide={()=>(setShow(false),setIsLoading(true))} animation={false}>
            <Modal.Header style={{borderBottom:'none'}} closeButton={true}>

            </Modal.Header>
            <Modal.Body className="py-1 pb-2">
                {
                    isLoading&&
                        <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <Spinner
                                as="span"
                                animation="border"
                                role="status"
                                aria-hidden="true"
                            />
                        </div>
                }
                <iframe 
                    width='100%' 
                    height='100%' 
                    src={url}
                    style={{borderRadius:15,border:'none'}}
                    onLoad={()=>setIsLoading(false)}
                ></iframe>
            </Modal.Body>
        </Modal>
    )
}
