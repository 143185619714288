import React from "react";
import img from "../../assets/team/team4.png";

function Vanessa() {
  return (
    <div className="bg-[#FFEEEF] w-full flex justify-center items-center py-11">
      <div className="max-w-[1440px] flex justify-between flex-col-reverse lg:flex-row items-center px-10 w-full gap-10">
        <img src={img} alt="" />
        <div className="flex justify-center flex-col items-start gap-4">
          <h1 className="font-bold text-lightGreen-500 text-3xl md:text-5xl">
            Vanessa Snyder
          </h1>
          <h2 className="font-bold text-lightGreen-500 text-xl md:text-2xl">
            MA, LPC, BCBA
          </h2>
          <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
            Vanessa Snyder is a Licensed Professional Counselor and a Board Certified Behavior Analyst.
          </p>
          <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
            Since <span className="font-mono">2008</span> Vanessa has provided
            in-home, center-based, and school-based support to providers and
            parents of children diagnosed with Autism Spectrum Disorder.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Vanessa;
