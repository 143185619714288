import React from "react";
import Clinical from "./Clinical";
import CareerSec from "./Career";
import Hero from "./Hero";
import Roles from "./Roles";
import TrainingandResource from "./Train&Resource";
import ProfessioanlDev from "./ProfessionalDev";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Informed from "../../Components/Informed";

function Career() {
  return (
    <div>
      <Header />
      <Hero />
      <TrainingandResource/>
      <Clinical />
      <ProfessioanlDev/>

      <CareerSec />
      <Roles />
      <Informed />
      <Footer />
    </div>
  );
}

export default Career;
