import React,{useEffect, useState} from 'react'
import {Spinner} from 'react-bootstrap'
import { baseURL } from '../Config';
import './CSS/UploadeImageComp.css'

export default function UploadeImageComp(props) {

    const [uploading, setUploading] = useState(false)

    const handleUpload = (e) =>{
        if(e.target.files[0]){
            props.getUploadedImage(e.target.files[0])
        }
    }

    const handleUpload2 = (e) =>{
        setUploading(true)
        const image = new FormData()
        image.append('image',e.target.files[0])

        fetch(baseURL + '/upload-file',{
            method:'Post',
            body:image
        })
        .then(res => res.json())
        .then(res2=>{
            if(res2.code === 'Success'){
                props.getUploadedImage(res2.message)
                setUploading(false)
            }
        })
    }

    if(props.onTimeUpload){
        return(
            <div style={props.containerStyle}>
                {
                    uploading ? 
                        <label  className="upload_image_container">
                            <div className="upload_image_col-1">
                                <div style={{width:60,height:60,borderRadius:30,border:"solid 3px lightgray",marginRight:20,overflow:'hidden'}}>
                                    <img src={props.image || ''} className="img-fluid" style={{width:70,height:70,objectFit:'fit'}} />
                                </div>
                                <div>
                                    <p style={{margin:0}}>Upload Photo</p>
                                    <p style={{color:'lightgray',fontSize:12,margin:0}}>Maximum size 5 mb</p>
                                </div>
                            </div>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </label>
                    :
                        <>
                            <label for="upload" className="upload_image_container">
                                <div className="upload_image_col-1">
                                    <div style={{width:60,height:60,borderRadius:30,border:"solid 3px lightgray",marginRight:20,overflow:'hidden'}}>
                                        <img src={props.image || '#'} className="img-fluid" style={{width:70,height:70,objectFit:'fit'}} />
                                    </div>
                                    <div>
                                        <p style={{margin:0}}>Upload Photo</p>
                                        <p style={{color:'lightgray',fontSize:12,margin:0}}>Maximum size 5 mb</p>
                                    </div>
                                </div>
                                <h2 style={{margin:0,color:'black'}}>+</h2>
                            </label>
                            <input type="file" id="upload" style={{display:'none'}} value=""  onChange={(e)=>handleUpload2(e)} accept="image/png, image/jpg, image/jpeg" />
                        </>
                }
                
            </div>
        )
    }
    else{
        return (
            <div style={props.containerStyle}>
                <label for="upload" className="upload_image_container">
                    <div className="upload_image_col-1">
                        <div style={{width:60,height:60,borderRadius:30,border:"solid 3px gray",marginRight:20,overflow:'hidden'}}>
                            <img src={props.image} className="img-fluid" style={{width:70,height:70,objectFit:'fit'}} />
                        </div>
                        <div>
                            <p style={{margin:0}}>Upload Photo</p>
                            <p style={{color:'lightgray',fontSize:12,margin:0}}>Maximum size 5 mb</p>
                        </div>
                    </div>
                    <h2 style={{margin:0,color:'black'}}>+</h2>
                </label>
                <input type="file" id="upload" style={{display:'none'}}  onChange={(e)=>handleUpload(e)} accept="image/png, image/jpg, image/jpeg" />
            </div>
        )
    }
    
}
