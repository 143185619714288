import React from "react";

export default function HomeCard(props) {
  const { key, title, src, color, desc, list1, list2, list3, lg } = props;

  return (
    <div
      key={key}
      className={`${color === "blue" ? "bg-[#E8FCFF]" : "bg-[#FFEEEF]"}  

      p-3 w-[312px] max-w-full min-h-[315px]`}
    >
      <div className="flex flex-col gap-4 px-2 ">
        <div className="flex justify-start">
          <img src={src} alt="" className="h-[50px]" />
        </div>
        <h4 className="text-brand font-bold mt-4 text-2xl">{title}</h4>
        {desc && (
          <div className="flex items-center mt-1 text-sm">
            <p className="text-sm text-dark1 ml-[-4px] mt-[-2px] text-justify"> {desc}</p>
          </div>
        )}
        <ul
          className={`flex flex-col text-sm items-center mt-3 list-disc px-3 ${
            lg ? "mb-2" : "mb-6"
          }  mb-6`}
        >
          {list1 && (
            <li className="text-dark1 ml-[-4px] mt-[-2px] text-justify"> {list1}</li>
          )}
          {list2 && (
            <li className="text-dark1 ml-[-4px] mt-[-2px] text-justify"> {list2}</li>
          )}
          {list3 && (
            <li className="text-dark1 ml-[-4px] mt-[-2px] text-justify"> {list3}</li>
          )}
        </ul>
        {/* <div className="flex  items-center  ">
          <p className="text-dark1 ml-[-4px] mt-[-2px]" > {subtitle2}</p>
        </div> */}
        {/* <div className="flex  items-center mt-1 ">
          <p className="text-[10px] text-dark1 ml-[-4px] mt-[-2px]">
            {" "}
            {subtitle3}
          </p>
        </div> */}
      </div>
    </div>
  );
}
