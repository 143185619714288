import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import {ProgressBar} from 'react-bootstrap'
import { HiOutlineDownload } from 'react-icons/hi';
import { RiArrowDropRightLine } from 'react-icons/ri';
import './CSS/DashboardNotesCard.css'

export default function DashboardNotesCard(props) {

    const [progress,setProgress] = useState(0)
    const [isDownlaod,setIsDownload] = useState(false)

    const handleDownload = () =>{

        setIsDownload(true)
        var xhr = new XMLHttpRequest();
        xhr.open('GET', props.file, true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader( 'Access-Control-Allow-Origin', '*');
        xhr.onprogress = function(event) {
            if (event.lengthComputable) {
                var percentComplete = (event.loaded / event.total)*100;
                setProgress(percentComplete) 
            } 
        };

        xhr.onload = function(event) {
            if (this.status == 200) {
                var filename = Date.now();  
                var a = document.createElement('a');        
                a.href = window.URL.createObjectURL(xhr.response);        
                a.download = filename;       
                a.style.display = 'none';        
                document.body.appendChild(a);        
                a.click();    
                setIsDownload(false)
                setProgress(0)  
            }
            else {
                setIsDownload(false)
                setProgress(0)
            }
        };


        xhr.send();
    }

    return (
        <div className="dashboard-notes-card-container">
            <div style={{height:'90%'}}>
                <p className="note-p mb-0">Note {props.index}</p>
                <h5 className="mt-0">{props.title&&props.title.length > 24 ? props.title.slice(0,24) + '...' : props.title}</h5>
                <p className="note-description">{props.description&&props.description.length > 70 ? props.description.slice(0,70) + '...' : props.description}</p>
            </div>
            <div style={{height:'10%',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                <a href={props.file} target="_blank" style={{color:'#F99B9B',fontFamily:'CocoSharp bold',fontSize:16,margin:0}} className="d-flex align-items-center">View <RiArrowDropRightLine size={30} style={{marginTop:2}} color="#F99B9B" /></a>
                {
                    !isDownlaod ?
                        <Link to="#" onClick={handleDownload}>
                            <HiOutlineDownload size={20} color="#F99B9B" />
                        </Link>
                    :
                        <Link to="#">
                            <HiOutlineDownload size={20} color="#F99B9B" />
                            <ProgressBar now={progress} style={{height:5}} />
                        </Link>
                }
                
            </div>
        </div>
    )
}
