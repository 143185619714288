import React,{useEffect} from 'react'
import { Row, Col } from 'react-bootstrap'
import InputComp from '../../../Components/InputComp'
import { useDispatch, useSelector } from 'react-redux'
import {
    onChangeLearningProgramForm5, onChangeEducationPlanForm5, onChangeCurrentLearningProgramForm5, onChangeHeldBackGradeForm5,
    onChangeSpecialEducationForm5, onChangeLearningAssistanceForm5, onChangeSuspendedOrExpelledForm5, onChangeReceivedTutoringForm5,
    onChangeSchoolProblemsForm5
} from '../../../Store/Actions/OnBoardingFormActions/Form5Actions'
import CheckboxComp from '../../../Components/CheckboxComp'
import TextAreaComp from '../../../Components/TextAreaComp'
import './CSS/PreCallQuestionForm.css'

function PreCallQuestionForm5(props) {

    const dispatch = useDispatch()

    const modiﬁedLearningProgram = useSelector(state => state.Form5Reducer.modiﬁedLearningProgram)
    const individualEducationPlan = useSelector(state => state.Form5Reducer.individualEducationPlan)
    const currentLearningProgram = useSelector(state => state.Form5Reducer.currentLearningProgram)
    const heldBackGrade = useSelector(state => state.Form5Reducer.heldBackGrade)
    const specialEducationClasses = useSelector(state => state.Form5Reducer.specialEducationClasses)
    const learningAssistance = useSelector(state => state.Form5Reducer.learningAssistance)
    const suspendedOrExpelled = useSelector(state => state.Form5Reducer.suspendedOrExpelled)
    const receivedTutoring = useSelector(state => state.Form5Reducer.receivedTutoring)
    const schoolProblems = useSelector(state => state.Form5Reducer.schoolProblems)

    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])

    return (
        <div style={{ width: '100%' }} className="pre-call-form-container">
            <h3>SCHOOL INFORMATION</h3>
            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0 d-flex align-items-center">
                    <p className="my-0 mr-3" style={{ fontWeight: 600 }}>Does your child have a modiﬁed learning program</p>
                    <CheckboxComp
                        checked={modiﬁedLearningProgram}
                        label="Yes"
                        onChange={() => dispatch(onChangeLearningProgramForm5(true))}
                    />
                    <CheckboxComp
                        checked={!modiﬁedLearningProgram}
                        className="ml-3"
                        label="No"
                        onChange={() => dispatch(onChangeLearningProgramForm5(false))}
                    />
                </Col>
            </Row>
            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0 d-flex align-items-center">
                    <p className="my-0 mr-3" style={{ fontWeight: 600 }}>Is there an individual education plan (IEP)?</p>
                    <CheckboxComp
                        checked={individualEducationPlan}
                        label="Yes"
                        onChange={() => dispatch(onChangeEducationPlanForm5(true))}
                    />
                    <CheckboxComp
                        checked={!individualEducationPlan}
                        className="ml-3"
                        label="No"
                        onChange={() => dispatch(onChangeEducationPlanForm5(false))}
                    />
                </Col>
            </Row>
            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0 d-flex align-items-center">
                    <p className="my-0 mr-3" style={{ fontWeight: 600 }}>Are you satisﬁed with your child's current learning program?</p>
                    <CheckboxComp
                        checked={currentLearningProgram.isSelected === 'Yes' && true}
                        label="Yes"
                        onChange={() => dispatch(
                            onChangeCurrentLearningProgramForm5({
                                isSelected: 'Yes',
                                detail: currentLearningProgram.detail
                            })
                        )}
                    />
                    <CheckboxComp
                        checked={currentLearningProgram.isSelected === 'No' && true}
                        className="ml-3"
                        label="No"
                        onChange={() => dispatch(
                            onChangeCurrentLearningProgramForm5({
                                isSelected: 'No',
                                detail: currentLearningProgram.detail
                            })
                        )}
                    />
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0">
                    <TextAreaComp
                        disabled={currentLearningProgram.isSelected === 'Yes' && true}
                        value={currentLearningProgram.detail}
                        label="If not, please explain"
                        labelStyle={{ fontSize: 16, fontWeight: 600 }}
                        inputLength={(e) => e.target.value = (e.target.value).slice(0, 500)}
                        onChange={(e) => dispatch(
                            onChangeCurrentLearningProgramForm5({
                                isSelected: 'No',
                                detail: e.target.value
                            })
                        )}
                    />
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0 d-flex align-items-center">
                    <p className="my-0 mr-3" style={{ fontWeight: 600 }}>Has your child been held back a grade?</p>
                    <CheckboxComp
                        checked={heldBackGrade.isSelected === 'Yes' && true}
                        label="Yes"
                        onChange={() => dispatch(
                            onChangeHeldBackGradeForm5({
                                isSelected: 'Yes',
                                grade: heldBackGrade.grade
                            })
                        )}
                    />
                    <CheckboxComp
                        checked={heldBackGrade.isSelected === 'No' && true}
                        className="ml-3"
                        label="No"
                        onChange={() => dispatch(
                            onChangeHeldBackGradeForm5({
                                isSelected: 'No',
                                grade: heldBackGrade.grade
                            })
                        )}
                    />
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0">
                    <InputComp
                        disabled={heldBackGrade.isSelected === 'No' && true}
                        value={heldBackGrade.grade}
                        label="Which grade?"
                        labelStyle={{ fontSize: 16, fontWeight: 600 }}
                        inputLength={(e) => e.target.value = (e.target.value).slice(0, 25)}
                        onChange={(e) => dispatch(
                            onChangeHeldBackGradeForm5({
                                isSelected: 'Yes',
                                grade: e.target.value
                            })
                        )}
                    />
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0 d-flex align-items-center">
                    <p className="my-0 mr-3" style={{ fontWeight: 600 }}>Is your child in any special education classes?</p>
                    <CheckboxComp
                        checked={specialEducationClasses.isSelected === 'Yes' && true}
                        label="Yes"
                        onChange={() => dispatch(
                            onChangeSpecialEducationForm5({
                                isSelected: 'Yes',
                                detail: specialEducationClasses.detail
                            })
                        )}
                    />
                    <CheckboxComp
                        checked={specialEducationClasses.isSelected === 'No' && true}
                        className="ml-3"
                        label="No"
                        onChange={() => dispatch(
                            onChangeSpecialEducationForm5({
                                isSelected: 'No',
                                detail: specialEducationClasses.detail
                            })
                        )}
                    />
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0">
                    <TextAreaComp
                        disabled={specialEducationClasses.isSelected === 'No' && true}
                        value={specialEducationClasses.detail}
                        label="If yes, please describe"
                        labelStyle={{ fontSize: 16, fontWeight: 600 }}
                        inputLength={(e) => e.target.value = (e.target.value).slice(0, 500)}
                        onChange={(e) => dispatch(
                            onChangeSpecialEducationForm5({
                                isSelected: 'Yes',
                                detail: e.target.value
                            })
                        )}
                    />
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0 d-flex align-items-center">
                    <p className="my-0 mr-3" style={{ fontWeight: 600 }}>Is your child receiving learning assistance at school?</p>
                    <CheckboxComp
                        checked={learningAssistance.isSelected === 'Yes' && true}
                        label="Yes"
                        onChange={() => dispatch(
                            onChangeLearningAssistanceForm5({
                                isSelected: 'Yes',
                                detail: learningAssistance.detail
                            })
                        )}
                    />
                    <CheckboxComp
                        checked={learningAssistance.isSelected === 'No' && true}
                        className="ml-3"
                        label="No"
                        onChange={() => dispatch(
                            onChangeLearningAssistanceForm5({
                                isSelected: 'No',
                                detail: learningAssistance.detail
                            })
                        )}
                    />
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0">
                    <TextAreaComp
                        disabled={learningAssistance.isSelected === 'No' && true}
                        value={learningAssistance.detail}
                        label="If yes, please describe"
                        labelStyle={{ fontSize: 16, fontWeight: 600 }}
                        inputLength={(e) => e.target.value = (e.target.value).slice(0, 500)}
                        onChange={(e) => dispatch(
                            onChangeLearningAssistanceForm5({
                                isSelected: 'Yes',
                                detail: e.target.value
                            })
                        )}
                    />
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0 d-flex align-items-center">
                    <p className="my-0 mr-3" style={{ fontWeight: 600 }}>Has your child been suspended or expelled from school?</p>
                    <CheckboxComp
                        checked={suspendedOrExpelled.isSelected === 'Yes' && true}
                        label="Yes"
                        onChange={() => dispatch(
                            onChangeSuspendedOrExpelledForm5({
                                isSelected: 'Yes',
                                detail: suspendedOrExpelled.detail
                            })
                        )}
                    />
                    <CheckboxComp
                        checked={suspendedOrExpelled.isSelected === 'No' && true}
                        className="ml-3"
                        label="No"
                        onChange={() => dispatch(
                            onChangeSuspendedOrExpelledForm5({
                                isSelected: 'No',
                                detail: suspendedOrExpelled.detail
                            })
                        )}
                    />
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0">
                    <TextAreaComp
                        disabled={suspendedOrExpelled.isSelected === 'No' && true}
                        value={suspendedOrExpelled.detail}
                        label="If yes, please describe"
                        labelStyle={{ fontSize: 16, fontWeight: 600 }}
                        inputLength={(e) => e.target.value = (e.target.value).slice(0, 500)}
                        onChange={(e) => dispatch(
                            onChangeSuspendedOrExpelledForm5({
                                isSelected: 'Yes',
                                detail: e.target.value
                            })
                        )}
                    />
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0 d-flex align-items-center">
                    <p className="my-0 mr-3" style={{ fontWeight: 600 }}>Has your child ever received tutoring?</p>
                    <CheckboxComp
                        checked={receivedTutoring.isSelected === 'Yes' && true}
                        label="Yes"
                        onChange={() => dispatch(
                            onChangeReceivedTutoringForm5({
                                isSelected: 'Yes',
                                detail: receivedTutoring.detail
                            })
                        )}
                    />
                    <CheckboxComp
                        checked={receivedTutoring.isSelected === 'No' && true}
                        className="ml-3"
                        label="No"
                        onChange={() => dispatch(
                            onChangeReceivedTutoringForm5({
                                isSelected: 'No',
                                detail: receivedTutoring.detail
                            })
                        )}
                    />
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0">
                    <TextAreaComp
                        disabled={receivedTutoring.isSelected === 'No' && true}
                        value={receivedTutoring.detail}
                        label="If yes, please describe"
                        labelStyle={{ fontSize: 16, fontWeight: 600 }}
                        inputLength={(e) => e.target.value = (e.target.value).slice(0, 500)}
                        onChange={(e) => dispatch(
                            onChangeReceivedTutoringForm5({
                                isSelected: 'Yes',
                                detail: e.target.value
                            })
                        )}
                    />
                </Col>
            </Row>

            <div className="w-100 pt-5">
                <h4>Brieﬂy describe classroom or school problems if applicable:</h4>
            </div>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0">
                    <TextAreaComp
                        value={schoolProblems}
                        label="Describe"
                        labelStyle={{ fontSize: 16, fontWeight: 600 }}
                        inputLength={(e) => e.target.value = (e.target.value).slice(0, 1000)}
                        onChange={(e) => dispatch(onChangeSchoolProblemsForm5(e.target.value))}
                    />
                </Col>
            </Row>

            <div className="mt-5 d-flex justify-content-center">
                <button className="pre-call-form-btn" onClick={() => props.onChangeStep(6)}>Next</button>
            </div>
        </div>
    )
}

export default PreCallQuestionForm5;
