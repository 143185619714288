import Form5State from '../../States/OnBoardingFormStates/Form5State'

const Form5Reducer = (state = Form5State, action) => {
    switch (action.type) {
        case 'form5-draft-data': {
            state = action.payload
            return state
        }   
        case 'onChangeLearningProgramForm5': {
            state.modiﬁedLearningProgram = action.payload
            return state
        }   
        case 'onChangeEducationPlanForm5': {
            state.individualEducationPlan = action.payload
            return state
        }   
        case 'onChangeCurrentLearningProgramForm5': {
            action.payload.isNull = action.payload.detail.trim() !== '' ? false : true
            state.currentLearningProgram = action.payload
            return state
        }   
        case 'onChangeHeldBackGradeForm5': {
            action.payload.isNull = action.payload.grade.trim() !== '' ? false : true
            state.heldBackGrade = action.payload
            return state
        }   
        case 'onChangeSpecialEducationForm5': {
            action.payload.isNull = action.payload.detail.trim() !== '' ? false : true
            state.specialEducationClasses = action.payload
            return state
        }   
        case 'onChangeLearningAssistanceForm5': {
            action.payload.isNull = action.payload.detail.trim() !== '' ? false : true
            state.learningAssistance = action.payload
            return state
        }   
        case 'onChangeSuspendedOrExpelledForm5': {
            action.payload.isNull = action.payload.detail.trim() !== '' ? false : true
            state.suspendedOrExpelled = action.payload
            return state
        }   
        case 'onChangeReceivedTutoringForm5': {
            action.payload.isNull = action.payload.detail.trim() !== '' ? false : true
            state.receivedTutoring = action.payload
            return state
        }   
        case 'onChangeSchoolProblemsForm5': {
            state.schoolProblems = action.payload
            return state
        }   
        case 'RESET_FORM_5':{
            state = Data
            return state
        }
        default:{
            return state
        }
    }
}


const Data = {
    modiﬁedLearningProgram: false,
    individualEducationPlan: false,
    currentLearningProgram: { isSelected: 'Yes', detail: '',isNull:true },
    heldBackGrade: { isSelected: 'No', grade: '',isNull:true },
    specialEducationClasses: { isSelected: 'No', detail: '',isNull:true },
    learningAssistance: { isSelected: 'No', detail: '',isNull:true },
    suspendedOrExpelled: { isSelected: 'No', detail: '',isNull:true },
    receivedTutoring: { isSelected: 'No', detail: '',isNull:true },
    schoolProblems: '',
}


export default Form5Reducer