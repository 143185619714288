import React, { useState, useEffect } from 'react'
import {Row,Col} from 'react-bootstrap'
import {useDispatch,useSelector} from 'react-redux'
import {
        onChangeAnyMedicationForm2,onChangeDevelopmentMilestoneForm2,onChangeSmokeForm2,onChangeAlcoholicBeveragesSelectForm2,
        onChangeAlcoholicBeveragesForm2,onChangeUseDrugForm2,onChangeCompilcationExpDuringPregForm2,
        onChangeComplicationDuringDeliveryForm2,onChangeBabyWasBornForm2,onChangeChildBirthWeightForm2,onChangePostDeliveryComplicationForm2,
        onChangeAgeSingleWordForm2,onChangeAgePhrasesForm2,onChangeChildBirthProblemForm2
} from '../../../Store/Actions/OnBoardingFormActions/Form2Actions'
import InputComp from '../../../Components/InputComp'
import TextAreaComp from '../../../Components/TextAreaComp'
import CheckboxComp from '../../../Components/CheckboxComp'
import './CSS/PreCallQuestionForm.css'

function PreCallQuestionForm2(props) {

    const dispatch = useDispatch()

    const [babyWasBornSelect,setBabyWasBornSelect] = useState(1)
    const [babyWasBornAge1,setBabyWasBornAge1] = useState('')
    const [babyWasBornAge2,setBabyWasBornAge2] = useState('')


    const developmentalMilestones = useSelector(state=>state.Form2Reducer.developmentalMilestones)
    const anyMedication = useSelector(state=>state.Form2Reducer.anyMedication)
    const smoke = useSelector(state=>state.Form2Reducer.smoke)
    const isSelectedAlcoholic = useSelector(state=>state.Form2Reducer.isSelectedAlcoholic)
    const alcoholicBeverages = useSelector(state=>state.Form2Reducer.alcoholicBeverages)
    const drugs = useSelector(state=>state.Form2Reducer.drugs)
    const compilcationExpDuringPreg = useSelector(state=>state.Form2Reducer.compilcationExpDuringPreg)
    const anyComplicationDuringDelivery = useSelector(state=>state.Form2Reducer.anyComplicationDuringDelivery)
    const childBirthWeight = useSelector(state=>state.Form2Reducer.childBirthWeight)
    const childBirthProblem = useSelector(state=>state.Form2Reducer.childBirthProblem)
    const postDeliveryComplication = useSelector(state=>state.Form2Reducer.postDeliveryComplication)
    const ageSingleWordAppear = useSelector(state=>state.Form2Reducer.ageSingleWordAppear)
    const agePhrasesAppear = useSelector(state=>state.Form2Reducer.agePhrasesAppear)


    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])
 
    return (
        <div style={{width:'100%'}} className="pre-call-form-container">
            <h3>DEVELOPMENTAL HISTORY</h3>
            <p style={{marginTop:0,color:'#F99B9B'}}>If your child is adopted, please fill in as much of the following information as you are aware of.</p>

            <div className="w-100 pt-5">
                <h4>During pregnancy, did the mother of this child</h4>
            </div>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0 d-flex align-items-center">
                    <p className="my-0 mr-3" style={{fontWeight:600}}>Take any medication?</p>
                    <CheckboxComp 
                        checked={anyMedication.isSelected === 'Yes' && true} 
                        label="Yes"
                        onChange={()=>dispatch(onChangeAnyMedicationForm2({isSelected:'Yes',value:anyMedication.value}))}
                    />
                    <CheckboxComp 
                        checked={anyMedication.isSelected === 'No' && true}  
                        className="ml-3" 
                        label="No"
                        onChange={()=>dispatch(onChangeAnyMedicationForm2({isSelected:'No',value:anyMedication.value}))}
                    />
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0">
                    <InputComp 
                        label="Type Here"
                        disabled={anyMedication.isSelected === 'No' && true} 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        value={anyMedication.value}
                        onChange={(e)=>dispatch(onChangeAnyMedicationForm2({isSelected:'Yes',value:e.target.value}))}
                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,200)}
                        isError={null}
                    />
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0 d-flex align-items-center">
                    <p className="my-0 mr-3" style={{fontWeight:600}}>Smoke?</p>
                    <CheckboxComp 
                        checked={smoke === 'Yes' && true} 
                        label="Yes"
                        onChange={()=>dispatch(onChangeSmokeForm2('Yes'))}
                    />
                    <CheckboxComp
                        checked={smoke === 'No' && true} 
                        className="ml-3" 
                        label="No"
                        onChange={()=>dispatch(onChangeSmokeForm2('No'))}
                    />
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0 d-flex align-items-center">
                    <p className="my-0 mr-3" style={{fontWeight:600}}>Drink alcoholic beverages?</p>
                    <CheckboxComp 
                        checked={isSelectedAlcoholic === 'Yes' && true} 
                        label="Yes"
                        onChange={()=>dispatch(onChangeAlcoholicBeveragesSelectForm2('Yes'))}
                    />
                    <CheckboxComp
                        checked={isSelectedAlcoholic === 'No' && true} 
                        className="ml-3" 
                        label="No"
                        onChange={()=>dispatch(onChangeAlcoholicBeveragesSelectForm2('No'))}
                    />
                </Col>
            </Row>


            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0">
                    <InputComp 
                        label="If yes, approximately how often" 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        disabled={isSelectedAlcoholic === 'No' && true} 
                        value={alcoholicBeverages.howOften}
                        onChange={(e)=>dispatch(onChangeAlcoholicBeveragesForm2({howOften:e.target.value,consumedEachDay:alcoholicBeverages.consumedEachDay}))}
                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,100)}
                        isError={null}
                    />
                </Col>
                <Col className="pr-0">
                    <InputComp 
                        label="Approximately how much alcohol was consumed each day" 
                        disabled={isSelectedAlcoholic === 'No' && true} 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        value={alcoholicBeverages.consumedEachDay}
                        onChange={(e)=>dispatch(onChangeAlcoholicBeveragesForm2({howOften:alcoholicBeverages.howOften,consumedEachDay:e.target.value}))}
                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
                        isError={null} 
                    />
                </Col>
            </Row>


            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0 d-flex align-items-center">
                    <p className="my-0 mr-3" style={{fontWeight:600}}>Use drugs?</p>
                    <CheckboxComp 
                        checked={drugs.isSelected === 'Yes' && true} 
                        label="Yes"
                        onChange={()=>dispatch(onChangeUseDrugForm2({isSelected:'Yes',value:drugs.value}))}
                    />
                    <CheckboxComp
                        checked={drugs.isSelected === 'No' && true} 
                        className="ml-3" 
                        label="No"
                        onChange={()=>dispatch(onChangeUseDrugForm2({isSelected:'No',value:drugs.value}))}
                    />
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0">
                    <InputComp 
                        label="If yes, approximately how often" 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        value={drugs.value}
                        disabled={drugs.isSelected === 'No' && true}
                        onChange={(e)=>dispatch(onChangeUseDrugForm2({isSelected:'Yes',value:e.target.value}))}
                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,200)}
                        isError={null} 
                    />
                </Col>
            </Row>

            <div className="w-100 pt-5">
                <h4>Please list any complications experienced during pregnancy (excessive vomiting, excessive
                    staining blood loss, threatened miscarriage, infections, toxemia, fainting, dizziness, etc):</h4>
            </div>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0">
                    <TextAreaComp 
                        label="Type Here" 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        value={compilcationExpDuringPreg}
                        onChange={(e)=>dispatch(onChangeCompilcationExpDuringPregForm2(e.target.value))}
                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,500)}
                        isError={null} 
                    />
                </Col>
            </Row>
        
            <div className="w-100 pt-5">
                <h4>Baby was born</h4>
            </div>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0 d-flex align-items-center">
                    <div className="d-flex align-items-end mr-5">
                        <CheckboxComp 
                            checked={babyWasBornSelect === 1 && true} 
                            className="mr-3"
                            onChange={()=>{
                                dispatch(onChangeBabyWasBornForm2(`Early at ${babyWasBornAge1} weeks`));
                                setBabyWasBornSelect(1)
                            }}
                        />
                        <p className="my-0" style={{fontWeight:600}}>
                            Early at  
                            <input
                                type="number"
                                value={babyWasBornAge1}
                                min="0"
                                disabled={babyWasBornSelect !== 1&&true}
                                style={{border:'none',border:'none',borderBottom:'solid 2px black',width:100,textAlign:'center'}}
                                onInput={(e)=>e.target.value = (e.target.value).slice(0,2)}
                                onChange={(e)=>{
                                    setBabyWasBornAge1(e.target.value);
                                    dispatch(onChangeBabyWasBornForm2(`Late at ${e.target.value} weeks`))
                                }}
                            />  
                            weeks 
                        </p>
                    </div>

                    <div className="d-flex align-items-end mr-5">
                        <CheckboxComp 
                            checked={babyWasBornSelect === 2 && true}
                            className="mr-3"
                            onChange={()=>{
                                dispatch(onChangeBabyWasBornForm2(`Early at ${babyWasBornAge2} weeks`));
                                setBabyWasBornSelect(2)
                                
                            }}
                        />
                        <p className="my-0" style={{fontWeight:600}}>
                            Late at  
                            <input
                                type="number"
                                min="0"
                                disabled={babyWasBornSelect !== 2&&true}
                                value={babyWasBornAge2}
                                style={{border:'none',border:'none',borderBottom:'solid 2px black',width:100,textAlign:'center'}} 
                                onInput={(e)=>e.target.value = (e.target.value).slice(0,2)}
                                onChange={(e)=>{
                                    setBabyWasBornAge2(e.target.value);
                                    dispatch(onChangeBabyWasBornForm2(`Late at ${e.target.value} weeks`))
                                }}
                            />  
                            weeks 
                        </p>
                    </div>

                    <CheckboxComp
                        label="Ontime"
                        checked={babyWasBornSelect === 3 && true}
                        onChange={()=>{
                            setBabyWasBornSelect(3);
                            dispatch(onChangeBabyWasBornForm2(`Ontime`))
                        }}
                    />
                </Col>
            </Row>

            <div className="w-100 pt-5">
                <h4>Were there any complications during labour & delivery</h4>
            </div>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0 d-flex align-items-center">
                    <CheckboxComp 
                        checked={anyComplicationDuringDelivery.isSelected === 'Yes'&&true} 
                        label="Yes"
                        onChange={()=>dispatch(onChangeComplicationDuringDeliveryForm2({isSelected:'Yes',value:anyComplicationDuringDelivery.value}))}
                    />
                    <CheckboxComp
                        checked={anyComplicationDuringDelivery.isSelected === 'No'&&true} 
                        className="ml-3" 
                        label="No"
                        onChange={()=>dispatch(onChangeComplicationDuringDeliveryForm2({isSelected:'No',value:anyComplicationDuringDelivery.value}))}
                    />
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0">
                    <TextAreaComp 
                        label="What Happened?" 
                        labelStyle={{fontSize:16,fontWeight:600}} 
                        disabled={anyComplicationDuringDelivery.isSelected === 'No'&&true}
                        onChange={(e)=>dispatch(onChangeComplicationDuringDeliveryForm2({isSelected:'Yes',value:e.target.value}))}
                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,500)}
                        isError={null} 
                    />
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-5">
                <Col sm={12} md={6} lg={4} className="pl-0 d-flex align-items-center">
                    <h4 className="m-0">What was your child's birth weight? (in kg)</h4>
                </Col>
                <Col sm={12} md={6} lg={8} className="pl-0">
                    <InputComp
                        type="number"
                        label="Type Here"
                        labelStyle={{fontSize:16,fontWeight:600}}
                        value={childBirthWeight}
                        onChange={(e)=>dispatch(onChangeChildBirthWeightForm2(e.target.value))}
                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,2)}
                        isError={null}
                    />
                </Col>
            </Row>


            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0 d-flex align-items-center">
                    <p className="my-0 mr-3" style={{fontWeight:600}}>Check any that apply following birth</p>
                    <CheckboxComp 
                        checked={childBirthProblem.value === 'Jaundice'&&true} 
                        label="Jaundice"
                        onChange={()=>dispatch(onChangeChildBirthProblemForm2({value:'Jaundice',detail:childBirthProblem.detail}))}
                    />
                    <CheckboxComp 
                        className="ml-3" 
                        label="Breathing problems"
                        checked={childBirthProblem.value === 'Breathing problems'&&true}
                        onChange={()=>dispatch(onChangeChildBirthProblemForm2({value:'Breathing problems',detail:childBirthProblem.detail}))}
                    />
                    <CheckboxComp 
                        className="ml-3" 
                        label="Birth defect"
                        checked={childBirthProblem.value === 'Birth defect'&&true}
                        onChange={()=>dispatch(onChangeChildBirthProblemForm2({value:'Birth defect',detail:childBirthProblem.detail}))}
                    />
                </Col>
            </Row>


            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0">
                    <TextAreaComp 
                        label="Please Describe" 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        value={childBirthProblem.detail}
                        onChange={(e)=>dispatch(onChangeChildBirthProblemForm2({value:childBirthProblem.value,detail:e.target.value}))}
                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,500)}
                        isError={null} 
                    />
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0 d-flex align-items-center">
                    <p className="my-0 mr-3" style={{fontWeight:600}}>Were there any other post-delivery complications?</p>
                    <CheckboxComp 
                        label="Yes"
                        checked={postDeliveryComplication.isSelected === 'Yes'&&true}
                        onChange={()=>dispatch(onChangePostDeliveryComplicationForm2({isSelected:'Yes',value:postDeliveryComplication.value}))}
                    />
                    <CheckboxComp 
                        className="ml-3" 
                        label="No"
                        checked={postDeliveryComplication.isSelected === 'No'&&true}
                        onChange={()=>dispatch(onChangePostDeliveryComplicationForm2({isSelected:'No',value:postDeliveryComplication.value}))}
                    />
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0">
                    <TextAreaComp 
                        label="Please Describe" 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        disabled={postDeliveryComplication.isSelected === 'No'&&true}
                        value={postDeliveryComplication.value}
                        onChange={(e)=>dispatch(onChangePostDeliveryComplicationForm2({isSelected:'Yes',value:e.target.value}))}
                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,500)}
                        isError={null}
                    />
                </Col>
            </Row>

            <div className="w-100 pt-5">
                <h4>Please indicate the approximate age at which your child achieved the following developmental milestones
                    by checking the appropriate box. Check "Never” if your child has not yet achieved a given milestone.</h4>
            </div>

            {
                developmentalMilestones.map((dt,i)=>{
                    return(
                        <Row className="mx-0 p-0 mt-5">
                            <Col md={4} lg={3}>
                                <p className="my-0 mr-3" style={{fontWeight:600}}>{dt.value}</p>
                            </Col>
                            <Col className="pl-0 d-flex align-items-center">
                                <CheckboxComp 
                                    checked={dt.selected === 'Early'&&true} 
                                    label="Early" 
                                    onChange={()=>dispatch(onChangeDevelopmentMilestoneForm2({index:i,selected:'Early'}))} 
                                />
                                <CheckboxComp 
                                    checked={dt.selected === 'Average'&&true} 
                                    className="ml-3" label="Average"
                                    onChange={()=>dispatch(onChangeDevelopmentMilestoneForm2({index:i,selected:'Average'}))}
                                />
                                <CheckboxComp 
                                    checked={dt.selected === 'Late'&&true} 
                                    className="ml-3" 
                                    label="Late"
                                    onChange={()=>dispatch(onChangeDevelopmentMilestoneForm2({index:i,selected:'Late'}))}
                                />
                                <CheckboxComp 
                                    checked={dt.selected === 'Never'&&true} 
                                    className="ml-3" 
                                    label="Never"
                                    onChange={()=>dispatch(onChangeDevelopmentMilestoneForm2({index:i,selected:'Never'}))}
                                />
                            </Col>
                        </Row>
                    )
                })
            }

            <div className="w-100 mt-5 pt-3">
                <h4>If your child was late to talk, please estimate the following:</h4>
            </div>
            <Row className="mx-0 p-0 mt-5">
                <Col className="pl-0">
                    <InputComp 
                        type="number"
                        label="Age at which single words appeared" 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        value={ageSingleWordAppear}
                        onChange={(e)=>dispatch(onChangeAgeSingleWordForm2(e.target.value))}
                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,2)}
                        isError={null}
                    />
                </Col>
                <Col className="pr-0">
                    <InputComp
                        type="number"
                        label="Age at which phrases appeared" 
                        labelStyle={{fontSize:16,fontWeight:600}}
                        value={agePhrasesAppear}
                        onChange={(e)=>dispatch(onChangeAgePhrasesForm2(e.target.value))}
                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,2)}
                        isError={null} 
                    />
                </Col>
            </Row>
            <div className="mt-5 d-flex justify-content-center">
                <button className="pre-call-form-btn" onClick={()=>props.onChangeStep(3)}>Next</button>
            </div>
        </div>
    )
}

export default PreCallQuestionForm2;
