import React,{useState,useEffect,useRef} from 'react'
import {useSelector,useDispatch} from 'react-redux'
import {baseURL, Decrypt, headers} from '../../Config'
import {useHistory} from 'react-router-dom'
import {GetClinicianAppointments} from '../../Store/Actions/AppointmentActions'
import {GetRBTData} from '../../Store/Actions/RBTActions'
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import ParentDashboardNavbar from '../../Components/ParentDashboardNavbar'
import ClinicianDashboardNavbar from '../../Components/ClinicianDashboardNavbar'
import { toast } from 'react-toastify'
import './CSS/ReportGenerationIntro.css'

const formSteps = [
    {name:'ISP Initial Report',type:0},
    {name:'B - Relaxation Practice - Program',type:1},
    {name:'B - Session Tip Sheet',type:2},
    {name:'B - Tolerating Novelty - Program',type:3},
    {name:'B - Tracking Outbursts - Program',type:4},
    {name:'B - Wait for Reinforcer long - Program',type:5}
]

export default function ReportGenerationIntro(props) {

    const iRef = useRef()
    const dispatch = useDispatch()

    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const [url,setURL] = useState('')
    const [userType,setUserType] = useState(null)

    const sidebarWidth = useSelector(data => data.SidebarReducer.width)

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })
        dispatch(GetRBTData())
        dispatch(GetClinicianAppointments())

        // Get Intro Form
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            setUserType(Decrypt(dt.userType))
            const path = Decrypt(dt.userType) === 'BC' ? '/api/createForm' : '/api/getForm'
            const obj = {childId:props.match.params.id, therapistId:dt.uid, type:props.match.params.type}

            fetch(baseURL + path,{
                method:'Post',
                body:JSON.stringify(obj),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    toast.error(res2?.message)
                }
                else{
                    setURL(res2?.message?.url)
                }
            })
            .catch(err=>console.log(err))
        }
    },[])

    return (
        <div className="dashboard-report-gen-container">
            {/* <ParentDashboardSideBar active="reports" user={userType&&userType === 'BC' ? "BC" :userType&&userType === 'BCBA' ? 'BCBA' :userType&&userType === 'RBT' ? 'RBT' : 'Parent'} /> */}
            <div>
                {/* {
                    userType&&userType === 'Parent' ?
                        <ParentDashboardNavbar active="reports" user='Parent' />
                    :
                        <ClinicianDashboardNavbar 
                            active="reports" 
                            user={userType&&userType === 'BC' ? "BC" :userType&&userType === 'RBT' ? "RBT" : 'BCBA'}
                            showAppointments={(userType === 'BCBA' || userType === 'RBT') ? false : true}
                        />
                } */}
                <div style={{width:'100%',height:'100vh',padding:'20px 40px'}}>
                    <iframe 
                        ref={iRef}
                        width='100%' 
                        height='100%' 
                        src={url}
                        style={{borderRadius:15,border:'solid 1.5px #096E82'}}
                    ></iframe>
                </div>
            </div>
        </div>
    )
}
