import React, { useState } from "react";
import InputComp2 from "../Components/InputComp2";
import PinkBtn from "../Components/PinkBtn";
import SelectComponent from "../Components/SelectComponent";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Informed from "../Components/Informed";
import { baseURL } from "../Config";
import { toast } from "react-toastify";
import {useHistory} from 'react-router-dom'

function CareersFrom() {

  const history = useHistory()
  
  const [firstName,setFirstName] = useState("")
  const [lastName,setLastName] = useState("")
  const [email,setEmail] = useState("")
  const [phone,setPhone] = useState("")
  const [address1,setAddress1] = useState("")
  const [address2,setAddress2] = useState("")
  const [city,setCity] = useState("")
  const [province,setProvince] = useState("")
  const [postalCode,setPostalCode] = useState("")
  const [bcba,setBCBA] = useState("")
  const [RASP,setRASP] = useState("")
  const [devExp,setDevExp] = useState("")
  const [homeSetting,setHomeSetting] = useState("")
  const [authorized,setAuthorized] = useState("")
  const [backgCheck,setBackgCheck] = useState("")
  const [selectedFile, setSelectedFile] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
	};

  const [canadaProvences, setCanadaProvences] = useState([
    "Alberta",

    "British Columbia",

    "Manitoba",

    "New Brunswick",

    "Newfoundland and Labrador",

    "Northwest Territories",

    "Nova Scotia",

    "Nunavut",

    "Ontario",

    "Prince Edward Island",

    "Quebec",

    "Saskatchewan",

    "Yukon Territory",
  ]);


  const handleSubmit = (e)=>{
    e.preventDefault()
    
    const formData = new FormData();

    formData.append("image", selectedFile)
    formData.append("name", firstName +" "+ lastName)
    formData.append("email", email)
    formData.append("phone", phone)
    formData.append("address1", address1)
    formData.append("address2", address2)
    formData.append("city", city)
    formData.append("bcba", bcba)
    formData.append("province",province)
    formData.append("postalCode",postalCode)
    formData.append("serviceProvider",RASP)
    formData.append("developmentExperience",devExp)
    formData.append("homeSetting",homeSetting)
    formData.append("authorized",authorized)
    formData.append("backgroundCheck",backgCheck)

    setIsLoading(true)
    fetch(baseURL + '/api/submitCareerRequest',{
      method:'Post',
      body:formData
    })
    .then(res=>res.json())
    .then(res2=>{
      if(res2?.code == 'Success'){
        setIsLoading(false)
        toast.success(res2?.message,{toastId:'career-form-succ',autoClose:3000})
        history.goBack()
      }
      else{
        setIsLoading(false)
      }
    })
    .catch(err =>{
      setIsLoading(false)
      console.log(err)
    })
  }

  return (
    <div className="w-[100%] h-[100%] mt-[140px] xl:mt-[180px] ">
      <Header />
      <div className="careersHeader h-[100%] w-[100%] px-5">
        <div className="pageHeader my-4">
          <div className="text-5xl font-bold text-[#096E82]">
            Now Hiring Behavior Interventionists
          </div>
          {/* <div className="text-3xl font-semibold my-2 text-[#096E82] text-justify">
            Join Our Collaborative & Supportive Team w/ a $2,000 Signing Bonus
          </div> */}
        </div>
        <div className="grid grid-cols-2 md:grid-cols-7 my-3 gap-x-[15px]">
          <div className="leftSide col-span-2 md:col-span-3 ">
            {/* text */}
            <div className="text-2xl font-thin text-[#096E82] text-3xl">
              About Us
            </div>
            <div className="desc text-lg">
              <div className="my-3 text-justify">
                We’re a unique team of psychologists, scientists, researchers,
                and engineers with a passion for making a difference in the
                lives of children with Autism and their families
              </div>
              <div className="my-3 text-justify">
                Our experts have devoted their careers to Autism research and
                advocated for better care and access to services across
                continents.
              </div>
              <div className="my-3 text-justify">
                At Cognitia, we’re building on this passion by combining
                research with technology to offer evidence-based programs with a
                family focused approach to ensure the best possible outcomes.
              </div>
              <div className="my-3 text-justify">
                We strive to nurture the potential of children with autism and
                support their families every step of the way.
              </div>
            </div>
            <div className="wrapper my-5">
              <div className="text-2xl font-thin text-[#096E82] text-3xl">
                Position
              </div>
              <div className="desc text-lg">Behavior Interventionist</div>
            </div>  <div className="wrapper my-5">
              <div className="text-2xl font-thin text-[#096E82] text-3xl">
                Location
              </div>
              <div className="desc text-lg">Lower Mainland (British Columbia)</div>
            </div>
            <div className="wrapper my-5">
              <div className="text-2xl font-thin text-[#096E82] text-3xl">
                What You'll do
              </div>
              <div className="desc text-lg text-justify">
              As a Behavior Interventionist you will be working with young children diagnosed with autism as part of the Behavioral Intervention team, under the direct supervision of a Behavioural Consultant. Your responsibilities will include:
              </div>
              <div className="ml-4 my-3">
                <div className="text-2xl font-thin text-[#096E82]">
                  Responsibilities
                </div>
                <ul className="ml-4" style={{ listStyleType: "circle" }}>
                  <li className="desc text-lg my-2 text-justify">
                  Providing Behaviour Intervention/Support services in a child’s home 
                  </li>
                  <li className="desc text-lg my-2 text-justify">
                  Preparing supports and materials
                  </li>
                  <li className="desc text-lg my-2 text-justify">
                  Collecting data on child’s behaviors
                  </li>
                  <li className="desc text-lg my-2 text-justify">
                  Participating in monthly clinical meetings
                  </li>
                  <li className="desc text-lg my-2 text-justify">
                  Using data collection tools to monitor effectiveness of the treatment plan
                  </li>
                </ul>
              </div>
              <div className="ml-4 my-3">
                <div className="text-2xl font-thin text-[#096E82]">
                  Core Skills
                </div>
                <ul className="ml-4" style={{ listStyleType: "circle" }}>
                  <li className="desc text-lg my-2">
                  	Ability to function independently and meet deadlines.
                  </li>
                  <li className="desc text-lg my-2">Ability to handle clients in a crisis.</li>
                </ul>
              </div>
              <div className="ml-4 my-3">
                <div className="text-2xl font-thin text-[#096E82]">
                  What you’ll need
                </div>
                <ul className="ml-4" style={{ listStyleType: "circle" }}>
                  <li className="desc text-lg my-2 text-justify">
                  High School degree or equivalent
                  </li>
                  <li className="desc text-lg my-2">
                  Minimum 6 months of experience working with children with Autism/special needs
                  </li>
                </ul>
              </div>
              <div className="ml-4 my-3">
                <div className="text-2xl font-thin text-[#096E82]">
                  Who you are
                </div>
                <ul className="ml-4" style={{ listStyleType: "circle" }}>
                  <li className="desc text-lg my-2">
                    Mission-driven:
                    <ul style={{ listStyleType: "circle" }} className="ml-4">
                      <li className="desc text-lg my-2 text-justify">
                      	Providing exceptional care to children with autism is at the heart of everything we do. You understand that and are dedicated to our mission
                      </li>
                    </ul>
                  </li>
                  <li className="desc text-lg my-2">
                    Motivated:
                    <ul style={{ listStyleType: "circle" }} className="ml-4">
                      <li className="desc text-lg my-2 text-justify">
                        It is important for you to take ownership of your tasks and ensure that the work you produce is of the highest quality. You communicate roadblocks and meet deadlines. Adding value is one of your priorities.
                      </li>
                    </ul>
                  </li>
                  <li className="desc text-lg my-2">
                    Creative:
                    <ul style={{ listStyleType: "circle" }} className="ml-4">
                      <li className="desc text-lg my-2">
                      	Solving complex problems with original ideas is your forte
                      </li>
                    </ul>
                  </li>
                  <li className="desc text-lg my-2">
                    Collaborative:
                    <ul style={{ listStyleType: "circle" }} className="ml-4">
                      <li className="desc text-lg my-2 text-justify">
                      Teamwork is your strength. You enjoy receiving feedback and incorporating others' opinions into your own
                      </li>
                    </ul>
                  </li>
                  <li className="desc text-lg my-2">
                    Compassionate:
                    <ul style={{ listStyleType: "circle" }} className="ml-4">
                      <li className="desc text-lg my-2">
                        People matter to you. It is important to you that others succeed
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            {/* next */}
            <div className="wrapper my-5">
              <div className="text-2xl font-thin text-[#096E82] text-3xl">
                Careers at Cognitia
              </div>
              <div className="desc text-lg text-justify">
                At Cognitia you will make a difference to the community every
                day by helping children acquire the skills they need to grow and
                become independent while empowering parents with training and
                resources to facilitating their success
              </div>
            </div>
            <div className="wrapper my-5">
              <div className="text-2xl font-thin text-[#096E82] text-3xl">
                What we believe
              </div>
              <div className="desc text-lg">
                <ul className="ml-4" style={{ listStyleType: "circle" }}>
                  <li className="desc text-lg my-2">
                    Focus on Family:
                    <ul style={{ listStyleType: "circle" }} className="ml-4">
                      <li className="desc text-lg my-2 text-justify">
                      	We’re committed to supporting the entire family and including them as part of the therapy. We want parents to be informed about what the therapy entails and to know how best to support their child when we’re not there
                      </li>
                    </ul>
                  </li>
                  <li className="desc text-lg my-2">
                    Individualized Treatment:
                    <ul style={{ listStyleType: "circle" }} className="ml-4">
                      <li className="desc text-lg my-2 text-justify">
                      A single solution does not fit all. We must ensure everything we do is tailored to our clients' needs and goals
                      </li>
                    </ul>
                  </li>
                  <li className="desc text-lg my-2">
                    Driven by Science:
                    <ul style={{ listStyleType: "circle" }} className="ml-4">
                      <li className="desc text-lg my-2 text-justify">
                      We’re passionate about ABA. Our clinicians use it every day to guide treatment
                      </li>
                    </ul>
                  </li>
                  {/* <li className="desc text-lg my-2">
                    Collaborative:
                    <ul style={{ listStyleType: "circle" }} className="ml-4">
                      <li className="desc text-lg my-2">
                        Teamwork is your strength. You enjoy receiving feedback
                        and incorporating others' opinions into your own
                      </li>
                    </ul>
                  </li> */}
                  {/* <li className="desc text-lg my-2">
                    Compassionate:
                    <ul style={{ listStyleType: "circle" }} className="ml-4">
                      <li className="desc text-lg my-2">
                        People matter to you. It is important to you that others
                        succeed
                      </li>
                    </ul>
                  </li> */}
                </ul>
              </div>
            </div>
            {/*  */}
            <div className="wrapper my-5">
              <div className="text-2xl font-thin text-[#096E82] text-3xl">
                Professional Development
              </div>
              <div className="desc text-lg text-justify">
                Your professional development is one of our top priorities, and we want to help you accomplish your career goals while enjoying the work you do
              </div>
              <div className="desc text-lg">
                <ul className="ml-4" style={{ listStyleType: "circle" }}>
                  <li className="desc text-lg my-2">
                    Training:
                    <ul style={{ listStyleType: "circle" }} className="ml-4">
                      <li className="desc text-lg my-2 text-justify">
                      We will provide you with comprehensive training to get you started
                      </li>
                    </ul>
                  </li>
                  <li className="desc text-lg my-2">
                    Certification Support:
                    <ul style={{ listStyleType: "circle" }} className="ml-4">
                      <li className="desc text-lg my-2 text-justify">
                      We provide you with full support for your certifications
                      </li>
                    </ul>
                  </li>
                  <li className="desc text-lg my-2">
                    BCBA Supervision & CEU:
                    <ul style={{ listStyleType: "circle" }} className="ml-4">
                      <li className="desc text-lg my-2">
                      	We provide BCBA Supervision & Continuing Education
                      </li>
                    </ul>
                  </li>
                  <li className="desc text-lg my-2">
                  Career advancement opportunities:
                    <ul style={{ listStyleType: "circle" }} className="ml-4">
                      <li className="desc text-lg my-2 text-justify">
                      	We put you on a clear career path and support you towards that goal
                      </li>
                    </ul>
                  </li>
                  {/* <li className="desc text-lg my-2">
                    Collaborative:
                    <ul style={{ listStyleType: "circle" }} className="ml-4">
                      <li className="desc text-lg my-2">
                        Teamwork is your strength. You enjoy receiving feedback
                        and incorporating others' opinions into your own
                      </li>
                    </ul>
                  </li> */}
                  {/* <li className="desc text-lg my-2">
                    Compassionate:
                    <ul style={{ listStyleType: "circle" }} className="ml-4">
                      <li className="desc text-lg my-2">
                        People matter to you. It is important to you that others
                        succeed
                      </li>
                    </ul>
                  </li> */}
                </ul>
              </div>
            </div>
            {/* Other Benefits */}
            <div className="wrapper my-5">
              <div className="text-2xl font-thin text-[#096E82] text-3xl">
                Other Benefits
              </div>
              <div className="desc text-lg">
                <ul className="ml-4" style={{ listStyleType: "circle" }}>
                  <li className="desc text-lg my-2">
                  Technology:
                    <ul style={{ listStyleType: "circle" }} className="ml-4">
                      <li className="desc text-lg my-2">
                      We provide tablets to all our staff
                      </li>
                    </ul>
                  </li>
                  <li className="desc text-lg my-2">
                  Health Insurance:
                    <ul style={{ listStyleType: "circle" }} className="ml-4">
                      <li className="desc text-lg my-2">
                      We provide medical, dental and vision insurance for full time staff
                      </li>
                    </ul>
                  </li>
                  {/* <li className="desc text-lg my-2">
                    Collaborative:
                    <ul style={{ listStyleType: "circle" }} className="ml-4">
                      <li className="desc text-lg my-2">
                        Teamwork is your strength. You enjoy receiving feedback
                        and incorporating others' opinions into your own
                      </li>
                    </ul>
                  </li> */}
                  {/* <li className="desc text-lg my-2">
                    Compassionate:
                    <ul style={{ listStyleType: "circle" }} className="ml-4">
                      <li className="desc text-lg my-2">
                        People matter to you. It is important to you that others
                        succeed
                      </li>
                    </ul>
                  </li> */}
                </ul>
              </div>
            </div>
            {/* last pera left bottom */}
            <div className="mb-5">
              <div className="text-2xl font-thin text-[#096E82] text-3xl">
              Equal Opportunity Employer
              </div>
              <div className="desc text-lg">
                  <div className="my-3 text-justify">
                  We’re an equal opportunity employer and are committed to providing all our team members a safe work environment. We strive to foster a culture that celebrates what makes each of us unique and welcome applicants from every background and life experience.
                    </div>
                </div>
            </div>
          </div>
          <div className="rightSide col-span-2 md:col-span-4">
            <div className="font-thin text-[#096E82] text-3xl">Apply Today</div>
            <div className="text-black text-lg my-2">
              "<span className="text-red-700 font-bold">*</span>" indicates
              required fields
            </div>
            <div className="flex justify-center  items-end text-lg ">
              <div className="right  mr-2 w-[100%]">
                <div className="label my-3 text-[#096E82] font-medium">
                  Name<span className="text-red-700 font-bold">*</span>
                </div>
                {/* <input className="font-medium" type="text" name="" id="" /> */}
                <InputComp2
                  type="text"
                  value={firstName}
                  onChange={(e)=>setFirstName(e.target.value)}
                />
              </div>
              <div className="right ml-2 w-[100%]">
                <div className="label my-3 text-[#096E82] font-semibold invisible">
                  TEST
                </div>
                {/* <input className="font-medium" type="text" name="" id="" /> */}
                <InputComp2
                  type="text"
                  value={lastName}
                  onChange={(e)=>setLastName(e.target.value)}
                />
              </div>
            </div>
            {/* second row */}
            <div className="flex justify-center items-end text-lg gap-[1rem]">
              <div className="right w-[100%]">
                <div className="label my-3 text-[#096E82] font-medium">
                  Email<span className="text-red-700 font-bold">*</span>
                </div>
                {/* <input className="font-medium" type="text" name="" id="" /> */}
                <InputComp2
                  type="text"
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)}
                />
              </div>
              <div className="right w-[100%]">
                <div className="label my-3 text-[#096E82] font-medium">
                  Phone
                </div>
                {/* <input className="font-medium" type="text" name="" id="" /> */}
                <InputComp2
                  type="text"
                  value={phone}
                  onChange={(e)=>setPhone(e.target.value)}
                />
              </div>
            </div>
            {/* 3rd orw */}
            <div className="right text-lg">
              <div className="label my-3 text-[#096E82] font-medium">
                Street Address
              </div>
              {/* <input className="font-medium" type="text" name="" id="" /> */}
              <InputComp2
                type="text"
                value={address1}
                onChange={(e)=>setAddress1(e.target.value)}
              />
            </div>
            {/* 4th row */}
            <div className="right text-lg">
              <div className="label my-3 text-[#096E82] font-medium">
                Address Line 2
              </div>
              {/* <input className="font-medium" type="text" name="" id="" /> */}
              <InputComp2
                type="text"
                value={address2}
                onChange={(e)=>setAddress2(e.target.value)}
              />
            </div>
            {/* 5th row */}
            <div className="flex justify-center items-end text-lg gap-[1rem]">
              <div className="right w-[100%]">
                <div className="label my-3 text-[#096E82] font-medium">
                  City
                </div>
                {/* <input className="font-medium" type="text" name="" id="" /> */}
                <InputComp2
                  type="text"
                  value={city}
                  onChange={(e)=>setCity(e.target.value)}
                />
              </div>
              <div className="right w-[100%]">
                <div className="label my-3 text-[#096E82] font-medium">
                  Province
                </div>
                {/* <input className="font-medium" type="text" name="" id="" /> */}
                <SelectComponent 
                  options={canadaProvences} 
                  value={province} 
                  onChange={(e)=>setProvince(e)}
                  containerStyle={{padding:'3px 0px',border:'solid 2px var(--secondary-color)'}}
                />
              </div>
            </div>
            {/* zipCode here */}
            <div className="right text-lg">
              <div className="label my-3 text-[#096E82] font-medium">
                Postal Code
              </div>
              {/* <input className="font-medium" type="text" name="" id="" /> */}
              <InputComp2
                type="text"
                value={postalCode}
                onChange={(e)=>setPostalCode(e.target.value)}
              />
            </div>
            {/* 6th row */}
            {/* <div className="flex justify-center items-end text-lg gap-[1rem]">
              <div className="right w-[100%]">
                <div className="label my-3 text-[#096E82] font-medium">
                  What is your BCBA#? <span className="text-red-700 font-bold">*</span>
                </div>
                <InputComp2
                  type="text"
                  value={bcba}
                  onChange={(e)=>setBCBA(e.target.value)}
                />
              </div>
              <div className="right w-[100%]">
                <div className="label my-3 text-[#096E82] font-medium">
                  Are you registered with the Registry of Autism Service
                  Providers (RASP)?<span className="text-red-700 font-bold">*</span>
                </div>
                <InputComp2
                  type="text"
                  value={RASP}
                  onChange={(e)=>setRASP(e.target.value)}
                />
              </div>
            </div> */}
            {/* 7th row */}
            <div className="flex justify-center items-end text-lg gap-[1rem]">
              <div className="right w-[100%]">
                <div className="label my-3 text-[#096E82] font-medium">
                  How many years of childhood development experience do you
                  have?<span className="text-red-700 font-bold">*</span>
                </div>
                {/* <input className="font-medium" type="text" name="" id="" /> */}
                <InputComp2
                  type="text"
                  value={devExp}
                  onChange={(e)=>setDevExp(e.target.value)}
                />
              </div>
              <div className="right w-[100%]">
                <div className="label my-3 text-[#096E82] font-medium">
                  Have you ever worked in a home setting?<span className="text-red-700 font-bold">*</span>
                </div>
                {/* <input className="font-medium" type="text" name="" id="" /> */}
                <InputComp2
                  type="text"
                  value={homeSetting}
                  onChange={(e)=>setHomeSetting(e.target.value)}
                />
              </div>
            </div>
            {/* 8th row */}
            <div className="flex justify-center items-end text-lg gap-[1rem]">
              <div className="right w-[100%]">
                <div className="label my-3 text-[#096E82] font-medium">
                  Are you authorized to work in Canada?<span className="text-red-700 font-bold">*</span>
                </div>
                {/* <input className="font-medium" type="text" name="" id="" /> */}
                <InputComp2
                  type="text"
                  value={authorized}
                  onChange={(e)=>setAuthorized(e.target.value)}
                />
              </div>
              <div className="right w-[100%]">
                <div className="label my-3 text-[#096E82] font-medium">
                  Are you willing to undergo a background check?<span className="text-red-700 font-bold">*</span>
                </div>
                {/* <input className="font-medium" type="text" name="" id="" /> */}
                <InputComp2
                  type="text"
                  value={backgCheck}
                  onChange={(e)=>setBackgCheck(e.target.value)}
                />
              </div>
            </div>
            {/* 9th row */}
            <div className="fileUploadComp">
              <div className="label my-3 text-lg text-[#096E82] font-medium">
                Please Upload Your Resume
              </div>
              <div className="fileInput">
                <input onChange={changeHandler} type="file" id="myfile" name="myfile" />
              </div>
            </div>

            {/* submit BTN */}
            <div className="submitBtn my-5 ">
              {
                isLoading ?
                  <button 
                    className="bg-lightPink-100 rounded-[10px] h-12 w-[136px] text-sm no-underline flex justify-center items-center font-semibold text-white hover:bg-pink-700 hover:scale-95 transition-all"
                  >
                    Submitting.....
                  </button>
                :
                  <button 
                    className="bg-lightPink-100 rounded-[10px] h-12 w-[136px] text-sm no-underline flex justify-center items-center font-semibold text-white hover:bg-pink-700 hover:scale-95 transition-all"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
              }
            </div>
          </div>
        </div>
      </div>
      <Informed />
      <Footer />
    </div>
  );
}

export default CareersFrom;