import crypto from 'crypto'

const ENCRYPTION_KEY = '155442c41a829fcb7de9a453171ce558'
const IV = '5db6fd5325ce8995'

export const Encrypt = (text) =>{
    var cipher = crypto.createCipheriv('aes-256-cbc',ENCRYPTION_KEY,IV)
    var crypted = cipher.update(text,'utf8','hex')
    crypted += cipher.final('hex');
    return crypted;
}

export const Decrypt = (text) => {
    var decipher = crypto.createDecipheriv('aes-256-cbc',ENCRYPTION_KEY,IV)
    var dec = decipher.update(text,'hex','utf8')
    dec += decipher.final('utf8');
    return dec;
}

// export const avatar = 'https://bx-specialist-data.s3.ap-southeast-1.amazonaws.com/avatar.jpg'
// export const baseURL = 'http://localhost:4000'
export const baseURL = 'https://api.cognitia.co:5000'


export const headers = {'Content-Type':'application/json'}


// export const socketURL = 'http://localhost:4000'
export const socketURL = 'https://api.cognitia.co:5000'

export const PUBNUB_PUBLISH_KEY = "pub-c-c14ac10b-2820-4f4b-8f6d-4e51db2a9e8f"
export const PUBNUB_SUBSCRIBE_KEY = "sub-c-2df75158-351d-4fae-a08a-59da00f4b7ac"
export const PUBNUB_SECRET_KEY = "sec-c-NzM0MGRjN2EtNzYxNS00NjZhLWIwZmQtZGI2MTEzYjIxYTRl"
export const GOOGLE_PLACES_API_KEY = "AIzaSyB9BrpIN_MqBgNEaaiGe2rDEcZOp0rM4YY"
export const AGORA_APP_ID = "e576e16dfbc74ab7879e18f36cd59d73"