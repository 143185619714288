import React, {useEffect,useState} from 'react';
import {Col} from 'react-bootstrap';
import Header from "../../../Components/Header";
import Footer from "../../../Components/Footer";
import Informed from "../../../Components/Informed";
import {Spinner} from 'react-bootstrap'
import {useHistory} from 'react-router-dom';
import insurancePay from '../../../images/insurance.png'
import selfPay from '../../../images/selfPayment.png'
import './CSS/SelectPaymentType.css';
import { baseURL, headers } from '../../../Config';
import { toast } from 'react-toastify';


export default function SelectPaymentType(props) { 

    const [type,setType] = useState(0)
    const [isLoading,setIsLoading] = useState(false)
    const history = useHistory()

    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])

    const handleContinue = () =>{

        const path = type === 0 ? '/api/selfPayment' : '/api/testPayWithInsurance'

        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){

            setIsLoading(true)
            fetch(baseURL + path ,{
                method:'Post',
                body:JSON.stringify({Id:dt?.childId}),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Success'){
                    setIsLoading(false)
                    res2?.message?.isPayed&&
                    history.push('/pre-call-questionnaire')
                }
                else{
                    toast.error(res2.message,{toastId:'self-pay-err-id1',autoClose:3000})
                    setIsLoading(false)
                }
            })
        }
    }

    return (
        <div style={{width:'100%'}}>
            <Header />
            <Col sm='12' md='12' lg='12' xl='12' className='mt-5' >
                <div className="Payment-type-Page-Container">
                    <div className="Payment-type-Page-Body">
                        <h1 style={{fontFamily:'CocoSharp bold'}}>Select Payment Type</h1>
                        <hr /> 
                        <div className="Payment-type-select-row">
                            <div onClick={()=>setType(0)} className={type === 0 ? "Payment-type-card active" : "Payment-type-card"}>
                                <img src={selfPay}/>
                                <h3>Self Pay</h3>
                            </div>
                            <div onClick={()=>setType(1)} className={type === 1 ? "Payment-type-card active" : "Payment-type-card"}>
                                <img src={insurancePay}/>
                                <h3>Insurance Pay</h3>
                            </div>
                        </div>
                        {
                            isLoading ?
                                <button className="Payment-type-cont-btn">
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    {' '}
                                    Loading....
                                </button>
                            :
                                <button onClick={handleContinue} className="Payment-type-cont-btn">Continue</button>
                        }
                    </div>
                </div>
            </Col>
            <Informed />
            <Footer />
        </div>
      
    );
  }
  

  