import React from "react";
import HomeCard from "../../Components/HomeCard";
import PinkBtn from "../../Components/PinkBtn";

export default function ChangeLife() {
  const tiles = [
    {
      title: "Digital Onboarding",
      desc: "We understand the importance of time. Our onboarding process is entirely digitized that can be completed from the comfort of your home.",
      url: "./Icons/onboard.png",
      color: "blue",
    },
    {
      title: "ABA at Home",
      desc: "We work with your child in a one-on-one setting at your home. Our evidence-based treatments increase your child’s skill acquisition in a highly motivating and enjoyable environment.",
      url: "./Icons/home.png",
      color: "pink",
    },
    {
      title: "ABA at School",
      desc: "Our clinicians work with your child in the school setting to ensure academic success. Our personalized programs help your child excel academically and behaviorally.",
      url: "./Icons/school.png",
      color: "blue",
    },
    {
      title: "Virtual ABA",
      desc: "At Cognitia, we understand the importance of family time. Whether you’re away on holiday or just prefer to connect virtually, our telehealth ABA services ensure your child continues to receive therapy uninterrupted.",
      url: "./Icons/virtualABA.png",
      color: "pink",
    },
    {
      title: "Caregiver Training",
      desc: "At Cognitia, we are committed to helping families. We train them with skills to manage problem behavior. Our proprietary parent training program, OASIS, has been developed by leading clinicians.",
      url: "./Icons/careGiverIcon.png",
      color: "blue",
    },
    {
      title: "Patient Care Services",
      desc: "We eliminate the stress of navigating the complex system of obtaining autism services. Our teams assist you throughout the process of obtaining a timely diagnosis to receiving funding and starting therapy.",
      url: "./Icons/patientCareIcon.png",
      color: "pink",
    },
  ];
  return (
    <>
      <div className="grid grid-cols-1">
        <p className="text-[#096E82] text-3xl p-[20px] font-bold md:text-5xl text-center md:px-[200px] md:py-[100px] ">
          Changing Lives Through Exceptional Care, One Milestone at a time
        </p>
      </div>
      <div className="grid grid-cols-1 px-5 lg:grid-cols-2 xl:grid-cols-3 gap-6 md:px-[180px] justify-center w-fit mx-auto">
        {tiles.map((v, i) => (
          <HomeCard
            key={i}
            src={v.url}
            color={v.color}
            title={v.title}
            desc={v.desc}
            subtitle2={v.subtitle2}
          />
        ))}
      </div>
      <div className="w-full flex justify-center mt-16">
        <PinkBtn text={"Get Started"} url={"signup"} />
      </div>
    </>
  );
}
