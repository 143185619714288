import React from "react";
import img from "../../assets/autism/behavior.png";
import patt from "../../assets/Home/model-patt.png";
import PinkBtn from "../../Components/PinkBtn";
function Behavior() {
  return (
    <div className="w-full flex justify-center items-center mt-[140px] xl:mt-[220px] px-5">
      <div className="xl:flex-row flex-col max-w-[1440px] flex justify-center items-center gap-16 xl:gap-8 w-full">
        <div className="relative isolate  flex justify-end items-center">
          <img
            src={patt}
            className="absolute -z-[1] -top-[10%] sm:-left-[8%] h-full -left-[4%]"
            alt=""
          />
          <img
            src={img}
            alt=""
            className="w-full max-w-[400px] xl:max-w-[522px]"
          />
        </div>
        <div className="flex justify-center items-start flex-col gap-4 xl:w-1/2">
          <h1 className="text-lightGreen-500 leading-9 sm:leading-[50px] font-bold text-[30px] sm:text-[48px]  ">
            Behavior Problems are <br /> not Abnormalities
          </h1>
          <p className="text-lightGreen-500 text-justify font-normal text-lg mb-0">
            Behavior problems are not abnormalities, but reasonable responses
            necessitated by the abilities of the individual and limitations of
            the environment. Problem behaviors continue to be exhibited because
            of the effects they create on the behavior of others and the
            environment.
          </p>
          <p className="w-full text-right text-lightGreen-500 font-bold text-lg">V. Mark Durand (1990)</p>
          <div className="mt-6">
            <PinkBtn text={"Get Started"} url="signup" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Behavior;
