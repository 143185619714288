import React,{useEffect,useState} from 'react'
import {baseURL, Decrypt, Encrypt, headers} from '../../Config'
import {useSelector,useDispatch} from 'react-redux'
import Skeleton from 'react-loading-skeleton';
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import ClinicianDashboardNavbar from '../../Components/ClinicianDashboardNavbar'
import { toast } from 'react-toastify'
import {useHistory} from 'react-router-dom'
import './CSS/ClinicianDetail.css'
import { Row,Col,Spinner } from 'react-bootstrap'

export default function ClinicianDetail(props) {

    const history = useHistory()

    const sidebarWidth = useSelector(data => data.SidebarReducer.width)
    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const [clinicianDetail,setClinicianDetail] = useState(null)
    const [isSelected,setIsSelected] = useState(1)
    const [isLoaded,setIsLoaded] = useState(false)
    const [userType,setUserType] = useState(null)

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })

        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            const type = props.match.params.type
            const path = type === 'rbt' ? '/api/getRbtById' :type === 'behaviour_consultant' ? '/api/get-clinician-detail-by-id' : '/api/getbcbaById'
            setUserType(Decrypt(dt.userType))
            fetch(baseURL + path,{
                method:'Post',
                body:JSON.stringify({uid:props.match.params.id}),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    toast.error('error..')
                    setIsLoaded(true)
                }
                else{
                    setClinicianDetail(res2.message[0])
                    setIsLoaded(true)
                }
            })
        }
    },[])


    return (
        <div className="bc-profile-detail-container">
            <ParentDashboardSideBar 
                user={userType&&userType === 'BC' ? "BC" :userType&&userType === 'BCBA' ? 'BCBA' : 'RBT'}
                active={
                    (userType&&userType === 'BCBA' && props.match.params.type === 'behaviour_consultant') ? 'bc' 
                    : (userType&&userType === 'BC' && props.match.params.type === 'bcba') ? 'bcba'
                    : null }
            />
            <div style={{marginLeft:windowWidth > 1024 &&sidebarWidth}}>
                <ClinicianDashboardNavbar 
                    user={userType&&userType === 'BC' ? "BC" :userType&&userType === 'BCBA' ? 'BCBA' : 'RBT'}
                    showAppointments={userType === 'BCBA' ? false : true}
                />
                <div className="bc-profile-detail-body">
                    <h2 style={{fontFamily:'CocoSharp bold'}}> {props.match.params.type=='bcba'?'BCBA Profile':'Clinician Profile Detail'} </h2>
                    {
                        isLoaded ?
                            <>
                                <div className="mx-0 mt-5 bc-profile-detail-row-1">
                                    <div className="bc-profile-detail-row-1-col-1" style={{backgroundImage:`url(${clinicianDetail&&Decrypt(clinicianDetail.image)})`}}>
                                    </div>
                                    <div className="bc-profile-detail-row-1-col-2">
                                        <div>
                                            <h1 style={{marginBottom:0,fontFamily:'CocoSharp regular',fontSize:36,color:'#096E82'}}>{clinicianDetail&&Decrypt(clinicianDetail.first_name) + ' ' + Decrypt(clinicianDetail.last_name)}</h1>
                                            <h4 style={{marginTop:10,fontFamily:'CocoSharp bold',color:'#AEDFE5'}}>Doctor</h4>
                                            <p style={{wordBreak:'break-all',marginTop:0,lineHeight:1.4,fontFamily:'CocoSharp regular',width:windowWidth >=768 ? '70ch' : '100%',fontSize:17,wordWrap:'break-word'}}>{clinicianDetail&&clinicianDetail.short_des ? Decrypt(clinicianDetail.short_des) : 'No Description'}</p>
                                        </div>
                                        <button onClick={()=>history.push('/therapist-chats')} className="clinician-send-message-btn">Send Message</button>
                                    </div>
                                </div>

                                <div className="mx-0 mt-3 bc-profile-detail-row-2">
                                    <div className="bc-profile-detail-row-2-tab-bar">
                                        <button className="bc-profile-detail-row-2-tab-bar-btn" onClick={()=>setIsSelected(1)} style={isSelected === 1 ? {borderBottom:'solid 2px #F99B9B',color:'#F99B9B'} : {borderBottom:'solid 1px #AEB0D0',color:'#ADB5CC'}}>
                                            Experiences
                                        </button>
                                        <button className="bc-profile-detail-row-2-tab-bar-btn" onClick={()=>setIsSelected(2)}  style={isSelected === 2 ? {borderBottom:'solid 2px #F99B9B',color:'#F99B9B'} : {borderBottom:'solid 1px #AEB0D0',color:'#ADB5CC'}}>
                                            About
                                        </button>
                                        {/* <button className="bc-profile-detail-row-2-tab-bar-btn" onClick={()=>setIsSelected(3)}  style={isSelected === 3 ? {borderBottom:'solid 2px #F99B9B',color:'#F99B9B'} : {borderBottom:'solid 1px #AEB0D0',color:'#ADB5CC'}}>
                                            Schedule
                                        </button> */}
                                        <hr style={{width:'100%',margin:0,borderTop:'solid 1px #AEB0D0'}} />
                                    </div>
                                    <div className="w-100">
                                    {
                                        isSelected === 1 ?
                                            <div className="mt-3 d-flex flex-wrap">
                                                {
                                                    clinicianDetail&&
                                                    clinicianDetail.experiences.map(dt=>{
                                                        return(
                                                            <div style={{border:'solid 1px #096E82',borderRadius:8,width:320,padding:'10px 10px',marginRight:10,marginTop:10}}>
                                                                <p style={{fontSize:16,fontFamily:'CocoSharp bold',color:'#096E82',margin:0}}>Hospital Name: {Decrypt(dt.hospital_name)}</p>
                                                                <p style={{fontSize:16,fontFamily:'CocoSharp bold',color:'#096E82',margin:0}}>Experience: {Decrypt(dt.experience)}</p>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        : isSelected === 2 ?
                                            <div>
                                                {
                                                    <p style={{wordBreak:'break-all',marginTop:20,lineHeight:1.3,fontFamily:'CocoSharp regular',width:windowWidth >=768 ? '70ch' : '100%',fontSize:17,wordWrap:'break-word'}}>{clinicianDetail&&clinicianDetail.short_des ? Decrypt(clinicianDetail.short_des) : 'No Description'}</p>
                                                }
                                            </div>
                                        :
                                            <div></div>
                                    }
                                    </div>
                                </div>
                            </>
                        :
                                <Row className="mx-0 mt-5 bc-profile-detail-row-1">
                                    <div className="bc-profile-detail-row-1-col-1">
                                        <Skeleton height="100%" />
                                    </div>
                                    <div className="bc-profile-detail-row-1-col-2">
                                        <div>
                                            <Skeleton height={25} width="100%" />
                                            <Skeleton height={25} width="100%" />
                                            <Skeleton height={150} width="100%" />
                                        </div>
                                        <div className="d-flex align-items-center mt-4">
                                            <Skeleton height={45} width={150} style={{marginRight:10}} />
                                            <Skeleton height={45} width={150} style={{backgroundColor:'transparent',backgroundImage:'none'}} />
                                        </div>
                                    </div>
                                </Row>
                    }
                </div>
            </div>
        </div>
    )
}
