import React from "react";
import HomeCard from "../../Components/HomeCard";

export default function KeyFeature() {
  const tiles = [
    {
      title: "Applied Science",
      desc: "ABA is an applied science with over fifty years of strong empirical support.",
      url: "./Icons/onboard.png",
      color: "blue",
    },
    {
      title: "Science of Learning",
      desc: "ABA is the science of learning that can benefit every child, regardless of disability or limitation.",
      url: "./Icons/home.png",
      color: "pink",
    },
    {
      title: "Data Driven",
      desc: "ABA is a data driven science. The data demonstrates the effectiveness of the program.",
      url: "./Icons/DataD.png",
      color: "blue",
    },
  ];
  return (
    <>
      <div className="grid grid-cols-1">
        <p className="text-[#096E82] font-bold text-[20px] px-[20px] py-[20px] md:text-[48px] text-center md:px-[200px] md:py-[100px] leading-none">
          A key feature to Applied Behavior Analysis is the recognition of the plasticity of behavior and the ability of every person to learn.
        </p>
      </div>
      <div className="grid grid-cols-1 px-5 lg:grid-cols-2 xl:grid-cols-3 gap-6 md:px-[180px] justify-center w-fit mx-auto">
        {tiles.map((v, i) => (
          <HomeCard
            key={i}
            src={v.url}
            color={v.color}
            title={v.title}
            desc={v.desc}
          />
        ))}
      </div>
    </>
  );
}
