import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../images/foot-logo.png";
import {
  faFacebook,
  faInstagram,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="w-full flex justify-center bg-lightGreen-200 px-5 md:px-20 flex-col items-center">
      <div className="w-full max-w-[1440px]">
        <div className="flex justify-between w-full items-center py-16 xl:flex-row flex-col gap-7 xl:gap-0">
          <div className="flex flex-col justify-start">
            <img src={logo} alt="" />
            <div className="text-lightGreen-500 font-bold text-lg mt-4 mb-3">
              Head Office
            </div>
            <div className="text-lightGreen-500 font-normal text-sm my-1">
              810 Quayside Dr.
              New Westminster
              BC V3M 6B9
              Canada
            </div>
            <div className="text-lightGreen-500 font-normal text-sm my-1">
            +1 778 917 5835
            </div>
            <div className="text-lightGreen-500 font-normal text-sm my-1">
              <a href="mailto: hello@cognitia.co">hello@cognitia.co</a > 
            </div>
          </div>
          <div className="flex justify-center lg:items-start gap-14 lg:flex-row flex-col items-center">
            <div className="flex lg:items-start lg:justify-start flex-col gap-2 justify-center items-center">
              <h2 className="text-lightGreen-500 font-medium text-xl">
                Our Services
              </h2>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to="Works"
                className="no-underline text-lightGreen-500 font-normal text-sm"
              >
                Autism Care
              </Link>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to="Works"
                className="no-underline text-lightGreen-500 font-normal text-sm"
              >
                Autism Diagnosis
              </Link>
            </div>
            <div className="flex lg:items-start lg:justify-start flex-col gap-2 justify-center items-center">
              <h2 className="text-lightGreen-500 font-medium text-xl">
                For Parents
              </h2>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to="Autism"
                className="no-underline text-lightGreen-500 font-normal text-sm"
              >
                Applied Behavior Analysis
              </Link>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to="Autism"
                className="no-underline text-lightGreen-500 font-normal text-sm"
              >
                <span className="font-mono">3</span>-Term Contingency
              </Link>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to="Autism"
                className="no-underline text-lightGreen-500 font-normal text-sm"
              >
                Behavior Problems
              </Link>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to="Autism"
                className="no-underline text-lightGreen-500 font-normal text-sm"
              >
                Science & Pseudoscience
              </Link>
              {/* <Link
                to=""
                className="no-underline text-lightGreen-500 font-normal text-sm"
              >
                M-CHAT Screening
              </Link>
              <Link
                to=""
                className="no-underline text-lightGreen-500 font-normal text-sm"
              >
                FAQ
              </Link> */}
            </div>
            <div className="flex lg:items-start lg:justify-start flex-col gap-2 justify-center items-center">
              <h2 className="text-lightGreen-500 font-medium text-xl">
                For Pediatricians
              </h2>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to={"/ReferalForm"}
                target="_blank"
                className="no-underline text-lightGreen-500 font-normal text-sm"
              >
                Refer a Patient
              </Link>
            </div>
            <div className="flex lg:items-start lg:justify-start flex-col gap-2 justify-center items-center">
              <h2 className="text-lightGreen-500 font-medium text-xl">
                For Providers
              </h2>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to={"/Career"}
                className="no-underline text-lightGreen-500 font-normal text-sm"
              >
                Join Cognitia
              </Link>
            </div>
            <div className="flex lg:items-start lg:justify-start flex-col gap-2 justify-center items-center">
              <h2 className="text-lightGreen-500 font-medium text-xl">
                About Us
              </h2>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to={"/About"}
                className="no-underline text-lightGreen-500 font-normal text-sm"
              >
                Our Story
              </Link>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to="/Team"
                className="no-underline text-lightGreen-500 font-normal text-sm"
              >
                Our Team
              </Link>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to="/Contact"
                className="no-underline text-lightGreen-500 font-normal text-sm"
              >
                Contact
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center w-full border-t-[1px] border-solid border-[rgba(9, 110, 130, 0.5)]">
        <div className="flex justify-between w-full items-center py-6 max-w-[1440px] md:flex-row md:gap-0 flex-col gap-4">
          <p className="text-lightGreen-500 font-normal text-sm">
            © Copyright. All Rights Reserved
          </p>
          <div className="flex justify-center items-center gap-4">
            {/* <Link
              to=""
              className="no-underline text-lightGreen-500 font-normal text-sm"
            >
              Cookies
            </Link> */}
            <Link
              target="_blank"
              onClick={() => window.scrollTo(0, 0)}
              to="/cookiePolicy"
              className="no-underline text-lightGreen-500 font-normal text-sm">
                Cookies
              
            </Link>
            <Link
              onClick={() => window.scrollTo(0, 0)}
              to="/privacyPolicy"
              target="_blank"
              className="no-underline text-lightGreen-500 font-normal text-sm"
            >
              Privacy Policy
            </Link>
            <Link
              target="_blank"
              onClick={() => window.scrollTo(0, 0)}
              to="/termAndConditions"
              className="no-underline text-lightGreen-500 font-normal text-sm"
            >
              Terms & Conditions
            </Link>
            
          </div>
          <div className="flex justify-center items-center gap-4">
            <Link onClick={() => window.scrollTo(0, 0)} to="" className="no-underline">
              {" "}
              <FontAwesomeIcon
                className="text-2xl text-lightGreen-500"
                icon={faFacebook}
              />
            </Link>
            <a onClick={() => window.scrollTo(0, 0)} target="blank" href="https://twitter.com/social_cognitia" className="no-underline">
              {" "}
              <FontAwesomeIcon
                className="text-2xl text-lightGreen-500"
                icon={faTwitter}
              />
            </a>
            <a onClick={() => window.scrollTo(0, 0)} target="blank" href="https://www.instagram.com/social_cognitia/" className="no-underline">
              {" "}
              <FontAwesomeIcon
                className="text-2xl text-lightGreen-500"
                icon={faInstagram}
              />
            </a>
            <Link onClick={() => window.scrollTo(0, 0)} to="#" className="no-underline">
              {" "}
              <FontAwesomeIcon
                className="text-2xl text-lightGreen-500"
                icon={faLinkedinIn}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
