import React, {useEffect, useState} from 'react';
import {Col,Spinner} from 'react-bootstrap';
import {PasswordValidation,Required} from '../../../Validations'
import { toast } from 'react-toastify';
import Header from "../../../Components/Header";
import Footer from "../../../Components/Footer";
import Informed from "../../../Components/Informed";
import InputPassComp from '../../../Components/InputPassComp';
import { baseURL, Encrypt, headers } from '../../../Config';
import {Link,useLocation} from 'react-router-dom';
import './CSS/ResetPassPage.css';


export default function ResetPassPage() {

    const location = useLocation()

    const [isLoading,setIsLoading] = useState(false)
    const [password, setPassword] = useState('');
    const [PasswordErr, setPasswordErr] = useState(null);

    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordErr, setConfirmPasswordErr] = useState(null);


    const [uid, setUID] = useState('');

    useEffect(()=>{
        setUID(location.search.slice(4,location.search.length))
    })

    const handleConfirmPassword = (e) =>{
        var pass = password.substr(0,e.target.value.length)
        setConfirmPassword(e.target.value)

        if(e.target.value.length > 0){
            if(e.target.value.length === password.length){
                if(pass === e.target.value){
                    setConfirmPasswordErr(1)
                }
                else{
                    setConfirmPasswordErr(2)
                }
            }
            else if(e.target.value.length < password.length)
            {
                if(pass === e.target.value){
                    setConfirmPasswordErr(0)
                }   
                else{
                    setConfirmPasswordErr(2)
                }
            }
            else{
                setConfirmPasswordErr(2)
            }
        }
        else{
            setConfirmPasswordErr(null)
        }
    }


    const handleUpdatePassword=()=>{
        if(Required('Password',password) && PasswordErr !== 0)
        {
            if(password !== confirmPassword){
                toast.error('Password not matched', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
            else if(uid.trim() === ''){
                toast.error('Wrong User Id')
            }
            else{
                setIsLoading(true)
                fetch(baseURL+'/change-password',{
                    method:'Post',
                    body:JSON.stringify({password:Encrypt(password),uid}),
                    headers:headers
                })
                .then(res=>res.json())
                .then(res2=>{
                    if(res2.code === 'Success'){
                        setIsLoading(false)
                        toast.success('Password Changed...',{toastId:'reset-pass-toast1'})
                    }
                    else{
                        toast.error(res2.message,{toastId:'reset-pass-toast2'})
                        setIsLoading(false)
                    }
                })
            }
        }
    }

    return (
        <div style={{width:'100%'}}>
            <Header />
            <Col sm='12' md='12' lg='12' xl='12' >
                <div className="Reset-Pass-Page-Container mt-5">
                    <div className="Reset-Pass-Page-Body">
                        <h1 style={{fontFamily:'CocoSharp bold'}}>Reset Password</h1>
                        <hr /> 
                        <div style={{width:'100%'}}>
                            <InputPassComp 
                                value={password}
                                onChange={(e)=> (setPasswordErr(PasswordValidation(e.target.value)), setPassword(e.target.value))}
                                inputLength={(e)=>e.target.value = (e.target.value).slice(0,64)}
                                containerStyle={{marginTop:30}} 
                                label="New Password" 
                                labelStyle={{fontSize:16,fontWeight:600}} 
                            />
                            {PasswordErr === 0 &&<p style={{marginLeft:10,fontSize:12,color:'red'}}><b>Weak</b>, Password must contain one digit (0-9) and one special character (! @ # $ % ^ & *)</p>}
                            {PasswordErr === 1 &&<p style={{marginLeft:10,fontSize:12,color:'green'}}>Strong</p>}

                            <InputPassComp
                                value={confirmPassword}
                                onChange={(e)=> handleConfirmPassword(e)}
                                inputLength={(e)=>e.target.value = (e.target.value).slice(0,64)}
                                containerStyle={{marginTop:30}} 
                                label="Confirm Password" 
                                labelStyle={{fontSize:16,fontWeight:600}} 
                            />
                            {confirmPasswordErr === 0 &&<p style={{marginLeft:10,fontSize:12,color:'blue'}}>Good Going</p>}
                            {confirmPasswordErr === 1 &&<p style={{marginLeft:10,fontSize:12,color:'green'}}>Perfect Matched</p>}
                            {confirmPasswordErr === 2 &&<p style={{marginLeft:10,fontSize:12,color:'red'}}>Not matched</p>}

                            <br/>
                            {
                                isLoading ?
                                    <button className="Reset-Pass-btn">
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        {' '}
                                        Loading....
                                    </button>
                                :
                                    <button className="Reset-Pass-btn" onClick={handleUpdatePassword}>Update Password</button>
                            }
                            <br/>
                            <div style={{marginTop:12}}>
                                <p style={{fontSize:16,fontFamily:'CocoSharp bold'}}>Do you remember your password? <Link to="/login" className="Reset-Pass-page-link">Login</Link></p>  
                            </div>        
                        </div>
                    </div>
                </div>
            </Col>
            <Informed />
            <Footer />
        </div>
      
    );
  }
  

  