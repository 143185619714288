import React,{useEffect,useState} from 'react'
import { Navbar,Nav } from 'react-bootstrap';
import {Link} from 'react-router-dom'
import logo from '../images/logo.svg'
import Avatar from 'react-avatar';
import Skeleton from 'react-loading-skeleton'
import {baseURL, Decrypt, headers} from '../Config'
import DropDown from './DropDown'
import './CSS/Navbar.css'

export default function MyNavbar(props) {

    const [image,setImage] = useState('')
    const [status,setStatus] = useState(0)
    const [userType,setUserType] = useState('')
    const [childStatus,setChildStatus] = useState(null)
    const [isLoggedIn,setIsLoggedIn] = useState(false)
    const [token,setToken] = useState('')
    const [path,setPath] = useState('')

    useEffect(()=>{
        const data = JSON.parse(localStorage.getItem('bx_user_token'))

        if(data){
            setToken(data.accessToken)
            setIsLoggedIn(true)

            if(Decrypt(data.userType) === 'BCBA'){
                
                setStatus(null)
                setUserType(Decrypt(data.userType))

                fetch(baseURL+'/api/getbcbaById',{
                    method:'Post',
                    body:JSON.stringify({uid:data.uid}),
                    headers:{...headers,'authorization':'Bearer ' + data.accessToken}
                })
                .then(res=>res.json())
                .then(res2=>{
                    if(res2.code === 'Success' && res2.message.length > 0){
                        const dt = res2.message[0]
                        setImage(Decrypt(dt.image))

                        if(token&&userType === 'BCBA'&&isLoggedIn){
                            setPath('/bcba-dashboard')
                        }
                        else{
                            setPath('')
                        }
                    }
                    else{
                        handleLogout()
                    }
                })
            }
            else if(Decrypt(data.userType) === 'BC'){
                
                setStatus(null)
                setUserType(Decrypt(data.userType))

                fetch(baseURL+'/api/getClinicianById',{
                    method:'Post',
                    body:JSON.stringify({uid:data.uid}),
                    headers:{...headers,'authorization':'Bearer ' + data.accessToken}
                })
                .then(res=>res.json())
                .then(res2=>{
                    if(res2.code === 'Success' && res2.message.length > 0){
                        const dt = res2.message[0]
                        setImage(Decrypt(dt.image))

                        if(token&&userType === 'BC'&&isLoggedIn){
                            setPath('/bc-dashboard')
                        }
                        else{
                            setPath('')
                        }
                    }
                    else{
                        handleLogout()
                    }
                })
            }
            else if(Decrypt(data.userType) === 'RBT'){
                
                setStatus(null)
                setUserType(Decrypt(data.userType))

                fetch(baseURL+'/api/getRbtById',{
                    method:'Post',
                    body:JSON.stringify({uid:data.uid}),
                    headers:{...headers,'authorization':'Bearer ' + data.accessToken}
                })
                .then(res=>res.json())
                .then(res2=>{
                    if(res2.code === 'Success' && res2.message.length > 0){
                        const dt = res2.message[0]
                        setImage(Decrypt(dt.image))

                        if(token&&userType === 'RBT'&&isLoggedIn){
                            setPath('/rbt-appointments')
                        }
                        else{
                            setPath('')
                        }
                    }
                    else{
                        handleLogout()
                    }
                })
            }
            else{

                setStatus(Decrypt(data.userStatus))
                if(Object.entries(data).length > 4){
                    setChildStatus(Decrypt(data.childStatus))
                }


                fetch(baseURL+'/api/getParentById',{
                    method:'Post',
                    body:JSON.stringify({uid:data.uid}),
                    headers:{...headers,'authorization':'Bearer ' + data.accessToken}
                })
                .then(res=>res.json())
                .then(res2=>{
                    
                    if(res2.code === 'Success' && res2.message.length > 0){
                        const dt = res2.message[0].obj
                        setImage(Decrypt(dt.image))

                        if(token&&parseInt(status) >= 1&&isLoggedIn){
                            setPath('/dashboard-home')
                        }
                        else if(token&&parseInt(status) === 0&&parseInt(childStatus) === 0&&isLoggedIn){
                            setPath('/payment-detail')
                        }
                        else if(token&&parseInt(status) === 0&&parseInt(childStatus) === 1&&isLoggedIn){
                            setPath('/pre-call-questionnaire')
                        }
                        else if(token&&parseInt(status) === 0&&parseInt(childStatus) === 2&&isLoggedIn){
                            setPath('/schedule-first-meeting')
                        }
                        else{
                            setPath('')
                        }
                    }
                    else{
                        handleLogout()
                    }
                })
            }
        }
    })

    const handleLogout = () =>{
        localStorage.removeItem('bx_user_token')
        setToken('')
        setStatus(null)
        setChildStatus(null)
        setIsLoggedIn(false)
    }

    return (
        <Navbar expand="lg" className='navbar-container sections-horizontal-padding'>
            <Navbar.Brand className="logo" as={Link} to="/"><img className="img-fluid" src={logo} /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav>
                    <Link to="/" className="link">
                        HOME
                    </Link>
                    <Link to="/how-does-it-work" className="link">
                        HOW DOES IT WORK?
                    </Link>
                    
                    <Link to="/pediatricians" className="link">
                        FOR PEDIATRICIANS
                    </Link>
                    <DropDown
                        title={"ABOUT US"}
                        linkClass="link"
                        options={[
                            {
                                route: "/our-team",
                                name: "Our Team"
                            },
                            {
                                route: "/our-story",
                                name: "Our Story"
                            },
                            {
                                route: "/careers",
                                name: "Careers"
                            },
                            {
                                route: "#",
                                name: "Contact Us"
                            },
                        ]}
                    />
                </Nav>
                
                {
                    isLoggedIn?
                        <div className='navbar-right-col'>
                            <div style={{display:'flex',alignItems:'center'}}>
                                {
                                    image ?
                                        <Avatar src={image} size="30" round={true} style={{border:'solid 1px gray'}} />
                                    :
                                        <Skeleton width="30px" height="30px" style={{borderRadius:'50%'}} />
                                }
                                <Link to={path || '#'} className='navbar-right-col ml-2'>
                                    <p>DASHBOARD</p>
                                </Link>
                            </div>

                            <Link to="#" onClick={()=>handleLogout()}  className='navbar-auth-btn'>
                                LOGOUT
                            </Link>
                        </div>
                    :
                        <div className='navbar-right-col'>
                            <Link to="/login" >
                                <p>LOGIN</p>
                            </Link>

                            <Link to="/child-condition" className='navbar-auth-btn'>
                                SIGNUP
                            </Link>
                        </div>
                }
            </Navbar.Collapse>
        </Navbar>
    )
}
