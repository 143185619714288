export const onChangeNameForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeNameForm1',payload:data})
    }
}

export const onChangeBirthDateForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeBirthDateForm1',payload:data})
    }
}

export const onChangeSchoolNameForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeSchoolNameForm1',payload:data})
    }
}


export const onChangeFamilyDocNameForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeFamilyDocNameForm1',payload:data})
    }
}


export const onChangeGradeForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeGradeForm1',payload:data})
    }
}

export const onChangeRefferingDocNameForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeRefferingDocNameForm1',payload:data})
    }
}

export const onChangePrimaryLangForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangePrimaryLangForm1',payload:data})
    }
}

export const onChangeOtherLangForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeOtherLangForm1',payload:data})
    }
}

export const onChangeChildFirstLangForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeChildFirstLangForm1',payload:data})
    }
}

export const onChangeAgeWhenLearnedEnglishForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeAgeWhenLearnedEnglishForm1',payload:data})
    }
}

export const onChangeChildBirthCountryForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeChildBirthCountryForm1',payload:data})
    }
}

export const onChangeAgeWhenArrivedCanadaForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeAgeWhenArrivedCanadaForm1',payload:data})
    }
}

export const onChangeChildLivesWithForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeChildLivesWithForm1',payload:data})
    }
}

// Parent 1 actions
export const onChangeParent1NameForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeParent1NameForm1',payload:data})
    }
}

export const onChangeParent1AgeForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeParent1AgeForm1',payload:data})
    }
}

export const onChangeParent1CellForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeParent1CellForm1',payload:data})
    }
}

export const onChangeParent1EmailForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeParent1EmailForm1',payload:data})
    }
}

export const onChangeParent1OccupationForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeParent1OccupationForm1',payload:data})
    }
}

export const onChangeParent1EducationForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeParent1EducationForm1',payload:data})
    }
}


// Parent 2 actions
export const onChangeParent2NameForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeParent2NameForm1',payload:data})
    }
}

export const onChangeParent2AgeForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeParent2AgeForm1',payload:data})
    }
}

export const onChangeParent2CellForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeParent2CellForm1',payload:data})
    }
}

export const onChangeParent2EmailForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeParent2EmailForm1',payload:data})
    }
}

export const onChangeParent2OccupationForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeParent2OccupationForm1',payload:data})
    }
}

export const onChangeParent2EducationForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeParent2EducationForm1',payload:data})
    }
}


// End parent 2 actions

export const onChangeMaritalStatusForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeMaritalStatusForm1',payload:data})
    }
}

export const onChangeLegalCustodyForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeLegalCustodyForm1',payload:data})
    }
}


// Other Relative actions

export const onChangeRelativeNameForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeRelativeNameForm1',payload:data})
    }
}

export const onChangeRelativeAgeForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeRelativeAgeForm1',payload:data})
    }
}

export const onChangeRelativeRelationForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeRelativeRelationForm1',payload:data})
    }
}

export const onChangeRelativeLivesInForm1 = (data) =>{
    return(dispatch)=>{
        dispatch({type:'onChangeRelativeLivesInForm1',payload:data})
    }
}