import React from "react";
import HomeCard from "../../Components/HomeCard";

export default function Principles() {
  const tiles = [
    {
      title: "Reinforcement",
      desc: "This is an event that follows a behavior that makes the behavior more likely to increase. Reinforcement is idiosyncratic. This means that ",
      list1:
        "Things that are reinforcing to one child may not be reinforcing to another child.",
      list2:
        "Also, things that are reinforcing to a child at any given time may not be reinforcing at a later time.",
      url: "./Icons/reinfo.png",
      color: "blue",
      lg: "lg",
    },

    {
      title: "Function",
      desc: "All behavior is functional and serves a purpose. The two functions of behavior are",

      list1:
        "positive reinforcement (to gain access to people, items, activities, settings) and ",

      list2:
        "negative reinforcement (to escape or avoid people, activities, settings).",

      url: "./Icons/func.png",
      color: "pink",
      lg: "lg",
    },
    {
      title: "Extinction",
      desc: "If the behavior does not work, meaning that it no longer results in reinforcement, the individual will stop using that behavior.",

      url: "./Icons/ext.png",
      color: "blue",
      lg: "lg",
    },
  ];

  return (
    <>
      <div className="grid grid-cols-1">
        <p className="text-[#096E82] font-bold text-[25px] px-[20px] py-[20px] md:text-[48px] text-center md:px-[200px] md:py-[100px] ">
          The Basic Principles of Behaviour
        </p>
      </div>
      <div className="grid grid-cols-1 px-5 lg:grid-cols-2 xl:grid-cols-3 gap-6 md:px-[180px] justify-center w-fit mx-auto">
        {tiles.map((v, i) => (
          <HomeCard
            key={i}
            src={v.url}
            color={v.color}
            title={v.title}
            desc={v.desc}
            list1={v.list1}
            list2={v.list2}
            lg={v.lg}
          />
        ))}
      </div>
    </>
  );
}
