import Form4State from '../../States/OnBoardingFormStates/Form4State'

const Form4Reducer = (state = Form4State, action) => {
    switch (action.type) {
        case 'form4-draft-data': {
            state = action.payload
            return state
        }
        case 'onChangeSleepinAndEatingForm4': {
            const newArr = state.sleepinAndEating.map((dt, i) => {
                if (i === action.payload.index) {
                    dt = action.payload.data
                    return dt
                }
                return dt
            })

            return {
                ...state,
                sleepinAndEating: newArr
            }
        }
        case 'onChangeBehaviourForm4': {
            const newArr = state.behaviour.map((dt, i) => {
                if (i === action.payload.index) {
                    dt = action.payload.data
                    return dt
                }
                return dt
            })

            return {
                ...state,
                behaviour: newArr
            }
        }
        case 'onChangeSocialDevelopmentForm4': {
            const newArr = state.socialDevelopment.map((dt, i) => {
                if (i === action.payload.index) {
                    dt = action.payload.data
                    return dt
                }
                return dt
            })

            return {
                ...state,
                socialDevelopment: newArr
            }
        }
        case 'onChangeMotorSkillsForm4': {
            const newArr = state.motorSkills.map((dt, i) => {
                if (i === action.payload.index) {
                    dt = action.payload.data
                    return dt
                }
                return dt
            })

            return {
                ...state,
                motorSkills: newArr
            }
        }
        case 'onChangeMoodForm4': {
            const newArr = state.mood.map((dt, i) => {
                if (i === action.payload.index) {
                    dt = action.payload.data
                    return dt
                }
                return dt
            })

            return {
                ...state,
                mood: newArr
            }
        }
        case 'onChangeOtherForm4': {
            const newArr = state.other.map((dt, i) => {
                if (i === action.payload.index) {
                    dt = action.payload.data
                    return dt
                }
                return dt
            })

            return {
                ...state,
                other: newArr
            }
        }
        case 'RESET_FORM_4':{
            state = Data
            return state
        }
        default: {
            return state
        }
    }
}


const Data = {
    sleepinAndEating: [
        { behaviour: 'Nightmares', isSelected: false },
        { behaviour: 'Trouble faling asleep', isSelected: false },
        { behaviour: 'Trouble staying asleep through night', isSelected: false },
        { behaviour: 'Needs to sleep with parent(s)', isSelected: false },
        { behaviour: 'Eats poorly', isSelected: false },
        { behaviour: 'Eats excessively', isSelected: false },
        { behaviour: 'Restricted rarge of foods', isSelected: false },
    ],
    behaviour: [
        { behaviour: 'Stubborn', isSelected: false },
        { behaviour: 'Frequent tantrums', isSelected: false },
        { behaviour: 'Physically aggressive', isSelected: false },
        { behaviour: 'Throws or destroys things', isSelected: false },
        { behaviour: 'Lies', isSelected: false },
        { behaviour: 'Steals', isSelected: false },
        { behaviour: 'Argumentative with adults', isSelected: false },
        { behaviour: 'Easily frustrated', isSelected: false },
        { behaviour: 'Run away', isSelected: false },
        { behaviour: 'Daredevil—type behaviour', isSelected: false },
        { behaviour: 'Throws or destroys things', isSelected: false },
        { behaviour: 'Needs a lot of supervision', isSelected: false },
        { behaviour: 'Does things without thinking', isSelected: false },
        { behaviour: 'Poor sense of danger', isSelected: false },
        { behaviour: 'Skips school', isSelected: false },
        { behaviour: 'Uses drugs', isSelected: false },
        { behaviour: 'Uses alcohol', isSelected: false },
        { behaviour: 'Sexually active', isSelected: false }
    ],
    socialDevelopment: [
        { behaviour: 'Poor eye contact', isSelected: false },
        { behaviour: 'Reduced range of facial expression', isSelected: false },
        { behaviour: 'Prefers to be alone', isSelected: false },
        { behaviour: 'Excessively shy', isSelected: false },
        { behaviour: 'More irterested in objects than in people', isSelected: false },
        { behaviour: 'Difﬁculty making friends', isSelected: false },
        { behaviour: "Doesn't appreciate humour", isSelected: false },
        { behaviour: 'Teased by other children', isSelected: false },
        { behaviour: 'Bullies other children', isSelected: false },
        { behaviour: 'Not sought out for friendship by peers', isSelected: false },
        { behaviour: "Difﬁculty seeing another person's point of view", isSelected: false },
        { behaviour: 'Doesn’t empathise with others', isSelected: false },
        { behaviour: "Overly trusting of others", isSelected: false }
    ],
    motorSkills: [
        { behaviour: 'Difﬁculty with shoelaces, buttons, zippers', isSelected: false },
        { behaviour: 'Poor pencil grasp', isSelected: false },
        { behaviour: 'Difﬁculties with utensils or scissors', isSelected: false },
        { behaviour: 'Seems clumsy compared to peers', isSelected: false },
        { behaviour: 'Lacks awareness of body in space', isSelected: false }
    ],
    mood: [
        { behaviour: 'Mood changes quickly', isSelected: false },
        { behaviour: 'Feels emotions intensely', isSelected: false },
        { behaviour: 'Gets upset easily by small details', isSelected: false },
        { behaviour: 'Seems sad a lot of the time', isSelected: false },
        { behaviour: 'Cries often', isSelected: false },
        { behaviour: 'Irritable, angry, or resentful', isSelected: false },
        { behaviour: "Excessively worried and anxious", isSelected: false },
        { behaviour: 'Overly preoccupied with details', isSelected: false },
        { behaviour: 'Does repetitive actions to reduce anxiety', isSelected: false },
        { behaviour: 'Engages in frequent reassurance seeking', isSelected: false },
        { behaviour: "Purposely harms or injures self", isSelected: false },
        { behaviour: 'Talks about killing self', isSelected: false }
    ],
    other: [
        { behaviour: 'Bladder control problems {not duirrg seizure)', isSelected: false },
        { behaviour: 'Poor bowel control (soils self)', isSelected: false },
        { behaviour: 'Very sensitive to noise', isSelected: false },
        { behaviour: 'Excessively bothered by tags on clotting, certain fabric or sock seams, textures, smells, sounds', isSelected: false }
    ],
}

export default Form4Reducer