import React, { useState } from 'react'
import GooglePlacesAutocomplete, {geocodeByPlaceId, getLatLng} from 'react-google-places-autocomplete';
import {GOOGLE_PLACES_API_KEY} from '../Config'
import './CSS/InputComp.css'

export default function PlacesInput({getData,containerStyle,...props}) {

    const [value,setValue] = useState('')
    const [focus,setFocus] = useState(false)

    const handleOnChange = async (val) =>{
        setValue(val)
        const result = await geocodeByPlaceId(val?.value?.place_id)
        const {lat, lng} = await getLatLng(result[0])
        getData({address:val?.label,latitude:lat,longitude:lng})
    }

    return (
        <fieldset className={props.isError ? "fld_set_err" : focus ? "fld_set_fcs" : "fld_set"} style={containerStyle}>
            <legend className={props.isError ? "legend2" : focus ? "legend1" : "legend"} style={props.labelStyle}>{props.label}</legend>
            <GooglePlacesAutocomplete 
                apiKey={GOOGLE_PLACES_API_KEY}
                selectProps={{
                    placeholder:'Enter here...',
                    onFocus: () => setFocus(true),
                    onBlur: () => setFocus(false),
                    styles: {
                        control: provided => ({
                            ...provided,
                            border: 0,
                            boxShadow: 'none',
                            marginTop:-2,
                            marginBottom:5,
                            fontSize:16,
                        }),
                        input: (provided) => ({
                            ...provided,
                            color: 'black',
                        }),
                        placeholder: (provided) => ({
                            ...provided,
                            color: 'black',
                        }),
                        dropdownIndicator: base => ({
                            ...base,
                            color: focus ? '#F99B9B' : "black"
                        })
                    },
                    value,
                    onChange: handleOnChange,
                }}
            />
        </fieldset>
    )
}
