import React,{useState,useEffect} from 'react'
import PhoneInput from 'react-phone-number-input'
import './CSS/PhoneNumberComp.css'

export default function PhoneNumberComp(props) {

    const [focus,setFocus] = useState(false)

    return (
        <fieldset className={props.isError ? "phn_fld_set_err" : focus ? "phn_fld_set_fcs" : "phn_fld_set"} style={props.containerStyle}>
            <legend className={props.isError ? "legend2" : focus ? "legend1" : "legend"} style={props.labelStyle}>{props.label}</legend>
            <PhoneInput
                onFocus={()=>setFocus(true)} 
                onBlur={()=>setFocus(false)} 
                international
                // placeholder="Enter phone number"
                value={props.value}
                onChange={(e)=>props.onChange(e)}
                className="number-fld"
                onInput={props.inputLength}
                disabled={props.disabled || false}
            />
        </fieldset>
    )
}
