import React, { useEffect, useState } from 'react'
import { Row, Col, Spinner } from 'react-bootstrap'
import TextAreaComp from '../../../Components/TextAreaComp'
import {
    onChangeChildFavouriteActivitiesForm6, onChangeBestWayToHelpChildForm6, onChangeAssetsOrStrengthsForm6,
    onChangeMostProudMomentForm6, onChangeOtherInformationForm6,onChangeImageForm6,onChangeDocumentsForm6,
    onChangeDeleteDocumentForm6,onChangeChildGenderForm6
} from '../../../Store/Actions/OnBoardingFormActions/Form6Actions'
import { useDispatch, useSelector } from 'react-redux'
import UploadImageComp from '../../../Components/UploadImageComp'
import UploadFileComp from '../../../Components/UploadFileComp'
import UploadFile2Comp from '../../../Components/UploadFileComp2'
import DownloadDocComp from '../../../Components/DownloadDocComp'
import {AiFillFileText,AiFillDelete,AiOutlineClose} from 'react-icons/ai'
import './CSS/PreCallQuestionForm.css'

function PreCallQuestionForm6(props) {

    const dispatch = useDispatch()

    // const [isLoading,setIsLaoding] = useState(props.isLoading)

    const childFavouriteActivities = useSelector(state => state.Form6Reducer.childFavouriteActivities)
    const bestWayToHelpChild = useSelector(state => state.Form6Reducer.bestWayToHelpChild)
    const assetsOrStrengths = useSelector(state => state.Form6Reducer.assetsOrStrengths)
    const mostProudMoment = useSelector(state => state.Form6Reducer.mostProudMoment)
    const otherInformation = useSelector(state => state.Form6Reducer.otherInformation)
    const childGender = useSelector(state => state.Form6Reducer.childGender)
    const imageUrl = useSelector(state => state.Form6Reducer.imageUrl)
    const documents = useSelector(state => state.Form6Reducer.documents)


    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])

    return (
        <div style={{ width: '100%' }} className="pre-call-form-container">
            <h3>OTHER INFORMATION</h3>

            <Row className="mx-0 p-0 mt-5">
                <Col className="pl-0">
                    <h5 className="mb-1" style={{ fontWeight: 600 }}>What are your child's favourite activities?</h5>
                    <TextAreaComp
                        value={childFavouriteActivities}
                        label="Type Here"
                        labelStyle={{ fontSize: 16, fontWeight: 600 }}
                        inputLength={(e) => e.target.value = (e.target.value).slice(0, 500)}
                        onChange={(e) => dispatch(onChangeChildFavouriteActivitiesForm6(e.target.value))}
                    />
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-5">
                <Col className="pl-0">
                    <h5 className="mb-1" style={{ fontWeight: 600 }}>What have you found to be the best way to help your child?</h5>
                    <TextAreaComp
                        value={bestWayToHelpChild}
                        label="Type Here"
                        labelStyle={{ fontSize: 16, fontWeight: 600 }}
                        inputLength={(e) => e.target.value = (e.target.value).slice(0, 500)}
                        onChange={(e) => dispatch(onChangeBestWayToHelpChildForm6(e.target.value))}
                    />
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-5">
                <Col className="pl-0">
                    <h5 className="mb-1" style={{ fontWeight: 600 }}>What are your child's assets or strengths?</h5>
                    <TextAreaComp
                        value={assetsOrStrengths}
                        label="Type Here"
                        labelStyle={{ fontSize: 16, fontWeight: 600 }}
                        inputLength={(e) => e.target.value = (e.target.value).slice(0, 500)}
                        onChange={(e) => dispatch(onChangeAssetsOrStrengthsForm6(e.target.value))}
                    />
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-5">
                <Col className="pl-0">
                    <h5 className="mb-1" style={{ fontWeight: 600 }}>What about your child makes you the most proud?</h5>
                    <TextAreaComp
                        value={mostProudMoment}
                        label="Type Here"
                        labelStyle={{ fontSize: 16, fontWeight: 600 }}
                        inputLength={(e) => e.target.value = (e.target.value).slice(0, 500)}
                        onChange={(e) => dispatch(onChangeMostProudMomentForm6(e.target.value))}
                    />
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-5">
                <Col className="pl-0">
                    <h5 className="mb-1" style={{ fontWeight: 600 }}>Is there any other information that you think that could help me in assessing your child?</h5>
                    <TextAreaComp
                        value={otherInformation}
                        label="Type Here"
                        labelStyle={{ fontSize: 16, fontWeight: 600 }}
                        inputLength={(e) => e.target.value = (e.target.value).slice(0, 500)}
                        onChange={(e) => dispatch(onChangeOtherInformationForm6(e.target.value))}
                    />
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-5">
                <Col className="pl-0">
                    <h5 className="mb-1" style={{ fontWeight: 600 }}>Child Gender</h5>
                    <div style={{display:'flex',alignItems:'center',marginTop:10}}>
                        <input 
                            type="radio" 
                            name="gender" 
                            value="Male"
                            checked={childGender === 'Male' && true}
                            onChange={(e)=>dispatch(onChangeChildGenderForm6(e.target.value))}
                        /><span style={{marginLeft:10,marginRight:10}}>Male</span>
                        <input 
                            type="radio" 
                            name="gender" 
                            value="Female" 
                            checked={childGender === 'Female' && true}
                            onChange={(e)=>dispatch(onChangeChildGenderForm6(e.target.value))}
                        /><span style={{marginLeft:10}}>Female</span>
                    </div>
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-5">
                <Col className="pl-0">
                    <h5 className="mb-3" style={{ fontWeight: 600 }}>Upload Child profile image</h5>
                    <UploadImageComp onTimeUpload={true} image={imageUrl} getUploadedImage={(e)=>dispatch(onChangeImageForm6(e))} />
                    {props.form6Image&&imageUrl ===''&&<p style={{color:'red',fontSize:12,margin:0}}>Please Upload Image</p>}
                </Col>
            </Row>

            <Row className="mx-0 p-0 mt-5">
                <Col className="pl-0">
                    <h5 className="mb-3" style={{ fontWeight: 600 }}>Download Third Party Doc</h5>
                    <DownloadDocComp />
                </Col>
                <Col className="pr-0">
                    <h5 className="mb-3" style={{ fontWeight: 600 }}>Upload Documents</h5>
                    {
                        documents&&
                        documents.length === 0 &&
                        <UploadFileComp
                            getUploadedFile={(e)=>dispatch(onChangeDocumentsForm6(e))}  
                        />
                    }
                    
                    {/* {props.form6Docs&&documents.length === 0&&<p style={{margin:0,fontSize:12,color:'red'}}>Third Party document is Required.</p>} */}
                    <div className="d-flex align-items-center mt-3">
                        {
                            documents&&
                            documents.map((dt,i)=>{
                                return (
                                    <div style={{width:90,height:90,borderRadius:10,fontSize:40,padding:2,marginRight:15,border:'solid 1px lightgray',position:'relative',display:'flex',justifyContent:'center',alignItems:'center'}} >
                                        <AiOutlineClose 
                                            id="doc-close-icon"
                                            color="#333333" 
                                            onClick={()=>dispatch(onChangeDeleteDocumentForm6(i))}
                                        />
                                        <AiFillFileText color="#F99B9B"  />
                                    </div>
                                )
                            })
                        }
                        {
                            documents&&
                            documents.length > 0 &&
                            <UploadFile2Comp
                                getUploadedFile={(e)=>dispatch(onChangeDocumentsForm6(e))}  
                            />
                        } 
                    </div>

                </Col>
            </Row>
            {
                props.isLoading ?
                    <div className="mt-5 d-flex justify-content-center">
                        <button className="pre-call-form-btn">
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> 
                            Loading...
                        </button>
                    </div>
                :

                    <div className="mt-5 d-flex justify-content-center">
                        <button className="pre-call-form-btn" onClick={() => props.onSubmitForm()}>Save</button>
                    </div>
            }
        </div>
    )
}

export default PreCallQuestionForm6;
