import React,{useEffect,useState} from 'react'
import {baseURL, Decrypt, headers} from '../../Config'
import {useSelector} from 'react-redux'
import Skeleton from 'react-loading-skeleton';
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import ClinicianDashboardNavbar from '../../Components/ClinicianDashboardNavbar'
import DashboardParentCard from '../../Components/DashboardParentCard'
import { toast } from 'react-toastify'
import './CSS/ParentProfiles.css'
import { Row,Col } from 'react-bootstrap'

export default function CiliniciansProfile() {

    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const [parents,setParents] = useState([])
    const [skeletons,setSkeletons] = useState([{},{},{},{},{},{},{},{},{},{}])
    const [isLoading,setIsLoading] = useState(true)
    const [userType,setUserType] = useState(null)

    const sidebarWidth = useSelector(data => data.SidebarReducer.width)

    useEffect(()=>{

        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })

        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            setUserType(Decrypt(dt.userType))

            const path = Decrypt(dt.userType) === 'BC' ? '/api/get-clinician-parents' :Decrypt(dt.userType) === 'BCBA' ? '/api/get-bcba-parents' : '/api/get-rbt-parents'

            fetch(baseURL+path ,{
                method:'Post',
                body:JSON.stringify({uid:dt.uid}),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    toast.error('error..')
                    setIsLoading(false)
                }
                else{
                    setParents(res2.message)
                    setIsLoading(false)
                }
            })
        }
    },[])

    return (
        <div className="parent-profiles-page-container">
            <ParentDashboardSideBar active="parents" user={userType&&userType === 'BC' ? "BC" :userType === 'RBT' ? 'RBT' : 'BCBA'} />
            <div style={{marginLeft:windowWidth > 1024 &&sidebarWidth}}>
                <ClinicianDashboardNavbar 
                    showAppointments={(userType&&userType === 'RBT' || userType === 'BCBA') ? false : true} active="parents" 
                    user={userType&&userType === 'BC' ? "BC" :userType === 'RBT' ? 'RBT' : 'BCBA'} 
                />
                <div className="parent-profiles-page-body">
                    <h2 style={{fontFamily:'CocoSharp bold'}}>Parents Profile</h2>
                    <Row className="mx-0 mt-4">
                        <Col sm={12} className="d-flex just align-items-center flex-wrap px-0">
                            {
                                isLoading ?
                                    skeletons.map(dat=>{
                                        return <Skeleton width={210} height={220} style={{marginLeft:0,marginRight:20,marginTop:20,borderRadius:15}}/>
                                    })
                                :
                                    parents.length > 0 ?
                                        parents.map((dt,i)=>{
                                            return  <React.Fragment key={i}>
                                                        <DashboardParentCard
                                                            name={Decrypt(dt.first_name) + ' ' + Decrypt(dt.last_name)}
                                                            image={Decrypt(dt.image)}
                                                            otherInfo={Decrypt(dt.short_des)}
                                                            Id={dt.uid}
                                                        />
                                                    </React.Fragment>
                                        })
                                    : 
                                        <div>
                                            <h4 style={{color:'lightgray',margin:0}}>No Data</h4>
                                        </div>
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}
