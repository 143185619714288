import React from "react";
import Hannah from "./Hannah";
import Hero from "./Hero";
import Linda from "./Linda";
import Monika from "./Monika";
import Paul from "./Paul";
import Natalia from "./Natalia";
import Vanessa from "./Vanessa";
import Christie from "./Christie";
import Michelle from "./Michelle";
import Joanah from "./Joanah";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Informed from "../../Components/Informed";
import Carmen from "./Carmen";
import Sumaia from "./Sumaia";
import Merim from "./Merim";

function Team() {
  return (
    <div>
      <Header />
      <Hero />
      <Linda />
      <Monika />
      <Paul />
      <Carmen />
      <Sumaia />
      {/* <Joanah /> */}
      <Natalia />
      <Christie />
      <Merim />
      {/* <Vanessa /> */}
      {/* <Hannah /> */}
      {/* <Michelle /> */}
      <Informed />
      <Footer />
    </div>
  );
}

export default Team;
