import React,{useEffect,useState,useRef} from 'react'
import {baseURL, Decrypt, Encrypt, headers} from '../../Config'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import ParentDashboardNavbar from '../../Components/ParentDashboardNavbar'
import ClinicianDashboardNavbar from '../../Components/ClinicianDashboardNavbar'
import ChildDetailModal from '../../Components/ChildDetailModal'
import TextAreaComp from '../../Components/TextAreaComp'
import ReportsCard from '../../Components/ReportsCard'
import { toast } from 'react-toastify'
import {GoPlus} from 'react-icons/go'
import { Carousel } from 'antd'
import Skeleton from 'react-loading-skeleton';
import { saveAs } from "file-saver";
import './CSS/ChildProfileDetail.css'
import { Row,Spinner,Modal } from 'react-bootstrap'

export default function ParentChildProfileDetail(props) {

    const history = useHistory()
    const sliderRef = useRef(null)

    const sidebarWidth = useSelector(data => data.SidebarReducer.width)
    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const [isSelected,setIsSelected] = useState(1)
    const [childDetail,setChildDetail] = useState(null)
    const [description,setDescription] = useState('')
    const [isLoaded,setIsLoaded] = useState(false)
    const [isLoading,setIsLoading] = useState(false)
    const [show,setShow] = useState(false)
    const [reports,setReports] = useState([])
    const [isLoadedReports,setIsLoadedReports] = useState(false)
    const [showDetailModal,setShowDetailModal] = useState(false)
    const [userType,setUserType] = useState(null)
    const [assesmentData,setAssessmentData] = useState(null)

    const first_name = useSelector(data => data.ParentReducer.parentData.first_name)
    const last_name = useSelector(data => data.ParentReducer.parentData.last_name)

    useEffect(()=>{

        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })

        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            setUserType(Decrypt(dt.userType))
            fetch(baseURL+ '/api/get-child-by-Id',{
                method:'Post',
                body:JSON.stringify({uid:props.match.params.id}),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    toast.error('error..')
                    setIsLoaded(true)
                }
                else{
                    setChildDetail(res2.message)
                    setIsLoaded(true)
                }
            })
        }
    },[])

    useEffect(()=>{
        handleGetChildReports()
    },[])

    const ConvertDOB = (date) =>{
        const dob = new Date(date)
        const newDate = new Date()
        const diffTime = Math.abs(newDate - dob);
        const diffDays = Math.ceil(diffTime / (1000*60*60*24)); 
        if(diffDays < 30){
            if( diffDays === 1 ){
                return diffDays + ' ' + 'Day'
            }
            else{
                return diffDays + ' ' + 'Days'
            }
        }
        else if(diffDays < 365){
            if( Math.round(diffDays/30) === 1 ){
                return Math.round(diffDays/30) + ' ' + 'Month'
            }
            else{
                return Math.round(diffDays/30) + ' ' + 'Months'
            }
        }
        else{
            if(Math.round(diffDays/365) === 1){
                return Math.round(diffDays/365) + ' ' + 'Year'
            }
            else{
                return Math.round(diffDays/365) + ' ' + 'Years'
            }
        }
    }

    const handleSave = () =>{
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        setIsLoading(true)
        fetch(baseURL+ '/api/edit-child-description',{
            method:'Put',
            body:JSON.stringify({uid:props.match.params.id,description:Encrypt(description)}),
            headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
        })
        .then(res=>res.json())
        .then(res2=>{
            if(res2.code === 'Error'){
                toast.error('error..')
                setIsLoading(false)
            }
            else{
                childDetail.other_information = res2.message
                setIsLoading(false)
                setShow(false)
            }
        })
    }

    const handleGetChildReports = () =>{
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        setIsSelected(1)
        fetch(baseURL+ '/api/getChildReports?Id=' + props.match.params.id,{
            headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
        })
        .then(res=>res.json())
        .then(res2=>{
            if(res2.code === 'Error'){
                toast.error('error..')
                setIsLoadedReports(true)
            }
            else{
                setIsLoadedReports(true)
                setReports(res2.message)
            }
        })
    }

    const handleGetAssessmentDetail = () =>{
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        setIsSelected(2)
        fetch(baseURL+ '/api/getChildAssessmentDetail?Id=' + props.match.params.id,{
            headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
        })
        .then(res=>res.json())
        .then(res2=>{
            if(res2.code === 'Error'){
                toast.error('error..')
            }
            else{
                setAssessmentData(res2.message)
            }
        })
    }

    const handleDownloadRatingForm = () =>{
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        fetch(baseURL+ '/api/downloadChildRatingFormDetail?Id=' + props.match.params.id,{
            headers:{'authorization':'Bearer ' + dt.accessToken}
        })
        .then(res=>res.blob())
        .then(res2=>{
            saveAs(res2,'Rating Form.docx');
        })
    }

    return (
        <div className="child-profile-detail-container">
            <ParentDashboardSideBar active="child" user={userType&&userType === 'BC' ? "BC" :userType&&userType === 'BCBA' ? 'BCBA' :userType&&userType === 'RBT' ? 'RBT' : 'Parent'} />
            <div style={{marginLeft:windowWidth > 1024 &&sidebarWidth}}>
                {
                    userType&&userType === 'Parent' ?
                        <ParentDashboardNavbar active="child"  user='Parent'/>
                    :
                        <ClinicianDashboardNavbar 
                            showAppointments={(userType&&userType === 'RBT' || userType === 'BCBA') ? false : true} 
                            active="child" 
                            user={userType&&userType === 'BC' ? "BC" :userType&&userType === 'BCBA' ? 'BCBA' : 'RBT'}
                        />
                }
                <div className="child-profile-detail-body">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <h2 style={{fontFamily:'CocoSharp bold'}}>Child Profile Detail</h2>
                        {
                            // userType&&userType === 'BC' &&
                            // <button className="child-profile-create-task-btn">Create Task <GoPlus/></button>
                        }
                    </div>
                    {
                        isLoaded ?
                            <>
                                <Row className="mx-0 mt-5 child-profile-detail-row-1">
                                    <div className="child-profile-detail-row-1-col-1" style={{backgroundImage:`url(${childDetail&&childDetail.image})`,backgroundPosition:'center',backgroundSize:'cover'}}>
                                    </div>
                                    <div className="child-profile-detail-row-1-col-2">
                                        <div>
                                            <h2 style={{marginBottom:0}}>{childDetail&&Decrypt(childDetail.child_name)}</h2>
                                            <p style={{marginTop:0,lineHeight:1,marginBottom:0,fontFamily:'CocoSharp bold',color:'#096E82'}}>Age: {childDetail&&ConvertDOB(Decrypt(childDetail.birth_date))}</p>
                                            <p style={{marginTop:0,marginBottom:0,fontFamily:'CocoSharp bold',color:'#096E82'}}>Gender: {childDetail&&Decrypt(childDetail.gender)}</p>
                                            {/* {childDetail&&Decrypt(childDetail.mchat_score)&&<p style={{marginTop:0,marginBottom:15,lineHeight:1,fontFamily:'CocoSharp bold',color:'#096E82'}}>M-Chat Score: {Decrypt(childDetail.mchat_score)}</p>} */}
                                            <h4 style={{fontFamily:'CocoSharp bold',color:'#096E82',fontSize:22}}>Parent: {Decrypt(childDetail?.parentName)}</h4>
                                            <p style={{marginTop:30,lineHeight:1.4,fontFamily:'CocoSharp bold',width:windowWidth >=768 ? '70ch' : '100%',fontSize:17,wordWrap:'break-word'}}>{childDetail&&Decrypt(childDetail.other_information) ? Decrypt(childDetail.other_information) : 'No Description'}</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            {
                                                userType&&userType === "Parent" &&
                                                <button className="child-profile-edit-btn" onClick={()=>(setDescription(childDetail&&Decrypt(childDetail.other_information)),setShow(true))}>Edit Description</button>
                                            }
                                            {
                                            //    childDetail&&childDetail?.childStatus >=4 && 
                                               userType&&userType !== "Parent" &&
                                               <button className="child-profile-view-btn" onClick={handleDownloadRatingForm}>Rating Form</button>
                                            }
                                            <button className="child-profile-view-btn" onClick={()=>setShowDetailModal(true)}>View Detail</button>
                                        </div>
                                    </div>
                                </Row>

                                <Row className="mx-0 mt-5 child-profile-detail-row-2">
                                    <div className="child-profile-detail-row-2-tab-bar">
                                        <button className="child-profile-detail-row-2-tab-bar-btn" onClick={handleGetChildReports} style={isSelected === 1 ? {borderBottom:'solid 2px #F99B9B',color:'#F99B9B'} : {borderBottom:'solid 1px #AEB0D0',color:'#ADB5CC'}}>
                                            Documents
                                        </button>
                                        {
                                            userType&&userType !== "Parent" &&
                                            <button className="child-profile-detail-row-2-tab-bar-btn" onClick={handleGetAssessmentDetail} style={isSelected === 2 ? {borderBottom:'solid 2px #F99B9B',color:'#F99B9B'} : {borderBottom:'solid 1px #AEB0D0',color:'#ADB5CC'}}>
                                                Assessment Detail
                                            </button>
                                        }
                                        <hr style={{width:'100%',margin:0,borderTop:'solid 1px #AEB0D0'}} />
                                    </div>
                                    <div className="w-100">
                                    {
                                        isSelected === 1 ?
                                            <div>
                                                {
                                                    isLoadedReports ?
                                                        reports&&reports.length > 0 ?
                                                            reports.map((dt,i)=>{
                                                                return(
                                                                    <React.Fragment key={i}>
                                                                        <ReportsCard 
                                                                            childName={Decrypt(dt.child_name)}
                                                                            parentName={Decrypt(dt.parent_name)}
                                                                            report={dt.report}
                                                                        />
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                        : null
                                                    : 
                                                        [{},{},{},{},{},{}].map(dt=>{
                                                            return <Skeleton width={220} height={215} style={{marginTop:25,borderRadius:15,marginRight:25}} />
                                                        })
                                                }
                                            </div>
                                        :isSelected === 2 ?
                                            assesmentData && assesmentData?.telepeds ?
                                                <div className="row m-0 mt-4">
                                                    <div onClick={()=>sliderRef?.current?.prev()} className="col-md-1 m-0 d-flex align-items-center">
                                                        <div id='side-arrows'>
                                                            <p style={{color:'white',margin:0}}>{'<'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-10 m-0">
                                                        <Carousel 
                                                            dots={false}
                                                            ref={sliderRef}
                                                            fade
                                                        >
                                                            <div>
                                                                <h3>Child-Direction Play 2 min</h3>
                                                                <p>
                                                                    “Find 3-5 toys and put them on the floor/table near .” [Toys could include: pop-up toy with buttons, musical toys, shape sorter, puzzle, blocks] 
                                                                    “We are going to let him/her play with these toys for a couple minutes. 
                                                                    You can just sit back and let him/her play and respond normally if he/she tries to get your attention.
                                                                    We will do our best to not tell him/her directly what to do.”
                                                                </p>

                                                                <p style={{border:'solid 1.5px #096E82',padding:10,borderRadius:8}}>
                                                                    {assesmentData?.telepeds?.direction_play}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <h3>Calling Name #1</h3>
                                                                <p>
                                                                    “While is not looking at you, please call his/her name one time.”
                                                                    [Does child look to caregiver with eye contact?]
                                                                </p>

                                                                <p style={{border:'solid 1.5px #096E82',padding:10,borderRadius:8}}>
                                                                    {assesmentData?.telepeds?.calling_name1}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <h3>Directing Child’s Attention #1</h3>
                                                                <p>
                                                                    “Find something on the wall or an object across the room that you 
                                                                    could point to. Point to that object and say, look!’ one time.” 
                                                                    [Does child follow care giver’s point and gaze to look at object?]
                                                                </p>

                                                                <p style={{border:'solid 1.5px #096E82',padding:10,borderRadius:8}}>
                                                                    {assesmentData?.telepeds?.child_attention1}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <h3>Joint Play (2 minutes)</h3>
                                                                <p>
                                                                    “Now you can join and play in whatever way feels natural. 
                                                                    You can include new toys if you’d like.”
                                                                </p>

                                                                <p style={{border:'solid 1.5px #096E82',padding:10,borderRadius:8}}>
                                                                    {assesmentData?.telepeds?.joint_play}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <h3>Calling Name #2</h3>
                                                                <p>
                                                                    “While is not looking at you, please call his/her name one time.” 
                                                                    [Does your child look to caregiver with eye contact?]
                                                                </p>

                                                                <p style={{border:'solid 1.5px #096E82',padding:10,borderRadius:8}}>
                                                                    {assesmentData?.telepeds?.calling_name2}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <h3>Directing Child’s Attention #2</h3>
                                                                <p>
                                                                    “Find something on the wall or an object across the room that you could 
                                                                    point to. Point to that object and say, look!’ one time.” 
                                                                    [Does child follow care giver’s point and gaze to look at object?]
                                                                </p>

                                                                <p style={{border:'solid 1.5px #096E82',padding:10,borderRadius:8}}>
                                                                    {assesmentData?.telepeds?.child_attention2}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <h3>Family Play Routine</h3>
                                                                <p>
                                                                    “Is there a game that you like to play with ‘  ‘ like peek-a-boo OR I’m gonna get you? 
                                                                    Go ahead and play that game with ‘  ‘ for a couple of minutes.” 
                                                                    [Does child respond to caregiver’s bid to play? Shared enjoyment? Requesting? Initiating 
                                                                    routine?
                                                                </p>

                                                                <p style={{border:'solid 1.5px #096E82',padding:10,borderRadius:8}}>
                                                                    {assesmentData?.telepeds?.family_play}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <h3>Ready, Set, Go</h3>
                                                                <p>
                                                                    “Find something on the wall or an object across the room that you could point to. 
                                                                    Point to that object and say, ‘          ‘ look!’ one time.” 
                                                                    [Does child follow care giver’s point and gaze to look at object?]
                                                                </p>

                                                                <p style={{border:'solid 1.5px #096E82',padding:10,borderRadius:8}}>
                                                                    {assesmentData?.telepeds?.ready_go}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <h3>Requesting Snack</h3>
                                                                <p>
                                                                    “Find a Tupperware or other similar container, fill it with a snack, and give it to”. OR Alternatives:”Fill a container with small objects (e.g., stickers, blocks, etc.) and give it to.”
                                                                    “Hand container to and say, “You can have some.’ Wait and see how reacts.”
                                                                    “Give one piece of snack/one sticker, etc. Close container.” [Please administer a total three times]
                                                                    [Did the child request? Did the child coordinate eye contact with gestures/vocalizations? Hand as tool?]
                                                                </p>

                                                                <p style={{border:'solid 1.5px #096E82',padding:10,borderRadius:8}}>
                                                                    {assesmentData?.telepeds?.requesting_snack}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <h3>Indpendent Play + Ignoring (2 minutes)</h3>
                                                                <p>
                                                                    “Get out some toys and let play for a couple minutes on his/her own. 
                                                                    I want you to ignore for a couple of minutes, but if he/she approaches 
                                                                    you just respond as you normally would. I will let you know when we are done.” 
                                                                    [Initiates an interaction?]
                                                                </p>

                                                                <p style={{border:'solid 1.5px #096E82',padding:10,borderRadius:8}}>
                                                                    {assesmentData?.telepeds?.ind_play}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <h3>Closing</h3>
                                                                <p>
                                                                    “Was this a good snapshot of your child’s behavior?
                                                                </p>

                                                                <p style={{border:'solid 1.5px #096E82',padding:10,borderRadius:8}}>
                                                                    {assesmentData?.telepeds?.closing}
                                                                </p>
                                                            </div>
                                                        </Carousel>
                                                    </div>
                                                    <div className="col-md-1 m-0 d-flex align-items-center justify-content-end">
                                                        <div id='side-arrows' onClick={()=>sliderRef?.current?.next()}>
                                                            <p style={{color:'white',margin:0}}>{'>'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            :assesmentData && assesmentData?.observation ?
                                                <div className="row m-0 mt-4">
                                                    <h3>Consultation Detail</h3>
                                                    {
                                                        assesmentData && assesmentData?.observation ?
                                                            <p style={{width:'100%',border:'solid 1.5px #096E82',padding:10,borderRadius:8}}>
                                                                {assesmentData?.observation?.notes}
                                                            </p>
                                                        :null
                                                    }
                                                </div>
                                            : null 
                                        : null                  
                                    }
                                    </div>
                                </Row>
                            </>
                        :
                            <Row className="mx-0 mt-5 child-profile-detail-row-1">
                                <div className="child-profile-detail-row-1-col-1">
                                    <Skeleton height="100%" />
                                </div>
                                <div className="child-profile-detail-row-1-col-2">
                                    <div>
                                        <Skeleton height={20} width="100%" />
                                        <Skeleton height={20} width="100%" />
                                        <Skeleton height={20} width="100%" />
                                        <Skeleton height={20} width="100%" />
                                        <Skeleton height={140} width="100%" />
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <Skeleton height={45} width={150} style={{marginRight:10}} />
                                        <Skeleton height={45} width={150} />
                                    </div>
                                </div>
                            </Row>
                    }
                </div>
                {/* Edit Description Modal */}
                <Modal className="child-detail-edit-modal" show={show} centered onHide={()=>setShow(false)} animation={false}>
                    <Modal.Header style={{borderBottom:'none'}} closeButton>
                        <p style={{fontFamily:'CocoSharp regular',margin:0,fontSize:28,color:'#096E82'}}>Edit Description</p>
                    </Modal.Header>
                    <Modal.Body style={{position:'relative',zIndex:1,borderRadius:20}} className="py-2 pb-4">
                        
                        <TextAreaComp
                            value={description}
                            label="Description"
                            containerStyle={{marginTop:windowWidth < 991&&30}}
                            labelStyle={{ fontSize: 16, fontWeight: 600 }}
                            inputLength={(e) => e.target.value = (e.target.value).slice(0, 500)}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        {
                            isLoading ? 
                                <button id="child-profile-detail-modal-btn" style={{marginTop:30}}>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    {' '}
                                    Loading....
                                </button>
                            :
                                <button id="child-profile-detail-modal-btn" onClick={handleSave}  style={{marginTop:30}}>Save</button>

                        }
                    </Modal.Body>
                </Modal>
            </div>
            <ChildDetailModal 
                childId = {props.match.params.id} 
                childDetail={childDetail} 
                showDetailModal={showDetailModal} 
                hideShowDetailModal={()=>setShowDetailModal(false)} 
            />
        </div>
    )
}
