import React from "react";
import img from "../../assets/team/team10.png";
function Carmen() {
    return (
        <div className="bg-[#FFEEEF] w-full flex justify-center items-center py-11">
            <div className="max-w-[1440px] flex justify-between flex-col-reverse lg:flex-row items-center px-10 w-full gap-10">
                <img src={img} alt="" />
                <div className="flex justify-center flex-col items-start gap-1">
                    <h1 className="font-bold text-lightGreen-500 text-3xl md:text-5xl">
                        Carmen Fong
                    </h1>
                    <h2 className="font-bold text-lightGreen-500 text-xl md:text-2xl">
                        M.Ed. ABA, BCBA, LBA (Washington, USA)
                    </h2>
                    <h4 className="font-bold text-lightGreen-500 text-md md:text-xl">
                        Behavior Analyst - Clinical Supervisor
                    </h4>
                    {/*
          <p className="font-normal text-lightGreen-500 text-lg lg:max-w-[680px]">
            Dr. Suchowierska-Stephany earned her Ph.D. in{" "}
            <span className="font-mono">2003</span> from the University of
            Kansas.
          </p> */}
                    <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
                        Carmen has been a practitioner of Precision Teaching and Applied Behavior Analysis since <span className="font-mono">2012</span>.
                        She has over <span className="font-mono">10</span> years of experience in providing the principles of Behavior Analysis,
                        Precision Teaching, and Direct Instruction to solve educational and Behavioral challenges in home-based intervention and school classrooms.
                        Carmen’s primary expertise lies in fluency-based instruction,
                        precise Behavioral measurement, and instructional design.

                    </p>
                    <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
                        Carmen holds a Masters of Education in Autism and ABA from Endicott College Beverly, MA, USA.
                        She is also a Board Certified Behavior Analyst (BCBA).
                    </p>
                    <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
                        She also maintains memberships with the Association of Professional Behavior Analysts
                        and the Standard Celeration Society. She’s also a Category A Behavior Consultant on the Registry of Autism Service Providers (RASP) in British Columbia, Canada.
                    </p>
                    <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
                        When she’s not working, Carmen enjoys challenging herself at the gym,
                        experimenting in the kitchen, and learning modern calligraphy.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Carmen;
