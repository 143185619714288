// import React from "react";
// import img from "../../assets/team/Merim.png";

// function Merim() {
//     return (
//         <div className="bg-[#E8FCFF] w-full flex justify-center items-center py-11">
//             <div className="max-w-[1440px] flex justify-between flex-col lg:flex-row items-center px-10 w-full gap-10">
//                 <div className="flex justify-center flex-col items-start gap-1">
//                     <h1 className="font-bold text-lightGreen-500 text-3xl md:text-5xl">
//                         Merim Ussenova
//                     </h1>
//                     <h2 className="font-bold text-lightGreen-500 text-xl md:text-2xl">
//                         MSc. Clinical Psychology
//                     </h2>
//                     <h4 className="font-bold text-lightGreen-500 text-md md:text-xl">
//                         Behavior Consultant
//                     </h4>
//                     <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
//                         Merim is a highly skilled professional with a strong background in Clinical Psychology and Applied Behavior Analysis (ABA).
//                         Holding a Masters degree in Clinical Psychology, Merim has developed a deep understanding of the unique needs of children with autism.
//                     </p>
//                     <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
//                         With a passion for making a positive impact in the lives of these children, Merim utilizes her expertise in ABA to design and implement individualized behavior intervention plans.
//                         Through careful assessment, data analysis, and evidence-based strategies,
//                         Merim helps children with autism learn new skills, overcome challenges, and reach their full potential.
//                     </p>
//                     <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
//                         Merim’s commitment to the field of autism treatment extends beyond the therapy room. She collaborates closely with families, providing support, guidance, and resources to help them navigate the unique journey of raising a child with autism.
//                         Merim believes in fostering strong partnerships with families to ensure a holistic approach to therapy and to create a supportive network for ongoing progress.
//                     </p>
//                     <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
//                         With her combination of clinical psychology knowledge and expertise in ABA, Merim brings a comprehensive and multidisciplinary approach to her work as a Behavior Consultant.
//                         Her compassionate nature, dedication, and ability to connect with children and their families make her a valued member of the autism services community.
//                     </p>
//                     <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
//                         Merim’s commitment to ongoing professional development and staying abreast of the latest research and best practices in the field reflects her dedication to providing the highest quality care to the children she serves.
//                         She is deeply passionate about making a difference in the lives of children with autism and strives to create a positive and nurturing environment where they can thrive.
//                     </p>
//                 </div>
//                 <img src={img} alt="" />
//             </div>
//         </div>
//     );
// }

// export default Merim;


import React from "react";
import img from "../../assets/team/Merim.png";
function Merim() {
  return (
    <div className="bg-[#FFEEEF] w-full flex justify-center items-center py-11">
      <div className="max-w-[1440px] flex justify-between flex-col-reverse lg:flex-row items-center px-10 w-full gap-10">
        <img src={img} alt="" />
        <div className="flex justify-center flex-col items-start gap-1">
          <h1 className="font-bold text-lightGreen-500 text-3xl md:text-5xl">
          Merim Ussenova
          </h1>
          <h2 className="font-bold text-lightGreen-500 text-xl md:text-2xl">
          MSc. Clinical Psychology
          </h2>
          <h4 className="font-bold text-lightGreen-500 text-md md:text-xl">
          Behavior Consultant
          </h4>
          <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
          Merim is a highly skilled professional with a strong background in Clinical Psychology and Applied Behavior Analysis (ABA).
          Holding a Masters degree in Clinical Psychology, Merim has developed a deep understanding of the unique needs of children with autism.
          </p>
          <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
          With a passion for making a positive impact in the lives of these children, Merim utilizes her expertise in ABA to design and implement individualized behavior intervention plans.
                        Through careful assessment, data analysis, and evidence-based strategies,
                         Merim helps children with autism learn new skills, overcome challenges, and reach their full potential.
          </p>
          <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
          Merim’s commitment to the field of autism treatment extends beyond the therapy room. She collaborates closely with families, providing support, guidance, and resources to help them navigate the unique journey of raising a child with autism.
         Merim believes in fostering strong partnerships with families to ensure a holistic approach to therapy and to create a supportive network for ongoing progress.
          </p>
          <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
          Merim’s commitment to ongoing professional development and staying abreast of the latest research and best practices in the field reflects her dedication to providing the highest quality care to the children she serves.
                    She is deeply passionate about making a difference in the lives of children with autism and strives to create a positive and nurturing environment where they can thrive.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Merim;
