// import ReactDOM from 'react-dom'
import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../Components/Header'
import Informed from '../Components/Informed'
import Footer from '../Components/Footer'
import ellipseImg from '../images/circleImg.svg'
import Skeleton from 'react-loading-skeleton';
import './CSS/TermsAndConditions.css'
import { baseURL } from '../Config';

export default function TermsAndConditions() {

    const [content,setContent] = useState('')
    const [isLoaded,setIsLoaded] = useState(false)
    const ifrmeRef = useRef('')

    const ref = React.useRef();
    let interval = useRef()
    const [height, setHeight] = React.useState("0px");

    useEffect(()=>{
        fetch(baseURL+'/api/getTermsAndServices')
        .then(res=>res.json())
        .then(res2=>{
            if(res2.code === 'Success'){
                setIsLoaded(true)
                setContent(res2?.message[0]?.description)
            }
            else {
                setIsLoaded(true)
            }
        })
    },[])

     

    



    return (
        <div>
            <div className="container-fluid">
                <Navbar />
            </div>
            <div className="tc-inner-container sections-horizontal-padding">
                <h1 className="tc-heading">Terms And Conditions</h1>
                <hr className="tc-divider" />
                <div className='tc-content'>
                {/* <iframe style={{ height: ifrmeRef.current.body.scrollHeight+'px', border: "none"}}  className='w-[100%] h-content' src="https://app.termly.io/document/privacy-policy/bf2bd49e-3048-4bf2-b177-2ab6f2ba7278">
                </iframe> */}

                {/* <FrameWrapper /> */}

                {/* <iframe 
                style={{maxWidth:640, width:'100%', height:this.state.iFrameHeight, overflow:'visible'}}
                onLoad={() => {
                    const obj = ReactDOM.findDOMNode(this);
                    this.setState({
                        "iFrameHeight":  obj.contentWindow.document.body.scrollHeight + 'px'
                    });
                }} 
                ref="iframe" 
                src="https://app.termly.io/document/privacy-policy/bf2bd49e-3048-4bf2-b177-2ab6f2ba7278" 
                width="100%" 
                height={this.state.iFrameHeight} 
                scrolling="no" 
                frameBorder="0"
            /> */}

                {
                    isLoaded ?
                        content != '' ?
                            <div dangerouslySetInnerHTML={{__html:content}}></div>
                        : null
                    :
                        <div>
                            <div>
                                <Skeleton width='100%' height={15} />
                                <Skeleton width='100%' height={15} />
                                <Skeleton width='100%' height={15} />
                                <Skeleton width='50%' height={15} />
                            </div>
                            <div className='mt-4'>
                                <Skeleton width='100%' height={15} />
                                <Skeleton width='100%' height={15} />
                                <Skeleton width='20%' height={15} />
                            </div>
                            <div className='mt-4'>
                                <Skeleton width='100%' height={15} />
                                <Skeleton width='100%' height={15} />
                                <Skeleton width='100%' height={15} />
                                <Skeleton width='100%' height={15} />
                                <Skeleton width='100%' height={15} />
                                <Skeleton width='70%' height={15} />
                            </div>
                            <div className='mt-4'>
                                <Skeleton width='100%' height={15} />
                                <Skeleton width='100%' height={15} />
                                <Skeleton width='100%' height={15} />
                                <Skeleton width='40%' height={15} />
                            </div>
                        </div>
                }
                </div>
                <img src={ellipseImg} className="ellipse-bg" />
            </div>
            {/* <Informed /> */}
            <Footer />
        </div>
    )
}
