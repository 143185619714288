import React, {useEffect,useState} from 'react';
import {Spinner} from 'react-bootstrap'
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux'
import insurancePay from '../../../images/insurance.png'
import selfPay from '../../../images/selfPayment.png'
import ParentDashboardSideBar from '../../../Components/ParentDashboardSideBar'
import ParentDashboardNavbar from '../../../Components/ParentDashboardNavbar'
import './CSS/SelectPaymentType.css';
import { baseURL, headers, Encrypt } from '../../../Config';
import { toast } from 'react-toastify';

export default function SelectPaymentType() { 

    const [type,setType] = useState(0)
    const [isLoading,setIsLoading] = useState(false)
    const history = useHistory()

    const [windowWidth,setWindowWidth] = useState(window.innerWidth)


    const sidebarWidth = useSelector(data => data.SidebarReducer.width)

    useEffect(()=>{
        window.scrollTo(0, 0)

        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })
    },[])

    const handleContinue = () =>{

        const path = type === 0 ? '/api/payWithCardAndCreateChild' : '/api/testPayWithInsuranceAndCreateChild'

        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        // const diagnosis_type = JSON.parse(localStorage.getItem('diagnosisType'))

        if(dt){

            const obj = {
                diagnosis_type:Encrypt('Assessment'),
                mchat_score:0,
                Id:dt.uid
            }

            setIsLoading(true)
            fetch(baseURL + path ,{
                method:'Post',
                body:JSON.stringify(obj),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Success'){
                    setIsLoading(false)
                    localStorage.removeItem('diagnosisType')
                    window.localStorage.setItem('bx_user_token',JSON.stringify({...dt,childId:res2.message.childId,childStatus:Encrypt(res2.message.childStatus)}))
                    history.push('/pre-call-questionnaire')
                }
                else{
                    toast.error(res2.message,{toastId:'self-pay-err-id1',autoClose:3000})
                    setIsLoading(false)
                }
            })
        }
    }

    return (

        <div className="parent-dashboard-Payment-type-Page-Container">
            <ParentDashboardSideBar active="child" />
            <div style={{marginLeft:windowWidth > 1024 && sidebarWidth}}>
                <ParentDashboardNavbar active="child" />
                <div className="parent-dashboard-Payment-type-Page-Body">
                    <h1 style={{fontFamily:'CocoSharp bold'}}>Select Payment Type</h1>
                    <hr /> 
                    <div className="parent-dashboard-Payment-type-select-row">
                        <div onClick={()=>setType(0)} className={type === 0 ? "parent-dashboard-Payment-type-card active" : "parent-dashboard-Payment-type-card"}>
                            <img src={selfPay}/>
                            <h3>Self Pay</h3>
                        </div>
                        <div onClick={()=>setType(1)} className={type === 1 ? "parent-dashboard-Payment-type-card active" : "parent-dashboard-Payment-type-card"}>
                            <img src={insurancePay}/>
                            <h3>Insurance Pay</h3>
                        </div>
                    </div>
                    {
                        isLoading ?
                            <button className="parent-dashboard-Payment-type-cont-btn">
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                {' '}
                                Loading....
                            </button>
                        :
                            <button onClick={handleContinue} className="parent-dashboard-Payment-type-cont-btn">Continue</button>
                    }
                </div>
            </div>
        </div>
      
    );
  }
  

  