import {baseURL,headers} from '../../Config'
import {toast} from 'react-toastify'

export const GetParentAppointments = () =>{
    const dt = JSON.parse(localStorage.getItem('bx_user_token'))
    return(disptach)=>{
        fetch(baseURL + '/api/get-parent-appointments',{
            method:'Post',
            body:JSON.stringify({Id:dt.uid}),
            headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
        })
        .then(res=>res.json())
        .then(res2=>{
            console.log(res2)
            if(res2.code === 'Error'){
                toast.error('Error',{toastId:'parent-appointment-toast-id1'})
                disptach({type:'GetAppointments',payload:[]})
            }
            else{
                disptach({type:'GetAppointments',payload:res2.message})
            }
        })
    }
}


export const GetClinicianAppointments = () =>{
    const dt = JSON.parse(localStorage.getItem('bx_user_token'))
    return(disptach)=>{
        fetch(baseURL + '/api/get-bc-appointments',{
            method:'Post',
            body:JSON.stringify({Id:dt.uid}),
            headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
        })
        .then(res=>res.json())
        .then(res2=>{
            if(res2.code === 'Error'){
                toast.error('Error',{toastId:'parent-appointment-toast-id1'})
                disptach({type:'GetAppointments',payload:[]})
            }
            else{
                disptach({type:'GetAppointments',payload:res2.message})
            }
        })
    }
}