import React,{useEffect,useState,useRef} from 'react'
import { Button,Modal, Row,Col,Dropdown, ButtonGroup, ProgressBar } from 'react-bootstrap'
import AgoraRTC from 'agora-rtc-sdk-ng';
import { toast } from 'react-toastify';
// import './CSS/TestDeviceModal.css'

export default function TestDeviceModal(props) {

    const interval = useRef()

    const [audioDevices,setAudioDevice] = useState([])
    const [videoDevices,setVideoDevice] = useState([])
    const [selectedAudioDevice,setSelectedAudioDevice] = useState(null)
    const [localAudioTrack,setLocalAudioTrack] = useState('')
    const [localVideoTrack,setLocalVideoTrack] = useState('')
    const [selectedVideoDevice,setSelectedVideoDevice] = useState(null)
    const [volume,setVolume] = useState('')

    useEffect(()=>{
        AgoraRTC.getDevices()
        .then(async devices => {
            const aDevices = devices.filter(function(device){
                return device.kind === "audioinput" && device.deviceId !== 'default' && device.deviceId !== 'communications';
            });
            const vDevices = devices.filter(function(device){
                return device.kind === "videoinput" && device.deviceId !== 'default' && device.deviceId !== 'communications'
            });

            if(aDevices && aDevices.length > 0){
                const aDev = aDevices && aDevices.map(dt=>{
                    return {name:dt.label,value:dt.deviceId}
                })

                aDevices&&
                setAudioDevice(aDev)
                setSelectedAudioDevice(aDev[0])
                const localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack({microphoneId:aDev[0]?.value})
                setLocalAudioTrack(localAudioTrack)
            }

            if(vDevices && vDevices.length > 0){
                const vDev = vDevices && vDevices.map(dt=>{
                    return {name:dt.label,value:dt.deviceId}
                })

                vDevices&&
                setVideoDevice(vDev)
                setSelectedVideoDevice(vDev[0])
                getLocalVideoTrack(vDev[0])
            }
        })

    },[])

    useEffect(()=>{
        
        interval.current = setInterval(() => {
            const level = localAudioTrack&&localAudioTrack?.getVolumeLevel();
            setVolume((level*100).toFixed(0))
        }, 300);

        return () => clearInterval(interval.current);
    },[localAudioTrack])


    const getLocalVideoTrack = async (device) =>{

        const localVideoTrack = await AgoraRTC.createCameraVideoTrack({
            cameraId:device?.value,
            encoderConfig: {
                width: {ideal:0,min:200,max:0},
                // Specify a value range and an ideal value
                height: { ideal: 250, min: 200, max: 250 },
                frameRate: 15,
                bitrateMin: 100, bitrateMax: 1000,
                
              }
        })
        .catch(err=>console.log(err))
        setLocalVideoTrack(localVideoTrack)
        localVideoTrack.play('video-player-for-testing')
    }

    const handleChangeAudioDevice = async (e) =>{
        const device = audioDevices.filter(dt=>dt.value == e)
        const localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack({microphoneId:device[0]?.value})
        setLocalAudioTrack(localAudioTrack)
        setSelectedAudioDevice(device[0])
    }

    const handleChangeVideoDevice = (e) =>{
        localVideoTrack.stop();
        localVideoTrack.close();
        const device = videoDevices.filter(dt=>dt.value == e)
        getLocalVideoTrack(device[0])
        setSelectedVideoDevice(device[0])
    }

    const handleContinue = async () =>{
        if(selectedAudioDevice?.value){
            if (localAudioTrack) {
                localAudioTrack.stop();
                localAudioTrack.close();
            }
            if (localVideoTrack) {
                localVideoTrack.stop();
                localVideoTrack.close();
            }
            props.onContinue(selectedAudioDevice,selectedVideoDevice)
        }
        else{
            toast.error('Please Select Audio Device',{toastId:'audio-device-err'})
        }
    }

    // useEffect(()=>{
    //     return ()=>window.location.reload()
    // },[])

    return (
        <Modal show={props.show} size="lg" centered animation={false}>
            <Modal.Header>
                <div>
                    <h3 className="m-0">Check Devices</h3>
                    <h6 style={{color:'gray',margin:0}}>Check your audio and video device before join meeting</h6>
                </div>
            </Modal.Header>
            <Modal.Body className="py-4 pl-4 pr-3 w-100">
                <h5 style={{marginTop:20}}>Mic Test</h5>
                <Row className="w-100"> 
                    <Col md={3}>
                        <Dropdown style={{width:'100%'}} as={ButtonGroup} onSelect={(e)=>handleChangeAudioDevice(e)}>
                            <Button style={{backgroundColor:'#096E82',border:'none'}}>Mics</Button>

                            <Dropdown.Toggle split style={{backgroundColor:'#096E82',border:'none'}} />

                            <Dropdown.Menu>
                                {
                                    audioDevices.map((dt,i)=>{
                                        return <Dropdown.Item eventKey={dt.value}>{dt.name}</Dropdown.Item>
                                    })
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col md={9} className="d-flex align-items-center">
                        {
                            audioDevices.length > 0 ? 
                                <h6 style={{margin:0}}>{selectedAudioDevice?.name}</h6>
                            :
                                <h6 style={{margin:0,color:'lightgray'}}>No Device Connected</h6> 
                        }
                    </Col>
                </Row>

                <Row className="mt-3" style={{width:300}}>
                    <Col md={12}>
                        <ProgressBar now={volume} variant="info" visuallyHidden />
                    </Col>
                </Row>

                <hr style={{margin:'40px auto'}} />

                <h5 style={{marginTop:20}}>Cam Test</h5>
                <Row className="w-100"> 
                    <Col md={3}>
                        <Dropdown style={{width:'100%'}} as={ButtonGroup} onSelect={(e)=>handleChangeVideoDevice(e)}>
                            <Button style={{backgroundColor:'#096E82',border:'none'}}>Cams</Button>

                            <Dropdown.Toggle split style={{backgroundColor:'#096E82',border:'none'}} />

                            <Dropdown.Menu>
                                {
                                    videoDevices.map((dt,i)=>{
                                        return <Dropdown.Item eventKey={dt.value}>{dt.name}</Dropdown.Item>
                                    })
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col md={9} className="d-flex align-items-center">
                        {
                            videoDevices.length > 0 ? 
                                <h6 style={{margin:0}}>{selectedVideoDevice?.name}</h6>
                            :
                                <h6 style={{margin:0,color:'lightgray'}}>No Device Connected</h6> 
                        }
                    </Col>
                </Row>

                <Row className="mt-3" style={{width:300,height:200,overflow:'hidden'}}>
                    <Col md={12} >
                        {
                            videoDevices.length > 0 &&
                            <div id="video-player-for-testing" style={{width:300,height:200}}></div>
                        }
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={handleContinue} style={{backgroundColor: '#096E82',borderRadius:8,height:45,width:150,color:'white',border:'none'}}>Continue</button>
            </Modal.Footer>
        </Modal>
    )
}
