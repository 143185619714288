export const onChangeChildFavouriteActivitiesForm6 = (data) => {
    return (dispatch) => {
        dispatch({ type: 'onChangeChildFavouriteActivitiesForm6', payload: data })
    }
}


export const onChangeBestWayToHelpChildForm6 = (data) => {
    return (dispatch) => {
        dispatch({ type: 'onChangeBestWayToHelpChildForm6', payload: data })
    }
}


export const onChangeAssetsOrStrengthsForm6 = (data) => {
    return (dispatch) => {
        dispatch({ type: 'onChangeAssetsOrStrengthsForm6', payload: data })
    }
}


export const onChangeMostProudMomentForm6 = (data) => {
    return (dispatch) => {
        dispatch({ type: 'onChangeMostProudMomentForm6', payload: data })
    }
}


export const onChangeOtherInformationForm6 = (data) => {
    return (dispatch) => {
        dispatch({ type: 'onChangeOtherInformationForm6', payload: data })
    }
}


export const onChangeChildGenderForm6 = (data) => {
    return (dispatch) => {
        dispatch({ type: 'onChangeChildGenderForm6', payload: data })
    }
}

export const onChangeImageForm6 = (data) => {
    return (dispatch) => {
        dispatch({ type: 'onChangeImageForm6', payload: data })
    }
}

export const onChangeDocumentsForm6 = (data) => {
    return (dispatch) => {
        dispatch({ type: 'onChangeDocumentsForm6', payload: data })
    }
}

export const onChangeDeleteDocumentForm6 = (data) => {
    return (dispatch) => {
        dispatch({ type: 'onChangeDeleteDocumentForm6', payload: data })
    }
}