import React,{useEffect,useState} from 'react'
import {baseURL, Decrypt, headers} from '../../Config'
import {useSelector} from 'react-redux'
import Skeleton from 'react-loading-skeleton';
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import ClinicianDashboardNavbar from '../../Components/ClinicianDashboardNavbar'
import DashboardChildCard from '../../Components/DashboardChildCard'
import DashboardClinicianCard from '../../Components/DashboardClinicianCard'
import { toast } from 'react-toastify'
import './CSS/ParentDetail.css'
import { Row } from 'react-bootstrap'

export default function ParentDetail(props) {


    const sidebarWidth = useSelector(data => data.SidebarReducer.width)
    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const [skeletons,setSkeletons] = useState([{},{},{},{},{},{},{},{},{},{}])
    const [parentDetail,setParentDetail] = useState(null)
    const [isSelected,setIsSelected] = useState(1)
    const [notes,setNotes] = useState([])
    const [childs,setChilds] = useState([])
    const [rbts,setRBTS] = useState([])
    const [isChildsLoaded,setIsChildsLoaded] = useState(false)
    const [isRbtsLoaded,setIsRbtsLoaded] = useState(false)
    const [isLoaded,setIsLoaded] = useState(false)
    const [userType,setUserType] = useState(null)

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })

        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            setUserType(Decrypt(dt.userType))
            fetch(baseURL+ '/api/getParentById',{
                method:'Post',
                body:JSON.stringify({uid:props.match.params.id}),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    toast.error('error..')
                    setParentDetail([])  
                    setIsLoaded(true)
                }
                else{
                    setParentDetail(res2.message[0].obj)
                    setIsLoaded(true)
                }
            })
        }
    },[])

    const handleGetChilds = () =>{
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        setIsSelected(2)

        if(dt){
            fetch(baseURL+ '/api/get-parent-childs',{
                method:'Post',
                body:JSON.stringify({uid:props.match.params.id}),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    toast.error('error..')
                    setIsChildsLoaded(true)
                    setChilds([])
                }
                else{
                    setChilds(res2.message)
                    setIsChildsLoaded(true)
                }
            })
        }
    }


    const handleGetRBTs = () =>{
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        setIsSelected(3)

        if(dt){
            fetch(baseURL+ '/api/get-parent-assigned-rbts',{
                method:'Post',
                body:JSON.stringify({uid:props.match.params.id}),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    toast.error('error..')
                    setIsRbtsLoaded(true)
                    setRBTS([])
                }
                else{
                    setRBTS(res2.message)
                    setIsRbtsLoaded(true)
                }
            })
        }
    }

    return (
        <div className="parent-profile-detail-container">
            <ParentDashboardSideBar active="parents" user={userType&&userType === 'BC' ? "BC" :userType === 'RBT' ? 'RBT' : 'BCBA'} />
            <div style={{marginLeft:windowWidth > 1024 &&sidebarWidth}}>
                <ClinicianDashboardNavbar 
                    showAppointments={(userType&&userType === 'RBT' || userType === 'BCBA') ? false : true} 
                    active="parents" 
                    user={userType&&userType === 'BC' ? "BC" :userType === 'RBT' ? 'RBT' : 'BCBA'}
                />
                <div className="parent-profile-detail-body">
                    <h2 style={{fontFamily:'CocoSharp bold'}}>Parent Profile</h2>
                    {
                        isLoaded ?
                        <>
                            <div className="mx-0 mt-5 parent-profile-detail-row-1">
                                <div className="parent-profile-detail-row-1-col-1" style={{backgroundImage:`url(${parentDetail&&Decrypt(parentDetail.image)})`}}>
                                </div>
                                <div className="parent-profile-detail-row-1-col-2">
                                    <div>
                                        <h1 style={{marginBottom:0,fontFamily:'CocoSharp regular',fontSize:36,color:'#096E82'}}>{parentDetail&&Decrypt(parentDetail.first_name) + ' ' + Decrypt(parentDetail.last_name)}</h1>
                                        <h4 style={{marginTop:10,fontFamily:'CocoSharp bold',color:'#AEDFE5'}}>Parent</h4>
                                        <p style={{marginTop:0,lineHeight:1.4,fontFamily:'CocoSharp regular',width:windowWidth >=768 ? '70ch' : '100%',fontSize:17,wordWrap:'break-word'}}>{parentDetail&&Decrypt(parentDetail.short_des) ? Decrypt(parentDetail.short_des) : 'No Description'}</p>
                                    </div>
                                    <button className="parent-send-message-btn">Send Message</button>
                                </div>
                            </div>

                            <Row className="mx-0 mt-3 parent-profile-detail-row-2">
                                <div className="parent-profile-detail-row-2-tab-bar">
                                    {/* <button className="parent-profile-detail-row-2-tab-bar-btn" onClick={()=>setIsSelected(1)} style={isSelected === 1 ? {borderBottom:'solid 2px #F99B9B',color:'#F99B9B'} : {borderBottom:'solid 1px #AEB0D0',color:'#ADB5CC'}}>
                                        Notes
                                    </button> */}
                                    <button className="parent-profile-detail-row-2-tab-bar-btn" onClick={handleGetChilds}  style={isSelected === 2 ? {borderBottom:'solid 2px #F99B9B',color:'#F99B9B'} : {borderBottom:'solid 1px #AEB0D0',color:'#ADB5CC'}}>
                                        Childs
                                    </button>
                                    <button className="parent-profile-detail-row-2-tab-bar-btn" onClick={handleGetRBTs} style={isSelected === 3 ? {borderBottom:'solid 2px #F99B9B',color:'#F99B9B'} : {borderBottom:'solid 1px #AEB0D0',color:'#ADB5CC'}}>
                                        Behavior Interveners
                                    </button>
                                    <hr style={{width:'100%',margin:0,borderTop:'solid 1px #AEB0D0'}} />
                                </div>
                                <div className="w-100">
                                {
                                    isSelected === 1 ?
                                        <div>

                                        </div>
                                    : isSelected === 2 ?
                                        <div style={{display:'flex',flexWrap:'wrap'}}>
                                            {
                                                    isChildsLoaded ?

                                                        childs.length > 0 ?
                                                            childs.map((dt,i)=>{
                                                                return  <React.Fragment key={i}>
                                                                            <DashboardChildCard 
                                                                                isParent={false}
                                                                                childName={Decrypt(dt.child_name)}
                                                                                image={dt.image}
                                                                                dob={Decrypt(dt.birth_date)}
                                                                                gender={Decrypt(dt.gender)}
                                                                                otherInfo={Decrypt(dt.other_information)}
                                                                                Id={dt.uid}
                                                                                path="/child-detail"
                                                                            />
                                                                        </React.Fragment>
                                                            })
                                                        : 
                                                            <div style={{width:'100%',height:300,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                                <h1 style={{color:'lightgray'}}>No Data</h1>
                                                            </div>

                                                    :
                                                        skeletons.map(dat=>{
                                                            return <Skeleton width={210} height={220} style={{marginLeft:0,marginRight:20,marginTop:20,borderRadius:15}}/>
                                                        })
                                                    
                                            }
                                        </div>
                                    :
                                        <div>
                                            {
                                                    isRbtsLoaded ?
                                                        rbts.length > 0 ?
                                                            rbts.map((dt,i)=>{
                                                                return  <React.Fragment key={i}>
                                                                            <DashboardClinicianCard 
                                                                                isParent={false}
                                                                                name={Decrypt(dt.first_name) + ' ' + Decrypt(dt.last_name)}
                                                                                image={Decrypt(dt.image)}
                                                                                otherInfo={Decrypt(dt.short_des)}
                                                                                Id={dt.uid}
                                                                                path={`/therapist-profile-detail/rbt/${dt.uid}`}
                                                                            />
                                                                        </React.Fragment>
                                                            })
                                                        : 
                                                            <div style={{width:'100%'}}>
                                                                <h4 style={{color:'lightgray'}}>No Data</h4>
                                                            </div>

                                                    :
                                                        skeletons.map(dat=>{
                                                            return <Skeleton width={210} height={220} style={{marginLeft:0,marginRight:20,marginTop:20,borderRadius:15}}/>
                                                        })
                                                    
                                            }
                                        </div>
                                }
                                </div>
                            </Row>
                        </>
                        :
                            <div className="mx-0 mt-5 parent-profile-detail-row-1">
                                <div className="parent-profile-detail-row-1-col-1">
                                    <Skeleton height="100%" />
                                </div>
                                <div className="parent-profile-detail-row-1-col-2">
                                    <div>
                                        <Skeleton height={25} width="100%" />
                                        <Skeleton height={25} width="100%" />
                                        <Skeleton height={150} width="100%" />
                                    </div>
                                    <div className="d-flex align-items-center mt-4">
                                        <Skeleton height={45} width={150} style={{marginRight:10}} />
                                        <Skeleton height={45} width={150} style={{backgroundColor:'transparent',backgroundImage:'none'}} />
                                    </div>
                                </div>
                            </div> 
                        }
                </div>
            </div>
        </div>
    )
}
