import React,{useState,useEffect} from 'react'
import {useSelector,useDispatch} from 'react-redux'
import ProgramCard from '../../Components/ReportGenerationComp/ProgramCard'
import {baseURL, Decrypt, headers} from '../../Config'
import {useHistory,Prompt} from 'react-router-dom'
import Swal from 'sweetalert2'
import TextAreaComp from '../../Components/TextAreaComp'
import {GetClinicianAppointments} from '../../Store/Actions/AppointmentActions'
import {GetRBTData} from '../../Store/Actions/RBTActions'
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import ClinicianDashboardNavbar from '../../Components/ClinicianDashboardNavbar'
import IntroModal from '../../Components/ReportGenerationComp/IntroModal'
import { toast } from 'react-toastify'
import { useStopwatch } from 'react-timer-hook';
import { Row,Spinner,Modal } from 'react-bootstrap'
import './CSS/ReportGenerationIntro.css'
import { convertIntoUtc, getTimeInISO } from '../../TimeFormate'

const formSteps = [
    {name:'ISP Initial Report',type:0},
    {name:'B - Relaxation Practice - Program',type:1},
    {name:'B - Session Tip Sheet',type:2},
    {name:'B - Tolerating Novelty - Program',type:3},
    {name:'B - Tracking Outbursts - Program',type:4},
    {name:'B - Wait for Reinforcer long - Program',type:5}
]

export default function ReportGenerationIntro(props) {

    const history = useHistory()
    const dispatch = useDispatch()

    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const [show,setShow] = useState(false)
    const [showRequestModal,setShowRequestModal] = useState(false)
    const [showTimerModal,setShowTimerModal] = useState(true)
    const [url,setURL] = useState('')
    const [therapistId,setTherapistId] = useState('')
    const [isApproved,setIsApproved] = useState('')
    const [description,setDescription] = useState('')
    const [userType,setUserType] = useState(null)
    const [isLoading,setIsLoading] = useState(false)

    const sidebarWidth = useSelector(data => data.SidebarReducer.width)
    const {seconds,minutes,hours,isRunning,start,pause,reset} = useStopwatch({ autoStart: false });

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })
        dispatch(GetRBTData())
        dispatch(GetClinicianAppointments())

        //Get File
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            setUserType(Decrypt(dt.userType))
            const path = Decrypt(dt.userType) === 'BC' ? '/api/createForm' : '/api/getForm'
            const obj = {childId:props.match.params.id, therapistId:dt.uid, type:0}

            fetch(baseURL + path,{
                method:'Post',
                body:JSON.stringify(obj),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    toast.error(res2?.message)
                }
                else{
                    setTherapistId(res2?.message?.therapistId)
                    setIsApproved(res2?.message?.isApproved)
                }
            })
            .catch(err=>console.log(err))
        }

        isRunning&&
        window.addEventListener('beforeunload', alertUser)
        window.addEventListener('unload', handleStopTime)

        return () => {
            isRunning&&
            window.removeEventListener('beforeunload', alertUser)
            window.removeEventListener('unload', handleStopTime)
        }

    },[])

    const alertUser = e => {
        e.preventDefault()
        e.returnValue = 'Please stop time before leaving, other wise your time will not report'
        return 'Please stop time before leaving, other wise your time will not report'
    }

    const handleRequest = () =>{
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            const obj = {childId:props.match.params.id, Id:dt.uid, therapistId, description}
            setIsLoading(true)
            fetch(baseURL + '/api/requestSession',{
                method:'Post',
                body:JSON.stringify(obj),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    setIsLoading(false)
                    toast.error(res2?.message,{toastId:'request-session-id',autoClose:3000})
                }
                else{
                    setIsLoading(false)
                    setIsApproved(true)
                    setShowRequestModal(false)
                    toast.error(res2?.message,{toastId:'request-session-suc-id',autoClose:3000})
                }
            })
            .catch(err=>console.log(err))
        }
    }

    const handleStopTime = () =>{
        // reset()
        Swal.fire({
            title: 'Are you sure?',
            text: 'If you stop time you can not resume it again.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, stop',
            cancelButtonText: 'Cancel'
        }).then((result) => {
            const dt = JSON.parse(localStorage.getItem('bx_user_token'))
            if (result.isConfirmed && dt) {
                const obj = {
                    therapist_id:dt.uid,
                    therapist_type:userType,
                    child_id:props.match.params.id,
                    hours:hours,
                    minutes:minutes,
                    recorded_date:convertIntoUtc(getTimeInISO())
                }

                fetch(baseURL + '/api/reportTimeByTherapist',{
                    method:'Post',
                    body:JSON.stringify(obj),
                    headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
                })
                .then(res=>res.json())
                .then(res2=>{
                    if(res2.code === 'Error'){
                        toast.error('Something went wrong',{toastId:'stop-time-id',autoClose:3000})
                    }
                    else{
                        reset()
                        pause()
                    }
                })
            }
        })
    }

    const handleGetFile = (type) =>{
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            // setShow(true)
            const path = Decrypt(dt.userType) === 'BC' ? '/api/createForm' : '/api/getForm'
            const obj = {childId:props.match.params.id, therapistId:dt.uid, type:type}

            fetch(baseURL + path,{
                method:'Post',
                body:JSON.stringify(obj),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    toast.error(res2?.message)
                }
                else{
                    setURL(res2?.message?.url)
                    setTherapistId(res2?.message?.therapistId)
                }
            })
            .catch(err=>console.log(err))
        }
    }

    return (
        <div className="dashboard-report-gen-container">
            <Prompt
                when={isRunning}
                message="Please stop time before leaving, other wise your time will not report."
            />
            <ParentDashboardSideBar active="reports" user={userType&&userType === 'BC' ? "BC" :userType&&userType === 'BCBA' ? 'BCBA' :userType&&userType === 'RBT' ? 'RBT' : 'Parent'} />
            <div style={{marginLeft:windowWidth > 1024 && sidebarWidth}}>
                <ClinicianDashboardNavbar 
                    active="reports" 
                    user={userType&&userType === 'BC' ? "BC" :userType&&userType === 'RBT' ? "RBT" : 'BCBA'}
                    showAppointments={(userType === 'BCBA' || userType === 'RBT') ? false : true}
                />
                <div className="dashboard-report-gen-body">
                    <div style={{width:'100%',marginTop:20}}>
                        {
                            userType&&userType === 'BC' ?
                                isApproved !== '' ?
                                    <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',marginBottom:20}}>
                                    {
                                        !isApproved && isRunning ?
                                            <div style={{display:'flex',alignItems:'center'}}>
                                                <p style={{fontFamily:'CocoSharp bold',color:'#F99B9B',margin:0,fontSize:28,marginRight:10}}>{hours.toString().length > 1 ? hours : '0' + hours} : {minutes.toString().length > 1 ? minutes : '0' + minutes} : {seconds.toString().length > 1 ? seconds : '0' + seconds}</p>
                                                <button onClick={handleStopTime} className="report-gen-req-btn">Stop</button>
                                            </div>
                                        :isApproved && !isRunning ?
                                            <p style={{fontFamily:'CocoSharp bold',textAlign:'right',color:'#F99B9B'}}>Approved</p>
                                        :
                                            <p style={{fontFamily:'CocoSharp bold',textAlign:'right',color:'#F99B9B'}}>Not Approved</p>
                                    } 
                                    </div>
                                :null
                            :userType&&userType === 'BCBA' ?
                                isApproved !== '' ?
                                    !isApproved&&
                                        <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',flexWrap:'wrap'}}>
                                            {
                                                !isApproved && isRunning ?
                                                    <div style={{display:'flex',alignItems:'center',marginBottom:20}}>
                                                        <p style={{fontFamily:'CocoSharp bold',color:'#F99B9B',margin:0,fontSize:28,marginRight:10}}>{hours.toString().length > 1 ? hours : '0' + hours} : {minutes.toString().length > 1 ? minutes : '0' + minutes} : {seconds.toString().length > 1 ? seconds : '0' + seconds}</p>
                                                        <button onClick={handleStopTime} className="report-gen-req-btn">Stop</button>
                                                    </div>
                                                :!isApproved && !isRunning ?
                                                    <button onClick={()=>setShowRequestModal(true)} style={{marginLeft:10,marginBottom:20}} className="report-gen-req-btn">Approve Reports</button>
                                                :null
                                            }
                                        </div>
                                : null
                            : null
                                
                        }
                        <div style={{marginTop:15}}> 
                            {
                                formSteps?.map((dt,i)=>{
                                    return  <React.Fragment key={i}> 
                                                <ProgramCard 
                                                    name={dt.name} 
                                                    path={`/report-view/${props.match.params.id}/${dt.type}`}
                                                    // onClick={()=>(setShow(true),setTimeout(()=>handleGetFile(dt.type),300))}
                                                />
                                            </React.Fragment>

                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <IntroModal show={show} setShow={(val)=>setShow(val)} url={url} />
            
            {/* Start Timer */}
            <Modal className="request-session-detail-modal" show={(userType&&userType === 'BC' || userType&&userType === 'BCBA') && (!isApproved ? showTimerModal : false)} centered animation={false}>
                {
                    isApproved !== '' ?
                        !isApproved &&
                            <Modal.Body style={{position:'relative',zIndex:1,borderRadius:20}} className="py-4 pb-4 text-center">
                                <h2>Do you want to record time?</h2>
                                <p>Please Note: Before Leaving this page make sure to stop time, otherwise your time will not be recorded.</p>
                                
                                <div style={{display:'flex',alignItems:'center',justifyContent:'space-around',marginTop:30}}>
                                    <button onClick={()=>(start(),setShowTimerModal(false))} id="start-timer-modal-btn">Start Timer</button>
                                    <button onClick={()=>setShowTimerModal(false)} id="start-timer-modal-btn">Cancel</button>
                                </div>

                            </Modal.Body>
                    :
                        <Modal.Body style={{position:'relative',zIndex:1,borderRadius:20}} className="py-5 pb-4 text-center">
                            <h2 className='m-0' style={{fontSize:24}}>Loading, please wait</h2>
                        </Modal.Body>
                }
            </Modal>

            {/* Request sessions */}
            <Modal className="request-session-detail-modal" show={showRequestModal} centered onHide={()=>setShowRequestModal(false)} animation={false}>
                <Modal.Header style={{borderBottom:'none'}} closeButton>
                    <p style={{fontFamily:'CocoSharp regular',margin:0,fontSize:28,color:'#096E82'}}>Instruction for Sessions</p>
                </Modal.Header>
                <Modal.Body style={{position:'relative',zIndex:1,borderRadius:20}} className="py-2 pb-4">
                
                    <TextAreaComp
                        value={description}
                        label="Type here"
                        containerStyle={{marginTop:windowWidth < 991&&30}}
                        labelStyle={{ fontSize: 16, fontWeight: 600 }}
                        inputLength={(e) => e.target.value = (e.target.value).slice(0, 500)}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    {
                        isLoading ? 
                            <button id="request-session-detail-modal-btn" style={{marginTop:30}}>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                {' '}
                                Loading....
                            </button>
                        :
                            <button id="request-session-detail-modal-btn" onClick={handleRequest}  style={{marginTop:30}}>Submit Request</button>

                    }
                </Modal.Body>
            </Modal>
        </div>
    )
}
