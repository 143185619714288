import React,{useEffect, useState} from "react";
import heroImg from "../../assets/career/hero.png";
import heroDesk from "../../assets/career/hero-desk.png";
import curve from "../../assets/career/curve.png";
import PinkBtn from "../../Components/PinkBtn";
function Hero() {

  const [scrollHeight,setScrollHeight] = useState(0)
  
  useEffect(()=>{
    setScrollHeight(document.body.scrollHeight - 400)
  },[])
  
  return (
    <div className=" relative z-[3] mt-[100px] flex justify-between items-center gap-7 xl:gap-0 bg-lightGreen-100 xl:px-0 xl:flex-row flex-col-reverse py-6  xl:py-0">
      <div className="flex-[0.5] flex justify-center xl:pl-20 xl:text-left xl:items-start gap-4 flex-col">
        <h1 className="text-lightGreen-500 font-bold md:text-5xl text-2xl">
          Join Us
        </h1>

        <p className="text-lightGreen-500 font-normal text-lg ">
          Careers with us are more than just jobs; they make a difference in
          people's lives.
        </p>
        <div className="xl:mt-7">
          <PinkBtn onClick={()=>window.scrollTo(0,scrollHeight)} text={"View Positions"} url={'Career'} />
        </div>
      </div>
      <div className=" flex xl:justify-end justify-center flex-[0.5] h-full relative z-[3]">
        <img className="w-[100%] xl:block hidden" src={heroDesk} alt="" />
        <img
          className="w-[100%] rounded-lg xl:hidden block xl:rounded-tl-[50%] xl:rounded-bl-[50%] "
          src={heroImg}
          alt=""
        />
      </div>
      <img
        src={curve}
        className="absolute object-cover xl:block hidden bottom-[0%]  left-0 w-full z-10"
        alt=""
      />
    </div>
  );
}

export default Hero;
