import React,{useState,useEffect} from 'react'
import {Row,Col} from 'react-bootstrap'
import TopBar from '../../Components/TopBar'
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
import SelectComponent from '../../Components/SelectComponent'
import vectorImg from '../../images/diag-info-vector-img.png'
import circleImg from '../../images/circle-1.png'
import './CSS/SelectDiagnosis.css'
import { useHistory } from 'react-router-dom'

function SelectDiagnosis() {

    const history = useHistory()

    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])

    const handleOnChange = (val) =>{
        if(val !== ''){
            localStorage.setItem('diagnosisType',JSON.stringify({isRecom:val,score:''}))
            history.push('/signup')
        }
    }

    return (
        <div style={{width:'100%'}}>
            <Col sm='12' md='12' lg='12' xl='12' >
                <Navbar /> 
            </Col>
            <Col sm='12' md='12' lg='12' xl='12' className="p-0" style={{position:'relative',overflow:'hidden'}} >
                <Row className='m-0 pt-4 px-0'>
                    <Col sm={12} md={12} className="select-diagnosis-col">
                        <div className="select-container">
                            <h1 style={{color:'#030533'}}>Select Diagnosis</h1>
                            <p style={{color:'#030533',fontSize:20}}>Worried you or a loved one might have Autism?</p>
                            <SelectComponent 
                                label="Select Here"
                                containerStyle={{width:'100%',marginTop:20}}
                                labelStyle={{fontSize:16,fontWeight:600,textAlign:'left'}}
                                onChange={(val)=>handleOnChange(val)}
                                options={['Assessment','Consultation']}
                            />
                        </div>
                    </Col>
                </Row>
                <img src={vectorImg} className="img-fluid" id="floated-circle" style={{position:'absolute',left:'25%',top:'0%',zIndex:-500}} />
                <img src={circleImg} className="img-fluid" id="floated-circle" style={{position:'absolute',bottom:-400,right:-500,zIndex:-100}} />
            </Col>
            <Footer />
        </div>
    )
}

export default SelectDiagnosis;
