import SidebarState from '../States/SidebarState'

const SidebarReducer = (state=SidebarState,action)=>{
    switch(action.type){
        case 'SetWidth':
        {
            let width = state.width === '18%' ? '60px' : '18%'
            return { width }
        }
        default :
        {
            return state
        }
    }
}

export default SidebarReducer;