import React,{useState} from "react";
import PinkBtn from "./PinkBtn";
import {Required} from '../Validations'
import { baseURL, headers } from '../Config'
import { toast } from 'react-toastify'

function ReferalForm() {

  const [email,setEmail] = useState('')
  const [isLoading,setIsLoading] = useState(false)

  const handleSubscribe = () =>{
    if(Required('Email',email)){
        setIsLoading(true)
        fetch(baseURL + '/api/createNewsLetterSub',{
            method:'Post',
            body:JSON.stringify({email}),
            headers:headers
        })
        .then(res=>res.json())
        .then(res2=>{
            if(res2?.code === 'Success'){
                setEmail('')
                setIsLoading(false)
                toast.success(res2?.message,{toastId:'home-sub-news-id1'})
            }
            else{
                setIsLoading(false)
                toast.success(res2?.message,{toastId:'home-sub-news-id2'})
            }
        })
    }
  }


  return (
    <div className="bg-[#278091] mt-[50px] w-full flex justify-center items-center px-5">
      <div className="flex items-center justify-center flex-col h-[350px] gap-14 w-full max-w-[1440px]">
        <h1 className="text-white font-bold sm:text-5xl text-4xl">
          Stay Informed
        </h1>
        <div className="flex justify-center items-center gap-3 w-full max-w-[810px] sm:flex-row flex-col">
          <input
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            type="email"
            placeholder="Enter email address"
            className="rounded-[10px] w-full bg-white h-[72px] py-4 px-3 text-[#278091] placeholder:text-lg placeholder:font-normal"
          />
          {
              !isLoading ?
                <PinkBtn
                  text={"Subscribe"}
                  classes={"h-[72px] sm:w-[190px] w-full text-xl"}
                  onClick={handleSubscribe}
                />
              :
                <PinkBtn
                  text={"Subscribing..."}
                  classes={"h-[72px] sm:w-[190px] w-full text-xl"}
                />
          }
        </div>
      </div>
    </div>
  );
}

export default ReferalForm;
