import React from 'react'
import {useHistory} from 'react-router-dom'
import './CSS/MChatIntroComp.css'

export default function MChatIntroComp({path}) {

    const history = useHistory()

    return (
            <div className="mchat-intro-screen-content">
                <h1 style={{margin:0,fontFamily:'CocoSharp bold'}}>M-CHAT-R</h1>
                <p style={{marginTop:10}}>
                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. 
                    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, 
                    content here', making it look like readable English.
                </p>

                <h1 style={{marginTop:40,fontFamily:'CocoSharp bold'}}>Instructions</h1>
                <p style={{marginTop:10}}>
                    The Modified Checklist for Autism in Toddlers, Revised (M-CHAT-R) is a screener that will ask a series of 20 
                    questions about your child’s behavior. It's intended for toddlers between 16 and 30 months of age. 
                    The results will let you know if a further evaluation may be needed. You can use the results of the 
                    screener to discuss any concerns that you may have with your child’s healthcare provider.
                </p>
                <p style={{marginTop:30}}>
                    Please answer questions to reflect your child's usual behaviors. If the behavior is rare 
                    (e.g., you've seen it only once or twice), answer as if the child has not acquired the behavior.
                </p>

                <button className="mchat-intro-start-btn" onClick={()=>history.push(path)}>Lets Start</button>
            </div>
    )
}
