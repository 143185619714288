import React, {useState,useEffect} from 'react';
import {Col } from 'react-bootstrap';
import Header from "../../../Components/Header";
import Footer from "../../../Components/Footer";
import Informed from "../../../Components/Informed";
import {Spinner} from 'react-bootstrap'
import {OnlyAlphabets,EmailValidation,PasswordValidation,Required} from '../../../Validations'
import InputComp from '../../../Components/InputComp';
import InputPassComp from '../../../Components/InputPassComp';
import SelectCountryComp from '../../../Components/SelectCountryComp';
import PhoneNumberComp from '../../../Components/PhoneNumberComp';
import UploadImageComp from '../../../Components/UploadImageComp';
import PlacesInput from '../../../Components/PlacesInput';
import {Link,useHistory} from 'react-router-dom';
import {Encrypt,baseURL,headers,Decrypt} from '../../../Config'
import './CSS/SignupPage.css';
import { toast } from 'react-toastify';


export default function SignUpPage() {

    const history = useHistory()
    
    const [firstName, setFirstName] = useState('');
    const [firstNameErr, setFirstNameErr] = useState(false);

    const [lastName, setLastName] = useState('');
    const [lastNameErr, setLastNameErr] = useState(false);

    const [email, setEmail] = useState('');
    const [isEmailVerify, setIsEmailVerify] = useState(false);
    const [emailErr, setEmailErr] = useState(false);
    const [emailExist, setEmailExist] = useState(false);

    const [password, setPassword] = useState('');
    const [PasswordErr, setPasswordErr] = useState(null);

    const [number, setNumber] = useState('');
    const [numberErr, setNumberErr] = useState(false);

    const [image, setImage] = useState(null);

    const [nationality, setNationality] = useState('');
    const [gender, setGender] = useState('Male');
    const [address, setAddress] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');

    const [isLoading, setIsLoading] = useState(false);


    const handleLogout = () =>{
        localStorage.removeItem('bx_user_token')
        history.push('/')
    }

    useEffect(()=>{
            const data = JSON.parse(localStorage.getItem('bx_user_token'))
    
            if(data){
                if(Decrypt(data.userType) === 'BC'){
    
                    fetch(baseURL+'/api/getClinicianById',{
                        method:'Post',
                        body:JSON.stringify({uid:data.uid}),
                        headers:{...headers,'authorization':'Bearer ' + data.accessToken}
                    })
                    .then(res=>res.json())
                    .then(res2=>{
                        if(res2.code === 'Success' && res2.message.length > 0){
                            const dt = res2.message[0]
    
                            if(data.accessToken&&data.userType === 'BC'){
                                history.push('/bc-dashboard')
                            }
                            else{
                               return null
                            }
                        }
                        else{
                            handleLogout()
                        }
                    })
                }
                else{
                    fetch(baseURL+'/api/getParentById',{
                        method:'Post',
                        body:JSON.stringify({uid:data.uid}),
                        headers:{...headers,'authorization':'Bearer ' + data.accessToken}
                    })
                    .then(res=>res.json())
                    .then(res2=>{
                        
                        if(res2.code === 'Success' && res2.message.length > 0){
                            const dt = res2.message[0]

                            if(data.accessToken&&parseInt(Decrypt(data.userStatus)) >= 1){
                                history.push('/dashboard-home')
                            }
                            else if(data.accessToken&&parseInt(Decrypt(data.userStatus)) === 0&&parseInt(Decrypt(data.childStatus)) === 0){
                                history.push('/payment-detail')
                            }
                            else if(data.accessToken&&parseInt(Decrypt(data.userStatus)) === 0&&parseInt(Decrypt(data.childStatus)) === 1){
                                history.push('/pre-call-questionnaire')
                            }
                            else if(data.accessToken&&parseInt(Decrypt(data.userStatus)) === 0&&parseInt(Decrypt(data.childStatus)) === 2){
                                history.push('/schedule-first-meeting')
                            }
                            else{
                                return null
                            }
                        }
                        else{
                            handleLogout()
                        }
                    })
                }
            }
    },[])
    

    const handleEmailValidation = (e) =>{

        setEmail(e.target.value)

        if(EmailValidation(e.target.value)){
            
            setEmailErr(true)
            setIsEmailVerify(false)
            setEmailExist(false)
        }
        else{

            setEmailErr(false)
            setEmailExist(false)
            setIsEmailVerify(true)


            fetch(baseURL+'/verify-email',{
                method:'Post',
                body:JSON.stringify({email:Encrypt(e.target.value)}),
                headers:headers
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Success'){
                    setIsEmailVerify(false)
                    setEmailExist(false)
                }
                else{
                    setIsEmailVerify(false)
                    setEmailExist(true)
                }
                
            })
        }
    }
    

    const handleSignUp = () =>{

        const isDone = Required('First Name',firstName) && !firstNameErr && Required('Last Name',lastName) && !lastNameErr && 
                        Required('Email',email) && !emailErr && Required('Password',password) && PasswordErr !== 0 && Required('Number',number) && Required('Address',address)
                        && !emailExist && !numberErr

        
        if(isDone){
            if(number.trim().length < 8){
                toast.error('Mobile number should have atleast 8 digits')
            }
            else{
                // const diagnosis_type = JSON.parse(localStorage.getItem('diagnosisType'))
                const formData = new FormData;
                
                formData.append('fName',Encrypt(firstName))
                formData.append('lName',Encrypt(lastName))
                formData.append('email',Encrypt(email))
                formData.append('password',Encrypt(password))
                formData.append('mobile',Encrypt(number))
                formData.append('nationality',Encrypt(nationality))
                formData.append('image',image)
                formData.append('gender',Encrypt(gender))
                formData.append('address',Encrypt(address))
                formData.append('lattitude',Encrypt(latitude.toString()))
                formData.append('longitude',Encrypt(longitude.toString()))
                formData.append('diagnosis_type',Encrypt('Assessment'))
                // formData.append('mchat_score',diagnosis_type?.score ? Encrypt(diagnosis_type?.score) : '')
                formData.append('mchat_score','')

                setIsLoading(true)

                fetch(baseURL + '/api/parent-registration',{
                    method:'Post',
                    body:formData
                })
                .then(res=>res.json())
                .then(res2=>{
                    if(res2.code === 'Error'){
                        toast.error(res2.message,{toastId:'sign-up-err-toast-1'})
                        setIsLoading(false)
                    }
                    else{
                        localStorage.removeItem('diagnosisType')
                        window.localStorage.setItem('bx_user_token',JSON.stringify(res2.message))
                        setIsLoading(false)
                        setFirstName('')
                        setLastName('')
                        setEmail('')
                        setPassword('')
                        setNumber('')
                        setNationality('')
                        setImage(null)
                        setGender('Male')
                        setIsLoading(false) 
                        history.push('/payment-type')
                    }
                })
                .catch(err=>toast.error(err,{toastId:'sign-up-err-toast-2'}))
            }
        }
    }

    return (
        <div style={{width:'100%'}} >
            <Header />
            <Col sm='12' md='12' lg='12' xl='12' className="mt-5">
                <div className="Signup-Page-Container mt-5">
                    <div className="Signup-Page-Body">
                        <h1 style={{fontFamily:'CocoSharp bold'}}>Create Account</h1>
                        <hr /> 

                        <div style={{width:'100%',marginTop:10}}>
                            <InputComp 
                                value={firstName}
                                onChange={(e)=>(
                                    OnlyAlphabets(e.target.value) ? 
                                        (setFirstNameErr(true), setFirstName(e.target.value)) 
                                    : 
                                        (setFirstName(e.target.value), setFirstNameErr(false))   
                                    )}
                                inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
                                isError={firstNameErr}
                                containerStyle={{marginTop:30}}
                                label="First Name *"
                                labelStyle={{fontSize:16,fontWeight:600}} 
                            />
                            {firstNameErr&&<p style={{marginLeft:10,fontSize:12,color:'red'}}>First Name should be only alphabets</p>}

                            <InputComp
                                value={lastName}
                                onChange={(e)=>(
                                    OnlyAlphabets(e.target.value) ? 
                                        (setLastNameErr(true), setLastName(e.target.value)) 
                                    : 
                                        (setLastName(e.target.value), setLastNameErr(false))   
                                    )}
                                inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
                                isError={lastNameErr} 
                                containerStyle={{marginTop:30}} 
                                label="Last Name *" 
                                labelStyle={{fontSize:16,fontWeight:600}} 
                            />
                            {lastNameErr&&<p style={{marginLeft:10,fontSize:12,color:'red'}}>First Name should be only alphabets</p>}
                            
                            <InputComp
                                value={email}
                                onChange={(e)=>handleEmailValidation(e)}
                                inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
                                isError={emailErr || emailExist} 
                                containerStyle={{marginTop:30}} 
                                label="Email *" 
                                labelStyle={{fontSize:16,fontWeight:600}} 
                            />
                            {isEmailVerify&&<span style={{fontSize:12,textAlign:'right'}}><Spinner as="span" animation="border" style={{color:'green',fontSize:8,width:12,height:12}} role="status" aria-hidden="true"/> Verifying Email...</span>}
                            {emailErr&&<p style={{marginLeft:10,fontSize:12,color:'red'}}>Email should be in format e.g abc@zyx.com</p>}
                            {emailExist&&<p style={{marginLeft:10,fontSize:12,color:'red'}}>Email already exist</p>}
                            
                            <InputPassComp
                                value={password}
                                onChange={(e)=> (setPasswordErr(PasswordValidation(e.target.value)), setPassword(e.target.value))}
                                inputLength={(e)=>e.target.value = (e.target.value).slice(0,64)}
                                containerStyle={{marginTop:30}}
                                label="Password *" 
                                labelStyle={{fontSize:16,fontWeight:600}} 
                            />
                            {PasswordErr === 0 &&<p style={{marginLeft:10,fontSize:12,color:'red'}}><b>Weak</b>, Password must contain one digit (0-9) and one special character (! @ # $ % ^ & *)</p>}
                            {PasswordErr === 1 &&<p style={{marginLeft:10,fontSize:12,color:'green'}}>Strong</p>}
                            
                            <PhoneNumberComp 
                                value={number}
                                onChange={(e)=>  e&&(e).toString().replace(/ /g,"").length < 8 ? (setNumber(e),setNumberErr(true)) : (setNumber(e),setNumberErr(false)) }
                                inputLength={(e)=>e.target.value = (e.target.value).toString().replace(/ /g,"").slice(0,15)}
                                containerStyle={{marginTop:30}} 
                                label="Mobile Number *" 
                                labelStyle={{fontSize:16,fontWeight:600}} 
                            />
                            {numberErr&&<p style={{marginLeft:10,fontSize:12,color:'red'}}>Number Should be between 8 to 15 digits</p>}

                            {/* <SelectCountryComp
                                value={nationality}
                                onSelect={(val)=>setNationality(val)}
                                label="Nationality"
                                labelStyle={{fontSize:16,fontWeight:600}} 
                                containerStyle={{marginTop:30}}  
                            /> */}

                            <PlacesInput 
                                isError={false} 
                                label="Address *" 
                                labelStyle={{fontSize:16,fontWeight:600}}
                                containerStyle={{marginTop:30}}
                                getData={(data)=>(
                                    setAddress(data?.address),
                                    setLatitude(data?.latitude),
                                    setLongitude(data?.longitude)
                                )}
                            />

                            <UploadImageComp 
                                containerStyle={{marginTop:40}} 
                                multiple={false}
                                getUploadedImage={(img)=>setImage(img)}
                                image={image&&URL.createObjectURL(image)}
                            />

                            <div style={{display:'flex',alignItems:'center',marginTop:25}}>
                                <input 
                                    id="check-box"
                                    type="radio" 
                                    name="gender" 
                                    value="Male" 
                                    checked={gender === 'Male' && true}
                                    onChange={(e)=>setGender(e.target.value)}
                                />
                                <label className="custom-radio-btn" for="check-box">
                                    <span className="check-mark"></span>
                                </label>
                                <span style={{marginLeft:10,marginRight:10,fontFamily:'CocoSharp regular',color:'black'}}>Male</span>
                                
                                <input 
                                    id="check-box-1"
                                    type="radio" 
                                    name="gender" 
                                    value="Female" 
                                    checked={gender === 'Female' && true}
                                    onChange={(e)=>setGender(e.target.value)}
                                />
                                <label className="custom-radio-btn" for="check-box-1">
                                   <span className="check-mark"></span>
                                </label>
                                <span style={{marginLeft:10,fontFamily:'CocoSharp regular',color:'black'}}>Female</span>
                            </div>
                            <br/>
                            {
                                isLoading ?
                                    <button className="Signup-btn">
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        {' '}
                                        Loading....
                                    </button>
                                :

                                    <button onClick={handleSignUp} className="Signup-btn">Next</button>
                            }
                            <br/>
                            <div style={{marginTop:12}}>
                                <p style={{fontFamily:'CocoSharp bold'}}>Already have account? <Link to="/login" className="Signup-page-link">Login</Link></p>  
                            </div>        
                        </div>       
                    </div>
                </div>
            </Col>
            <Informed />
            <Footer />
        </div>
      
    );
  }
  

  