import React,{useEffect, useState} from 'react'
import { RiFileDownloadLine } from 'react-icons/ri';
import './CSS/DownloadDocComp.css'

export default function DownloadDocComp(props) {


    return (
        <div className="download_doc_container">
            <RiFileDownloadLine color="#F99B9B" size={40} />
            <p style={{margin:0}}>Download Document</p>
        </div>
    )
}
