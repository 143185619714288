import React,{useEffect,useState} from "react";
import PinkBtn from "../../Components/PinkBtn";
import HomeCard from "../../Components/HomeCard";

export default function TrainingandResource() {
  const tiles = [
    {
      title: "Individualized Treatment",
      desc: "A single solution does not fit all. We must ensure everything we do is tailored to our clients' needs and goals.",
      url: "./Icons/IT.png",
      color: "blue",
    },
    {
      title: "Focus on the Family",
      desc: "We’re committed to supporting the entire family and including them as part of the therapy. We want parents to be informed about what the therapy entails and to know how best to support their child when we’re not there.",
      url: "./Icons/FOF.png",
      color: "pink",
    },
    {
      title: "Driven by Science",
      desc: "We’re passionate about ABA. Our clinicians use it every day to guide treatment.",
      url: "./Icons/DBS.png",
      color: "blue",
    },
  ];

  const [scrollHeight,setScrollHeight] = useState(0)
  
  useEffect(()=>{
    setScrollHeight(document.body.scrollHeight - 400)
  },[])


  return (
    <>
      <div className="grid grid-cols-1">
        <p className="text-[#096E82] font-bold text-lightGreen-500 font-bold text-4xl lg:text-4xl xl:text-5xl px-[20px] py-[20px]  text-center md:px-[200px] md:py-[100px] ">
          At Cognitia you will make a difference to the community every day by
          helping children acquire the skills they need to grow and become
          independent while empowering parents with training and resources to
          facilitating their success
        </p>
      </div>
      <div className="grid  grid-cols-1 px-[10px] md:grid-cols-3 gap-6 md:px-[180px]">
        {tiles.map((v, i) => (
          <HomeCard
            key={i}
            src={v.url}
            color={v.color}
            title={v.title}
            desc={v.desc}
          />
        ))}
      </div>
      <div className="mt-8 flex justify-center">
        <PinkBtn onClick={()=>window.scrollTo(0,scrollHeight)} text={"View Positions"} url={'Career'} />
      </div>
    </>
  );
}
