import React,{useState} from 'react'
import {ProgressBar} from 'react-bootstrap'
import { HiOutlineDownload } from 'react-icons/hi';
import downloadIcon from '../icons/downloadIcon.svg'
import pdfPreviewImage from '../images/pdfPreviewImage.png'
import './CSS/ReportsCard.css'

export default function ReportsCard({childName,parentName,report}) {

    const [progress,setProgress] = useState(0)
    const [isDownlaod,setIsDownload] = useState(false)

    const handleDownload = () =>{
        setIsDownload(true)
        var xhr = new XMLHttpRequest();
        xhr.open('GET', report, true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader( 'Access-Control-Allow-Origin', '*');
        xhr.onprogress = function(event) {
            if (event.lengthComputable) {
                var percentComplete = (event.loaded / event.total)*100;
                setProgress(percentComplete) 
            } 
        };

        xhr.onload = function(event) {
            if (this.status == 200) {
                var filename = Date.now();  
                var a = document.createElement('a');        
                a.href = window.URL.createObjectURL(xhr.response);        
                a.download = filename;       
                a.style.display = 'none';        
                document.body.appendChild(a);        
                a.click();    
                setIsDownload(false)
                setProgress(0)  
            }
            else {
                setIsDownload(false)
                setProgress(0)
            }
        };


        xhr.send();
    }

    return (
        <div className="reports-card-container">
            <div className="reports-card-header">
                <div style={{display:'flex'}}>
                    <div>
                        <p style={{fontSize:10,margin:0,color:'var(--primary-color)'}}>Child</p>
                        <p style={{fontSize:14,margin:0,color:'var(--text-color)',lineHeight:1}}>{childName.length > 8 ? childName.slice(0,7) + '..' : childName}</p>
                    </div>
                    <div style={{width:1.5,height:'30px',backgroundColor:'#C4C4C4',margin:'0px 10px'}}></div>
                    <div>
                        <p style={{fontSize:10,margin:0,color:'var(--primary-color)'}}>Parent</p>
                        <p style={{fontSize:14,margin:0,color:'var(--text-color)',lineHeight:1}}>{parentName.length > 8 ? parentName.slice(0,7) + '..' : parentName}</p>
                    </div>
                </div>
                {
                    !isDownlaod ?
                        <div onClick={handleDownload} style={{cursor:'pointer'}}>
                            <img src={downloadIcon} />
                        </div>
                    :
                        <div>
                            <HiOutlineDownload size={20} color="#00C8D4" />
                            <ProgressBar now={progress} style={{height:5}} />
                        </div>
                }
               
            </div>
            <div style={{marginTop:15}}>
                <img src={pdfPreviewImage} />
            </div>
            
        </div>
    )
}
