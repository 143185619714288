import React,{useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Required} from '../Validations'
import InsuranceForm from './InsuranceForm';
import './CSS/InsurancePaymentModal.css'

export default function InsurancePaymentModal({show,setShow,handleConfirmPay}) {

    const [company,setCompany] = useState('')
    const [insurancePlan,setInsurancePlan] = useState('')
    const [holderName,setHolderName] = useState('')
    const [insuranceId,setInsuranceId] = useState('')
    const [expiryDate,setExpiryDate] = useState('')


    const handleConfirmPayment = () =>{

        const isDone = Required('Company Name',company)&&Required('Insurance Plan',insurancePlan)&&Required('Holder name',holderName) &&
                        Required('Insurance Id',insuranceId)&&Required('Expiry Date',expiryDate.toString())
        if(isDone){
            handleConfirmPay({company,insurancePlan,holderName,insuranceId,expiryDate})
        }
    }

    return (
        <Modal className="insurance-pay-modal-comp" show={show} centered onHide={()=>setShow(false)} animation={false}>
                <Modal.Header style={{borderBottom:'none'}} closeButton>
                    
                </Modal.Header>
                <Modal.Body className="py-1 pb-4">
                    <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                        <h1 style={{fontFamily:'CocoSharp bold'}}>Insurance Details</h1>
                        <hr /> 
                    </div>
                    <InsuranceForm 
                        companyVal={company}
                        setCompany={(val)=>setCompany(val)}

                        insurancePlanVal={insurancePlan}
                        setInsurancePlan={(val)=>setInsurancePlan(val)}

                        holderNameVal={holderName}
                        setHolderName={(val)=>setHolderName(val)}

                        insuranceIdVal={insuranceId}
                        setInsuranceId={(val)=>setInsuranceId(val)}

                        expiryDateVal={expiryDate}
                        setExpiryDate={(val)=>setExpiryDate(val)}
                    />

                    <button className="pd-insurance-pay-btn" onClick={handleConfirmPayment}>Confirm</button>
                </Modal.Body>
            </Modal>
    )
}

