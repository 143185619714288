import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../assets/logo.png";
import Avatar from 'react-avatar';
import Skeleton from 'react-loading-skeleton'
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import PinkBtn from "./PinkBtn";
import {baseURL, Decrypt, headers} from '../Config'
import { Link, NavLink, useHistory } from "react-router-dom";
import DropDown from './DropDown'

function Header() {

  const history = useHistory()

  const [openHeader, setOpenHeader] = useState(false);

  const [image,setImage] = useState('')
  const [status,setStatus] = useState(0)
  const [userType,setUserType] = useState('')
  const [childStatus,setChildStatus] = useState(null)
  const [isLoggedIn,setIsLoggedIn] = useState(false)
  const [token,setToken] = useState('')
  const [path,setPath] = useState('')


  useEffect(()=>{
    const data = JSON.parse(localStorage.getItem('bx_user_token'))

    if(data){
        setToken(data.accessToken)
        setIsLoggedIn(true)

        if(Decrypt(data.userType) === 'BCBA'){
            
            setStatus(null)
            setUserType(Decrypt(data.userType))

            fetch(baseURL+'/api/getbcbaById',{
                method:'Post',
                body:JSON.stringify({uid:data.uid}),
                headers:{...headers,'authorization':'Bearer ' + data.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Success' && res2.message.length > 0){
                    const dt = res2.message[0]
                    setImage(Decrypt(dt.image))

                    if(token&&userType === 'BCBA'&&isLoggedIn){
                        setPath('/bcba-dashboard')
                    }
                    else{
                        setPath('')
                    }
                }
                else{
                    handleLogout()
                }
            })
        }
        else if(Decrypt(data.userType) === 'BC'){
            
            setStatus(null)
            setUserType(Decrypt(data.userType))

            fetch(baseURL+'/api/getClinicianById',{
                method:'Post',
                body:JSON.stringify({uid:data.uid}),
                headers:{...headers,'authorization':'Bearer ' + data.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Success' && res2.message.length > 0){
                    const dt = res2.message[0]
                    setImage(Decrypt(dt.image))

                    if(token&&userType === 'BC'&&isLoggedIn){
                        setPath('/bc-dashboard')
                    }
                    else{
                        setPath('')
                    }
                }
                else{
                    handleLogout()
                }
            })
        }
        else if(Decrypt(data.userType) === 'RBT'){
            
            setStatus(null)
            setUserType(Decrypt(data.userType))

            fetch(baseURL+'/api/getRbtById',{
                method:'Post',
                body:JSON.stringify({uid:data.uid}),
                headers:{...headers,'authorization':'Bearer ' + data.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Success' && res2.message.length > 0){
                    const dt = res2.message[0]
                    setImage(Decrypt(dt.image))

                    if(token&&userType === 'RBT'&&isLoggedIn){
                        setPath('/rbt-appointments')
                    }
                    else{
                        setPath('')
                    }
                }
                else{
                    handleLogout()
                }
            })
        }
        else{

            setStatus(Decrypt(data.userStatus))
            if(Object.entries(data).length > 4){
                setChildStatus(Decrypt(data.childStatus))
            }


            fetch(baseURL+'/api/getParentById',{
                method:'Post',
                body:JSON.stringify({uid:data.uid}),
                headers:{...headers,'authorization':'Bearer ' + data.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                
                if(res2.code === 'Success' && res2.message.length > 0){
                    const dt = res2.message[0].obj
                    setImage(Decrypt(dt.image))

                    if(token&&parseInt(status) >= 1&&isLoggedIn){
                        setPath('/dashboard-home')
                    }
                    else if(token&&parseInt(status) === 0&&parseInt(childStatus) === 0&&isLoggedIn){
                        setPath('/payment-detail')
                    }
                    else if(token&&parseInt(status) === 0&&parseInt(childStatus) === 1&&isLoggedIn){
                        setPath('/pre-call-questionnaire')
                    }
                    else if(token&&parseInt(status) === 0&&parseInt(childStatus) === 2&&isLoggedIn){
                        setPath('/schedule-first-meeting')
                    }
                    else{
                        setPath('')
                    }
                }
                else{
                    handleLogout()
                }
            })
        }
    }
  })

  const handleLogout = () =>{
      localStorage.removeItem('bx_user_token')
      setToken('')
      setStatus(null)
      setChildStatus(null)
      setIsLoggedIn(false)
  }


  return (
    <div className="border-[1px] border-solid border-[#E9E9E9] flex justify-center items-center w-full bg-white px-5 fixed z-50 top-0 left-0">
      <div className="flex justify-between items-center h-[100px] w-full max-w-[1440px]">
        <Link to={"/"}>
          <img src={logo} alt="" />
        </Link>
        <div
          className={`lg:bg-white lg:w-auto lg:p-0 lg:h-auto flex lg:justify-center lg:items-center gap-6 lg:static fixed flex-col lg:flex-row  top-0 item-center text-center justify-center sm:items-start py-20 w-screen sm:w-[355px] bg-lightGreen-100 text-white h-screen sm:justify-start px-5 transition-all ${
            openHeader ? "right-0" : "-right-full"
          }`}
        >
          <NavLink
            className={({ isActive }) =>
              isActive
                ? "lg:text-sm font-bold text-brand"
                : "text-gray-500 lg:text-sm font-normal text-lg "
            }
            to={"/"}
            onClick={() => setOpenHeader(false)}
          >
            Home
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive
                ? "lg:text-sm font-bold text-brand"
                : "text-gray-500 lg:text-sm font-normal text-lg "
            }
            to={"/Works"}
            onClick={() => setOpenHeader(false)}
          >
            How It Works?
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive
                ? "lg:text-sm font-bold text-brand"
                : "text-gray-500 lg:text-sm font-normal text-lg "
            }
            to={"/Pediatricians"}
            onClick={() => setOpenHeader(false)}
          >
            For Pediatricians
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive
                ? "lg:text-sm font-bold text-brand"
                : "text-gray-500 lg:text-sm font-normal text-lg "
            }
            to={"/Autism"}
            onClick={() => setOpenHeader(false)}
          >
            Science of Autism Treatment
          </NavLink>
          {/* <NavLink
            className={({ isActive }) =>
              isActive
                ? "lg:text-sm font-bold text-brand"
                : "text-gray-500 lg:text-sm font-normal text-lg "
            }
            onClick={() => setOpenHeader(false)}
            to={"/About"}
          >
            About Us
          </NavLink> */}

          <DropDown
            title={"About Us"}
            linkClass="text-gray-500 lg:text-sm font-normal text-lg"
            options={[
                {
                    route: "/About",
                    name: "Our Story"
                },
                {
                    route: "/Team",
                    name: "Our Team"
                },
                {
                    route: "/Career",
                    name: "Careers"
                },
                {
                    route: "/Contact",
                    name: "Contact Us"
                },
            ]}
          />

         
          {
              isLoggedIn?
                <div className="lg:flex justify-center items-center gap-5 lg:hidden">
                  <div style={{display:'flex',alignItems:'center',marginBottom:40}}>
                      {
                          image ?
                              <Avatar src={image} size="30" round={true} style={{border:'solid 1px gray'}} />
                          :
                              <Skeleton width="30px" height="30px" style={{borderRadius:'50%'}} />
                      }
                      <NavLink to={path || '#'} className='text-gray-500 text-sm capitalize font-bold ml-2'>
                          DASHBOARD
                      </NavLink>
                  </div>
                  <PinkBtn 
                    text={"SIGN OUT"}
                    onClick={handleLogout}
                  />
                </div>
              :
                <div className="flex justify-center items-center gap-5 lg:hidden ">
                    <NavLink
                      className="text-gray-500 text-sm capitalize font-bold"
                      to={"/login"}
                      onClick={() => setOpenHeader(false)}
                    >
                      LOGIN
                    </NavLink>
                    <PinkBtn 
                      text={"SIGN UP"}
                      url="signup"
                    />
                </div>
          }
          
        </div>

        {
              isLoggedIn?
                <div className="lg:flex justify-center items-center gap-5 hidden">
                  <div style={{display:'flex',alignItems:'center'}}>
                      {
                          image ?
                              <Avatar src={image} size="30" round={true} style={{border:'solid 1px gray'}} />
                          :
                              <Skeleton width="30px" height="30px" style={{borderRadius:'50%'}} />
                      }
                      <NavLink to={path || '#'} className='text-gray-500 text-sm capitalize font-bold ml-2'>
                          DASHBOARD
                      </NavLink>
                  </div>
                  <PinkBtn 
                    text={"SIGN OUT"}
                    onClick={handleLogout}
                  />
                </div>
              :
                <div className="lg:flex justify-center items-center gap-5 hidden">
                  <NavLink to={"/login"} className="text-gray-500 text-sm capitalize font-bold">
                    LOGIN
                  </NavLink>
                  <PinkBtn 
                    text={"SIGN UP"}
                    url="signup"
                  />
                </div>
        }
        <FontAwesomeIcon
          onClick={() => setOpenHeader((prev) => !prev)}
          icon={openHeader ? faXmark : faBars}
          className="lg:hidden block relative z-4 text-xl cursor-pointer"
        />
      </div>
    </div>
  );
}

export default Header;
