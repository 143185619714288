import React,{useState} from 'react'
import {Modal,Spinner} from 'react-bootstrap'
import {OnlyAlphabets,Required} from '../Validations'
import InputComp from './InputComp';
import DatePickerComp from './DatePickerComp';
import {AiOutlineGoogle,AiFillApple} from 'react-icons/ai'
import { toast } from 'react-toastify';
import './CSS/SelfPayModal.css'

export default function SelfPayModal({show,setShow,handleConfirmPay,...props}) {

    const [method,setMethod] = useState('default')
    const [name,setName] = useState('')
    const [nameErr,setNameErr] = useState(false)
    const [number,setNumber] = useState('')
    const [cvv,SetCVV] = useState('')
    const [expiryDate,setExpiryDate] = useState('')
    const [isChecked,setIsChecked] = useState('')


    const handleConfirmPayment = () =>{
        if(Required('Card Holder Name',name)&&Required('Card Number',number)&&Required('Expiry Date',expiryDate.toString())&&Required('CVV',cvv)&&!nameErr){
            if(isChecked){
                if(name.length < 2){
                    toast.error('Card holder name must be 2 or more characters',{toastId:"self-pay-comp-err-id-1",autoClose:3000})
                }
                else if(number.length < 16){
                    toast.error('Card number must be 16 digits',{toastId:"self-pay-comp-err-id-2",autoClose:3000})
                }
                else if(cvv.length < 3){
                    toast.error('CVV number must be 3 or 4 digits',{toastId:"self-pay-comp-err-id-3",autoClose:3000})
                }
                else{

                    handleConfirmPay()
                }
            }
            else{
                toast.error('Please accept terms & conditions',{toastId:"self-pay-comp-err-id-4",autoClose:3000})
            }
        }
    }

    return (
        <Modal className="self-pay-modal-comp" show={show} centered onHide={()=>setShow(false)} animation={false}>
            <Modal.Header style={{borderBottom:'none'}} closeButton={!props.isShowCloseBtn ? false : true}>
                
            </Modal.Header>
            <Modal.Body className="py-1 pb-4">
                    <div className="self-pay-modal-comp-body">
                        <h1 style={{fontFamily:'CocoSharp bold'}}>Payment</h1>
                        <hr /> 
                        <div className={`Payment-Card-tabs-container ${method === 'google' ? "left" : method === 'apple' ?  "right" : 'default'}`}>
                            <button className={method === 'google' ? "tabs active-tab d-flex justify-content-center align-items-center" :method === 'apple' ? "tabs d-flex justify-content-center align-items-center" : "active-default d-flex justify-content-center align-items-center"} 
                            onClick={() => setMethod(method === 'default' ? 'default' : 'google' )}><AiOutlineGoogle size={20} /> Pay</button>
                            <button className={method === 'apple' ? "tabs active-tab d-flex justify-content-center align-items-center" : "tabs d-flex justify-content-center align-items-center"} 
                            onClick={() => setMethod('apple')}><AiFillApple size={20} /> Apple Pay</button>
                        </div>
                        
                        <div style={{width:'100%',marginTop:25}}>
                            <div className="Payment-Card-row">
                                <InputComp
                                    value={name}
                                    containerStyle={{marginTop:30,marginRight:10}} 
                                    label="Cardholder Name" 
                                    labelStyle={{fontSize:16,fontWeight:600}}
                                    inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
                                    isError={nameErr}
                                    onChange={(e)=>(
                                        OnlyAlphabets(e.target.value) ? 
                                            (setName(e.target.value), setNameErr(true))
                                        : 
                                            (setName(e.target.value), setNameErr(false))
                                    )}
                                />
                                <InputComp
                                    type="number"
                                    value={number}
                                    containerStyle={{marginTop:30}} 
                                    label='Card Number'
                                    labelStyle={{fontSize:16,fontWeight:600}}
                                    inputLength={(e)=>e.target.value = (e.target.value).slice(0,16)}
                                    onChange={(e)=>setNumber(e.target.value)}
                                />
                            </div>
                            <div  className="Payment-Card-row">
                                <DatePickerComp 
                                    onChange={(date)=>setExpiryDate(date)} 
                                    value={expiryDate} 
                                    containerStyle={{marginTop:30,marginRight:10}}  
                                    labelStyle={{fontSize:16,fontWeight:600}}
                                    minDate={new Date()}
                                    label="Expiry Date"
                                    format="y-MM-dd"
                                />
                                <InputComp
                                    type="number"
                                    value={cvv}
                                    containerStyle={{marginTop:30}} 
                                    label="CVV" 
                                    labelStyle={{fontSize:16,fontWeight:600}} 
                                    inputLength={(e)=>e.target.value = (e.target.value).slice(0,4)}
                                    onChange={(e)=>SetCVV(e.target.value)}
                                />
                            </div>
                            <div className="d-flex align-items-center mt-3">
                                    <input 
                                        id="check-box"
                                        type="checkbox" 
                                        checked={isChecked} 
                                        onChange={(e)=>setIsChecked(e.target.checked)}
                                    />
                                    <label className="custom-check-box" for="check-box">
                                        <span className="check-mark"></span>
                                    </label>
                                    <span style={{fontFamily:'CocoSharp bold',marginLeft:10}}>Accept Terms & Conditions</span>
                            </div>
                            
                            <br/>
                            {
                                props.isLoading ?
                                    <button className="Payment-Card-btn">
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        /> 
                                        Loading...
                                    </button>
                                :
                                    <button className="Payment-Card-btn" onClick={handleConfirmPayment}>Confirm</button>
                            }
                        </div>
                    </div>
            </Modal.Body>
        </Modal>
    )
}

