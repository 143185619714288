import React from "react";
import heroImg from "../../assets/Home/hero-img.png";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import heroImgMob from "../../assets/Home/hero.png";
import heroPatt from "../../assets/Home/hero-curve.png";
import PinkBtn from "../../Components/PinkBtn";

function HeroSection() {
  const { text } = useTypewriter({
    words: ["at Home", "at School","Via telehealth","at Convenience"],
    loop: false,
    typeSpeed: 220,
    deleteSpeed: 120,
    delaySpeed: 1200,
  });
  return (
    <div className=" relative mb-20  mt-[100px] flex justify-end items-center bg-lightGreen-100 md:gap-5 gap-9 xl:flex-row flex-col py-6   xl:py-0">
      <div className=" flex justify-center items-center xl:text-left xl:items-start gap-4  px-5 md:px-10 flex-col">
        <h1 className="text-lightGreen-500 font-bold md:text-5xl text-2xl">
          Exceptional Care for Autism
        </h1>
        <h2 className="p-3 bg-white rounded-[24px] rounded-tl-[0px] w-fit text-lightGreen-500 font-extrabold md:text-5xl text-4xl">
          Delivered{" "}
          <span className="text-lightPink-100">
            {text}
            <Cursor />
          </span>
        </h2>
        <p className="text-lightGreen-500 font-normal text-lg 2xl:w-3/5">
          Cognitia offers Scientific & Research validated behavioral healthcare
          to help children and adults navigate autism.
        </p>
        <div className="xl:mt-7 relative z-10">
          <PinkBtn text={"Get Started"} url={"signup"} />
        </div>
      </div>
      <div className="h-full">
        <img className="w-[100%] xl:block hidden" src={heroImg} alt="" />
        <img
          className="w-[100%] rounded-lg xl:hidden block xl:rounded-tl-[50%] xl:rounded-bl-[50%] "
          src={heroImgMob}
          alt=""
        />
      </div>
      <img
        src={heroPatt}
        className="absolute object-cover xl:block hidden bottom-[0%]  left-0 w-full z-[2]"
        alt=""
      />
    </div>
  );
}

export default HeroSection;
