import moment from 'moment'
import 'moment-timezone'

export const getcurrentDateTime = () =>{
    const formattedDT = moment()
    .tz(moment.tz.guess())
    .format()
    return formattedDT;
}

export const getTimeInISO = (dt) =>{
    const formattedDT = moment(dt)
    .tz(moment.tz.guess())
    .format()
    return formattedDT;
}

export const getTimeInLocal = (dt) =>{
    const formattedDT = moment(dt)
    .tz(moment.tz.guess())
    .format()
    
    return formattedDT;
}

export const getTimeInLocal1 = (dt) =>{
    const formattedDT = moment(dt)
    .tz(moment.tz.guess())
    .format('LLLL')
    
    return formattedDT;
}

export const getTimeInLocalString = (dt) =>{
    const formattedDT = moment(dt).tz(moment.tz.guess())
    const newDate = formattedDT.format('LLLL')
    return newDate;
}

export const setTimeOffset = (dt) =>{
    const formattedDT = moment(dt).utcOffset(0)
    const newDate = formattedDT.format('LLLL')
    return newDate;
}


export const convertIntoUtc = (date) =>{
    const formattedDT = moment
    .tz(date,moment.tz.guess()).utc().format()

    return formattedDT;
}

export const convert24To12 = (dt) => {
    const time = dt.split('T')[1].split(':')

    if(parseInt(time[0]) > 12){
        const newTime = parseInt(time[0]) - 12
        return `${newTime.toString().length > 1 ? newTime : '0'+newTime}:${time[1]}PM`
    }
    else if (parseInt(time[0]) === 12){
        return `${time[0]}:${time[1]}PM`
    }
    else{
        return `${time[0]}:${time[1]}AM`
    }
}

export const convert12To24=(time)=>{
    const newTime = time.split(':')
    const meridiam = newTime[1].slice(2,4)
    if(meridiam === 'PM'){
        const newTime1 = parseInt(newTime[0]) + 12
        if(newTime1 === 24)
        {
            return '00:' + newTime[1].slice(0,2)
        }
        else{
            return newTime1 + ':' + newTime[1].slice(0,2)
        }
    }
    else{
        return time.slice(0,5)
    }
}

export const slotsSequence = (slotArr) => {

    const date = new Date()

    if(slotArr&&slotArr.length > 0){
        for(let i=0; i < slotArr.length; i++)
        {
            for(let j=i+1;j<slotArr.length;j++){
                if(moment.duration(moment(getTimeInLocal(slotArr[i])).diff(getTimeInISO(date))) > moment.duration(moment(getTimeInLocal(slotArr[j])).diff(getTimeInISO(date))))
                {
                    const temp = slotArr[i]
                    slotArr[i] = slotArr[j]
                    slotArr[j] = temp

                }
            }
        }
    }

    return slotArr

}

export const timezone = moment.tz.guess()