import React,{useState,useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {baseURL, Encrypt, Decrypt} from '../../Config'
import Skeleton from 'react-loading-skeleton';
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import ParentDashboardNavbar from '../../Components/ParentDashboardNavbar'
import ClinicianDashboardNavbar from '../../Components/ClinicianDashboardNavbar'
import DashboardArticleCard from '../../Components/DashboardArticleCard'
import videoImage from '../../images/VideoImage.png'
import { toast } from 'react-toastify'
import './CSS/AdditionalResources.css'

export default function AdditionalResources() {

    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const [loader,setLoader] = useState([{},{},{},{}])
    const [isLoaded,setIsLoaded] = useState(false)
    const [videos,setVideos] = useState([])
    const [articles,setArticles] = useState([])
    const [userType,setUserType] = useState(null)
    const [therapies,setTherapies] = useState([])

    const sidebarWidth = useSelector(data => data.SidebarReducer.width)

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })

        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
                setUserType(Decrypt(dt.userType))
                fetch(baseURL + '/api/get-addintional-resources',{
                    headers:{'authorization':'Bearer ' + dt.accessToken}
                })
                .then(res=>res.json())
                .then(res2=>{
                    if(res2.code === 'Error'){
                        toast.error('Error',{toastId:'inst-guide-parent-id1'})
                        setIsLoaded(true)
                    }
                    else{
                        const videos = res2.message&&res2.message.filter(dt=>dt.type == 0)
                        const articles = res2.message&&res2.message.filter(dt=>dt.type == 1)
                        const therapies = res2.message&&res2.message.filter(dt=>dt.type == 2)
                        setVideos(videos)
                        setArticles(articles)
                        setTherapies(therapies)
                        setIsLoaded(true)
                    }
                })
            }
    },[])

    return (
        <div className="parent-dashboard-additional-resources">
            <ParentDashboardSideBar active="home" user={userType&&userType === 'BC' ? "BC" :userType === 'BCBA' ? 'BCBA' :userType === 'RBT' ? 'RBT' : 'Parent'} />
            <div style={{marginLeft:windowWidth > 1024 && sidebarWidth}}>
                {
                    userType&&userType === 'Parent' ?
                        <ParentDashboardNavbar active="home" user='Parent' />
                    :
                        <ClinicianDashboardNavbar 
                            active="home" user={userType&&userType === 'BC' ? "BC" :userType === 'BCBA' ? 'BCBA' : 'RBT' }
                            showAppointments={userType === 'BCBA' ? false : true} 
                        />

                }
                <div className="parent-dashboard-additional-resources-body">
                    <h1 style={{fontFamily:'CocoSharp bold'}}>Additional Resources</h1>
                    <h5>You can view the training material.</h5>
                    <div style={{width:'100%',marginTop:72}}>
                        <h2 style={{fontFamily:'CocoSharp bold'}} className="mb-0">Videos</h2>
                        <div className="parent-dashboard-additional-resources-videos">
                            {
                                isLoaded ?
                                    videos.length > 0 ?
                                        videos.map(dt=>{
                                            return <VideoCard 
                                                        Id={dt.Id}
                                                        src={videoImage}
                                                        thumbnail={dt.thumbnail}
                                                        title={dt.title}
                                                        file={dt.file}
                                                        description={dt.description}
                                                        videoCardContainerClass="video-continer" 
                                                    />
                                        })
                                    :
                                        <h4 style={{color:'lightgray'}}>No Data</h4>
                                        
                                :
                                    loader.map(dt=>{
                                        return <Skeleton className="video-continer" />
                                    })
                            }
                        </div>
                    </div>
                    <div style={{width:'100%',marginTop:30}}>
                        <h2 style={{fontFamily:'CocoSharp bold'}} className="mb-0">Articles</h2>
                        <div className="parent-dashboard-additional-resources-notes-section">
                            {
                                isLoaded ?
                                    articles.length > 0 ?
                                        articles.map((dt,i)=>{
                                            return(
                                                <DashboardArticleCard 
                                                    title={dt.title}
                                                    thumbnail={dt.thumbnail}
                                                    file={dt.file}
                                                />
                                            )
                                        })
                                    :
                                        <h4 style={{color:'lightgray'}}>No Data</h4>
                                    
                                :
                                    loader.map(dt=>{
                                        return <Skeleton width={200} height={200} style={{marginTop:20,borderRadius:15,marginRight:20}} />
                                    })
                            }
                        </div>
                    </div>
                    <div style={{width:'100%',marginTop:30}}>
                        <h2 style={{fontFamily:'CocoSharp bold'}} className="mb-0">Therapy in Action</h2>
                        <div className="parent-dashboard-additional-resources-videos">
                            {
                                isLoaded ?
                                    therapies.length > 0 ?
                                        therapies.map((dt,i)=>{
                                            return(
                                                <VideoCard 
                                                    Id={dt.Id}
                                                    src={videoImage}
                                                    thumbnail={dt.thumbnail}
                                                    title={dt.title}
                                                    file={dt.file}
                                                    description={dt.description}
                                                    videoCardContainerClass="video-continer" 
                                                />
                                            )
                                        })
                                    :
                                        <h4 style={{color:'lightgray'}}>No Data</h4>
                                    
                                :
                                    loader.map(dt=>{
                                        return <Skeleton width={200} height={200} style={{marginTop:20,borderRadius:15,marginRight:20}} />
                                    })
                            }
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}


const VideoCard = (props) => {
    return(
        <div>
            <div className={props.videoCardContainerClass} style={{backgroundImage:`url(${props.thumbnail})`,backgroundPosition:'center',backgroundSize:'cover'}}>
                <Link to={{pathname:`/watch-video/${Encrypt(`${JSON.stringify({title:props.title,des:props.description,url:props.file,comp:1})}`)}`}}>
                    <img src={props.src} style={{objectFit:'cover'}} />
                </Link>
            </div>
            <p style={{fontSize:16,fontFamily: 'CocoSharp regular',marginTop:5,marginLeft:5}}>{props.title&&props.title.length > 24 ? props.title.slice(0,24) + '...' : props.title}</p>
        </div>
    )
}