export const onChangeAnyMedicationForm2 = (data)=>{
    return (dispatch)=>{
        dispatch({type:'onChangeAnyMedicationForm2',payload:data})
    }
}

export const onChangeSmokeForm2 = (data)=>{
    return (dispatch)=>{
        dispatch({type:'onChangeSmokeForm2',payload:data})
    }
}

export const onChangeAlcoholicBeveragesSelectForm2 = (data)=>{
    return (dispatch)=>{
        dispatch({type:'onChangeAlcoholicBeveragesSelectForm2',payload:data})
    }
}

export const onChangeAlcoholicBeveragesForm2 = (data)=>{
    return (dispatch)=>{
        dispatch({type:'onChangeAlcoholicBeveragesForm2',payload:data})
    }
}

export const onChangeUseDrugForm2 = (data)=>{
    return (dispatch)=>{
        dispatch({type:'onChangeUseDrugForm2',payload:data})
    }
}

export const onChangeCompilcationExpDuringPregForm2 = (data)=>{
    return (dispatch)=>{
        dispatch({type:'onChangeCompilcationExpDuringPregForm2',payload:data})
    }
}

export const onChangeBabyWasBornForm2 = (data)=>{
    return (dispatch)=>{
        dispatch({type:'onChangeBabyWasBornForm2',payload:data})
    }
}

export const onChangeComplicationDuringDeliveryForm2 = (data)=>{
    return (dispatch)=>{
        dispatch({type:'onChangeComplicationDuringDeliveryForm2',payload:data})
    }
}


export const onChangeChildBirthWeightForm2 = (data)=>{
    return (dispatch)=>{
        dispatch({type:'onChangeChildBirthWeightForm2',payload:data})
    }
}


export const onChangeChildBirthProblemForm2 = (data)=>{
    return (dispatch)=>{
        dispatch({type:'onChangeChildBirthProblemForm2',payload:data})
    }
}

export const onChangePostDeliveryComplicationForm2 = (data)=>{
    return (dispatch)=>{
        dispatch({type:'onChangePostDeliveryComplicationForm2',payload:data})
    }
}

export const onChangeDevelopmentMilestoneForm2 = (data)=>{
    return (dispatch)=>{
        dispatch({type:'onChangeDevelopmentMilestoneForm2',payload:data})
    }
}

export const onChangeAgeSingleWordForm2 = (data)=>{
    return (dispatch)=>{
        dispatch({type:'onChangeAgeSingleWordForm2',payload:data})
    }
}

export const onChangeAgePhrasesForm2 = (data)=>{
    return (dispatch)=>{
        dispatch({type:'onChangeAgePhrasesForm2',payload:data})
    }
}