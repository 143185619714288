import React,{useState} from 'react'
import './CSS/TextAreaComp.css'

export default function TextAreaComp(props) {
    const [focus,setFocus] = useState(false)
    return (
        <fieldset className={focus ? "fld_set_fcs" : "fld_set"} style={props.containerStyle}>
            <legend className={focus ? "legend1" : "legend"} style={props.labelStyle}>{props.label}</legend>
            <textarea 
                onFocus={()=>setFocus(true)} 
                onBlur={()=>setFocus(false)}  
                disabled={props.disabled}
                onChange={(e)=>props.onChange(e)}
                onInput={(e)=>props.inputLength(e)}
                value={props.value}
                rows={props.rows || 5} 
                className="text-area form-control"
                style={{resize:'none'}}
                ></textarea>
        </fieldset>
    )
}
