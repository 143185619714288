import React, { useState } from 'react';
import {Spin,Popover} from 'antd'
import MicRecorder from 'mic-recorder-to-mp3'
import {VscStopCircle} from 'react-icons/vsc';
import sendIcon from '../../icons/send-message-icon.svg';
import voiceIcon from '../../icons/voice-icon.svg';
import attachmentIcon from '../../icons/attachment-icon.svg';
import './CSS/ChatFooterComp.css'
import { toast } from 'react-toastify';


const recorder = new MicRecorder({
  bitRate: 128
});

export default function ChatFooterComp({handleSendMessage,handleSendImageMessage,handleSendFileMessage,handleSendVoiceMessage,handleOnFocus,handleUnFocus}) {

  const popOverContent = (
    <div style={{display:'flex',flexDirection:'column'}}>
        <div style={{marginBottom:0}}>
            <input type="file" value="" id="file" accept="image/*" onChange={(e)=>handleSendImageMessage(e.target.files[0])} hidden />
            <label htmlFor="file" style={{fontSize:14,textDecoration:'underline',cursor:'pointer'}}>Upload Image</label>
        </div>

        <div>
            <input type="file" value="" id="file1" accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={(e)=>handleSendFileMessage(e.target.files[0])} hidden />
            <label htmlFor="file1" style={{fontSize:14,textDecoration:'underline',cursor:'pointer'}}>Upload File</label>
        </div>
    </div>
  )

  const [text,setText] = useState('')
  const [isRecording,setIsRecording] = useState(false)


  const handleSubmit = (e) => {
    e.preventDefault()
    handleSendMessage(text)
    setText('')
  }

  const handleStartRecording = async () =>{
    try{
      await recorder.start()
      setIsRecording(true)
    }
    catch(err){
      if(err?.message == 'Requested device not found'){
        toast.error('Please plugin microphone',{toastId:'microphone-err1',autoClose:3000})

      }
    }
  }

  const handleStopRecording = () =>{
    recorder
    .stop()
    .getMp3().then(([buffer, blob]) => {
        const file = new File(buffer, 'me-at-thevoice.mp3', {
            type: blob.type,
            size:blob.size,
            lastModified: Date.now()
        });

        // setRecordedVoice(URL.createObjectURL(file))
        // setRecordedFile(file)
        handleSendVoiceMessage(file)
        setIsRecording(false)
    })
  }

  return (
        <div className='chat-footer-container'>
            <div className='chat-footer-attachment-icon'>
              <Popover content={popOverContent} trigger="click">
                <img src={attachmentIcon} style={{cursor:'pointer'}} />
              </Popover>
            </div>
            <div className='chat-footer-voice-icon'>
              {
                isRecording ?
                  <div onClick={handleStopRecording} style={{cursor:'pointer'}}>
                      <VscStopCircle size={25} color="#6588DE"  />
                  </div>
                :
                  <div onClick={handleStartRecording} style={{cursor:'pointer'}}>
                    <img src={voiceIcon} />
                  </div>
              }
            </div>
            <form onSubmit={handleSubmit} className='chat-footer-input-container'>
              <input 
                  type='text'
                  placeholder='Type here...'
                  value={text}
                  onChange={(e)=>setText(e?.target.value)}
                  onFocus={handleOnFocus}
                  onBlur={handleUnFocus}
              />
              <button onClick={handleSubmit} className='send-message-btn' style={{backgroundColor:text?.length > 0 ? '#096E82' :'rgba(9, 110, 130,0.4)'}}>
                  Send
                  <img src={sendIcon} style={{marginLeft:10}} />
              </button>
            </form>
        </div>

  )
}
