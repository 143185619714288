import NotificationsState from '../States/NotificationsState'

const NotificationReducer = (state=NotificationsState,action)=>{
    switch(action.type){
        case 'GetNotifications':
        {
            state.notifications = action.payload
            return state
        }
        case 'NewNotifications':
        {
            let newArr = state?.notifications
            newArr.push(action.payload)
            state.notifications = newArr
            return state
        }
        default:
        {
            return state
        }
    }
}

export default NotificationReducer;