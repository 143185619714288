import MeetingState from '../States/MeetingState'

const MeetingReducer = (state=MeetingState,action)=>{
    switch(action.type){
        case 'GetMeeting':
        {
            state.data = action.payload
            return state
        }
        case 'ExtendMeeting':
        {
            state.data.duration = action?.payload?.duration
            return state
        }
        case 'ExtendCall':
        {
            state.isExtend = action.payload
            return state
        }
        default :
        {
            return state
        }
    }
}

export default MeetingReducer;