import React,{useEffect,useState} from 'react'
import { Link } from 'react-router-dom'
import { HiOutlineDownload } from 'react-icons/hi';
import { RiArrowDropRightLine } from 'react-icons/ri';
import './CSS/DashboardChildCard.css'

export default function DashboardChildCard(props) {

    const [dobirth,setDOB] = useState('')


    useEffect(()=>{
        const dob = new Date(props.dob)
        const newDate = new Date()
        const diffTime = Math.abs(newDate - dob);
        const diffDays = Math.ceil(diffTime / (1000*60*60*24)); 

        if(diffDays < 30){
            if( diffDays === 1 ){
                setDOB(diffDays + ' ' + 'Day')
            }
            else{
                setDOB(diffDays + ' ' + 'Days')
            }
        }
        else if(diffDays < 365){
            if( Math.round(diffDays/30) === 1 ){
                setDOB(Math.round(diffDays/30) + ' ' + 'Month')
            }
            else{
                setDOB(Math.round(diffDays/30) + ' ' + 'Months')
            }
        }
        else{
            if(Math.round(diffDays/365) === 1){
                setDOB(Math.round(diffDays/365) + ' ' + 'Year')
            }
            else{
                setDOB(Math.round(diffDays/365) + ' ' + 'Years')
            }
        }
    },[])
    

    return (
        <div className="dashboard-child-card-container">
            <div>
                {props.isParent&&<p style={{fontFamily:'CocoSharp bold',fontSize:16,marginBottom:5}}>Parent: <span style={{color:'#F99B9B'}}>{props.isParent&&props.parentName.length > 10 ? props.parentName.slice(0,12) + '...' : props.parentName}</span></p>}
                <div className="d-flex align-items-center">
                    <div style={{height:50,width:50,borderRadius:'50%',backgroundImage:`url(${props.image})`,backgroundSize:'cover',backgroundRepeat:'no-repeat',backgroundPosition:'center'}}>
                    </div>
                    <div className="d-flex flex-column justify-content-center ml-2">
                        <p style={{fontFamily:'CocoSharp bold',color:'#096E82',fontSize:18,margin:0}}>{props.childName&&props.childName.length > 10 ? props.childName.slice(0,10) + '...' : props.childName}</p>
                        <p style={{fontSize:16,margin:0,lineHeight:0.5,color:'#096E82',fontFamily:'CocoSharp regular'}}>{dobirth}</p>
                    </div>
                </div>
            </div>
            
            
            <div style={{wordWrap:'break-word'}}>
                <p style={{fontSize:14,margin:0,lineHeight:1.2,marginTop:0,fontFamily:'CocoSharp bold',color:'#096E82'}}>{props.otherInfo ? props.otherInfo.slice(0,70)+'...' : 'No description'}</p>
            </div>
            <Link to={`${props.path}/${props.Id}`} style={{fontFamily:'CocoSharp bold',color:'#F99B9B',marginTop:5}} className="d-flex align-items-center">Explore Now <RiArrowDropRightLine size={30} /></Link>
        </div>
    )
}
