import React from 'react'
import { Row,Col } from 'react-bootstrap'
import './CSS/RatingFormComp.css'

export default function RatingFormComp({title,text1,text2,text3,val1,val2,setVal1,setVal2}) {

    return (
        <div className="rating-form-fld-container">
            <h2 className="rating-form-fld-title">{title}</h2>
            <Row className="rating-form-fld-row p-0 m-0">
                <Col xl={3} lg={3} md={3} className="rating-form-fld-col py-3 px-2 m-0">{text1}</Col>
                <Col xl={3} lg={3} md={3} className="rating-form-fld-col py-3 px-2 m-0">{text2}</Col>
                <Col xl={4} lg={4} md={4} className="rating-form-fld-col py-3 px-2 m-0">{text3}</Col>
                <Col xl={1} lg={1} md={1} className="rating-form-fld-col-4 py-0 px-2 m-0">
                    <p>Dichotomous <br /> 1/3</p>
                    <input
                        type="number"
                        min="1"
                        max="3"
                        value={val1}
                        onChange={(e)=>setVal1(e.target.value)}
                        onInput={(e)=>e.target.value = (e.target.value).slice(0,1)}
                    />
                </Col>
                <Col xl={1} lg={1} md={1} className="rating-form-fld-col-5 py-0 px-1 m-0">
                    <p>Likert <br /> 1/2/3</p>   
                    <input 
                        type="number" 
                        min="1"
                        max="3"
                        value={val2}
                        onChange={(e)=>setVal2(e.target.value)}
                        onInput={(e)=>e.target.value = (e.target.value).slice(0,1)} 
                    />
                </Col>
            </Row>
        </div>
    )
}
