import React from 'react'
import {useHistory} from 'react-router-dom'
import haveDiagnosis from '../images/haveDiagnosis.png'
import dontHaveDiagnosis from '../images/dontHaveDiagnosis.png'
import './CSS/ChildCondition.css'

export default function ChildCondition({path1,path2}) {

    const history = useHistory()

    return (

            <div className="child-condition-main-container">
                <h1>Better Care At Home</h1>
                <hr />
                <div className="child-condition-cards-container">
                    <div className="child-condition-card-1" onClick={()=>history.push(path1)}>
                        <img src={haveDiagnosis} className="img-fluid" />
                        <div style={{position:'absolute',bottom:20,left:20}}>
                            <p>Have an autism diagnosis?</p>
                            <h3>Training Program</h3>
                        </div>
                    </div>
                    <div className="child-condition-card-2" onClick={()=>history.push(path2)}>
                        <img src={dontHaveDiagnosis} className="img-fluid" />
                        <div style={{position:'absolute',bottom:20,left:20}}>
                            <p>Don’t have a diagnosis?</p>
                            <h3>Autism Diagnosis</h3>
                        </div>
                    </div>
                </div>
            </div>
    )
}
