import React,{useState,useEffect} from 'react'
import { Spinner } from 'react-bootstrap'
import {useSelector} from 'react-redux'
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import ClinicianDashboardNavbar from '../../Components/ClinicianDashboardNavbar'
import CheckboxComp from '../../Components/CheckboxComp'
import RatingFormComp from '../../Components/AssessmentToolComponents/RatingFormComp'
import './CSS/RatingForm.css'
import { baseURL, headers } from '../../Config'
import { toast } from 'react-toastify'


export default function RatingForm(props) {

    const therapistData = useSelector(data => data.ParentReducer.parentData)

    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const [isForce,setIsForce] = useState('Absent')
    const [recommend,setRecommend] = useState('Absent')
    const [impression,setImpression] = useState('Completely uncertain')
    const [totalScore,setTotalScore] = useState(0)
    const [isLoading,setIsLoading] = useState(false)
    const [formQuestions,setFormQuestions] = useState([
        {
            Id:1,
            title:'Socially directed speech and sounds',
            text1:'Child often uses words or other vocalizations for a variety of social purposes (e.g. requesting, protesting, directing attention, sharing enjoyment).',
            text2:'Inconsistent socially directed speech.',
            text3:'Most of the child’s sounds are self-directed. May make atypical non-word noises (e.g., “digga digga”).',
            val1:"",
            val2:"",
            db_name:'speech_Likert',
            db_name1:'speech_dichotomous'
        },
        {
            Id:2,
            title:'Frequent and flexible eye contact',
            text1:'Child frequently makes eye contact with others and across a variety of activities.',
            text2:'Child’s eye contact seems inconsistent. Gaze seems less flexible and harder to catch than expected.',
            text3:'Child infrequently makes eye contact. Might only make eye contact during one activity (e.g., asking for help).',
            val1:"",
            val2:"",
            db_name:'eye_contact_Likert',
            db_name1:'eye_contact_dichotomous'
        },
        {
            Id:3,
            title:'Unusual vocalizations',
            text1:'No unusual qualities of speech/language observed. Most of child’s speech is appropriate for the child’s age and developmental level.',
            text2:'Speech is not clearly unusual, but there are some differences (e.g., volume, slight repetitive quality of speech/language, unclear echoing, some occasional sounds that are unusual).',
            text3:'Child produces unusual jargon, sounds, or speech/language (e.g., undirected jargoning, speech of peculiar intonation, unusual sounds, repetitive vocalizations, echoing or repetitive speech/language.',
            val1:"",
            val2:"",
            db_name:'vocalizations_Likert',
            db_name1:'vocalizations_dichotomous'
        },
        {
            Id:4,
            title:'Unusual or repetitive play',
            text1:'Child plays with toys in appropriate ways (uses toys as expected). ',
            text2:'Child’s play is not clearly unusual, but child is strongly focused on some toys, routines, or activities. May sometimes be hard to shift child’s attention to something new',
            text3:'Child shows clearly repetitive or unusual play, such as repeatedly pushing buttons, watching how objects move, lining things up, or scrambling/dropping toys.',
            val1:"",
            val2:"",
            db_name:'repetitive_play_Likert',
            db_name1:'repetitive_play_dichotomous'
        },
        {
            Id:5,
            title:'Unusual or repetitive body movements',
            text1:'No unusual or repetitive body movements seen.',
            text2:'Unclear unusual/repetitive body movements. Some repetitive jumping or very brief posturing of fingers, hands, or arms that is not clearly atypical.',
            text3:'Child clearly shows unusual or repetitive (e.g., hand-flapping, posturing or tensing upper body, toe-walking, facial grimacing, hand/finger mannerisms) repetitive running/ walking/spinning/jumping.',
            val1:"",
            val2:"",
            db_name:'body_movements_Likert',
            db_name1:'body_movements_dichotomous'
        },
        {
            Id:6,
            title:'Combines gestures, eye contact, and speech/ vocalization',
            text1:'Child frequently points and uses other gestures to communicate. Child’s gestures are usually combined with vocalizations and eye contact. ',
            text2:'Child may sometimes point or use other gestures, but less than expected. Child does not always look at you or make a sound when gesturing.',
            text3:'Child does not usually gesture to communicate. May sometimes reach or point, but does not usually combine these with eye gaze or sounds. May move your hand or push on your body to get help',
            val1:"",
            val2:"",
            db_name:'combines_gestures_Likert',
            db_name1:'combines_gestures_dichotomous'
        },
        {
            Id:7,
            title:'Unusual sensory exploration or reaction',
            text1:'No unusual sensory behavior observed.',
            text2:'Unclear sensory exploration or reaction. May have a brief response to a sound, smell, or how something feels or moves.',
            text3:'Child shows sensory differences. May closely inspect objects, overreact to sounds, show intense interest or dislike to textures (e.g., touching, licking, biting, refusing to touch specific toys), or clear self-injurious behavior.',
            val1:"",
            val2:"",
            db_name:'reaction_Likert',
            db_name1:'reaction_dichotomous'
        }
    ])
    const sidebarWidth = useSelector(data => data.SidebarReducer.width)

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })

        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            fetch(baseURL + '/api/getChildRatingFormData?Id='+props?.match?.params?.id,{
                headers:{'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2?.code === 'Success'){
                    if(res2?.message?.length > 0){
                        
                    }
                }
            })
        }
    })

    const handleSubmitForm = () =>{

        const dt = JSON.parse(localStorage.getItem('bx_user_token'))

        const obj = {
            Id:'',
            speech_dichotomous:parseInt(formQuestions[0]?.val1) || 1,
            speech_Likert:parseInt(formQuestions[0]?.val2) || 1,
            eye_contact_dichotomous:parseInt(formQuestions[1]?.val1) || 1,
            eye_contact_Likert:parseInt(formQuestions[1]?.val2) || 1,
            vocalizations_dichotomous:parseInt(formQuestions[2]?.val1) || 1,
            vocalizations_Likert:parseInt(formQuestions[2]?.val2) || 1,
            repetitive_play_dichotomous:parseInt(formQuestions[3]?.val1) || 1,
            repetitive_play_Likert:parseInt(formQuestions[3]?.val2) || 1,
            body_movements_dichotomous:parseInt(formQuestions[4]?.val1) || 1,
            body_movements_Likert:parseInt(formQuestions[4]?.val2) || 1,
            combines_gestures_dichotomous:parseInt(formQuestions[5]?.val1) || 1,
            combines_gestures_Likert:parseInt(formQuestions[5]?.val2) || 1,
            reaction_dichotomous:parseInt(formQuestions[6]?.val1) || 1,
            reaction_Likert:parseInt(formQuestions[6]?.val2) || 1,
            ASD:isForce,
            in_person_evaluation:recommend,
            diagnostic_impression:impression,
            total_score:parseInt(totalScore) || 0,
            therapist_id:therapistData?.uid,
            child_id:props?.match?.params?.id
        }

        if(props?.match?.params?.id && therapistData?.uid){
            setIsLoading(true)
            fetch(baseURL + '/api/submitRatingForm',{
                method:'Post',
                body:JSON.stringify(obj),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                setIsLoading(false)
                toast.success(res2?.message)
            })
            .catch(err=>{
                setIsLoading(false)
            })
        }
        else{
            toast.error('Something went wrong')
        }
    }

    return (
        <div className="cilinician-db-rating-form-container">
            <ParentDashboardSideBar active="child" user="BC" />
            <div style={{marginLeft:windowWidth > 1024 &&sidebarWidth}}>
                <ClinicianDashboardNavbar />
                <div className="cilinician-db-rating-form-body">
                    <h2 style={{fontFamily:'CocoSharp bold',textAlign:'center'}}>Assessment Rating Form</h2>
                    <p className="rating-form-criteria">Dichotomous score: Is the symptom present or not (1 vs 3)</p>
                    <p className="rating-form-criteria">Likert score : 1 = symptom not present; 2 = symptom present at sub clinical levels; 3 = symptom obviously consistent with AS</p>
                    <br />
                    {
                        formQuestions.map((dt,i)=>{
                            return(
                                    <React.Fragment key={i}>
                                        <RatingFormComp 
                                            title={dt.title} 
                                            text1={dt.text1} 
                                            text2={dt.text2} 
                                            text3={dt.text3}
                                            val1={dt.val1}
                                            val2={dt.val2}
                                            setVal1={(val)=>setFormQuestions(formQuestions.map(dat => (dat.Id === dt.Id ? {...dt,val1:val} : dat)))}
                                            setVal2={(val)=>setFormQuestions(formQuestions.map(dat => (dat.Id === dt.Id ? {...dt,val2:val} : dat)))}
                                        />
                                    </React.Fragment>
                                )
                        })
                    }
                    <div className="clinician-db-rating-form-section2">
                        <div className="clinician-db-rating-form-section2-col1">
                            <h5>ASD if forced to choose?</h5>
                            <br />
                            <CheckboxComp
                                checked={isForce === 'Absent'&&true}
                                label="Absent"
                                onChange={() => setIsForce("Absent")}
                            />
                            <br />
                            <CheckboxComp
                                checked={isForce === 'Present'&&true}
                                label="Present"
                                onChange={() => setIsForce("Present")}
                            />
                            <br />
                            <CheckboxComp
                                checked={isForce === 'Unsure'&&true}
                                label="Unsure"
                                onChange={() => setIsForce("Unsure")}
                            />
                        </div>
                        <div className="clinician-db-rating-form-section2-col2">
                            <h5>Did you recommend in person evaluation for diagnostic clarification?</h5>
                            <br />
                            <CheckboxComp
                                checked={recommend === 'Absent'&&true}
                                label="Absent"
                                onChange={() => setRecommend("Absent")}
                            />
                            <br />
                            <CheckboxComp
                                checked={recommend === 'Present'&&true}
                                label="Present"
                                onChange={() => setRecommend("Present")}
                            />
                        </div>
                        <div className="clinician-db-rating-form-section2-col3">
                            <h5>How certain are you of your diagnostic impression?</h5>
                            <br />
                            <CheckboxComp
                                checked={impression === "Completely uncertain"&&true}
                                label="Completely uncertain"
                                onChange={() => setImpression("Completely uncertain")}
                            />
                            <br />
                            <CheckboxComp
                                checked={impression === "Somewhat uncertain"&&true}
                                label="Somewhat uncertain"
                                onChange={() => setImpression("Somewhat uncertain")}
                            />
                            <br />
                            <CheckboxComp
                                checked={impression === "Somewhat certain"&&true}
                                label="Somewhat certain"
                                onChange={() => setImpression("Somewhat certain")}
                            />
                            <br />
                            <CheckboxComp
                                checked={impression === "Completely certain"&&true}
                                label="Completely certain"
                                onChange={() => setImpression("Completely certain")}
                            />
                        </div>
                        <div className="clinician-db-rating-form-section2-col4">
                            <h5>Total Score</h5>
                            <input 
                                type="number" 
                                min="1"
                                max="3"
                                value={totalScore}
                                onChange={(e)=>setTotalScore(e.target.value)}
                                onInput={(e)=>e.target.value = (e.target.value).slice(0,3)} 
                            />
                        </div>
                    </div>
                    <div style={{display:'flex',justifyContent:'center',marginTop:50}}>
                        { 
                            isLoading ?
                                <button className="rating-form-btn" style={{marginTop:30,height:55}}>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    {' '}
                                    Loading....
                                </button>
                            :
                                <button className="rating-form-btn" onClick={handleSubmitForm} style={{marginTop:30,height:55}}>Submit Detail</button>
                        }
                    </div>
                </div>
                <div className="assessment-form-mobile" style={{padding:'80px 20px',textAlign:'center'}}>
                    <h4 style={{color:'lightgray',margin:0}}>Please open assessment form in desktop or laptop</h4>
                </div>
            </div>
        </div>
    )
}
