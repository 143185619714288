import React from "react";
import img from "../../assets/team/team2.png";
function Monika() {
  return (
    <div className="bg-[#FFEEEF] w-full flex justify-center items-center py-11">
      <div className="max-w-[1440px] flex justify-between flex-col-reverse lg:flex-row items-center px-10 w-full gap-10">
        <img src={img} alt="" />
        <div className="flex justify-center text-justify flex-col items-start gap-1">
          <h1 className="font-bold text-lightGreen-500 text-3xl md:text-5xl">
            Dr. Monika Suchowierska-Stephany
          </h1>
          <h2 className="font-bold text-lightGreen-500 text-xl md:text-2xl">
            Ph.D., BCBA-D
          </h2>
          <h4 className="font-bold text-lightGreen-500 text-md md:text-xl">
            Chief Research Officer
          </h4>
          <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
            Dr. Monika Suchowierska-Stephany is a highly esteemed professional in the field of autism treatment,
            renowned for her expertise and unwavering dedication to supporting children with autism.
            With a Ph.D. from the University of Kansas and the prestigious designation of Board Certified Behavior Analyst-D,
            she brings a wealth of knowledge and experience.
          </p>
          <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
            With over <span className="font-mono">20</span> years of experience, Dr. Suchowierska-Stephany has made a profound impact on the lives of countless individuals and families affected by autism.
            Her extensive career has involved providing evidence-based interventions,
            conducting research, and training professionals in the field.
          </p>
          <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
            Beyond her clinical practice, Dr. Suchowierska-Stephany has contributed significantly to the field of autism through her written works. She has authored several books,
            sharing her expertise and insights to empower parents, caregivers,
            and practitioners with practical strategies and knowledge.
            Her publications have become valuable resources in the autism community,
            enhancing understanding and promoting effective interventions.
          </p>
          <p className="font-normal text-lightGreen-500 text-lg lg:max-w-[680px]">
            Dr. Suchowierska-Stephany’s passion for working with children with autism is evident in every aspect of her work.
            She possesses a deep understanding of the unique needs and challenges faced by individuals on the autism spectrum.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Monika;
