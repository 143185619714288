import React,{useState,useEffect} from 'react'
import {useSelector,useDispatch} from 'react-redux'
import {baseURL, Decrypt, Encrypt, headers} from '../../Config'
import Skeleton from 'react-loading-skeleton';
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import ParentDashboardNavbar from '../../Components/ParentDashboardNavbar'
import ClinicianDashboardNavbar from '../../Components/ClinicianDashboardNavbar'
import DashboradAppointmentCard from '../../Components/DashboradAppointmentCard'
import {GetParentAppointments,GetClinicianAppointments} from '../../Store/Actions/AppointmentActions'
import { toast } from 'react-toastify'
import {Link} from 'react-router-dom'
import './CSS/Appointments.css'

export default function Appointments() {

    const dispatch = useDispatch()

    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const [loader,setLoader] = useState([{},{},{},{}])
    const [userType,setUserType] = useState(null)

    const sidebarWidth = useSelector(data => data.SidebarReducer.width)
    const upcomingApp = useSelector(data => data.AppointmentReducer.upcomingApp)
    const previousApp = useSelector(data => data.AppointmentReducer.previousApp)
    const isLoaded = useSelector(data => data.AppointmentReducer.isLoaded)


    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })

        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            setUserType(Decrypt(dt.userType))
            dispatch(
                Decrypt(dt.userType) === 'Parent' ? 
                    GetParentAppointments() 
                :Decrypt(dt.userType) === 'BC' ?
                    GetClinicianAppointments()
                :
                    GetClinicianAppointments()
            )
        }
    },[])


    return (
        <div className="dashboard-appointments-container">
            <ParentDashboardSideBar active="appointments" user={userType&&userType === 'BC' ? "BC" :userType === 'BCBA' ? 'BCBA' : 'Parent'} />
            <div style={{marginLeft:windowWidth > 1024 && sidebarWidth}}>
                {
                    userType&&userType === 'Parent' ?
                        <ParentDashboardNavbar active="appointments" user='Parent' />
                    :
                        <ClinicianDashboardNavbar 
                            active="appointments" user={userType&&userType === 'BC' ? "BC" : 'BCBA'}
                            showAppointments={userType === 'BCBA' ? false : true}
                        />
                }
                <div className="dashboard-appointments-body">
                    <div style={{width:'100%',marginTop:0}}>
                        <h2 style={{fontFamily:'CocoSharp bold'}} className="mb-0">Appointments</h2>
                        <div className="dashboard-appointments-upcoming">
                            {
                                isLoaded ?
                                    upcomingApp&&
                                    upcomingApp.length > 0 ?
                                        upcomingApp.map((dt,i)=>{
                                            return(
                                                <React.Fragment key={i}>
                                                    <DashboradAppointmentCard
                                                        Id={dt.Id}
                                                        title="Scheduled-Call"
                                                        dateAndtime={dt.schedulingDateTime}
                                                        status="Upcoming"
                                                        user={userType&&userType === 'BC' ? "BC" : 'Parent'}
                                                    />
                                                </React.Fragment>
                                            )
                                        })
                                    :
                                        <div style={{width:'100%'}}>
                                            <h5 style={{color:'lightgray',marginTop:20}}>No Upcoming Appointments</h5>
                                        </div>
                                :
                                    loader.map(dt=>{
                                        return <Skeleton width={220} height={170} style={{marginTop:35,borderRadius:8,marginRight:15}} />
                                    })
                                    
                            }
                            
                        </div>
                    </div>
                    <div style={{width:'100%',marginTop:30}}>
                        <h2 style={{fontFamily:'CocoSharp bold'}} className="mb-0">Appointments History</h2>
                        <div className="dashboard-appointments-history">
                            {
                                isLoaded ?
                                    previousApp&&
                                    previousApp.length > 0 ?
                                        previousApp.map((dt,i)=>{
                                            return(
                                                <React.Fragment key={i}>
                                                    <DashboradAppointmentCard
                                                        title="Previous-Call"
                                                        dateAndtime={dt.schedulingDateTime}
                                                        status={dt.status}
                                                    />
                                                </React.Fragment>
                                            )
                                        })
                                    :
                                        <div style={{width:'100%'}}>
                                            <h5 style={{color:'lightgray',marginTop:20}}>No Appointments History</h5>
                                        </div>
                                :
                                    loader.map(dt=>{
                                        return <Skeleton width={220} height={170} style={{marginTop:35,borderRadius:8,marginRight:15}} />
                                    })
                            }
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}


