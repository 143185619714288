import React from "react";
import icon1 from "../../assets/Home/steps/icon1.svg";
import icon2 from "../../assets/Home/steps/icon2.svg";
import icon3 from "../../assets/Home/steps/icon3.svg";

import icon4 from "../../assets/Home/steps/icon4.svg";
import line from "../../assets/Home/steps/line.svg";
import PinkBtn from "../../Components/PinkBtn";

function Steps({ margin }) {
  return (
    <>
    <div
      className={`overflow-x-hidden w-full flex justify-center items-center ${
        margin ? margin : "mt-[140px] xl:mt-[180px]"
      } px-5`}
    >
      <div className=" flex-col max-w-[1440px] flex justify-center items-center gap-12 w-full box-border relative">
        <h1 className="text-center text-lightGreen-500 leading-9 sm:leading-[50px] font-bold text-[30px] sm:text-[48px]  ">
          Get Started In Four <br /> Simple Steps
        </h1>
        <div className="w-full relative lg:h-[500px] mt-10 lg:mt-[100px] flex justify-center items-center lg:gap-0 gap-10 lg:items-start flex-col lg:flex-row">
          <img src={line} className="w-[85%] lg:block hidden" alt="" />
          <div className="-top-[5%] left-[0%] static lg:absolute flex justify-center flex-col gap-4 items-center text-center max-w-[500px] lg:max-w-[233px]">
            <img src={icon1} alt="" />
            <h2 className="text-lightGreen-500 text-2xl font-bold">
              Digital On-boarding
            </h2>
            <p className="text-gray-500 text-base font-normal">
              We have a simple, fully digitized onboarding process. Start by
              signing up and providing us with information about your child to
              develop an understanding of their needs.
            </p>
          </div>
          <div className="top-[10%] left-[28%] static lg:absolute flex justify-center flex-col gap-4 items-center text-center max-w-[500px] lg:max-w-[233px]">
            <img src={icon2} alt="" />
            <h2 className="text-lightGreen-500 text-2xl font-bold">
              Assessment
            </h2>
            <p className="text-gray-500 text-base font-normal">
              Setup a free call with one of our qualified clinicians who will
              conduct an initial assessment.
            </p>
          </div>
          <div className="-top-[10%] left-[56%] xl:left-[63%] static lg:absolute flex justify-center flex-col gap-4 items-center text-center max-w-[500px] lg:max-w-[233px]">
            <img src={icon3} alt="" />
            <h2 className="text-lightGreen-500 text-2xl font-bold">
              Treatment Planning
            </h2>
            <p className="text-gray-500 text-base font-normal">
              The clinical team will develop a treatment plan and review it with
              you.
            </p>
          </div>
          <div className="top-[20%] right-[0%] static lg:absolute flex justify-center flex-col gap-4 items-center text-center max-w-[500px] lg:w-[233px]">
            <img src={icon4} alt="" />
            <h2 className="text-lightGreen-500 text-2xl font-bold">Therapy</h2>
            <p className="text-gray-500 text-base font-normal">
              Your child will be assigned a care team to provide ongoing therapy
              based on the treatment plan.
            </p>
          </div>
        </div>
        <div className="flex justify-center absolute bottom-14 z-10">
          <PinkBtn text={"Get Started"} url={"signup"} />
        </div>
      </div>
    </div>
    </>
  );
}

export default Steps;
