import React from "react";
import img from "../../assets/team/team6.png";

function Christie() {
  return (
    <div className="bg-[#E8FCFF] w-full flex justify-center items-center py-11">
      <div className="max-w-[1440px] flex justify-between flex-col lg:flex-row items-center px-10 w-full gap-10">
        <div className="flex justify-center flex-col items-start gap-4">
          <h1 className="font-bold text-lightGreen-500 text-3xl md:text-5xl">
            Christine Stiehl
          </h1>
          <h2 className="font-bold text-lightGreen-500 text-xl md:text-2xl">
            M.A., BCBA, LBA
          </h2>
          <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
            Christine is a Board Certified Behavior Analyst (BCBA) with 14 years of experience providing behavior-analytic
            services to individuals with disabilities across the lifespan and functional domains, including severe
            problem behavior and complex verbal behavior
          </p>
          {/* <p className="font-normal text-lightGreen-500 text-lg lg:max-w-[680px]">
            Since <span className="font-mono">2008</span> Vanessa has provided
            in-home, center-based, and school-based support to providers and
            parents of children diagnosed with Autism Spectrum Disorder.
          </p>
          <p className="font-normal text-lightGreen-500 text-lg lg:max-w-[680px]">
            In <span className="font-mono">2015</span> , Vanessa joined the
            Kansas Center for Autism Research and Training at the University of
            Kansas Medical Center where she coordinates the trainings and
            provides clinical services to families of children with ASD and
            other related disorders.
          </p> */}
        </div>
        <img src={img} alt="" />
      </div>
    </div>
  );
}

export default Christie;





// import React from "react";
// import img from "../../assets/team/team6.png";
// function Christie() {
//   return (
//     <div className="bg-[#FFEEEF] w-full flex justify-center items-center py-11">
//       <div className="max-w-[1440px] flex justify-between flex-col-reverse lg:flex-row items-center px-10 w-full gap-10">
//         <img src={img} alt="" />
//         <div className="flex justify-center flex-col items-start gap-1">
//           <h1 className="font-bold text-lightGreen-500 text-3xl md:text-5xl">
//             Christine Stiehl
//           </h1>
//           <h2 className="font-bold text-lightGreen-500 text-xl md:text-2xl">
//             M.A., BCBA, LBA
//           </h2>
//           <h4 className="font-bold text-lightGreen-500 text-md md:text-xl">
//             Behavior Analyst - Clinical Supervisor
//           </h4>
//           <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
//             Christine is a Board Certified Behavior Analyst (BCBA) with 14 years of experience providing behavior-analytic
//             services to individuals with disabilities across the lifespan and functional domains, including severe
//             problem behavior and complex verbal behavior
//           </p>
//           {/* <p className="font-normal text-lightGreen-500 text-lg lg:max-w-[680px]">
//             Since <span className="font-mono">2008</span> Vanessa has provided
//             in-home, center-based, and school-based support to providers and
//             parents of children diagnosed with Autism Spectrum Disorder.
//           </p>
//           <p className="font-normal text-lightGreen-500 text-lg lg:max-w-[680px]">
//             In <span className="font-mono">2015</span> , Vanessa joined the
//             Kansas Center for Autism Research and Training at the University of
//             Kansas Medical Center where she coordinates the trainings and
//             provides clinical services to families of children with ASD and
//             other related disorders.
//           </p> */}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Christie;
