import React from "react";
import img from "../../assets/team/team1.png";
function Linda() {
  return (
    <div className="bg-[#E8FCFF] w-full flex justify-center items-center py-11">
      <div className="max-w-[1440px] flex justify-between flex-col lg:flex-row items-center px-10 w-full gap-10">
        <div className="flex justify-center flex-col items-start gap-1">
          <h1 className="font-bold text-lightGreen-500 text-3xl md:text-5xl">
            Dr. Linda Heitzman-Powell
          </h1>
          <h2 className="font-bold text-lightGreen-500 text-xl md:text-2xl">
            PhD., BCBA-D
          </h2>
          <h4 className="font-bold text-lightGreen-500 text-md md:text-xl">
            Chief Clinical Officer
          </h4>
          {/* <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
            Dr. Heitzmann-Powell is one of the most prominent figures in Autism Research & Training.
          </p> */}
          <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
            Dr. Linda Heitzman-Powell is a highly accomplished professional in the field of autism treatment,
            with the distinguished designation of Board Certified Behavior Analyst-Doctoral (BCBA-D). With a PhD.
            From the University of Kansas and over <span className="font-mono">25</span> years of experience, she has made significant contributions to the field through her clinical expertise,
            research endeavors, and commitment to evidence based practices.
          </p>
          <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
            Throughout her career, Dr.Heitzman - Powell has actively engaged in research to advance the field of autism treatment.
            Her research and academic intrests have focused on parent training,
            evidence based autism treatment and the use of technology to increase access for families of children with autism.

          </p>
          <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
            With her combined expertise as a BCBA - D, researcher,
            and an advocate for evidence based practices, Dr.Heitzman - Powell has become a respected authority in the field of autism treatment.Her dedication to improving the lives of individuals with autism through the application of sound behavioral principles, research,
            and technology has made a significant impact on the field and the individuals she serves.
          </p>
        </div>
        <img src={img} alt="" />
      </div>
    </div>
  );
}

export default Linda;
