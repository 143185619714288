import React, {useEffect} from 'react';
import {Col} from 'react-bootstrap';
import Navbar from '../../../Components/Navbar';
import Footer from '../../../Components/Footer';
import OrderDetailComponent from '../../../Components/OrderDetailComponent';
import {useHistory} from 'react-router-dom';
import './CSS/ConfirmPayment.css';


export default function ConfirmPayment(props) { 

    const history = useHistory()

    
    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])


    return (
        <div style={{width:'100%'}}>
            <Col sm='12' md='12' lg='12' xl='12' >
                <div className="Payment-Confirm-Page-Container">
                    <div className="Payment-Confirm-Page-Body">
                        <h1 style={{fontFamily:'CocoSharp bold'}}>Payment</h1>
                        <hr /> 
                        <OrderDetailComponent path1="/self-pay" path2="/insurance-pay" />
                    </div>
                </div>
            </Col>
        </div>
      
    );
  }
  

  