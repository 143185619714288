import {baseURL,Decrypt,headers} from '../../Config'
import {toast} from 'react-toastify'

export const GetNotifications = () =>{
    
    const data = JSON.parse(localStorage.getItem('bx_user_token'))
    return(dispatch)=>{
        fetch(baseURL+'/api/get-notifications',{
            method:'Post',
            body:JSON.stringify({uid:data.uid}),
            headers:{...headers,'authorization':'Bearer ' + data.accessToken}
        })
        .then(res=>res.json())
        .then(res2=>{
            if(res2.code === 'Success'){
                dispatch({type : 'GetNotifications' , payload:res2.message})
            }
        })
    }
}

export const MarkAsRead = (Id) =>{
    
    const data = JSON.parse(localStorage.getItem('bx_user_token'))
    return(dispatch)=>{
        fetch(baseURL+'/api/readNotification',{
            method:'Put',
            body:JSON.stringify({Id,uid:data.uid}),
            headers:{...headers,'authorization':'Bearer ' + data.accessToken}
        })
        .then(res=>res.json())
        .then(res2=>{
            if(res2.code === 'Success'){
                dispatch({type : 'GetNotifications' , payload:res2.message})
            }
        })
        
    }
}

export const MarkAllAsRead = () =>{
    
    const data = JSON.parse(localStorage.getItem('bx_user_token'))
    return(dispatch)=>{
        fetch(baseURL+'/api/readAllNotifications',{
            method:'Put',
            body:JSON.stringify({uid:data.uid}),
            headers:{...headers,'authorization':'Bearer ' + data.accessToken}
        })
        .then(res=>res.json())
        .then(res2=>{
            if(res2.code === 'Success'){
                dispatch({type : 'GetNotifications' , payload:res2.message})
            }
        })
        
    }
}

