import React,{useState,useEffect} from 'react'
import Calendar from 'react-calendar';
import Skeleton from 'react-loading-skeleton';
import { Button } from 'react-bootstrap';
import { IoIosArrowDown,IoIosArrowUp } from 'react-icons/io';
import {getTimeInISO,getTimeInLocal,convert24To12, slotsSequence} from '../TimeFormate'
import moment from 'moment'
import {baseURL,headers} from '../Config'
import './CSS/CalendarComp.css'

export default function CalenderComp2(props) {

    const [selectedTime, setSelectedTime] = useState('')
    const [showCompleteTime,setShowCompleteTime] = useState(false)
    const [timePeriod,setTimePeriod] = useState([])
    const [isTimeLoaded,setIsTimeLoaded] = useState(true)

    useEffect(()=>{
        setTimePeriod(props.slots)
    },[props.slots])
    
    const handleDateSelect = (date) => {
        const newDate = getTimeInISO(date)
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            setIsTimeLoaded(false)
            fetch(baseURL+ '/api/get-clinician-available-dates',{
                method:'Post',
                body:JSON.stringify({uid:dt.uid,Date:newDate}),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    setIsTimeLoaded(false)
                }
                else{
                    if(res2.message&&res2.message.slots.length > 0){
                        const mySlots = []
                        const sequencedSlot = slotsSequence(res2.message.slots)
                        sequencedSlot.map(dt=>{
                            const local_time = getTimeInLocal(dt)
                            const diff = moment.duration(moment(getTimeInLocal(dt)).diff(getTimeInISO(date)))
                            
                            if(local_time.split('T')[0] === getTimeInISO(date).split('T')[0] && (diff/1000) > 0)
                            {
                                const slot = convert24To12(local_time)
                                mySlots.push(slot)
                            }
                        })
                        props.selectedDate(newDate)
                        setTimePeriod(mySlots)
                        setIsTimeLoaded(true)
                    }else{
                        props.selectedDate(newDate)
                        setTimePeriod([])
                        setIsTimeLoaded(true)
                    }
                }
            })
        }
    }
    

    return (
        <div className="calendar-component">
            <Calendar
                minDate={new Date()}
                onChange={(e)=>handleDateSelect(e)}
                nextLabel="Next"
                prevLabel="Pre"
                calendarType="US"
                tileDisabled={props.tileDisabled}
            />
            <div style={{border:'solid 1px #096E82',borderRadius:10,marginTop:30}}>
                <div className={showCompleteTime ? "calendar-componete-time-accordian-full" : "calendar-componete-time-accordian"}>
                    {
                        isTimeLoaded ?
                            timePeriod&&
                            timePeriod.length > 0 ?
                                timePeriod.map((time,i)=>{
                                    return <Button 
                                            key={i}
                                            onClick={()=>(setSelectedTime(time),props.selectedTime(time))} 
                                            className={`time-btn`}
                                            style={selectedTime === time ? {backgroundColor:"#F99B9B",color:'white'} : null}
                                            >{time}</Button>
                                })
                            :
                                <h5 style={{color:'lightgray'}}>No slots for today</h5>
                        :
                            [{},{},{},{}].map((time,i)=><Skeleton key={i} style={{width:125,height:showCompleteTime ? 40 : 80}} />)
                    }
                </div>
                <div style={{display:'flex',justifyContent:'center'}}>
                    {
                        showCompleteTime ? 
                            <IoIosArrowUp size={30} className="down-arrow-icon-calendar" onClick={()=>setShowCompleteTime(false)} />
                        :
                            <IoIosArrowDown size={30} className="down-arrow-icon-calendar" onClick={()=>setShowCompleteTime(true)} />
                    }
                </div>                
            </div>
        </div>
        
    )
}
