import React from "react";
import img from "../../assets/team/team9.png";

function Natalia() {
  return (
    <div className="bg-[#FFEEEF] w-full flex justify-center items-center py-11">
      <div className="max-w-[1440px] flex justify-between flex-col-reverse lg:flex-row items-center px-10 w-full gap-10">
        <img src={img} alt="" />
        <div className="flex justify-center flex-col items-start gap-1">
          <h1 className="font-bold text-lightGreen-500 text-3xl md:text-5xl">
          Natalia Ploch-Bernier
          </h1>
          <h2 className="font-bold text-lightGreen-500 text-xl md:text-2xl">
          MSc. ABA, BCBA
          </h2>
          <h4 className="font-bold text-lightGreen-500 text-md md:text-xl">
          Behavior Analyst - Clinical Supervisor
          </h4>
          <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
          Natalia is an accomplished psychologist and a Board Certified Behavior Analyst who has dedicated her career to working with children with autism.
          </p>
          <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
          Natalia has a triple Masters degree that showcases her commitment to ongoing learning and professional
            development. She holds a Masters in Psychology with a focus on Applied Behavior Analysis from California State University Stanislaus, equipping her with a solid foundation in the principles and practices of behavior analysis. Additionally, she earned a Masters degree in Applied Behavior Analysis from the University of Bangor, further refining her expertise in evidence based interventions. Natalia’s academic journey also includes a Masters in Psychology from the University of Social
            Sciences and Humanities in Warsaw, Poland, broadening her perspective on child psychology and development.
          </p>
          <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
          As a BCBA, Natalia leverages behavior analysis principles to develop individualized treatment plans that target specific areas of need. Her focus on early intervention ensures that children receive timely and comprehensive support, setting the stage for positive outcomes and maximizing their potential.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Natalia;
