import React from 'react'
import { Link } from 'react-router-dom'
import { RiArrowDropRightLine } from 'react-icons/ri';
import './CSS/DashboardParentCard.css'

export default function DashboardParentCard(props) {

    return (
        <div className="dashboard-parent-card-container">
            <div className="d-flex align-items-center">
                <div style={{height:65,width:65,borderRadius:'50%',backgroundImage:`url(${props.image})`,backgroundSize:'cover',backgroundPosition:'center',backgroundRepeat:'no-repeat'}}>
                </div>
                <div className="d-flex flex-column justify-content-center pt-2 ml-2">
                    <p style={{fontFamily:'CocoSharp bold',fontSize:17,margin:0,color:'#096E82'}}>{props.name.length > 20 ? props.name.slice(0,20) + '...' : props.name}</p>
                </div>
            </div>
           <div style={{wordWrap:'break-word',marginTop:0}}>
                <p style={{fontSize:14,margin:0,fontFamily:'CocoSharp bold',color:'#096E82'}}>{props.otherInfo !==  '' ? props.otherInfo.length > 50 ? props.otherInfo.slice(0,50)+'...' : props.otherInfo : 'No description'}</p>
            </div>
            <Link to={props.path || `/parent-detail/${props.Id}`} style={{fontFamily:'CocoSharp bold',color:'#F99B9B',marginTop:5}} className="d-flex align-items-center">Explore Now <RiArrowDropRightLine size={30} /></Link>
        </div>
    )
}
