import {combineReducers} from 'redux'
import SidebarReducer from './SidebarReducer'
import Form1Reducer from './OnBoardingFormReducers/Form1Reducer'
import Form2Reducer from './OnBoardingFormReducers/Form2Reducer'
import Form3Reducer from './OnBoardingFormReducers/Form3Reducer'
import Form4Reducer from './OnBoardingFormReducers/Form4Reducer'
import Form5Reducer from './OnBoardingFormReducers/Form5Reducer'
import Form6Reducer from './OnBoardingFormReducers/Form6Reducer'
import ParentReducer from './ParentReducer'
import AppointmentReducer from './AppointmentReducer'
import NotificationsReducer from './NotificationsReducer'
import MeetingReducer from './MeetingReducer'

const CombineReducer = combineReducers({
    SidebarReducer,
    Form1Reducer,
    Form2Reducer,
    Form3Reducer,
    Form4Reducer,
    Form5Reducer,
    Form6Reducer,
    ParentReducer,
    AppointmentReducer,
    NotificationsReducer,
    MeetingReducer
})

export default CombineReducer;