import React,{useState,useEffect} from 'react'
import Calendar from 'react-calendar';
import { Button } from 'react-bootstrap';
import { IoIosArrowDown,IoIosArrowUp } from 'react-icons/io';
import {convert12To24,convert24To12,convertIntoUtc, getTimeInLocal} from '../TimeFormate'
import './CSS/CalendarComp.css'

export default function CalenderComp(props) {

    const [selectedTime, setSelectedTime] = useState('')
    const [slots, setSlots] = useState([])
    const [showCompleteTime,setShowCompleteTime] = useState(false)

    let timePeriod = ['07:00AM','08:30AM','10:00AM','11:30AM',
                        '01:00PM','02:30PM','04:00PM','05:30PM',
                        '07:00PM']

    let timePeriod1 = []
    
    useEffect(()=>{
        const tempArr = []
        timePeriod.map(dt=>{  
            const currentDate = new Date()
            const dateInLocal = getTimeInLocal(currentDate)
            const newTime = convert12To24(dt)
            const utc_date = convertIntoUtc(dateInLocal.split('T')[0] + 'T' + newTime + ':00')

            if(utc_date.split('T')[0] == dateInLocal.split('T')[0]){
                tempArr.push(dt)
            }
        })
        setSlots(tempArr)
    },[])

    return (
        <div className="calendar-component">
            <Calendar
                minDate={new Date(new Date().setDate(new Date().getDate() + 2))}
                onChange={(e)=>props.selectedDate(e)}
                nextLabel="Next"
                prevLabel="Pre"
            />
            <div style={{border:'solid 1px #096E82',borderRadius:10,marginTop:30}}>
                <div className={showCompleteTime ? "calendar-componete-time-accordian-full" : "calendar-componete-time-accordian"}>
                    {
                        timePeriod.map(time=>{
                            return <Button 
                                    onClick={()=>(setSelectedTime(time),props.selectedTime(time))} 
                                    className={`time-btn`}
                                    style={selectedTime === time ? {backgroundColor:"#F99B9B",color:'white'} : null}
                                    >{time}</Button>
                        })
                    }
                </div>
                <div style={{display:'flex',justifyContent:'center'}}>
                    {
                        showCompleteTime ? 
                            <IoIosArrowUp size={30} className="down-arrow-icon-calendar" onClick={()=>setShowCompleteTime(false)} />
                        :
                            <IoIosArrowDown size={30} className="down-arrow-icon-calendar" onClick={()=>setShowCompleteTime(true)} />
                    }
                </div>                
            </div>
        </div>
        
    )
}
