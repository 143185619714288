import React, { useState } from 'react'
import {Collapse} from 'react-collapse';
import downArrow from '../icons/down-arrow.svg'
import rightArrow from '../icons/right-arrow.svg'

export default function AccordionComp({title,content,containerStyle}) {
    const [isOpen,setIsOpen] = useState(false)
    return (
        <div style={{width:'100%',padding:'12px 0px',...containerStyle}}>
            <div onClick={()=>setIsOpen(!isOpen)} style={{display:'flex',justifyContent:'space-between',alignItems:'center',cursor:'pointer'}}>
                <h5 style={{margin:0,fontSize:16,fontFamily:'CocoSharp regular',fontWeight:600}}>{title}</h5>
                <img src={isOpen ? downArrow : rightArrow} />
            </div>
            <Collapse isOpened={isOpen}>
                {content}
            </Collapse>
        </div>
    )
}
