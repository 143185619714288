import React,{useState,useEffect} from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import {Link} from 'react-router-dom'
import {MdKeyboardArrowLeft,MdKeyboardArrowRight} from 'react-icons/md'
import docImg from '../images/doc_img.svg'
import './CSS/ChildDetailModal.css'
import { Decrypt,headers,baseURL } from '../Config'

export default function ChildDetailModal(props) {

    const [selectedMenu,setSelectedMenu] = useState(1)
    const [childDetail,setChildDetail] = useState(1)
    const [isLoaded,setIsLoaded] = useState(false)


    useEffect(() => {
        const apiRoute = selectedMenu === 1 ? '/api/getChildAdolescentDetail' :selectedMenu === 2 ? '/api/getDevHistoryDetail' :selectedMenu === 3 ? '/api/getMedHistoryDetail' :selectedMenu === 4 ? '/api/getBehaviourChechList' :selectedMenu === 5 ? '/api/getSchoolInfoDetail' : '/api/getOtherInfoDetail'
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            fetch(baseURL + apiRoute,{
                method:'Post',
                body:JSON.stringify({Id:props?.childId}),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    toast.error('error..')
                    setIsLoaded(true)
                }
                else{
                    setChildDetail(res2.message)
                    setIsLoaded(true)
                }
            })
        }
    }, [props])


    const handleNext = () =>{

        const menu = selectedMenu + 1

        const apiRoute = menu === 1 ? '/api/getChildAdolescentDetail' :menu === 2 ? '/api/getDevHistoryDetail' :menu === 3 ? '/api/getMedHistoryDetail' :menu === 4 ? '/api/getBehaviourChechList' :menu === 5 ? '/api/getSchoolInfoDetail' : '/api/getOtherInfoDetail'
        
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt && isLoaded){
            setSelectedMenu(selectedMenu + 1)
            setIsLoaded(false)
            fetch(baseURL + apiRoute,{
                method:'Post',
                body:JSON.stringify({Id:props?.childId}),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    toast.error('error..')
                    setIsLoaded(true)
                }
                else{
                    setChildDetail(res2.message)
                    setIsLoaded(true)
                }
            })
        }
    }


    const handlePrevious = () =>{

        const menu = selectedMenu - 1

        const apiRoute = menu === 1 ? '/api/getChildAdolescentDetail' :menu === 2 ? '/api/getDevHistoryDetail' :menu === 3 ? '/api/getMedHistoryDetail' :menu === 4 ? '/api/getBehaviourChechList' :menu === 5 ? '/api/getSchoolInfoDetail' : '/api/getOtherInfoDetail'
        
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt && isLoaded){
            setSelectedMenu(selectedMenu - 1)
            setIsLoaded(false)
            fetch(baseURL + apiRoute,{
                method:'Post',
                body:JSON.stringify({Id:props?.childId}),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    toast.error('error..')
                    setIsLoaded(true)
                }
                else{
                    setChildDetail(res2.message)
                    setIsLoaded(true)
                }
            })
        }
    }
    
    return (
            <Modal className="child-detail-modal-container" contentClassName="child-detail-modal" size="lg" show={props.showDetailModal} onHide={()=>props.hideShowDetailModal()} centered animation={false}>
                <Modal.Header className="d-flex justify-content-between align-items-center w-100">
                    <h4 className="select-previous" style={{color:selectedMenu > 1 ? '#096E82':'#C4C4C4'}} onClick={()=>selectedMenu > 1 && handlePrevious()}><MdKeyboardArrowLeft size="28"/>Previous</h4>
                    {
                        selectedMenu === 1 ?
                            <h3>Background History</h3>
                        :selectedMenu === 2 ?
                            <h3>Developmental History</h3>
                        :selectedMenu === 3 ?
                            <h3>Medical History</h3>
                        :selectedMenu === 4 ?
                            <h3>Behaviour Checklist</h3>
                        :selectedMenu === 5 ?
                            <h3>School Information</h3>
                        :
                            <h3>Other Information</h3>
                    }
                    <h4 className="select-next" style={{color:selectedMenu <= 5? '#096E82':'#C4C4C4'}} onClick={()=> selectedMenu < 6 && handleNext()}>Next<MdKeyboardArrowRight size="28"/></h4>
                </Modal.Header>
                {
                    isLoaded ? 
                        <Modal.Body className="w-100">
                            {
                                selectedMenu === 1 ?
                                    <Form1Detail childDetail={childDetail} containerClass="pre-call-form-1-detail-container" />
                                :selectedMenu === 2 ?
                                    <Form2Detail childDetail={childDetail} containerClass="pre-call-form-2-detail-container" />
                                :selectedMenu === 3 ?
                                    <Form3Detail childDetail={childDetail} id="custom-table" id1="custom-table-1" containerClass="pre-call-form-3-detail-container" />
                                :selectedMenu === 4 ?
                                    <Form4Detail childDetail={childDetail} containerClass="pre-call-form-4-detail-container" />
                                :selectedMenu === 5 ?
                                    <Form5Detail childDetail={childDetail} containerClass="pre-call-form-5-detail-container" />
                                :
                                    <Form6Detail childDetail={childDetail} containerClass="pre-call-form-6-detail-container" />
                            }
                        </Modal.Body>
                    :
                        <Modal.Body className="w-100">
                            <Row style={{marginTop:55}}>
                                <Col md={4}><Skeleton height="35px" /></Col>
                                <Col md={4}><Skeleton height="35px" /></Col>
                                <Col md={4}><Skeleton height="35px" /></Col>
                            </Row>

                            <Row style={{marginTop:30}}>
                                <Col md={4}><Skeleton height="35px" /></Col>
                                <Col md={4}><Skeleton height="35px" /></Col>
                                <Col md={4}><Skeleton height="35px" /></Col>
                            </Row>

                            <Row style={{marginTop:30}}>
                                <Col md={12}><Skeleton height="150px" /></Col>
                            </Row>

                            <Row style={{marginTop:30}}>
                                <Col md={6}><Skeleton height="35px" /></Col>
                                <Col md={6}><Skeleton height="35px" /></Col>
                            </Row>

                            <Row style={{marginTop:30}}>
                                <Col md={3}><Skeleton height="35px" /></Col>
                                <Col md={3}><Skeleton height="35px" /></Col>
                                <Col md={3}><Skeleton height="35px" /></Col>
                                <Col md={3}><Skeleton height="35px" /></Col>
                            </Row>

                            <Row style={{marginTop:30}}>
                                <Col md={4}><Skeleton height="35px" /></Col>
                                <Col md={4}><Skeleton height="35px" /></Col>
                                <Col md={4}><Skeleton height="35px" /></Col>
                            </Row>

                        </Modal.Body> 
                }
            </Modal>
    )
}


const Form1Detail = (props) =>{
    return(
        <div className={props.containerClass} style={{marginTop:55}}>
            <div>
                <h3>Child Adolescent</h3>
                <Row className="px-3 mt-4">
                    <Col md={3}>
                        <p className="form-fld-title">Name</p>
                        <p className="form-fld-detail">{props?.childDetail&&Decrypt(props.childDetail.child_name)}</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Birthdate</p>
                        <p className="form-fld-detail">{props?.childDetail&&Decrypt(props.childDetail.birth_date)}</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">School</p>
                        <p className="form-fld-detail">{props?.childDetail&&Decrypt(props.childDetail.school_name) || '-' }</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Family Doctor</p>
                        <p className="form-fld-detail">{props?.childDetail&&Decrypt(props.childDetail.family_doctor) || '-' }</p>
                    </Col>
                </Row>
                <Row className="px-3 mt-3">
                    <Col md={3}>
                        <p className="form-fld-title">Grade</p>
                        <p className="form-fld-detail">{props?.childDetail&&Decrypt(props.childDetail.grade) || '-'}</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Referring Doctor</p>
                        <p className="form-fld-detail">{props?.childDetail&&Decrypt(props.childDetail.reffering_doc) || '-'}</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Primary Language At Home</p>
                        <p className="form-fld-detail">{props?.childDetail&&Decrypt(props.childDetail.primary_language) || '-'}</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Other Languages Spoken</p>
                        <p className="form-fld-detail">{props?.childDetail&&Decrypt(props.childDetail.other_languages) || '-'}</p>
                    </Col>
                </Row>

                <h3 style={{marginTop:60,textAlign:'center'}}>If your child’s first language is not English, please complete the following</h3>
                <Row className="px-3 mt-5">
                    <Col md={3}>
                        <p className="form-fld-title">Child’s First Language</p>
                        <p className="form-fld-detail">{props?.childDetail&&Decrypt(props.childDetail.first_language) || '-'}</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Age When Child Learn English</p>
                        <p className="form-fld-detail">{props?.childDetail&&Decrypt(props.childDetail.age_when_learned_eng) || '-'}</p>
                    </Col>
                </Row>

                <h3 style={{marginTop:60,textAlign:'center'}}>If your child was not born in Canada, please Complete the following</h3>
                <Row className="px-3 mt-5">
                    <Col md={3}>
                        <p className="form-fld-title">Birth Country</p>
                        <p className="form-fld-detail">{props?.childDetail&&Decrypt(props.childDetail.birth_country) || '-'}</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Age Of Arrival In Canada</p>
                        <p className="form-fld-detail">{props?.childDetail&&Decrypt(props.childDetail.age_when_arrived_canada) || '-'}</p>
                    </Col>
                </Row>
            </div>
            <hr style={{width:'100%',margin:'40px 0px'}} />
            <div>
                <h3>Parents/Guardian</h3>
                <Row className="px-3 mt-4">
                    <Col md={12}>
                        <Row className="px-3 mt-4 mb-3">
                            <Col md={4} lg={2}>
                                <p className="form-fld-title">Name</p>
                            </Col>
                            <Col md={4} lg={2}>
                                <p className="form-fld-title">Age</p>
                            </Col>
                            <Col md={4} lg={2}>
                                <p className="form-fld-title">Occupation</p>
                            </Col>
                            <Col md={4} lg={2}>
                                <p className="form-fld-title">Education Level</p>
                            </Col>
                            <Col md={4} lg={2}>
                                <p className="form-fld-title">Work or Cell</p>
                            </Col>
                            <Col md={4} lg={2}>
                                <p className="form-fld-title">Email</p>
                            </Col>
                        </Row>
                        {
                            props?.childDetail&&props.childDetail.parents.length > 0 &&
                            props.childDetail.parents.map((dt,i)=>{
                                return (
                                    <Row key={i} className="px-3 mt-0">
                                        <Col md={4} lg={2}>
                                            <p className="form-fld-detail" style={{margin:0}}>{dt.parentName&&Decrypt(dt.parentName) || '-'}</p>
                                        </Col>
                                         <Col md={4} lg={2}>
                                            <p className="form-fld-detail" style={{margin:0}}>{dt.parentAge&&Decrypt(dt.parentAge) || '-'}</p>
                                        </Col>
                                        <Col md={4} lg={2}>
                                            <p className="form-fld-detail" style={{margin:0}}>{dt.parentOccupation&&Decrypt(dt.parentOccupation) || '-'}</p>
                                        </Col>
                                        <Col md={4} lg={2}>
                                            <p className="form-fld-detail" style={{margin:0}}>{dt.parentEducationLevel&&Decrypt(dt.parentEducationLevel) || '-'}</p>
                                        </Col>
                                       <Col md={4} lg={2}>
                                            <p className="form-fld-detail" style={{margin:0}}>{dt.parentWorkOrCell&&Decrypt(dt.parentWorkOrCell) || '-'}</p>
                                        </Col>
                                        <Col md={4} lg={2}>
                                            <p className="form-fld-detail" style={{margin:0}}>{dt.parentEmail&&Decrypt(dt.parentEmail) || '-'}</p>
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </Col>
                    
                    {/* <Col sm={6}> 
                        <Row className="mt-3">
                            <Col md={6}>
                                <p className="form-fld-title">Parent 1 Name</p>
                                <p className="form-fld-detail">{props?.childDetail&&Decrypt(props.childDetail.age_when_arrived_canada) || '-'}</p>
                            </Col>
                            <Col md={6}>
                                <p className="form-fld-title">Age</p>
                                <p className="form-fld-detail">35</p>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md={6}>
                                <p className="form-fld-title">Occupatio</p>
                                <p className="form-fld-detail">Software Engineer</p>
                            </Col>
                            <Col md={6}>
                                <p className="form-fld-title">Education Level</p>
                                <p className="form-fld-detail">PhD</p>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md={6}>
                                <p className="form-fld-title">Work or Cell</p>
                                <p className="form-fld-detail">0341-1234567</p>
                            </Col>
                            <Col md={6}>
                                <p className="form-fld-title">Email</p>
                                <p className="form-fld-detail">henry@gmail.com</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={6}>
                        <Row className="mt-3">
                            <Col md={6}>
                                <p className="form-fld-title">Parent 2 Name</p>
                                <p className="form-fld-detail">-</p>
                            </Col>
                            <Col md={6}>
                                <p className="form-fld-title">Age</p>
                                <p className="form-fld-detail">-</p>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md={6}>
                                <p className="form-fld-title">Occupatio</p>
                                <p className="form-fld-detail">-</p>
                            </Col>
                            <Col md={6}>
                                <p className="form-fld-title">Education Level</p>
                                <p className="form-fld-detail">-</p>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md={6}>
                                <p className="form-fld-title">Work or Cell</p>
                                <p className="form-fld-detail">-</p>
                            </Col>
                            <Col md={6}>
                                <p className="form-fld-title">Email</p>
                                <p className="form-fld-detail">-</p>
                            </Col>
                        </Row>
                    </Col> */}

                </Row>
            </div>

            <hr style={{width:'100%',margin:'40px 0px'}} />

            <div>
                <h3>Child Lives With?</h3>
                <Row className="px-3 mt-4">
                    <Col md={3}>
                        <p className="form-fld-title">Parent 1</p>
                        <p className="form-fld-detail">{props?.childDetail&&(Decrypt(props.childDetail.lives_with) === 'Parent 1' || Decrypt(props.childDetail.lives_with) === 'Both') ? 'Yes' : 'No'}</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Parent 2</p>
                        <p className="form-fld-detail">{props?.childDetail&&(Decrypt(props.childDetail.lives_with) === 'Parent 2' || Decrypt(props.childDetail.lives_with) === 'Both') ? 'Yes' : 'No'}</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Marital Status of Parent/Guardian</p>
                        <p className="form-fld-detail">{props?.childDetail&&Decrypt(props.childDetail.marital_status_of_parent) || '-'}</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Who Has LegalCustody of the child?</p>
                        <p className="form-fld-detail">{props?.childDetail&&Decrypt(props.childDetail.legal_custody) || '-'}</p>
                    </Col>
                </Row>
            </div>

            <hr style={{width:'100%',margin:'40px 0px'}} />

            <div>
                <h3>Other Important Adults/Family Members (e.g. stepparents, siblings)</h3>
                <Row className="px-3 mt-4 mb-3">
                    <Col md={3}>
                        <p className="form-fld-title">Name</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Age</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Relationship to your child</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Who Has LegalCustody of the child?</p>
                    </Col>
                </Row>
                {
                    props?.childDetail&&props.childDetail.relatives.length > 0 &&
                    props.childDetail.relatives.map((dt,i)=>{
                        return (
                            <Row key={i} className="px-3 mt-0">
                                <Col md={3}>
                                    <p className="form-fld-detail" style={{margin:0}}>{dt.relName&&Decrypt(dt.relName) || '-'}</p>
                                </Col>
                                <Col md={3}>
                                    <p className="form-fld-detail" style={{margin:0}}>{dt.relAge&&Decrypt(dt.relAge) || '-'}</p>
                                </Col>
                                <Col md={3}>
                                    <p className="form-fld-detail" style={{margin:0}}>{dt.relRelation&&Decrypt(dt.relRelation) || '-'}</p>
                                </Col>
                                <Col md={3}>
                                    <p className="form-fld-detail" style={{margin:0}}>{dt.relLivesInHome&&Decrypt(dt.relLivesInHome) || '-'}</p>
                                </Col>
                            </Row>
                        )
                    })
                }
                
            </div>
        </div>
    )
}



const Form2Detail = (props) =>{
    return(
        <div className={props.containerClass} style={{marginTop:55}}>
            <div>
                <h3>During Pregnancy, did the mother of this child</h3>
                <Row className="px-3 mt-4">
                    <Col md={12}>
                        <p className="form-fld-title">Take any medication?</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.medication === 'No' ? 'No' : 'Yes'}</p>
                    </Col>
                </Row>
                <Row className="px-3 mt-0">
                    <Col md={12} className="d-flex align-items-center">
                        <p className="form-fld-title mr-2">Medication Detail:</p>
                        <p className="form-fld-detail" style={{marginBottom:0}}>
                            {props?.childDetail&&props.childDetail.medication || '-'}
                        </p>
                    </Col>
                </Row>
                <Row className="px-3 mt-5">
                    <Col md={3}>
                        <p className="form-fld-title">Smoke</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.smoke}</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Drink Alcohol Beverage?</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.alcoholic_beverages}</p>
                    </Col>
                </Row>
                <Row className="px-3 mt-0">
                    <Col md={12} className="d-flex align-items-center">
                        <p className="form-fld-title mr-2">How Often:</p>
                        <p className="form-fld-detail" style={{marginBottom:0}}>{props?.childDetail&&props.childDetail.alcoholic_beverages_approx || '-'}</p>
                    </Col>
                </Row>

                <Row className="px-3 mt-5">
                    <Col md={12}>
                        <p className="form-fld-title">Use Drugs?</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.use_drug}</p>
                    </Col>
                </Row>
                <Row className="px-3 mt-0">
                    <Col md={12} className="d-flex align-items-center">
                        <p className="form-fld-title mr-2">How Often:</p>
                        <p className="form-fld-detail" style={{marginBottom:0}}>{props?.childDetail&&props.childDetail.use_drug_approx || '-'}</p>
                    </Col>
                </Row>
            </div>
            <hr style={{width:'100%',margin:'40px 0px'}} />
            <div>
                <h3>
                    Please list any complications experienced during pregnancy (excessive vomiting, excessive
                    staining blood loss, threatened miscarriage, infections, toxemia, fainting, dizziness, etc):
                </h3>
                <Row className="px-3 mt-4">
                    <Col sm={12}>
                        <p className="form-fld-title">
                            {props?.childDetail&&props.childDetail.compilcation_during_preg || 'No Detail'}
                        </p>
                    </Col>
                </Row>

                <Row className="px-3 mt-5">
                    <Col md={3}>
                        <p className="form-fld-title">Baby Was Born</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.baby_was_born || '-'}</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Were there any complications during labour & delivery</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.complication_during_delivery || '-'}</p>
                    </Col>
                </Row>
                <Row className="px-3 mt-0">
                    <Col md={12} className="d-flex">
                        <p className="form-fld-title mr-2">Complications:</p>
                        <p className="form-fld-detail" style={{marginBottom:0}}>
                            {props?.childDetail&&props.childDetail.what_hap_during_delivery || '-'}
                        </p>
                    </Col>
                </Row>
            </div>

            <hr style={{width:'100%',margin:'40px 0px'}} />

            <div>
                <h3>What was your child's birth weight?</h3>
                <Row className="px-3 mt-5">
                    <Col md={12}>
                        <p className="form-fld-title">Birth weight</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.child_birth_weight} Kg</p>
                    </Col>
                </Row>
                <Row className="px-3 mt-0">
                    <Col md={12}>
                        <p className="form-fld-title">Birth Problem</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.child_birth_problem || '-'}</p>
                    </Col>
                </Row>
                <Row className="px-3 mt-0">
                    <Col md={12} className="d-flex">
                        <p className="form-fld-title mr-2">Problem Detail:</p>
                        <p className="form-fld-detail" style={{marginBottom:0}}>
                            {props?.childDetail&&props.childDetail.child_birth_weight_detail || '-'}
                        </p>
                    </Col>
                </Row>

                <Row className="px-3 mt-5">
                    <Col md={12}>
                        <p className="form-fld-title">Were there any post delivery complications?</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.post_delivery_complication}</p>
                    </Col>
                </Row>
                <Row className="px-3 mt-0">
                    <Col md={12} className="d-flex">
                        <p className="form-fld-title mr-2">Complications:</p>
                        <p className="form-fld-detail" style={{marginBottom:0}}>
                            {props?.childDetail&&props.childDetail.post_delivery_comp_detail || '-'}
                        </p>
                    </Col>
                </Row>
            </div>

            <hr style={{width:'100%',margin:'40px 0px'}} />

            <div>
                <h3>
                    Please indicate the approximate age at which your child achieved the following developmental milestones
                    by checking the appropriate box. Check "Never” if your child has not yet achieved a given milestone.
                </h3>
                <Row className="px-3 mt-4">
                    <Col md={3}>
                        <p className="form-fld-title">Smiled</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.smiled}</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Rolled Over</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.rolled_over}</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Sat Alone</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.crawled}</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Crawled</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.walked}</p>
                    </Col>
                </Row>
                
                <Row className="px-3 mt-4">
                    <Col md={3}>
                        <p className="form-fld-title">Ran</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.ran}</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Babbled</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.babbled}</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">First Word</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.first_word}</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Sentences</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.sentences}</p>
                    </Col>
                </Row>

                <Row className="px-3 mt-4">
                    <Col md={3}>
                        <p className="form-fld-title">Tied Shoelaces</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.tied_shoelaces}</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Dressed Self</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.dressed_self}</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Fed Self</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.fed_self}</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Bladder Trained, day</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.bladder_trained_day}</p>
                    </Col>
                </Row>

                <Row className="px-3 mt-4">
                    <Col md={3}>
                        <p className="form-fld-title">Bladder Trained, day</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.bladder_trained_night}</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Bowel Trained</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.bowel_trained}</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Rode Tricycle</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.rode_tricycle}</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Rode bicycle</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.rode_bicycle}</p>
                    </Col>
                </Row>
            </div>

            <hr style={{width:'100%',margin:'40px 0px'}} />

            <div>
                <h3>If your child was late to talk, please estimate the following:</h3>
                <Row className="px-3 mt-4">
                    <Col md={3}>
                        <p className="form-fld-title">Age at which single words appeard</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.age_single_word_appear}</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Age at which phrases words appeard</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.age_phrases_appear}</p>
                    </Col>
                </Row>
            </div>
        </div>
    )
}


const Form3Detail = (props) =>{
    return(
        <div className={props.containerClass} style={{marginTop:55}}>
            <div>
                <h3>During Pregnancy, did the mother of this child</h3>
                <Row className="px-3 mt-4">
                    <Col md={3}>
                        <p className="form-fld-title">Illness or Condition</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Date(s) or age(s)</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Illness or Condition</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Date(s) or age(s)</p>
                    </Col>
                </Row>

                <Row className="px-3 mt-2 mb-0">
                    {
                        props?.childDetail&&props.childDetail.illnesOrCond.length > 0 &&
                            props.childDetail.illnesOrCond.map((dt,i)=>{
                                return(
                                    <React.Fragment key={i}>
                                        <Col md={3}>
                                            <p className="form-fld-detail mb-0">{dt.illness&&Decrypt(dt.illness)}</p>
                                        </Col>
                                        <Col md={3}>
                                            <p className="form-fld-detail mb-0">{dt.illAge&&Decrypt(dt.illAge)}</p>
                                        </Col>
                                    </React.Fragment>
                                )
                            })
                    }
                </Row>
            </div>

            <hr style={{width:'100%',margin:'40px 0px'}} />

            <div>
                <h3>Current Medications</h3>
                <Row className="px-3 mt-4">
                    <Col sm={12}>
                        <table cellPadding={0} id={props.id} className="w-100 mt-4">
                            <tr className="w-100">
                                <th>Medications</th>
                                <th>Reason Taken</th>
                                <th>Dosage (If Known)</th>
                                <th>Start Date</th>
                            </tr>
                            {
                                
                                props?.childDetail&&props.childDetail.currentMedications.length > 0 &&
                                props.childDetail.currentMedications.map((dt,i)=>{
                                    return(
                                        <tr key={i} className="w-100">
                                            <td>{dt.medication&&Decrypt(dt.medication) || '-'}</td>
                                            <td>{dt.reason_taken&&Decrypt(dt.reason_taken) || '-'}</td>
                                            <td>{dt.dosage&&Decrypt(dt.dosage) || '-'}</td>
                                            <td>{dt.start_date&&Decrypt(dt.start_date) || '-'}</td>
                                        </tr>
                                    )
                                })
                            }
                        </table>
                    </Col>
                </Row>

                <Row className="px-3 mt-5">
                    <Col md={3}>
                        <p className="form-fld-title">Vision Problem</p>
                        <p className="form-fld-detail">{props?.childDetail&&Decrypt(props.childDetail.vission_problem)}</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Date of Last Vision Exam</p>
                        <p className="form-fld-detail">{props?.childDetail&&Decrypt(props.childDetail.vission_problem_date) || '-'}</p>
                    </Col>
                </Row>
                <Row className="px-3 mt-0">
                    <Col md={12} className="d-flex">
                        <p className="form-fld-title mr-2">VisionProblems:</p>
                        <p className="form-fld-detail" style={{marginBottom:0}}>
                            {props?.childDetail&&Decrypt(props.childDetail.vission_problem_detail) || '-'}
                        </p>
                    </Col>
                </Row>


                <Row className="px-3 mt-5">
                    <Col md={3}>
                        <p className="form-fld-title">Hearing Problems</p>
                        <p className="form-fld-detail">{props?.childDetail&&Decrypt(props.childDetail.hearing_problem)}</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Date of Last Vision Exam</p>
                        <p className="form-fld-detail">{props?.childDetail&&Decrypt(props.childDetail.hearing_problem_date) || '-'}</p>
                    </Col>
                </Row>
                <Row className="px-3 mt-0">
                    <Col md={12} className="d-flex">
                        <p className="form-fld-title mr-2">HearingProblems:</p>
                        <p className="form-fld-detail" style={{marginBottom:0}}>
                            {props?.childDetail&&Decrypt(props.childDetail.hearing_problem_detail) || '-'}
                        </p>
                    </Col>
                </Row>
            </div>

            <hr style={{width:'100%',margin:'40px 0px'}} />

            <div>
                <h3>Family Medical History</h3>
                <Row className="px-3 mt-4">
                    <Col md={3}>
                        <p className="form-fld-title">Condition</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Relation to child</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Condition</p>
                    </Col>
                    <Col md={3}>
                        <p className="form-fld-title">Relation to child</p>
                    </Col>
                </Row>

                <Row className="px-3 mt-2 mb-0">
                    {
                        props?.childDetail&&props.childDetail.familyMedications.length > 0 &&
                        props.childDetail.familyMedications.map((dt,i)=>{
                            return(
                                <React.Fragment key={i}>
                                    <Col md={3}>
                                        <p className="form-fld-detail mb-0">{dt.conditions&&Decrypt(dt.conditions)}</p>
                                    </Col>
                                    <Col md={3}>
                                        <p className="form-fld-detail mb-0">{dt.relation_with_child&&Decrypt(dt.relation_with_child)}</p>
                                    </Col>
                                </React.Fragment>
                            )
                        })
                    }
                </Row>
            </div>

            <hr style={{width:'100%',margin:'40px 0px'}} />

            <div>
                <h3>Previous Assessments And Interventions Of Your Child</h3>
                <Row className="px-3 mt-4 mb-4">
                    <Col md={4} lg={3}>
                        <p className="form-fld-title">Assessments</p>
                    </Col>
                    <Col md={4} lg={3}>
                        <p className="form-fld-title">Date(s) of Testing</p>
                    </Col>
                    <Col md={4} lg={3}>
                        <p className="form-fld-title">Name of Examiner</p>
                    </Col>
                </Row>
                {
                    props?.childDetail&&props.childDetail.previouseAssessments.length > 0 &&
                    props.childDetail.previouseAssessments.map((dt,i)=>{
                        return(
                            <Row key={i} className="px-3 mt-0">
                                <Col md={4} lg={3}>
                                    <p className="form-fld-detail">{dt.assesment_type&&Decrypt(dt.assesment_type) || '-'}</p>
                                </Col>
                                <Col md={4} lg={3}>
                                    <p className="form-fld-detail">{dt.assesment_type&&Decrypt(dt.testing_date) || '-'}</p>
                                </Col>
                                <Col md={4} lg={3}>
                                    <p className="form-fld-detail">{dt.assesment_type&&Decrypt(dt.examinar_name) || '-'}</p>
                                </Col>
                            </Row>
                        )
                    })
                }
            </div>

            <hr style={{width:'100%',margin:'40px 0px'}} />

            <div>
                <h3>
                    List any psychological/psychiatric treatment that your child has had (e.g., psychotherapy, family therapy,
                    inpatient or residential treatment)
                </h3>
                <Row className="px-3 mt-4">
                    <Col md={12}>
                        <table cellPadding={0} id={props.id1} className="w-100 mt-4">
                            <tr className="w-100">
                                <th>Type of Treatment</th>
                                <th>Dates</th>
                                <th>Name of therapist</th>
                            </tr>
                            {
                                    props?.childDetail&&props.childDetail.treatmentLists.length > 0 &&
                                    props.childDetail.treatmentLists.map((dt,i)=>{
                                        return(
                                            <tr key={i} className="w-100">
                                                <td>{dt.treatment_type&&Decrypt(dt.treatment_type) || '-'}</td>
                                                <td>{dt.treatment_date&&Decrypt(dt.treatment_date) || '-'}</td>
                                                <td>{dt.therapist_name&&Decrypt(dt.therapist_name) || '-'}</td>
                                            </tr>
                                        )
                                    })
                            }
                        </table>
                    </Col>
                </Row>
            </div>
            
            <hr style={{width:'100%',margin:'40px 0px'}} />

            <div>
                <h3>
                    Have there been any recent stressors that you think may be contributing to your child’s difﬁculties (e.g.,
                    illness, deaths, operations, accidents, separations, divorce of parents, parent changed job, changed
                    schools, family moved, family ﬁnancial problems, remarriage, other losses)
                </h3>
                <Row className="px-3 mt-4">
                    <Col md={12}>
                        <p className="form-fld-title">
                            {props?.childDetail&&Decrypt(props.childDetail.recent_stressors) || 'No Detail'}
                        </p>
                    </Col>
                </Row>
            </div>
        </div>
    )
}


const Form4Detail = (props) =>{

    const sleepingAndEating = props.childDetail.sleeping_and_eating&&props.childDetail.sleeping_and_eating.split(',')
    const motorSkills = props.childDetail.motor_skills&&props.childDetail.motor_skills.split(',')
    const moodAffect = props.childDetail.mood_affect&&props.childDetail.mood_affect.split(',')
    const behaviour = props.childDetail.behaviour&&props.childDetail.behaviour.split(',')
    const socialDevelopment = props.childDetail.social_development&&props.childDetail.social_development.split(',')

    return(
        <div className={props.containerClass} style={{marginTop:55}}>
            <div>
                <Row className="px-3 mt-4">
                    <Col md={4}>
                        <h3>Sleeping and Eating</h3>
                        {
                            sleepingAndEating&&
                            sleepingAndEating.length > 0 ?
                                sleepingAndEating.map((dt,i)=>{
                                    return <p key={i} className="form-fld-title">{dt}</p>
                                })
                            :
                                <p className="form-fld-title">-</p>
                        }
                    </Col>
                    <Col md={4}>
                        <h3>Motor Skills</h3>
                        {
                            motorSkills&&
                            motorSkills.length > 0 ?
                                motorSkills.map((dt,i)=>{
                                    return <p key={i} className="form-fld-title">{dt}</p>
                                })
                            :
                                <p className="form-fld-title">-</p>
                        }
                    </Col>
                    <Col md={4}>
                        <h3>Mood/Affect</h3>
                        {
                            moodAffect&&
                            moodAffect.length > 0 ?
                                moodAffect.map((dt,i)=>{
                                    return <p key={i} className="form-fld-title">{dt}</p>
                                })
                            :
                                <p className="form-fld-title">-</p>
                        }
                    </Col>
                </Row>

                <Row className="px-3 mt-5 mb-0">
                    <Col md={4}>
                        <h3>Behaviour</h3>
                        {
                            behaviour&&
                            behaviour.length > 0 ?
                                behaviour.map((dt,i)=>{
                                    return <p key={i} className="form-fld-title">{dt}</p>
                                })
                            :
                                <p className="form-fld-title">-</p>
                        }
                    </Col>
                    <Col md={4}>
                        <h3>Social Development</h3>
                        {
                            socialDevelopment&&
                            socialDevelopment.length > 0 ?
                                socialDevelopment.map((dt,i)=>{
                                    return <p key={i} className="form-fld-title">{dt}</p>
                                })
                            :
                                <p className="form-fld-title">-</p>
                        }
                    </Col>
                </Row>
            </div>
        </div>
    )
}


const Form5Detail = (props) =>{
    return(
        <div className={props.containerClass} style={{marginTop:55}}>
            
            <div>
                <h3>School Information</h3>
                <Row className="px-3 mt-4">
                    <Col md={12}>
                        <p className="form-fld-title">Does your chilld have a modified learning program?</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.modiﬁed_learning_program}</p>
                    </Col>
                </Row>
                <Row className="px-3 mt-4">
                    <Col md={12}>
                        <p className="form-fld-title">Is there an individual education plan (IEP)?</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.individual_education_plan}</p>
                    </Col>
                </Row>
                <Row className="px-3 mt-4">
                    <Col md={12}>
                        <p className="form-fld-title">Are you satisfied with your child’s current learning program?</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.satisﬁed_with_clp}</p>
                        <p className="form-fld-title mt-2">{props?.childDetail&&props.childDetail.learning_pro_detail}</p>
                    </Col>
                    
                </Row>
                <Row className="px-3 mt-5">
                    <Col md={12}>
                        <p className="form-fld-title">Has your child been held back a grade?</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.held_back_grade}</p>
                        <p className="form-fld-title mt-2">{props?.childDetail&&props.childDetail.which_grade}</p>
                    </Col>
                    
                </Row>
                <Row className="px-3 mt-5">
                    <Col md={12}>
                        <p className="form-fld-title">Is your child in any special education classes?</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.special_education_classes}</p>
                        <p className="form-fld-title mt-2">{props?.childDetail&&props.childDetail.special_classes_detail} </p>
                    </Col>
                    
                </Row>
                <Row className="px-3 mt-5">
                    <Col md={12}>
                        <p className="form-fld-title">Is your child receiving learning asistance at shcool?</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.learning_assistance}</p>
                        <p className="form-fld-title mt-2">{props?.childDetail&&props.childDetail.learning_assistance_detail}</p>
                    </Col>
                </Row>
                <Row className="px-3 mt-5">
                    <Col md={12}>
                        <p className="form-fld-title">Has y our child been suspended or expelled from school?</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.suspended_or_expelled}</p>
                        <p className="form-fld-title mt-2">{props?.childDetail&&props.childDetail.suspended_detail}</p>
                    </Col>
                </Row>
                <Row className="px-3 mt-5">
                    <Col md={12}>
                        <p className="form-fld-title">Has your child ever receive tutoring?</p>
                        <p className="form-fld-detail">{props?.childDetail&&props.childDetail.received_tutoring}</p>
                        <p className="form-fld-title mt-3">{props?.childDetail&&props.childDetail.received_tutoring_detail}</p>
                    </Col>
                </Row>

                <h3 className="mt-5">Brieﬂy describe classroom or school problems if applicable:</h3>
                <Row className="px-3 mt-3">
                    <Col md={12}>
                        <p className="form-fld-title">{props?.childDetail&&props.childDetail.school_problems || 'No Detail'}</p>
                    </Col>
                </Row>
            </div>
        </div>
    )
}


const Form6Detail = (props) =>{

    const handleDownload = (file) =>{

        var xhr = new XMLHttpRequest();
        xhr.open('GET', file, true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader( 'Access-Control-Allow-Origin', '*');
        xhr.onprogress = function(event) {
            if (event.lengthComputable) {
                var percentComplete = (event.loaded / event.total)*100;
            } 
        };

        xhr.onload = function(event) {
            if (this.status == 200) {
                var filename = Date.now();  
                var a = document.createElement('a');        
                a.href = window.URL.createObjectURL(xhr.response);        
                a.download = filename;       
                a.style.display = 'none';        
                document.body.appendChild(a);        
                a.click();    
            }
            else {
        
            }
        };


        xhr.send();
    }

    return(
        <div className={props.containerClass} style={{marginTop:55}}>
            
            <div>
                <h3>Other Information</h3>
                <Row className="px-3 mt-4">
                    <Col md={12}>
                        <p className="form-fld-detail mb-0">What are your child’s favorite activites?</p>
                        <p className="form-fld-title">{props?.childDetail&&Decrypt(props.childDetail.favourite_activities) || 'No Detail'}</p>
                    </Col>
                </Row>
                
                <Row className="px-3 mt-5">
                    <Col md={12}>
                        <p className="form-fld-detail mb-0">What have you found to be the best way to help your child?</p>
                        <p className="form-fld-title">{props?.childDetail&&Decrypt(props.childDetail.best_way_to_help) || 'No Detail'}</p>
                    </Col>
                </Row>

                <Row className="px-3 mt-5">
                    <Col md={12}> 
                        <p className="form-fld-detail mb-0">What are your child’s assests or strengths?</p>
                        <p className="form-fld-title">{props?.childDetail&&Decrypt(props.childDetail.assets_or_strengths) || 'No Detail'}</p>
                    </Col>
                </Row>

                <Row className="px-3 mt-5">
                    <Col md={12}>
                        <p className="form-fld-detail mb-0">What about your child makes you the most proud?</p>
                        <p className="form-fld-title">{props?.childDetail&&Decrypt(props.childDetail.makes_you_proud) || 'No Detail'}</p>
                    </Col>
                </Row>

                <Row className="px-3 mt-5">
                    <Col md={12}>
                        <p className="form-fld-detail mb-0">Is there any other information that you think that could help me in assessing your child?</p>
                        <p className="form-fld-title">{props?.childDetail&&Decrypt(props.childDetail.other_information) || 'No Detail'}</p>
                    </Col>
                </Row>

                <h3 className="mt-5">Documents:</h3>
                <Row className="px-3 mt-4">
                    {
                        props?.childDetail&&
                        props?.childDetail.documents&&
                        props?.childDetail.documents.length > 0 &&
                        props?.childDetail.documents.map((dt,i)=>{
                            return(
                                <Col key={i}>
                                    <Link onClick={()=>handleDownload(dt.document)}>
                                        <img src={docImg} className="img-fluid" />
                                    </Link>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        </div>
    )
}