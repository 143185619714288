import React,{useEffect, useState} from 'react';
import { Decrypt } from '../../Config';
import ContactCard from './ContactCard'
import { usePubNub } from 'pubnub-react';
import Skeleton from 'react-loading-skeleton';
import './CSS/ContactsListComp.css'

export default function ContactsListComp({headerTitle,headerTitle1,selectedType,listLoaded,changeSelectedType,type,list,hanldeSelectChat,channel}) {

    const pubnub = usePubNub()

    useEffect(()=>{
        pubnub.addListener({ 
            presence:(event)=>{
                // if(event?.action === 'join'){
                //     if(Id === event?.uuid){
                //         setIsOnline(true)
                //     }
                // }
                // else if(event?.action === 'leave' || event?.action === 'timeout'){
                //     if(Id === event?.uuid){
                //         setIsOnline(false)
                //     }
                // }
            }
        });
    },[pubnub])

    return(
        <div className='chat-contacts-list-comp'>
            {
                type == "1" ?
                    <div className='chat-contacts-list-header'>{headerTitle}</div>
                :
                    <div className='chat-contacts-list-header-1'>
                        <div 
                            className='chat-contacts-list-header-card' 
                            onClick={()=>(changeSelectedType('1'))} 
                            style={{
                                    borderRadius: '14px 0px 0px 0px' ,
                                    backgroundColor:selectedType == '1' ? '#096E82' : 'white',
                                    color:selectedType == '1' ? 'white' : '#096E82',
                                    textAlign:'center'
                                }}
                        >{headerTitle}</div>
                        <div 
                            className='chat-contacts-list-header-card' 
                            onClick={()=>(changeSelectedType('2'))} 
                            style={{
                                    borderRadius: '0px 14px 0px 0px',
                                    backgroundColor:selectedType == '2' ? '#096E82' : 'white',
                                    color:selectedType == '2' ? 'white' : '#096E82',
                                    textAlign:'center'
                                }}
                        >{headerTitle1}</div>
                    </div>
            }
            <div className='chat-contacts-list-body'>
                {
                    listLoaded ?
                        list?.length > 0 ? 
                            list?.map((dt,i)=>{
                                return  (
                                    <React.Fragment key={i}>
                                        <ContactCard 
                                            name={Decrypt(dt.first_name) + ' ' + Decrypt(dt.last_name)}
                                            image={Decrypt(dt.image)}
                                            lastMessage={'hello'}
                                            date={dt.date}
                                            isUnread={dt.isUnread}
                                            isActive={dt.isActive}
                                            hanldeSelectChat={(Id,userType)=>hanldeSelectChat(Id,userType)}
                                            Id={dt.uid}
                                            channel={channel}
                                            userType={Decrypt(dt.user_type)}
                                        />
                                    </React.Fragment>
                                )
                            })
                        :null
                    :
                        [{},{},{},{}].map((dat,i)=>{
                            return <Skeleton key={i} width={'100%'} height={80} style={{marginTop:15,borderRadius:10}}/>
                        })
                }
            </div>
        </div>  
    )
}
