import React,{useState,useEffect} from 'react'
import {useSelector} from 'react-redux'
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import ClinicianDashboardNavbar from '../../Components/ClinicianDashboardNavbar'
import ChatComponent from '../../Components/ChatComp/ChatIndex'
import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import './CSS/TherapistChats.css'
import { baseURL, headers, PUBNUB_PUBLISH_KEY, PUBNUB_SUBSCRIBE_KEY, Decrypt } from '../../Config'
import { toast } from 'react-toastify'
import moment from 'moment'

export default function TherapistChats() {

    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const sidebarWidth = useSelector(data => data.SidebarReducer.width)
    const parentData = useSelector(data => data.ParentReducer?.parentData)
    const pubnub = new PubNub({
        publishKey: PUBNUB_PUBLISH_KEY,
        subscribeKey: PUBNUB_SUBSCRIBE_KEY,
        uuid: parentData?.uid
    });

    const [selectedUser,setSelectedUser] = useState(null)
    const [isLoaded,setIsLoaded] = useState(false)
    const [channel,setChannel] = useState('')
    const [isLoadedList,setIsLoadedList] = useState(false)
    const [selectedType,setSelectedType] = useState('1')
    const [usersList,setUsersList] = useState([])
    const [userType,setUserType] = useState(null)

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })

        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            setUserType(Decrypt(dt.userType))
            getParents(dt)
        }
    },[])

    const hanldeSelectChat = (Id,user_type) =>{

        const newArr = usersList.map(dt=>{
            if(dt.uid === Id){
                if(user_type === 'Parent') {
                    setChannel(`${parentData?.uid}-${dt?.uid}`)
                    pubnub.objects.setMemberships({
                        uuid:parentData?.uid,
                        channels:[{
                            id:`${parentData?.uid}-${dt?.uid}`,
                            custom:{
                                lastReadTimetoken:`${moment().unix() * 10000000}`
                            }
                        }]
                    })
                } 
                else if (userType === 'BCBA') {
                    setChannel(`${parentData?.uid}-${dt?.uid}`)
                    pubnub.objects.setMemberships({
                        uuid:parentData?.uid,
                        channels:[{
                            id:`${parentData?.uid}-${dt?.uid}`,
                            custom:{
                                lastReadTimetoken:`${moment().unix() * 10000000}`
                            }
                        }]
                    })
                }
                else if (userType === 'BC'  && user_type === 'BCBA') {
                    setChannel(`${dt?.uid}-${parentData?.uid}`)
                    pubnub.objects.setMemberships({
                        uuid:parentData?.uid,
                        channels:[{
                            id:`${dt?.uid}-${parentData?.uid}`,
                            custom:{
                                lastReadTimetoken:`${moment().unix() * 10000000}`
                            }
                        }]
                    })
                }
                else if(userType === 'BC'  && user_type !== 'BCBA') {
                    setChannel(`${parentData?.uid}-${dt?.uid}`)
                    pubnub.objects.setMemberships({
                        uuid:parentData?.uid,
                        channels:[{
                            id:`${parentData?.uid}-${dt?.uid}`,
                            custom:{
                                lastReadTimetoken:`${moment().unix() * 10000000}`
                            }
                        }]
                    })
                }
                else if (userType === 'RBT') {
                    setChannel(`${dt?.uid}-${parentData?.uid}`)
                    pubnub.objects.setMemberships({
                        uuid:parentData?.uid,
                        channels:[{
                            id:`${dt?.uid}-${parentData?.uid}`,
                            custom:{
                                lastReadTimetoken:`${moment().unix() * 10000000}`
                            }
                        }]
                    })
                }

                dt.isActive = true
                setSelectedUser(dt)
                return dt
            }
            dt.isActive = false
            return dt
        })

        setUsersList(newArr)
    }

    useEffect(()=>{
        return()=>{
            pubnub.unsubscribeAll()
        }
    },[])

    const handleChangeSelectedType = (type) =>{
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        setIsLoadedList(false)

        if(isLoadedList){
            if(type == 1){
                setSelectedType(type)
                setSelectedUser(null)
                dt&&
                getParents(dt)
            }
            else{
                setSelectedType(type)
                setSelectedUser(null)
                dt&&
                getTherapists(dt)
            }
        }
    }

    const getParents = (dt) =>{
        const path = Decrypt(dt.userType) === 'BC' ? '/api/get-clinician-parents' :Decrypt(dt.userType) === 'BCBA' ? '/api/get-bcba-parents' : '/api/get-rbt-parents'
        fetch(baseURL+ path,{
            method:'Post',
            body:JSON.stringify({uid:dt.uid}),
            headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
        })
        .then(res=>res.json())
        .then(async res2=>{
            if(res2.code === 'Error'){
                toast.error('error..')
                setIsLoaded(true)
                setIsLoadedList(true)
            }
            else{
                
                if(res2?.message?.length > 0){

                    const newChats = await res2?.message?.reduce(async (chat, dt) => {
                        pubnub.subscribe({channels:[`${parentData?.uid}-${dt?.uid}`],withPresence:true}) // therapist-parent
                        let newArr = await chat
                        const res = await pubnub.objects.getMemberships({uuid:parentData?.uid,include: {totalCount:true,customFields: true}})
                        let count;
                        if(res?.data?.length > 0){
                            count = await pubnub.messageCounts({
                                channels: [`${parentData?.uid}-${dt?.uid}`],
                                channelTimetokens: [res?.data[0]?.custom?.lastReadTimetoken]
                            })
                        }

                        dt.isActive = false
                        dt.isUnread = count?.channels[`${parentData?.uid}-${dt?.uid}`] == 0 ? false : true
                        newArr = [...newArr,dt]
                        return newArr
                    }, [])

                    setUsersList(newChats)
                    setIsLoaded(true)
                    setIsLoadedList(true)
                }
                else{
                    setUsersList([])
                    setIsLoaded(true)
                    setIsLoadedList(true)
                }
            }
        })
    }

    const getTherapists = (dt) =>{
        const path = Decrypt(dt.userType) === 'BC' ? `/api/getAllAssignedTherapistBC?Id=${dt.uid}` :Decrypt(dt.userType) === 'BCBA' ? `/api/getAllAssignedTherapistBCBA?Id=${dt.uid}` : `/api/getAllAssignedTherapistRBT?Id=${dt.uid}`
        
        fetch(baseURL+ path,{
            headers:{'authorization':'Bearer ' + dt.accessToken}
        })
        .then(res=>res.json())
        .then(async res2=>{
            if(res2.code === 'Error'){
                toast.error('error..')
                setIsLoaded(true)
                setIsLoadedList(true)
            }
            else{
                if(res2?.message?.length > 0){
                    const newChats = await res2?.message?.reduce(async (chat, dt) => {

                        if(userType === 'BCBA') {

                            pubnub.subscribe({channels:[`${parentData?.uid}-${dt?.uid}`],withPresence:true}) // therapist-parent
                            let newArr = await chat
                            const res = await pubnub.objects.getMemberships({uuid:parentData?.uid,include: {totalCount:true,customFields: true}})
                            
                            let count;
                            if(res?.data?.length > 0){
                                count = await pubnub.messageCounts({
                                    channels: [`${parentData?.uid}-${dt?.uid}`],
                                    channelTimetokens: [res?.data[0]?.custom?.lastReadTimetoken]
                                })
                            }
                            
                            dt.isActive = false
                            dt.isUnread = count?.channels[`${parentData?.uid}-${dt?.uid}`] == 0 ? false : true
                            newArr = [...newArr,dt]
                            return newArr

                        }
                        else if(userType === 'BC'  && Decrypt(dt.user_type) === 'BCBA') {

                            pubnub.subscribe({channels:[`${dt?.uid}-${parentData?.uid}`],withPresence:true}) 
                            let newArr = await chat
                            const res = await pubnub.objects.getMemberships({uuid:parentData?.uid,include: {totalCount:true,customFields: true}})
                            
                            let count;
                            if(res?.data?.length > 0){
                                count = await pubnub.messageCounts({
                                    channels: [`${dt?.uid}-${parentData?.uid}`],
                                    channelTimetokens: [res?.data[0]?.custom?.lastReadTimetoken]
                                })
                            }
                            
                            dt.isActive = false
                            dt.isUnread = count?.channels[`${dt?.uid}-${parentData?.uid}`] == 0 ? false : true
                            newArr = [...newArr,dt]
                            return newArr
                        }
                        else if(userType === 'BC'  && Decrypt(dt.user_type) !== 'BCBA') {

                            pubnub.subscribe({channels:[`${parentData?.uid}-${dt?.uid}`],withPresence:true}) 
                            let newArr = await chat
                            const res = await pubnub.objects.getMemberships({uuid:parentData?.uid,include: {totalCount:true,customFields: true}})
                            
                            let count;
                            if(res?.data?.length > 0){
                                count = await pubnub.messageCounts({
                                    channels: [`${parentData?.uid}-${dt?.uid}`],
                                    channelTimetokens: [res?.data[0]?.custom?.lastReadTimetoken]
                                })
                            }
                            
                            dt.isActive = false
                            dt.isUnread = count?.channels[`${parentData?.uid}-${dt?.uid}`] == 0 ? false : true
                            newArr = [...newArr,dt]
                            return newArr
                            
                        }
                        else if(userType === 'RBT') {

                            pubnub.subscribe({channels:[`${dt?.uid}-${parentData?.uid}`],withPresence:true})
                            let newArr = await chat
                            const res = await pubnub.objects.getMemberships({uuid:parentData?.uid,include: {totalCount:true,customFields: true}})
                            
                            let count;
                            if(res?.data?.length > 0){
                                count = await pubnub.messageCounts({
                                    channels: [`${dt?.uid}-${parentData?.uid}`],
                                    channelTimetokens: [res?.data[0]?.custom?.lastReadTimetoken]
                                })
                            }
                            
                            dt.isActive = false
                            dt.isUnread = count?.channels[`${dt?.uid}-${parentData?.uid}`] == 0 ? false : true
                            newArr = [...newArr,dt]
                            return newArr

                        }
                    }, [])

                    setUsersList(newChats)
                    setIsLoaded(true)
                    setIsLoadedList(true)
                }
                else{
                    setUsersList([])
                    setIsLoaded(true)
                    setIsLoadedList(true)
                }
            }
        })
    }

    return (
        <PubNubProvider client={pubnub}>
            <div className="therapist-dashboard-chats-container">
                <ParentDashboardSideBar active="chats" user={userType&&userType === 'BC' ? "BC" :userType&&userType === 'BCBA' ? 'BCBA' : 'RBT'} />
                <div style={{marginLeft:windowWidth > 1024 && sidebarWidth}}>
                    <ClinicianDashboardNavbar 
                        showAppointments={(userType&&userType === 'RBT' || userType === 'BCBA') ? false : true} 
                        user={userType&&userType === 'BC' ? "BC" :userType&&userType === 'BCBA' ? 'BCBA' : 'RBT'} 
                        active="chats" 
                    />
                    <div className="therapist-dashboard-chats-body">
                        <div style={{width:'100%',marginTop:0}}>
                            <h2 style={{fontFamily:'CocoSharp bold'}} className="mb-0">Messages</h2>
                            <ChatComponent 
                                contactListHeaderTitle="Parents"
                                contactListHeaderTitle1="Therapists"
                                selectedType={selectedType}
                                listLoaded={isLoadedList}
                                changeSelectedType={handleChangeSelectedType}
                                type="2"
                                contactList={usersList}
                                hanldeSelectChat={(Id,userType)=>hanldeSelectChat(Id,userType)}
                                selectedUser={selectedUser}
                                channel={channel}
                                senderImage={parentData?.image}
                                senderName={parentData?.first_name + ' ' + parentData?.last_name}
                                user="2"
                                userId={parentData?.uid}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </PubNubProvider>
    )
}