import Form2State from '../../States/OnBoardingFormStates/Form2State'

const Form2Reducer = (state=Form2State,action)=>{
    switch(action.type){
        case 'form2-draft-data':{
            state = action.payload
            return state
        }
        case 'onChangeAnyMedicationForm2':{
            state.anyMedication = action.payload
            return state
        }
        case 'onChangeSmokeForm2':{
            state.smoke = action.payload
            return state
        }
        case 'onChangeAlcoholicBeveragesSelectForm2':{
            state.isSelectedAlcoholic = action.payload
            return state
        }
        case 'onChangeAlcoholicBeveragesForm2':{
            state.alcoholicBeverages = action.payload
            return state
        }
        case 'onChangeUseDrugForm2':{
            state.drugs = action.payload
            return state
        }
        case 'onChangeCompilcationExpDuringPregForm2':{
            state.compilcationExpDuringPreg = action.payload
            return state
        }
        case 'onChangeBabyWasBornForm2':{
            state.babyWasBorn = action.payload
            return state
        }
        case 'onChangeComplicationDuringDeliveryForm2':{
            state.anyComplicationDuringDelivery = action.payload
            return state
        }
        case 'onChangeChildBirthWeightForm2':{
            state.childBirthWeight = action.payload
            return state
        }
        case 'onChangeChildBirthProblemForm2':{
            state.childBirthProblem = action.payload
            return state
        }
        case 'onChangePostDeliveryComplicationForm2':{
            state.postDeliveryComplication = action.payload
            return state
        }
        case 'onChangeDevelopmentMilestoneForm2':{
            const newArr = state.developmentalMilestones.map((dt,i)=>{
                if(i === action.payload.index){
                    dt.selected = action.payload.selected
                    return dt
                }
                return dt
            })

            return{
                ...state,
                developmentalMilestones:newArr
            }
        }
        case 'onChangeAgeSingleWordForm2':{
            state.ageSingleWordAppear = action.payload
            return state
        }
        case 'onChangeAgePhrasesForm2':{
            state.agePhrasesAppear = action.payload
            return state
        }

        case 'RESET_FORM_2':{
            state = Data
            return state
        }

        default:{
            return state
        }
    }
}


const Data = {
    anyMedication:{isSelected:'No',value:''},
    smoke:'No',
    isSelectedAlcoholic:'No',
    alcoholicBeverages:{howOften:'',consumedEachDay:''},
    drugs:{isSelected:'No',value:''},
    compilcationExpDuringPreg:'',
    babyWasBorn:'',
    anyComplicationDuringDelivery:{isSelected:'No',value:''},
    childBirthWeight:0,
    childBirthProblem:{value:'Jaundice',detail:''},
    postDeliveryComplication:{isSelected:'No',value:''},
    developmentalMilestones:[
        {value:'Smiled',selected:'Never'},
        {value:'Rolled over',selected:'Never'},
        {value:'Crawled',selected:'Never'},
        {value:'Walked',selected:'Never'},
        {value:'Ran',selected:'Never'},
        {value:'Babbled',selected:'Never'},
        {value:'First word',selected:'Never'},
        {value:'Sentences',selected:'Never'},
        {value:'Tied shoelaces',selected:'Never'},
        {value:'Dressed self',selected:'Never'},
        {value:'Fed self',selected:'Never'},
        {value:'Bladder trained, day',selected:'Never'},
        {value:'Bladder trained, night',selected:'Never'},
        {value:'Bowel trained',selected:'Never'},
        {value:'Rode tricycle',selected:'Never'},
        {value:'Rode bicycle',selected:'Never'}
    ],
    ageSingleWordAppear:0,
    agePhrasesAppear:0
}


export default Form2Reducer;