import React,{useRef,useEffect, useState} from 'react';
import Avatar from 'react-avatar';
import ChatFooterComp from './ChatFooterComp';
import ChatSideDrawer from './ChatSideDrawer';
import FirstUserBubble from './FirstUserBubble';
import SecondUserBubble from './SecondUserBubble';
import { usePubNub } from 'pubnub-react';
import { FaBars } from 'react-icons/fa';
import moment from 'moment'
import './CSS/MessagesContainer.css'

export default function MessagesContainer({name,image,status,isShowMessages,list,hanldeSelectChat,channel,senderImage,senderName,senderId,user,userType,headerTitle1,selectedType,changeSelectedType,type}) {

    const pubnub = usePubNub()
    const [visible,setVisible] = useState(false)
    const [messages,setMessages] = useState([])
    const [typingUser,setTypingUser] = useState(null)

    const AlwaysScrollToBottom = () => {
        const elementRef = useRef();
        useEffect(() => elementRef.current.scrollIntoView());
        return <div ref={elementRef} />;
    };

    useEffect(()=>{
        pubnub.fetchMessages({channels:[channel],count:25},(status,response)=>{
            setMessages(response?.channels[channel])
        })
        pubnub.addListener({ 
            message: (event)=> setMessages(msgs=>msgs?.length > 0 ? [...msgs,event] : [event]), 
            file:(event)=> setMessages(msgs=>msgs?.length > 0 ? [...msgs,{message:{...event}}] : [{message:{...event}}]),
            signal:(event)=>{
                if(event?.message?.isTyping && event?.message?.name != senderName){
                    setTypingUser(event?.message)
                }else{
                    setTypingUser(null)
                }
            }
        });
    },[pubnub])

    const handleSendMessage = (text) =>{
        const obj = {
            payload:text,
            sender:senderId,
            type:0,
            senderImage:senderImage,
            senderName:senderName,
            date:Date.now()
        }
        pubnub.publish({
            channel:channel,
            message:{message:obj},
        })

        pubnub.objects.setMemberships({
            uuid:senderId,
            channels:[{
                id:channel,
                custom:{
                    lastReadTimetoken:`${moment().unix() * 10000000}`
                }
            }]
        })
    }

    const handleSendImageMessage = async(image) => {
        const obj = {
            sender:senderId,
            type:1,
            senderImage:senderImage,
            senderName:senderName,
            date:Date.now()
        }

        await pubnub.sendFile({
            channel:channel,
            file:image,
            message:obj
        })

        pubnub.objects.setMemberships({
            uuid:senderId,
            channels:[{
                id:channel,
                custom:{
                    lastReadTimetoken:`${moment().unix() * 10000000}`
                }
            }]
        })
    }

    const handleSendFileMessage = async (file) => {
        const obj = {
            sender:senderId,
            type:2,
            senderImage:senderImage,
            senderName:senderName,
            date:Date.now()
        }

        await pubnub.sendFile({
            channel:channel,
            file:file,
            message:obj
        })

        pubnub.objects.setMemberships({
            uuid:senderId,
            channels:[{
                id:channel,
                custom:{
                    lastReadTimetoken:`${moment().unix() * 10000000}`
                }
            }]
        })
    }

    const handleSendVoiceMessage = async (file) => {
        const obj = {
            sender:senderId,
            type:3,
            senderImage:senderImage,
            senderName:senderName,
            date:Date.now()
        }

        await pubnub.sendFile({
            channel:channel,
            file:file,
            message:obj
        })

        pubnub.objects.setMemberships({
            uuid:senderId,
            channels:[{
                id:channel,
                custom:{
                    lastReadTimetoken:`${moment().unix() * 10000000}`
                }
            }]
        })
    }
 
    const handleOnFocus = () =>{
        pubnub.signal({
            channel:channel,
            message:{
                name:senderName,
                isTyping:true
            }
        })
    }

    const handleUnFocus = () =>{
        pubnub.signal({
            channel:channel,
            message:{
                name:senderName,
                isTyping:false
            }
        })
    }
    
    return (
      <div className='chat-messages-container'>
          {
              isShowMessages ?
                <>
                    <div className='chat-messages-header'>
                        <div style={{width:'100%',padding:15,cursor:'pointer',display:'flex',alignItems:'center',borderRadius:15}}>
                            <Avatar src={image} name={name} round size='45px' />
                            <div style={{marginLeft:10,width:'100%'}}>
                                <p style={{margin:0,fontSize:14,fontFamily:'CocoSharp bold',color:'#52585D'}}>{name}</p>
                                {
                                    typingUser&&typingUser?.isTyping ?
                                        <p style={{margin:0,marginTop:0,fontSize:11,color:'#52585D'}}>{typingUser?.name} is typing...</p>
                                    :
                                        <p style={{margin:0,marginTop:0,fontSize:11,color:'#52585D'}}>{userType}</p>
                                }
                            </div>
                        </div>
                        <div onClick={()=>setVisible(true)}>
                            <FaBars color="gray" size={20} id="chat-contact-list-bar" />
                        </div>
                    </div>
                    <div className='chat-messages-body'>
                        {
                            messages?.map((dt,i)=>{
                                if(dt?.message?.message?.sender == senderId){
                                    return(
                                        <React.Fragment key={i}>
                                            <FirstUserBubble
                                                senderImage={dt?.message?.message?.senderImage}
                                                senderName={dt?.message?.message?.senderName}
                                                message={dt?.message}
                                                messageType={dt?.message?.message?.type}
                                                date={dt?.message?.message?.date}
                                                channel={channel}
                                            /> 
                                        </React.Fragment>
                                    )
                                }
                                else {
                                    return(
                                        <React.Fragment key={i}>
                                            <SecondUserBubble
                                                senderImage={dt?.message?.message?.senderImage}
                                                senderName={dt?.message?.message?.senderName}
                                                message={dt?.message}
                                                messageType={dt?.message?.message?.type}
                                                date={dt?.message?.message?.date}
                                                channel={channel}
                                            />
                                        </React.Fragment>
                                    )
                                }
                            })
                        }
                        <AlwaysScrollToBottom />
                    </div>
                    <div className='chat-messages-footer'>
                        <ChatFooterComp 
                            handleSendMessage={handleSendMessage}
                            handleSendFileMessage={handleSendFileMessage}
                            handleSendImageMessage={handleSendImageMessage}
                            handleSendVoiceMessage={handleSendVoiceMessage}
                            handleOnFocus={handleOnFocus}
                            handleUnFocus={handleUnFocus}
                            channel={channel}
                            typingUser={typingUser}
                        />
                    </div>
                </>
              :
                <>
                    <div className='chat-messages-header'>
                        <div style={{width:'100%',padding:15,cursor:'pointer',display:'flex',alignItems:'center',borderRadius:15}}>
                        </div>
                        <div onClick={()=>setVisible(true)}>
                            <FaBars color="gray" size={20} id="chat-contact-list-bar" />
                        </div>
                    </div>
                    <div style={{width:'100%',marginTop:'25%',justifyContent:'center',alignItems:'center'}}>
                        <h3 style={{color:'lightgray',textAlign:'center'}}>Please Select To See Messages</h3>
                    </div>
                </>
        }
        <ChatSideDrawer 
            visible={visible}
            onClose={()=>setVisible(false)}
            headerTitle="Doctors"
            list={list}
            headerTitle1={headerTitle1}
            selectedType={selectedType}
            changeSelectedType={changeSelectedType}
            type={type}
            hanldeSelectChat={(Id)=>(setVisible(false),hanldeSelectChat(Id))}
        />
      </div>
  )
}
