import React from "react";
import heroImg from "../../assets/about/hero.png";
import heroDesk from "../../assets/about/hero-desk.png";
import curve from "../../assets/about/curve.png";
import PinkBtn from "../../Components/PinkBtn";

function Hero() {
  return (
    <div className=" relative z-[3] mt-[100px] flex justify-between items-center gap-7 xl:gap-0 bg-lightGreen-100 xl:px-0 xl:flex-row flex-col py-6  xl:py-0">
      <div className="xl:block flex justify-center flex-[0.5] h-full relative z-[3]">
        <img className="w-[100%] xl:block hidden" src={heroDesk} alt="" />
        <img
          className="w-[100%] rounded-lg xl:hidden block xl:rounded-tl-[50%] xl:rounded-bl-[50%] "
          src={heroImg}
          alt=""
        />
      </div>
      <div className="xl:pl-14 xl:pb-[150px] text-justify flex-[0.5] mx-3 xl:mx-3 my-10 flex justify-center items-center xl:text-left xl:items-start gap-4 flex-col">
        <h1 className="text-lightGreen-500 font-bold text-4xl lg:text-4xl xl:text-5xl mb-0">
          Our Story
        </h1>

        <p className="text-lightGreen-500 font-normal text-base 2xl:text-lg 2xl:w-3/5 mb-0 md:leading-6">
          We’re a unique team of psychologists, scientists, researchers and engineers
          with a passion for making a difference in the lives of children with Autism
          and their families.
        </p>
        <p className="text-lightGreen-500 font-normal text-base 2xl:text-lg 2xl:w-3/5 mb-0 lg:leading-6">
          Our experts have devoted their careers to Autism research and advocated
          for better care and access to services across continents.
        </p>
        <p className="text-lightGreen-500 font-normal text-base 2xl:text-lg 2xl:w-3/5 mb-0 lg:leading-6">
          At Cognitia, we’re building on this passion by combining research with technology
          to offer evidence-based programs with a family focused approach to ensure the best
          possible outcomes.
        </p>
        <p className="text-lightGreen-500 font-normal text-base 2xl:text-lg 2xl:w-3/5 mb-0 lg:leading-6">
          We strive to nurture the potential of children with autism and support their
          families every step of the way. Embracing your role as your child's first and most
          important teacher, we partner with you to help create a supportive and nurturing environment
          for you and your child.
        </p>
        <div className="xl:mt-3 mb-10" >
          <PinkBtn text={"Meet Our Team"} url={"Team"} />
        </div>
      </div>
      <img
        src={curve}
        className="absolute object-cover xl:block hidden bottom-[0%]  left-0 w-full z-10"
        alt=""
      />
    </div>
  );
}

export default Hero;
