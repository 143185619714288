import React from "react";
import heroImg from "../../assets/autism/hero.png";
import heroDesk from "../../assets/autism/hero-desk.png";
import curve from "../../assets/autism/curve.png";
import PinkBtn from "../../Components/PinkBtn";
function HeroSection() {
  return (
    <div className=" relative z-[3] mt-[100px] flex justify-between items-center gap-7 xl:gap-0 bg-lightGreen-100 xl:px-0 xl:flex-row flex-col py-6  xl:py-0">
      <div className="xl:block flex justify-center flex-[0.5] h-full relative z-[3]">
        <img className="w-[100%] xl:block hidden" src={heroDesk} alt="" />
        <img
          className="w-[100%] rounded-lg xl:hidden block xl:rounded-tl-[50%] xl:rounded-bl-[50%] "
          src={heroImg}
          alt=""
        />
      </div>
      <div className="flex-[0.5] xl:pb-9 xl:pl-10 flex justify-center items-center xl:text-left xl:items-start gap-4 flex-col">
        <h1 className="text-lightGreen-500 font-bold md:text-5xl text-2xl mb-0">
          What is Applied <br /> Behavior Analysis?
        </h1>

        <p className="text-lightGreen-500 font-normal mr-4 xl:mx-0 text-justify text-lg 2xl:w-3/5 mb-0">
          Applied Behavior Analysis is the process of systematically applying
          interventions based upon the principles of learning theory to improve
          socially significant behaviors to a meaningful degree, and to
          demonstrate that the interventions employed are responsible for the
          improvement in behavior
        </p>
        <div className="xl:mt-1">
          <PinkBtn text={"Get Started"} url="signup" />
        </div>
      </div>{" "}
      <img
        src={curve}
        className="absolute object-cover xl:block hidden bottom-[0%]  left-0 w-full z-10"
        alt=""
      />
    </div>
  );
}

export default HeroSection;
