import AppointmentsState from '../States/AppointmentsState'

const AppointmentReducer = (state=AppointmentsState,action)=>{
    switch(action.type){
        case 'GetAppointments':
        {
            
            const uppcomingApp = action.payload&&action.payload.filter(dt=>dt.status === 0)
            const prevApp = action.payload&&action.payload.filter(dt=>dt.status !== 0)
            
            return{
                upcomingApp:uppcomingApp,
                previousApp:prevApp,
                isLoaded:true
            }
        }
        case 'AppointmentIsLoaded':{
            state.isLoaded = action.payload
            return state
        }
        default:
        {
            return state
        }
    }
}

export default AppointmentReducer;