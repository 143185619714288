import React,{useEffect,useState} from 'react'
import {useSelector,useDispatch} from 'react-redux'
import {useLocation} from 'react-router-dom'
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import ClinicianDashboardNavbar from '../../Components/ClinicianDashboardNavbar'
import './CSS/ClinicianDashboardHome.css'
import DashboardHomeCard from '../../Components/DashboardHomeCard'
import Img1 from '../../images/clinician-profile-img.png'
import Img2 from '../../images/clinician-db-img2.png'
import Img3 from '../../images/clinician-db-img3.png'
import Img4 from '../../images/clinician-db-img4.png'
import Img5 from '../../images/clinician-db-img5.png'
import dashboardResource from '../../images/dashboard-resource-img.png'

export default function ClinicianDashboardHome() {

    const dispatch = useDispatch()
    const location = useLocation()

    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const sidebarWidth = useSelector(data => data.SidebarReducer.width)


    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })
    },[])

    
    useEffect(()=>{
        location?.state?.reload && 
        window.location.reload()
    },[location])

    return (
        <div className="bc-dashboard-home">
            <ParentDashboardSideBar active="home" user="BCBA"  />
            <div style={{marginLeft:windowWidth > 1024 &&sidebarWidth}}>
                <ClinicianDashboardNavbar active="home" user="BCBA" showAppointments={false} />
                <div className="bc-dashboard-home-body">
                    <DashboardHomeCard
                        title="Behavior Consultants"
                        buttonTitle="Connect Now"
                        imageUrl={Img1}
                        path="/behaviour-consultants" 
                    />
                    <DashboardHomeCard
                        title="Clinician's Reports"
                        buttonTitle="Explore Now"
                        imageUrl={Img5}
                        path="/diagnosis-reports" 
                    />
                    <DashboardHomeCard
                        title="Sessions"
                        buttonTitle="View Sessions"
                        imageUrl={Img4}
                        path="/bcba-sessions"
                    />
                    <DashboardHomeCard
                        title="Resources "
                        buttonTitle="Explore Now"
                        imageUrl={dashboardResource}
                        row="2"
                        path="/additional-resources" 
                    />
                    <DashboardHomeCard
                        title="Oasis Coach Training Material"
                        buttonTitle="Explore Now"
                        imageUrl={Img2}
                        isDisable={true}
                        row="2"
                        // path="/dashboard-instructins" 
                    />
                    
                    {/* <DashboardHomeCard
                        title="Clinician's Notes"
                        buttonTitle="Connect Now"
                        imageUrl={Img3}
                        // path="/" 
                    /> */}
                    
                    
                     <DashboardHomeCard
                        title=""
                        buttonTitle=""
                        imageUrl=""
                        row="2"
                        display="none"
                        style={{backgroundColor:'transparent',border:'none',boxShadow:'none' }}
                        // path="/" 
                    />
                    
                </div>
            </div>
        </div>
    )
}
