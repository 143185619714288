import { Decrypt } from '../../Config'
import ParentState from '../States/ParentState'

const ParentReducer = (state=ParentState,action)=>{
    switch(action.type){
        case 'getParentData':
        {
            state.parentData = action.payload
            return state
        }
        case 'UpdateImage':
        {
            state.parentData.image = action.payload
            return state
        }
        case 'SetIsLoading':
        {
            state.isLoading = action.payload
            return state
        }
        case 'ChangeName':
        {
            state.parentData.first_name = Decrypt(action.payload.first_name)
            state.parentData.last_name = Decrypt(action.payload.last_name)
            return state
        }
        case 'setUserPayment':
        {
            state.isPay = action.payload
            return state
        }
        default :
        {
            return state
        }
    }
}

export default ParentReducer;