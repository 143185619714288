import React from "react";
import img from "../../assets/team/team5.png";
function Hannah() {
  return (
      <div className="bg-[#FFEEEF] w-full flex justify-center items-center py-11">
        <div className="max-w-[1440px] flex justify-between flex-col-reverse lg:flex-row items-center px-10 w-full gap-10">
          <img src={img} alt="" />
          <div className="flex justify-center flex-col items-start gap-4">
            <h1 className="font-bold text-lightGreen-500 text-3xl md:text-5xl">
              Hanna Traphagan
            </h1>
            <h2 className="font-bold text-lightGreen-500 text-xl md:text-2xl">
              M.Ed
            </h2>
            <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
              Hanna received her undergraduate degree in Biological Health with a
              minor in Psychology from University of Oklahoma, and her Masters of
              Education in Public Health Education from Texas State University.
            </p>
            <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
              Hanna has experience working in statewide youth tobacco prevention,
              juvenile delinquency prevention, and youth substance use prevention.
            </p>
          </div>
        </div>
      </div>
  );
}

export default Hannah;
