import React,{useState,useEffect} from 'react'
import {useSelector} from 'react-redux'
import ParentDashboardSideBar from '../../../Components/ParentDashboardSideBar'
import ParentDashboardNavbar from '../../../Components/ParentDashboardNavbar'
import OrderDetailComponent from '../../../Components/OrderDetailComponent'
import InsurancePaymentModal from '../../../Components/InsurancePaymentModal'
import SelfPayModal from '../../../Components/SelfPayModal'
import {useHistory} from 'react-router-dom'
import {Encrypt,baseURL,headers} from '../../../Config'
import { toast } from 'react-toastify';
import './CSS/PaymentDetail.css'

export default function PaymentDetail() {

    const history = useHistory()

    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const [showInsuranceModal,setShowInsuranceModal] = useState(false)
    const [showSelfModal,setShowSelfModal] = useState(false)
    const [isLoadingSelf,setIsLoadingSelf] = useState(false)
    const [isLoading,setIsLoading] = useState(false)


    const sidebarWidth = useSelector(data => data.SidebarReducer.width)

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })
    },[])


    const handleConfirmSelfPay = () =>{
        const data = JSON.parse(localStorage.getItem('bx_user_token'))
        const diagnosis_type = JSON.parse(localStorage.getItem('diagnosisType'))
        const obj = {
            diagnosis_type:Encrypt(diagnosis_type?.isRecom),
            mchat_score:diagnosis_type?.score ? Encrypt(`${diagnosis_type?.score}`) : '',
            Id:data.uid
        }

        setShowSelfModal(false)
        setIsLoadingSelf(true)
        fetch(baseURL + '/api/payWithCardAndCreateChild',{
            method:'Post',
            body:JSON.stringify(obj),
            headers:{...headers,'authorization':'Bearer ' + data.accessToken}
        })
        .then(res=>res.json())
        .then(res2=>{
            if(res2.code === 'Success'){
                localStorage.removeItem('diagnosisType')
                window.localStorage.setItem('bx_user_token',JSON.stringify({...data,childId:res2.message.childId,childStatus:Encrypt(res2.message.childStatus)}))
                setIsLoadingSelf(false)
                history.push('/pre-call-questionnaire')
            }
            else{
                toast.error(res2.message,{toastId:'insurance-req-err-id1',autoClose:3000})
                setIsLoadingSelf(false)
            }
        })
    }

    const handleConfirmInsurancePay = ({company,insurancePlan,holderName,insuranceId,expiryDate}) =>{
        const date = expiryDate.toString().split(' ')
        const newDate = date[0] + ' ' + date[1] + ' ' + date[2] + ' ' + date[3] 

        const data = JSON.parse(localStorage.getItem('bx_user_token'))
        const diagnosis_type = JSON.parse(localStorage.getItem('diagnosisType'))

        const obj = {
            companyName:Encrypt(company),
            insurancePlan:Encrypt(insurancePlan),
            holderName:Encrypt(holderName),
            insuranceId:Encrypt(insuranceId),
            expiryDate:Encrypt(newDate),
            totalAmount:Encrypt('1000'),
            diagnosis_type:Encrypt(diagnosis_type?.isRecom),
            mchat_score:diagnosis_type?.score ? Encrypt(`${diagnosis_type?.score}`) : '',
            Id:data.uid
        }

        setShowInsuranceModal(false)
        setIsLoading(true)
        fetch(baseURL + '/api/payWithInsuranceAndCreateChild',{
            method:'Post',
            body:JSON.stringify(obj),
            headers:{...headers,'authorization':'Bearer ' + data.accessToken}
        })
        .then(res=>res.json())
        .then(res2=>{
            if(res2.code === 'Success'){
                localStorage.removeItem('diagnosisType')
                window.localStorage.setItem('bx_user_token',JSON.stringify({...data,childId:res2.message.childId,childStatus:Encrypt(res2.message.childStatus)}))
                setIsLoading(false)
                history.push('/pre-call-questionnaire')
            }
            else{
                toast.error(res2.message,{toastId:'insurance-req-err-id1',autoClose:3000})
                setIsLoading(false)
            }
        })
    }

    return (
        <div className="parent-dashboard-payment-detail">
            <ParentDashboardSideBar active="child" />
            <div style={{marginLeft:windowWidth > 1024 && sidebarWidth}}>
                <ParentDashboardNavbar active="child" />
                <div className="parent-dashboard-payment-detail-body">
                    <OrderDetailComponent 
                        path1="/self-pay" 
                        path2="/insurance-pay"
                        showModals={true}
                        isLoadingSelf={isLoadingSelf}
                        isLoading={isLoading}
                        onClick2={()=>setShowInsuranceModal(true)}
                        onClick1={()=>setShowSelfModal(true)}
                    />
                </div>
            </div>

            {/* Insurance Pay Modal */}
            <InsurancePaymentModal
                show={showInsuranceModal} 
                setShow={()=>setShowInsuranceModal(false)}
                handleConfirmPay={({company,insurancePlan,holderName,insuranceId,expiryDate})=>handleConfirmInsurancePay({company,insurancePlan,holderName,insuranceId,expiryDate})}
            />

            {/* Self Pay Modal */}
            <SelfPayModal 
                show={showSelfModal} 
                setShow={()=>setShowSelfModal(false)}
                handleConfirmPay={()=>handleConfirmSelfPay()}
            />
        </div>
        
    )
}
