import React from 'react'
import Lottie from "lottie-react";
import loader from '../images/DataLoader.json'
import {Spinner} from 'react-bootstrap'

export default function DataLoader() {
    // return <Spinner animation="border" style={{opacity:0.3,width:50,height:50}}/>
    return (
        <div className="w-100 d-flex flex-column align-items-center justify-content-center">
            <Lottie style={{height:120,width:120}} animationData={loader} />
            <h5 style={{color:'gray'}}>Thanks for your patience, Please wait!</h5>
        </div>
    )
}
