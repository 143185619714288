import React, { useState, useEffect } from 'react'
import { Row, Col, Modal } from 'react-bootstrap'
import Loader from '../../../Components/DataLoader'
import Header from "../../../Components/Header";
import Footer from "../../../Components/Footer";
import Informed from "../../../Components/Informed";
import TopBar from '../../../Components/TopBar'
import TopStepBar from './TopStepBar'
import SideStepBar from './SideStepBar'
import PreCallQuestionForm1 from './PreCallQuestionForm1'
import PreCallQuestionForm2 from './PreCallQuestionForm2'
import PreCallQuestionForm3 from './PreCallQuestionForm3'
import PreCallQuestionForm4 from './PreCallQuestionForm4'
import PreCallQuestionForm5 from './PreCallQuestionForm5'
import PreCallQuestionForm6 from './PreCallQuestionForm6'
import {useHistory} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { FaSave, FaPlus } from 'react-icons/fa'
import { BsInfoCircleFill } from 'react-icons/bs'
import { RiDraftFill } from 'react-icons/ri'
import { Fab, Action } from 'react-tiny-fab'
import 'react-tiny-fab/dist/styles.css'
import './CSS/PreCallQuestionIndex.css'
import { baseURL, headers,Encrypt, Decrypt } from '../../../Config'

export default function PreCallQuestionIndex() {

    const history = useHistory()

    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(false)
    const [showModal,setShowModal] = useState(true)

    //Steps State
    const [step, setStep] = useState(1)
    const [isLoaded,setIsLoaded] = useState(false)


    // forms Data
    const form1Data = useSelector(state => state.Form1Reducer)
    const form2Data = useSelector(state => state.Form2Reducer)
    const form3Data = useSelector(state => state.Form3Reducer)
    const form4Data = useSelector(state => state.Form4Reducer)
    const form5Data = useSelector(state => state.Form5Reducer)
    const form6Data = useSelector(state => state.Form6Reducer)


    // State for Side and top bar error
    const [form1Err, setForm1Err] = useState(false)
    const [form3Err, setForm3Err] = useState(false)
    const [form6Err, setForm6Err] = useState(false)

    /// Form 1 Error States
    const [form1NameErr, setForm1NameErr] = useState(false)
    const [form1BirthDateErr, setForm1BirthDateErr] = useState(false)
    const [form1SchoolErr, setForm1SchoolErr] = useState(false)
    const [form1FamilyDocErr, setForm1FamilyDocErr] = useState(false)
    const [form1GradeErr, setForm1GradeErr] = useState(false)
    const [form1ReferingDocErr, setForm1ReferingDocErr] = useState(false)
    const [form1OtherLangErr, setForm1OtherLangErr] = useState(false)
    const [form1PrimaryLangErr, setForm1PrimaryLangErr] = useState(false)
    const [form1Parent1NameErr, setForm1Parent1NameErr] = useState(false)
    const [form1Parent1AgeErr, setForm1Parent1AgeErr] = useState(false)
    const [form1Parent1CellErr, setForm1Parent1CellErr] = useState(false)
    const [form1Parent1EmailErr, setForm1Parent1EmailErr] = useState(false)
    const [form1Parent2NameErr, setForm1Parent2NameErr] = useState(false)
    const [form1Parent2AgeErr, setForm1Parent2AgeErr] = useState(false)
    const [form1Parent2CellErr, setForm1Parent2CellErr] = useState(false)
    const [form1Parent2EmailErr, setForm1Parent2EmailErr] = useState(false)

    // Form 3 Error States
    const [form3IllnessOrCondErr, setForm3IllnessOrCondErr] = useState([])
    const [form3VissionProbErr, setForm3VissionProbErr] = useState(false)
    const [form3HearingProbErr, setForm3HearingProbErr] = useState(false)
    const [form3FamilyMedicationErr, setForm3FamilyMedicationErr] = useState([])

    
    // Form 6 Error States
    const [form6Image, setForm6Image] = useState(false)
    const [form6Docs, setForm6Docs] = useState(false)


    const Form1ErrFunction = () =>{

        setForm1NameErr(form1Data.Name.isNull)
        setForm1BirthDateErr(form1Data.birthDate.isNull)
        setForm1SchoolErr(form1Data.schoolName.isNull)
        setForm1FamilyDocErr(form1Data.familyDoc.isNull)
        setForm1GradeErr(form1Data.grade.isNull)
        setForm1ReferingDocErr(form1Data.refferingDoc.isNull)
        setForm1PrimaryLangErr(form1Data.primaryLang.isNull)
        setForm1OtherLangErr(form1Data.otherLang.isNull)

        // const parent1Name = form1Data.livesWith[0].isLived === 'Yes' && form1Data.livesWith[0].Name.isNull;
        // const parent1NameErr = form1Data.livesWith[0].isLived === 'Yes' && form1Data.livesWith[0].Name.isErr;
        // const parent1Age = form1Data.livesWith[0].isLived === 'Yes' && form1Data.livesWith[0].Age.isNull;
        // const parent1Cell = form1Data.livesWith[0].isLived === 'Yes' && form1Data.livesWith[0].cell.isNull;
        // const parent1Cell2 = form1Data.livesWith[0].isLived === 'Yes' && form1Data.livesWith[0].cell.isLimitErr;
        // const parent1Email = form1Data.livesWith[0].isLived === 'Yes' && form1Data.livesWith[0].email.isNull;
        // const parent1EmailErr = form1Data.livesWith[0].isLived === 'Yes' && form1Data.livesWith[0].email.isErr;
      
        const parent1Name = form1Data.livesWith[0].Name.isNull;
        const parent1NameErr = form1Data.livesWith[0].Name.isErr;
        const parent1Age = form1Data.livesWith[0].Age.isNull;
        const parent1Cell = form1Data.livesWith[0].cell.isNull;
        const parent1Cell2 = form1Data.livesWith[0].cell.isLimitErr;
        const parent1Email = form1Data.livesWith[0].email.isNull;
        const parent1EmailErr = form1Data.livesWith[0].email.isErr;

        
        setForm1Parent1NameErr(parent1Name)
        setForm1Parent1AgeErr(parent1Age)
        setForm1Parent1CellErr(parent1Cell)
        setForm1Parent1EmailErr(parent1Email)

        const parent2Name = form1Data.livesWith[1].isLived === 'Yes' && form1Data.livesWith[1].Name.isNull;
        const parent2NameErr = form1Data.livesWith[1].isLived === 'Yes' && form1Data.livesWith[1].Name.isErr;
        const parent2Age = form1Data.livesWith[1].isLived === 'Yes' && form1Data.livesWith[1].Age.isNull;
        const parent2Cell = form1Data.livesWith[1].isLived === 'Yes' && form1Data.livesWith[1].cell.isNull;
        const parent2Cell2 = form1Data.livesWith[1].isLived === 'Yes' && form1Data.livesWith[1].cell.isLimitErr;
        const parent2Email = form1Data.livesWith[1].isLived === 'Yes' && form1Data.livesWith[1].email.isNull;
        const parent2EmailErr = form1Data.livesWith[1].isLived === 'Yes' && form1Data.livesWith[1].email.isErr;

        // const parent2Name = form1Data.livesWith[1].Name.isNull;
        // const parent2NameErr = form1Data.livesWith[1].Name.isErr;
        // const parent2Age = form1Data.livesWith[1].Age.isNull;
        // const parent2Cell = form1Data.livesWith[1].cell.isNull;
        // const parent2Cell2 = form1Data.livesWith[1].cell.isLimitErr;
        // const parent2Email = form1Data.livesWith[1].email.isNull;
        // const parent2EmailErr = form1Data.livesWith[1].email.isErr;

        setForm1Parent2NameErr(parent2Name)
        setForm1Parent2AgeErr(parent2Age)
        setForm1Parent2CellErr(parent2Cell)
        setForm1Parent2EmailErr(parent2Email)


        const isErrForm1 = form1Data.Name.isNull || form1Data.Name.isErr || form1Data.birthDate.isNull || form1Data.schoolName.isNull || 
            form1Data.familyDoc.isNull || form1Data.familyDoc.isErr || form1Data.grade.isNull || form1Data.refferingDoc.isNull || form1Data.refferingDoc.isErr ||
            form1Data.primaryLang.isNull || form1Data.primaryLang.isErr || form1Data.otherLang.isNull || parent1Name || parent1NameErr || parent1Age || parent1Cell || parent1Cell2 || parent1Email || parent1EmailErr
            || parent2Name || parent2NameErr || parent2Age || parent2Cell || parent2Email || parent2EmailErr || parent2Cell2

        if (isErrForm1) {
            setForm1Err(true)
            toast.error('Error in Background History Form', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId:"pre-call-form1"
            })

            return false
        }
        else {
            setForm1Err(false)
            return true
        }
    
    }


    const Form3ErrFunction = () =>{
        const newArr = []
        form3Data.illnessOrConditions.map((dt,i) => {
            if (dt.isSelected && dt.isNull) {
                newArr.push(i)
            }
        })
        setForm3IllnessOrCondErr(newArr)
 
        const vissionErr = form3Data.vissionProblemSelect === 'Yes' ? (form3Data.vissionProblem.isDetailNull || form3Data.vissionProblem.isDateNull) : false
        setForm3VissionProbErr(vissionErr)
        const hearingErr = form3Data.hearingProblemSelect === 'Yes' ? (form3Data.hearingProblem.isDetailNull || form3Data.hearingProblem.isDateNull) : false
        setForm3HearingProbErr(hearingErr)

        const newArr1 = []
        form3Data.familyMedication.map((dt,i) => {
            if (dt.isSelected && dt.isNull) {
                newArr1.push(i)
                
            }
        })
        setForm3FamilyMedicationErr(newArr1)

        let nameOfExaminerErr = false;
         form3Data.previousAssessments.map(dt => {
            if (dt.isErr) {
                nameOfExaminerErr = true
            }
        })


        const isForm3Err = newArr.length > 0 || form3VissionProbErr || form3HearingProbErr || newArr1.length > 0 || nameOfExaminerErr

        if (isForm3Err) {
            setForm3Err(true)
            toast.error('Error in Medical History Form', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId:"pre-call-form3"
            })
            return false
        }
        else {
            setForm3Err(false)
            return true
        }
    }

    const Form6ErrFunction = () =>{

        const form6Image = form6Data.imageUrl !== '' ? false : true
        const form6Docs = form6Data.documents.length > 0 ? false : true


        setForm6Image(form6Image)
        setForm6Docs(form6Docs)


        if(form6Image){
            setForm6Err(true)
            toast.error('Error in Other Information Form', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId:"pre-call-form6"
            })
            return false
        }
        else {
            setForm6Err(false)
            return true
        }
    }

    /// On Submit or Save data
    const OnSubmitFormData = () => {
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
       const form1 = Form1ErrFunction()
       const form3 = Form3ErrFunction()
    //    const form6 = Form6ErrFunction()
       const data = {formsData:Encrypt(JSON.stringify({form1Data,form2Data,form3Data,form4Data,form5Data,form6Data})),childId:dt.childId,uid:dt.uid}

       if(form1&&form3){
            setIsLoading(true)
           fetch(baseURL + '/api/add-child-detail',{
               method:'Post',
               body:JSON.stringify(data),
               headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
           })
           .then(res=>res.json())
           .then(res2=>{
               if(res2.code === 'Success'){
                   if(Decrypt(dt.userStatus) == '1'){
                        setIsLoading(false)
                        dispatch({type:'RESET_FORM_1',payload: null})
                        dispatch({type:'RESET_FORM_2',payload: null})
                        dispatch({type:'RESET_FORM_3',payload: null})
                        dispatch({type:'RESET_FORM_4',payload: null})
                        dispatch({type:'RESET_FORM_5',payload: null})
                        dispatch({type:'RESET_FORM_6',payload: null})
                        history.push('/childs')
                   }
                   else{
                       const dt = JSON.parse(localStorage.getItem('bx_user_token'))
                       dt.childStatus = Encrypt('2')
                       localStorage.setItem('bx_user_token',JSON.stringify(dt))
                       setIsLoading(false)
                       dispatch({type:'RESET_FORM_1',payload: null})
                       dispatch({type:'RESET_FORM_2',payload: null})
                       dispatch({type:'RESET_FORM_3',payload: null})
                       dispatch({type:'RESET_FORM_4',payload: null})
                       dispatch({type:'RESET_FORM_5',payload: null})
                       dispatch({type:'RESET_FORM_6',payload: null})
                       history.push('/schedule-first-meeting')
                   }
               }
               else{
                setIsLoading(false)
                toast.error('There is some error', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
               }
           })
       }
    }



    useEffect(() => {
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        setIsLoaded(true)
        fetch(baseURL+'/api/get-draft-data',{
            method:'Post',
            body:JSON.stringify({uid:dt.uid}),
            headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
        })
        .then(res=>res.json())
        .then(res2=>{
            if(res2.code === 'Success'){
                setIsLoaded(true)
                if(res2.message){
                    dispatch({type:'form1-draft-data',payload: JSON.parse(res2.message).form1Data})
                    dispatch({type:'form2-draft-data',payload: JSON.parse(res2.message).form2Data})
                    dispatch({type:'form3-draft-data',payload: JSON.parse(res2.message).form3Data})
                    dispatch({type:'form4-draft-data',payload: JSON.parse(res2.message).form4Data})
                    dispatch({type:'form5-draft-data',payload: JSON.parse(res2.message).form5Data})
                    dispatch({type:'form6-draft-data',payload: JSON.parse(res2.message).form6Data})
                }
            }
        })

    }, [])



    // handle save in draft
    const handleSaveDraft = () => {
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            const data = {formsData:Encrypt(JSON.stringify({form1Data,form2Data,form3Data,form4Data,form5Data,form6Data})),uid:dt.uid}
            fetch(baseURL+'/api/save-draft',{
                method:'Post',
                body:JSON.stringify(data),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2 => {
                if(res2.code === 'Error'){

                }
                else{
                    toast.success(res2.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        toastId:'save-draft-toast1'
                    })
                }
            })
        }   
    }

        return (
            <div style={{ width: '100%' }} className="pre-call-questionnaire-container">
                <Col sm='12' md='12' lg='12' xl='12' >
                    <Header />
                </Col>
                <Col sm='12' md='12' lg='12' xl='12' className="my-5 py-5 px-3 text-center pre-call-questionnaire-main-container-1">
                    <h5 style={{ color: 'white' }}>You, need to open site in desktop to fill the form.</h5>
                </Col>
                {
                    isLoaded ?
                        <Col sm='12' md='12' lg='12' xl='12' style={{marginTop:100}} className="p-0 pre-call-questionnaire-main-container-2">
                            <TopStepBar
                                isActive={step}
                                onChangeStep={(val) => setStep(val)}
                                form1Err={form1Err}
                                form3Err={form3Err}
                                form6Err = {form6Err}
                                handleSaveDraft={handleSaveDraft}
                            />
                            <Row className="m-0 py-5 px-0">
                                <Col sm={1} md={1} className="p-0 PreCallQuestionIndex-col-1">
                                    <SideStepBar
                                        isActive={step}
                                        onChangeStep={(val) => setStep(val)}
                                        form1Err={form1Err}
                                        form3Err={form3Err}
                                        form6Err = {form6Err}
                                    />
                                </Col>
                                <Col md={12} lg={10}>
                                    {
                                        step === 1 ?
                                            <PreCallQuestionForm1
                                                onChangeStep={(val) => setStep(val)}
                                                form1NameErr={form1NameErr}
                                                form1BirthDateErr={form1BirthDateErr}
                                                form1SchoolErr={form1SchoolErr}
                                                form1FamilyDocErr={form1FamilyDocErr}
                                                form1GradeErr={form1GradeErr}
                                                form1ReferingDocErr={form1ReferingDocErr}
                                                form1PrimaryLangErr={form1PrimaryLangErr}
                                                form1OtherLangErr={form1OtherLangErr}

                                                form1Parent1NameErr={form1Parent1NameErr}
                                                form1Parent1AgeErr={form1Parent1AgeErr}
                                                form1Parent1CellErr={form1Parent1CellErr}
                                                form1Parent1EmailErr={form1Parent1EmailErr}

                                                form1Parent2NameErr={form1Parent2NameErr}
                                                form1Parent2AgeErr={form1Parent2AgeErr}
                                                form1Parent2CellErr={form1Parent2CellErr}
                                                form1Parent2EmailErr={form1Parent2EmailErr}
                                            />
                                            : step === 2 ?
                                                <PreCallQuestionForm2 onChangeStep={(val) => setStep(val)} />
                                            : step === 3 ?
                                                <PreCallQuestionForm3
                                                    onChangeStep={(val) => setStep(val)}
                                                    illnessOrCondErr={form3IllnessOrCondErr}
                                                    form3VissionProbErr={form3VissionProbErr}
                                                    form3HearingProbErr={form3HearingProbErr}
                                                    form3FamilyMedicationErr={form3FamilyMedicationErr}
                                                />
                                            : step === 4 ?
                                                <PreCallQuestionForm4 onChangeStep={(val) => setStep(val)} />
                                            : step === 5 ?
                                                <PreCallQuestionForm5 onChangeStep={(val) => setStep(val)} />
                                            :
                                                <PreCallQuestionForm6 
                                                    onChangeStep={(val) => setStep(val)} 
                                                    onSubmitForm={() => OnSubmitFormData()}
                                                    form6Image = {form6Image}
                                                    form6Docs = {form6Docs}
                                                    isLoading={isLoading}
                                                />
                                    }
                                </Col>
                                <Col sm={1} md={1} className="p-0 PreCallQuestionIndex-col-3">
                                </Col>
                            </Row>
                        </Col>
                    :
                        <div style={{padding:'100px 0px'}}>
                            <Loader />
                        </div>
                }
                <Informed />
                <Footer />
                <div id="floated-action-btn">
                    <Fab
                        mainButtonStyles={{ backgroundColor: '#F99B9B' }}
                        icon={<FaPlus />}
                        alwaysShowTitle={true}

                    >
                        <Action text="Save Draft" style={{ backgroundColor: '#F99B9B' }} onClick={handleSaveDraft}>
                            <RiDraftFill />
                        </Action>
                        {
                            step === 6 &&
                            <Action text="Save" style={{ backgroundColor: '#F99B9B' }} onClick={OnSubmitFormData}>
                                <FaSave />
                            </Action>
                        }
                        <Action style={{ backgroundColor: '#F99B9B' }}>
                            <BsInfoCircleFill />
                        </Action>
                    </Fab>
                </div>

                {/* Info Modal */}
                <Modal className="info-detail-modal" show={showModal} centered animation={false}>
                    <Modal.Body style={{position:'relative',zIndex:1,borderRadius:20}} className="py-4 pb-4 text-center">
                        <h2>Note</h2>
                        <p>
                            Please provide as much information as you can. 
                            but <b>Name</b>, <b>Birthdate</b>, <b>School</b>, <b>Family Doctor</b>, <b>Grade</b>,
                            <b> Reffering Doctor</b>, <b>Primary language at home</b>, <b>Other Languages</b> and 
                            <b> Parent/Guardian Details</b> are the mandatory fields.
                        </p>
                        
                        <div style={{display:'flex',alignItems:'center',justifyContent:'space-around',marginTop:30}}>
                            <button onClick={()=>setShowModal(false)} id="info-modal-btn">Continue</button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
}
