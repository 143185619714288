import React from "react";
import HomeCard from "../../Components/HomeCard";

export default function Values() {
  const tiles = [
    {
      title: "Dignity",
      desc: "Dedicated to maintaining the dignity and self-determination of every individual.",
      url: "./Icons/Dignity.png",
      color: "blue",
    },
    {
      title: "Equity",
      desc: "Treatment and education that aims to deliver the highest quality of life for every individual with autism.",
      url: "./Icons/Equity.png",
      color: "pink",
    },
    {
      title: "Inclusion",
      desc: "Integrating every individual into society without barriers or exceptions in a collaborative environment.",
      url: "./Icons/school.png",
      color: "blue",
    },
  ];
  const tiles2 = [
    {
      title: "Diversity",
      desc: "Dedicated to valuing diversity and respecting unique and individual differences.",
      url: "./Icons/Diversity1.png",
      color: "blue",
    },
    {
      title: "Integrity",
      desc: "Our thoughts, words, and actions are guided by truth, honor, and respect.",
      url: "./Icons/Integrity.png",
      color: "pink",
    },
  ];
  return (
    <>
      <div className="grid grid-cols-1">
        <p className="text-lightGreen-500 font-bold text-4xl lg:text-4xl xl:text-5xl px-[20px] py-[20px]  text-center md:px-[200px] md:py-[100px] ">
          Our Values
        </p>
      </div>
      <div className="grid grid-cols-1 px-3 lg:grid-cols-3 gap-6 md:px-[180px] w-fit mx-auto">
        {tiles.map((v, i) => (
          <HomeCard
            key={i}
            src={v.url}
            color={v.color}
            title={v.title}
            desc={v.desc}
          />
        ))}
      </div>

      <br />
      <div className="flex justify-center items-center">
        <div className="grid grid-cols-1 px-[10px] md:grid-cols-2 gap-6 md:px-[320px] w-fit mx-auto">
          {tiles2.map((v, i) => (
            <HomeCard
              key={i}
              src={v.url}
              color={v.color}
              title={v.title}
              desc={v.desc}
            />
          ))}
        </div>
      </div>
    </>
  );
}
