import React,{useState,useEffect,useRef} from 'react'
import {useSelector,useDispatch} from 'react-redux'
import { Table, Dropdown, Menu } from 'antd'
import { DownOutlined } from "@ant-design/icons"
import {Modal,Spinner} from 'react-bootstrap'
import {RiCloseLine} from 'react-icons/ri'
import closeIcon from '../../images/closeIcon.svg'
import pdfPreviewImage from '../../images/pdfPreviewImage.png'
import {baseURL, socketURL, headers} from '../../Config'
import { convertIntoUtc, getcurrentDateTime, getTimeInISO,getTimeInLocalString } from '../../TimeFormate'
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import SelectDate from '../../Components/SelectDate'
import ClinicianDashboardNavbar from '../../Components/ClinicianDashboardNavbar'
import UploadeFileComp from '../../Components/UploadFileComp'
import {GetRBTData} from '../../Store/Actions/RBTActions'
import { io } from "socket.io-client";
import { toast } from 'react-toastify'
import {Link, useHistory} from 'react-router-dom'
import Swal from 'sweetalert2'
import './CSS/ClinicianAppointments.css'

export default function ClinicianAppointments() {
    
    const dispatch = useDispatch()
    const history = useHistory()
    const socket = useRef(null)
    socket.current = io(socketURL)

    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const [sessions,setSessions] = useState([])
    const [sessions1,setSessions1] = useState([])
    const [show,setShow] = useState(false)
    const [isUploadingDoc,setIsUploadingDoc] = useState(false)
    const [file,setFile] = useState(null)
    const [sessionId,setSessionId] = useState('')
    const [type,setType] = useState('0')
    const [loader,setLoader] = useState([{},{},{},{}])

    const sidebarWidth = useSelector(data => data.SidebarReducer.width)

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })

        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
          fetch(baseURL + '/api/getSessionsByRBTId?Id='+dt.uid,{
            headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
          })
          .then(res=>res.json())
          .then(res2=>{
            if(res2?.code === 'Success'){
              setSessions(res2?.message)
              setSessions1(res2?.message)
            }
          })
        }

        dispatch(GetRBTData())
    },[])

    socket.current.on('patientJoinedSession',(data)=>{
      const id = JSON.parse(data)?.Id

      if(id){
        const newArr = sessions.map(dt=>{
            if(dt.Id === id){
              dt.status = 2
              return dt
            }
            return dt
        })
        setSessions(newArr)
        setSessions1(newArr)
      }
    })

    const handleSetType = (data,key) =>{
      const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
          fetch(baseURL + '/api/setSessionsType',{
            method:'PUT',
            body:JSON.stringify({Id:data.Id,key}),
            headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
          })
          .then(res=>res.json())
          .then(res2=>{
            if(res2?.code === 'Success'){
              const newArr = sessions.map(dt=>{
                if(data.Id === dt.Id){
                  dt.type = key
                  return dt
                }
                return dt
              })
        
              setSessions(newArr)
            }
          })
        }
      
    }

    const getAppointmentsByDate = (date) =>{
      if(date){
        const newArr = sessions1.filter(dt=>getTimeInISO(dt.sessionDateTime).split('T')[0] === getTimeInISO(date).split('T')[0])
        setSessions(newArr)
      }
      else{
        setSessions(sessions1)
      }
    }

    const handleCompleteSession = (Id) =>{
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))

        if(Id && dt){
          fetch(baseURL + '/api/completeSession',{
            method:'Post',
            body:JSON.stringify({Id:Id}),
            headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
          })
          .then(res=>res.json())
          .then(res2=>{
            if(res2?.code === 'Success'){
                const newArr = sessions.map(dt=>{
                    if(dt.Id === res2?.message){
                      dt.status = 4
                      return dt
                    }
                    return dt
                })

                setSessions(newArr)
                setSessions1(newArr)
                setShow(false)
            }
            else{
              toast.error(res2?.message,{toastId:'upload-file-err2',autoClose:3000})
            }
          })
        }
    }

    const handleStartSession = (data) =>{
      const dt = JSON.parse(localStorage.getItem('bx_user_token'))
      if(dt){
        socket.current.emit('requestStartSession',JSON.stringify({...data,rbtId:dt?.uid}))
        
        const newArr = sessions.map(dt=>{
            if(dt.Id === data?.Id){
              dt.status = 1
              return dt
            }
            return dt
        })

        setSessions(newArr)
        setSessions1(newArr)
      }
    }

    const handleEndSession = (Id) =>{
      const dt = JSON.parse(localStorage.getItem('bx_user_token'))

        if(Id && dt){

          const date = getcurrentDateTime()
          const utc_date = convertIntoUtc(date)

          Swal.fire({
              title: 'Are you sure?',
              text: 'This action will end the session',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes!',
              cancelButtonText: 'No, keep it'
          }).then((result) => {
              if (result.isConfirmed) {
                  fetch(baseURL + '/api/handleEndSession',{
                    method:'Post',
                    body:JSON.stringify({Id:Id,date:utc_date}),
                    headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
                  })
                  .then(res=>res.json())
                  .then(res2=>{
                    if(res2?.code === 'Success'){
                        const newArr = sessions.map(dt=>{
                            if(dt.Id === res2?.message){
                              dt.status = 3
                              return dt
                            }
                            return dt
                        })

                        setSessions(newArr)
                        setSessions1(newArr)
                    }
                    else{
                      toast.error(res2?.message,{toastId:'upload-file-err2',autoClose:3000})
                    }
                  })
              }
          })
        }
    }

    const uploadFinalReport = () =>{
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))

        if(sessionId && dt){
          setIsUploadingDoc(true)
          fetch(baseURL + '/api/uploadSessionReport',{
            method:'Post',
            body:JSON.stringify({Id:sessionId,file:file}),
            headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
          })
          .then(res=>res.json())
          .then(res2=>{
            if(res2?.code === 'Success'){
                toast.error(res2?.message,{toastId:'upload-file-succ',autoClose:3000})
                setShow(false)
                setFile(null)
                setIsUploadingDoc(false)
            }
            else{
              toast.error(res2?.message,{toastId:'upload-file-err2',autoClose:3000})
              setIsUploadingDoc(false)
            }
          })
        }
    }

    return (
        <div className="bc-dashboard-appointments-container">
            <ParentDashboardSideBar active="appointments" user="RBT" />
            <div style={{marginLeft:windowWidth > 1024 && sidebarWidth}}>
                <ClinicianDashboardNavbar showAppointments={false} active="appointments" user="RBT" />
                <div className="bc-dashboard-appointments-body">
                    <div style={{width:'100%',marginTop:0}}>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems:'flex-end'}}>
                            <h2 style={{fontFamily:'CocoSharp bold'}} className="mb-0">Appointments</h2>
                            <SelectDate onChange={getAppointmentsByDate}/>
                        </div>
                        <Table 
                            columns={[
                              {
                                title: 'BCBA',
                                dataIndex: 'bcbaName',
                                render:(data,dt)=> <Link to={`/therapist-profile-detail/bcba/${dt.bcbaId}`}>{dt.bcbaName}</Link>
                              },
                              {
                                title: 'Behavior Consultant',
                                dataIndex: 'bcName',
                                render:(data,dt)=> <Link to={`/therapist-profile-detail/behaviour_consultant/${dt.bcId}`}>{dt.bcName}</Link>
                              },
                              {
                                title: 'Date',
                                dataIndex: 'sessionDateTime',
                                render:(data,dt)=>getTimeInLocalString(dt.sessionDateTime).split(' ')[0] + ' ' + getTimeInLocalString(dt.sessionDateTime).split(' ')[1] + ' ' + getTimeInLocalString(dt.sessionDateTime).split(' ')[2] + ' ' + getTimeInLocalString(dt.sessionDateTime).split(' ')[3]
                              },
                              {
                                title: 'Time',
                                dataIndex: 'sessionDateTime',
                                render:(data,dt)=>getTimeInLocalString(dt.sessionDateTime).split(' ')[4] + ' ' + getTimeInLocalString(dt.sessionDateTime).split(' ')[5]
                              },
                              {
                                title: 'Parent',
                                dataIndex: 'parentName',
                                render:(data,dt)=> <Link to={`/parent-detail/${dt.parentId}`}>{dt.parentName}</Link>
                              },
                              {
                                title: 'Child',
                                dataIndex: 'child_name',
                                render:(data,dt)=> <Link to={`/child-detail/${dt.childId}`}>{dt.child_name}</Link>
                              },
                              {
                                title: 'Status',
                                dataIndex: 'status',
                                render:(data,dt)=> dt.status === 0 ? 'Up coming' :dt.status === 1 ? 'Pending' :dt.status === 2 ? 'Ongoing' : 'Completed'
                              },
                              {
                                title: 'Session type',
                                dataIndex: 'type',
                                render:(data,dt)=> (
                                    <Dropdown overlay={
                                      <Menu onClick={(key)=>handleSetType(dt,key?.key)}>
                                        <Menu.Item key="0">
                                          <p>In Person</p>
                                        </Menu.Item>
                                        <Menu.Item key="1">
                                          <p>Virtual</p>
                                        </Menu.Item>
                                      </Menu>
                                    } 
                                      trigger={['click']} 
                                    >
                                      <p style={{margin:0,cursor:'pointer'}}>
                                        {dt.type == '0' ? 'In Person' : 'Virtual'} <DownOutlined />
                                      </p>
                                    </Dropdown>
                                )
                              },
                              {
                                title: 'Action',
                                dataIndex: 'status',
                                render:(data,dt)=> (
                                  dt.status === 0 ?
                                    <button className="rbt-appointments-start-session-btn" onClick={()=>handleStartSession(dt)}>Start Session</button>
                                  :dt.status === 2 ?
                                    <>
                                      <button className="rbt-appointments-start-session-btn1" style={{marginRight:10}} onClick={()=>handleEndSession(dt.Id)}>End Session</button>
                                      {
                                        dt.type == '1'&&
                                        <button className="rbt-appointments-start-session-btn" onClick={()=>history.push(`/virtual-session/${dt.Id}`)}>Join Session</button>
                                      }
                                    </>

                                  :dt.status === 3 ?
                                    <>
                                      <button className="rbt-appointments-start-session-btn1" onClick={()=>(handleCompleteSession(dt.Id))}>Mark as complete</button>
                                      <button className="rbt-appointments-start-session-btn2" style={{marginLeft:10}} onClick={()=>(setShow(true),setSessionId(dt.Id))}>Upload File</button>
                                    </>
                                  :dt.status === 4 ?
                                    <button className="rbt-appointments-start-session-btn2" onClick={()=>(setShow(true),setSessionId(dt.Id))}>Upload File</button>
                                  :null
                                )
                              }
                            ]} 
                            dataSource={sessions}
                            className="rbt-appointments-table"
                        />
                    </div>
                </div>
            </div>
            <Modal show={show} centered animation={false}>
                    <Modal.Header style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                        <p style={{margin:0,color:'#096E82',fontSize:28}}>Final Report</p>
                        <a style={{fontSize:24}}>
                            <RiCloseLine color="#096E82" onClick={()=>(setShow(false))} />
                        </a>
                    </Modal.Header>
                    <Modal.Body style={{position:'relative',zIndex:1,borderRadius:20}} className="py-2 pb-4">
                        <p style={{margin:0,fontSize:16}}>Please upload final report of the child to complete session</p>
                        {
                          file ? 
                              <div style={{
                                      width:120,
                                      height:120,
                                      padding:5,
                                      display:'flex',
                                      alignItems:'flex-end',
                                      borderRadius:10,
                                      border: 'solid 2px #030533',
                                      position:'relative',
                                      marginRight:10,
                                      marginTop:10
                                  }}
                              >
                                  <div style={{backgroundColor:'white',width:20,position:'absolute',right:-12,top:-12,borderRadius:10}}>
                                      <img style={{width:15,cursor:'pointer'}} src={closeIcon} onClick={()=>setFile(null)} />
                                  </div>
                                  <div>
                                      <img src={pdfPreviewImage} style={{height:100}} className="img-fluid" />
                                  </div>
                              </div>
                          :
                            <UploadeFileComp  
                              containerStyle={{marginTop:20}}
                              getUploadedFile={(file)=>setFile(file)}
                              multiple={false}
                            />
                        }
                        {
                          isUploadingDoc ?
                            <button className="upload-report-modal-btn" style={{marginTop:30,height:55}}>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                {' '}
                                Loading....
                            </button>
                          :
                          <button id="upload-report-modal-btn" onClick={uploadFinalReport} style={{marginTop:30,height:55}}>Upload</button>
                        }
                    </Modal.Body>
            </Modal>
        </div>
    )
}


