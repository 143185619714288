import React,{useState} from 'react'
import Select from 'react-dropdown-select'
import './CSS/SearchableDropdown.css'

export default function SearchableDropdown({options,selectedOption,setSelectedOption}) {

    return (
        <Select
            dropdownHandle={false}
            searchable
            valueField="id"
            searchBy="name"
            labelField="name"
            clearable={true}
            options={options}
            onChange={(values) => setSelectedOption(values)}
            placeholder="Search child here.."
            style={{width:'100%',justifyContent:'space-between'}}
        />
    )
}
