import React,{useState,useEffect,useRef} from 'react'
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import ClinicianDashboardNavbar from '../../Components/ClinicianDashboardNavbar'
import {useSelector,useDispatch} from 'react-redux'
import CallCompletedModal from '../../Components/CallCompletedModal'
import TelePeds from '../../Components/AssessmentToolComponents/Tele-Peds-ASD'
import ConsultationForm from '../../Components/AssessmentToolComponents/ConsultationForm'
import ProceduresIntro from '../../Components/AssessmentToolComponents/ProceduresIntro'
import ProcedureComp from '../../Components/AssessmentToolComponents/ProcedureComp'
import AgoraVideoComp from '../../Components/VideoCallComp/callIndex'
import './CSS/ClinicianVideoCallingPage.css'
import { baseURL,headers } from '../../Config'
import { Required } from '../../Validations'
import { toast } from 'react-toastify'

export default function ClinicianVideoCallingPage() {

    const formBodyRef = useRef()
    const interval = useRef(null)
    const dispatch = useDispatch()

    const meetingData = useSelector(dt=>dt.MeetingReducer.data)

    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const [showCallCompleteModal,setShowCallCompleteModal] = useState(false)
    const [form,setForm] = useState(1)
    const [therapistName,setTherapistName] = useState('')
    const [parentName,setParentName] = useState('')
    const [childName,setChildName] = useState('')
    const [mobileNumber,setMobileNumber] = useState('')
    const [dob,setDob] = useState('')
    const [address,setAddress] = useState('')
    const [directionPlay,setDirectionPlay] = useState('')
    const [callingName1,setCallingName1] = useState('')
    const [callingName2,setCallingName2] = useState('')
    const [childAttention1,setChildAttention1] = useState('')
    const [childAttention2,setChildAttention2] = useState('')
    const [jointPlay,setJointPlay] = useState('')
    const [familyPlay,setFamilyPlay] = useState('')
    const [indPlay,setIndPlay] = useState('')
    const [readyGo,setReadyGo] = useState('')
    const [closing,setClosing] = useState('')
    const [requestingSnack,setRequestingSnack] = useState('')
    const sidebarWidth = useSelector(data => data.SidebarReducer.width)

    const [observationNotes,setObservationNotes] = useState('')


    useEffect(()=>{
        // observationNotes&&clearInterval(interval.current)
        // interval.current = setInterval(()=>{
        //     if(meetingData?.type == 0){
        //         const obj = {
        //             therapist_name:therapistName,
        //             parent_name:parentName,
        //             phone_number:mobileNumber,
        //             child_name:childName,
        //             dob,
        //             address:'',
        //             direction_play:directionPlay,
        //             child_attention1:childAttention1,
        //             child_attention2:childAttention2,
        //             calling_name1:callingName1,
        //             calling_name2:callingName2,
        //             family_play:familyPlay,
        //             joint_play:jointPlay,
        //             ready_go:readyGo,
        //             requesting_snack:requestingSnack,
        //             ind_play:indPlay,
        //             closing,
        //             childId:meetingData.childId
        //         }
        
        //         const isDone = therapistName.trim() !==''&&parentName.trim() !== ''&&mobileNumber.trim() !== ''
        //         if(isDone){
        //             const data = JSON.parse(localStorage.getItem('bx_user_token'))
        //             if(data){
        //                 fetch(baseURL + '/api/addtelePedsData',{
        //                     method:'Post',
        //                     body:JSON.stringify(obj),
        //                     headers:{...headers,'authorization':'Bearer ' + data.accessToken}
        //                 })
        //                 .then(res=>res.json())
        //                 .then(res2=>{
        //                     if(res2.code === 'Success'){
        //                         toast.success('changes saved',{toastId:'save-tele-data-suc1'})
        //                     }
        //                 })
        //             }
        //         }
        //     }
        //     else if(meetingData?.type == 1){

        //         const obj = {
        //             notes:observationNotes,
        //             childId:meetingData.childId
        //         }

        //         if(observationNotes.trim() !== ''){
        //             const data = JSON.parse(localStorage.getItem('bx_user_token'))
        //             if(data){
        //                 fetch(baseURL + '/api/addConsultationData',{
        //                     method:'Post',
        //                     body:JSON.stringify(obj),
        //                     headers:{...headers,'authorization':'Bearer ' + data.accessToken}
        //                 })
        //                 .then(res=>res.json())
        //                 .then(res2=>{
        //                     if(res2.code === 'Success'){
        //                         toast.success('changes saved',{toastId:'save-consultation-data-suc1'})
        //                     }
        //                 })
        //             }
        //         }
        //     }
            
        // },10000)

    },[observationNotes])

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })

        const data = JSON.parse(localStorage.getItem('bx_user_token'))

        if(data){
            fetch(baseURL + '/api/getClinicianMeeting',{
                method:'Post',
                body:JSON.stringify({Id:data.uid}),
                headers:{...headers,'authorization':'Bearer ' + data.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Success'){
                    setParentName(res2.message.parentName)
                    setMobileNumber(res2.message.mobileNumber)
                    setChildName(res2.message.childName)
                    setDob(res2.message.dob)
                    setTherapistName(res2.message.therapist_name)
                    setAddress(res2.message.address)
                    setCallingName1(res2.message.calling_name1)
                    setCallingName2(res2.message.calling_name2)
                    setChildAttention1(res2.message.child_attention1)
                    setChildAttention2(res2.message.child_attention2)
                    setDirectionPlay(res2.message.direction_play)
                    setJointPlay(res2.message.joint_play)
                    setFamilyPlay(res2.message.family_play)
                    setClosing(res2.message.closing)
                    setReadyGo(res2.message.ready_go)
                    setRequestingSnack(res2.message.requesting_snack)
                    setIndPlay(res2.message.ind_play)
                    dispatch({type:'GetMeeting',payload:res2.message})
                }
            })
        }
    },[])


    const handlePrev = () =>{
        setForm(form - 1)
        formBodyRef.current.scrollTop = 0
    }

    const handleNext = () =>{

        if(form === 2){
            const isDone = Required('Therapist name',therapistName)&&Required('Parent name',parentName)&&Required('Mobile number',mobileNumber)
            if(isDone){
                setForm(form + 1)
                formBodyRef.current.scrollTop = 0
            }
        }
        else{
            setForm(form + 1)
            formBodyRef.current.scrollTop = 0
        }
    }


    const handleSave = () =>{
        const obj = {
            therapist_name:therapistName,
            parent_name:parentName,
            phone_number:mobileNumber,
            child_name:childName,
            dob,
            address:'',
            direction_play:directionPlay,
            child_attention1:childAttention1,
            child_attention2:childAttention2,
            calling_name1:callingName1,
            calling_name2:callingName2,
            family_play:familyPlay,
            joint_play:jointPlay,
            ready_go:readyGo,
            requesting_snack:requestingSnack,
            ind_play:indPlay,
            closing,
            childId:meetingData.childId
        }

        const isDone = Required('Therapist name',therapistName)&&Required('Parent name',parentName)&&Required('Mobile number',mobileNumber)
        if(isDone){
            const data = JSON.parse(localStorage.getItem('bx_user_token'))
            if(data){
                fetch(baseURL + '/api/addtelePedsData',{
                    method:'Post',
                    body:JSON.stringify(obj),
                    headers:{...headers,'authorization':'Bearer ' + data.accessToken}
                })
                .then(res=>res.json())
                .then(res2=>{
                    if(res2.code === 'Success'){
                        toast.success(res2.message,{toastId:'save-tele-data-suc'})
                    }
                })
            }
        }
    }

    const handleFinish = () =>{
        const obj = {
            therapist_name:therapistName,
            parent_name:parentName,
            phone_number:mobileNumber,
            child_name:childName,
            dob,
            address:'',
            direction_play:directionPlay,
            child_attention1:childAttention1,
            child_attention2:childAttention2,
            calling_name1:callingName1,
            calling_name2:callingName2,
            family_play:familyPlay,
            joint_play:jointPlay,
            ready_go:readyGo,
            requesting_snack:requestingSnack,
            ind_play:indPlay,
            closing,
            childId:meetingData.childId
        }

        const isDone = Required('Therapist name',therapistName)&&Required('Parent name',parentName)&&Required('Mobile number',mobileNumber)
        if(isDone){
            const data = JSON.parse(localStorage.getItem('bx_user_token'))
            if(data){
                fetch(baseURL + '/api/submitTelePedsData',{
                    method:'Post',
                    body:JSON.stringify(obj),
                    headers:{...headers,'authorization':'Bearer ' + data.accessToken}
                })
                .then(res=>res.json())
                .then(res2=>{
                    if(res2.code === 'Success'){
                        toast.success(res2.message,{toastId:'save-tele-data-suc'})
                    }
                })
            }
        }
    }


    const handleSaveConsultation = () =>{
        const obj = {
            notes:observationNotes,
            childId:meetingData.childId
        }

        if(observationNotes.trim() !== ''){
            const data = JSON.parse(localStorage.getItem('bx_user_token'))
            if(data){
                fetch(baseURL + '/api/addConsultationData',{
                    method:'Post',
                    body:JSON.stringify(obj),
                    headers:{...headers,'authorization':'Bearer ' + data.accessToken}
                })
                .then(res=>res.json())
                .then(res2=>{
                    if(res2.code === 'Success'){
                        toast.success('changes saved',{toastId:'save-consultation-data-suc1'})
                    }
                })
            }
        }
    }

    const handleFinishConsultation = () =>{
        const obj = {
            notes:observationNotes,
            childId:meetingData.childId
        }

        if(observationNotes.trim() !== ''){
            const data = JSON.parse(localStorage.getItem('bx_user_token'))
            if(data){
                fetch(baseURL + '/api/submitConsultationData',{
                    method:'Post',
                    body:JSON.stringify(obj),
                    headers:{...headers,'authorization':'Bearer ' + data.accessToken}
                })
                .then(res=>res.json())
                .then(res2=>{
                    if(res2.code === 'Success'){
                        toast.success(res2.message,{toastId:'save-consultation-data-suc1'})
                    }
                })
            }
        }
    }

    return (
        <div className="clinician-video-calling-page-container">
            <ParentDashboardSideBar user="BCBA" />
            <div style={{marginLeft:windowWidth > 1024 &&sidebarWidth}}>
                <ClinicianDashboardNavbar user="BCBA" showAppointments={false} />
                <div className="clinician-video-calling-page-body">
                    <div className="clinician-video-calling-page-row-1">
                        <AgoraVideoComp 
                            view='second-view' 
                            user='clinician' 
                            handleCallCompleteModal={(val)=>setShowCallCompleteModal(val)}
                        />
                    </div>
                    <div className="clinician-video-calling-page-row-2">
                        {
                            meetingData?.type == 0 ?
                                <div style={{display:'flex',justifyContent:'space-between',alignItems:'flex-end'}}>
                                    <div>
                                        <p>{form === 1 ? 'Guideline' : 'Observation Notes'}</p>
                                        <h1>{form === 1 ? 'Assessment Tool' : 'Procedures'}</h1>
                                        <hr />
                                    </div>
                                    {
                                        form !== 1 &&
                                            <button onClick={handleSave} className="assesment-tool-save-btn">Save</button>
                                    }
                                </div>
                            : meetingData?.type == 1 ?
                                <div style={{display:'flex',justifyContent:'space-between',alignItems:'flex-end'}}>
                                    <div>
                                        <p>Notes</p>
                                        <h1>Observation Notes</h1>
                                        <hr />
                                    </div>
                                    <button onClick={handleSaveConsultation} className="assesment-tool-save-btn">Save</button>
                                </div>  
                            : null                     
                    
                        }
                        
                   
                        <div ref={formBodyRef} className="clinician-video-calling-page-row-2-form-body">
                        {
                            meetingData?.type == 0 ?
                                <>
                                    {
                                        form === 1 ?
                                            <TelePeds />
                                        : form === 2 ?
                                            <ProceduresIntro 
                                                therapistName={therapistName}
                                                setTheraName={(val)=>setTherapistName(val)}
                                                parentName={parentName}
                                                setParentName={(val)=>setParentName(val)}
                                                phone={mobileNumber}
                                                setPhone={(val)=>setMobileNumber(val)}
                                                childName={childName}
                                                setChildName={(val)=>setChildName(val)}
                                                Dob={dob}
                                                setDob={(val)=>setDob(val)}
                                                // Address={address}
                                                // setAddress={(val)=>setAddress(val)}
                                            />
                                        : form === 3 ?
                                            <>
                                                <ProcedureComp 
                                                    title="Child-Direction Play 2min"
                                                    content="
                                                            “Find 3-5 toys and put them on the floor/table near .” 
                                                            [Toys could include: pop-up toy with buttons, musical toys, shape sorter, puzzle, blocks] 
                                                            “We are going to let him/her play with these toys for a couple minutes. You can just sit back 
                                                            and let him/her play and respond normally if he/she tries to get your attention. 
                                                            We will do our best to not tell him/her directly what to do.”
                                                        "
                                                    text={directionPlay}
                                                    setText={(val=>setDirectionPlay(val))}
                                                />
                                                <ProcedureComp 
                                                    title="Calling Name #1"
                                                    content="
                                                            “While is not looking at you, please call his/her name one time.”
                                                            [Does child look to caregiver with eye contact?]
                                                        "
                                                    text={callingName1}
                                                    setText={(val=>setCallingName1(val))}
                                                />
                                            </>
                                        : form === 4 ?
                                            <>
                                                <ProcedureComp 
                                                    title="CDirecting Child’s Attention #1"
                                                    content="
                                                            “Find something on the wall or an object across the room that you could point to. 
                                                            Point to that object and say, look!’ one time.” [Does child follow care giver’s 
                                                            point and gaze to look at object?]
                                                        "
                                                    text={childAttention1}
                                                    setText={(val=>setChildAttention1(val))}
                                                />
                                                <ProcedureComp 
                                                    title="Joint Play (2 minutes)"
                                                    content="
                                                            “Now you can join and play in whatever way feels natural. 
                                                            You can include new toys if you’d like.”
                                                        "
                                                    text={jointPlay}
                                                    setText={(val=>setJointPlay(val))}
                                                />
                                            </>
                                        : form === 5 ?
                                            <>
                                                <ProcedureComp 
                                                    title="Calling Name #2"
                                                    content="
                                                            “While is not looking at you, please call his/her name one time.” 
                                                            [Does your child look to caregiver with eye contact?]
                                                        "
                                                    text={callingName2}
                                                    setText={(val=>setCallingName2(val))}
                                                />
                                                <ProcedureComp 
                                                    title="Directing Child’s Attention #2"
                                                    content="
                                                                “Find something on the wall or an object across the room that you could point to. 
                                                                Point to that object and say, look!’ one time.” [Does child follow care giver’s 
                                                                point and gaze to look at object?]
                                                        "
                                                    text={childAttention2}
                                                    setText={(val=>setChildAttention2(val))}
                                                />
                                            </>
                                        : form === 6 ?
                                            <>
                                                <ProcedureComp 
                                                    title="Family Play Routine"
                                                    content="
                                                            “Is there a game that you like to play with your child like peek-a-boo OR 
                                                            I’m gonna get you? Go ahead and play that game with your child for a couple of minutes.” 
                                                            [Does child respond to caregiver’s bid to play? Shared enjoyment? Requesting? Initiating 
                                                            routine?
                                                        "
                                                    text={familyPlay}
                                                    setText={(val=>setFamilyPlay(val))}
                                                />
                                                <ProcedureComp 
                                                    title="Ready, Set, Go"
                                                    content="
                                                            “Find something on the wall or an object across the room that you could point to. 
                                                            Point to that object and say, look!’ one time.” [Does child follow care giver’s point and gaze to look at object?]
                                                        "
                                                    text={readyGo}
                                                    setText={(val=>setReadyGo(val))}
                                                />
                                            </>
                                        : form === 7 ?
                                            <>
                                                <ProcedureComp 
                                                    title="Requesting Snack"
                                                    content="
                                                            “Find a Tupperware or other similar container, fill it with a snack, and give it to”. OR Alternatives:”Fill a container with small objects (e.g., stickers, blocks, etc.) and give it to.”
                                                            “Hand container to and say, “You can have some.’ Wait and see how reacts.”
                                                            “Give one piece of snack/one sticker, etc. Close container.” [Please administer a total three times]
                                                            [Did the child request? Did the child coordinate eye contact with gestures/vocalizations? Hand as tool?]
                                                        "
                                                    text={requestingSnack}
                                                    setText={(val=>setRequestingSnack(val))}
                                                />
                                                <ProcedureComp 
                                                    title="Indpendent Play + Ignoring (2 minutes)"
                                                    content="
                                                            “Get out some toys and let play for a couple minutes on his/her own. 
                                                            I want you to ignore for a couple of minutes, but if he/she approaches 
                                                            you just respond as you normally would. I will let you know when we are done.” 
                                                            [Initiates an interaction?]
                                                        "
                                                    text={indPlay}
                                                    setText={(val=>setIndPlay(val))}
                                                />
                                            </>
                                        :
                                            <>
                                                <ProcedureComp 
                                                    title="Closing"
                                                    content="
                                                            “Was this a good snapshot of your child’s behavior?
                                                        "
                                                    text={closing}
                                                    setText={(val=>setClosing(val))}
                                                />
                                            </>                  
                                    }

                                    <div className="assesment-tool-btns-container">
                                        {
                                            form !== 1 && <button onClick={handlePrev} className="assesment-tool-prev-btn">Previous</button>
                                        }
                                        {
                                            form === 8 ?
                                                <button onClick={handleFinish} className="assesment-tool-next-btn">Finish</button>
                                            :
                                                <button onClick={handleNext} className="assesment-tool-next-btn">Next</button>
                                        }
                                    </div>
                                </>
                            : meetingData?.type == 1 ?
                                <ConsultationForm 
                                    value={observationNotes}
                                    onChange={(val)=>setObservationNotes(val)}
                                    handleFinish={handleFinishConsultation}
                                /> 
                            : null                     
                    
                        }
                        </div> 
                    </div>
                    <CallCompletedModal 
                        showCompletedAlert={showCallCompleteModal} 
                        path="/bc-dashboard"
                    />
                </div>
            </div>
        </div>
    )
}
