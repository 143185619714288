const Form2Data = {
    anyMedication:{isSelected:'No',value:''},
    smoke:'No',
    isSelectedAlcoholic:'No',
    alcoholicBeverages:{howOften:'',consumedEachDay:''},
    drugs:{isSelected:'No',value:''},
    compilcationExpDuringPreg:'',
    babyWasBorn:'',
    anyComplicationDuringDelivery:{isSelected:'No',value:''},
    childBirthWeight:0,
    childBirthProblem:{value:'Jaundice',detail:''},
    postDeliveryComplication:{isSelected:'No',value:''},
    developmentalMilestones:[
        {value:'Smiled',selected:'Never'},
        {value:'Rolled over',selected:'Never'},
        {value:'Crawled',selected:'Never'},
        {value:'Walked',selected:'Never'},
        {value:'Ran',selected:'Never'},
        {value:'Babbled',selected:'Never'},
        {value:'First word',selected:'Never'},
        {value:'Sentences',selected:'Never'},
        {value:'Tied shoelaces',selected:'Never'},
        {value:'Dressed self',selected:'Never'},
        {value:'Fed self',selected:'Never'},
        {value:'Bladder trained, day',selected:'Never'},
        {value:'Bladder trained, night',selected:'Never'},
        {value:'Bowel trained',selected:'Never'},
        {value:'Rode tricycle',selected:'Never'},
        {value:'Rode bicycle',selected:'Never'}
    ],
    ageSingleWordAppear:0,
    agePhrasesAppear:0
}

export default Form2Data