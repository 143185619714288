import React,{useEffect,useState} from 'react'
import {useHistory} from 'react-router-dom'
import {baseURL, Decrypt, headers} from '../../Config'
import {useSelector} from 'react-redux'
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import ParentDashboardNavbar from '../../Components/ParentDashboardNavbar'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton';
import './CSS/AssignedClinician.css'
import { Row } from 'react-bootstrap'

export default function AssignedClinician() {

    const history = useHistory()

    const sidebarWidth = useSelector(data => data.SidebarReducer.width)
    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const [clinicianDetail,setClinicianDetail] = useState(null)
    const [isLoaded,setIsLoaded] = useState(false)
    const [isSelected,setIsSelected] = useState(1)

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })

        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            fetch(baseURL+ '/api/get-assigned-clinician',{
                method:'Post',
                body:JSON.stringify({uid:dt.uid}),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    toast.error('error..')
                    setIsLoaded(true)
                }
                else{
                    setClinicianDetail(res2.message[0])
                    setIsLoaded(true)
                }
            })
        }
    },[])


    return (
        <div className="assigned-clinician-detail-container">
            <ParentDashboardSideBar active="clinician" />
            <div style={{marginLeft:windowWidth > 1024 &&sidebarWidth}}>
                <ParentDashboardNavbar />
                <div className="assigned-clinician-detail-body">
                    <h2 style={{fontFamily:'CocoSharp bold'}}>Clinician Profile</h2>
                    {
                        isLoaded ?
                            clinicianDetail ?
                                <>
                                    <Row className="mx-0 mt-5 assigned-clinician-detail-row-1 w-100">
                                        <div className="assigned-clinician-detail-row-1-col-1" style={{backgroundImage:`url(${clinicianDetail&&Decrypt(clinicianDetail.image)})`}}>
                                        </div>
                                        <div className="assigned-clinician-detail-row-1-col-2">
                                            <div>
                                                <h1 style={{marginBottom:0,fontFamily:'CocoSharp regular',fontSize:36,color:'#096E82'}}>{clinicianDetail&&Decrypt(clinicianDetail.first_name) + ' ' + Decrypt(clinicianDetail.last_name)}</h1>
                                                <h4 style={{marginTop:10,fontFamily:'CocoSharp bold',color:'#AEDFE5'}}>Doctor</h4>
                                                <p style={{wordBreak:'break-all',marginTop:0,lineHeight:1.4,fontFamily:'CocoSharp regular',width:windowWidth >=1470 ? '70ch' : '100%',fontSize:17,wordWrap:'break-word'}}>{clinicianDetail&&clinicianDetail.short_des ? Decrypt(clinicianDetail.short_des) : 'No Description'}</p>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <button onClick={()=>history.push('/parent-chats')} className="clinician-send-message-btn">Send Message</button>
                                                {/* <button className="clinician-send-message-btn-1">Report</button> */}
                                            </div>
                                        </div>
                                    </Row>

                                    <Row className="mx-0 mt-3 assigned-clinician-detail-row-2">
                                        <div className="assigned-clinician-detail-row-2-tab-bar">
                                            <button className="assigned-clinician-detail-row-2-tab-bar-btn" onClick={()=>setIsSelected(1)} style={isSelected === 1 ? {borderBottom:'solid 2px #F99B9B',color:'#F99B9B'} : {borderBottom:'solid 1px #AEB0D0',color:'#ADB5CC'}}>
                                                Experiences
                                            </button>
                                            <button className="assigned-clinician-detail-row-2-tab-bar-btn" onClick={()=>setIsSelected(2)}  style={isSelected === 2 ? {borderBottom:'solid 2px #F99B9B',color:'#F99B9B'} : {borderBottom:'solid 1px #AEB0D0',color:'#ADB5CC'}}>
                                                About
                                            </button>
                                            {/* <button className="assigned-clinician-detail-row-2-tab-bar-btn" onClick={()=>setIsSelected(3)}  style={isSelected === 3 ? {borderBottom:'solid 2px #F99B9B',color:'#F99B9B'} : {borderBottom:'solid 1px #AEB0D0',color:'#ADB5CC'}}>
                                                Schedule
                                            </button> */}
                                            <hr style={{width:'100%',margin:0,borderTop:'solid 1px #AEB0D0'}} />
                                        </div>
                                        <div className="w-100">
                                        {
                                            isSelected === 1 ?
                                                <div className="mt-3 d-flex flex-wrap">
                                                    {
                                                        clinicianDetail&&
                                                        clinicianDetail.experiences.map((dt,i)=>{
                                                            return(
                                                                <div key={i} style={{border:'solid 1px #096E82',borderRadius:8,width:320,padding:'10px 10px',marginRight:10,marginTop:10}}>
                                                                    <p style={{fontSize:16,fontFamily:'CocoSharp bold',color:'#096E82',margin:0}}>Hospital Name: {Decrypt(dt.hospital_name)}</p>
                                                                    <p style={{fontSize:16,fontFamily:'CocoSharp bold',color:'#096E82',margin:0}}>Experience: {Decrypt(dt.experience)}</p>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            : isSelected === 2 ?
                                                <div>
                                                    {
                                                        <p style={{wordBreak:'break-all',marginTop:20,lineHeight:1.3,fontFamily:'CocoSharp bold',width:windowWidth >=768 ? '70ch' : '100%',fontSize:17,wordWrap:'break-word'}}>{clinicianDetail&&clinicianDetail.short_des ? Decrypt(clinicianDetail.short_des) : 'No Description'}</p>
                                                    }
                                                </div>
                                            :
                                                <div></div>
                                        }
                                        </div>
                                    </Row>
                                </>
                            :
                                <div style={{width:'100%'}}>
                                    <h5 style={{color:'lightgray',marginTop:20}}>No Assigned Clinician</h5>
                                </div>
                        :
                            <Row className="mx-0 mt-5 assigned-clinician-detail-row-1">
                                <div className="assigned-clinician-detail-row-1-col-1">
                                    <Skeleton height="100%" />
                                </div>
                                <div className="assigned-clinician-detail-row-1-col-2">
                                    <div style={{width:'300px'}}>
                                        <Skeleton height={25} width="100%" />
                                        <Skeleton height={25} width="100%" />
                                        <Skeleton height={150} width="100%" />
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <Skeleton height={45} width={150} style={{marginRight:10}} />
                                        <Skeleton height={45} width={150} />
                                    </div>
                                    
                                </div>
                            </Row>
                    }
                </div>
            </div>
        </div>
    )
}
