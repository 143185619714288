import Form3State from '../../States/OnBoardingFormStates/Form3State'

const Form3Reducer = (state = Form3State, action) => {
    switch (action.type) {
        case 'form3-draft-data': {
            state = action.payload
            return state
        }
        case 'onChangeIllnessForm3': {
            const newArr = state.illnessOrConditions.map((dt, i) => {
                if (i === action.payload.index) {
                    action.payload.data.isNull = action.payload.data.isSelected && action.payload.data.age.trim() !== '' ? false : true
                    dt = action.payload.data
                    return dt
                }
                return dt
            })
            return {
                ...state,
                illnessOrConditions: newArr
            }
        }
        case 'onChangeCurrentMedicationForm3': {
            const newArr = state.currentMedication.map((dt, i) => {
                if (i === action.payload.index) {
                    dt = action.payload.data
                    return dt
                }
                return dt
            })
            return {
                ...state,
                currentMedication: newArr
            }
        }
        case 'onChangeVissionProblemSelectForm3': {
            state.vissionProblemSelect = action.payload
            return state
        }
        case 'onChangeVissionProblemForm3': {
            action.payload.isDetailNull = state.vissionProblemSelect === 'Yes' && action.payload.detail.trim() !== '' ? false : true
            action.payload.isDateNull = state.vissionProblemSelect === 'Yes' && action.payload.examinationDate.trim() !== '' ? false : true
            state.vissionProblem = action.payload
            return state
        }
        case 'onChangeHearingProblemSelectForm3': {
            state.hearingProblemSelect = action.payload
            return state
        }
        case 'onChangeHearingProblemForm3': {
            action.payload.isDetailNull = state.hearingProblemSelect === 'Yes' && action.payload.detail.trim() !== '' ? false : true
            action.payload.isDateNull = state.hearingProblemSelect === 'Yes' && action.payload.examinationDate.trim() !== '' ? false : true
            state.hearingProblem = action.payload
            return state
        }
        case 'onChangeFamilyMedicationForm3': {
            const newArr = state.familyMedication.map((dt, i) => {
                if (i === action.payload.index) {
                    action.payload.data.isNull = action.payload.data.isSelected && action.payload.data.detail.trim() !== '' ? false : true
                    dt = action.payload.data
                    return dt
                }
                return dt
            })
            return {
                ...state,
                familyMedication: newArr
            }
        }
        case 'onChangePreviousAssessmentsForm3': {
            const newArr = state.previousAssessments.map((dt, i) => {
                if (i === action.payload.index) {
                    dt = action.payload.data
                    return dt
                }
                return dt
            })
            return {
                ...state,
                previousAssessments: newArr
            }
        }
        case 'onChangePsycatricTreatmentListForm3': {
            const newArr = state.psycatricTreatmentList.map((dt, i) => {
                if (i === action.payload.index) {
                    dt = action.payload.data
                    return dt
                }
                return dt
            })
            return {
                ...state,
                psycatricTreatmentList: newArr
            }
        }
        case 'onChangeRecentStressorsForm3': {
            state.recentStressors = action.payload
            return state
        }
        case 'RESET_FORM_3':{
            state = Data
            return state
        }
        default: {
            return state
        }
    }
}


const Data = {
    illnessOrConditions: [
        { illness: 'Meningitis', age: '', isSelected: false, isNull: false }, { illness: 'Thyroid problems', age: '', isSelected: false, isNull: false }, { illness: 'Encephalitis', age: '', isSelected: false, isNull: false },
        { illness: 'Tuberculosis', age: '', isSelected: false, isNull: false }, { illness: 'High fever', age: '', isSelected: false, isNull: false }, { illness: 'Bone or joint disease', age: '', isSelected: false, isNull: false },
        { illness: 'Seizures', age: '', isSelected: false, isNull: false }, { illness: 'Jaundice/hepatitis', age: '', isSelected: false, isNull: false }, { illness: 'Allergies', age: '', isSelected: false, isNull: false },
        { illness: 'Rheumatic fever', age: '', isSelected: false, isNull: false }, { illness: 'Asthma', age: '', isSelected: false, isNull: false }, { illness: 'Diabetes', age: '', isSelected: false, isNull: false },
        { illness: 'Eczema or hives', age: '', isSelected: false, isNull: false }, { illness: 'Cancer', age: '', isSelected: false, isNull: false }, { illness: 'Injuries to head', age: '', isSelected: false, isNull: false },
        { illness: 'High blood pressure', age: '', isSelected: false, isNull: false }, { illness: 'Loss of consciousness', age: '', isSelected: false, isNull: false }, { illness: 'Heart disease', age: '', isSelected: false, isNull: false },
        { illness: 'Severe headaches', age: '', isSelected: false, isNull: false }, { illness: 'Bleeding problems', age: '', isSelected: false, isNull: false }, { illness: 'Broken bones', age: '', isSelected: false, isNull: false },
        { illness: 'Anaemia', age: '', isSelected: false, isNull: false }, { illness: 'Hospitalizations', age: '', isSelected: false, isNull: false }, { illness: 'Poisoning', age: '', isSelected: false, isNull: false },
        { illness: 'Operations', age: '', isSelected: false, isNull: false }, { illness: 'Paralysis ', age: '', isSelected: false, isNull: false }
    ],
    currentMedication: [
        { medication: '', reasonTaken: '', dosage: '', startDate: '' },
        { medication: '', reasonTaken: '', dosage: '', startDate: '' },
        { medication: '', reasonTaken: '', dosage: '', startDate: '' },
        { medication: '', reasonTaken: '', dosage: '', startDate: '' },
        { medication: '', reasonTaken: '', dosage: '', startDate: '' },
        { medication: '', reasonTaken: '', dosage: '', startDate: '' },
        { medication: '', reasonTaken: '', dosage: '', startDate: '' }
    ],
    vissionProblemSelect: 'No',
    vissionProblem: { detail: '', examinationDate: '', isDetailNull: true, isDateNull: true },
    hearingProblemSelect: 'No',
    hearingProblem: { detail: '', examinationDate: '', isDetailNull: true, isDateNull: true },
    familyMedication: [
        { illness: 'Seizures or Epilepsy', detail: '', isSelected: false, inNull: true }, { illness: "Tics or Tourette's syndrome", detail: '', isSelected: false, inNull: true }, { illness: 'Attention Problems', detail: '', isSelected: false, inNull: true },
        { illness: 'Alcohol abuse', detail: '', isSelected: false, inNull: true }, { illness: 'Hyperactivity', detail: '', isSelected: false, inNull: true }, { illness: 'Drug abuse', detail: '', isSelected: false, inNull: true },
        { illness: 'Learning Disability', detail: '', isSelected: false, inNull: true }, { illness: 'Suicide attempt', detail: '', isSelected: false, inNull: true }, { illness: 'Intellectual Disability', detail: '', isSelected: false, inNull: true },
        { illness: 'Schizophrenia', detail: '', isSelected: false, inNull: true }, { illness: 'Language delay', detail: '', isSelected: false, inNull: true }, { illness: 'High blood pressure', detail: '', isSelected: false, inNull: true },
        { illness: 'Autism (ASD)', detail: '', isSelected: false, inNull: true }, { illness: 'Heart disease', detail: '', isSelected: false, inNull: true }, { illness: 'Depression', detail: '', isSelected: false, inNull: true },
        { illness: 'Neurological condition', detail: '', isSelected: false, inNull: true }, { illness: 'Anxiety', detail: '', isSelected: false, inNull: true }, { illness: 'Involvement with the law', detail: '', isSelected: false, inNull: true },
        { illness: 'Bipolar Disorder', detail: '', isSelected: false, inNull: true }, { illness: 'Genetic syndrome', detail: '', isSelected: false, inNull: true }, { illness: 'Obsessive Compulsive Disorder (OCD)', detail: '', isSelected: false, inNull: true },
        { illness: 'Childhood behaviour problems', detail: '', isSelected: false, inNull: true }
    ],
    previousAssessments: [
        { assessment: 'Psychiatric', dateOfTesting: '', nameOfExaminer: '', isErr:false }, { assessment: "Psychological", dateOfTesting: '', nameOfExaminer: '', isErr:false }, { assessment: 'Neuropsychological', dateOfTesting: '', nameOfExaminer: '', isErr:false },
        { assessment: 'Educational', dateOfTesting: '', nameOfExaminer: '', isErr:false }, { assessment: 'Speech Pathology', dateOfTesting: '', nameOfExaminer: '', isErr:false }, { assessment: 'Other', dateOfTesting: '', nameOfExaminer: '', isErr:false }
    ],
    psycatricTreatmentList: [
        { typeOfTreatment: '', dateOfTreatment: '', nameOfTherapist: '', isErr:false},
        { typeOfTreatment: '', dateOfTreatment: '', nameOfTherapist: '', isErr:false},
        { typeOfTreatment: '', dateOfTreatment: '', nameOfTherapist: '', isErr:false},
        { typeOfTreatment: '', dateOfTreatment: '', nameOfTherapist: '', isErr:false}
    ],
    recentStressors: ''
}

export default Form3Reducer