import React from 'react'
import { Button,Modal, Row,Col } from 'react-bootstrap'
import vector from '../images/modal_vector.png'
import avatar from '../images/avatar.jpg'
import { Link } from 'react-router-dom'
import './CSS/AppointmentScheduleAlert.css'

export default function AppointmentScheduleAlert(props) {

    return (
        <Modal show={props.show} centered animation={false}>
            <Modal.Body style={{position:'relative',zIndex:1,borderRadius:20}} className="py-4 pl-4 pr-3 w-100">
                <Row style={{height:180,width:'100%'}} className="mx-0">
                    <div style={{width:'30%',borderRadius:8,height:180,backgroundImage:props.image ? `url(${props.image})` : `url(${avatar})`,backgroundSize:'cover',backgroundRepeat:'no-repeat',backgroundPosition:'center'}}>
                    </div>
                    <Col sm={8} style={{width:'70%'}}>
                        <p style={{fontSize:16,color:'#096E82',fontFamily:'CocoSharp regular'}}>Your Appointment has been Scheduled Successfully</p>
                        <p style={{fontSize:18,color:'#096E82',fontFamily:'CocoSharp bold',marginBottom:5}}>Dr. {props.clinician_name}</p>

                        <p className="mb-0 mt-0" style={{fontSize:16,color:'#096E82',fontFamily:'CocoSharp regular'}}>{props.time}</p>
                        <p className="mt-0" style={{fontSize:16,color:'#096E82',fontFamily:'CocoSharp regular',lineHeight:0.5}}>{props.date.replace(/-/g,'/')}</p>

                        <p style={{fontSize:16,color:'#096E82',fontFamily:'CocoSharp bold'}}>Child : <span style={{color:'#F99B9B'}}>{props.child_name}</span></p>

                        
                    </Col>
                </Row>
                {
                    props.type === 'fixed' ?

                        <div style={{zIndex:1000}} className="w-100 d-flex justify-content-end">
                            <Link to={props.path} style={{width:100}} >
                                <button className="appointment-schedule-alert-btn">Continue</button>
                            </Link> 
                        </div>
                        
                    :
                        <div className="w-100 d-flex justify-content-end">
                            <button className="appointment-schedule-alert-btn" onClick={()=>props.close()}>Close</button>
                        </div>
                }
                <img src={vector} className="img-fluid" style={{position:'absolute',top:0,left:0,right:0,zIndex:-1}} />
            </Modal.Body>
        </Modal>
    )
}
