const Form5Data = {
    modiﬁedLearningProgram: false,
    individualEducationPlan: false,
    currentLearningProgram: { isSelected: 'Yes', detail: '',isNull:true },
    heldBackGrade: { isSelected: 'No', grade: '',isNull:true },
    specialEducationClasses: { isSelected: 'No', detail: '',isNull:true },
    learningAssistance: { isSelected: 'No', detail: '',isNull:true },
    suspendedOrExpelled: { isSelected: 'No', detail: '',isNull:true },
    receivedTutoring: { isSelected: 'No', detail: '',isNull:true },
    schoolProblems: '',
}

export default Form5Data