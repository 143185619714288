import React,{useState} from 'react'
import { RiEyeLine,RiEyeCloseLine } from 'react-icons/ri';
import './CSS/InputPassComp.css'

export default function InputPassComp(props) {

    const [showPass,setShowPass] = useState(false)
    const [focus,setFocus] = useState(false)

    return (
        <fieldset className={focus ? "fld_set_fcs" : "fld_set"} style={props.containerStyle}>
            <legend className={focus ? "legend1" : "legend"} style={props.labelStyle}>{props.label}</legend>
            <div style={{display:'flex',alignItems:'center',padding:'0px 10px 0px 0px'}}>
                <input onInput={(e)=>props.inputLength(e)} value={props.value} onChange={(e)=>props.onChange(e)} onFocus={()=>setFocus(true)} onBlur={()=>setFocus(false)} type={!showPass ? "password" : "text" } className="input-pass form-control" />
                {
                    showPass ? 
                        <RiEyeLine className="input-pass-icon" size={25} style={{marginBottom:10}} onClick={()=>setShowPass(false)} />
                    :
                        <RiEyeCloseLine className="input-pass-icon" size={25} style={{marginBottom:10}} onClick={()=>setShowPass(true)}  />
                    
                }
                
            </div>
        </fieldset>
    )
}
