import React,{useState,useEffect} from "react";
import img from "../../assets/career/clinical.png";
import patt from "../../assets/Home/model-patt.png";
import PinkBtn from "../../Components/PinkBtn";

function Clinical() {

  const [scrollHeight,setScrollHeight] = useState(0)
  
  useEffect(()=>{
    setScrollHeight(document.body.scrollHeight - 400)
  },[])

  return (
    <div className="w-full flex justify-center items-center mt-[140px] xl:mt-[180px] px-5">
      <div className="xl:flex-row flex-col max-w-[1440px] flex justify-center items-center gap-16 xl:gap-8 w-full">
        <div className="relative isolate  flex justify-end items-center">
          <img
            src={patt}
            className="absolute -z-[1] -top-[10%] sm:-left-[8%] h-full -left-[4%]"
            alt=""
          />
          <img
            src={img}
            alt=""
            className="w-full max-w-[400px] xl:max-w-[522px]"
          />
        </div>
        <div className="flex justify-center items-start flex-col gap-4 xl:w-1/2">
          <h1 className="text-lightGreen-500 leading-9 sm:leading-[50px] font-bold text-[30px] sm:text-[48px]  ">
            Exceptional Clinical <br /> Services
          </h1>
          <p className="text-lightGreen-500 font-normal text-lg ">
            Our vision is to become a leading provider of clinical services
            where our clients thrive, and our people grow and develop.
          </p>
          <div className="mt-6">
            <PinkBtn onClick={()=>window.scrollTo(0,scrollHeight)} text={"View Positions"} url={'Career'}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Clinical;
