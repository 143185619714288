import React,{useState,useEffect} from 'react'
import AgoraRTC from 'agora-rtc-sdk-ng';
import {useSelector,useDispatch} from 'react-redux'
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import ParentDashboardNavbar from '../../Components/ParentDashboardNavbar'
import ClinicianDashboardNavbar from '../../Components/ClinicianDashboardNavbar'
import {baseURL, headers, Decrypt, AGORA_APP_ID} from '../../Config'
import VideoPlayer from '../../Components/VideoCallComp/AgoraVideoPlayer'
import {FiMic,FiMicOff,FiVideo,FiVideoOff} from 'react-icons/fi'
import {MdCallEnd} from 'react-icons/md'
import {useHistory} from 'react-router-dom'
import TestDeviceModal from '../../Components/VideoCallComp/TestDeviceModal'
import './CSS/VirtualSession.css'

// Creating client
const client = AgoraRTC.createClient({ codec: 'h264', mode: 'rtc' });

export default function VirtualSession(props) {

    const history = useHistory()

    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const [showTestModal,setShowTestModal] = useState(true)

    const dispatch = useDispatch()
    const sidebarWidth = useSelector(data => data.SidebarReducer.width)

    // States for agora
    const [localVideoTrack, setLocalVideoTrack] = useState(undefined);
    const [localAudioTrack, setLocalAudioTrack] = useState(undefined);
    const [joinState, setJoinState] = useState(false);
    const [remoteUsers, setRemoteUsers] = useState([]);
    const [userType,setUserType] = useState(null)

    // Other States
    const [isMutedVideo,setIsMutedVideo] = useState(false)
    const [isMutedAudio,setIsMutedAudio] = useState(false)
    const [audioDevices,setAudioDevices] = useState([])
    const [videoDevices,setVideoDevices] = useState([])


    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })

        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            setUserType(Decrypt(dt.userType))
        }
    },[])


    // Creating Local Audio and Video track
    const createLocalTracks = async (audioDeviceId,videoDeviceId)=>{
        
        let microphoneTrack;    
        let cameraTrack;

        try{   
          cameraTrack = await AgoraRTC.createCameraVideoTrack({
            cameraId:videoDeviceId,
            encoderConfig: {
              width: 640,
              // Specify a value range and an ideal value
              height: { ideal: 480, min: 400, max: 500 },
              frameRate: 15,
              bitrateMin: 100, bitrateMax: 1000,
            },
          });

          microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack({
            microphoneId:audioDeviceId,
            AEC:true,
            ANS:true
          });

          setLocalAudioTrack(microphoneTrack);
          setLocalVideoTrack(cameraTrack);
        }
        catch(err){
          if(err.code === 'DEVICE_NOT_FOUND'){
            setShowTestModal(true)
          }
        }

        return [microphoneTrack, cameraTrack];
    }

    // Function for join channel
    const join = async (appid, channel, token, audioDeviceId, videoDeviceId) => {
        
        if (!client) return;
        const [microphoneTrack, cameraTrack] = await createLocalTracks(audioDeviceId, videoDeviceId);
        await client.join(appid, channel, token || null);

        await client.publish([microphoneTrack, cameraTrack]);

        (window).client = client;
        (window).videoTrack = cameraTrack;
    
        setJoinState(true);
    }

    // leaving call
    const leave = async () => {
        if (localAudioTrack) {
          localAudioTrack.stop();
          localAudioTrack.close();
        }
        if (localVideoTrack) {
          localVideoTrack.stop();
          localVideoTrack.close();
        }
        setRemoteUsers([]);
        setJoinState(false);
        await client.unpublish();
        await client.leave();
        
        history.push(userType && userType === 'Parent' ? '/select-child' : '/rbt-appointments')
    }

    // mute audio
    const handleMuteAudio = async () =>{
        await localAudioTrack.setEnabled(false)
        setIsMutedAudio(true)
    }
    
    // unmute audio
    const handleUnMuteAudio = async () =>{
        await localAudioTrack.setEnabled(true)
        setIsMutedAudio(false)
    }
    
    // mute video
    const handleMuteVideo = async () =>{
      if(localVideoTrack){
        await localVideoTrack.setEnabled(false)
        setIsMutedVideo(true)
      }
    }
    
    // unmute video
    const handleUnMuteVideo = async () =>{
      if(localVideoTrack){
        await localVideoTrack.setEnabled(true)
        setIsMutedVideo(false)
      }
    }

    useEffect(() => {
        if (!client) return;
    
        setRemoteUsers(client.remoteUsers);
    
        const handleUserPublished = async (user, mediaType = ('audio' || 'video')) => {
          await client.subscribe(user, mediaType);
    
          // toggle re-render while state of remoteUsers changed.
          setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
        }
        const handleUserUnpublished = (user) => {
          setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
        }
        const handleUserJoined = (user) => {
          setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
        }
        const handleUserLeft = (user) => {
          setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
        }
    
        client.on('user-published', handleUserPublished);
        client.on('user-unpublished', handleUserUnpublished);
        client.on('user-joined', handleUserJoined);
        client.on('user-left', handleUserLeft);
    
        return () => {
          client.off('user-published', handleUserPublished);
          client.off('user-unpublished', handleUserUnpublished);
          client.off('user-joined', handleUserJoined);
          client.off('user-left', handleUserLeft);
        };
    
    }, [client]);

    const onHandleContinue = (audioDevice , videoDevice) =>{
        if(audioDevice?.value && videoDevice?.value){
            const dt = JSON.parse(localStorage.getItem('bx_user_token'))
            if(dt){
                fetch(baseURL + '/api/getSessionToken?Id='+props.match.params.id,{
                    headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
                })
                .then(res=>res.json())
                .then(res2=>{
                    if(res2?.code === 'Success'){
                        join(AGORA_APP_ID, res2?.message[0]?.channelName,res2?.message[0]?.token, audioDevice?.value , videoDevice?.value)
                        setShowTestModal(false)
                    }
                })
            }
          
        }
    }

    return (
        <div className="virtual-session-container">
            <ParentDashboardSideBar active={userType && userType === 'Parent' ? "sessions" : 'appointments'} user={userType && userType === 'Parent' ? "Parent" : 'RBT'} />
            <div style={{marginLeft:windowWidth > 1024 && sidebarWidth}}>
                {
                    userType&&userType === 'Parent' ?
                        <ParentDashboardNavbar active="sessions" user='Parent' />
                    :
                        <ClinicianDashboardNavbar 
                            active="appointments" user='RBT'
                            showAppointments={false}
                        />
                }
                <div className="virtual-session-body">
                    <div className="agora-video-component-container">
                        {
                            remoteUsers&&
                            remoteUsers.length > 0 ?
                                <VideoPlayer style={{maxWidth:'100%',height:'750px',position:'relative',backgroundColor:'black',borderRadius:8}} videoTrack={remoteUsers[0].videoTrack} audioTrack={remoteUsers[0].audioTrack}>
                                    <div className="agora-video-component-row">
                                        <div className='virtual-session-call-btns-container'>
                                            {
                                                isMutedAudio ?
                                                    <button onClick={handleUnMuteAudio} style={{marginRight:20}} className="call-btn">
                                                        <FiMicOff />
                                                    </button>
                                                :
                                                    <button onClick={handleMuteAudio} style={{marginRight:20}} className="call-btn">
                                                        <FiMic />
                                                    </button>
                                            }
                                            
                                            <button onClick={leave} style={{marginRight:20,fontSize:25,backgroundColor:'red'}} className="call-btn">
                                                <MdCallEnd color="white" />
                                            </button>

                                            {
                                                isMutedVideo ?
                                                    <button onClick={handleUnMuteVideo} style={{fontSize:22}} className="call-btn">
                                                        <FiVideoOff />
                                                    </button>
                                            :
                                                    <button onClick={handleMuteVideo} style={{fontSize:22}} className="call-btn">
                                                        <FiVideo />
                                                    </button>
                                            }
                                        </div>
                                        <div className="local-user-container">
                                            <VideoPlayer style={{width:'100%',height:'100%'}} videoTrack={localVideoTrack} key={localAudioTrack?.uid} ></VideoPlayer>
                                        </div>
                                    </div>
                                </VideoPlayer>
                                
                            :
                                <div className="" style={{maxWidth:'100%',height:'750px',position:'relative',backgroundColor:'black',borderRadius:8}}>
                                {
                                    joinState&&
                                    <div className="agora-video-component-row">
                                        <div className='virtual-session-call-btns-container'>
                                            {
                                                isMutedAudio ?
                                                    <button onClick={handleUnMuteAudio} style={{marginRight:20}} className="call-btn">
                                                        <FiMicOff />
                                                    </button>
                                                :
                                                    <button onClick={handleMuteAudio} style={{marginRight:20}} className="call-btn">
                                                        <FiMic />
                                                    </button>
                                            }
                                            
                                            <button onClick={leave} style={{marginRight:20,fontSize:25,backgroundColor:'red'}} className="call-btn">
                                                <MdCallEnd color="white" />
                                            </button>

                                            {
                                                isMutedVideo ?
                                                    <button onClick={handleUnMuteVideo} style={{fontSize:22}} className="call-btn">
                                                        <FiVideoOff />
                                                    </button>
                                            :
                                                    <button onClick={handleMuteVideo} style={{fontSize:22}} className="call-btn">
                                                        <FiVideo />
                                                    </button>
                                            }
                                        </div>
                                        <div className="local-user-container">
                                            <VideoPlayer style={{width:'100%',height:'100%'}} videoTrack={localVideoTrack} key={localAudioTrack.uid} ></VideoPlayer>
                                        </div>
                                    </div>
                                }
                                </div>
                        }
                        
                        <TestDeviceModal 
                            show={showTestModal} 
                            audioDevices={audioDevices} 
                            videoDevices={videoDevices}
                            onContinue={(audioDeviceId , videoDeviceId)=>onHandleContinue(audioDeviceId , videoDeviceId)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

