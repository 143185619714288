import React from "react";
import bg from "../../assets/works/combined.png";
function Learn() {
  return (
    <div className="lg:-mb-[270px] lg:px-0 w-full flex items-center relative justify-center isolate lg:-top-[300px] 2xl:-top-[350px] bg-lightPink-200 lg:bg-transparent py-8">
      <div className="max-w-[1440px] w-full flex flex-col items-center justify-center text-center gap-7 static lg:absolute left-1/2 top-[55%] lg:-translate-y-1/2 lg:-translate-x-1/2">
        <h1 className="text-lightGreen-500 font-bold text-[24px]  md:text-[40px] lg:text-[60px] xl:text-[80px]">
          IF THEY CAN’T LEARN THE <br /> WAY WE TEACH, WE TEACH <br /> THE WAY
          THEY LEARN.
        </h1>
        <h2 className="text-lightGreen-500 font-bold md:text-xl text-base lg:text-[40px]">
          Ivar Lovaas, Ph.D.
        </h2>
      </div>
      <img src={bg} className="w-full object-cover hidden lg:block" alt="" />
    </div>
  );
}

export default Learn;
