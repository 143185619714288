import React from "react";
import Faq from "./Faq";
import HeroSection from "./HeroSection";
import Model from "./Model";
import Redefine from "./Redefine";
import Steps from "./Steps";
import Team from "./Team";
import ChangeLife from "./Changelife";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Refer from "../../Components/Informed";

function Home() {
  return (
    <div>
      <Header />
      <HeroSection />
      <ChangeLife />
      <Model />
      <div className="bg-lightGreen-100 mt-[100px] overflow-x-hidden pb-[70px]">
        <Faq />
        <Redefine />
        <Steps />
        <Team />
      </div>
      <Refer />
      <Footer />
    </div>
  );
}

export default Home;
