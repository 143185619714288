import React from 'react'
import './CSS/ConsultationForm.css'

export default function ConsultationForm({value,onChange,handleFinish}) {
    return (
        <div style={{height:'100%',display:'flex',flexDirection:'column',alignItems:'center'}}>
            <textarea
                 placeholder='Observation notes here'
                 style={{width:'100%',height:'100%',border:'none',resize:'none',fontSize:16,outline:'none'}}
                 value={value}
                 onChange={(e)=>onChange(e.target.value)}
                 autoFocus
             ></textarea>
             <button onClick={handleFinish} className="consultatin-tool-finish-btn">Finish</button>
        </div>
    )
}
