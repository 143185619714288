import React,{ useEffect } from "react";

import { Route, Switch, useLocation } from "react-router-dom";
import ScrollToTop from './Components/ScrollToTop'
import {useDispatch} from 'react-redux'
import ScreenLoader from './ScreenLoader'
import {GetParentData} from './Store/Actions/ParentActions'
import {GetClinicianData} from './Store/Actions/ClinicianActions'
import {GetRBTData} from './Store/Actions/RBTActions'
import {GetBCBAData} from './Store/Actions/BCBAActions'
import {GetNotifications} from './Store/Actions/NotificationActions'
import { ToastContainer } from 'react-toastify';
import {ProtectedRoute,ProtectedRoute1,CommonProtectedRoute} from './ProtectedRoute'
import { Decrypt } from './Config';
import useSockets from './Hooks/useSockets';
import WebFont from 'webfontloader';

// Static pages
import ReferalForm from "./Pages/ReferalForm/index";
import Home from "./Pages/Home";
import Contact from "./Pages/Contact";
import Career from "./Pages/Career";
import Team from "./Pages/Team";
import About from "./Pages/About";
import Autism from "./Pages/Autism";
import Works from "./Pages/Works";
import Pediatricians from "./Pages/Pediatricians";
import MChatIntroScreen from './Pages/MChat/IntroScreen'
import MChatQuestions from './Pages/MChat/MChatQuestions'
import MChatResult from './Pages/MChat/MChatResult'
import ChildCondition from './Pages/SelectChildCondition'
import ChildOrAdult from './Pages/ChildOrAdult'
import PrivacyPolicy from './Pages/PrivacyPolicy'
import TermsAndConditions from './Pages/TermsAndConditions'
import CareersFrom from './Pages/CareersFrom'
import CareerBCBC from './Pages/CareerBcba'
import CareerBI from './Pages/CareerBI'
import CookiePolicy from './Pages/CookiesPolicy'

import CookieConsent, { Cookies } from "react-cookie-consent";


// Login Pages
import LoginPage from './Pages/Registeration/login/LoginPage'
import ForgetPassPage from './Pages/Registeration/login/ForgetPassPage'
import ResetPassPage from './Pages/Registeration/login/ResetPassPage'

// Signup Pages
import SignupPage from './Pages/Registeration/Signup/SignupPage'

//Onboarding pages
import SelectDiagnosis from './Pages/OnBoarding/SelectDiagnosis'
import PreCallQuestionaire from './Pages/OnBoarding/PreCallQuestionsForm/Index'
import ScheduleFirstMeeting from './Pages/Registeration/Signup/ScheduleFirstMeeting'
import PaymentCardPage from './Pages/Registeration/Signup/PaymentCardPage'
import InsurancePay from './Pages/Registeration/Signup/InsurancePay'
import ConfirmPayment from './Pages/Registeration/Signup/ConfirmPayment'
import SelectPaymentType from './Pages/Registeration/Signup/SelectPaymentType'

// Common Pages
import ChildProfiles from './Pages/CommonPages/ChildProfiles'
import ChildProfileDetail from './Pages/CommonPages/ChildProfileDetail'
import Appointments from './Pages/CommonPages/Appointments'
import SelectChild from './Pages/CommonPages/SelectChild'
import ReportGenerationIntro from './Pages/CommonPages/ReportGenerationIntro'
import ReportsView from './Pages/CommonPages/ReportsView'
import ParentsProfiles from './Pages/CommonPages/ParentsProfiles'
import ParentDetail from './Pages/CommonPages/ParentDetail'
import DiagnosisReports from './Pages/CommonPages/DiagnosisReports'
import AdditionalResources from './Pages/CommonPages/AdditionalResources'
import TherapistChat from './Pages/CommonPages/TherapistChat'
import TherapistProfileDetail from './Pages/CommonPages/TherapistProfileDetail'
import ClinicianEditProfile from './Pages/CommonPages/ClinicianEditProfile'
import VirtualSession from './Pages/CommonPages/VirtualSession'

// Parent Dashboard Pages
import DashboardMainPage from './Pages/ParentDashboardPages/DashboardHomePage'
import ParentEditProfile from './Pages/ParentDashboardPages/ParentEditProfile'
import WatchVideo from './Pages/ParentDashboardPages/WatchVideo'
import InstructionsAndGuidencePage from './Pages/ParentDashboardPages/Instructions&GuidencePage'
import AssignedClinician from './Pages/ParentDashboardPages/AssignedClinician'
import ScheduleAppointment from './Pages/ParentDashboardPages/ScheduleAppointment'
import ParentVideoCallingPage from './Pages/ParentDashboardPages/ParentVideoCallingPage'
import SelectChildCondition from './Pages/ParentDashboardPages/AddNewChild/SelectChildCondition'
import SelectDiagnosisType from './Pages/ParentDashboardPages/AddNewChild/SelectDiagnosisType'
import ScreenerIntro from './Pages/ParentDashboardPages/AddNewChild/ScreenerIntro'
import ScreenerQuestions from './Pages/ParentDashboardPages/AddNewChild/ScreenerQuestions'
import ScreenerResult from './Pages/ParentDashboardPages/AddNewChild/ScreenerResult'
import PaymentDetail from './Pages/ParentDashboardPages/AddNewChild/PaymentDetail'
import DashboarSelectPaymentType from './Pages/ParentDashboardPages/AddNewChild/SelectPaymentType'
import ParentChats from './Pages/ParentDashboardPages/ParentChats'
import ParentSessions from './Pages/ParentDashboardPages/Sessions'
import SelectAvailability from './Pages/ParentDashboardPages/SelectAvailability'

// Clinician Dashboard
import ClinicianDashboard from './Pages/BCDashboardPages/ClinicianDashboardHome'
import CliniciansProfiles from './Pages/BCDashboardPages/CliniciansProfiles'
import AssignedBCBA from './Pages/BCDashboardPages/AssignedBCBA'
import ClinicianVideoCallingPage from './Pages/BCDashboardPages/ClinicianVideoCallingPage'
import RatingForm from './Pages/BCDashboardPages/RatingForm'
import BCSessions from './Pages/BCDashboardPages/Sessions'

// BCBA Dashboard
import BCBADashboard from './Pages/BCBADashboardPages/ClinicianDashboardHome'
import BCBABehaviorConsultants from './Pages/BCBADashboardPages/BehaviorConsultants'
import BCBAVideoCallingPage from './Pages/BCBADashboardPages/ClinicianVideoCallingPage'
import BCBARatingForm from './Pages/BCBADashboardPages/RatingForm'
import BCBASessions from './Pages/BCBADashboardPages/Sessions'

// // RBT Dashboard
import BCBAProfiles from './Pages/RBTDashboardPages/CliniciansProfiles'
import RBTAppointments from './Pages/RBTDashboardPages/ClinicianAppointments'
import RbtEarnings from './Pages/RBTDashboardPages/RbtEarnings'
import RBTVideoCallingPage from './Pages/RBTDashboardPages/ClinicianVideoCallingPage'
import RBTChildReports from './Pages/RBTDashboardPages/ChildReports'


function App() {

  const dispatch = useDispatch()
  const runSockets = useSockets()
  const location = useLocation();

  useEffect(()=>{
    runSockets()

    const data = JSON.parse(localStorage.getItem('bx_user_token'))
        
    if(data){
      if(Decrypt(data.userType) === 'BC'){
          dispatch(GetClinicianData())
          dispatch(GetNotifications())
      }
      else if (Decrypt(data.userType) === 'BCBA'){
          dispatch(GetBCBAData())
      }
      else if (Decrypt(data.userType) === 'RBT'){
          dispatch(GetRBTData())
      }
      else{
        dispatch(GetParentData())
        dispatch(GetNotifications())
      }
    }
  },[])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="max-w-screen">
      <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/Works" component={Works} />
          <Route path="/Pediatricians" component={Pediatricians} />
          <Route path="/Autism" component={Autism} />
          <Route path="/About" component={About} />
          <Route path="/Team" component={Team} />
          <Route path="/Career" component={Career} />
          <Route path="/Contact" component={Contact}  />
          <Route path="/ReferalForm" component={ReferalForm} />
          <Route path="/privacyPolicy" component={PrivacyPolicy} />
          <Route path="/termAndConditions" component={TermsAndConditions} />
          <Route path="/cookiePolicy" component={CookiePolicy} />
          <Route path="/careersFrom" component={CareersFrom} />
          <Route path="/careersBCBC" component={CareerBCBC} />
          <Route path="/careersBI" component={CareerBI} />


          {/* Login Routes */}
          <Route path="/login" component={LoginPage} />
          <Route path="/forgot-password" component={ForgetPassPage} />
          <Route path="/reset-password" component={ResetPassPage} />

          {/* Signup Routes */}
          <Route path="/mchat-screener-intro" component={MChatIntroScreen} />
          <Route path="/mchat-screener-questions" component={MChatQuestions} />
          <Route path="/mchat-screener-result/:result" component={MChatResult} />
          <Route path='/select-diagnosis' component={SelectDiagnosis} />
          <Route path='/child-condition' component={ChildCondition} />
          <Route path='/child-or-adult' component={ChildOrAdult} />
          <Route path="/signup" component={SignupPage} />

          {/* on boarding */}
          <ProtectedRoute path="/payment-type" component={SelectPaymentType} />
          <ProtectedRoute path="/payment-detail" component={ConfirmPayment} />
          <ProtectedRoute path="/self-pay" component={PaymentCardPage} />
          <ProtectedRoute path="/insurance-pay" component={InsurancePay} />
          <ProtectedRoute path='/pre-call-questionnaire' component={PreCallQuestionaire} />
          <ProtectedRoute path="/schedule-first-meeting" component={ScheduleFirstMeeting} />

          {/* Common Pages */}
          <CommonProtectedRoute path="/childs" component={ChildProfiles} />
          <CommonProtectedRoute path="/child-detail/:id" component={ChildProfileDetail} />
          <CommonProtectedRoute path="/appointments" component={Appointments} />
          <CommonProtectedRoute path="/select-child" component={SelectChild} />
          <CommonProtectedRoute path="/report-generation/:id" component={ReportGenerationIntro} />
          <CommonProtectedRoute path="/report-view/:id/:type" component={ReportsView} />
          <CommonProtectedRoute path="/parents-profiles" component={ParentsProfiles} />
          <CommonProtectedRoute path="/parent-detail/:id" component={ParentDetail} />
          <CommonProtectedRoute path="/diagnosis-reports" component={DiagnosisReports} />
          <CommonProtectedRoute path="/additional-resources" component={AdditionalResources} />
          <CommonProtectedRoute path="/therapist-chats" component={TherapistChat} />
          <CommonProtectedRoute path="/therapist-profile-detail/:type/:id" component={TherapistProfileDetail} />
          <CommonProtectedRoute path="/profile-detail" component={ClinicianEditProfile} />
          <CommonProtectedRoute path="/virtual-session/:id" component={VirtualSession} />

          {/* Parent Dashboard Routes */}
          <ProtectedRoute path="/dashboard-home" component={DashboardMainPage} />
          <ProtectedRoute path="/perent-account-info" component={ParentEditProfile} />
          <ProtectedRoute path="/instructions-and-guidance" component={InstructionsAndGuidencePage} />
          <ProtectedRoute path="/watch-video/:comp" component={WatchVideo} />
          <ProtectedRoute path="/assigned-clinician" component={AssignedClinician} />
          <ProtectedRoute path="/schedule-appointment" component={ScheduleAppointment} />
          <ProtectedRoute path="/parent-calling" component={ParentVideoCallingPage} />
          <ProtectedRoute path="/dashboard-child-condition" component={SelectChildCondition} />
          <ProtectedRoute path="/dashboard-diagnosis-type" component={SelectDiagnosisType} />
          <ProtectedRoute path="/dashboard-screener-intro" component={ScreenerIntro} />
          <ProtectedRoute path="/dashboard-screener-questions" component={ScreenerQuestions} />
          <ProtectedRoute path="/dashboard-screener-result/:result" component={ScreenerResult} />
          <ProtectedRoute path="/dashboard-payment-detail" component={PaymentDetail} />
          <ProtectedRoute path="/dashboar-select-payment-type" component={DashboarSelectPaymentType} />
          <ProtectedRoute path="/parent-chats" component={ParentChats} />
          <ProtectedRoute path="/child-sessions/:id" component={ParentSessions} />
          <ProtectedRoute path="/parent-set-availability/:childId/:packageId" component={SelectAvailability} />

          {/* BC Dashoboard Routes */}
          <ProtectedRoute1 path="/bc-dashboard" component={ClinicianDashboard} />
          <ProtectedRoute1 path="/clinicians" component={CliniciansProfiles} />
          <ProtectedRoute1 path="/bc-assigned-bcba" component={AssignedBCBA} />
          <ProtectedRoute1 path="/bc-calling" component={ClinicianVideoCallingPage} />
          <ProtectedRoute1 path="/assessment-rating-form/:id" component={RatingForm} />
          <ProtectedRoute1 path="/bc-sessions" component={BCSessions} />

          {/* BCBA Dashoboard Routes */}
          <ProtectedRoute1 path="/bcba-dashboard" component={BCBADashboard} />
          <ProtectedRoute1 path="/behaviour-consultants" component={BCBABehaviorConsultants} />
          <ProtectedRoute1 path="/bcba-calling" component={BCBAVideoCallingPage} />
          <ProtectedRoute1 path="/bcba-assessment-rating-form/:id" component={BCBARatingForm} />
          <ProtectedRoute1 path="/bcba-sessions" component={BCBASessions} />

          {/* RBT Dashoboard Routes */}
          <ProtectedRoute1 path="/rbt-appointments" component={RBTAppointments} />
          <ProtectedRoute1 path="/bcba-profiles" component={BCBAProfiles} />
          <ProtectedRoute1 path="/rbt-earnings" component={RbtEarnings} /> 
          <ProtectedRoute1 path="/rbt-calling" component={RBTVideoCallingPage} />
          <ProtectedRoute1 path="/rbt-child-reports" component={RBTChildReports} />
      </Switch>
        <CookieConsent
          location="bottom"
          buttonText="Close"
          buttonClasses="btn btn-primary py-3 px-5 rounded"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#278091",padding:"15px 10px", fontSize:"20px" }}
          buttonStyle={{ backgroundColor:"#F99B9B", color: "#ffffff", fontSize: "18px" }}
          expires={150}
        >
          This website uses cookies to enhance user experience.
      </CookieConsent>
      <ToastContainer hideProgressBar={true} autoClose={1000}/>
    </div>
  );
}

export default App;
