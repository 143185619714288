import React,{useEffect} from 'react'
import logo from '../images/logo.png'
import {useSelector,useDispatch} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {GetParentData} from '../Store/Actions/ParentActions'
import {PatientRoutes,BCBARoutes,RBTRoutes, BCRoutes} from '../Routes'
import './CSS/ParentDashboardSideBar.css'
import { Link,useHistory } from 'react-router-dom';
import { toast } from 'react-toastify'

export default function ParentDashboardSideBar(props) {

    const history = useHistory()
    const location = useLocation()

    const sidebarWidth = useSelector(data => data.SidebarReducer.width)
    const dispatch = useDispatch()

    const handleLogout = () =>{
        dispatch({type:'GetAppointments',payload:[]})
        localStorage.removeItem('bx_user_token')
        history.push('/')
        // location.href = '/'
        // window.location.href = '/'
    }

    useEffect(()=>{
        const data = JSON.parse(localStorage.getItem('bx_user_token'))
          
        if(data){
            dispatch(GetParentData())
        }
    },[])

    const showAlert = () =>{
        toast.error('Coming soon',{toastId:'show-alert-coming-soon-id'})
    }

    return (
        <div className={sidebarWidth === '18%' ? 'parent-dashboard-sidebar-comp bg-lightGreen-100' : 'parent-dashboard-sidebar-comp1 bg-lightGreen-100'}>
            <div className="parent-dashboard-head-section">
                {/* <div className="d-flex justify-content-end">
                    <FaBars color="gray" size={25} id="bars" onClick={()=>dispatch(SetSidebarWidth())} />
                </div> */}
                {
                    sidebarWidth === '18%' &&
                    <HeaderLogo />
                    
                } 
            </div>
            {
                props.user === 'BCBA' ?
                    <div  className="parent-dashboard-menu-container">
                        {
                            BCBARoutes?.map((dt,i)=>{
                                return(
                                    <Link key={i} to={dt.route || '#'} onClick={()=>dt.type == 'logout' ? handleLogout() : dt.type == 'notes' || dt.type == 'schedule' ? showAlert() : null } className={dt.type == props.active ? "parent-dashboard-menu-row active" : "parent-dashboard-menu-row"}>
                                        <div className="parent-dashboard-menu-col-1">
                                            <img src={dt.type == props.active ? dt.activeIcon : dt.icon} />
                                        </div>
                                        {
                                            sidebarWidth === '18%' &&
                                            <div className="parent-dashboard-menu-col-2">
                                                <p>{dt.name}</p> 
                                            </div>
                                        }
                                    </Link>
                                )
                            })
                        }
                    </div>

                :props.user === 'BC' ?
                    <div  className="parent-dashboard-menu-container">
                        {
                            BCRoutes?.map((dt,i)=>{
                                return(
                                    <Link key={i} to={dt.route || '#'} onClick={()=>dt.type == 'logout' ? handleLogout() : dt.type == 'notes' || dt.type == 'schedule' ? showAlert() : null } className={dt.type == props.active ? "parent-dashboard-menu-row active" : "parent-dashboard-menu-row"}>
                                        <div className="parent-dashboard-menu-col-1">
                                            <img src={dt.type == props.active ? dt.activeIcon : dt.icon} />
                                        </div>
                                        {
                                            sidebarWidth === '18%' &&
                                            <div className="parent-dashboard-menu-col-2">
                                                <p>{dt.name}</p> 
                                            </div>
                                        }
                                    </Link>
                                )
                            })
                        }
                    </div>

                : props.user === 'RBT' ?

                    <div  className="parent-dashboard-menu-container">
                        {
                            RBTRoutes?.map((dt,i)=>{
                                return(
                                    <Link key={i} to={dt.route || '#'} onClick={()=>dt.type == 'logout' ? handleLogout() : dt.type == 'notes' ? showAlert() : null } className={dt.type == props.active ? "parent-dashboard-menu-row active" : "parent-dashboard-menu-row"}>
                                        <div className="parent-dashboard-menu-col-1">
                                            <img src={dt.type == props.active ? dt.activeIcon : dt.icon} />
                                        </div>
                                        {
                                            sidebarWidth === '18%' &&
                                            <div className="parent-dashboard-menu-col-2">
                                                <p>{dt.name}</p> 
                                            </div>
                                        }
                                    </Link>
                                )
                            })
                        }
                    </div>
                :
                    <div  className="parent-dashboard-menu-container">
                        {
                            PatientRoutes?.map((dt,i)=>{
                                return(
                                    <Link key={i} to={dt.route || '#'} onClick={()=>dt.type == 'logout' ? handleLogout() : dt.type == 'notes' ? showAlert() : null } className={dt.type == props.active ? "parent-dashboard-menu-row active" : "parent-dashboard-menu-row"}>
                                        <div className="parent-dashboard-menu-col-1">
                                            <img src={dt.type == props.active ? dt.activeIcon : dt.icon} />
                                        </div>
                                        {
                                            sidebarWidth === '18%' &&
                                            <div className="parent-dashboard-menu-col-2">
                                                <p>{dt.name}</p> 
                                            </div>
                                        }
                                    </Link>
                                )
                            })
                        }
                    </div>
            }
        </div>
    )
}


const HeaderLogo = () =>{
    return (    
        <div className="">
            <img src={logo} className="img-fluid" />
        </div>
    )
}