import React,{useEffect,useState} from 'react'
import { Link } from 'react-router-dom'
import { baseURL, headers, Encrypt } from '../Config'
import {getTimeInLocal,getTimeInLocalString,convert24To12,slotsSequence,convert12To24,convertIntoUtc} from '../TimeFormate'
import Swal from 'sweetalert2'
import moment from 'moment'
import Skeleton from 'react-loading-skeleton';
import {GetClinicianAppointments, GetParentAppointments} from '../Store/Actions/AppointmentActions'
import {useDispatch} from 'react-redux'
import {Modal,Button,Spinner} from 'react-bootstrap'
import {RiCloseLine} from 'react-icons/ri'
import CalenderComp from '../Components/CalenderComp2'
import './CSS/DashboardAppointmentCard.css'
import { toast } from 'react-toastify'

export default function DashboardAppointmentCard(props) {

    const dispatch = useDispatch()

    const [date,setDate] = useState('')
    const [time,setTime] = useState('')
    const [days,setDays] = useState([])
    const [clinician_id,setClinicianId] = useState('')
    const [time2,setTime2] = useState('')
    const [date2,setDate2] = useState('')
    const [show,setShow] = useState(false)
    const [slots,setSlots] = useState([])
    const [isLoaded,setIsLoaded] = useState(false)
    const [isLoading,setIsLoading] = useState(false)

    useEffect(() => {

        const newDateTime = getTimeInLocalString(props.dateAndtime)
        const date = newDateTime.split(' ')[1] + ' ' + newDateTime.split(' ')[2] + ' ' + newDateTime.split(' ')[3]
        const time = newDateTime.split(' ')[4] + ' ' + newDateTime.split(' ')[5]

        setDate(date)
        setTime(time)
    }, [props])


    const handleCancelAppointment = () =>{
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))

        const currentDate = new Date()
        const diff = moment.duration(moment(getTimeInLocal(props.dateAndtime)).diff(getTimeInLocal(currentDate)))
        const diffInHour = Math.round(diff/3.6e+6)
        
        if(dt){
            if(diffInHour > 2){
                Swal.fire({
                    title: 'Are you sure?',
                    text: 'Once cancel, you will not be able to recover this!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, cancel it!',
                    // confirmButtonColor:"#F99B9B",
                    cancelButtonText: 'No, keep it'
                }).then((result) => {
                    if (result.isConfirmed) {

                        // dispatch({type:'AppointmentIsLoaded',payload:false})

                        fetch(baseURL + '/api/cancel-schedule',{
                            method:'Delete',
                            body:JSON.stringify({Id:props.Id}),
                            headers:{ ...headers ,'authorization':'Bearer ' + dt.accessToken}
                        })
                        .then(res=>res.json())
                        .then(res2=>{
                            if(res2.code === 'Error'){
                                toast.error(res2.message,{toastId:'parent-cancel-app-id',autoClose:5000})
                            }
                            else{
                                props?.user == 'BC' ?
                                    dispatch(GetClinicianAppointments())
                                :
                                    dispatch(GetParentAppointments())
                                toast.success(res2.message,{toastId:'parent-canceled-app-id',autoClose:5000})
                            }

                        })
                    }
                })
            }
            else{
                toast.error('You can not cancel appointment',{toastId:'parent-cancel-app-id-1',autoClose:2000})
            }
        }
    }

    const handleOpenModal = () => {
        const date = new Date()
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            setShow(true)
            fetch(baseURL+ '/api/get-clinician-available-dates',{
                method:'Post',
                body:JSON.stringify({uid:dt.uid,Date:getTimeInLocal(date)}),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    toast.error('error..')
                    setIsLoaded(true)
                }
                else{
                    if(res2.message&&res2.message.slots.length > 0){
                        const mySlots = []
                        const sequencedSlots = slotsSequence(res2.message.slots)
                        sequencedSlots.map(dt=>{
                            const local_time = getTimeInLocal(dt)
                            const diff = moment.duration(moment(getTimeInLocal(dt)).diff(getTimeInLocal(date)))
                            
                            if(local_time.split('T')[0] === getTimeInLocal(date).split('T')[0] && (diff/1000) > 0)
                            {
                                const slot = convert24To12(local_time)
                                mySlots.push(slot)
                            }
                        })

                        setDays(res2.message.daysOfWeek)
                        setSlots(mySlots)
                        setClinicianId(res2.message.clinician_id)
                        setIsLoaded(true)
                    }else{
                        setDays(res2.message.daysOfWeek)
                        setSlots([])
                        setClinicianId(res2.message.clinician_id)
                        setIsLoaded(true)
                    }
                }
            })
        }
    }


    const handleRescheduling = () =>{
        if(date2 !== '' && time2 !== ''){
            const dt = JSON.parse(localStorage.getItem('bx_user_token'))

            const currentDate = new Date()

            const newDate = getTimeInLocal(date2).split('T')[0]
            const newTime = convert12To24(time2)
            const utc_date = convertIntoUtc(newDate + 'T' + newTime + ':00')

            const diff = moment.duration(moment(getTimeInLocal(props.dateAndtime)).diff(getTimeInLocal(currentDate)))

            const diffInHour = Math.round(diff/3.6e+6)

            if(dt){

                if(diffInHour > 24){
                    setIsLoading(true)
                    fetch(baseURL+ '/api/appointment-reschedule-request',{
                        method:'Put',
                        body:JSON.stringify({
                            Id:props.Id,
                            appointmentDate:Encrypt(utc_date)
                        }),
                        headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
                    })
                    .then(res=>res.json())
                    .then(res2=>{
                        if(res2.code === 'Error'){
                            toast.error(res2.message,{toastId:'reschedule-app-err-id-1',autoClose:5000})
                            setIsLoading(false)
                        }
                        else{
                            toast.success(res2.message,{toastId:'reschedule-app-succ-id-1',autoClose:5000})
                            setTime2('')
                            setDate2('')
                            setIsLoading(false)
                            setShow(false)
                        }
                    })
                }
                else{
                    toast.error('You can not Reschedule appointment',{toastId:'reschedule-app-err-id-2',autoClose:2000})
                }
            }
        }
        else{
            toast.error('Please select date or time',{toastId:'reschedule-app-err-id-3',autoClose:2000})
        }
    }


    const getRemainingTime = (date) =>{
        const currentDate = new Date()
        const diff = moment.duration(moment(getTimeInLocal(date)).diff(getTimeInLocal(currentDate)))
        const minutes = Math.ceil(diff / 60000)

        if(minutes > 0){
            if((minutes / 1440) >= 1){
                const days = minutes / 1440
                if(Math.floor(days) > 1){
                    return Math.round(days) + ' Days Remaining'
                }
                else{
                    return Math.round(days) + ' Day Remaining'
                }
            }
            else if((minutes / 60) >= 1){
                const hours = minutes / 60
                if(Math.floor(hours) > 1){
                    return Math.round(hours) + ' Hours Remaining'
                }
                else{
                    return Math.round(hours) + ' Hour Remaining'
                }
            }
            else{
                if(minutes > 1){
                    return Math.round(minutes) + ' Minutes Remaining'
                }
                else{
                    return Math.round(minutes) + ' Minute Remaining'
                }
            }
        }
        else{
            return '0 minutes Remaining'
        }

    }

    return (
        <div className="dashboard-appointments-card-container">
            <div>
                <h5>{props.title}</h5>
                <p className="appointment-date">{date}</p>
                <p className="appointment-time">{time&&time.split(':')[0].length < 2 ? '0' + time : time}</p>
            </div>
            <div style={{marginTop:20,display:'flex',justifyContent:'space-between',alignItems:'center'}}>
            {
                props.status === 'Upcoming' ?
                    props.user !== 'clinician' ?
                        <Link to="#" onClick={handleOpenModal} className="appointment-card-reschedule">Reschedule</Link>
                    :
                        <div className="appointment-card-reschedule">{getRemainingTime(props.dateAndtime)}</div>
                :   
                    
                    <p className="appointment-card-status">Status: {props.status === 1 ? "Done" :props.status === 2 ? "Cancel" : "No Show"}</p>
            }
            
            { props.status === 'Upcoming'&&props.user !== 'clinician'&&<Link to="#" className="d-flex align-items-center appointment-card-cancel" onClick={handleCancelAppointment}>Cancel</Link>}
            </div>
            <Modal show={show} centered animation={false}>
                <Modal.Header style={{border:'none',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                    <h1 style={{fontFamily:'CocoSharp regular',color:'#0B0F66',fontSize:28,margin:0}}>Reschedule Your Call</h1>
                    <a style={{fontSize:24}}>
                        <RiCloseLine color="#096E82" onClick={()=>setShow(false)} />
                    </a>
                </Modal.Header>
                <Modal.Body style={{zIndex:1,width:'100%'}} className="py-2 pb-4">
                    {
                        isLoaded ?
                            <>
                                <CalenderComp
                                    tileDisabled={({date, view}) =>!days.includes(date.getDay())}
                                    slots={slots}
                                    selectedTime={(time)=>setTime2(time)} 
                                    selectedDate={(date)=>setDate2(date)}
                                />

                                {
                                    isLoading ?
                                        <button id="appointment-card-reschedule-btn2">
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            {' '}
                                            Loading....
                                        </button>
                                    :

                                    <Button onClick={handleRescheduling} id="appointment-card-reschedule-btn2">Reschedule</Button>
                            }

                            </>

                        :
                            <div>
                                <Skeleton width='100%' height={50} />
                                <Skeleton width='100%' height={200} />
                                <Skeleton width='100%' height={80} />
                                <Skeleton width='100%' height={50} style={{marginTop:30}} />
                            </div>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
}
