import React,{useState,useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {Row,Col} from 'react-bootstrap'
import ParentDashboardSideBar from '../../../Components/ParentDashboardSideBar'
import ParentDashboardNavbar from '../../../Components/ParentDashboardNavbar'
import SelectComponent from '../../../Components/SelectComponent'
import './CSS/SelectDiagnosisType.css'
import { baseURL, Encrypt, headers } from '../../../Config'
import { toast } from 'react-toastify'

export default function SelectDiagnosisType() {

    const history = useHistory()
    const [windowWidth,setWindowWidth] = useState(window.innerWidth)


    const sidebarWidth = useSelector(data => data.SidebarReducer.width)

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })
    },[])


    const handleOnChange = (val) =>{
        const data = JSON.parse(localStorage.getItem('bx_user_token'))
        if(val !== '' && data){
            localStorage.setItem('diagnosisType',JSON.stringify({isRecom:val,score:''}))
            history.push('/dashboar-select-payment-type')

             /// Will Remove after payment
            // const obj = {
            //     diagnosis_type:Encrypt(val),
            //     mchat_score:'',
            //     Id:data.uid
            // }
            // fetch(baseURL + '/api/payWithCardAndCreateChild',{
            //     method:'Post',
            //     body:JSON.stringify(obj),
            //     headers:{...headers,'authorization':'Bearer ' + data.accessToken}
            // })
            // .then(res=>res.json())
            // .then(res2=>{
            //     if(res2.code === 'Success'){
            //         localStorage.removeItem('diagnosisType')
            //         window.localStorage.setItem('bx_user_token',JSON.stringify({...data,childId:res2.message.childId,childStatus:Encrypt(res2.message.childStatus)}))
            //         history.push('/pre-call-questionnaire')
            //     }
            //     else{
            //         toast.error(res2.message,{toastId:'insurance-req-err-id1',autoClose:3000})
            //     }
            // })
        }
    }

    return (
        <div className="parent-dashboard-diagnosis-type">
            <ParentDashboardSideBar active="child" />
            <div style={{marginLeft:windowWidth > 1024 && sidebarWidth}}>
                <ParentDashboardNavbar active="child" />
                <Row className='m-0 pt-4 px-0'>
                    <Col sm={12} md={12} className="select-diagnosis-col">
                        <div className="select-container">
                            <h1 style={{color:'#030533'}}>Select Diagnosis</h1>
                            <p style={{color:'#030533',fontSize:20}}>Worried you or a loved one might have Autism?</p>
                            <SelectComponent 
                                label="Select Here"
                                containerStyle={{width:'100%',marginTop:20}}
                                labelStyle={{fontSize:16,fontWeight:600,textAlign:'left'}}
                                onChange={(val)=>handleOnChange(val)}
                                options={['Assessment','Consultation']}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

