import React from "react";
import img from "../../assets/Home/model.png";
import patt from "../../assets/Home/model-patt.png";
import PinkBtn from "../../Components/PinkBtn";

function Model() {
  return (
    <div className="w-full flex justify-center items-center mt-[140px] xl:mt-[180px] px-5">
      <div className="xl:flex-row flex-col max-w-[1440px] flex justify-center items-center gap-16 xl:gap-8 w-full">
        <div className="relative isolate  flex justify-end items-center">
          <img
            src={patt}
            className="absolute -z-[1] -top-[10%] sm:-left-[8%] h-full -left-[4%]"
            alt=""
          />
          <img
            src={img}
            alt=""
            className="w-full max-w-[400px] xl:max-w-[522px]"
          />
        </div>
        <div className="flex justify-center items-start flex-col gap-4 xl:w-1/2">
          <h1 className="text-lightGreen-500 leading-9 sm:leading-[50px] font-bold text-[30px] sm:text-[48px]  ">
            Early Comprehensive <br /> Treatment Models
          </h1>
          <p className="text-lightGreen-500 font-normal text-lg ">
            We use scientific and research validated early comprehensive
            treatment models for effective Cognitive, Language, and Behavioral
            outcomes in children with Autism.
          </p>
          <div className="mt-6">
            <PinkBtn text={"Get Started"} url={"Signup"} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Model;
