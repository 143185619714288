import React from 'react'
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Informed from "../Components/Informed";
import ChildCondition from '../Components/ChildCondition'
import { Col } from 'react-bootstrap'

export default function SelectChildCondition() {


    return (
        <div style={{width:'100%'}}>
            <Header />
            <Col sm='12' md='12' lg='12' xl='12' className='mt-5' >
                <ChildCondition path1="/signup" path2="/mchat-screener-intro" />
            </Col>
            <Informed />
            <Footer />
        </div>
    )
}
