import React,{useState,useEffect} from 'react'
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './CSS/CallCompletedModal.css'

export default function CallCompletedModal(props) {
    
    return (
        <div className="call-completed-modal-component">
            <Modal contentClassName="call-completed-modal" size="lg" show={props.showCompletedAlert} centered animation={false}>
                <h5 style={{textAlign:'center',fontFamily:'CocoSharp bold',color:'white',margin:0,fontSize:35}}>Call has completed</h5>
                <Link to={{pathname:props.path,state:{reload:true}}}>
                    <Button style={{border:'none',backgroundColor:'#096E82',marginTop:25,width:150,height:43,borderRadius:10}}>Home</Button>
                </Link>
            </Modal>
        </div>
        
    )
}
