import React from 'react'
import AccordionComp from '../AccordionComp'
import './CSS/Tele-Peds-ASD.css'

export default function TelePedsASD() {
    return (
        <div className="tele-peds-asd-container">
            <p className="tele-peds-asd-title">TELE-PEDS-ASD</p>
            <p className="tele-peds-asd-sub-title">(Administration Guidelines & General Observation)</p>

            <AccordionComp 
                title="1.Speech & Sound" 
                content={
                    <div className="mt-3">
                        – Functional play? <br />
                        – Pretend play? <br />
                        – Imitation? <br />
                        – Repetitive or unusual play: lining up, scrambling/dropping/throwing toys <br />
                        – Becomes preoccupied with a certain toy/activity
                    </div>
                }
                containerStyle={{marginTop:40}}
            />
            <AccordionComp 
                title="2.Coordinating eye contact/gestures/speech or vocalization" 
                content={
                    <div className="mt-3">
                        – Functional play? <br />
                        – Pretend play? <br />
                        – Imitation? <br />
                        – Repetitive or unusual play: lining up, scrambling/dropping/throwing toys <br />
                        – Becomes preoccupied with a certain toy/activity
                    </div>
                }
            />
            <AccordionComp 
                title="3.Play" 
                content={
                    <div className="mt-3">
                        – Functional play? <br />
                        – Pretend play? <br />
                        – Imitation? <br />
                        – Repetitive or unusual play: lining up, scrambling/dropping/throwing toys <br />
                        – Becomes preoccupied with a certain toy/activity
                    </div>
                }
            />
            <AccordionComp 
                title="4.Body Movement" 
                content={
                    <div className="mt-3">
                        – Functional play? <br />
                        – Pretend play? <br />
                        – Imitation? <br />
                        – Repetitive or unusual play: lining up, scrambling/dropping/throwing toys <br />
                        – Becomes preoccupied with a certain toy/activity
                    </div>
                }
            />
            <AccordionComp 
                title="5.Sensory Differences" 
                content={
                    <div className="mt-3">
                        – Functional play? <br />
                        – Pretend play? <br />
                        – Imitation? <br />
                        – Repetitive or unusual play: lining up, scrambling/dropping/throwing toys <br />
                        – Becomes preoccupied with a certain toy/activity
                    </div>
                }
            />
        </div>
    )
}
