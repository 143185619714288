import React from 'react'

export default function ExtendCallConfirmModal(props) {
    return (
        <div style={{width:280,padding:'5px 10px',height:150,zIndex:1,borderRadius:10,display:'flex',flexDirection:'column',justifyContent:'center',backgroundColor:'white',position:'absolute',top:'50%',left:'50%',transform:'translate(-50%,-50%)'}}>
            <p style={{fontSize:18,marginBottom:10,fontFamily:'CocoSharp regular',textAlign:'center'}}>Do you wants to extend call for 15 minutes</p>
            <div className="d-flex justify-content-center">
                <button 
                    style={{border:'none',width:100,color:'white',marginRight:10,height:35,borderRadius:10,backgroundColor:'#096E82'}}
                    onClick={()=>props.handleExtendCall()}
                >Yes</button>
                <button 
                    style={{border:'solid 2px #096E82',width:100,height:35,borderRadius:10,backgroundColor:'white',color:'#096E82'}}
                    onClick={()=>props.setExtendCallConfirmation(false)}
                >No</button>
            </div>
        </div>
    )
}
