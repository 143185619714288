import React from 'react'
import {useHistory} from 'react-router-dom'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { Col } from 'react-bootstrap'
import forAdultImg from '../images/forAdultImg.png'
import forChildImg from '../images/forChildImg.png'
import './CSS/ChildOrAdult.css'

export default function ChildOrAdult() {

    const history = useHistory()

    return (
        <div style={{width:'100%'}}>
            <Col sm='12' md='12' lg='12' xl='12' >
                <div className="child-or-adult-body-content mt-5">
                    <h1>Better Care At Home</h1>
                    <hr />
                    <div className="child-or-adult-cards-container">
                        <div className="child-or-adult-card-1" onClick={()=>history.push('/child-condition')}>
                            <img src={forChildImg} className="img-fluid" />
                            <p>For Childs</p>
                        </div>
                        <div className="child-or-adult-card-2">
                            <img src={forAdultImg} className="img-fluid" />
                            <p>For Adults</p>
                        </div>
                    </div>
                </div>
            </Col>
        </div>
    )
}
