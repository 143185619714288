import React,{useState,useEffect} from 'react'
import {useSelector} from 'react-redux'
import ParentDashboardSideBar from '../../../Components/ParentDashboardSideBar'
import ParentDashboardNavbar from '../../../Components/ParentDashboardNavbar'
import MChatIntroComp from '../../../Components/MChatIntroComp'
import './CSS/ScreenerIntro.css'

export default function ScreenerIntro() {


    const [windowWidth,setWindowWidth] = useState(window.innerWidth)


    const sidebarWidth = useSelector(data => data.SidebarReducer.width)

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })
    },[])

    return (
        <div className="parent-dashboard-screener-intro">
            <ParentDashboardSideBar active="child" />
            <div style={{marginLeft:windowWidth > 1024 && sidebarWidth}}>
                <ParentDashboardNavbar active="child" />
                <div className="parent-dashboard-screener-intro-body">
                    <MChatIntroComp path="/dashboard-screener-questions" />
                </div>
            </div>
        </div>
        
    )
}
