import React,{useEffect} from 'react'
import { Row, Col } from 'react-bootstrap'
import CheckboxComp from '../../../Components/CheckboxComp'
import {
    onChangeSleepinAndEatingForm4, onChangeBehaviourForm4, onChangeSocialDevelopmentForm4, onChangeMotorSkillsForm4,
    onChangeMoodForm4, onChangeOtherForm4
} from '../../../Store/Actions/OnBoardingFormActions/Form4Actions'
import { useDispatch, useSelector } from 'react-redux'
import './CSS/PreCallQuestionForm.css'

function PreCallQuestionForm4(props) {

    const dispatch = useDispatch()

    const sleepinAndEating = useSelector(state => state.Form4Reducer.sleepinAndEating)
    const behaviour = useSelector(state => state.Form4Reducer.behaviour)
    const motorSkills = useSelector(state => state.Form4Reducer.motorSkills)
    const socialDevelopment = useSelector(state => state.Form4Reducer.socialDevelopment)
    const mood = useSelector(state => state.Form4Reducer.mood)
    const other = useSelector(state => state.Form4Reducer.other)


    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])

    return (
        <div style={{ width: '100%' }} className="pre-call-form-container">
            <h3>BEHAVIOUR CHECK LIST</h3>
            <p style={{ marginTop: 0, color: '#F99B9B' }}>Please place a check next to any of the folowing behaviours that are of concern to you.</p>

            <Row className="mx-0 p-0 mt-4">
                <Col className="pl-0">
                    <h5>Sleeping and Eating</h5>
                    <div className="mt-4">
                        {
                            sleepinAndEating.map((dt, i) => {
                                return <CheckboxComp
                                    checked={dt.isSelected}
                                    label={dt.behaviour}
                                    containerClass={i > 0 && "mt-4"}
                                    onChange={() => dispatch(
                                        onChangeSleepinAndEatingForm4({
                                            index: i, data: {
                                                behaviour: dt.behaviour,
                                                isSelected: !dt.isSelected
                                            }
                                        })
                                    )}
                                />
                            })
                        }
                    </div>

                    <h5 className="mt-5">Motor Skills</h5>
                    <div className="mt-4">
                        {
                            motorSkills.map((dt, i) => {
                                return <CheckboxComp
                                    checked={dt.isSelected}
                                    label={dt.behaviour}
                                    containerClass={i > 0 && "mt-4"}
                                    onChange={() => dispatch(
                                        onChangeMotorSkillsForm4({
                                            index: i, data: {
                                                behaviour: dt.behaviour,
                                                isSelected: !dt.isSelected
                                            }
                                        })
                                    )}
                                />
                            })
                        }
                    </div>

                    <h5 className="mt-5">Mood/Affect</h5>
                    <div className="mt-4">
                        {
                            mood.map((dt, i) => {
                                return <CheckboxComp
                                    checked={dt.isSelected}
                                    label={dt.behaviour}
                                    containerClass={i > 0 && "mt-4"}
                                    onChange={() => dispatch(
                                        onChangeMoodForm4({
                                            index: i, data: {
                                                behaviour: dt.behaviour,
                                                isSelected: !dt.isSelected
                                            }
                                        })
                                    )}
                                />
                            })
                        }
                    </div>
                </Col>

                <Col>
                    <h5>Behaviour</h5>
                    <div className="mt-4">
                        {
                            behaviour.map((dt, i) => {
                                return <CheckboxComp
                                    checked={dt.isSelected}
                                    label={dt.behaviour}
                                    containerClass={i > 0 && "mt-4"}
                                    onChange={() => dispatch(
                                        onChangeBehaviourForm4({
                                            index: i, data: {
                                                behaviour: dt.behaviour,
                                                isSelected: !dt.isSelected
                                            }
                                        })
                                    )}
                                />
                            })
                        }
                    </div>
                </Col>

                <Col>
                    <h5>Social Development</h5>
                    <div className="mt-4">
                        {
                            socialDevelopment.map((dt, i) => {
                                return <CheckboxComp
                                    checked={dt.isSelected}
                                    label={dt.behaviour}
                                    containerClass={i > 0 && "mt-4"}
                                    onChange={() => dispatch(
                                        onChangeSocialDevelopmentForm4({
                                            index: i, data: {
                                                behaviour: dt.behaviour,
                                                isSelected: !dt.isSelected
                                            }
                                        })
                                    )}
                                />
                            })
                        }
                    </div>

                    <h5 className="mt-5">Other Problems</h5>
                    <div className="mt-4">
                        {
                            other.map((dt, i) => {
                                return <CheckboxComp
                                    checked={dt.isSelected}
                                    label={dt.behaviour}
                                    containerClass={i > 0 && "mt-4"}
                                    onChange={() => dispatch(
                                        onChangeOtherForm4({
                                            index: i, data: {
                                                behaviour: dt.behaviour,
                                                isSelected: !dt.isSelected
                                            }
                                        })
                                    )}
                                />
                            })
                        }
                    </div>
                </Col>
            </Row>

            <div className="mt-5 d-flex justify-content-center">
                <button className="pre-call-form-btn" onClick={() => props.onChangeStep(5)}>Next</button>
            </div>
        </div>
    )
}

export default PreCallQuestionForm4;
