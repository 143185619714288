import React from "react";
import img from "../../assets/about/mission.png";

function Mission() {
  return (
    <div className="pt-8 lg:pt-0 gap-10 flex justify-between items-center flex-col lg:flex-row w-full bg-[#E8FCFF] mt-[100px] lg:gap-7">
      <div className="flex justify-start items-start flex-col pl-5 xl:pl-[120px] gap-4">
        <h1 className="text-lightGreen-500 font-bold text-4xl lg:text-4xl xl:text-5xl">
          Our Mission
        </h1>
        <p className="text-gray-500 font-normal text-xl max-w-[600px]">
          Our mission is to empower families to live beyond a diagnosis through
          exceptional behavioral healthcare services.
        </p>
      </div>
      <img src={img} className="object-contain self-end lg:self-auto" alt="" />
    </div>
  );
}

export default Mission;
