import React, { useRef, useEffect } from "react";
import './CSS/AgoraVideoPlayer.css'

const AgoraVideoPlayer = (props) => {
  const container = useRef(null);

  useEffect(() => {
    if (!container.current) return;
    props.videoTrack?.play(container.current);
    return () => {
      props.videoTrack?.stop();
    };
  }, [container, props.videoTrack]);
  
  useEffect(() => {
    props.audioTrack?.play();
    return () => {
      props.audioTrack?.stop();
    };
  }, [props.audioTrack]);

  return (
    <div ref={container} key={props.key} className="agora-video-player" style={props.style}>{props.children}</div>
  );
}

export default AgoraVideoPlayer;