import React,{useState,useEffect} from 'react'
import {Row,Col,Modal,Spinner} from 'react-bootstrap'
import {GoPlus} from 'react-icons/go'
import ClinicianDashboardNavbar from '../../Components/ClinicianDashboardNavbar'
import Skeleton from 'react-loading-skeleton';
import closeIcon from '../../images/closeIcon.svg'
import pdfPreviewImage from '../../images/pdfPreviewImage.png'
import SearchableDropdown from '../../Components/SearchableDropdown'
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import UploadComponent from '../../Components/UploadComponent'
import ReportsCard from '../../Components/ReportsCard'
import {useSelector,useDispatch} from 'react-redux'
import './CSS/DiagnosisReports.css'
import { Link } from 'react-router-dom'
import { baseURL,Decrypt,Encrypt,headers } from '../../Config'
import { Required } from '../../Validations'
import { toast } from 'react-toastify'

export default function DiagnosisReports() {
    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const [show,setShow] = useState(false)
    const [options,setOptions] = useState([])
    const [reports,setReports] = useState([])
    const [uploadedFile,setUploadedFile] = useState([])
    const [selectedOption,setSelectedOption] = useState('')
    const [isLoading,setIsLoading] = useState(false)
    const [isLoaded,setIsLoaded] = useState(false)
    const [userType,setUserType] = useState(null)
    const sidebarWidth = useSelector(data => data.SidebarReducer.width)

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })

        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            setUserType(Decrypt(dt.userType))

            const path = Decrypt(dt.userType) === 'BC' ? `/api/getReportsByClinician?Id=${dt.uid}` :Decrypt(dt.userType) === 'BCBA' ? `/api/getReportsByBCBA?Id=${dt.uid}` : null

            fetch(baseURL+path,{
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Success'){
                    setReports(res2.message)
                    setIsLoaded(true)
                }
                else{
                    setReports([])
                    setIsLoaded(true)
                }
            })
        }
    })


    const handleCreateReport = () =>{
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        setShow(true)
        fetch(baseURL+'/api/get-clinician-assigned-childs',{
            method:'Post',
            body:JSON.stringify({uid:dt.uid}),
            headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
        })
        .then(res=>res.json())
        .then(res2=>{
            if(res2.code === 'Success'){
                const newArr = res2.message?.map(dt=>{
                    return {"id":dt.uid,"name":Decrypt(dt.child_name)}
                }).filter(dt=> dt !== undefined)
                setOptions(newArr)
            }
        })
    }

    const handleRemoveFile = (file) =>{
        const newArr = uploadedFile.filter(dt=>dt !== file)
        setUploadedFile(newArr)
    }

    const handleAddReport = () =>{
        const id = selectedOption[0]?.id || ''
        if(Required('Child',id)){
            if(uploadedFile.length > 0){
                const dt = JSON.parse(localStorage.getItem('bx_user_token'))
                setIsLoading(true)
                fetch(baseURL+'/api/addReport',{
                    method:'Post',
                    body:JSON.stringify({id,files:uploadedFile}),
                    headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
                })
                .then(res=>res.json())
                .then(res2=>{
                    if(res2.code === 'Success'){
                        setIsLoading(false)
                        setSelectedOption('')
                        setUploadedFile([])
                        setShow(false)
                        toast.success(res2.message,{toastId:'child-report-upload-succ',autoClose:3000})
                    }
                    else{
                        setIsLoading(false)
                    }
                })
            }
            else{
                toast.error('Please upload atleast one file',{toastId:'child-report-upload-file-err',autoClose:3000})
            }
        }
    }

    return (
        <div className="diagnosis-reports-page-container">
            <ParentDashboardSideBar active="home" user={userType&&userType === 'BC' ? "BC" :userType === 'RBT' ? 'RBT' : 'BCBA'} />
            <div style={{marginLeft:windowWidth > 1024 &&sidebarWidth}}>
                <ClinicianDashboardNavbar 
                    active="home" 
                    user={userType&&userType === 'BC' ? "BC" :userType === 'RBT' ? 'RBT' : 'BCBA'}
                    showAppointments={userType === 'BCBA' ? false : true}
                />
                <div className="diagnosis-reports-page-body">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <h2 style={{fontFamily:'CocoSharp bold'}}>Clinician's Diagnosis Report</h2>
                        {
                            userType&&userType === 'BC' &&
                            <Link to="#">
                                <button onClick={handleCreateReport} className="diagnosis-reports-create-report-btn">Create Report <GoPlus/></button>
                            </Link>
                        }
                    </div>
                    <Row className="mx-0">
                        <Col sm={12}className="d-flex align-items-center flex-wrap px-0 mt-2">
                            {
                                isLoaded ?
                                    reports&&reports.length > 0 ?
                                        reports.map(dt=>{
                                            return(
                                                <ReportsCard 
                                                    childName={Decrypt(dt.child_name)}
                                                    parentName={Decrypt(dt.parent_name)}
                                                    report={dt.report}
                                                />
                                            )
                                        })
                                    : null
                                : 
                                    [{},{},{},{},{},{},{},{}].map(dt=>{
                                        return <Skeleton width={220} height={215} style={{marginTop:25,borderRadius:15,marginRight:25}} />
                                    })
                            }
                        </Col>
                    </Row>
                </div>
            </div>
            
            {/* Create Report */}
            <Modal className="diagnosis-reports-page-create-modal" show={show} centered onHide={()=>setShow(false)} animation={true}>
                <Modal.Header closeButton>
                    Upload Reports
                </Modal.Header>
                <Modal.Body style={{position:'relative',zIndex:1,borderRadius:20}} className="py-2 pb-4">
                    <div style={{marginBottom:30,marginTop:30}}>  
                        <SearchableDropdown 
                            options={options}
                            setSelectedOption={(val)=>setSelectedOption(val)}
                        />
                    </div>
                    <div style={{display:'flex',flexWrap:'wrap'}}>
                        {
                            uploadedFile.map(dt=>{
                                return(
                                    <div style={{
                                            width:120,
                                            height:120,
                                            padding:5,
                                            display:'flex',
                                            alignItems:'flex-end',
                                            borderRadius:10,
                                            border: 'solid 2px #030533',
                                            position:'relative',
                                            marginRight:10,
                                            marginTop:10
                                        }}
                                    >
                                        <div style={{backgroundColor:'white',width:20,position:'absolute',right:-12,top:-12,borderRadius:10}}>
                                            <img style={{width:15,cursor:'pointer'}} src={closeIcon} onClick={()=>handleRemoveFile(dt)} />
                                        </div>
                                        <div>
                                            <img src={pdfPreviewImage} style={{height:100}} className="img-fluid" />
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <UploadComponent  
                            isMultiple={false}
                            getUploadedFile={(file)=>setUploadedFile(uploadedFile.concat(file))}
                        />
                    </div>
                    
                    {
                        isLoading ? 
                            <button id="diagnosis-reports-page-modal-btn" style={{marginTop:40}}>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                {' '}
                                Loading....
                            </button>
                        :
                            <button id="diagnosis-reports-page-modal-btn" onClick={handleAddReport}  style={{marginTop:40}}>Submit</button>

                    }
                </Modal.Body>
            </Modal>
        </div>
    )
}
