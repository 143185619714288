import React,{useState} from "react";
import {useHistory} from "react-router-dom";
import Select from "react-dropdown-select";
import Input from "../../Components/InputComp2";
import languages from './languages'
import {Required} from "../../Validations";
import { baseURL, headers } from "../../Config";
import { toast } from "react-toastify";

export function Label({ label, para, para2, select, value, onChange, options }) {
  return (
    <div className="w-full flex flex-col justify-start items-start gap-2 mt-4">
      <label
        htmlFor="#"
        className="font-bold text-lightGreen-500 text-xl sm:text-[24px] max-w-[580px]"
      >
        {label}
      </label>
      {para2 && (
        <>
          <p className="font-normal text-lightGreen-500 mt-2 text-base sm:text-[20px]">
            {para2}
          </p>
          {/* <p className="font-normal text-lightGreen-500 text-bases sm:text-[20px] mb-2">
            {para2}
          </p> */}
        </>
      )}
      {select ? (
        <div className="relative w-full max-w-[580px]">
          <Select
            className="dropdown max-w-[580px] h-[54px] border-2 border-solid border-lightGreen-500 rounded-[10px] text-[#030533] font-['CocoSharp regular']"
            options={options}
            onChange={onChange}
            searchable={true}
          />
        </div>
      ) : (
        <Input
          value={value}
          className="max-w-[580px] h-[54px] pt-1"
          inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
          onChange={onChange}
        />
      )}
    </div>
  );
}

function ReferalForm() {

  const history = useHistory()

  const [clinicName, setClinicName] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [providerEmail, setProviderEmail] = useState("");
  const [providerPhoneNumber, setProviderPhoneNumber] = useState("");
  const [providerZipCode, setProviderZipCode] = useState("");
  const [parentName, setParentName] = useState("");
  const [parentZipCode, setParentZipCode] = useState("");
  const [parentPhone, setParentPhone] = useState("");
  const [parentEmail, setParentEmail] = useState("");
  const [language, setLanguage] = useState("");
  const [childName, setChildName] = useState("");
  const [requestingAppointmentFor, setRequestingAppointmentFor] = useState("");
  const [autismDiagnosis, setAutismDiagnosis] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [concerns, setConcerns] = useState("");
  const [hipaaComplaint, setHipaaComplaint] = useState("");
  const [insuranceProvider, setInsuranceProvider] = useState("");

  const [sendResponse, setSendResponse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = () => {

    if(
          Required("Origanization or Clinic Name", clinicName) && Required("Contact Person", contactPerson) && Required("Provider Email", providerEmail) &&
          Required("Provider phone Number", providerPhoneNumber) && Required("Provider Postal Code", providerZipCode) && Required("Parent Name", parentName) &&
          Required("Parent Zip Code", parentZipCode) && Required("Parent Phone", parentPhone) && Required("Parent Email", parentEmail) &&
          Required("Language", language) && Required("Child Name", childName) && Required("Requesting Appointment For", requestingAppointmentFor) &&
          Required("Autism Diagnosis", autismDiagnosis) && Required("Date of Birth", dateOfBirth) && Required("Concerns", concerns) &&
          Required("Insurance Card", hipaaComplaint) && Required("Insurance Provider" , insuranceProvider)
      ) {

        const obj = {
          name_of_organization:clinicName,
          contact:contactPerson,
          email:providerEmail,
          number:providerPhoneNumber,
          zip_code:providerZipCode,
          parent_or_guardian_name:parentName,
          parent_zip_code:parentZipCode,
          phone:parentPhone,
          parent_email:parentEmail,
          language_preference:language,
          child_name:childName,
          appointment_for:requestingAppointmentFor,
          have_an_autism:autismDiagnosis,
          date_of_birth:dateOfBirth,
          concerns:concerns,
          insurance_card:hipaaComplaint,
          insurance_provider:insuranceProvider,
          send_responses:sendResponse
        }

        setIsLoading(true)
        fetch(baseURL + '/api/submitReferralForm',{
          method:'Post',
          body:JSON.stringify(obj),
          headers:headers
        })
        .then(res=>res.json())
        .then(res2=>{
          if(res2.code === 'Success'){
            setIsLoading(false)
            toast.error(res2?.message)

            setClinicName('')
            setContactPerson('')
            setProviderEmail('')
            setProviderPhoneNumber('')
            setProviderZipCode('')
            setParentName('')
            setParentZipCode('')
            setParentPhone('')
            setParentEmail('')
            setLanguage('')
            setChildName('')
            setRequestingAppointmentFor('')
            setAutismDiagnosis('')
            setDateOfBirth('')
            setConcerns('')
            setHipaaComplaint('')
            setInsuranceProvider('')
            setSendResponse(false)
            setIsLoading(false)
            history.goBack()
          }
          else{
            setIsLoading(false)
            toast.error(res2?.message)
          }
        })
    }
  };

  return (
    <div className="flex flex-col md:grid grid-cols-[0.4fr_0.6fr] h-screen">
       <div className="flex justify-center md:items-start w-full bg-lightGreen-100 md:pl-[30px] h-auto xl:pl-[100px] py-8 md:py-0 items-center pl-0 flex-col">
        <h1 className="font-bold text-lightGreen-500 text-[64px] xl:ml-[100px] md:ml-[30px]">Cognitia</h1>
        <p className="font-normal text-lightGreen-500 text-2xl xl:ml-[100px] md:ml-[30px]">
          Referral Form
        </p>
      </div>
      <div class="flex-1 flex overflow-hidden">
        <div className="flex-1 overflow-y-scroll flex justify-start items-start flex-col gap-5 pt-4 mt-10 md:mt-24 px-4 sm:px-5 pb-5 border-t-[1px] border-[#0B0F66] border-solid">
          <h2 className="font-normal text-lightGreen-500 text-[32px]">
            Provider Form
          </h2>
          <h3 className="font-bold text-lightGreen-500 text-[24px]">
            Name of Organization or Clinic*
          </h3>
          <div className="w-full flex flex-col justify-start items-start gap-2">
            <label
              htmlFor="#"
              className="font-normal text-lightGreen-500 text-[20px]"
            >
              What organization is referring you to Cognitia?
            </label>
            <Input
              value={clinicName}
              onChange={(e)=>setClinicName(e.target.value)}
              className="max-w-[580px] h-[54px] pt-1"
              inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
            />
          </div>
          <Label 
            label={"Contact Person*"}
            value={contactPerson}
            onChange={(e)=>setContactPerson(e.target.value)}
          />
          <Label
            label={"Provider Email*"}
            para={"Parent email if parent completing,"}
            para2={"Provider email if provider completing"}
            value={providerEmail}
            onChange={(e)=>setProviderEmail(e.target.value)}
          />
          <Label 
            label={"Provider Phone Number*"}
            value={providerPhoneNumber}
            onChange={(e)=>setProviderPhoneNumber(e.target.value)}
          />
          <Label 
            label={"Provider Postal Code*"}
            value={providerZipCode}
            onChange={(e)=>setProviderZipCode(e.target.value)}
          />
          <h2 className=" sm:pl-7 w-full font-normal text-lightGreen-500 text-[32px] my-3 py-3 border-y-[1px] border-[#0B0F66] border-solid">
            Parent Form
          </h2>
          <Label 
            label={"Parent or Guardian Name(s):*"}
            value={parentName}
            onChange={(e)=>setParentName(e.target.value)}
          />
          <Label 
            label={"Parent Postal Code*"}
            value={parentZipCode}
            onChange={(e)=>setParentZipCode(e.target.value)}
          />
          <Label 
            label={"Phone*"}
            value={parentPhone}
            onChange={(e)=>setParentPhone(e.target.value)}
          />
          <Label 
            label={"Email*"}
            value={parentEmail}
            onChange={(e)=>setParentEmail(e.target.value)}
          />
          <Label 
            label={"Language Preference*"} 
            select
            options={languages}
            onChange={(e)=>{
              let v = e[0].value;
              setLanguage(v);
            }}
          />
          <h2 className="sm:pl-7 w-full font-normal text-lightGreen-500 text-[32px] my-3 py-3 border-y-[1px] border-[#0B0F66] border-solid">
            Child Information
          </h2>
          <Label 
            label={"Child Name*"}
            value={childName}
            onChange={(e)=>setChildName(e.target.value)}
          />
          <Label 
            label={"Requesting appointment for:*"}
            select
            options={[
              {
                label: "Consult",
                value: "Consult",
              },
              {
                label: "Treatment",
                value: "Treatment",
              },
            ]}
            onChange={(e)=>{
              let v = e[0].value;
              setRequestingAppointmentFor(v);
            }}
          />
          <Label 
            label={"Does the child have an autism diagnosis?"}
            select
            options={[
              {
                label: "Yes",
                value: "Yes",
              },
              {
                label: "No",
                value: "No",
              },
            ]}
            onChange={(e)=>{
              let v = e[0].value;
              setAutismDiagnosis(v);
            }}
          />
          <Label 
            label={"Date of Birth*"}
            value={dateOfBirth}
            onChange={(e)=>setDateOfBirth(e.target.value)}
          />
          <Label 
            label={"Concerns"}
            value={concerns}
            onChange={(e)=>setConcerns(e.target.value)}
          />
          <Label
            label={
              "Care Card AND Prescription and Diagnostics Evaluation Upload"
            }
            para={
              ""
            }
            para2={"This upload is HIPAA complaint."}
            value={hipaaComplaint}
            onChange={(e)=>setHipaaComplaint(e.target.value)}
          />
          <Label 
            label={"Insurance Provider"}
            value={insuranceProvider}
            onChange={(e)=>setInsuranceProvider(e.target.value)}
          />
          <div className="sm:pl-7  my-3 py-3 border-y-[1px] border-[#0B0F66] border-solid w-full">
            <h2 className=" font-normal text-lightGreen-500 text-[32px] ">
              Thank you!
            </h2>
            <p className="font-normal text-lightGreen-500 text-[16px] mb-0">
              Our team of experts will be in touch shortly.
            </p>
          </div>
          {/*   */}
          <div className="sm:self-auto self-center">
            {
              isLoading ?
                <button
                  className={`bg-lightPink-100 rounded-[10px] h-12  w-[136px] text-sm no-underline flex justify-center items-center font-semibold text-white hover:bg-pink-700 hover:scale-95 transition-all`}
                >
                  Submitting...
                </button>
              :
                <button 
                  onClick={handleFormSubmit}
                  className={`bg-lightPink-100 rounded-[10px] h-12  w-[136px] text-sm no-underline flex justify-center items-center font-semibold text-white hover:bg-pink-700 hover:scale-95 transition-all`}
                >
                  Submit
                </button>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReferalForm;
