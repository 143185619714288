import React from 'react';
import Avatar from 'react-avatar';
import { usePubNub } from 'pubnub-react';
import {BiCloudDownload} from 'react-icons/bi'
import {AiFillFile} from 'react-icons/ai'
import './CSS/FirstUserBubble.css'

export default function FirstUserBubble({message,senderName,senderImage,messageType,date,channel}) {

  const pubnub = usePubNub()
  let time = date && new Date(date).toLocaleTimeString()
  let time1 = time?.split(':')[0] + ':' + time?.split(':')[1] + ' ' + time?.split(':')[2].split(' ')[1]

  return (
      <div className='first-user-bubble-container'>
        {
          messageType === 0 ?
            <>
              <p style={{fontSize:12}}>{senderName}</p>
              <div className='first-user-bubule'>
                <div style={{padding:10,backgroundColor:'#F6F4FF',marginRight:10,borderRadius:'8px 0px 8px 8px',minWidth:80,maxWidth:300}}>
                    <p style={{margin:0,textAlign:'left'}} >{message?.message?.payload}</p>
                    <p style={{margin:0,textAlign:'left',fontSize:10,marginTop:5}} >{time1 || '00'}</p>
                </div>
                <Avatar src={senderImage} name={senderName} round size='45px' />
              </div>
            </>
          : messageType === 1 ?
            <>
                <p style={{fontSize:12}}>{senderName}</p>
                <div className='first-user-bubule'>
                  <div style={{padding:10,backgroundColor:'#F6F4FF',marginRight:10,borderRadius:'8px 0px 8px 8px',width:200,height:200}}>
                    <img src={pubnub.getFileUrl({id:message?.file?.id,name:message?.file?.name,channel:channel})} style={{width:'180px',height:'180px'}} />
                  </div>
                  <Avatar src={senderImage} name={senderName} round size='45px' />
                </div>
            </>
          : messageType === 2 ?
            <>
                <p style={{fontSize:12}}>{senderName}</p>
                <div className='first-user-bubule'>
                  <div style={{width:200,height:80,padding:10,backgroundColor:'#F6F4FF',marginRight:10,borderRadius:'8px 0px 8px 8px',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                      <div>
                        <AiFillFile size={30} color="var(--secondary-color)" />
                        <p style={{margin:0}}>{message?.file?.name?.length > 15 ? message?.file?.name?.slice(0,15) + '..' : message?.file?.name}</p>
                      </div>
                      <a href={pubnub.getFileUrl({id:message?.file?.id,name:message?.file?.name,channel:channel})} target="_blank" download="document" style={{cursor:'pointer'}}>
                          <BiCloudDownload size={30} color="var(--secondary-color)" />
                      </a>
                  </div>
                  <Avatar src={senderImage} name={senderName} round size='45px' />
                </div>
            </>
          : messageType === 3 ?
            <>
                <p style={{fontSize:12}}>{senderName}</p>
                <div className='first-user-bubule'>
                  <div style={{width:280,height:80,padding:10,backgroundColor:'#F6F4FF',marginRight:10,borderRadius:'8px 0px 8px 8px',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                      <audio controls controlsList="nodownload" style={{height:60}}>
                          <source src={pubnub.getFileUrl({id:message?.file?.id,name:message?.file?.name,channel:channel})} type='audio/mp3' />
                      </audio>
                  </div>
                  <Avatar src={senderImage} name={senderName} round size='45px' />
                </div>
            </>
          :null
        }
      </div>
  )
}
