import React,{useState,useEffect} from "react";
import PinkBtn from "../../Components/PinkBtn";
import HomeCard from "../../Components/HomeCard";

export default function ProfessioanlDev() {
  const tiles = [
    {
      title: "Training",
      desc: "We will provide you with comprehensive training to get you started.",
      url: "./Icons/Train.png",
      color: "blue",
    },
    {
      title: "Health Insurance",
      desc: "We provide medical, dental and vision insurance for full time staff.",
      url: "./Icons/HI.png",
      color: "pink",
    },
    {
      title: "Certification Support",
      desc: "We provide you with full support for your certifications (Behavior Intervener', BCBA, etc.).",
      url: "./Icons/CS.png",
      color: "blue",
    },

    {
      title: "BCBA Supervision & CEU",
      desc: "We provide BCBA Supervision & Continuing Education.",
      url: "./Icons/BCBA.png",
      color: "pink",
    },

    {
      title: "Stipends",
      desc: "We provide stipends to cover partial costs of your professional development & research.",
      url: "./Icons/Stipend.png",
      color: "blue",
    },

    {
      title: "Technology",
      desc: "We provide tablets to all our staff.",
      url: "./Icons/Tech.png",
      color: "pink",
    },
  ];

  const [scrollHeight,setScrollHeight] = useState(0)
  
  useEffect(()=>{
    setScrollHeight(document.body.scrollHeight - 400)
  },[])


  return (
    <>
      <div className="grid grid-cols-1">
        <p className="text-[#096E82] font-bold text-lightGreen-500 font-bold text-4xl lg:text-4xl xl:text-5xl px-[20px] py-[20px]  text-center md:px-[200px] md:py-[100px] ">
          Your professional development is one of our top priorities, and we
          want to help you accomplish your career goals while enjoying the work
          you do
        </p>
      </div>
      <div className="grid  grid-cols-1 px-[10px] md:grid-cols-3 gap-6 md:px-[180px]">
        {tiles.map((v, i) => (
          <HomeCard
            key={i}
            src={v.url}
            color={v.color}
            title={v.title}
            desc={v.desc}
          />
        ))}
      </div>
      <div className="mt-8 flex justify-center">
        <PinkBtn onClick={()=>window.scrollTo(0,scrollHeight)} text={"View Positions"} url={'Career'} />
      </div>
    </>
  );
}
