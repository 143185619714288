import React from 'react'
import "./CSS/ProceduresIntro.css"

export default function ProceduresIntro({therapistName,setTheraName,childName,setChildName,parentName,setParentName,phone,setPhone,Dob,setDob,Address,setAddress}) {
    return (
        <div className="procedures-intro-container">
            <p className="procedures-intro-title">Introduction</p>
            <p className="procedures-intro-detail">
                “Hi, I’m <input value={therapistName} type="text" className="procedures-intro-therapist-name-input" onChange={(e)=>setTheraName(e.target.value)} />. Before we get started, can I get your full name, your child’s full name & DOB, your
                current location (address), and a phone number to reach you if we get disconnected or if there is an
                emergency?”
            </p>

            <div className="procedures-intro-form-container" >
                <div className="procedures-intro-form">
                    Name: <input value={parentName} type="text" className="procedures-intro-form-fld" onChange={(e)=>setParentName(e.target.value)} />
                </div>
                <div className="procedures-intro-form">
                    Phone: <input value={phone} type="text" className="procedures-intro-form-fld" onChange={(e)=>setPhone(e.target.value)} />
                </div>
                <div className="procedures-intro-form">
                    Child Name: <input value={childName} type="text" className="procedures-intro-form-fld" readOnly onChange={(e)=>setChildName(e.target.value)} />
                </div>
                <div className="procedures-intro-form">
                    DOB: <input value={Dob} type="text" className="procedures-intro-form-fld" readOnly onChange={(e)=>setDob(e.target.value)} />
                </div>
                {/* <div className="procedures-intro-form">
                    Address: <input value={Address} type="text" className="procedures-intro-form-fld" onChange={(e)=>setAddress(e.target.value)} />
                </div> */}
            </div>

            <p className="procedures-intro-detail">
                “I am going to ask you to play with your child with toys and objects that you have in your home. 
                The materials we will need are: – 3-5 toys for free-play – Clear container with lid that has a snack 
                or other desired object in it (e.g., stickers) I may ask you if is looking at you during some of the tasks.
                Please let me know if you have any questions along the way.”
            </p>
        </div>
    )
}
