import React,{useState} from "react";
import { baseURL, headers } from "../../Config";
import { Required } from "../../Validations";
import {toast} from 'react-toastify'

function Input({ label, textArea, value, onChange }) {
  return (
    <div className="w-full relative">
      <label className="p-1 -top-[15px] bg-white left-5 absolute text-lightGreen-500 text-base font-bold">
        {label}
      </label>
      {textArea ? (
        <textArea
          value={value}
          onChange={onChange}
          className="border-2 border-solid  border-lightGreen-500 rounded-[11px] resize-none bg-white w-full  h-[120px] p-2 pl-4"
        ></textArea>
      ) : (
        <input
          type="text"
          value={value}
          onChange={onChange}
          className="border-2 border-solid  border-lightGreen-500 rounded-[11px] bg-white w-full  h-[60px] p-2 pl-4"
        />
      )}
    </div>
  );
}

function Form() {

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [city, setCity] = useState("");
  const [childAge, setChildAge] = useState("");
  const [briefBackground, setBriefBackground] = useState("");
  const [isLoading,setIsLoading] = useState(false)

  const handleFormSubmit = () =>{
    if(Required('First Name' , firstName) && Required('Last Name', lastName) && Required('Email Address' , emailAddress) && Required('Phone No.' , phoneNumber)){
        
        const obj = {
          first_name : firstName,
          last_name : lastName,
          email : emailAddress,
          number: phoneNumber,
          city:city,
          child_age:childAge,
          short_des:briefBackground
        }

        setIsLoading(true)
        fetch(baseURL + '/api/submitQuery',{
          method:'Post',
          body:JSON.stringify(obj),
          headers:headers
        })
        .then(res=>res.json())
        .then(res2=>{
          toast.success(res2?.message,{toastId:'career-suc-toast'})
          setIsLoading(false)
          setFirstName('')
          setLastName('')
          setEmailAddress('')
          setPhoneNumber('')
          setCity('')
          setChildAge('')
          setBriefBackground('')
        })
        .catch(err=>{
          setIsLoading(false)
          toast.error(err?.message,{toastId:'career-err-toast'})
        })
    }
  }

  return (
    <div className="w-full flex items-center justify-center px-5">
      <div className="w-full justify-center items-center flex flex-col mt-24 gap-10 max-w-[1300px]">
        <h1 className="text-lightGreen-500 leading-9 sm:leading-[50px] font-bold text-[30px] sm:text-[48px]  ">
          Let's Talk
        </h1>
        <div className="w-full sm:flex-row flex-col justify-center items-center flex gap-6">
          <Input
            label={"First Name"}
            value={firstName}
            onChange={(e)=>setFirstName(e.target.value)}
          />
          <Input 
            label={"Last Name"}
            value={lastName}
            onChange={(e)=>setLastName(e.target.value)}
          />
        </div>
        <div className="w-full sm:flex-row flex-col justify-center items-center flex gap-6">
          <Input 
            label={"Email Address"}
            value={emailAddress}
            onChange={(e)=>setEmailAddress(e.target.value)}
          />
          <Input 
            label={"Phone No."}
            value={phoneNumber}
            onChange={(e)=>setPhoneNumber(e.target.value)}
          />
        </div>
        <div className="w-full sm:flex-row flex-col justify-center items-center flex gap-6">
          <Input 
            label={"City"}
            value={city}
            onChange={(e)=>setCity(e.target.value)}
          />
          <Input 
            label={"Age of Child"}
            value={childAge}
            onChange={(e)=>setChildAge(e.target.value)} 
          />
        </div>
        <Input 
          label={"Message"} 
          textArea 
          value={briefBackground}
          onChange={(e)=>setBriefBackground(e.target.value)} 
        />

        <div className="mt-6 w-fit mx-auto">
          {
            isLoading ?
              <button
                className={`bg-lightPink-100 rounded-[10px] h-12  w-[136px] text-sm no-underline flex justify-center items-center font-semibold text-white hover:bg-pink-700 hover:scale-95 transition-all`}
              >
                Submitting..
              </button>
            :
              <button 
                onClick={handleFormSubmit}
                className={`bg-lightPink-100 rounded-[10px] h-12  w-[136px] text-sm no-underline flex justify-center items-center font-semibold text-white hover:bg-pink-700 hover:scale-95 transition-all`}
              >
                Submit
              </button>
          }
        </div>
      </div>
    </div>
  );
}

export default Form;
