import React,{useState,useEffect} from 'react'
import {useSelector,useDispatch} from 'react-redux'
import {baseURL, Decrypt, Encrypt, headers} from '../../Config'
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import ParentDashboardNavbar from '../../Components/ParentDashboardNavbar'
import ClinicianDashboardNavbar from '../../Components/ClinicianDashboardNavbar'
import { toast } from 'react-toastify'
import {useHistory} from 'react-router-dom'
import './CSS/SelectChild.css'


export default function SelectChild() {

    const history = useHistory()
    const dispatch = useDispatch()

    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const [childs,setChilds] = useState([])
    const [isLoaded,setIsLoaded] = useState(false)
    const [userType,setUserType] = useState(null)

    const sidebarWidth = useSelector(data => data.SidebarReducer.width)

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })

        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            setUserType(Decrypt(dt.userType))
            const path = Decrypt(dt.userType) === 'BC' ? '/api/get-clinician-assigned-childs' :Decrypt(dt.userType) === 'BCBA' ? '/api/get-bcba-assigned-childs-for-reports' :Decrypt(dt.userType) === 'Parent' ? '/api/get-parent-childs' :Decrypt(dt.userType) === 'RBT' ? '/api/get-rbt-assigned-childs-for-reports' : null
            fetch(baseURL+ path,{
                method:'Post',
                body:JSON.stringify({uid:dt.uid}),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    toast.error('error..')
                    setIsLoaded(false)
                }
                else{
                    setChilds(res2.message)
                    setIsLoaded(false)
                }
            })   
        }
    },[])

    return (
        <div className="dashboard-select-child-container">
            <ParentDashboardSideBar active={userType&&userType === 'Parent' ? 'sessions' : "reports"} user={userType&&userType === 'BC' ? "BC" :userType&&userType === 'BCBA' ? 'BCBA' :userType&&userType === 'RBT' ? 'RBT' : 'Parent'} />
            <div style={{marginLeft:windowWidth > 1024 && sidebarWidth}}>
                {
                    userType&&userType === 'Parent' ?
                        <ParentDashboardNavbar active='sessions' user='Parent' />
                    :
                        <ClinicianDashboardNavbar 
                            active="reports" 
                            user={userType&&userType === 'BC' ? "BC" :userType&&userType === 'RBT' ? "RBT" : 'BCBA'}
                            showAppointments={(userType === 'BCBA' || userType === 'RBT') ? false : true}
                        />
                        
                }
                <div className="dashboard-select-child-body">
                    <div style={{width:'100%',marginTop:0}}>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems:'flex-end'}}>
                            <h2 style={{fontFamily:'CocoSharp bold'}} className="mb-0">Child/Children</h2>
                        </div>
                        <div style={{marginTop:30}}>
                            {
                                childs&&childs.map((dt,i)=>{
                                    return(
                                        <React.Fragment key={i}>
                                            <div className='child-card-container'>
                                                <div>
                                                    <p style={{fontFamily:'CocoSharp bold',color:'#096E82',fontSize:22,margin:0}}>{Decrypt(dt.child_name)}</p>
                                                    <p style={{fontFamily:'CocoSharp regular',color:'#F99B9B',fontSize:16,margin:0}}><b>Parent:</b> {Decrypt(dt.parentName)}</p>
                                                </div>

                                                <button onClick={()=>history.push(userType&&userType === 'Parent' ? `/child-sessions/${dt.uid}` : `/report-generation/${dt.uid}`)} className="child-card-select-btn">Select</button>
                                            </div>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}