import React,{useState} from "react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PinkBtn from "../../Components/PinkBtn";

function Faq() {
  const faqs = [
    {
      question: "Personalized Care",
      answer:
        "Every child with autism is unique. We personalize treatment services, so your child receives effective and targeted therapy.",
    },
    {
      question: "Scientific & Research Validated",
      answer:"Our programs utilize the scientific principles of Applied Behavior Analysis (ABA), the science of learning, to improve the lives of children with autism.",
    },
    {
      question: "Technology & Data Driven",
      answer:
        "We use interactive, intelligent data collection and analytics tool that allows us to track your child’s progress through therapy.",
    },
    {
      question: "Measurement Based",
      answer:
        "We use systematic and continuing assessment of your child’s outcomes over the entire course of clinical care to deliver personalised and effective interventions.",
    },
    {
      question: "Continuity of Care",
      answer:
        "We understand autism is a lifelong condition. We help your child as they grow. We are with them to address their multidimensional needs.",
    },
    {
      question: "Outstanding Care Team",
      answer:
        "Our clinicians are highly trained and provide evidence-based treatment to increase your child’s skill acquisition in a highly motivating and enjoyable treatment environment.",
    },
  ];

  const [faqActive, setFaqActive] = useState(Array(faqs.length).fill(false));

  return (
    <div className="w-full flex justify-center pt-[70px] px-5">
      <div className="w-full max-w-[1440px] flex flex-col justify-center items-center gap-11">
        <h1 className="text-center text-lightGreen-500 leading-7 sm:leading-9 md:leading-[50px] font-bold sm:text-[30px] md:text-[48px] text-[24px]">
          Leading The Way To Better <br /> Access and Care For Autism
        </h1>
        <div className="flex justify-center items-center gap-4 flex-col">
          {faqs.map((elem, idx) => {
            return (
              <div
                key={"faq-her" + idx}
                className={`transition-all max-w-[960px] w-full border-[1px] border-solid border-lightGreen-600  rounded-[10px] flex flex-col items-start overflow-hidden ${
                  faqActive[idx] && "bg-lightGreen-600"
                }`}
              >
                <div
                  className={`flex justify-between w-full items-center cursor-pointer p-4`}
                  onClick={() => {
                    setFaqActive((prev) => {
                      let copy = Array(faqs.length).fill(false);
                      copy[idx] = !prev[idx];
                      return copy;
                    });
                  }}
                >
                  <h2
                    className={`transition-all ${
                      faqActive[idx] ? "text-white" : "text-lightGreen-600"
                    } font-bold text-lg sm:text-2xl`}
                  >
                    {elem.question}
                  </h2>
                  <FontAwesomeIcon
                    className={`transition-all text-[20px] ${
                      faqActive[idx]
                        ? "text-white rotate-180"
                        : "text-lightGreen-600"
                    }`}
                    icon={faChevronDown}
                  />
                </div>
                <div
                  className={`text-white text-lg transition-all pointer-events-none px-4  ${
                    faqActive[idx] ? "max-h-screen pb-4" : "max-h-0"
                  } `}
                >
                  <p>{elem.answer}</p>
                </div>
              </div>
            )
          })}
        </div>

        <PinkBtn text={"Get Started"} url={"signup"} />
      </div>
    </div>
  );
}

export default Faq;
