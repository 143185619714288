import React,{useEffect,useState} from 'react'
import {baseURL, Decrypt, Encrypt, headers} from '../../Config'
import {useSelector,useDispatch} from 'react-redux'
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import ClinicianDashboardNavbar from '../../Components/ClinicianDashboardNavbar'
import InputComp from '../../Components/InputComp'
import InputPassComp from '../../Components/InputPassComp'
import TextAreaComp from '../../Components/TextAreaComp'
import PhoneNumberComp from '../../Components/PhoneNumberComp'
import SelectCountryComp from '../../Components/SelectCountryComp'
import {RiCloseLine} from 'react-icons/ri'
import {BsTrash} from 'react-icons/bs'
import Swal from 'sweetalert2'
import './CSS/ClinicianEditProfile.css'
import { Row,Col,Spinner,Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { OnlyAlphabets, PasswordValidation, Required } from '../../Validations'
import { GetRBTData, UpdateRBTProfile } from '../../Store/Actions/RBTActions'
import { GetBCBAData, UpdateBCBAProfile } from '../../Store/Actions/BCBAActions'
import { GetClinicianData, UpdateClinicianProfile } from '../../Store/Actions/ClinicianActions'

export default function ClinicianEditProfile() {

    const dispatch = useDispatch()
    const parentImage = useSelector(data => data.ParentReducer.parentData.image)

    const [windowWidth,setWindowWidth] = useState(window.innerWidth)

    const [show,setShow] = useState(false)

    const [userName,setUserName] = useState('')

    const [firstName,setFirstName] = useState('')
    const [firstNameErr,setFirstNameErr] = useState(false)

    const [lastName,setLastName] = useState('')
    const [lastNameErr,setLastNameErr] = useState(false)

    const [email,setEmail] = useState('')
    const [number,setNumber] = useState('')
    const [numberErr,setNumberErr] = useState('')

    const [nationality,setNationality] = useState('')
    const [image,setImage] = useState(parentImage)
    const [description,setDescription] = useState('')
    const [imageUploading,setImageUploading] = useState(false)

    const [currentPassword,setCurrentPassword] = useState('')
    const [newPass,setNewPass] = useState('')
    const [newPassErr,setNewPassErr] = useState('')

    const [experiences,setExperiences] = useState([])

    const [hospitalName,setHospitalName] = useState('')
    const [hospitalNameErr,setHospitalNameErr] = useState(false)

    const [experience,setExperience] = useState(1)

    const [userType,setUserType] = useState(null)

    const [isLoadingConnect,setIsLoadingConnect] = useState(false)


    const sidebarWidth = useSelector(data => data.SidebarReducer.width)
    const newImage = useSelector(data => data.ParentReducer.parentData.image)
    const first_name = useSelector(data => data.ParentReducer.parentData.first_name)
    const last_name = useSelector(data => data.ParentReducer.parentData.last_name)
    const newEmail = useSelector(data => data.ParentReducer.parentData.email)
    const mobile_number = useSelector(data => data.ParentReducer.parentData.mobile_number)
    const nationality1 = useSelector(data => data.ParentReducer.parentData.nationality)
    const short_des = useSelector(data => data.ParentReducer.parentData.short_des)
    const clinicianExperiences = useSelector(data => data.ParentReducer.parentData.experiences)
    const isLoading = useSelector(data => data.ParentReducer.isLoading)

    const parentData = useSelector(data => data.ParentReducer.parentData)


    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })

        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            setUserType(Decrypt(dt.userType))
            dispatch(Decrypt(dt.userType) === 'BC' ? GetClinicianData() :Decrypt(dt.userType) === 'BCBA' ? GetBCBAData() : GetRBTData())
        }
    },[])


    useEffect(()=>{
        setUserName(first_name + ' ' + last_name)
        setFirstName(first_name)
        setLastName(last_name)
        setEmail(newEmail)
        setNumber(mobile_number)
        setNationality(nationality1)
        setImage(newImage)
        setDescription(short_des)
        clinicianExperiences&&
        setExperiences(clinicianExperiences)
    },[newImage,first_name,last_name,mobile_number,nationality1,parentData,clinicianExperiences])


    const handleUpdateImage = (e) =>{
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))

        if(dt){
            const formData = new FormData()
            formData.append('image',e.target.files[0])
            formData.append('uid',dt.uid)

            setImageUploading(true)

            const path = userType&&userType === 'BC' ? '/api/update-clinician-image' :userType&&userType === 'BCBA' ? '/api/aupdate-bcba-image' :'/api/update-rbt-image'

            fetch(baseURL + path,{
                method:'Put',
                body:formData,
                headers:{'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    setImageUploading(false)
                    toast.error('error..')
                }
                else{
                    setImageUploading(false)
                    dispatch({type:'UpdateImage',payload:res2.message[0].image})
                }
            })
        }
    }

    const handleUpdateProfile = () =>{

        const isDone = Required('First Name',firstName)&&Required('Last Name',lastName)&&Required('Number',number)&&Required('Nationality',nationality)&&!numberErr&&!firstNameErr&&!lastNameErr  

        if(isDone){
            const obj = {
                first_name:Encrypt(firstName),
                last_name:Encrypt(lastName),
                mobile_number:Encrypt(number),
                nationality:Encrypt(nationality),
                short_des:Encrypt(description),
                password:Encrypt(currentPassword),
                newPass:Encrypt(newPass)
            }

            if(currentPassword.trim() !== ''){
                if(Required('New Password',newPass)&&newPassErr !== 0){
                    dispatch(userType&&userType === 'BC' ?  UpdateClinicianProfile(obj) :userType&&userType === 'BCBA' ? UpdateBCBAProfile(obj) : UpdateRBTProfile(obj))
                    dispatch({type:'SetIsLoading',payload:true})
                }
            }
            else{
                dispatch(userType&&userType === 'BC' ?  UpdateClinicianProfile(obj) :userType&&userType === 'BCBA' ? UpdateBCBAProfile(obj) : UpdateRBTProfile(obj))
                dispatch({type:'SetIsLoading',payload:true})
            }
        }
    }
    
    const handleAddExperience = () =>{
        if(Required('Hospital Name',hospitalName)&&Required('Experience',`${experience}`)&&!hospitalNameErr){
            const dt = JSON.parse(localStorage.getItem('bx_user_token'))

            if(dt){
                const path = userType&&userType === 'BC' ? '/api/add-clinician-experience' :userType&&userType === 'BCBA' ? '/api/add-bcba-experience' :'/api/add-rbt-experience'
                
                fetch(baseURL + path,{
                    method:'Put',
                    body:JSON.stringify({hospitalName,experience,uid:dt.uid}),
                    headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
                })
                .then(res=>res.json())
                .then(res2=>{
                    if(res2.code === 'Error'){
                        toast.error('error..')
                    }
                    else{
                        setHospitalName('')
                        setExperience(1)
                        setExperiences(res2.message)
                        setShow(false)
                    }
                })
            }
        }
    }

    const handleDeleteExperience = (data) => {
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            Swal.fire({
                title: 'Are you sure?',
                text: 'Once deleted, you will not be able to recover this!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                // confirmButtonColor:"#F99B9B",
                cancelButtonText: 'No, keep it'
            }).then((result) => {
                if (result.isConfirmed) {

                    const path = userType&&userType === 'BC' ? '/api/delete-clinician-experience' :userType&&userType === 'BCBA' ? '/api/delete-bcba-experience' :'/api/delete-rbt-experience'

                    fetch(baseURL + path,{
                        method:'Delete',
                        body:JSON.stringify({data,uid:dt.uid}),
                        headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
                    })
                    .then(res=>res.json())
                    .then(res2=>{
                        if(res2.code === 'Error'){
                            toast.error('error..')
                        }
                        else{
                            setExperiences(res2.message)
                        }
                    })
                }
            })
        }
    }

    const handleConnectToStripe = () =>{
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            setIsLoadingConnect(true)
            fetch(baseURL + '/api/connecToStripe',{
                method:'Post',
                body:JSON.stringify({Id:dt.uid,email:email,first_name:firstName,last_name:lastName,requestedFrom:'web'}),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    setIsLoadingConnect(false)
                    toast.error(res2?.message,{autoClose:3000,toastId:'stripe-connected-id'})
                }
                else{
                    setIsLoadingConnect(false)
                    window.location.href = res2?.message?.url
                }
            })
        }
    }

    return (
        <div className="clinician-edit-profile-container">
            <ParentDashboardSideBar active="profile" user={userType&&userType === 'BC' ? "BC" :userType&&userType === 'BCBA' ? 'BCBA' : 'RBT' } />
            <div style={{marginLeft:windowWidth > 1024 &&sidebarWidth}}>
                <ClinicianDashboardNavbar 
                    active="profile" 
                    user={userType&&userType === 'BC' ? "BC" :userType&&userType === 'RBT' ? "RBT" : 'BCBA'}
                    showAppointments={(userType === 'BCBA' || userType === 'RBT') ? false : true}
                />
                <div className="clinician-edit-profile-body">
                    <h2 style={{fontFamily:'CocoSharp bold'}}>Edit Profile</h2>
                    <Row className="mx-0 mt-4 clinician-edit-profile-body-row">
                        <Col sm={12} md={12} lg={4} className="d-flex flex-column align-items-center clinician-edit-profile-body-row-col-1">
                            <div style={{width:100,height:100,borderRadius:'50%',backgroundImage:`url(${image})`,backgroundSize:'cover',backgroundPosition:'center',backgroundRepeat:'no-repeat',marginTop:40}}>
                            </div>
                            <h5 className="mt-2 mb-0" style={{fontFamily:'CocoSharp bold',color:'#096E82',fontSize:25}}>{first_name + ' ' + last_name}</h5>
                            <p className="mb-0 mt-0">{email}</p>
                            
                            <p className="mb-0 mt-5" style={{fontFamily:'CocoSharp bold',marginTop:50,fontSize:15}}>Acceptable format jpg, png only</p>
                            <p className="mb-3 mt-0" style={{fontFamily:'CocoSharp bold',fontSize:16}}>Max file size is 500 kb</p>
                            {
                                imageUploading ? 
                                    <label className="edit-profile-btn">Uploading.....</label>
                                :
                                    <>
                                        <input type='file' style={{display:'none'}} id="change-image-input" onChange={(e)=>handleUpdateImage(e)} />
                                        <label htmlFor="change-image-input" className="edit-profile-btn">Change Picture</label>
                                    </>
                            }
                            {
                                isLoadingConnect ?
                                <button className="connect-stripe-button">
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    {' '}
                                    Loading....
                                </button>
                                :
                                    userType&&userType === 'RBT'&&
                                    <button className="connect-stripe-button" onClick={handleConnectToStripe}>Connect To Stripe</button>
                            }
                        </Col>
                        <Col sm={12} md={12} lg={8} className="clinician-edit-profile-body-row-col-2 px-0">
                            <Row className="mx-0 mt-4 ">
                                <Col sm={12} md={12} lg={6} className="pl-0 c-p-0">
                                    <InputComp 
                                        value={firstName}
                                        onChange={(e)=>OnlyAlphabets(e.target.value) ? (setFirstName(e.target.value),setFirstNameErr(true)) : (setFirstName(e.target.value),setFirstNameErr(false))}
                                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
                                        isError={firstNameErr}  
                                        label="First Name" 
                                        labelStyle={{fontSize:16,fontWeight:600}} 
                                    />
                                    <InputComp 
                                        value={lastName}
                                        onChange={(e)=>OnlyAlphabets(e.target.value) ? (setLastName(e.target.value),setLastNameErr(true)) : (setLastName(e.target.value),setLastNameErr(false))}
                                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
                                        isError={lastNameErr} 
                                        containerStyle={{marginTop:20}} 
                                        label="Last Name" 
                                        labelStyle={{fontSize:16,fontWeight:600}} 
                                    />
                                    <PhoneNumberComp 
                                        value={number}
                                        onChange={(e)=>  e&&(e).toString().replace(/ /g,"").length < 8 ? (setNumber(e),setNumberErr(true)) : (setNumber(e),setNumberErr(false)) }
                                        inputLength={(e)=>e.target.value = (e.target.value).toString().replace(/ /g,"").slice(0,15)}
                                        // isError={emailErr || emailExist} 
                                        containerStyle={{marginTop:20}} 
                                        label="Number" 
                                        labelStyle={{fontSize:16,fontWeight:600}} 
                                    />
                                    {numberErr&&<p style={{marginLeft:10,fontSize:12,color:'red'}}>Number Should be between 8 to 15 digits</p>}
                                    
                                    <SelectCountryComp 
                                        value={nationality}
                                        onSelect={(e)=>setNationality(e)}
                                        containerStyle={{marginTop:20}} 
                                        label="Nationality" 
                                        labelStyle={{fontSize:16,fontWeight:600}} 
                                    />
                                </Col>
                                <Col sm={12} md={12} lg={6} className="pr-0 c-p-0">
                                    <TextAreaComp
                                        value={description}
                                        label="Description"
                                        containerStyle={{marginTop:windowWidth < 991&&30}}
                                        labelStyle={{ fontSize: 16, fontWeight: 600 }}
                                        inputLength={(e) => e.target.value = (e.target.value).slice(0, 800)}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                    <InputPassComp
                                        value={currentPassword}
                                        onChange={(e)=> setCurrentPassword(e.target.value)}
                                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,64)}
                                        label="Current Password" 
                                        containerStyle={{marginTop:20}}
                                        labelStyle={{fontSize:16,fontWeight:600}} 
                                    />
                                    <InputPassComp
                                        value={newPass}
                                        onChange={(e)=> (setNewPassErr(PasswordValidation(e.target.value)), setNewPass(e.target.value))}
                                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,64)}
                                        containerStyle={{marginTop:20}}
                                        label="New Password" 
                                        labelStyle={{fontSize:16,fontWeight:600}} 
                                        
                                    />
                                    {newPassErr === 0 &&<p style={{marginLeft:10,fontSize:12,color:'red'}}><b>Weak</b>, Password must contain one digit (0-9) and one special character (! @ # $ % ^ & *)</p>}
                                </Col>
                            </Row>
                            <div>
                                {
                                    experiences !== undefined&&experiences.length > 0 ?
                                    experiences.map((dt,i)=>{
                                        return(
                                            <Row key={i} className="mx-0 mt-0">
                                                <Col sm={12} md={12} lg={6} className="pl-0 c-p-0">
                                                    <InputComp 
                                                        value={Decrypt(dt.hospital_name)}
                                                        onChange={(e)=>OnlyAlphabets(e.target.value) ? (setFirstName(e.target.value),setFirstNameErr(true)) : (setFirstName(e.target.value),setFirstNameErr(false))}
                                                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
                                                        containerStyle={{marginTop:20}}
                                                        label="Hospital Name" 
                                                        labelStyle={{fontSize:16,fontWeight:600}}
                                                        disabled={true}
                                                    />
                                                </Col>
                                                <Col sm={12} md={12} lg={6} className="pr-0 c-p-0 d-flex align-items-end">
                                                    <InputComp 
                                                        value={Decrypt(dt.experience)}
                                                        onChange={(e)=>OnlyAlphabets(e.target.value) ? (setFirstName(e.target.value),setFirstNameErr(true)) : (setFirstName(e.target.value),setFirstNameErr(false))}
                                                        inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
                                                        containerStyle={{marginTop:20}}
                                                        label="Experience" 
                                                        labelStyle={{fontSize:16,fontWeight:600}}
                                                        disabled={true}
                                                    />
                                                    <div style={{padding:'0px 10px',display:'flex',alignItems:'flex-end',height:'100%'}}>

                                                        <BsTrash 
                                                            color="#F99B9B" 
                                                            style={{marginBottom:20,fontSize:18}} 
                                                            className="add-more-link"
                                                            onClick={()=>handleDeleteExperience(dt)}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        )
                                    })
                                       
                                    :
                                        null
                                }
                            </div>
                            {experiences.length <= 4 &&<div className="w-100 mt-1 text-right">
                                <p style={{fontSize:12,fontWeight:'bold',color:'#F99B9B',textDecoration:'underline',display:'inline'}} className="add-more-link" onClick={()=>setShow(true)}>Add Experience</p>
                            </div>}
                            { 
                                isLoading ?
                                    <button className="edit-profile-btn" style={{marginTop:30,height:55}}>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        {' '}
                                        Loading....
                                    </button>
                                :
                                    <button className="edit-profile-btn" onClick={handleUpdateProfile} style={{marginTop:30,height:55}}>Save Changes</button>
                            }
                        </Col>
                    </Row>
                </div>

                <Modal show={show} centered animation={false}>
                    <Modal.Header style={{border:'none',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                        <p style={{margin:0,color:'#096E82',fontSize:28}}>Add Experience</p>
                        <a style={{fontSize:24}}>
                            <RiCloseLine color="#096E82" onClick={()=>(setHospitalName(''),setExperience(1),setShow(false))} />
                        </a>
                    </Modal.Header>
                    <Modal.Body style={{position:'relative',zIndex:1,borderRadius:20}} className="py-2 pb-4">
                        <InputComp 
                            value={hospitalName}
                            onChange={(e)=>OnlyAlphabets(e.target.value) ? (setHospitalName(e.target.value),setHospitalNameErr(true)) : (setHospitalName(e.target.value),setHospitalNameErr(false))}
                            inputLength={(e)=>e.target.value = (e.target.value).slice(0,50)}
                            isError={hospitalNameErr} 
                            label="Hospital Name" 
                            labelStyle={{fontSize:16,fontWeight:600}}
                        />
                        <InputComp
                            type="number" 
                            value={experience}
                            onChange={(e)=>setExperience(e.target.value)}
                            inputLength={(e)=>e.target.value = (e.target.value).slice(0,2)}
                            containerStyle={{marginTop:20}}
                            label="Experience" 
                            labelStyle={{fontSize:16,fontWeight:600}}
                        />
                        

                        <button id="edit-profile-modal-btn" onClick={handleAddExperience} style={{marginTop:30,height:55}}>Save</button>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}
