export const onChangeSleepinAndEatingForm4 = (data) => {
    return (dispatch) => {
        dispatch({ type: 'onChangeSleepinAndEatingForm4', payload: data })
    }
}


export const onChangeBehaviourForm4 = (data) => {
    return (dispatch) => {
        dispatch({ type: 'onChangeBehaviourForm4', payload: data })
    }
}


export const onChangeSocialDevelopmentForm4 = (data) => {
    return (dispatch) => {
        dispatch({ type: 'onChangeSocialDevelopmentForm4', payload: data })
    }
}


export const onChangeMotorSkillsForm4 = (data) => {
    return (dispatch) => {
        dispatch({ type: 'onChangeMotorSkillsForm4', payload: data })
    }
}


export const onChangeMoodForm4 = (data) => {
    return (dispatch) => {
        dispatch({ type: 'onChangeMoodForm4', payload: data })
    }
}


export const onChangeOtherForm4 = (data) => {
    return (dispatch) => {
        dispatch({ type: 'onChangeOtherForm4', payload: data })
    }
}