import React from "react";

import Bigcard from "../../Components/bigcard";
import PinkBtn from "../../Components/PinkBtn";

function TreatmentDesease() {
  const tiles = [
    {
      title: "Scientific Treatments",
      desc: "Evidence-based Practice & Best Practices means that science is how we evaluate treatment effectiveness.",

      list2:
        "With Evidence-Based Practice, scientific knowledge functions as a filter and is the fundamental principle guiding clinical work.",

      list3:
        "Science is the dominant judge of value in many disciplines. But in psychology, interventions based on scientific treatment are not frequently sought by consumers or endorsed by service providers.",
      list4:
        "The premises of the scientific method include conducting quantitative and direct measurements of observable events, testing verifiable hypotheses, relying on data to draw conclusion, and publishing results in peer-reviewed journals",

      url: "./Icons/scientific.png",
      color: "blue",
    },
    {
      title: "Pseudoscientific Therapies",
      desc: "There are many dubious and pseudoscientific interventions for children with autism and other developmental disabilities.",

      list2:
        "These interventions promote high success rates and rapid effects. Be cautious, ask questions",

      list3:
        "The “theory” behind the therapy concept contradicts objective knowledge (and sometimes, common sense)",
      list4:
        "Testimonials, anecdotes, or personal accounts are offered in support of claims about the therapy’s effectiveness, but little or no objective evidence is provided.",

      list5:"Critics and scientific investigators are often met with hostility, and are accused of persecuting the promoters, being “close-minded,” or having some ulterior motive for “debunking” the therapy.",
      url: "./Icons/pseudo.png",
      color: "pink",
    },
  ];
  return (
    <div>
      <div className="grid grid-cols-1">
        <p className="text-[#096E82] font-bold text-[22px] px-[20px] py-[20px] md:text-[48px] text-center md:px-[200px] md:py-[100px] leading-none">
          Science and Pseudoscience in the Treatment of Autism Importance of
          informed treatment decisions
        </p>
      </div>
      <div className="grid  grid-cols-1 px-[10px] md:grid-cols-2 gap-6 md:px-[180px]">
        {tiles.map((v, i) => (
          <Bigcard
            key={i}
            src={v.url}
            color={v.color}
            title={v.title}
            desc={v.desc}
            list2={v.list2}
            list3={v.list3}
            list4={v.list4}
            list5={v.list5}
          />
        ))}
      </div>
      <div className="mt-6 w-fit mx-auto">
        <PinkBtn text={"Get Started"} url="signup" />
      </div>
    </div>
  );
}

export default TreatmentDesease;
