import React,{useState} from 'react'
import { RiFileUploadLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { baseURL } from '../Config';
import './CSS/UploadFileComp.css'

export default function UploadeFileComp(props) {

    const [uploading, setUploading] = useState(false)

    const handleUpload = (e) =>{
        if(e){
            setUploading(true)
            for(let i=0;i < e.target.files.length; i++){
                const image = new FormData()
                image.append('image',e.target.files[i])

                fetch(baseURL + '/upload-file',{
                    method:'Post',
                    body:image
                })
                .then(res => res.json())
                .then(res2=>{
                    if(res2.code === 'Success'){
                        props.getUploadedFile(res2.message)
                        if(i+1 === e.target.files.length){
                            setUploading(false)
                            toast.success('Files Uploaded...')
                        }
                    }
                })
            }
        }
    }

    return (
        <div style={props.containerStyle}>
            {
                uploading ? 
                    <label className="upload_file_container">
                        <RiFileUploadLine color="#F99B9B" size={40} />
                        <p style={{margin:0,color:'blue'}}>Files Uploading....</p>
                    </label>
                :
                <>
                    <label for="file" style={{marginBottom:0}} className="upload_file_container">
                        <RiFileUploadLine color="#F99B9B" size={40} />
                        <p style={{margin:0}}>Upload Documents</p>
                    </label>
                    <input 
                        type="file" 
                        id="file" 
                        style={{display:'none'}} 
                        // accept=".doc, .docx, .txt, .pdf"
                        accept=".pdf"
                        multiple={props.multiple || true}
                        onChange={(e)=>handleUpload(e)} 
                    />
                </>

            }
            
            
        </div>
    )
}
