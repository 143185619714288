import React from "react";
import WorkCard from "../../Components/WorkCard";

export default function Programs() {
  const tiles = [
    {
      title: "Limitation Skills",
      list1: "Motor imitation with objects.",
      list2: "Verbal imitation.",
      url: "./Icons/imigrationskills.png",
      color: "blue",
    },
    {
      title: "Motor Skills",
      list1: "Gross Motor.",
      list2: "Fine Motor.",
      url: "./Icons/motorskills.png",
      color: "pink",
    },
    {
      title: "Receptive Language",
      list1: "Expressive labels.",
      list2: "Social Questions.",
      url: "./Icons/expressivelang.png",
      color: "blue",
    },
    {
      title: "Expressive Language",
      list1: "Expressive labels.",
      list2: "Social Questions.",
      url: "./Icons/expressivelang.png",
      color: "pink",
    },
    {
      title: "Academic Skills",
      list1: "Writing.",
      list2: "Counting.",
      list3: "Reading.",
      url: "./Icons/Academic.png",
      color: "blue",
    },
    {
      title: "Social Skills",
      list1: "Eye Contact.",
      list2: " Greeting.",
      list3: "Sharing.",
      url: "./Icons/socialskills.png",
      color: "pink",
    },

    {
      title: "Play Skills",
      list1: "Independent Play.",
      list2: "Play with peers.",
      url: "./Icons/playskillls.png",
      color: "blue",
    },
    {
      title: "Daily Living Skills",
      list1: "Getting Dressed.",
      list2: "Brushing teeth.",
      list3: "Using a toilet.",
      url: "./Icons/dailyliving.png",
      color: "pink",
    },
  ];

  return (
    <>
      <div className="grid grid-cols-1">
        <p className="text-lightGreen-500 font-bold text-4xl lg:text-4xl xl:text-5xl px-[20px] py-[20px]  text-center md:px-[200px] md:py-[100px] ">
          Our Programs Are Designed To Teach Important Skills
        </p>
      </div>
      <div className="grid grid-cols-1 px-[10px] md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:px-[40px] w-fit mx-auto">
        {tiles.map((v, i) => (
          <WorkCard
            key={i}
            src={v.url}
            color={v.color}
            title={v.title}
            list1={v.list1}
            list2={v.list2}
            list3={v.list3}
          />
        ))}
      </div>
    </>
  );
}
