import React, { useEffect,useState } from 'react';
import Avatar from 'react-avatar';
import { usePubNub } from 'pubnub-react';
import './CSS/ContactCard.css'

export default function ContactCard({name,date,Id,hanldeSelectChat,isActive,image,channel,userType,isUnread}) {

    const [isOnline,setIsOnline] = useState(false)
    const pubnub = usePubNub()

    useEffect(()=>{
        pubnub.addListener({ 
            presence:(event)=>{
                if(event?.action === 'join'){
                    if(Id === event?.uuid){
                        setIsOnline(true)
                    }
                }
                else if(event?.action === 'leave' || event?.action === 'timeout'){
                    if(Id === event?.uuid){
                        setIsOnline(false)
                    }
                }
            }
        });
    },[pubnub])

    return (
        <div onClick={()=>(hanldeSelectChat(Id,userType))} className={'contact-card-container'} style={{backgroundColor:isActive ? '#096E82' : 'white'}}>
                <Avatar src={image} name={name} round size='45px' unstyled />
                <div style={{marginLeft:10,width:'100%'}}>
                    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                        <p style={{margin:0,fontSize:14,fontFamily:'CocoSharp bold',color:isActive ? 'white' : '#52585D'}}>{name}</p>
                        <p style={{margin:0,fontSize:11,color:isActive ? 'white' : '#52585D'}}>{date}</p>
                    </div>
                    {/* <p style={{margin:0,marginTop:0,fontSize:11,color:isActive ? 'white' : '#52585D'}}>{isOnline ? 'Online' : 'Offline'}</p> */}
                    <p style={{margin:0,marginTop:0,fontSize:11,color:isActive ? 'white' : '#52585D'}}>{userType}</p>
                </div>
                {/* {
                    isUnread ? <div style={{width:8,height:8,borderRadius:4,backgroundColor:'red'}}></div>
                    :null
                } */}
        </div>
    )
}
