import React,{useState,useEffect} from 'react'
import {useSelector} from 'react-redux'
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import ParentDashboardNavbar from '../../Components/ParentDashboardNavbar'
import ChatComponent from '../../Components/ChatComp/ChatIndex'
import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import './CSS/ParentChats.css'
import { baseURL, headers, PUBNUB_PUBLISH_KEY, PUBNUB_SUBSCRIBE_KEY } from '../../Config'
import { toast } from 'react-toastify'
import moment from 'moment'

export default function ParentChats() {

    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const sidebarWidth = useSelector(data => data.SidebarReducer.width)
    const parentData = useSelector(data => data.ParentReducer?.parentData)
    const pubnub = new PubNub({
        publishKey: PUBNUB_PUBLISH_KEY,
        subscribeKey: PUBNUB_SUBSCRIBE_KEY,
        uuid: parentData?.uid
    });

    const [selectedUser,setSelectedUser] = useState(null)
    const [selectedType,setSelectedType] = useState('1')
    const [isLoaded,setIsLoaded] = useState(false)
    const [isLoadedList,setIsLoadedList] = useState(false)
    const [channel,setChannel] = useState('')
    const [clinicians,setClinicians] = useState([])

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })

        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            fetch(baseURL+ '/api/getAllTherapistsParent',{
                method:'Post',
                body:JSON.stringify({uid:dt.uid}),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(async res2=>{
                if(res2.code === 'Error'){
                    toast.error('error..')
                    setIsLoaded(true)
                    setIsLoadedList(true)
                }
                else{
                    if(res2?.message?.length > 0){

                        const newChats = await res2?.message?.reduce(async (chat, dat) => {
                            pubnub.subscribe({channels:[`${dat?.uid}-${parentData?.uid}`],withPresence:true}) // therapist-parent
                            let newArr = await chat
                            const res = await pubnub.objects.getMemberships({uuid:parentData?.uid,include: {totalCount:true,customFields: true}})
                            let count;
                            if(res?.data?.length > 0){

                                count = await pubnub.messageCounts({
                                    channels: [`${dat?.uid}-${parentData?.uid}`],
                                    channelTimetokens: [res?.data[0]?.custom?.lastReadTimetoken || '']
                                })
                                .catch(err=>console.log(err))
                            }

                            dt.isActive = false
                            dt.isUnread = count?.channels[`${dat?.uid}-${parentData?.uid}`] == 0 ? false : true
                            newArr = [...newArr,dat]
                            return newArr
                        }, [])

                        setClinicians(newChats)
                        setIsLoaded(true)
                        setIsLoadedList(true)
                    }
                    else{
                        setClinicians([])
                        setIsLoadedList(true)
                    }
                }
            })
        }
    },[])

    const hanldeSelectChat = (Id) =>{
        const newArr = clinicians.map(dt=>{
            if(dt.uid === Id){
                pubnub.objects.setMemberships({
                    uuid:parentData?.uid,
                    channels:[{
                        id:`${dt?.uid}-${parentData?.uid}`,
                        custom:{
                            lastReadTimetoken:`${moment().unix() * 10000000}`
                        }
                    }]
                })
                setChannel(`${dt?.uid}-${parentData?.uid}`)
                dt.isActive = true
                setSelectedUser(dt)
                return dt
            }
            dt.isActive = false
            return dt
        })

        setClinicians(newArr)
    }

    useEffect(()=>{
        return()=>{
            pubnub.unsubscribeAll()
        }
    },[])


    const handleChangeSelectedType = (type) =>{
        setSelectedType(type)
    }

    return (
        <PubNubProvider client={pubnub}>
            <div className="parent-dashboard-chats-container">
                <ParentDashboardSideBar active="chats" />
                <div style={{marginLeft:windowWidth > 1024 && sidebarWidth}}>
                    <ParentDashboardNavbar />
                    <div className="parent-dashboard-chats-body">
                        <div style={{width:'100%',marginTop:0}}>
                            <h2 style={{fontFamily:'CocoSharp bold'}} className="mb-0">Messages</h2>
                            <ChatComponent 
                                contactListHeaderTitle="Therapists"
                                // contactListHeaderTitle1="RBT"
                                selectedType={selectedType}
                                changeSelectedType={handleChangeSelectedType}
                                type="1"
                                contactList={clinicians}
                                listLoaded={isLoadedList}
                                hanldeSelectChat={(Id)=>hanldeSelectChat(Id)}
                                selectedUser={selectedUser}
                                channel={channel}
                                senderImage={parentData?.image}
                                senderName={parentData?.first_name + ' ' + parentData?.last_name}
                                user="1"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </PubNubProvider>
    )
}