import React,{ useRef } from "react";
import { useDispatch,useSelector } from "react-redux";
import { toast } from "react-toastify";
import { io } from "socket.io-client";
import {socketURL,Decrypt} from '../Config'

export default function useSockets() {
    const dispatch = useDispatch()

    const socket = useRef(null)
    socket.current = io(socketURL)

    const runSockets = () =>{
        
        // notification
        socket.current.on("notification", (res) => {    
            const data = JSON.parse(localStorage.getItem('bx_user_token'))
            if(res&&res?.userId === data.uid){
              dispatch({type:'NewNotifications',payload:res})
            }
        });


        // extend call permission
        socket.current.on("extend-call-permission", (res) => {
            const data = JSON.parse(localStorage.getItem('bx_user_token'))
            if(data&&Decrypt(data.userType)  !== 'Parent'&&data.uid === res.clinician_id){
                toast.error(res.msg,{toastId:'extend-call-err-id-1',autoClose:3000})
            }
        });


        // Call extended
        socket.current.on("call-extended", (res) => {
            const data = JSON.parse(localStorage.getItem('bx_user_token'))
            if(res.code === 'Success'){
              if(Decrypt(data.userType)  === 'Parent'){
                  if(res.msg.parent_id === data.uid){
                    dispatch({type:'ExtendMeeting',payload:res.msg})
                    toast.success('Call extended..',{toastId:'extend-call-success-id-1',autoClose:3000})
                  }
              }
              else{
                if(res.msg.clinician_id === data.uid){
                  dispatch({type:'ExtendMeeting',payload:res.msg})
                  // dispatch({type:'ExtendCall',payload:false})
                  toast.success('Call extended..',{toastId:'extend-call-success-id-2',autoClose:3000})
                }
              }
            }
            else{
              if(Decrypt(data.userType)  !== 'Parent'&&res.msg.clinician_id === data.uid){
                toast.error(res.msg,{toastId:'meeting-extended-err-id-1',autoClose:3000})
              }
            }
        });
    }

    return runSockets
}
