import React from "react";
import heroImg from "../../assets/pediatricians/works-hero.png";
import heroImgDesk from "../../assets/pediatricians/hero.png";
import PinkBtn from "../../Components/PinkBtn";
import patt from "../../assets/pediatricians/patt.png";
function HeroSection() {
  return (
    <div className=" relative  mt-[100px] flex justify-between items-center gap-7 xl:gap-0 bg-lightGreen-100 xl:px-0 xl:flex-row flex-col py-6  xl:py-0">
      <div className="xl:block flex justify-center flex-[0.5] h-full relative z-[3]">
        <img className="w-[100%] xl:block hidden" src={heroImgDesk} alt="" />
        <img
          className="w-[100%] rounded-lg xl:hidden block xl:rounded-tl-[50%] xl:rounded-bl-[50%] "
          src={heroImg}
          alt=""
        />
      </div>
      <div className="flex-[0.5] xl:pl-10 flex justify-center items-center xl:text-left xl:items-start gap-4 flex-col">
        <h1 className="text-lightGreen-500 font-bold md:text-5xl text-2xl mb-0">
          Your Patients Can <br /> Count on us
        </h1>

        <p className="text-lightGreen-500 mx-2 md:mr-3 text-justify font-normal text-lg 2xl:w-3/5 mb-0">
          At Cognitia, we believe in cohesive collaboration between
          professionals to facilitate effective treatment. Collaborate with our
          team of highly qualified clinicians who make a difference in the lives
          of children and their families.
        </p>
        <div className="xl:mt-3">
          <PinkBtn text={"Refer a Patient"} url={"ReferalForm"} target="_blank" />
        </div>
      </div>
      <img
        src={patt}
        className="absolute object-cover xl:block hidden bottom-[0%]  left-0 w-full z-10"
        alt=""
      />
    </div>
  );
}

export default HeroSection;
