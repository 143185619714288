import React,{useState,useEffect} from 'react'
import './CSS/MChatQuestionComp.css'

export default function MChatQuestionComp({questionNumber,question,example,onYes,onNo}) {

    const [isActive,setIsActive] = useState(false)

    useEffect(()=>{
        setIsActive(true)
    })


    useEffect(()=>{
        setIsActive(true)
    },[questionNumber])

    const style = {
        width: 110,
        height: 50,
        backgroundColor: '#F99B9B',
        borderRadius: 7,
        color: 'white',
        alignSelf: 'center',
        border: 'none'
    }

    return (
        <div className={`MChat-Question-Comp-Container ${isActive&&'active'}`}>
            <p style={{margin:0,marginTop:30,fontFamily:'CocoSharp regular'}}>Question {questionNumber} From 20</p>
            <h1 style={{margin:0,marginTop:20,fontFamily:'CocoSharp bold'}} >{question}</h1>
            {example&&<p style={{margin:0,marginTop:27,fontFamily:'CocoSharp regular'}} >Example: {example}</p>}

            <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:80}}>
                <button style={{...style, marginRight:40}} onClick={()=>onYes()}>Yes</button>
                <button style={style} onClick={()=>onNo()} >No</button>
            </div>
        </div>
    )
}
