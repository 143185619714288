import React from "react";
import "antd/dist/antd.min.css";
import {Link} from 'react-router-dom'
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";

const DropDown = ({title , options, linkClass, overlayStyle, contianerStyle}) => {
  return (
    <Dropdown overlayStyle={overlayStyle} overlay={
        <Menu>
            {
                options.map((option , i )=>(
                    <Menu.Item key={i} style={{margin:'10px 0px'}}>
                    <Link
                      to={option.route}
                      className={linkClass}
                    >
                      {option.name}
                    </Link>
                  </Menu.Item>
                ))
            }
       
      </Menu>
      }>
      <a className={linkClass} style={contianerStyle} onClick={(e) => e.preventDefault()}>
        {title} <DownOutlined />
      </a>
    </Dropdown>
  );
};

export default DropDown;
