import React,{useState,useEffect} from 'react'
import {useSelector} from 'react-redux'
import {baseURL, Decrypt, Encrypt ,headers} from '../../Config'
import ParentDashboardSideBar from '../../Components/ParentDashboardSideBar'
import ParentDashboardNavbar from '../../Components/ParentDashboardNavbar'
import AppointmentScheduleAlert from '../../Components/AppointmentScheduleAlert'
import CalenderComp from '../../Components/CalenderComp2'
import SelfPayModal from '../../Components/SelfPayModal'
import Skeleton from 'react-loading-skeleton';
import './CSS/ScheduleAppointment.css'
import { Button,Modal,Spinner } from 'react-bootstrap'
import moment from 'moment'
import {getTimeInISO,convertIntoUtc,getTimeInLocal,convert24To12,slotsSequence,convert12To24} from '../../TimeFormate'
import {RiCloseLine} from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

export default function ScheduleAppointment(props) {

    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const [days,setDays] = useState([])
    const [slots,setSlots] = useState([])
    const [appointments,setAppointments] = useState([])
    const [childs,setChilds] = useState([])
    const [skeletons,setSkeletons] = useState([{},{}])
    const [isLoaded,setIsLoaded] = useState(false)
    const [isLoading,setIsLoading] = useState(true)
    const [isDone,setIsDone] = useState(true)
    const [time,setTime] = useState('')
    const [date,setDate] = useState('')
    const [show,setShow] = useState(false)
    const [clinician_id,setClinicianId] = useState('')
    const [showAlert,setShowAlert] = useState(false)
    const [showSelfModal,setShowSelfModal] = useState(false)
    const [clinicianName,setClinicianName] = useState('')
    const [clinicianImage,setClinicianImage] = useState('')
    const [childName,setChildName] = useState('')
    const [appDate,setAppDate] = useState('')
    const [appTime,setAppTime] = useState('')

    const sidebarWidth = useSelector(data => data.SidebarReducer.width)

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })

        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt){
            const date = new Date()
            fetch(baseURL+ '/api/get-clinician-available-dates',{
                method:'Post',
                body:JSON.stringify({uid:dt.uid,Date:getTimeInLocal(date)}),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    toast.error('error..')
                    setIsLoaded(true)
                }
                else{
                    if(res2.message&&res2.message.slots.length > 0){
                        const mySlots = []
                        const sequencedSlots = slotsSequence(res2.message.slots)
                        sequencedSlots.map(dt=>{
                            const local_time = getTimeInLocal(dt)
                            const diff = moment.duration(moment(getTimeInLocal(dt)).diff(getTimeInISO(date)))
                            
                            if(local_time.split('T')[0] === getTimeInISO(date).split('T')[0] && (diff/1000) > 0)
                            {
                                const slot = convert24To12(local_time)
                                mySlots.push(slot)
                            }
                        })

                        setDays(res2.message.daysOfWeek)
                        setSlots(mySlots)
                        setClinicianId(res2.message.clinician_id)
                        setIsLoaded(true)
                    }else{
                        setDays(res2.message.daysOfWeek)
                        setSlots([])
                        setClinicianId(res2.message.clinician_id)
                        setIsLoaded(true)
                    }
                }
            })
        }
    },[])


    const handleShowModal = () =>{
        if(time === '' || date === ''){
            toast.error('Please select date or time',{toastId:"schedule-app-Id1"})
        }
        else{
            setShow(true)
            const dt = JSON.parse(localStorage.getItem('bx_user_token'))
            if(dt){
                fetch(baseURL+ '/api/get-parent-childs',{
                    method:'Post',
                    body:JSON.stringify({uid:dt.uid}),
                    headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
                })
                .then(res=>res.json())
                .then(res2=>{
                    if(res2.code === 'Error'){
                        toast.error('error..')
                        setIsLoading(false)
                    }
                    else{
                        setChilds(res2.message)
                        setIsLoading(false)
                    }
                })
            } 
        }
    }

    const converDateOfBirth = (date) =>{
        const dob = new Date(date)
        const newDate = new Date()
        const diffTime = Math.abs(newDate - dob);
        const diffDays = Math.ceil(diffTime / (1000*60*60*24)); 

        if(diffDays < 30){
            if( diffDays === 1 ){
                return diffDays + ' ' + 'Day'
            }
            else{
                return diffDays + ' ' + 'Days'
            }
        }
        else if(diffDays < 365){
            if( Math.round(diffDays/30) === 1 ){
                return Math.round(diffDays/30) + ' ' + 'Month'
            }
            else{
                return Math.round(diffDays/30) + ' ' + 'Months'
            }
        }
        else{
            if(Math.round(diffDays/365) === 1){
                return Math.round(diffDays/365) + ' ' + 'Year'
            }
            else{
                return Math.round(diffDays/365) + ' ' + 'Years'
            }
        }
    }

    const handleSchedule = (child_id) =>{
        if(clinician_id !== '' || child_id !== '' || date !== '' || time !== ''){
            const dt = JSON.parse(localStorage.getItem('bx_user_token'))
            const newDate = getTimeInISO(date).split('T')[0]
            const newTime = convert12To24(time)
            const utc_date = convertIntoUtc(newDate + 'T' + newTime + ':00')
            if(dt){
                setIsDone(false)
                setShow(false)
                fetch(baseURL+ '/api/scheduleWithAssignedClinician',{
                    method:'Post',
                    body:JSON.stringify({
                        Id:dt.uid,
                        childId:child_id,
                        clinicianId:clinician_id,
                        appointmentDate:Encrypt(`${utc_date}`)
                    }),
                    headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
                })
                .then(res=>res.json())
                .then(res2=>{
                    if(res2.code === 'Error'){
                        setIsDone(true)
                        toast.error(res2.message,{autoClose:5000,toastId:'db-schedule-app-err-id'})
                    }
                    else{
                        if(res2.message.code&&Decrypt(res2.message.code) == '2002'){
                            setIsDone(true)
                            setShowSelfModal(true)
                        }
                        else{
                            const resData = res2.message[0]
                            const local_time = getTimeInLocal(resData.schedulingDateTime)
                            const date = local_time.toString().split('T')[0]
                            const time = convert24To12(local_time)
                            const clinicianName = res2.message[0].first_name + ' ' + res2.message[0].last_name
                            const clinicianImage = res2.message[0].clinicianImage
                            const childName = res2.message[0].child_name

                            setAppDate(date)
                            setAppTime(time)
                            setClinicianImage(clinicianImage)
                            setClinicianName(clinicianName)
                            setChildName(childName)
                            setIsDone(true)
                            setShowAlert(true)
                        }

                    }
                })
            }
        }
        else{
            toast.error('error, something went wrong',{toastId:'schedule-app-id-2'})
        }
    }


    const handleConfirmSelfPay = () =>{
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))

        if(dt){
            setShowSelfModal(false)
            setIsDone(false) 
            fetch(baseURL + '/api/payRemainingAmount',{
                method:'Post',
                body:JSON.stringify({Id:dt.childId}),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Success'){
                    setIsDone(true) 
                   toast.error(res2.message,{toastId:'pay-rem-amount-success-id1',autoClose:3000})
                }
                else{
                    setIsDone(true) 
                    toast.error(res2.message,{toastId:'pay-rem-amount-err-id1',autoClose:3000})
                }
            })
        }
    }

    return (
        <div className="parent-dashboard-schedule-appointment">
            <ParentDashboardSideBar active="schedule" />
            <div style={{marginLeft:windowWidth > 1024 && sidebarWidth}}>
                <ParentDashboardNavbar showAppointments={true} />
                <div className="parent-dashboard-schedule-appointment-body">
                    <h1 style={{fontFamily:'CocoSharp regular',color:'#0B0F66'}}>Schedule Your Call</h1>
                    {
                        isLoaded ?
                            <>
                                <CalenderComp
                                    tileDisabled={({date, view}) =>!days.includes(date.getDay())}
                                    slots={slots}
                                    selectedTime={(time)=>setTime(time)} 
                                    selectedDate={(date)=>setDate(date)}
                                />
                                {
                                    isDone ?
                                        <Button onClick={handleShowModal} className="parent-dashboard-schedule-appointment-btn">Schedule</Button>
                                    :
                                        <Button  className="parent-dashboard-schedule-appointment-btn">
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            /> 
                                            Loading...
                                        </Button>
                                }
                            </>
                        :
                            <div>
                                <Skeleton width={window.innerWidth < 576 ? 310 : 500} height={50} />
                                <Skeleton width={window.innerWidth < 576 ? 310 : 500} height={200} />
                                <Skeleton width={window.innerWidth < 576 ? 310 : 500} height={80} />
                                <Skeleton width={window.innerWidth < 576 ? 310 : 500} height={50} style={{marginTop:30}} />
                            </div>
                    }
                </div>
            </div>
            <Modal show={show} centered animation={false}>
                <Modal.Header style={{border:'none',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                    <p style={{margin:0,color:'#096E82',fontSize:28,fontFamily:'CocoSharp regular'}}>Select Child</p>
                    <a style={{fontSize:24}}>
                        <RiCloseLine color="#096E82" onClick={()=>setShow(false)} />
                    </a>
                </Modal.Header>
                <Modal.Body style={{zIndex:1,width:'100%'}} className="py-2 pb-4">
                    <fieldset id="schdule-app-modal-fldset" >
                        <legend style={{fontSize:16,margin:0,fontFamily:'CocoSharp regular',padding:'0px 15px 0px 5px',width:'auto'}}>Child</legend>
                            {
                                isLoading ? 
                                    skeletons.map(dt=><Skeleton style={{height:100,width:'100%',marginBottom:10,marginTop:10}} />)
                                :
                                    childs&&
                                    childs.length > 0 ?
                                        childs.map(dt=>{
                                            return(
                                                <div style={{height:100,width:'100%',padding:"0px 10px",marginBottom:10,marginTop:10,display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                                                    <div style={{display:'flex',alignItems:'center'}}>
                                                        <div style={{height:50,width:50,borderRadius:'50%',backgroundImage:`url(${dt.image})`,backgroundSize:'cover',backgroundRepeat:'no-repeat',backgroundPosition:'center'}}>
                                                        </div>
                                                        <div className="d-flex flex-column justify-content-center ml-2">
                                                            <p style={{fontFamily:'CocoSharp bold',color:'#096E82',fontSize:18,margin:0}}>{Decrypt(dt.child_name)}</p>
                                                            <p style={{fontSize:16,margin:0,lineHeight:0.5,color:'#096E82',fontFamily:'CocoSharp regular'}}>{Decrypt(dt.birth_date)&&converDateOfBirth(Decrypt(dt.birth_date))}</p>
                                                        </div>
                                                    </div>

                                                    <button id="schedule-app-select-btn" onClick={()=>handleSchedule(dt.uid)}>Select</button>

                                                </div>
                                            )
                                        })
                                    :
                                        <div style={{height:100,width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                            <h3 style={{color:'lightgray'}}>No Childs</h3>
                                        </div>
                                    
                            }
                    </fieldset>
                </Modal.Body>
            </Modal>

            {/* Self Pay Modal */}
            <SelfPayModal 
                show={showSelfModal} 
                setShow={()=>setShowSelfModal(false)}
                handleConfirmPay={()=>handleConfirmSelfPay()}
            />

            <AppointmentScheduleAlert 
                show={showAlert}
                date={appDate}
                time={appTime}
                clinician_name={clinicianName}
                child_name={childName}
                image={clinicianImage}
                path='/appointments'
                type="fixed" 
            />
        </div>
    )
}