import React from "react";
import img from "../../assets/team/Sumaiya.png";

function Sumaia() {
    return (
        <div className="bg-[#E8FCFF] w-full flex justify-center items-center py-11">
            <div className="max-w-[1440px] flex justify-between flex-col lg:flex-row items-center px-10 w-full gap-10">
                <div className="flex justify-center flex-col items-start gap-1">
                    <h1 className="font-bold text-lightGreen-500 text-3xl md:text-5xl">
                        Sumaia Agha
                    </h1>
                    <h2 className="font-bold text-lightGreen-500 text-xl md:text-2xl">
                        MSc. ABA, BCBA
                    </h2>
                    <h4 className="font-bold text-lightGreen-500 text-md md:text-xl">
                        Behavior Analyst - Clinical Supervisor
                    </h4>
                    <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
                        Sumaia is an accomplished Board Certified Behavior Analyst (BCBA) with a wealth of
                        experience and a strong dedication to supporting children with autism.
                        With a remarkable <span className="font-mono">10</span>-year track record in the field, Sumaia has honed her expertise in
                        utilizing Applied Behavior Analysis (ABA) to make a positive impact on the lives of
                        individuals with autism.
                    </p>
                    <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
                        Sumaia holds a Master of Science degree in Applied Behavior Analysis (ABA) from St.
                        Cloud State University, USA. She’s also a Board Certified Behavior Consultant and is registered
                        as a Category A Behavior Consultant on the Registry of Autism Service Providers in British Columbia (RASP).
                    </p>
                    <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
                        Sumaia’s dedication, expertise and compassionate approach make her a highly respected
                        professional in the field. As a BCBA, she remains dedicated to staying up to date with the
                        latest research and advancements in the field of autism treatment.
                        She actively engages in professional development activities, attending conferences,
                        workshops, and seminars to enhance her knowledge and refine her skills.
                    </p>
                    {/* <p className="font-normal text-justify text-lightGreen-500 text-lg lg:max-w-[680px]">
                        Joanna is also a Board Certified Behavior Analyst (BCBA). She is currently pursuing her Ph.D.
                    </p> */}
                </div>
                <img src={img} alt="" />
            </div>
        </div>
    );
}

export default Sumaia;
