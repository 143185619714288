import React from 'react'
import { DatePicker } from 'antd';
import calendarIcon from '../icons/calendarIcon.svg'
import closeIcon from '../icons/closeIcon.svg'
import './CSS/SelectDate.css'

export default function SelectDate({onChange}) {
    return (
        <div className="custom-select-date">
            <DatePicker 
                onChange={onChange}
                suffixIcon={<img src={calendarIcon} className="img-fluid" style={{transform: `scale(1.5)`}} />}
                clearIcon={<img src={closeIcon} className="img-fluid" style={{transform: `scale(0.7)`}} />}
            />
        </div>
    )
}
