import React,{useState,useEffect} from 'react'
import {useSelector} from 'react-redux'
import ParentDashboardSideBar from '../../../Components/ParentDashboardSideBar'
import ParentDashboardNavbar from '../../../Components/ParentDashboardNavbar'
import { Link, useHistory } from 'react-router-dom'
import './CSS/ScreenerResult.css'
import { baseURL, Decrypt, Encrypt, headers } from '../../../Config'
import { toast } from 'react-toastify'

export default function ScreenerResult(props) {

    const history = useHistory()

    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const [isSelected,setIsSelected] = useState(2)
    const [isRecom,setIsRecom] = useState('Consultation')
    const [risk,setRisk] = useState('')
    const [score,setScore] = useState(0)


    const sidebarWidth = useSelector(data => data.SidebarReducer.width)

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            const windowWidth = window.innerWidth;
            setWindowWidth(windowWidth)
        })
        const result = Decrypt(props?.match?.params?.result)
        setScore(result)
        if(result >=0 && result <= 2){
            setRisk('LOW risk')
            setIsRecom('Consultation')
            setIsSelected(1)
        }
        else if(result >=3 && result <= 7){
            setRisk('MODERATE risk')
            setIsRecom('Assessment')
            setIsSelected(2)
        }
        else{
            setRisk('HIGH risk')
            setIsRecom('Assessment')
            setIsSelected(2)
        }
    },[])

    const handleContinue = () => {
        localStorage.setItem('diagnosisType',JSON.stringify({isRecom,score}))
        history.push('/dashboar-select-payment-type')

        /// Will Remove after payment
        // const data = JSON.parse(localStorage.getItem('bx_user_token'))

        // if(data){
        //     const obj = {
        //         diagnosis_type:Encrypt(isRecom),
        //         mchat_score:score,
        //         Id:data.uid
        //     }
        //     fetch(baseURL + '/api/payWithCardAndCreateChild',{
        //         method:'Post',
        //         body:JSON.stringify(obj),
        //         headers:{...headers,'authorization':'Bearer ' + data.accessToken}
        //     })
        //     .then(res=>res.json())
        //     .then(res2=>{
        //         if(res2.code === 'Success'){
        //             localStorage.removeItem('diagnosisType')
        //             window.localStorage.setItem('bx_user_token',JSON.stringify({...data,childId:res2.message.childId,childStatus:Encrypt(res2.message.childStatus)}))
        //             history.push('/pre-call-questionnaire')
        //         }
        //         else{
        //             toast.error(res2.message,{toastId:'insurance-req-err-id1',autoClose:3000})
        //         }
        //     })
        // }
    }

    return (
        <div className="parent-dashboard-screener-result">
            <ParentDashboardSideBar active="child" />
            <div style={{marginLeft:windowWidth > 1024 && sidebarWidth}}>
                <ParentDashboardNavbar active="child" />
                <div className="parent-dashboard-screener-result-content">
                    <p style={{margin:0,fontSize:20,fontFamily:'CocoSharp bold'}}>M-CHAT-R : Score = {score}</p>
                    <h1 style={{margin:0,marginTop:35}}>Result : {risk}</h1>

                    <p style={{marginTop:30}}>
                        The score indicates a moderate risk of autism or other developmental disorders. 
                        We recommend that you take your child for consultation or you can go for an assessment of your child.
                    </p>
                    <p style={{marginTop:20}}>
                        This questionnaire is designed to screen for autism, not other developmental issues. 
                        It is important to discuss any concerns about your child’s development with his or her doctor.
                    </p>
                   
                    {/* <div className="parent-dashboard-screener-result-start-btns-container">
                        <button className={`parent-dashboard-screener-result-start-btn ${isSelected===1&&'selected'}`} onClick={()=>(setIsRecom('Consultation'),setIsSelected(1))} >Consultation</button>
                        <button className={`parent-dashboard-screener-result-start-btn ${isSelected===2&&'selected'}`} onClick={()=>(setIsRecom('Assessment'),setIsSelected(2))}>Assessment</button>
                    </div>
                     */}
                    <Link onClick={handleContinue} className="parent-dashboard-screener-result-start-btn selected" style={{marginTop:50}}>Continue</Link>

                </div>
            </div>
        </div>
        
    )
}
