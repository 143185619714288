import React, {useState,useEffect} from 'react';
import {Spinner, Col, Modal } from 'react-bootstrap';
import Header from "../../../Components/Header";
import Footer from "../../../Components/Footer";
import Informed from "../../../Components/Informed";
import Skeleton from 'react-loading-skeleton';
import AppointmentScheduleAlert from '../../../Components/AppointmentScheduleAlert';
import CalenderComp from '../../../Components/CalenderComp';
import {useHistory} from 'react-router-dom';
import moment from 'moment'
import { toast } from 'react-toastify';
import {RiCloseLine} from 'react-icons/ri'
import './CSS/ScheduleFirstMeeting.css';
import {getTimeInLocal,convert12To24,convertIntoUtc,getTimeInLocalString,convert24To12} from '../../../TimeFormate.js'
import { baseURL,Decrypt,Encrypt,headers} from '../../../Config';


export default function ScheduleFirstMeeting() {

    const history = useHistory()

    const [skeletons,setSkeletons] = useState([{},{}])
    const [isLoaded,setIsLoaded] = useState(false)
    const [isLoading,setIsLoading] = useState(false)
    const [time,setTime] = useState('')
    const [date,setDate] = useState('')
    const [clinicianName,setClinicianName] = useState('')
    const [clinicianImage,setClinicianImage] = useState('')
    const [childName,setChildName] = useState('')
    const [appDate,setAppDate] = useState('')
    const [appTime,setAppTime] = useState('')
    const [clinicians,setClinicians] = useState([])
    const [show,setShow] = useState(false)
    const [showAlert,setShowAlert] = useState(false)
    const [showSelfModal,setShowSelfModal] = useState(false)


    useEffect(() => {
        window.scrollTo(0, 0)
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))
        if(dt&&Object.entries(dt).length === 4&&Decrypt(dt.userStatus) == 1){
            history.push('/dashboard-home')
        }
    }, [])


    const handleGetCliniciansList = () =>{

        setIsLoading(true)
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))

        if(time === '' || date === ''){
            toast.error('Please select date or time',{toastId:"schedule-Id"})
            setIsLoading(false)
        }
        else{
            const newDate = getTimeInLocal(date).split('T')[0]
            const newTime = convert12To24(time)
            const utc_date = convertIntoUtc(newDate + 'T' + newTime + ':00')

            const dateInLocalFormate = getTimeInLocalString(newDate + 'T' + newTime + ':00')
            const day = moment(dateInLocalFormate).day()

            fetch(baseURL+ '/api/getCliniciansList',{
                method:'Post',
                body:JSON.stringify({appointmentDate:Encrypt(utc_date),day:Encrypt(`${day}`),childId:dt.childId,parentId:dt.uid}),
                headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
            })
            .then(res=>res.json())
            .then(res2=>{
                if(res2.code === 'Error'){
                    setIsLoading(false)
                    setIsLoaded(true)
                    toast.error(res2.message,{toastId:"get-clinicians-Id1",autoClose:6000})
                }
                else{
                    if(res2.message.code&&Decrypt(res2.message.code) == '2002'){
                        setIsLoading(false)
                        setShowSelfModal(true)
                    }
                    else{
                        setIsLoading(false)
                        setShow(true)
                        setIsLoaded(true)
                        setClinicians(res2.message)
                    }
                }
            })
        }
    }


    const handleSchedule = (Id) =>{
        const dt = JSON.parse(localStorage.getItem('bx_user_token'))

        setShow(false)
        setIsLoading(true)
        const newDate = getTimeInLocal(date).split('T')[0]
        const newTime = convert12To24(time)
        const utc_date = convertIntoUtc(newDate + 'T' + newTime + ':00')

        fetch(baseURL+ '/api/scheduleFirstMeeting',{
            method:'Post',
            body:JSON.stringify({Id:dt.uid,appointmentDate:Encrypt(utc_date),childId:dt.childId,clinicianId:Id}),
            headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
        })
        .then(res=>res.json())
        .then(res2=>{
            if(res2.code === 'Error'){
                setIsLoading(false)
                toast.error(res2.message,{toastId:"schedule-Id1"})
            }
            else{
                const obj = {
                    accessToken: dt.accessToken,
                    uid: dt.uid,
                    userStatus: Encrypt('1'),
                    userType:dt.userType
                }

                localStorage.setItem('bx_user_token',JSON.stringify(obj))

                const resData = res2.message[0]
                const local_time = getTimeInLocal(resData.schedulingDateTime)
                const date = local_time.toString().split('T')[0]
                const time = convert24To12(local_time)
                const clinicianName = res2.message[0].first_name + ' ' + res2.message[0].last_name
                const clinicianImage = res2.message[0].clinicianImage
                const childName = res2.message[0].child_name
                

                setAppDate(date)
                setAppTime(time)
                setClinicianImage(clinicianImage)
                setClinicianName(clinicianName)
                setChildName(childName)
                setIsLoading(false)
                setShowAlert(true)
            }
        })
        
    }


    // const handleConfirmSelfPay = () =>{
    //     const dt = JSON.parse(localStorage.getItem('bx_user_token'))

    //     if(dt){
    //         setShowSelfModal(false)
    //         setIsLoading(true) 
    //         fetch(baseURL + '/api/payRemainingAmount',{
    //             method:'Post',
    //             body:JSON.stringify({Id:dt.childId}),
    //             headers:{...headers,'authorization':'Bearer ' + dt.accessToken}
    //         })
    //         .then(res=>res.json())
    //         .then(res2=>{
    //             if(res2.code === 'Success'){
    //                setIsLoading(false) 
    //                toast.error(res2.message,{toastId:'pay-rem-amount-success-id1',autoClose:3000})
    //             }
    //             else{
    //                 setIsLoading(false)
    //                 toast.error(res2.message,{toastId:'pay-rem-amount-err-id1',autoClose:3000})
    //             }
    //         })
    //     }
    // }

    return (
        <div style={{width:'100%'}}>
            <Header />
            <Col sm='12' md='12' lg='12' xl='12' className="mt-5">
                <div className="Schedule-First-Meeting-Page-Container">
                    <div className="Schedule-First-Meeting-Page-Body">
                        <h1 style={{fontFamily:'CocoSharp bold'}}>Schedule Your First Meeting</h1>
                        <hr /> 
                        <div style={{width:'100%'}}>
                            <CalenderComp selectedTime={(time)=>setTime(time)} selectedDate={(date)=>setDate(date)} />
                            <br/>
                            {
                                isLoading ?
                                    <button className="Schedule-First-Meeting-btn">
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        /> 
                                        Loading...
                                    </button>
                                :
                                    <button onClick={handleGetCliniciansList} className="Schedule-First-Meeting-btn">Next</button>
                            }
                        </div>
                    </div>
                </div>
            </Col>
            <Informed />
            <Footer />
            <Modal show={show} centered animation={false}>
                <Modal.Header style={{border:'none',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                    <p style={{margin:0,color:'#096E82',fontSize:28,fontFamily:'CocoSharp regular'}}>Select Behaviour Consultant</p>
                    <a style={{fontSize:24}}>
                        <RiCloseLine color="#096E82" onClick={()=>setShow(false)} />
                    </a>
                </Modal.Header>
                <Modal.Body style={{zIndex:1,width:'100%'}} className="py-2 pb-4">
                    <fieldset id="clinicians-list-modal-fldset" >
                        <legend style={{fontSize:16,margin:0,fontFamily:'CocoSharp regular',padding:'0px 15px 0px 5px',width:'auto'}}>Behaviour Consultant's</legend>
                            {
                                !isLoaded ? 
                                    skeletons.map(dt=><Skeleton style={{height:100,width:'100%',marginBottom:10,marginTop:10}} />)
                                :
                                    clinicians&&
                                    clinicians.length > 0 ?
                                        clinicians.map(dt=>{
                                            return(
                                                <div style={{height:100,width:'100%',padding:"0px 10px",marginBottom:10,marginTop:10,display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                                                    <div style={{display:'flex',alignItems:'center'}}>
                                                        <div style={{height:50,width:50,borderRadius:'50%',background:dt.image ? `url(${dt.image})` : 'lightgray',backgroundSize:'cover',backgroundRepeat:'no-repeat',backgroundPosition:'center'}}>
                                                        </div>
                                                        <div className="d-flex flex-column justify-content-center ml-2">
                                                            <p style={{fontFamily:'CocoSharp bold',color:'#096E82',fontSize:18,margin:0}}>{Decrypt(dt.name)}</p>
                                                        </div>
                                                    </div>

                                                    <button id="clinicians-list-select-btn" onClick={()=>handleSchedule(dt.Id)}>Select</button>
                                                </div>
                                            )
                                        })
                                    :
                                        <div style={{height:100,width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                            <h3 style={{color:'lightgray'}}>No Clinicians</h3>
                                        </div>
                                    
                            }
                    </fieldset>
                </Modal.Body>
            </Modal>

            {/* Self Pay Modal */}
            {/* <SelfPayModal 
                show={showSelfModal} 
                setShow={()=>setShowSelfModal(false)}
                handleConfirmPay={()=>handleConfirmSelfPay()}
            /> */}

            <AppointmentScheduleAlert 
                show={showAlert}
                date={appDate}
                time={appTime}
                clinician_name={clinicianName}
                child_name={childName}
                image={clinicianImage}
                path='/dashboard-home'
                type="fixed" 
            />
        </div>
      
    );
  }
  

  